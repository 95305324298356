import React, { useCallback } from "react";
import { Button, Flex, Heading, Spacer, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MatchType, matchTypes } from "./components/ConfigureMatchDialog";
import { Card } from "src/components/Layout/Card";
import { ConfigureMatchButton } from "./components/ConfigureMatchButton";
import { RiEditLine } from "react-icons/ri";
import { ConfigureMatchSchoolList } from "./components/ConfigureMatchSchoolList";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";
import { useMatchConfiguration } from "src/hooks/useMatchConfiguration";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { UPSERT_MATCH_CONFIGURATION } from "./graphql/mutations";
import { GET_MATCH_CONFIGURATION } from "./graphql/queries";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import * as breadcrumb from "src/services/breadcrumb";

export function Configure() {
  const navigate = useNavigate();
  const toast = useAvelaToast();
  const { state } = useLocation();
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const { matchConfig } = useMatchConfiguration();
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();

  const [selectedType, setSelectedType] =
    React.useState<MatchType>("multi-offer");

  useEffect(() => {
    if (matchConfig?.parameters.match_type) {
      setSelectedType(matchConfig.parameters.match_type);
    } else if (state?.matchType) {
      setSelectedType(state.matchType);
    }
  }, [state, matchConfig]);

  const selectedMatchObject = matchTypes.find(
    (matchType) => matchType.type === selectedType
  );

  const [upsertMatchConfiguration] = useRemoteDataMutation<
    GQL.UpsertMatchConfiguration,
    GQL.UpsertMatchConfigurationVariables
  >(UPSERT_MATCH_CONFIGURATION);

  const handleSubmitMatchConfiguration = useCallback(async () => {
    try {
      if (!selectedEnrollmentPeriod?.id) {
        throw new Error(
          "Enrollment period is required uppon match config creation."
        );
      }

      await upsertMatchConfiguration({
        variables: {
          matchConfig: {
            id: matchConfig?.id,
            name: `match-config-${selectedType}-${selectedEnrollmentPeriod?.name}`,
            enrollment_period_id:
              matchConfig?.enrollment_period_id ?? selectedEnrollmentPeriod?.id,
            parameters: { match_type: selectedType },
          },
        },
        refetchQueries: [GET_MATCH_CONFIGURATION],
        awaitRefetchQueries: true,
      });

      if (!toast.isActive("match-configuration-saved")) {
        toast({
          id: "match-configuration-saved",
          title: glossary`Match configuration saved`,
          isClosable: true,
          status: "success",
        });
      }

      navigate(
        organization.map((org) => OrgAdmin.Match.index(org)).withDefault("#")
      );
    } catch (error) {
      console.error(error);
      if (!toast.isActive("match-configuration-saved")) {
        toast({
          id: "match-configuration-saved",
          title: glossary`Error saving match configuration`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
      }
    }
  }, [
    selectedType,
    upsertMatchConfiguration,
    glossary,
    toast,
    navigate,
    organization,
    selectedEnrollmentPeriod,
    matchConfig,
  ]);

  return (
    <Flex direction="column" gap={6} height="100%">
      <Breadcrumb
        items={breadcrumb.match.getBreadcrumbsForConfigure(organization)}
      />
      <Heading as="h1" color="gray.700" fontWeight="semibold" fontSize="3xl">
        Configure match
      </Heading>

      <Flex direction="column" gap={4}>
        <Text>Match type</Text>

        <Card
          display="flex"
          showBorder
          padding={6}
          width="100%"
          justifyContent="space-between"
        >
          <Flex direction="column" gap={4} width="100%">
            <Flex justifyContent="space-between">
              <Text fontSize="xl" color="gray.800">
                {selectedMatchObject?.title}
              </Text>

              <WithUserPermissions permissions={["match.configuration:update"]}>
                <ConfigureMatchButton
                  editMode
                  variant="outline"
                  colorScheme="gray"
                  size="sm"
                  leftIcon={<RiEditLine />}
                  onMatchTypeChange={setSelectedType}
                />
              </WithUserPermissions>
            </Flex>
            <Text>{selectedMatchObject?.description}</Text>
          </Flex>
        </Card>
      </Flex>

      <Text>School rules</Text>

      <ConfigureMatchSchoolList />

      <Spacer minH={8} />

      <WithUserPermissions
        permissions={["match.configuration:update"]}
        orElse={
          <AdminFormButtons justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={() =>
                navigate(
                  organization
                    .map((org) => OrgAdmin.Match.index(org))
                    .withDefault("#")
                )
              }
            >
              Back
            </Button>
          </AdminFormButtons>
        }
      >
        <AdminFormButtons justifyContent="space-between">
          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() =>
              navigate(
                organization
                  .map((org) => OrgAdmin.Match.index(org))
                  .withDefault("#")
              )
            }
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitMatchConfiguration}
            isDisabled={!selectedType}
          >
            Done
          </Button>
        </AdminFormButtons>
      </WithUserPermissions>
    </Flex>
  );
}
