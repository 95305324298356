import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { useState, useMemo } from "react";
import { FormSchool } from "../types";
import { RunMatchDialog } from "./RunMatchDialog";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { RunMatchOldDialog } from "./RunMatchOldDialog";
import { MultiSchoolWarningDialog } from "./MultiSchoolWarningDialog";

interface RunMatchButtonProps {
  formTemplateId: string;
  selectedFormSchools: FormSchool[];
}

export const RunMatch: React.FC<RunMatchButtonProps> = ({
  formTemplateId,
  selectedFormSchools,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [matchName, setMatchName] = useState("");
  const flags = useFlags(["match-v2"]);
  const isMatchV2Enabled = flags["match-v2"].enabled;
  const [showMultiSchoolWarning, setShowMultiSchoolWarning] = useState(false);

  const hasMultipleSchools = useMemo(() => {
    const uniqueSchools = new Set(
      selectedFormSchools
        .map((sf) => sf.school?.id)
        .filter((id): id is string => !!id)
    );
    return uniqueSchools.size > 1;
  }, [selectedFormSchools]);

  const handleRunMatchClick = () => {
    if (isMatchV2Enabled && hasMultipleSchools) {
      setShowMultiSchoolWarning(true);
    } else {
      onOpen();
    }
  };

  const handleConfirmMultiSchool = () => {
    setShowMultiSchoolWarning(false);
    onOpen();
  };

  return (
    <>
      <MenuItem
        textColor="gray.700"
        fontWeight="400"
        fontSize="sm"
        onClick={handleRunMatchClick}
        isDisabled={!selectedFormSchools.length}
      >
        Run match
      </MenuItem>

      <MultiSchoolWarningDialog
        isOpen={showMultiSchoolWarning}
        onClose={() => setShowMultiSchoolWarning(false)}
        onConfirm={handleConfirmMultiSchool}
      />

      {isMatchV2Enabled ? (
        <RunMatchDialog
          formTemplateId={formTemplateId}
          schoolForms={selectedFormSchools}
          isOpen={isOpen}
          onCancel={onClose}
          matchName={matchName}
          onMatchNameChange={setMatchName}
        />
      ) : (
        <RunMatchOldDialog
          schoolForms={selectedFormSchools}
          isOpen={isOpen}
          onCancel={onClose}
        />
      )}
    </>
  );
};
