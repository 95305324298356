import { Box, HStack, useRadioGroup, UseRadioProps } from "@chakra-ui/react";

import { useRadio } from "@chakra-ui/react";
import { useField } from "formik";
import { DATE_RANGE_MAP, DateRange } from "./types/dateRange";
type RadioCardProps = UseRadioProps & {
  children: React.ReactNode;
};

function RadioCard({ name, ...props }: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" height="2.5rem">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="blackAlpha.16"
        _checked={{
          color: "purple.500",
          borderColor: "purple.500",
        }}
        _focus={{
          bg: "purple.50",
        }}
        px={4}
        py={2}
        fontSize="md"
      >
        {props.children}
      </Box>
    </Box>
  );
}

const DATE_RANGE_OPTIONS = Object.keys(DATE_RANGE_MAP);

type DateRangeSelectorProps = {
  name: string;
  onChange: (nextValue: DateRange) => void;
};

export function DateRangeSelector({ name, onChange }: DateRangeSelectorProps) {
  const [, { initialValue }] = useField(name);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: initialValue,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {DATE_RANGE_OPTIONS.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio} value={value}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
}
