/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAnnouncements
// ====================================================

export interface FetchAnnouncements_announcement {
  __typename: "announcement";
  type: announcement_type_enum;
  title: string;
  description: string;
  display_type: announcement_display_type_enum;
  link_text: string | null;
  link_url: string | null;
}

export interface FetchAnnouncements {
  /**
   * fetch data from the table: "announcement"
   */
  announcement: FetchAnnouncements_announcement[];
}

export interface FetchAnnouncementsVariables {
  organization_id: uuid;
  entry_point: announcement_entry_point_enum;
  condition: announcement_condition_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBoundariesByForm
// ====================================================

export interface GetBoundariesByForm_geojson_school_boundaries_geo_eligibilities {
  __typename: "geo_eligibility";
  id: uuid;
  is_eligible_inside: boolean;
}

export interface GetBoundariesByForm_geojson_school_boundaries_grade {
  __typename: "grade";
  id: uuid;
  school_id: uuid;
}

export interface GetBoundariesByForm_geojson_school_boundaries {
  __typename: "school_boundary";
  /**
   * An array relationship
   */
  geo_eligibilities: GetBoundariesByForm_geojson_school_boundaries_geo_eligibilities[];
  school_id: uuid;
  /**
   * An object relationship
   */
  grade: GetBoundariesByForm_geojson_school_boundaries_grade | null;
  geojson_property_value: string;
  geojson_id: uuid;
}

export interface GetBoundariesByForm_geojson_boundary_tags {
  __typename: "boundary_tag";
  id: uuid;
  name: string;
  is_inside: boolean;
}

export interface GetBoundariesByForm_geojson {
  __typename: "geojson";
  id: uuid;
  geojson_property_key: string;
  geojson: jsonb;
  /**
   * An array relationship
   */
  school_boundaries: GetBoundariesByForm_geojson_school_boundaries[];
  /**
   * An array relationship
   */
  boundary_tags: GetBoundariesByForm_geojson_boundary_tags[];
}

export interface GetBoundariesByForm {
  /**
   * fetch data from the table: "geojson"
   */
  geojson: GetBoundariesByForm_geojson[];
}

export interface GetBoundariesByFormVariables {
  form_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBoundariesByEnrollmentPeriod
// ====================================================

export interface GetBoundariesByEnrollmentPeriod_geojson_school_boundaries_geo_eligibilities {
  __typename: "geo_eligibility";
  id: uuid;
  is_eligible_inside: boolean;
}

export interface GetBoundariesByEnrollmentPeriod_geojson_school_boundaries_grade {
  __typename: "grade";
  id: uuid;
  school_id: uuid;
}

export interface GetBoundariesByEnrollmentPeriod_geojson_school_boundaries {
  __typename: "school_boundary";
  /**
   * An array relationship
   */
  geo_eligibilities: GetBoundariesByEnrollmentPeriod_geojson_school_boundaries_geo_eligibilities[];
  school_id: uuid;
  /**
   * An object relationship
   */
  grade: GetBoundariesByEnrollmentPeriod_geojson_school_boundaries_grade | null;
  geojson_property_value: string;
  geojson_id: uuid;
}

export interface GetBoundariesByEnrollmentPeriod_geojson_boundary_tags {
  __typename: "boundary_tag";
  id: uuid;
  name: string;
  is_inside: boolean;
}

export interface GetBoundariesByEnrollmentPeriod_geojson {
  __typename: "geojson";
  id: uuid;
  geojson_property_key: string;
  geojson: jsonb;
  /**
   * An array relationship
   */
  school_boundaries: GetBoundariesByEnrollmentPeriod_geojson_school_boundaries[];
  /**
   * An array relationship
   */
  boundary_tags: GetBoundariesByEnrollmentPeriod_geojson_boundary_tags[];
}

export interface GetBoundariesByEnrollmentPeriod {
  /**
   * fetch data from the table: "geojson"
   */
  geojson: GetBoundariesByEnrollmentPeriod_geojson[];
}

export interface GetBoundariesByEnrollmentPeriodVariables {
  enrollmentPeriodId?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendEmailVerification
// ====================================================

export interface ResendEmailVerification_resendEmailVerification {
  __typename: "ResendEmailVerificationOutput";
  message: string | null;
  path: string | null;
  result: string | null;
}

export interface ResendEmailVerification {
  /**
   * Resend Email Verification with Email Verification API
   */
  resendEmailVerification: ResendEmailVerification_resendEmailVerification | null;
}

export interface ResendEmailVerificationVariables {
  user_id: string;
  client_id?: string | null;
  secondaryIdentity?: SecondaryIdentity | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormAddressAnswersForFilter
// ====================================================

export interface GetFormAddressAnswersForFilter_form_address {
  __typename: "form_address";
  id: uuid;
  street_address: string;
  street_address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface GetFormAddressAnswersForFilter {
  /**
   * fetch data from the table: "form_address"
   */
  form_address: GetFormAddressAnswersForFilter_form_address[];
}

export interface GetFormAddressAnswersForFilterVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGradeConfigAnswer
// ====================================================

export interface GetGradeConfigAnswer_grades_answer {
  __typename: "grades_answer";
  grade_config_id: uuid | null;
}

export interface GetGradeConfigAnswer {
  /**
   * fetch data from the table: "grades_answer"
   */
  grades_answer: GetGradeConfigAnswer_grades_answer[];
}

export interface GetGradeConfigAnswerVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormImport
// ====================================================

export interface GetFormImport_form_import_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormImport_form_import {
  __typename: "form_import";
  id: uuid;
  status: form_import_status_enum;
  review_status: form_import_review_status_enum | null;
  errors: jsonb;
  warnings: jsonb;
  created_at: timestamptz;
  updated_at: timestamptz;
  form_template_id: uuid | null;
  /**
   * An object relationship
   */
  enrollment_period: GetFormImport_form_import_enrollment_period | null;
}

export interface GetFormImport {
  /**
   * fetch data from the table: "form_import"
   */
  form_import: GetFormImport_form_import[];
}

export interface GetFormImportVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormImportRowAggregates
// ====================================================

export interface GetFormImportRowAggregates_form_import {
  __typename: "form_import";
  id: uuid;
}

export interface GetFormImportRowAggregates_in_progress_aggregate {
  __typename: "form_import_row_aggregate_fields";
  count: number;
}

export interface GetFormImportRowAggregates_in_progress {
  __typename: "form_import_row_aggregate";
  aggregate: GetFormImportRowAggregates_in_progress_aggregate | null;
}

export interface GetFormImportRowAggregates_completed_aggregate {
  __typename: "form_import_row_aggregate_fields";
  count: number;
}

export interface GetFormImportRowAggregates_completed {
  __typename: "form_import_row_aggregate";
  aggregate: GetFormImportRowAggregates_completed_aggregate | null;
}

export interface GetFormImportRowAggregates_failed_aggregate {
  __typename: "form_import_row_aggregate_fields";
  count: number;
}

export interface GetFormImportRowAggregates_failed {
  __typename: "form_import_row_aggregate";
  aggregate: GetFormImportRowAggregates_failed_aggregate | null;
}

export interface GetFormImportRowAggregates {
  /**
   * fetch data from the table: "form_import"
   */
  form_import: GetFormImportRowAggregates_form_import[];
  /**
   * fetch aggregated fields from the table: "form_import_row"
   */
  in_progress: GetFormImportRowAggregates_in_progress;
  /**
   * fetch aggregated fields from the table: "form_import_row"
   */
  completed: GetFormImportRowAggregates_completed;
  /**
   * fetch aggregated fields from the table: "form_import_row"
   */
  failed: GetFormImportRowAggregates_failed;
}

export interface GetFormImportRowAggregatesVariables {
  form_import_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormImportRowErrors
// ====================================================

export interface GetFormImportRowErrors_form_import_row {
  __typename: "form_import_row";
  errors: jsonb;
  row_number: number;
}

export interface GetFormImportRowErrors {
  /**
   * fetch data from the table: "form_import_row"
   */
  form_import_row: GetFormImportRowErrors_form_import_row[];
}

export interface GetFormImportRowErrorsVariables {
  form_import_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadRelationships
// ====================================================

export interface UploadRelationships_insert_relationship_person {
  __typename: "relationship_person_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UploadRelationships {
  /**
   * insert data into the table: "relationship_person"
   */
  insert_relationship_person: UploadRelationships_insert_relationship_person | null;
}

export interface UploadRelationshipsVariables {
  relationships: relationship_person_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkUploadPeople
// ====================================================

export interface BulkUploadPeople_insert_person {
  __typename: "person_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkUploadPeople {
  /**
   * insert data into the table: "person"
   */
  insert_person: BulkUploadPeople_insert_person | null;
}

export interface BulkUploadPeopleVariables {
  people: person_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDocumentItem
// ====================================================

export interface DeleteDocumentItem_update_document_metadata_by_pk {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface DeleteDocumentItem {
  /**
   * update single row of the table: "document_metadata"
   */
  update_document_metadata_by_pk: DeleteDocumentItem_update_document_metadata_by_pk | null;
}

export interface DeleteDocumentItemVariables {
  document_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertFormAnswerOne
// ====================================================

export interface InsertFormAnswerOne_insert_form_answer_one {
  __typename: "form_answer";
  id: uuid;
}

export interface InsertFormAnswerOne {
  /**
   * insert a single row into the table: "form_answer"
   */
  insert_form_answer_one: InsertFormAnswerOne_insert_form_answer_one | null;
}

export interface InsertFormAnswerOneVariables {
  form_answer: form_answer_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPersonRelationship
// ====================================================

export interface AddPersonRelationship_insert_relationship_person_one {
  __typename: "relationship_person";
  id: uuid;
}

export interface AddPersonRelationship {
  /**
   * insert a single row into the table: "relationship_person"
   */
  insert_relationship_person_one: AddPersonRelationship_insert_relationship_person_one | null;
}

export interface AddPersonRelationshipVariables {
  relationship: relationship_person_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePersonRelationship
// ====================================================

export interface DeletePersonRelationship_update_relationship_person_by_pk {
  __typename: "relationship_person";
  id: uuid;
}

export interface DeletePersonRelationship {
  /**
   * update single row of the table: "relationship_person"
   */
  update_relationship_person_by_pk: DeletePersonRelationship_update_relationship_person_by_pk | null;
}

export interface DeletePersonRelationshipVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormTemplate
// ====================================================

export interface DeleteFormTemplate_update_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
}

export interface DeleteFormTemplate_update_assigned_form {
  __typename: "assigned_form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteFormTemplate {
  /**
   * update single row of the table: "form_template"
   */
  update_form_template_by_pk: DeleteFormTemplate_update_form_template_by_pk | null;
  /**
   * update data of the table: "assigned_form"
   */
  update_assigned_form: DeleteFormTemplate_update_assigned_form | null;
}

export interface DeleteFormTemplateVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteEnrollmentPeriod
// ====================================================

export interface DeleteEnrollmentPeriod_update_enrollment_period_by_pk {
  __typename: "enrollment_period";
  id: uuid;
}

export interface DeleteEnrollmentPeriod {
  /**
   * update single row of the table: "enrollment_period"
   */
  update_enrollment_period_by_pk: DeleteEnrollmentPeriod_update_enrollment_period_by_pk | null;
}

export interface DeleteEnrollmentPeriodVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadApplicantAttendingSchools
// ====================================================

export interface UploadApplicantAttendingSchools_insert_applicant_attending_school {
  __typename: "applicant_attending_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UploadApplicantAttendingSchools {
  /**
   * insert data into the table: "applicant_attending_school"
   */
  insert_applicant_attending_school: UploadApplicantAttendingSchools_insert_applicant_attending_school | null;
}

export interface UploadApplicantAttendingSchoolsVariables {
  data: applicant_attending_school_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateForm
// ====================================================

export interface CreateForm_insert_form_one {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  is_hidden_from_parent: boolean | null;
}

export interface CreateForm {
  /**
   * insert a single row into the table: "form"
   */
  insert_form_one: CreateForm_insert_form_one | null;
}

export interface CreateFormVariables {
  person_id: uuid;
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonRelationships
// ====================================================

export interface GetPersonRelationships_relationship_person_first_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetPersonRelationships_relationship_person_first_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetPersonRelationships_relationship_person_first_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetPersonRelationships_relationship_person_first_latest_forms_form_template_enrollment_period;
}

export interface GetPersonRelationships_relationship_person_first_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetPersonRelationships_relationship_person_first_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetPersonRelationships_relationship_person_first_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetPersonRelationships_relationship_person_first_latest_forms_form_school_ranks[];
}

export interface GetPersonRelationships_relationship_person_first {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetPersonRelationships_relationship_person_first_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetPersonRelationships_relationship_person_first_latest_forms[];
}

export interface GetPersonRelationships_relationship_person_second_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetPersonRelationships_relationship_person_second_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetPersonRelationships_relationship_person_second_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetPersonRelationships_relationship_person_second_latest_forms_form_template_enrollment_period;
}

export interface GetPersonRelationships_relationship_person_second_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetPersonRelationships_relationship_person_second_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetPersonRelationships_relationship_person_second_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetPersonRelationships_relationship_person_second_latest_forms_form_school_ranks[];
}

export interface GetPersonRelationships_relationship_person_second {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetPersonRelationships_relationship_person_second_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetPersonRelationships_relationship_person_second_latest_forms[];
}

export interface GetPersonRelationships_relationship_person {
  __typename: "relationship_person";
  id: uuid;
  /**
   * An object relationship
   */
  first: GetPersonRelationships_relationship_person_first;
  /**
   * An object relationship
   */
  second: GetPersonRelationships_relationship_person_second;
}

export interface GetPersonRelationships {
  /**
   * fetch data from the table: "relationship_person"
   */
  relationship_person: GetPersonRelationships_relationship_person[];
}

export interface GetPersonRelationshipsVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocumentoInfo
// ====================================================

export interface GetDocumentoInfo_document_metadata_by_pk {
  __typename: "document_metadata";
  filename: string;
  document_id: uuid;
  document_type: document_type_enum;
  form_answer_id: uuid | null;
}

export interface GetDocumentoInfo {
  /**
   * fetch data from the table: "document_metadata" using primary key columns
   */
  document_metadata_by_pk: GetDocumentoInfo_document_metadata_by_pk | null;
}

export interface GetDocumentoInfoVariables {
  document_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormAnswerId
// ====================================================

export interface GetFormAnswerId_form_answer {
  __typename: "form_answer";
  id: uuid;
}

export interface GetFormAnswerId {
  /**
   * fetch data from the table: "form_answer"
   */
  form_answer: GetFormAnswerId_form_answer[];
}

export interface GetFormAnswerIdVariables {
  question_id: uuid;
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplatesByEnrollmentPeriod
// ====================================================

export interface GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules_status_rule_next_form_template {
  __typename: "form_template";
  name: string;
}

export interface GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules_status_rule {
  __typename: "status_rule";
  /**
   * An object relationship
   */
  next_form_template: GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules_status_rule_next_form_template;
}

export interface GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules {
  __typename: "rule";
  /**
   * An object relationship
   */
  status_rule: GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules_status_rule | null;
}

export interface GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule {
  __typename: "form_template_rule";
  /**
   * An array relationship
   */
  rules: GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule_rules[];
}

export interface GetFormTemplatesByEnrollmentPeriod_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  description: string | null;
  active: boolean;
  lottery_offers_enabled: boolean;
  offer_start_at: timestamptz | null;
  locked: boolean;
  /**
   * An object relationship
   */
  form_template_rule: GetFormTemplatesByEnrollmentPeriod_form_template_form_template_rule | null;
}

export interface GetFormTemplatesByEnrollmentPeriod {
  /**
   * fetch data from the table: "form_template"
   */
  form_template: GetFormTemplatesByEnrollmentPeriod_form_template[];
}

export interface GetFormTemplatesByEnrollmentPeriodVariables {
  enrollment_period: uuid;
  include_rules?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBasicFormTemplatesByEnrollmentPeriod
// ====================================================

export interface GetBasicFormTemplatesByEnrollmentPeriod_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  description: string | null;
  active: boolean;
  locked: boolean;
}

export interface GetBasicFormTemplatesByEnrollmentPeriod {
  /**
   * fetch data from the table: "form_template"
   */
  form_template: GetBasicFormTemplatesByEnrollmentPeriod_form_template[];
}

export interface GetBasicFormTemplatesByEnrollmentPeriodVariables {
  enrollment_period: uuid;
  search?: form_template_bool_exp | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormAssignmentsForStudentAndTemplate
// ====================================================

export interface GetFormAssignmentsForStudentAndTemplate_assigned_form_form_template {
  __typename: "form_template";
  id: uuid;
}

export interface GetFormAssignmentsForStudentAndTemplate_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  person_id: uuid;
  /**
   * An object relationship
   */
  form_template: GetFormAssignmentsForStudentAndTemplate_assigned_form_form_template;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
}

export interface GetFormAssignmentsForStudentAndTemplate {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetFormAssignmentsForStudentAndTemplate_assigned_form[];
}

export interface GetFormAssignmentsForStudentAndTemplateVariables {
  applicant_id: uuid;
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertUserAddress
// ====================================================

export interface UpsertUserAddress_insert_user_address_one {
  __typename: "user_address";
  id: uuid;
  updated_at: timestamptz;
}

export interface UpsertUserAddress {
  /**
   * insert a single row into the table: "user_address"
   */
  insert_user_address_one: UpsertUserAddress_insert_user_address_one | null;
}

export interface UpsertUserAddressVariables {
  user_address: user_address_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAddresses
// ====================================================

export interface GetUserAddresses_user_address {
  __typename: "user_address";
  id: uuid;
  street_address: string;
  street_address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface GetUserAddresses {
  /**
   * fetch data from the table: "user_address"
   */
  user_address: GetUserAddresses_user_address[];
}

export interface GetUserAddressesVariables {
  user_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertPersonAnswerBank
// ====================================================

export interface InsertPersonAnswerBank_insert_person_answer_bank_one {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface InsertPersonAnswerBank {
  /**
   * insert a single row into the table: "person_answer_bank"
   */
  insert_person_answer_bank_one: InsertPersonAnswerBank_insert_person_answer_bank_one | null;
}

export interface InsertPersonAnswerBankVariables {
  entry: person_answer_bank_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePersonAnswerBank
// ====================================================

export interface UpdatePersonAnswerBank_delete_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdatePersonAnswerBank_update_person_answer_bank_returning {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface UpdatePersonAnswerBank_update_person_answer_bank {
  __typename: "person_answer_bank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdatePersonAnswerBank_update_person_answer_bank_returning[];
}

export interface UpdatePersonAnswerBank_insert_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdatePersonAnswerBank {
  /**
   * delete data from the table: "person_answer"
   */
  delete_person_answer: UpdatePersonAnswerBank_delete_person_answer | null;
  /**
   * update data of the table: "person_answer_bank"
   */
  update_person_answer_bank: UpdatePersonAnswerBank_update_person_answer_bank | null;
  /**
   * insert data into the table: "person_answer"
   */
  insert_person_answer: UpdatePersonAnswerBank_insert_person_answer | null;
}

export interface UpdatePersonAnswerBankVariables {
  answer_bank_id: uuid;
  last_used_at: timestamptz;
  person_answers: person_answer_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestion
// ====================================================

export interface GetCustomQuestion_question_by_pk_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions_question;
}

export interface GetCustomQuestion_question_by_pk_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestion_question_by_pk_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetCustomQuestion_question_by_pk_form_question_form_question_options_additional_questions[];
}

export interface GetCustomQuestion_question_by_pk_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestion_question_by_pk_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestion_question_by_pk_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestion_question_by_pk_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question_form_question | null;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields_question;
}

export interface GetCustomQuestion_question_by_pk_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetCustomQuestion_question_by_pk_custom_question_custom_question_type_custom_question_type_fields[];
}

export interface GetCustomQuestion_question_by_pk_custom_question {
  __typename: "custom_question";
  /**
   * An array relationship
   */
  custom_question_relationships: GetCustomQuestion_question_by_pk_custom_question_custom_question_relationships[];
  /**
   * An object relationship
   */
  custom_question_type: GetCustomQuestion_question_by_pk_custom_question_custom_question_type;
}

export interface GetCustomQuestion_question_by_pk {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestion_question_by_pk_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestion_question_by_pk_form_question | null;
  /**
   * An object relationship
   */
  custom_question: GetCustomQuestion_question_by_pk_custom_question | null;
}

export interface GetCustomQuestion {
  /**
   * fetch data from the table: "question" using primary key columns
   */
  question_by_pk: GetCustomQuestion_question_by_pk | null;
}

export interface GetCustomQuestionVariables {
  question_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestionAnswer
// ====================================================

export interface GetCustomQuestionAnswer_custom_question_answer_form_answer_form_question_question {
  __typename: "question";
  type: question_type_enum;
}

export interface GetCustomQuestionAnswer_custom_question_answer_form_answer_form_question {
  __typename: "form_question";
  question_id: uuid;
  category: form_question_category_enum;
  /**
   * An object relationship
   */
  question: GetCustomQuestionAnswer_custom_question_answer_form_answer_form_question_question;
}

export interface GetCustomQuestionAnswer_custom_question_answer_form_answer_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
  form_question_option_id: uuid;
}

export interface GetCustomQuestionAnswer_custom_question_answer_form_answer_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface GetCustomQuestionAnswer_custom_question_answer_form_answer {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_question: GetCustomQuestionAnswer_custom_question_answer_form_answer_form_question;
  free_text_answer: string | null;
  number_answer: numeric | null;
  date_answer: date | null;
  /**
   * An array relationship
   */
  form_answer_options: GetCustomQuestionAnswer_custom_question_answer_form_answer_form_answer_options[];
  /**
   * An array relationship
   */
  document_metadata: GetCustomQuestionAnswer_custom_question_answer_form_answer_document_metadata[];
}

export interface GetCustomQuestionAnswer_custom_question_answer {
  __typename: "custom_question_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_answer: GetCustomQuestionAnswer_custom_question_answer_form_answer;
}

export interface GetCustomQuestionAnswer {
  /**
   * fetch data from the table: "custom_question_answer"
   */
  custom_question_answer: GetCustomQuestionAnswer_custom_question_answer[];
}

export interface GetCustomQuestionAnswerVariables {
  form_id: uuid;
  question_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestionType
// ====================================================

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question | null;
}

export interface GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields_question;
}

export interface GetCustomQuestionType_custom_question_type_by_pk {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetCustomQuestionType_custom_question_type_by_pk_custom_question_type_fields[];
}

export interface GetCustomQuestionType {
  /**
   * fetch data from the table: "custom_question_type" using primary key columns
   */
  custom_question_type_by_pk: GetCustomQuestionType_custom_question_type_by_pk | null;
}

export interface GetCustomQuestionTypeVariables {
  custom_question_type_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonAnswerBank
// ====================================================

export interface GetPersonAnswerBank_person_answer_bank_person_answers_custom_question_type_field_question {
  __typename: "question";
  id: uuid;
  type: question_type_enum;
  question: string;
}

export interface GetPersonAnswerBank_person_answer_bank_person_answers_custom_question_type_field {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetPersonAnswerBank_person_answer_bank_person_answers_custom_question_type_field_question;
}

export interface GetPersonAnswerBank_person_answer_bank_person_answers_person_answer_options_form_question_option {
  __typename: "form_question_option";
  id: uuid;
  label: string;
  value: string | null;
}

export interface GetPersonAnswerBank_person_answer_bank_person_answers_person_answer_options {
  __typename: "person_answer_option";
  id: uuid;
  /**
   * An object relationship
   */
  form_question_option: GetPersonAnswerBank_person_answer_bank_person_answers_person_answer_options_form_question_option;
}

export interface GetPersonAnswerBank_person_answer_bank_person_answers {
  __typename: "person_answer";
  id: uuid;
  /**
   * An object relationship
   */
  custom_question_type_field: GetPersonAnswerBank_person_answer_bank_person_answers_custom_question_type_field;
  answer: string | null;
  /**
   * An array relationship
   */
  person_answer_options: GetPersonAnswerBank_person_answer_bank_person_answers_person_answer_options[];
}

export interface GetPersonAnswerBank_person_answer_bank {
  __typename: "person_answer_bank";
  id: uuid;
  /**
   * An array relationship
   */
  person_answers: GetPersonAnswerBank_person_answer_bank_person_answers[];
}

export interface GetPersonAnswerBank {
  /**
   * fetch data from the table: "person_answer_bank"
   */
  person_answer_bank: GetPersonAnswerBank_person_answer_bank[];
}

export interface GetPersonAnswerBankVariables {
  person_id: uuid;
  custom_question_type_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelectedPersonAnswerBank
// ====================================================

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_custom_question_type_field_question {
  __typename: "question";
  id: uuid;
  type: question_type_enum;
  question: string;
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_custom_question_type_field {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_custom_question_type_field_question;
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_person_answer_options_form_question_option {
  __typename: "form_question_option";
  id: uuid;
  label: string;
  value: string | null;
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_person_answer_options {
  __typename: "person_answer_option";
  id: uuid;
  /**
   * An object relationship
   */
  form_question_option: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_person_answer_options_form_question_option;
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers {
  __typename: "person_answer";
  id: uuid;
  /**
   * An object relationship
   */
  custom_question_type_field: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_custom_question_type_field;
  answer: string | null;
  /**
   * An array relationship
   */
  person_answer_options: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers_person_answer_options[];
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank {
  __typename: "person_answer_bank";
  id: uuid;
  /**
   * An array relationship
   */
  person_answers: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank_person_answers[];
}

export interface GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship";
  /**
   * An object relationship
   */
  person_answer_bank: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship_person_answer_bank;
}

export interface GetSelectedPersonAnswerBank {
  /**
   * fetch data from the table: "custom_question_answer_bank_relationship"
   */
  custom_question_answer_bank_relationship: GetSelectedPersonAnswerBank_custom_question_answer_bank_relationship[];
}

export interface GetSelectedPersonAnswerBankVariables {
  form_id: uuid;
  question_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestionTypes
// ====================================================

export interface GetCustomQuestionTypes_custom_question_type_custom_question_type_fields_question {
  __typename: "question";
  id: uuid;
  key: string | null;
  question: string;
  type: question_type_enum;
  order: number;
  is_required: boolean;
  link_text: string | null;
  link_url: string | null;
  permission_level: string | null;
}

export interface GetCustomQuestionTypes_custom_question_type_custom_question_type_fields {
  __typename: "custom_question_type_field";
  /**
   * An object relationship
   */
  question: GetCustomQuestionTypes_custom_question_type_custom_question_type_fields_question;
}

export interface GetCustomQuestionTypes_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetCustomQuestionTypes_custom_question_type_custom_question_type_fields[];
}

export interface GetCustomQuestionTypes {
  /**
   * fetch data from the table: "custom_question_type"
   */
  custom_question_type: GetCustomQuestionTypes_custom_question_type[];
}

export interface GetCustomQuestionTypesVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationConfig
// ====================================================

export interface GetOrganizationConfig_organization_config {
  __typename: "organization_config";
  id: uuid;
  type: organization_config_type_enum;
  enabled: boolean;
  data: jsonb;
}

export interface GetOrganizationConfig {
  /**
   * fetch data from the table: "organization_config"
   */
  organization_config: GetOrganizationConfig_organization_config[];
}

export interface GetOrganizationConfigVariables {
  organization_id: uuid;
  types?: organization_config_type_enum[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationsByUserId
// ====================================================

export interface GetOrganizationsByUserId_organization {
  __typename: "organization";
  name: string;
  path: string;
  id: uuid;
}

export interface GetOrganizationsByUserId {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetOrganizationsByUserId_organization[];
}

export interface GetOrganizationsByUserIdVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocumentLibraryForUser
// ====================================================

export interface GetDocumentLibraryForUser_document_metadata_form_answer_form_question_question {
  __typename: "question";
  question: string;
}

export interface GetDocumentLibraryForUser_document_metadata_form_answer_form_question {
  __typename: "form_question";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetDocumentLibraryForUser_document_metadata_form_answer_form_question_question;
}

export interface GetDocumentLibraryForUser_document_metadata_form_answer {
  __typename: "form_answer";
  created_at: timestamptz;
  /**
   * An object relationship
   */
  form_question: GetDocumentLibraryForUser_document_metadata_form_answer_form_question;
}

export interface GetDocumentLibraryForUser_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
  filename: string;
  created_at: timestamptz;
  /**
   * An object relationship
   */
  form_answer: GetDocumentLibraryForUser_document_metadata_form_answer | null;
}

export interface GetDocumentLibraryForUser {
  /**
   * An array relationship
   */
  document_metadata: GetDocumentLibraryForUser_document_metadata[];
}

export interface GetDocumentLibraryForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGlossary
// ====================================================

export interface GetGlossary_glossary_by_organization_glossary {
  __typename: "glossary";
  type: glossary_type_enum;
}

export interface GetGlossary_glossary_by_organization {
  __typename: "glossary_by_organization";
  /**
   * An object relationship
   */
  glossary: GetGlossary_glossary_by_organization_glossary | null;
  organization_id: uuid | null;
  enrollment_period_id: uuid | null;
  default_singular: string | null;
  default_plural: string | null;
  alias_singular: string | null;
  alias_plural: string | null;
}

export interface GetGlossary {
  /**
   * fetch data from the table: "glossary_by_organization"
   */
  glossary_by_organization: GetGlossary_glossary_by_organization[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckMatchRunStatus
// ====================================================

export interface CheckMatchRunStatus_match_run_by_pk {
  __typename: "match_run";
  status: match_run_status_enum | null;
  results_document_id: uuid | null;
  name: string | null;
  status_message: string | null;
  total_form_count: number;
  loaded_form_count: number;
  matched_form_count: number;
}

export interface CheckMatchRunStatus {
  /**
   * fetch data from the table: "match_run" using primary key columns
   */
  match_run_by_pk: CheckMatchRunStatus_match_run_by_pk | null;
}

export interface CheckMatchRunStatusVariables {
  match_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationId
// ====================================================

export interface GetOrganizationId_organization {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
}

export interface GetOrganizationId {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetOrganizationId_organization[];
}

export interface GetOrganizationIdVariables {
  path: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeamId
// ====================================================

export interface GetTeamId_person_person_teams {
  __typename: "person_team";
  team_id: uuid;
}

export interface GetTeamId_person {
  __typename: "person";
  /**
   * An array relationship
   */
  person_teams: GetTeamId_person_person_teams[];
}

export interface GetTeamId {
  /**
   * fetch data from the table: "person"
   */
  person: GetTeamId_person[];
}

export interface GetTeamIdVariables {
  organizationId: uuid;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUserPermissions
// ====================================================

export interface FetchUserPermissions_user_by_pk_school_users {
  __typename: "school_user";
  school_access_permission: string | null;
}

export interface FetchUserPermissions_user_by_pk_user_group_user_group_permissions {
  __typename: "user_group_permission";
  permission: string;
}

export interface FetchUserPermissions_user_by_pk_user_group {
  __typename: "user_group";
  name: string;
  /**
   * An array relationship
   */
  user_group_permissions: FetchUserPermissions_user_by_pk_user_group_user_group_permissions[];
}

export interface FetchUserPermissions_user_by_pk {
  __typename: "user";
  /**
   * An array relationship
   */
  school_users: FetchUserPermissions_user_by_pk_school_users[];
  /**
   * An object relationship
   */
  user_group: FetchUserPermissions_user_by_pk_user_group | null;
}

export interface FetchUserPermissions {
  /**
   * fetch data from the table: "user" using primary key columns
   */
  user_by_pk: FetchUserPermissions_user_by_pk | null;
}

export interface FetchUserPermissionsVariables {
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUserAttributes
// ====================================================

export interface FetchUserAttributes_user_by_pk_school_users {
  __typename: "school_user";
  school_id: uuid;
  school_access_permission: string | null;
}

export interface FetchUserAttributes_user_by_pk {
  __typename: "user";
  /**
   * An array relationship
   */
  school_users: FetchUserAttributes_user_by_pk_school_users[];
}

export interface FetchUserAttributes {
  /**
   * fetch data from the table: "user" using primary key columns
   */
  user_by_pk: FetchUserAttributes_user_by_pk | null;
}

export interface FetchUserAttributesVariables {
  userID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePreferredLanguage
// ====================================================

export interface UpdatePreferredLanguage_update_person_returning {
  __typename: "person";
  id: uuid;
  preferred_language: string | null;
}

export interface UpdatePreferredLanguage_update_person {
  __typename: "person_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdatePreferredLanguage_update_person_returning[];
}

export interface UpdatePreferredLanguage {
  /**
   * update data of the table: "person"
   */
  update_person: UpdatePreferredLanguage_update_person | null;
}

export interface UpdatePreferredLanguageVariables {
  person_id: uuid;
  preferred_language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBoundaryGroup
// ====================================================

export interface CreateBoundaryGroup_insert_geojson_one {
  __typename: "geojson";
  id: uuid;
}

export interface CreateBoundaryGroup {
  /**
   * insert a single row into the table: "geojson"
   */
  insert_geojson_one: CreateBoundaryGroup_insert_geojson_one | null;
}

export interface CreateBoundaryGroupVariables {
  geojson: geojson_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteBoundaryGroup
// ====================================================

export interface DeleteBoundaryGroup_delete_geo_eligibility {
  __typename: "geo_eligibility_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteBoundaryGroup_delete_school_boundary {
  __typename: "school_boundary_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteBoundaryGroup_delete_boundary_tag {
  __typename: "boundary_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteBoundaryGroup_delete_geojson_by_pk {
  __typename: "geojson";
  id: uuid;
}

export interface DeleteBoundaryGroup {
  /**
   * delete data from the table: "geo_eligibility"
   */
  delete_geo_eligibility: DeleteBoundaryGroup_delete_geo_eligibility | null;
  /**
   * delete data from the table: "school_boundary"
   */
  delete_school_boundary: DeleteBoundaryGroup_delete_school_boundary | null;
  /**
   * delete data from the table: "boundary_tag"
   */
  delete_boundary_tag: DeleteBoundaryGroup_delete_boundary_tag | null;
  /**
   * delete single row from the table: "geojson"
   */
  delete_geojson_by_pk: DeleteBoundaryGroup_delete_geojson_by_pk | null;
}

export interface DeleteBoundaryGroupVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBoundaryGroupList
// ====================================================

export interface GetBoundaryGroupList_geojson_school_boundaries_school {
  __typename: "school";
  name: string;
}

export interface GetBoundaryGroupList_geojson_school_boundaries_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetBoundaryGroupList_geojson_school_boundaries_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetBoundaryGroupList_geojson_school_boundaries_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetBoundaryGroupList_geojson_school_boundaries_grade_grade_config;
}

export interface GetBoundaryGroupList_geojson_school_boundaries {
  __typename: "school_boundary";
  /**
   * An object relationship
   */
  school: GetBoundaryGroupList_geojson_school_boundaries_school;
  /**
   * An object relationship
   */
  enrollment_period: GetBoundaryGroupList_geojson_school_boundaries_enrollment_period;
  /**
   * An object relationship
   */
  grade: GetBoundaryGroupList_geojson_school_boundaries_grade | null;
}

export interface GetBoundaryGroupList_geojson_organization {
  __typename: "organization";
  name: string;
}

export interface GetBoundaryGroupList_geojson {
  __typename: "geojson";
  id: uuid;
  /**
   * An array relationship
   */
  school_boundaries: GetBoundaryGroupList_geojson_school_boundaries[];
  /**
   * An object relationship
   */
  organization: GetBoundaryGroupList_geojson_organization;
}

export interface GetBoundaryGroupList {
  /**
   * fetch data from the table: "geojson"
   */
  geojson: GetBoundaryGroupList_geojson[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertGlossaryByOrganization
// ====================================================

export interface InsertGlossaryByOrganization_delete_glossary {
  __typename: "glossary_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertGlossaryByOrganization_insert_glossary {
  __typename: "glossary_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertGlossaryByOrganization {
  /**
   * delete data from the table: "glossary"
   */
  delete_glossary: InsertGlossaryByOrganization_delete_glossary | null;
  /**
   * insert data into the table: "glossary"
   */
  insert_glossary: InsertGlossaryByOrganization_insert_glossary | null;
}

export interface InsertGlossaryByOrganizationVariables {
  organizationId: uuid;
  glossary: glossary_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGlossaryByOrganization
// ====================================================

export interface DeleteGlossaryByOrganization_delete_glossary {
  __typename: "glossary_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteGlossaryByOrganization {
  /**
   * delete data from the table: "glossary"
   */
  delete_glossary: DeleteGlossaryByOrganization_delete_glossary | null;
}

export interface DeleteGlossaryByOrganizationVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGlossaryList
// ====================================================

export interface GetGlossaryList_organization {
  __typename: "organization";
  id: uuid;
  name: string;
}

export interface GetGlossaryList_glossary_enrollment_period {
  __typename: "enrollment_period";
  organization_id: uuid | null;
}

export interface GetGlossaryList_glossary {
  __typename: "glossary";
  organization_id: uuid | null;
  /**
   * An object relationship
   */
  enrollment_period: GetGlossaryList_glossary_enrollment_period | null;
}

export interface GetGlossaryList {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetGlossaryList_organization[];
  /**
   * fetch data from the table: "glossary"
   */
  glossary: GetGlossaryList_glossary[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGlossaryByOrganization
// ====================================================

export interface GetGlossaryByOrganization_organization_by_pk {
  __typename: "organization";
  name: string;
}

export interface GetGlossaryByOrganization_glossary_by_organization_glossary {
  __typename: "glossary";
  type: glossary_type_enum;
}

export interface GetGlossaryByOrganization_glossary_by_organization {
  __typename: "glossary_by_organization";
  /**
   * An object relationship
   */
  glossary: GetGlossaryByOrganization_glossary_by_organization_glossary | null;
  organization_id: uuid | null;
  enrollment_period_id: uuid | null;
  default_singular: string | null;
  default_plural: string | null;
  alias_singular: string | null;
  alias_plural: string | null;
}

export interface GetGlossaryByOrganization {
  /**
   * fetch data from the table: "organization" using primary key columns
   */
  organization_by_pk: GetGlossaryByOrganization_organization_by_pk | null;
  /**
   * fetch data from the table: "glossary_by_organization"
   */
  glossary_by_organization: GetGlossaryByOrganization_glossary_by_organization[];
}

export interface GetGlossaryByOrganizationVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGradesConfig
// ====================================================

export interface CreateGradesConfig_insert_grade_config {
  __typename: "grade_config_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CreateGradesConfig {
  /**
   * insert data into the table: "grade_config"
   */
  insert_grade_config: CreateGradesConfig_insert_grade_config | null;
}

export interface CreateGradesConfigVariables {
  grades_config: grade_config_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGradeConfig
// ====================================================

export interface DeleteGradeConfig_delete_grade_config {
  __typename: "grade_config_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteGradeConfig {
  /**
   * delete data from the table: "grade_config"
   */
  delete_grade_config: DeleteGradeConfig_delete_grade_config | null;
}

export interface DeleteGradeConfigVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGradeConfig
// ====================================================

export interface UpdateGradeConfig_update_grade_config_many {
  __typename: "grade_config_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateGradeConfig_insert_grade_config {
  __typename: "grade_config_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateGradeConfig {
  /**
   * update multiples rows of table: "grade_config"
   */
  update_grade_config_many: (UpdateGradeConfig_update_grade_config_many | null)[] | null;
  /**
   * insert data into the table: "grade_config"
   */
  insert_grade_config: UpdateGradeConfig_insert_grade_config | null;
}

export interface UpdateGradeConfigVariables {
  grade_config_updates: grade_config_updates[];
  new_grade_configs: grade_config_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationsByGradesConfig
// ====================================================

export interface GetOrganizationsByGradesConfig_organization_grade_configs {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
  value: string | null;
}

export interface GetOrganizationsByGradesConfig_organization {
  __typename: "organization";
  id: uuid;
  /**
   * An array relationship
   */
  grade_configs: GetOrganizationsByGradesConfig_organization_grade_configs[];
}

export interface GetOrganizationsByGradesConfig {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetOrganizationsByGradesConfig_organization[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgGradesConfigByPk
// ====================================================

export interface GetOrgGradesConfigByPk_organization_by_pk_grade_configs {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
  value: string | null;
}

export interface GetOrgGradesConfigByPk_organization_by_pk {
  __typename: "organization";
  id: uuid;
  /**
   * An array relationship
   */
  grade_configs: GetOrgGradesConfigByPk_organization_by_pk_grade_configs[];
}

export interface GetOrgGradesConfigByPk {
  /**
   * fetch data from the table: "organization" using primary key columns
   */
  organization_by_pk: GetOrgGradesConfigByPk_organization_by_pk | null;
}

export interface GetOrgGradesConfigByPkVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganization
// ====================================================

export interface CreateOrganization_insert_organization_one {
  __typename: "organization";
  id: uuid;
}

export interface CreateOrganization {
  /**
   * insert a single row into the table: "organization"
   */
  insert_organization_one: CreateOrganization_insert_organization_one | null;
}

export interface CreateOrganizationVariables {
  organization: organization_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganization
// ====================================================

export interface UpdateOrganization_update_organization_by_pk {
  __typename: "organization";
  id: uuid;
}

export interface UpdateOrganization {
  /**
   * update single row of the table: "organization"
   */
  update_organization_by_pk: UpdateOrganization_update_organization_by_pk | null;
}

export interface UpdateOrganizationVariables {
  id: uuid;
  organization?: organization_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAnnouncement
// ====================================================

export interface UpdateAnnouncement_update_announcement_by_pk {
  __typename: "announcement";
  id: uuid;
}

export interface UpdateAnnouncement {
  /**
   * update single row of the table: "announcement"
   */
  update_announcement_by_pk: UpdateAnnouncement_update_announcement_by_pk | null;
}

export interface UpdateAnnouncementVariables {
  id: uuid;
  announcement?: announcement_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAnnouncement
// ====================================================

export interface CreateAnnouncement_insert_announcement_one {
  __typename: "announcement";
  id: uuid;
}

export interface CreateAnnouncement {
  /**
   * insert a single row into the table: "announcement"
   */
  insert_announcement_one: CreateAnnouncement_insert_announcement_one | null;
}

export interface CreateAnnouncementVariables {
  announcement: announcement_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrganization
// ====================================================

export interface DeleteOrganization_delete_organization_by_pk {
  __typename: "organization";
  id: uuid;
}

export interface DeleteOrganization {
  /**
   * delete single row from the table: "organization"
   */
  delete_organization_by_pk: DeleteOrganization_delete_organization_by_pk | null;
}

export interface DeleteOrganizationVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganization
// ====================================================

export interface GetOrganization_organization_by_pk_announcements {
  __typename: "announcement";
  id: uuid;
  title: string;
  description: string;
  active: boolean;
  condition: announcement_condition_enum;
  display_type: announcement_display_type_enum;
  link_text: string | null;
  link_url: string | null;
}

export interface GetOrganization_organization_by_pk {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
  /**
   * An array relationship
   */
  announcements: GetOrganization_organization_by_pk_announcements[];
}

export interface GetOrganization {
  /**
   * fetch data from the table: "organization" using primary key columns
   */
  organization_by_pk: GetOrganization_organization_by_pk | null;
}

export interface GetOrganizationVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizations
// ====================================================

export interface GetOrganizations_organization_announcements {
  __typename: "announcement";
  id: uuid;
  title: string;
  description: string;
  active: boolean;
  condition: announcement_condition_enum;
  display_type: announcement_display_type_enum;
  link_text: string | null;
  link_url: string | null;
}

export interface GetOrganizations_organization {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
  /**
   * An array relationship
   */
  announcements: GetOrganizations_organization_announcements[];
}

export interface GetOrganizations {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetOrganizations_organization[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationsForListView
// ====================================================

export interface GetOrganizationsForListView_organization {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  status: organization_status_enum;
}

export interface GetOrganizationsForListView {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetOrganizationsForListView_organization[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePriorityTemplates
// ====================================================

export interface CreatePriorityTemplates_insert_priority_template_returning {
  __typename: "priority_template";
  id: uuid;
}

export interface CreatePriorityTemplates_insert_priority_template {
  __typename: "priority_template_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: CreatePriorityTemplates_insert_priority_template_returning[];
}

export interface CreatePriorityTemplates {
  /**
   * insert data into the table: "priority_template"
   */
  insert_priority_template: CreatePriorityTemplates_insert_priority_template | null;
}

export interface CreatePriorityTemplatesVariables {
  priority_templates: priority_template_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePriorityTemplates
// ====================================================

export interface DeletePriorityTemplates_delete_priority_template_grade {
  __typename: "priority_template_grade_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeletePriorityTemplates_delete_priority_template_school {
  __typename: "priority_template_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeletePriorityTemplates_delete_priority_template_enrollment_period {
  __typename: "priority_template_enrollment_period_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeletePriorityTemplates_delete_priority_template {
  __typename: "priority_template_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeletePriorityTemplates {
  /**
   * delete data from the table: "priority_template_grade"
   */
  delete_priority_template_grade: DeletePriorityTemplates_delete_priority_template_grade | null;
  /**
   * delete data from the table: "priority_template_school"
   */
  delete_priority_template_school: DeletePriorityTemplates_delete_priority_template_school | null;
  /**
   * delete data from the table: "priority_template_enrollment_period"
   */
  delete_priority_template_enrollment_period: DeletePriorityTemplates_delete_priority_template_enrollment_period | null;
  /**
   * delete data from the table: "priority_template"
   */
  delete_priority_template: DeletePriorityTemplates_delete_priority_template | null;
}

export interface DeletePriorityTemplatesVariables {
  priority_template_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEnrollmentPeriodEnums
// ====================================================

export interface GetEnrollmentPeriodEnums_enrollment_period_enrollment_period_tags {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetEnrollmentPeriodEnums_enrollment_period_grades {
  __typename: "grade";
  school_id: uuid;
}

export interface GetEnrollmentPeriodEnums_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  /**
   * An array relationship
   */
  enrollment_period_tags: GetEnrollmentPeriodEnums_enrollment_period_enrollment_period_tags[];
  /**
   * An array relationship
   */
  grades: GetEnrollmentPeriodEnums_enrollment_period_grades[];
}

export interface GetEnrollmentPeriodEnums {
  /**
   * fetch data from the table: "enrollment_period"
   */
  enrollment_period: GetEnrollmentPeriodEnums_enrollment_period[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchPriorityTemplates
// ====================================================

export interface FetchPriorityTemplates_priority_template_enrollment_period_organization {
  __typename: "organization";
  name: string;
}

export interface FetchPriorityTemplates_priority_template_enrollment_period {
  __typename: "enrollment_period";
  name: string;
  /**
   * An object relationship
   */
  organization: FetchPriorityTemplates_priority_template_enrollment_period_organization | null;
}

export interface FetchPriorityTemplates_priority_template {
  __typename: "priority_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: FetchPriorityTemplates_priority_template_enrollment_period;
}

export interface FetchPriorityTemplates {
  /**
   * fetch data from the table: "priority_template"
   */
  priority_template: FetchPriorityTemplates_priority_template[];
}

export interface FetchPriorityTemplatesVariables {
  order_by: priority_template_order_by;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProgramGroup
// ====================================================

export interface CreateProgramGroup_insert_program_group_one {
  __typename: "program_group";
  id: uuid;
}

export interface CreateProgramGroup {
  /**
   * insert a single row into the table: "program_group"
   */
  insert_program_group_one: CreateProgramGroup_insert_program_group_one | null;
}

export interface CreateProgramGroupVariables {
  program_group: program_group_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProgramGroup
// ====================================================

export interface UpdateProgramGroup_update_program_group_by_pk {
  __typename: "program_group";
  id: uuid;
}

export interface UpdateProgramGroup_insert_program {
  __typename: "program_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateProgramGroup_delete_program {
  __typename: "program_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateProgramGroup {
  /**
   * update single row of the table: "program_group"
   */
  update_program_group_by_pk: UpdateProgramGroup_update_program_group_by_pk | null;
  /**
   * insert data into the table: "program"
   */
  insert_program: UpdateProgramGroup_insert_program | null;
  /**
   * delete data from the table: "program"
   */
  delete_program: UpdateProgramGroup_delete_program | null;
}

export interface UpdateProgramGroupVariables {
  program_group_id: uuid;
  program_group_set: program_group_set_input;
  programs: program_insert_input[];
  programs_to_delete_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProgramGroup
// ====================================================

export interface DeleteProgramGroup_delete_program {
  __typename: "program_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteProgramGroup_delete_program_group_by_pk {
  __typename: "program_group";
  id: uuid;
}

export interface DeleteProgramGroup {
  /**
   * delete data from the table: "program"
   */
  delete_program: DeleteProgramGroup_delete_program | null;
  /**
   * delete single row from the table: "program_group"
   */
  delete_program_group_by_pk: DeleteProgramGroup_delete_program_group_by_pk | null;
}

export interface DeleteProgramGroupVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProgramGroups
// ====================================================

export interface GetProgramGroups_program_group_programs {
  __typename: "program";
  id: uuid;
  label: citext;
  order: number;
}

export interface GetProgramGroups_program_group {
  __typename: "program_group";
  id: uuid;
  name: citext;
  organization_id: uuid;
  /**
   * An array relationship
   */
  programs: GetProgramGroups_program_group_programs[];
}

export interface GetProgramGroups {
  /**
   * fetch data from the table: "program_group"
   */
  program_group: GetProgramGroups_program_group[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProgramGroupByPk
// ====================================================

export interface GetProgramGroupByPk_program_group_by_pk_programs {
  __typename: "program";
  id: uuid;
  label: citext;
  order: number;
}

export interface GetProgramGroupByPk_program_group_by_pk {
  __typename: "program_group";
  id: uuid;
  name: citext;
  organization_id: uuid;
  /**
   * An array relationship
   */
  programs: GetProgramGroupByPk_program_group_by_pk_programs[];
}

export interface GetProgramGroupByPk {
  /**
   * fetch data from the table: "program_group" using primary key columns
   */
  program_group_by_pk: GetProgramGroupByPk_program_group_by_pk | null;
}

export interface GetProgramGroupByPkVariables {
  program_group_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListQuestionTypesForOrganization
// ====================================================

export interface ListQuestionTypesForOrganization_custom_question_type_organization {
  __typename: "organization";
  id: uuid;
  name: string;
}

export interface ListQuestionTypesForOrganization_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  key: string | null;
  /**
   * An object relationship
   */
  organization: ListQuestionTypesForOrganization_custom_question_type_organization;
}

export interface ListQuestionTypesForOrganization {
  /**
   * fetch data from the table: "custom_question_type"
   */
  custom_question_type: ListQuestionTypesForOrganization_custom_question_type[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestionType
// ====================================================

export interface GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options {
  __typename: "form_question_option";
  label: string;
  value: string | null;
}

export interface GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question {
  __typename: "form_question";
  /**
   * An array relationship
   */
  form_question_options: GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question_form_question_options[];
}

export interface GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question {
  __typename: "question";
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  /**
   * An object relationship
   */
  form_question: GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question_form_question | null;
}

export interface GetQuestionType_custom_question_type_by_pk_custom_question_type_fields {
  __typename: "custom_question_type_field";
  /**
   * An object relationship
   */
  question: GetQuestionType_custom_question_type_by_pk_custom_question_type_fields_question;
}

export interface GetQuestionType_custom_question_type_by_pk {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  organization_id: uuid;
  key: string | null;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetQuestionType_custom_question_type_by_pk_custom_question_type_fields[];
}

export interface GetQuestionType {
  /**
   * fetch data from the table: "custom_question_type" using primary key columns
   */
  custom_question_type_by_pk: GetQuestionType_custom_question_type_by_pk | null;
}

export interface GetQuestionTypeVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateQuestionType
// ====================================================

export interface CreateQuestionType_insert_custom_question_type_one {
  __typename: "custom_question_type";
  id: uuid;
}

export interface CreateQuestionType {
  /**
   * insert a single row into the table: "custom_question_type"
   */
  insert_custom_question_type_one: CreateQuestionType_insert_custom_question_type_one | null;
}

export interface CreateQuestionTypeVariables {
  question_type: custom_question_type_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateM2MCredential
// ====================================================

export interface CreateM2MCredential_create_m2m_credential {
  __typename: "CreateM2MCredentialOutput";
  message: string | null;
  status_code: number | null;
  client_id: string | null;
  client_secret: string | null;
}

export interface CreateM2MCredential {
  /**
   * Create an m2m credetial via the account management API
   */
  create_m2m_credential: CreateM2MCredential_create_m2m_credential | null;
}

export interface CreateM2MCredentialVariables {
  client_name: string;
  organization_id: string;
  user_group_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCapacities
// ====================================================

export interface UpdateCapacities_update_grade_many {
  __typename: "grade_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateCapacities_update_bin_many {
  __typename: "bin_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateCapacities {
  /**
   * update multiples rows of table: "grade"
   */
  update_grade_many: (UpdateCapacities_update_grade_many | null)[] | null;
  /**
   * update multiples rows of table: "bin"
   */
  update_bin_many: (UpdateCapacities_update_bin_many | null)[] | null;
}

export interface UpdateCapacitiesVariables {
  gradeUpdates: grade_updates[];
  binUpdates: bin_updates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForEnrollmentPeriod
// ====================================================

export interface GetSchoolsForEnrollmentPeriod_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetSchoolsForEnrollmentPeriod {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsForEnrollmentPeriod_school[];
}

export interface GetSchoolsForEnrollmentPeriodVariables {
  enrollment_period_id: uuid;
  school_filter: school_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolGradeCapacity
// ====================================================

export interface GetSchoolGradeCapacity_grade_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetSchoolGradeCapacity_grade_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
  order: number;
}

export interface GetSchoolGradeCapacity_grade_program {
  __typename: "program";
  label: citext;
  id: uuid;
  order: number;
}

export interface GetSchoolGradeCapacity_grade_offered_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface GetSchoolGradeCapacity_grade_offered_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: GetSchoolGradeCapacity_grade_offered_offers_aggregate_aggregate | null;
}

export interface GetSchoolGradeCapacity_grade_accepted_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface GetSchoolGradeCapacity_grade_accepted_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: GetSchoolGradeCapacity_grade_accepted_offers_aggregate_aggregate | null;
}

export interface GetSchoolGradeCapacity_grade_waitlists_aggregate_aggregate {
  __typename: "waitlist_aggregate_fields";
  count: number;
}

export interface GetSchoolGradeCapacity_grade_waitlists_aggregate {
  __typename: "waitlist_aggregate";
  aggregate: GetSchoolGradeCapacity_grade_waitlists_aggregate_aggregate | null;
}

export interface GetSchoolGradeCapacity_grade {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  capacity: number | null;
  seats_taken: number | null;
  seats_available: number | null;
  /**
   * An object relationship
   */
  school: GetSchoolGradeCapacity_grade_school;
  /**
   * An object relationship
   */
  grade_config: GetSchoolGradeCapacity_grade_grade_config;
  /**
   * An object relationship
   */
  program: GetSchoolGradeCapacity_grade_program | null;
  /**
   * An aggregate relationship
   */
  offered_offers_aggregate: GetSchoolGradeCapacity_grade_offered_offers_aggregate;
  /**
   * An aggregate relationship
   */
  accepted_offers_aggregate: GetSchoolGradeCapacity_grade_accepted_offers_aggregate;
  /**
   * An aggregate relationship
   */
  waitlists_aggregate: GetSchoolGradeCapacity_grade_waitlists_aggregate;
}

export interface GetSchoolGradeCapacity_enrollment_period_by_pk_organization_program_groups {
  __typename: "program_group";
  id: uuid;
  name: citext;
}

export interface GetSchoolGradeCapacity_enrollment_period_by_pk_organization {
  __typename: "organization";
  /**
   * An array relationship
   */
  program_groups: GetSchoolGradeCapacity_enrollment_period_by_pk_organization_program_groups[];
}

export interface GetSchoolGradeCapacity_enrollment_period_by_pk {
  __typename: "enrollment_period";
  /**
   * An object relationship
   */
  organization: GetSchoolGradeCapacity_enrollment_period_by_pk_organization | null;
}

export interface GetSchoolGradeCapacity {
  /**
   * fetch data from the table: "grade"
   */
  grade: GetSchoolGradeCapacity_grade[];
  /**
   * fetch data from the table: "enrollment_period" using primary key columns
   */
  enrollment_period_by_pk: GetSchoolGradeCapacity_enrollment_period_by_pk | null;
}

export interface GetSchoolGradeCapacityVariables {
  enrollment_period_id: uuid;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCapacityForEnrollmentPeriod
// ====================================================

export interface GetAllCapacityForEnrollmentPeriod_grade_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
  order: number;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_program {
  __typename: "program";
  label: citext;
  id: uuid;
  order: number;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_offered_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_offered_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: GetAllCapacityForEnrollmentPeriod_grade_offered_offers_aggregate_aggregate | null;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_accepted_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_accepted_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: GetAllCapacityForEnrollmentPeriod_grade_accepted_offers_aggregate_aggregate | null;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_waitlists_aggregate_aggregate {
  __typename: "waitlist_aggregate_fields";
  count: number;
}

export interface GetAllCapacityForEnrollmentPeriod_grade_waitlists_aggregate {
  __typename: "waitlist_aggregate";
  aggregate: GetAllCapacityForEnrollmentPeriod_grade_waitlists_aggregate_aggregate | null;
}

export interface GetAllCapacityForEnrollmentPeriod_grade {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  capacity: number | null;
  seats_taken: number | null;
  seats_available: number | null;
  /**
   * An object relationship
   */
  school: GetAllCapacityForEnrollmentPeriod_grade_school;
  /**
   * An object relationship
   */
  grade_config: GetAllCapacityForEnrollmentPeriod_grade_grade_config;
  /**
   * An object relationship
   */
  program: GetAllCapacityForEnrollmentPeriod_grade_program | null;
  /**
   * An aggregate relationship
   */
  offered_offers_aggregate: GetAllCapacityForEnrollmentPeriod_grade_offered_offers_aggregate;
  /**
   * An aggregate relationship
   */
  accepted_offers_aggregate: GetAllCapacityForEnrollmentPeriod_grade_accepted_offers_aggregate;
  /**
   * An aggregate relationship
   */
  waitlists_aggregate: GetAllCapacityForEnrollmentPeriod_grade_waitlists_aggregate;
}

export interface GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk_organization_program_groups {
  __typename: "program_group";
  id: uuid;
  name: citext;
}

export interface GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk_organization {
  __typename: "organization";
  /**
   * An array relationship
   */
  program_groups: GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk_organization_program_groups[];
}

export interface GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk {
  __typename: "enrollment_period";
  /**
   * An object relationship
   */
  organization: GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk_organization | null;
}

export interface GetAllCapacityForEnrollmentPeriod {
  /**
   * fetch data from the table: "grade"
   */
  grade: GetAllCapacityForEnrollmentPeriod_grade[];
  /**
   * fetch data from the table: "enrollment_period" using primary key columns
   */
  enrollment_period_by_pk: GetAllCapacityForEnrollmentPeriod_enrollment_period_by_pk | null;
}

export interface GetAllCapacityForEnrollmentPeriodVariables {
  enrollment_period_id: uuid;
  school_filter: school_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFeedData
// ====================================================

export interface GetFeedData_audit_form_transaction_logged_actions_user_people {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
  person_type: person_type_enum | null;
}

export interface GetFeedData_audit_form_transaction_logged_actions_user {
  __typename: "user";
  /**
   * An array relationship
   */
  people: GetFeedData_audit_form_transaction_logged_actions_user_people[];
}

export interface GetFeedData_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * An object relationship
   */
  user: GetFeedData_audit_form_transaction_logged_actions_user | null;
  /**
   * Action type; I = insert, D = delete, U = update, T = truncate
   */
  action: string;
  /**
   * Wall clock time at which audited event's trigger call occurred
   */
  action_tstamp_clk: timestamptz;
  /**
   * Non-schema-qualified table name of table event occured in
   */
  table_name: string;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
  /**
   * New values of fields changed by UPDATE. Null except for row-level UPDATE events.
   */
  changed_fields: jsonb | null;
}

export interface GetFeedData_audit_form_transaction {
  __typename: "audit_form_transaction";
  transaction_id: bigint | null;
  action_tstamp_tx: timestamptz | null;
  /**
   * An array relationship
   */
  logged_actions: GetFeedData_audit_form_transaction_logged_actions[];
}

export interface GetFeedData_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetFeedData_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFeedData_grade_program {
  __typename: "program";
  id: uuid;
  label: citext;
}

export interface GetFeedData_grade {
  __typename: "grade";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetFeedData_grade_grade_config;
  /**
   * An object relationship
   */
  program: GetFeedData_grade_program | null;
}

export interface GetFeedData_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetFeedData {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetFeedData_audit_form_transaction[];
  /**
   * fetch data from the table: "school"
   */
  school: GetFeedData_school[];
  /**
   * fetch data from the table: "grade"
   */
  grade: GetFeedData_grade[];
  /**
   * fetch data from the table: "enrollment_period_tag"
   */
  enrollment_period_tag: GetFeedData_enrollment_period_tag[];
}

export interface GetFeedDataVariables {
  enrollment_period_id: uuid;
  organization_id: uuid;
  begin_at: timestamptz;
  end_at: timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetParentProcessedFormStat
// ====================================================

export interface GetParentProcessedFormStat_current_period_aggregate {
  __typename: "form_tracking_dates_aggregate_fields";
  count: number;
}

export interface GetParentProcessedFormStat_current_period {
  __typename: "form_tracking_dates_aggregate";
  aggregate: GetParentProcessedFormStat_current_period_aggregate | null;
}

export interface GetParentProcessedFormStat_previous_period_aggregate {
  __typename: "form_tracking_dates_aggregate_fields";
  count: number;
}

export interface GetParentProcessedFormStat_previous_period {
  __typename: "form_tracking_dates_aggregate";
  aggregate: GetParentProcessedFormStat_previous_period_aggregate | null;
}

export interface GetParentProcessedFormStat {
  /**
   * fetch aggregated fields from the table: "form_tracking_dates"
   */
  current_period: GetParentProcessedFormStat_current_period;
  /**
   * fetch aggregated fields from the table: "form_tracking_dates"
   */
  previous_period: GetParentProcessedFormStat_previous_period;
}

export interface GetParentProcessedFormStatVariables {
  current_begin_at: timestamptz;
  current_end_at: timestamptz;
  previous_begin_at: timestamptz;
  previous_end_at: timestamptz;
  enrollment_period_id: uuid;
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdminProcessedFormStat
// ====================================================

export interface GetAdminProcessedFormStat_current_period_aggregate {
  __typename: "form_tracking_dates_aggregate_fields";
  count: number;
}

export interface GetAdminProcessedFormStat_current_period {
  __typename: "form_tracking_dates_aggregate";
  aggregate: GetAdminProcessedFormStat_current_period_aggregate | null;
}

export interface GetAdminProcessedFormStat_previous_period_aggregate {
  __typename: "form_tracking_dates_aggregate_fields";
  count: number;
}

export interface GetAdminProcessedFormStat_previous_period {
  __typename: "form_tracking_dates_aggregate";
  aggregate: GetAdminProcessedFormStat_previous_period_aggregate | null;
}

export interface GetAdminProcessedFormStat {
  /**
   * fetch aggregated fields from the table: "form_tracking_dates"
   */
  current_period: GetAdminProcessedFormStat_current_period;
  /**
   * fetch aggregated fields from the table: "form_tracking_dates"
   */
  previous_period: GetAdminProcessedFormStat_previous_period;
}

export interface GetAdminProcessedFormStatVariables {
  current_begin_at: timestamptz;
  current_end_at: timestamptz;
  previous_begin_at: timestamptz;
  previous_end_at: timestamptz;
  enrollment_period_id: uuid;
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEnrollment
// ====================================================

export interface CreateEnrollment_insert_enrollment_period_one {
  __typename: "enrollment_period";
  id: uuid;
}

export interface CreateEnrollment {
  /**
   * insert a single row into the table: "enrollment_period"
   */
  insert_enrollment_period_one: CreateEnrollment_insert_enrollment_period_one | null;
}

export interface CreateEnrollmentVariables {
  enrollment: enrollment_period_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDefaultInternalTagGroup
// ====================================================

export interface CreateDefaultInternalTagGroup_insert_tag_group_one {
  __typename: "tag_group";
  id: uuid;
}

export interface CreateDefaultInternalTagGroup {
  /**
   * insert a single row into the table: "tag_group"
   */
  insert_tag_group_one: CreateDefaultInternalTagGroup_insert_tag_group_one | null;
}

export interface CreateDefaultInternalTagGroupVariables {
  enrollment_period_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEnrollmentPeriodInfoById
// ====================================================

export interface UpdateEnrollmentPeriodInfoById_update_enrollment_period_by_pk {
  __typename: "enrollment_period";
  id: uuid;
}

export interface UpdateEnrollmentPeriodInfoById {
  /**
   * update single row of the table: "enrollment_period"
   */
  update_enrollment_period_by_pk: UpdateEnrollmentPeriodInfoById_update_enrollment_period_by_pk | null;
}

export interface UpdateEnrollmentPeriodInfoByIdVariables {
  id: uuid;
  enrollment_period_info: enrollment_period_set_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEnrollment
// ====================================================

export interface GetEnrollment_enrollment_period_by_pk_form_templates_aggregate_aggregate {
  __typename: "form_template_aggregate_fields";
  count: number;
}

export interface GetEnrollment_enrollment_period_by_pk_form_templates_aggregate {
  __typename: "form_template_aggregate";
  aggregate: GetEnrollment_enrollment_period_by_pk_form_templates_aggregate_aggregate | null;
}

export interface GetEnrollment_enrollment_period_by_pk {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
  key: string | null;
  /**
   * An aggregate relationship
   */
  form_templates_aggregate: GetEnrollment_enrollment_period_by_pk_form_templates_aggregate;
}

export interface GetEnrollment {
  /**
   * fetch data from the table: "enrollment_period" using primary key columns
   */
  enrollment_period_by_pk: GetEnrollment_enrollment_period_by_pk | null;
}

export interface GetEnrollmentVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEnrollments
// ====================================================

export interface GetEnrollments_enrollment_period_form_templates_aggregate_aggregate {
  __typename: "form_template_aggregate_fields";
  count: number;
}

export interface GetEnrollments_enrollment_period_form_templates_aggregate {
  __typename: "form_template_aggregate";
  aggregate: GetEnrollments_enrollment_period_form_templates_aggregate_aggregate | null;
}

export interface GetEnrollments_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
  /**
   * An aggregate relationship
   */
  form_templates_aggregate: GetEnrollments_enrollment_period_form_templates_aggregate;
}

export interface GetEnrollments {
  /**
   * fetch data from the table: "enrollment_period"
   */
  enrollment_period: GetEnrollments_enrollment_period[];
}

export interface GetEnrollmentsVariables {
  organization_id?: uuid | null;
  order_by: enrollment_period_order_by;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkInsertGradePrograms
// ====================================================

export interface BulkInsertGradePrograms_insert_grade {
  __typename: "grade_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkInsertGradePrograms {
  /**
   * insert data into the table: "grade"
   */
  insert_grade: BulkInsertGradePrograms_insert_grade | null;
}

export interface BulkInsertGradeProgramsVariables {
  grade_programs: grade_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGradeByPk
// ====================================================

export interface DeleteGradeByPk_delete_grade_by_pk {
  __typename: "grade";
  id: uuid;
}

export interface DeleteGradeByPk_delete_bin_by_pk {
  __typename: "bin";
  id: uuid;
}

export interface DeleteGradeByPk {
  /**
   * delete single row from the table: "grade"
   */
  delete_grade_by_pk: DeleteGradeByPk_delete_grade_by_pk | null;
  /**
   * delete single row from the table: "bin"
   */
  delete_bin_by_pk: DeleteGradeByPk_delete_bin_by_pk | null;
}

export interface DeleteGradeByPkVariables {
  id: uuid;
  binId: uuid;
  skipBin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkInsertGradesGroups
// ====================================================

export interface BulkInsertGradesGroups_insert_grades_group {
  __typename: "grades_group_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkInsertGradesGroups {
  /**
   * insert data into the table: "grades_group"
   */
  insert_grades_group: BulkInsertGradesGroups_insert_grades_group | null;
}

export interface BulkInsertGradesGroupsVariables {
  grades_groups: grades_group_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGradesGroups
// ====================================================

export interface DeleteGradesGroups_delete_grade {
  __typename: "grade_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteGradesGroups_delete_grades_group {
  __typename: "grades_group_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteGradesGroups {
  /**
   * delete data from the table: "grade"
   */
  delete_grade: DeleteGradesGroups_delete_grade | null;
  /**
   * delete data from the table: "grades_group"
   */
  delete_grades_group: DeleteGradesGroups_delete_grades_group | null;
}

export interface DeleteGradesGroupsVariables {
  grades_group_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEnrollmentSchoolsAndConfigs
// ====================================================

export interface GetEnrollmentSchoolsAndConfigs_school_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetEnrollmentSchoolsAndConfigs_school_grades {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetEnrollmentSchoolsAndConfigs_school_grades_grade_config;
}

export interface GetEnrollmentSchoolsAndConfigs_school {
  __typename: "school";
  /**
   * An array relationship
   */
  grades: GetEnrollmentSchoolsAndConfigs_school_grades[];
  name: string;
  id: uuid;
  status: string;
}

export interface GetEnrollmentSchoolsAndConfigs_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  organization_id: uuid;
  order: number;
}

export interface GetEnrollmentSchoolsAndConfigs_program_group_programs {
  __typename: "program";
  id: uuid;
  label: citext;
  order: number;
}

export interface GetEnrollmentSchoolsAndConfigs_program_group {
  __typename: "program_group";
  id: uuid;
  name: citext;
  organization_id: uuid;
  /**
   * An array relationship
   */
  programs: GetEnrollmentSchoolsAndConfigs_program_group_programs[];
}

export interface GetEnrollmentSchoolsAndConfigs {
  /**
   * fetch data from the table: "school"
   */
  school: GetEnrollmentSchoolsAndConfigs_school[];
  /**
   * fetch data from the table: "grade_config"
   */
  grade_config: GetEnrollmentSchoolsAndConfigs_grade_config[];
  /**
   * fetch data from the table: "program_group"
   */
  program_group: GetEnrollmentSchoolsAndConfigs_program_group[];
}

export interface GetEnrollmentSchoolsAndConfigsVariables {
  organization_id?: uuid | null;
  order_by: school_order_by;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsAndGradesForEnrollmentPeriod
// ====================================================

export interface GetSchoolsAndGradesForEnrollmentPeriod_school_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetSchoolsAndGradesForEnrollmentPeriod_school_grades {
  __typename: "grade";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetSchoolsAndGradesForEnrollmentPeriod_school_grades_grade_config;
}

export interface GetSchoolsAndGradesForEnrollmentPeriod_school {
  __typename: "school";
  name: string;
  id: uuid;
  status: string;
  /**
   * An array relationship
   */
  grades: GetSchoolsAndGradesForEnrollmentPeriod_school_grades[];
}

export interface GetSchoolsAndGradesForEnrollmentPeriod {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsAndGradesForEnrollmentPeriod_school[];
}

export interface GetSchoolsAndGradesForEnrollmentPeriodVariables {
  organization_id?: uuid | null;
  enrollment_period_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGradeProgramsByEnrollmentSchool
// ====================================================

export interface GetGradeProgramsByEnrollmentSchool_school_by_pk_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetGradeProgramsByEnrollmentSchool_school_by_pk_grades_bin {
  __typename: "bin";
  id: uuid;
}

export interface GetGradeProgramsByEnrollmentSchool_school_by_pk_grades {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetGradeProgramsByEnrollmentSchool_school_by_pk_grades_grade_config;
  /**
   * An object relationship
   */
  bin: GetGradeProgramsByEnrollmentSchool_school_by_pk_grades_bin | null;
}

export interface GetGradeProgramsByEnrollmentSchool_school_by_pk {
  __typename: "school";
  /**
   * An array relationship
   */
  grades: GetGradeProgramsByEnrollmentSchool_school_by_pk_grades[];
  name: string;
  id: uuid;
  status: string;
}

export interface GetGradeProgramsByEnrollmentSchool {
  /**
   * fetch data from the table: "school" using primary key columns
   */
  school_by_pk: GetGradeProgramsByEnrollmentSchool_school_by_pk | null;
}

export interface GetGradeProgramsByEnrollmentSchoolVariables {
  enrollment_period_id: uuid;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGradesGroupsByEnrollmentSchool
// ====================================================

export interface GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs_program {
  __typename: "program";
  id: uuid;
  label: citext;
  order: number;
}

export interface GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  school_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs_grade_config;
  /**
   * An object relationship
   */
  program: GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs_program | null;
}

export interface GetGradesGroupsByEnrollmentSchool_grades_group {
  __typename: "grades_group";
  id: uuid;
  enrollment_period_id: uuid;
  school_id: uuid;
  /**
   * An array relationship
   */
  grade_programs: GetGradesGroupsByEnrollmentSchool_grades_group_grade_programs[];
}

export interface GetGradesGroupsByEnrollmentSchool {
  /**
   * fetch data from the table: "grades_group"
   */
  grades_group: GetGradesGroupsByEnrollmentSchool_grades_group[];
}

export interface GetGradesGroupsByEnrollmentSchoolVariables {
  enrollment_period_id: uuid;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMessageTemplate
// ====================================================

export interface UpdateMessageTemplate_insert_message_template_one {
  __typename: "message_template";
  id: uuid;
  enabled: boolean;
}

export interface UpdateMessageTemplate {
  /**
   * insert a single row into the table: "message_template"
   */
  insert_message_template_one: UpdateMessageTemplate_insert_message_template_one | null;
}

export interface UpdateMessageTemplateVariables {
  objects: message_template_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMessageTemplates
// ====================================================

export interface UpdateMessageTemplates_insert_message_template_returning {
  __typename: "message_template";
  id: uuid;
  type: message_template_type_enum;
}

export interface UpdateMessageTemplates_insert_message_template {
  __typename: "message_template_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdateMessageTemplates_insert_message_template_returning[];
}

export interface UpdateMessageTemplates_update_message_template_many {
  __typename: "message_template_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateMessageTemplates {
  /**
   * insert data into the table: "message_template"
   */
  insert_message_template: UpdateMessageTemplates_insert_message_template | null;
  /**
   * update multiples rows of table: "message_template"
   */
  update_message_template_many: (UpdateMessageTemplates_update_message_template_many | null)[] | null;
}

export interface UpdateMessageTemplatesVariables {
  messageTemplateInserts?: message_template_insert_input[] | null;
  messageTemplateUpdates?: message_template_updates[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMessageTemplateTypes
// ====================================================

export interface GetMessageTemplateTypes_message_template_type {
  __typename: "message_template_type";
  value: string;
}

export interface GetMessageTemplateTypes_message_template {
  __typename: "message_template";
  id: uuid;
  type: message_template_type_enum;
  enabled: boolean;
}

export interface GetMessageTemplateTypes_form_template_by_pk_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  ranking_enabled: boolean;
}

export interface GetMessageTemplateTypes_form_template_by_pk_sections {
  __typename: "form_template_section";
  /**
   * An object relationship
   */
  schools_ranking_section: GetMessageTemplateTypes_form_template_by_pk_sections_schools_ranking_section | null;
}

export interface GetMessageTemplateTypes_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  lottery_offers_enabled: boolean;
  /**
   * An array relationship
   */
  sections: GetMessageTemplateTypes_form_template_by_pk_sections[];
}

export interface GetMessageTemplateTypes {
  /**
   * fetch data from the table: "message_template_type"
   */
  message_template_type: GetMessageTemplateTypes_message_template_type[];
  /**
   * fetch data from the table: "message_template"
   */
  message_template: GetMessageTemplateTypes_message_template[];
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetMessageTemplateTypes_form_template_by_pk | null;
}

export interface GetMessageTemplateTypesVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMessageTemplate
// ====================================================

export interface GetMessageTemplate_message_template {
  __typename: "message_template";
  id: uuid;
  type: message_template_type_enum;
  email_subject: string;
  email_markup: string;
  email_plain_text: string;
  sms_body: string;
  enabled: boolean;
}

export interface GetMessageTemplate_form_template_by_pk_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  ranking_enabled: boolean;
}

export interface GetMessageTemplate_form_template_by_pk_sections {
  __typename: "form_template_section";
  /**
   * An object relationship
   */
  schools_ranking_section: GetMessageTemplate_form_template_by_pk_sections_schools_ranking_section | null;
}

export interface GetMessageTemplate_form_template_by_pk {
  __typename: "form_template";
  /**
   * An array relationship
   */
  sections: GetMessageTemplate_form_template_by_pk_sections[];
}

export interface GetMessageTemplate {
  /**
   * fetch data from the table: "message_template"
   */
  message_template: GetMessageTemplate_message_template[];
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetMessageTemplate_form_template_by_pk | null;
}

export interface GetMessageTemplateVariables {
  formTemplateId: uuid;
  templateType: message_template_type_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFormTemplate
// ====================================================

export interface CreateFormTemplate_insert_form_template_one {
  __typename: "form_template";
  id: uuid;
}

export interface CreateFormTemplate {
  /**
   * insert a single row into the table: "form_template"
   */
  insert_form_template_one: CreateFormTemplate_insert_form_template_one | null;
}

export interface CreateFormTemplateVariables {
  form_template: form_template_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertFormVerifications
// ====================================================

export interface InsertFormVerifications_insert_form_verification_returning {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface InsertFormVerifications_insert_form_verification {
  __typename: "form_verification_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: InsertFormVerifications_insert_form_verification_returning[];
}

export interface InsertFormVerifications {
  /**
   * insert data into the table: "form_verification"
   */
  insert_form_verification: InsertFormVerifications_insert_form_verification | null;
}

export interface InsertFormVerificationsVariables {
  inserts: form_verification_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateQuestionsAndSections
// ====================================================

export interface UpdateQuestionsAndSections_update_question_many_returning {
  __typename: "question";
  id: uuid;
  question: string;
}

export interface UpdateQuestionsAndSections_update_question_many {
  __typename: "question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdateQuestionsAndSections_update_question_many_returning[];
}

export interface UpdateQuestionsAndSections_update_form_question_many_returning_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface UpdateQuestionsAndSections_update_form_question_many_returning {
  __typename: "form_question";
  question_id: uuid;
  /**
   * An object relationship
   */
  form_verification: UpdateQuestionsAndSections_update_form_question_many_returning_form_verification | null;
}

export interface UpdateQuestionsAndSections_update_form_question_many {
  __typename: "form_question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdateQuestionsAndSections_update_form_question_many_returning[];
}

export interface UpdateQuestionsAndSections_update_disclaimer_section_many_returning {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface UpdateQuestionsAndSections_update_disclaimer_section_many {
  __typename: "disclaimer_section_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: UpdateQuestionsAndSections_update_disclaimer_section_many_returning[];
}

export interface UpdateQuestionsAndSections_update_schools_ranking_section_many {
  __typename: "schools_ranking_section_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_delete_eligibility_question_school {
  __typename: "eligibility_question_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_insert_eligibility_question_school {
  __typename: "eligibility_question_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_insert_form_question_option {
  __typename: "form_question_option_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_update_form_question_option_many {
  __typename: "form_question_option_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_insert_question {
  __typename: "question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_update_form_template_section_many {
  __typename: "form_template_section_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_insert_form_template_section {
  __typename: "form_template_section_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_insert_additional_question {
  __typename: "additional_question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections_update_grades_question_many {
  __typename: "grades_question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateQuestionsAndSections {
  /**
   * update multiples rows of table: "question"
   */
  update_question_many: (UpdateQuestionsAndSections_update_question_many | null)[] | null;
  /**
   * update multiples rows of table: "form_question"
   */
  update_form_question_many: (UpdateQuestionsAndSections_update_form_question_many | null)[] | null;
  /**
   * update multiples rows of table: "disclaimer_section"
   */
  update_disclaimer_section_many: (UpdateQuestionsAndSections_update_disclaimer_section_many | null)[] | null;
  /**
   * update multiples rows of table: "schools_ranking_section"
   */
  update_schools_ranking_section_many: (UpdateQuestionsAndSections_update_schools_ranking_section_many | null)[] | null;
  /**
   * delete data from the table: "eligibility_question_school"
   */
  delete_eligibility_question_school: UpdateQuestionsAndSections_delete_eligibility_question_school | null;
  /**
   * insert data into the table: "eligibility_question_school"
   */
  insert_eligibility_question_school: UpdateQuestionsAndSections_insert_eligibility_question_school | null;
  /**
   * insert data into the table: "form_question_option"
   */
  insert_form_question_option: UpdateQuestionsAndSections_insert_form_question_option | null;
  /**
   * update multiples rows of table: "form_question_option"
   */
  update_form_question_option_many: (UpdateQuestionsAndSections_update_form_question_option_many | null)[] | null;
  /**
   * insert data into the table: "question"
   */
  insert_question: UpdateQuestionsAndSections_insert_question | null;
  /**
   * update multiples rows of table: "form_template_section"
   */
  update_form_template_section_many: (UpdateQuestionsAndSections_update_form_template_section_many | null)[] | null;
  /**
   * insert data into the table: "form_template_section"
   */
  insert_form_template_section: UpdateQuestionsAndSections_insert_form_template_section | null;
  /**
   * insert data into the table: "additional_question"
   */
  insert_additional_question: UpdateQuestionsAndSections_insert_additional_question | null;
  /**
   * update multiples rows of table: "grades_question"
   */
  update_grades_question_many: (UpdateQuestionsAndSections_update_grades_question_many | null)[] | null;
}

export interface UpdateQuestionsAndSectionsVariables {
  eligibilityQuestionSchoolsDeletes?: eligibility_question_school_bool_exp | null;
  eligibilityQuestionSchoolsInserts?: eligibility_question_school_insert_input[] | null;
  questionUpdates?: question_updates[] | null;
  disclaimerUpdates?: disclaimer_section_updates[] | null;
  schoolRankingUpdates?: schools_ranking_section_updates[] | null;
  formQuestionUpdates?: form_question_updates[] | null;
  questionOptionInserts?: form_question_option_insert_input[] | null;
  questionOptionUpdates?: form_question_option_updates[] | null;
  questionInserts?: question_insert_input[] | null;
  sectionInserts?: form_template_section_insert_input[] | null;
  sectionUpdates?: form_template_section_updates[] | null;
  additionalQuestionInserts?: additional_question_insert_input[] | null;
  gradesQuestionUpdates?: grades_question_updates[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormStatusDescriptions
// ====================================================

export interface UpsertFormStatusDescriptions_insert_form_status_description {
  __typename: "form_status_description_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormStatusDescriptions {
  /**
   * insert data into the table: "form_status_description"
   */
  insert_form_status_description: UpsertFormStatusDescriptions_insert_form_status_description | null;
}

export interface UpsertFormStatusDescriptionsVariables {
  objects: form_status_description_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormTemplateSettingsById
// ====================================================

export interface UpdateFormTemplateSettingsById_insert_form_template_rule {
  __typename: "form_template_rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById_insert_rule {
  __typename: "rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById_insert_status_rule {
  __typename: "status_rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById_delete_status_rule {
  __typename: "status_rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById_delete_rule {
  __typename: "rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById_update_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
}

export interface UpdateFormTemplateSettingsById_delete_form_template_rule {
  __typename: "form_template_rule_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormTemplateSettingsById {
  /**
   * insert data into the table: "form_template_rule"
   */
  insert_form_template_rule: UpdateFormTemplateSettingsById_insert_form_template_rule | null;
  /**
   * insert data into the table: "rule"
   */
  insert_rule: UpdateFormTemplateSettingsById_insert_rule | null;
  /**
   * insert data into the table: "status_rule"
   */
  insert_status_rule: UpdateFormTemplateSettingsById_insert_status_rule | null;
  /**
   * delete data from the table: "status_rule"
   */
  delete_status_rule: UpdateFormTemplateSettingsById_delete_status_rule | null;
  /**
   * delete data from the table: "rule"
   */
  delete_rule: UpdateFormTemplateSettingsById_delete_rule | null;
  /**
   * update single row of the table: "form_template"
   */
  update_form_template_by_pk: UpdateFormTemplateSettingsById_update_form_template_by_pk | null;
  /**
   * delete data from the table: "form_template_rule"
   */
  delete_form_template_rule: UpdateFormTemplateSettingsById_delete_form_template_rule | null;
}

export interface UpdateFormTemplateSettingsByIdVariables {
  id: uuid;
  form_template_settings: form_template_set_input;
  upsert_form_template_rule?: form_template_rule_insert_input[] | null;
  upsert_rules?: rule_insert_input[] | null;
  upsert_status_rules?: status_rule_insert_input[] | null;
  deleted_rules?: uuid[] | null;
  deleted_form_template_rules?: uuid[] | null;
  skipRules: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormTemplateActiveStatus
// ====================================================

export interface UpdateFormTemplateActiveStatus_update_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
}

export interface UpdateFormTemplateActiveStatus {
  /**
   * update single row of the table: "form_template"
   */
  update_form_template_by_pk: UpdateFormTemplateActiveStatus_update_form_template_by_pk | null;
}

export interface UpdateFormTemplateActiveStatusVariables {
  id: uuid;
  form_template_status: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplateById
// ====================================================

export interface GetFormTemplateById_form_template_by_pk_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetFormTemplateById_form_template_by_pk_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetFormTemplateById_form_template_by_pk_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormTemplateById_form_template_by_pk_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormTemplateById_form_template_by_pk_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetFormTemplateById_form_template_by_pk_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetFormTemplateById_form_template_by_pk_sections_questions_custom_question | null;
}

export interface GetFormTemplateById_form_template_by_pk_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetFormTemplateById_form_template_by_pk_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetFormTemplateById_form_template_by_pk_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetFormTemplateById_form_template_by_pk_sections_questions[];
}

export interface GetFormTemplateById_form_template_by_pk_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormTemplateById_form_template_by_pk_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
  organization_id: uuid | null;
}

export interface GetFormTemplateById_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetFormTemplateById_form_template_by_pk_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetFormTemplateById_form_template_by_pk_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: GetFormTemplateById_form_template_by_pk_enrollment_period;
  closed_at: timestamptz | null;
}

export interface GetFormTemplateById {
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetFormTemplateById_form_template_by_pk | null;
}

export interface GetFormTemplateByIdVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplateFormsCount
// ====================================================

export interface GetFormTemplateFormsCount_form_template_by_pk_forms_aggregate_aggregate {
  __typename: "form_aggregate_fields";
  count: number;
}

export interface GetFormTemplateFormsCount_form_template_by_pk_forms_aggregate {
  __typename: "form_aggregate";
  aggregate: GetFormTemplateFormsCount_form_template_by_pk_forms_aggregate_aggregate | null;
}

export interface GetFormTemplateFormsCount_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  /**
   * An aggregate relationship
   */
  forms_aggregate: GetFormTemplateFormsCount_form_template_by_pk_forms_aggregate;
}

export interface GetFormTemplateFormsCount {
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetFormTemplateFormsCount_form_template_by_pk | null;
}

export interface GetFormTemplateFormsCountVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplateOrganization
// ====================================================

export interface GetFormTemplateOrganization_organization {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
}

export interface GetFormTemplateOrganization {
  /**
   * fetch data from the table: "organization"
   */
  organization: GetFormTemplateOrganization_organization[];
}

export interface GetFormTemplateOrganizationVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormVerifications
// ====================================================

export interface GetFormVerifications_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormVerifications {
  /**
   * fetch data from the table: "form_verification"
   */
  form_verification: GetFormVerifications_form_verification[];
}

export interface GetFormVerificationsVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForFormTemplateEdit
// ====================================================

export interface GetSchoolsForFormTemplateEdit_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetSchoolsForFormTemplateEdit {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsForFormTemplateEdit_school[];
}

export interface GetSchoolsForFormTemplateEditVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGradesConfigByOrganization
// ====================================================

export interface GetGradesConfigByOrganization_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
  order: number;
}

export interface GetGradesConfigByOrganization {
  /**
   * fetch data from the table: "grade_config"
   */
  grade_config: GetGradesConfigByOrganization_grade_config[];
}

export interface GetGradesConfigByOrganizationVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestionTypesByOrganization
// ====================================================

export interface GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  label: string;
  order: number;
  translate_options: boolean | null;
  value: string | null;
}

export interface GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question_form_question_form_question_options[];
}

export interface GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question {
  __typename: "question";
  id: uuid;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_text: string | null;
  link_url: string | null;
  order: number;
  permission_level: string | null;
  question: string;
  /**
   * An object relationship
   */
  form_question: GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question_form_question | null;
}

export interface GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields_question;
}

export interface GetQuestionTypesByOrganization_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetQuestionTypesByOrganization_custom_question_type_custom_question_type_fields[];
}

export interface GetQuestionTypesByOrganization {
  /**
   * fetch data from the table: "custom_question_type"
   */
  custom_question_type: GetQuestionTypesByOrganization_custom_question_type[];
}

export interface GetQuestionTypesByOrganizationVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchFormTemplateStatuses
// ====================================================

export interface FetchFormTemplateStatuses_form_status_description {
  __typename: "form_status_description";
  id: uuid;
  form_template_id: uuid | null;
  status: form_status_enum;
  description: string | null;
}

export interface FetchFormTemplateStatuses {
  /**
   * fetch data from the table: "form_status_description"
   */
  form_status_description: FetchFormTemplateStatuses_form_status_description[];
}

export interface FetchFormTemplateStatusesVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchFormStatusesByIdStatus
// ====================================================

export interface FetchFormStatusesByIdStatus_form_status_description {
  __typename: "form_status_description";
  id: uuid;
  form_template_id: uuid | null;
  status: form_status_enum;
  description: string | null;
}

export interface FetchFormStatusesByIdStatus {
  /**
   * fetch data from the table: "form_status_description"
   */
  form_status_description: FetchFormStatusesByIdStatus_form_status_description[];
}

export interface FetchFormStatusesByIdStatusVariables {
  form_template_id: uuid;
  form_status?: form_status_enum[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplateSettingsById
// ====================================================

export interface GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules_status_rule {
  __typename: "status_rule";
  next_form_template_id: uuid;
  form_status: form_status_enum | null;
  offer_status: offer_status_enum | null;
  type: status_rule_type_enum;
  waitlist_status: waitlist_status_enum | null;
}

export interface GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules {
  __typename: "rule";
  id: uuid;
  type: rule_type_enum;
  /**
   * An object relationship
   */
  status_rule: GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules_status_rule | null;
}

export interface GetFormTemplateSettingsById_form_template_by_pk_form_template_rule {
  __typename: "form_template_rule";
  id: uuid;
  /**
   * An array relationship
   */
  rules: GetFormTemplateSettingsById_form_template_by_pk_form_template_rule_rules[];
}

export interface GetFormTemplateSettingsById_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  name: string;
  key: string;
  description: string | null;
  open_at: timestamptz | null;
  closed_at: timestamptz | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  reopen_at: timestamptz | null;
  active: boolean;
  locked: boolean;
  /**
   * An object relationship
   */
  form_template_rule: GetFormTemplateSettingsById_form_template_by_pk_form_template_rule | null;
}

export interface GetFormTemplateSettingsById {
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetFormTemplateSettingsById_form_template_by_pk | null;
}

export interface GetFormTemplateSettingsByIdVariables {
  form_template_id: uuid;
  skip_form_template_rule: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAnswerAndDeleteSchoolsRank
// ====================================================

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_answer_returning {
  __typename: "form_answer";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_answer_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_grades_answer_returning {
  __typename: "grades_answer";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_grades_answer {
  __typename: "grades_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_grades_answer_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_address_returning {
  __typename: "form_address";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_address {
  __typename: "form_address_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_address_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_form_address {
  __typename: "form_address_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_custom_question_answer_returning {
  __typename: "custom_question_answer";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_custom_question_answer_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_update_person_answer_bank_returning {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_update_person_answer_bank {
  __typename: "person_answer_bank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_update_person_answer_bank_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer_bank_returning {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer_bank {
  __typename: "person_answer_bank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer_bank_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_delete_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface UpsertFormAnswerAndDeleteSchoolsRank_insert_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_school_rank_returning[];
}

export interface UpsertFormAnswerAndDeleteSchoolsRank {
  /**
   * delete data from the table: "custom_question_answer"
   */
  delete_custom_question_answer: UpsertFormAnswerAndDeleteSchoolsRank_delete_custom_question_answer | null;
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: UpsertFormAnswerAndDeleteSchoolsRank_delete_form_answer | null;
  /**
   * insert data into the table: "form_answer"
   */
  insert_form_answer: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_answer | null;
  /**
   * insert data into the table: "grades_answer"
   */
  insert_grades_answer: UpsertFormAnswerAndDeleteSchoolsRank_insert_grades_answer | null;
  /**
   * insert data into the table: "form_address"
   */
  insert_form_address: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_address | null;
  /**
   * delete data from the table: "form_address"
   */
  delete_form_address: UpsertFormAnswerAndDeleteSchoolsRank_delete_form_address | null;
  /**
   * insert data into the table: "custom_question_answer"
   */
  insert_custom_question_answer: UpsertFormAnswerAndDeleteSchoolsRank_insert_custom_question_answer | null;
  /**
   * delete data from the table: "person_answer"
   */
  delete_person_answer: UpsertFormAnswerAndDeleteSchoolsRank_delete_person_answer | null;
  /**
   * update data of the table: "person_answer_bank"
   */
  update_person_answer_bank: UpsertFormAnswerAndDeleteSchoolsRank_update_person_answer_bank | null;
  /**
   * insert data into the table: "person_answer"
   */
  insert_person_answer: UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer | null;
  /**
   * insert data into the table: "person_answer_bank"
   */
  insert_person_answer_bank: UpsertFormAnswerAndDeleteSchoolsRank_insert_person_answer_bank | null;
  /**
   * delete data from the table: "custom_question_answer_bank_relationship"
   */
  delete_custom_question_answer_bank_relationship: UpsertFormAnswerAndDeleteSchoolsRank_delete_custom_question_answer_bank_relationship | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: UpsertFormAnswerAndDeleteSchoolsRank_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: UpsertFormAnswerAndDeleteSchoolsRank_update_offer | null;
  /**
   * delete data from the table: "form_school_rank"
   */
  delete_form_school_rank: UpsertFormAnswerAndDeleteSchoolsRank_delete_form_school_rank | null;
  /**
   * insert data into the table: "form_school_rank"
   */
  insert_form_school_rank: UpsertFormAnswerAndDeleteSchoolsRank_insert_form_school_rank | null;
}

export interface UpsertFormAnswerAndDeleteSchoolsRankVariables {
  form_id: uuid;
  single_or_multi_select_question_ids: uuid[];
  answers: form_answer_insert_input[];
  grades_answers: grades_answer_insert_input[];
  address_answers: form_address_insert_input[];
  address_answer_deletes: form_address_bool_exp[];
  custom_question_ids: uuid[];
  custom_question_answers: custom_question_answer_insert_input[];
  answer_bank_ids: uuid[];
  answer_bank_answers: person_answer_insert_input[];
  new_answer_bank_entries: person_answer_bank_insert_input[];
  deleted_answers_custom_question_ids: uuid[];
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
  deleted_school_ranks: form_school_rank_bool_exp;
  upserted_school_ranks: form_school_rank_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAnswer
// ====================================================

export interface UpsertFormAnswer_delete_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer_insert_form_answer_returning {
  __typename: "form_answer";
  id: uuid;
}

export interface UpsertFormAnswer_insert_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_insert_form_answer_returning[];
}

export interface UpsertFormAnswer_insert_grades_answer_returning {
  __typename: "grades_answer";
  id: uuid;
}

export interface UpsertFormAnswer_insert_grades_answer {
  __typename: "grades_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_insert_grades_answer_returning[];
}

export interface UpsertFormAnswer_insert_form_address_returning {
  __typename: "form_address";
  id: uuid;
}

export interface UpsertFormAnswer_insert_form_address {
  __typename: "form_address_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_insert_form_address_returning[];
}

export interface UpsertFormAnswer_delete_form_address {
  __typename: "form_address_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer_insert_custom_question_answer_returning {
  __typename: "custom_question_answer";
  id: uuid;
}

export interface UpsertFormAnswer_insert_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_insert_custom_question_answer_returning[];
}

export interface UpsertFormAnswer_delete_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer_update_person_answer_bank_returning {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface UpsertFormAnswer_update_person_answer_bank {
  __typename: "person_answer_bank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_update_person_answer_bank_returning[];
}

export interface UpsertFormAnswer_insert_person_answer {
  __typename: "person_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer_insert_person_answer_bank_returning {
  __typename: "person_answer_bank";
  id: uuid;
}

export interface UpsertFormAnswer_insert_person_answer_bank {
  __typename: "person_answer_bank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: UpsertFormAnswer_insert_person_answer_bank_returning[];
}

export interface UpsertFormAnswer_delete_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertFormAnswer {
  /**
   * delete data from the table: "custom_question_answer"
   */
  delete_custom_question_answer: UpsertFormAnswer_delete_custom_question_answer | null;
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: UpsertFormAnswer_delete_form_answer | null;
  /**
   * insert data into the table: "form_answer"
   */
  insert_form_answer: UpsertFormAnswer_insert_form_answer | null;
  /**
   * insert data into the table: "grades_answer"
   */
  insert_grades_answer: UpsertFormAnswer_insert_grades_answer | null;
  /**
   * insert data into the table: "form_address"
   */
  insert_form_address: UpsertFormAnswer_insert_form_address | null;
  /**
   * delete data from the table: "form_address"
   */
  delete_form_address: UpsertFormAnswer_delete_form_address | null;
  /**
   * insert data into the table: "custom_question_answer"
   */
  insert_custom_question_answer: UpsertFormAnswer_insert_custom_question_answer | null;
  /**
   * delete data from the table: "person_answer"
   */
  delete_person_answer: UpsertFormAnswer_delete_person_answer | null;
  /**
   * update data of the table: "person_answer_bank"
   */
  update_person_answer_bank: UpsertFormAnswer_update_person_answer_bank | null;
  /**
   * insert data into the table: "person_answer"
   */
  insert_person_answer: UpsertFormAnswer_insert_person_answer | null;
  /**
   * insert data into the table: "person_answer_bank"
   */
  insert_person_answer_bank: UpsertFormAnswer_insert_person_answer_bank | null;
  /**
   * delete data from the table: "custom_question_answer_bank_relationship"
   */
  delete_custom_question_answer_bank_relationship: UpsertFormAnswer_delete_custom_question_answer_bank_relationship | null;
}

export interface UpsertFormAnswerVariables {
  form_id: uuid;
  single_or_multi_select_question_ids: uuid[];
  answers: form_answer_insert_input[];
  grades_answers: grades_answer_insert_input[];
  address_answers: form_address_insert_input[];
  address_answer_deletes: form_address_bool_exp[];
  custom_question_ids: uuid[];
  custom_question_answers: custom_question_answer_insert_input[];
  answer_bank_ids: uuid[];
  answer_bank_answers: person_answer_insert_input[];
  deleted_answers_custom_question_ids: uuid[];
  new_answer_bank_entries: person_answer_bank_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormStatus
// ====================================================

export interface UpdateFormStatus_update_form_by_pk {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
}

export interface UpdateFormStatus_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormStatus_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateFormStatus {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: UpdateFormStatus_update_form_by_pk | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: UpdateFormStatus_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: UpdateFormStatus_update_offer | null;
}

export interface UpdateFormStatusVariables {
  form_id: uuid;
  skipOfferWaitlist: boolean;
  set: form_set_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBulkFormStatus
// ====================================================

export interface UpdateBulkFormStatus_update_form {
  __typename: "form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateBulkFormStatus_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateBulkFormStatus_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateBulkFormStatus {
  /**
   * update data of the table: "form"
   */
  update_form: UpdateBulkFormStatus_update_form | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: UpdateBulkFormStatus_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: UpdateBulkFormStatus_update_offer | null;
}

export interface UpdateBulkFormStatusVariables {
  form_ids?: uuid[] | null;
  skipOfferWaitlist: boolean;
  set?: form_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertVerificationStatus
// ====================================================

export interface UpsertVerificationStatus_delete_form_verification_result {
  __typename: "form_verification_result_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertVerificationStatus_insert_form_verification_result_one {
  __typename: "form_verification_result";
  id: uuid;
  form_id: uuid;
  form_verification_id: uuid;
  verification_status: verification_status_enum;
}

export interface UpsertVerificationStatus {
  /**
   * delete data from the table: "form_verification_result"
   */
  delete_form_verification_result: UpsertVerificationStatus_delete_form_verification_result | null;
  /**
   * insert a single row into the table: "form_verification_result"
   */
  insert_form_verification_result_one: UpsertVerificationStatus_insert_form_verification_result_one | null;
}

export interface UpsertVerificationStatusVariables {
  form_id: uuid;
  form_verification_id: uuid;
  verification_status: verification_status_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertVerificationStatuses
// ====================================================

export interface UpsertVerificationStatuses_delete_form_verification_result {
  __typename: "form_verification_result_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertVerificationStatuses_insert_form_verification_result {
  __typename: "form_verification_result_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertVerificationStatuses {
  /**
   * delete data from the table: "form_verification_result"
   */
  delete_form_verification_result: UpsertVerificationStatuses_delete_form_verification_result | null;
  /**
   * insert data into the table: "form_verification_result"
   */
  insert_form_verification_result: UpsertVerificationStatuses_insert_form_verification_result | null;
}

export interface UpsertVerificationStatusesVariables {
  form_ids: uuid[];
  form_verification_id: uuid;
  verification_results: form_verification_result_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertActionItem
// ====================================================

export interface InsertActionItem_insert_action_item_returning {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface InsertActionItem_insert_action_item {
  __typename: "action_item_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: InsertActionItem_insert_action_item_returning[];
}

export interface InsertActionItem {
  /**
   * insert data into the table: "action_item"
   */
  insert_action_item: InsertActionItem_insert_action_item | null;
}

export interface InsertActionItemVariables {
  form_id: uuid;
  summary: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateActionItem
// ====================================================

export interface UpdateActionItem_update_action_item_by_pk {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface UpdateActionItem {
  /**
   * update single row of the table: "action_item"
   */
  update_action_item_by_pk: UpdateActionItem_update_action_item_by_pk | null;
}

export interface UpdateActionItemVariables {
  id: uuid;
  status: action_item_status_enum;
  summary: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCreateActionItems
// ====================================================

export interface BulkCreateActionItems_update_action_item_many {
  __typename: "action_item_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkCreateActionItems_insert_action_item_returning {
  __typename: "action_item";
  form_id: uuid;
  created_at: timestamptz;
  id: uuid;
  status: action_item_status_enum;
  summary: string;
  updated_at: timestamptz;
}

export interface BulkCreateActionItems_insert_action_item {
  __typename: "action_item_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: BulkCreateActionItems_insert_action_item_returning[];
}

export interface BulkCreateActionItems {
  /**
   * update multiples rows of table: "action_item"
   */
  update_action_item_many: (BulkCreateActionItems_update_action_item_many | null)[] | null;
  /**
   * insert data into the table: "action_item"
   */
  insert_action_item: BulkCreateActionItems_insert_action_item | null;
}

export interface BulkCreateActionItemsVariables {
  form_ids?: uuid[] | null;
  data?: action_item_insert_input[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCancelActionItems
// ====================================================

export interface BulkCancelActionItems_update_action_item_many {
  __typename: "action_item_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkCancelActionItems {
  /**
   * update multiples rows of table: "action_item"
   */
  update_action_item_many: (BulkCancelActionItems_update_action_item_many | null)[] | null;
}

export interface BulkCancelActionItemsVariables {
  form_ids?: uuid[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MakeBulkFormSchoolOffer
// ====================================================

export interface MakeBulkFormSchoolOffer_update_form {
  __typename: "form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface MakeBulkFormSchoolOffer_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface MakeBulkFormSchoolOffer_insert_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface MakeBulkFormSchoolOffer_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface MakeBulkFormSchoolOffer_update_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface MakeBulkFormSchoolOffer {
  /**
   * update data of the table: "form"
   */
  update_form: MakeBulkFormSchoolOffer_update_form | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: MakeBulkFormSchoolOffer_update_offer | null;
  /**
   * insert data into the table: "offer"
   */
  insert_offer: MakeBulkFormSchoolOffer_insert_offer | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: MakeBulkFormSchoolOffer_update_waitlist | null;
  /**
   * update data of the table: "form_school_rank"
   */
  update_form_school_rank: MakeBulkFormSchoolOffer_update_form_school_rank | null;
}

export interface MakeBulkFormSchoolOfferVariables {
  form_ids?: uuid[] | null;
  delete_offers_where: offer_bool_exp;
  insert_offers: offer_insert_input[];
  delete_waitlists_where: waitlist_bool_exp;
  form_school_rank_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeBulkFormSchoolOffer
// ====================================================

export interface RevokeBulkFormSchoolOffer_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface RevokeBulkFormSchoolOffer {
  /**
   * update data of the table: "offer"
   */
  update_offer: RevokeBulkFormSchoolOffer_update_offer | null;
}

export interface RevokeBulkFormSchoolOfferVariables {
  where: offer_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportLotteryOrder
// ====================================================

export interface ImportLotteryOrder_update_form_school_rank_many_returning {
  __typename: "form_school_rank";
  id: uuid;
  form_id: uuid;
  school_id: uuid;
  lottery_order: numeric | null;
}

export interface ImportLotteryOrder_update_form_school_rank_many {
  __typename: "form_school_rank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: ImportLotteryOrder_update_form_school_rank_many_returning[];
}

export interface ImportLotteryOrder {
  /**
   * update multiples rows of table: "form_school_rank"
   */
  update_form_school_rank_many: (ImportLotteryOrder_update_form_school_rank_many | null)[] | null;
}

export interface ImportLotteryOrderVariables {
  updates: form_school_rank_updates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptBulkFormSchoolOffer
// ====================================================

export interface AcceptBulkFormSchoolOffer_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AcceptBulkFormSchoolOffer {
  /**
   * update data of the table: "offer"
   */
  update_offer: AcceptBulkFormSchoolOffer_update_offer | null;
}

export interface AcceptBulkFormSchoolOfferVariables {
  acceptedCondition: offer_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineBulkFormSchoolOffer
// ====================================================

export interface DeclineBulkFormSchoolOffer_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeclineBulkFormSchoolOffer {
  /**
   * update data of the table: "offer"
   */
  update_offer: DeclineBulkFormSchoolOffer_update_offer | null;
}

export interface DeclineBulkFormSchoolOfferVariables {
  declinedCondition: offer_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcludeBulkForm
// ====================================================

export interface ExcludeBulkForm_update_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface ExcludeBulkForm_update_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: ExcludeBulkForm_update_form_school_rank_returning[];
}

export interface ExcludeBulkForm_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface ExcludeBulkForm_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface ExcludeBulkForm {
  /**
   * update data of the table: "form_school_rank"
   */
  update_form_school_rank: ExcludeBulkForm_update_form_school_rank | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: ExcludeBulkForm_update_offer | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: ExcludeBulkForm_update_waitlist | null;
}

export interface ExcludeBulkFormVariables {
  updateWhere: form_school_rank_bool_exp;
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearSubstatusBulkForm
// ====================================================

export interface ClearSubstatusBulkForm_update_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface ClearSubstatusBulkForm_update_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: ClearSubstatusBulkForm_update_form_school_rank_returning[];
}

export interface ClearSubstatusBulkForm_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface ClearSubstatusBulkForm_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface ClearSubstatusBulkForm {
  /**
   * update data of the table: "form_school_rank"
   */
  update_form_school_rank: ClearSubstatusBulkForm_update_form_school_rank | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: ClearSubstatusBulkForm_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: ClearSubstatusBulkForm_update_offer | null;
}

export interface ClearSubstatusBulkFormVariables {
  updateWhere: form_school_rank_bool_exp;
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormVisibilityById
// ====================================================

export interface UpdateFormVisibilityById_update_form_by_pk {
  __typename: "form";
  id: uuid;
}

export interface UpdateFormVisibilityById {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: UpdateFormVisibilityById_update_form_by_pk | null;
}

export interface UpdateFormVisibilityByIdVariables {
  form_id: uuid;
  visibility: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBulkFormVisibility
// ====================================================

export interface UpdateBulkFormVisibility_update_form {
  __typename: "form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpdateBulkFormVisibility {
  /**
   * update data of the table: "form"
   */
  update_form: UpdateBulkFormVisibility_update_form | null;
}

export interface UpdateBulkFormVisibilityVariables {
  form_ids?: uuid[] | null;
  visibility: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormIdsByFormTemplate
// ====================================================

export interface GetFormIdsByFormTemplate_search_form_by_school {
  __typename: "search_form_by_school";
  form_id: uuid | null;
  form_school_rank_id: uuid | null;
  school_id: uuid | null;
}

export interface GetFormIdsByFormTemplate {
  /**
   * fetch data from the table: "search.form_by_school"
   */
  search_form_by_school: GetFormIdsByFormTemplate_search_form_by_school[];
}

export interface GetFormIdsByFormTemplateVariables {
  form_template_id: uuid;
  search: search_form_by_school_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormIdsAndSchoolIds
// ====================================================

export interface GetFormIdsAndSchoolIds_search_form_by_school_form_previous_offer_school {
  __typename: "school";
  id: uuid;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school_form_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetFormIdsAndSchoolIds_search_form_by_school_form_previous_offer_school;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school_form_previous_waitlist_school {
  __typename: "school";
  id: uuid;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school_form_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: GetFormIdsAndSchoolIds_search_form_by_school_form_previous_waitlist_school;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school_form {
  __typename: "form";
  /**
   * An object relationship
   */
  previous_offer: GetFormIdsAndSchoolIds_search_form_by_school_form_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetFormIdsAndSchoolIds_search_form_by_school_form_previous_waitlist | null;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  school_id: uuid;
}

export interface GetFormIdsAndSchoolIds_search_form_by_school {
  __typename: "search_form_by_school";
  form_id: uuid | null;
  /**
   * An object relationship
   */
  form: GetFormIdsAndSchoolIds_search_form_by_school_form | null;
  /**
   * An object relationship
   */
  form_school_rank: GetFormIdsAndSchoolIds_search_form_by_school_form_school_rank | null;
}

export interface GetFormIdsAndSchoolIds {
  /**
   * fetch data from the table: "search.form_by_school"
   */
  search_form_by_school: GetFormIdsAndSchoolIds_search_form_by_school[];
}

export interface GetFormIdsAndSchoolIdsVariables {
  form_template_id: uuid;
  search: search_form_by_school_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreviousFormTemplate
// ====================================================

export interface GetPreviousFormTemplate_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
}

export interface GetPreviousFormTemplate {
  /**
   * fetch data from the table: "form_template"
   */
  form_template: GetPreviousFormTemplate_form_template[];
}

export interface GetPreviousFormTemplateVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDropoffFormIdsByFormTemplate
// ====================================================

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_grade_grade_config;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_school;
  /**
   * An object relationship
   */
  grade: GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer_grade;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_grade_grade_config;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_school;
  /**
   * An object relationship
   */
  grade: GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist_grade;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_form_form_school_ranks {
  __typename: "form_school_rank";
  id: uuid;
  school_id: uuid;
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form_previous_form {
  __typename: "form";
  id: uuid;
  /**
   * An array relationship
   */
  form_school_ranks: GetDropoffFormIdsByFormTemplate_assigned_form_previous_form_form_school_ranks[];
}

export interface GetDropoffFormIdsByFormTemplate_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  /**
   * An object relationship
   */
  previous_offer: GetDropoffFormIdsByFormTemplate_assigned_form_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetDropoffFormIdsByFormTemplate_assigned_form_previous_waitlist | null;
  /**
   * An object relationship
   */
  previous_form: GetDropoffFormIdsByFormTemplate_assigned_form_previous_form | null;
}

export interface GetDropoffFormIdsByFormTemplate {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetDropoffFormIdsByFormTemplate_assigned_form[];
}

export interface GetDropoffFormIdsByFormTemplateVariables {
  form_template_id: uuid;
  previous_form_template_id: uuid;
  search: assigned_form_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDropoffFormsByFormTemplate
// ====================================================

export interface GetDropoffFormsByFormTemplate_assigned_form_aggregate_aggregate {
  __typename: "assigned_form_aggregate_fields";
  count: number;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_aggregate {
  __typename: "assigned_form_aggregate";
  aggregate: GetDropoffFormsByFormTemplate_assigned_form_aggregate_aggregate | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_offer_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_offer_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_offer_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetDropoffFormsByFormTemplate_assigned_form_previous_offer_grade_grade_config;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetDropoffFormsByFormTemplate_assigned_form_previous_offer_school;
  /**
   * An object relationship
   */
  grade: GetDropoffFormsByFormTemplate_assigned_form_previous_offer_grade;
  status_updated_at: timestamptz | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_grade_grade_config;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist {
  __typename: "waitlist";
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  school: GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_school;
  /**
   * An object relationship
   */
  grade: GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist_grade;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  tag_group_id: uuid | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_tags {
  __typename: "form_school_tag";
  created_at: timestamptz;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_tags_enrollment_period_tag;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_form_school_offer_status_history {
  __typename: "form_school_offer_status_history";
  submitted_at: timestamptz | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks {
  __typename: "form_school_rank";
  id: uuid;
  rank: number;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  school_id: uuid;
  /**
   * An array relationship
   */
  tags: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_tags[];
  /**
   * An object relationship
   */
  form_school_offer_status_history: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks_form_school_offer_status_history | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_tags {
  __typename: "form_school_tag";
  created_at: timestamptz;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_tags_enrollment_period_tag;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_first_relationship_second {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_first_relationship_second;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_second_relationship_first {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_second_relationship_first;
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
  reference_id: string | null;
  /**
   * An array relationship
   */
  first_relationship: GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetDropoffFormsByFormTemplate_assigned_form_previous_form_person_second_relationship[];
}

export interface GetDropoffFormsByFormTemplate_assigned_form_previous_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  submitted_before: boolean | null;
  form_template_id: uuid;
  /**
   * An array relationship
   */
  form_school_ranks: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_ranks[];
  /**
   * An array relationship
   */
  form_school_tags: GetDropoffFormsByFormTemplate_assigned_form_previous_form_form_school_tags[];
  /**
   * An object relationship
   */
  person: GetDropoffFormsByFormTemplate_assigned_form_previous_form_person;
}

export interface GetDropoffFormsByFormTemplate_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  /**
   * An object relationship
   */
  previous_offer: GetDropoffFormsByFormTemplate_assigned_form_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetDropoffFormsByFormTemplate_assigned_form_previous_waitlist | null;
  /**
   * An object relationship
   */
  previous_form: GetDropoffFormsByFormTemplate_assigned_form_previous_form | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions_custom_question | null;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetDropoffFormsByFormTemplate_form_template_by_pk_sections_questions[];
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetDropoffFormsByFormTemplate_form_template_by_pk_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetDropoffFormsByFormTemplate_form_template_by_pk_form_verifications[];
}

export interface GetDropoffFormsByFormTemplate_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetDropoffFormsByFormTemplate {
  /**
   * fetch aggregated fields from the table: "assigned_form"
   */
  assigned_form_aggregate: GetDropoffFormsByFormTemplate_assigned_form_aggregate;
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetDropoffFormsByFormTemplate_assigned_form[];
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetDropoffFormsByFormTemplate_form_template_by_pk | null;
  /**
   * fetch data from the table: "grade_config"
   */
  grade_config: GetDropoffFormsByFormTemplate_grade_config[];
}

export interface GetDropoffFormsByFormTemplateVariables {
  organization_id: uuid;
  form_template_id: uuid;
  previous_form_template_id: uuid;
  search: assigned_form_bool_exp;
  offset?: number | null;
  limit?: number | null;
  skip_rank: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormsByFormTemplate
// ====================================================

export interface GetFormsByFormTemplate_search_form_by_school_aggregate_aggregate {
  __typename: "search_form_by_school_aggregate_fields";
  count: number;
}

export interface GetFormsByFormTemplate_search_form_by_school_aggregate {
  __typename: "search_form_by_school_aggregate";
  aggregate: GetFormsByFormTemplate_search_form_by_school_aggregate_aggregate | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin_grades {
  __typename: "grade";
  id: uuid;
  school_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin_grades_grade_config;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin {
  __typename: "bin";
  /**
   * An array relationship
   */
  grades: GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin_grades[];
}

export interface GetFormsByFormTemplate_search_form_by_school_form_person_person_bins {
  __typename: "person_bin";
  id: uuid;
  bin_id: uuid;
  lottery_number: number;
  person_id: uuid;
  /**
   * An object relationship
   */
  bin: GetFormsByFormTemplate_search_form_by_school_form_person_person_bins_bin;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  last_name: string | null;
  /**
   * An array relationship
   */
  person_bins: GetFormsByFormTemplate_search_form_by_school_form_person_person_bins[];
}

export interface GetFormsByFormTemplate_search_form_by_school_form_grades_answers_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: GetFormsByFormTemplate_search_form_by_school_form_grades_answers_grade_config | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_form_verification_results {
  __typename: "form_verification_result";
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: GetFormsByFormTemplate_search_form_by_school_form_form_verification_results_form_verification;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_tags {
  __typename: "form_tag";
  created_at: timestamptz;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetFormsByFormTemplate_search_form_by_school_form_tags_enrollment_period_tag;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_offer_school {
  __typename: "school";
  name: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_offer_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_offer_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetFormsByFormTemplate_search_form_by_school_form_previous_offer_grade_grade_config;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetFormsByFormTemplate_search_form_by_school_form_previous_offer_school;
  /**
   * An object relationship
   */
  grade: GetFormsByFormTemplate_search_form_by_school_form_previous_offer_grade;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_school {
  __typename: "school";
  name: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_grade_grade_config;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_school;
  /**
   * An object relationship
   */
  grade: GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist_grade;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_form_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: GetFormsByFormTemplate_search_form_by_school_form_previous_form_grades_answers_grade_config | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_previous_form {
  __typename: "form";
  /**
   * An array relationship
   */
  grades_answers: GetFormsByFormTemplate_search_form_by_school_form_previous_form_grades_answers[];
}

export interface GetFormsByFormTemplate_search_form_by_school_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  submitted_before: boolean | null;
  is_hidden_from_parent: boolean | null;
  /**
   * An object relationship
   */
  person: GetFormsByFormTemplate_search_form_by_school_form_person;
  /**
   * An array relationship
   */
  grades_answers: GetFormsByFormTemplate_search_form_by_school_form_grades_answers[];
  /**
   * An array relationship
   */
  form_verification_results: GetFormsByFormTemplate_search_form_by_school_form_form_verification_results[];
  /**
   * An array relationship
   */
  tags: GetFormsByFormTemplate_search_form_by_school_form_tags[];
  /**
   * An object relationship
   */
  previous_offer: GetFormsByFormTemplate_search_form_by_school_form_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetFormsByFormTemplate_search_form_by_school_form_previous_waitlist | null;
  /**
   * An object relationship
   */
  previous_form: GetFormsByFormTemplate_search_form_by_school_form_previous_form | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_offers {
  __typename: "offer";
  status_updated_at: timestamptz | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_waitlists_grade {
  __typename: "grade";
  grade_config_id: uuid;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_waitlists {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  grade: GetFormsByFormTemplate_search_form_by_school_form_school_rank_waitlists_grade;
  status_updated_at: timestamptz | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_form_school_offer_status_history {
  __typename: "form_school_offer_status_history";
  submitted_at: timestamptz | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  name: citext;
  tag_group_id: uuid | null;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank_tags {
  __typename: "form_school_tag";
  created_at: timestamptz;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetFormsByFormTemplate_search_form_by_school_form_school_rank_tags_enrollment_period_tag;
}

export interface GetFormsByFormTemplate_search_form_by_school_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  rank: number;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: GetFormsByFormTemplate_search_form_by_school_form_school_rank_school;
  /**
   * An array relationship
   */
  offers: GetFormsByFormTemplate_search_form_by_school_form_school_rank_offers[];
  /**
   * An array relationship
   */
  waitlists: GetFormsByFormTemplate_search_form_by_school_form_school_rank_waitlists[];
  /**
   * An object relationship
   */
  form_school_offer_status_history: GetFormsByFormTemplate_search_form_by_school_form_school_rank_form_school_offer_status_history | null;
  /**
   * An array relationship
   */
  tags: GetFormsByFormTemplate_search_form_by_school_form_school_rank_tags[];
  lottery_order: numeric | null;
}

export interface GetFormsByFormTemplate_search_form_by_school {
  __typename: "search_form_by_school";
  /**
   * An object relationship
   */
  form: GetFormsByFormTemplate_search_form_by_school_form | null;
  /**
   * An object relationship
   */
  form_school_rank: GetFormsByFormTemplate_search_form_by_school_form_school_rank | null;
  waitlist_position: number | null;
}

export interface GetFormsByFormTemplate {
  /**
   * fetch aggregated fields from the table: "search.form_by_school"
   */
  search_form_by_school_aggregate: GetFormsByFormTemplate_search_form_by_school_aggregate;
  /**
   * fetch data from the table: "search.form_by_school"
   */
  search_form_by_school: GetFormsByFormTemplate_search_form_by_school[];
}

export interface GetFormsByFormTemplateVariables {
  form_template_id: uuid;
  search: search_form_by_school_bool_exp;
  order_by: search_form_by_school_order_by;
  offset?: number | null;
  limit?: number | null;
  skip_rank: boolean;
  include_waitlist_position: boolean;
  enrollment_period_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMetadataByFormTemplate
// ====================================================

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetMetadataByFormTemplate_form_template_by_pk_sections_questions_custom_question | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetMetadataByFormTemplate_form_template_by_pk_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetMetadataByFormTemplate_form_template_by_pk_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetMetadataByFormTemplate_form_template_by_pk_sections_questions[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_enrollment_period_grades {
  __typename: "grade";
  school_id: uuid;
  grade_config_id: uuid;
  program_id: uuid | null;
  seats_available: number | null;
}

export interface GetMetadataByFormTemplate_form_template_by_pk_enrollment_period {
  __typename: "enrollment_period";
  /**
   * An array relationship
   */
  grades: GetMetadataByFormTemplate_form_template_by_pk_enrollment_period_grades[];
}

export interface GetMetadataByFormTemplate_form_template_by_pk {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetMetadataByFormTemplate_form_template_by_pk_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetMetadataByFormTemplate_form_template_by_pk_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: GetMetadataByFormTemplate_form_template_by_pk_enrollment_period;
}

export interface GetMetadataByFormTemplate {
  /**
   * fetch data from the table: "form_template" using primary key columns
   */
  form_template_by_pk: GetMetadataByFormTemplate_form_template_by_pk | null;
}

export interface GetMetadataByFormTemplateVariables {
  form_template_id: uuid;
  program_equals_exp: grade_bool_exp;
  skip_capacities: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreviousFormByFormId
// ====================================================

export interface GetPreviousFormByFormId_form_by_pk_form_template {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
}

export interface GetPreviousFormByFormId_form_by_pk_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetPreviousFormByFormId_form_by_pk_person_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetPreviousFormByFormId_form_by_pk_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetPreviousFormByFormId_form_by_pk_person_user | null;
  /**
   * An array relationship
   */
  person_bins: GetPreviousFormByFormId_form_by_pk_person_person_bins[];
}

export interface GetPreviousFormByFormId_form_by_pk_form_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  name: citext;
}

export interface GetPreviousFormByFormId_form_by_pk_form_tags {
  __typename: "form_tag";
  /**
   * An object relationship
   */
  enrollment_period_tag: GetPreviousFormByFormId_form_by_pk_form_tags_enrollment_period_tag;
}

export interface GetPreviousFormByFormId_form_by_pk_next_forms_form_template {
  __typename: "form_template";
  name: string;
}

export interface GetPreviousFormByFormId_form_by_pk_next_forms {
  __typename: "form";
  id: uuid;
  form_template_id: uuid;
  /**
   * An object relationship
   */
  form_template: GetPreviousFormByFormId_form_by_pk_next_forms_form_template;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_form_template {
  __typename: "form_template";
  name: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_form_school_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_form_school_tags {
  __typename: "form_school_tag";
  school_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetPreviousFormByFormId_form_by_pk_previous_form_form_school_tags_enrollment_period_tag;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_form_school_ranks {
  __typename: "form_school_rank";
  id: uuid;
  school_id: uuid;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: GetPreviousFormByFormId_form_by_pk_previous_form_grades_answers_grade_config | null;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_form {
  __typename: "form";
  id: uuid;
  form_template_id: uuid;
  /**
   * An object relationship
   */
  form_template: GetPreviousFormByFormId_form_by_pk_previous_form_form_template;
  /**
   * An array relationship
   */
  form_school_tags: GetPreviousFormByFormId_form_by_pk_previous_form_form_school_tags[];
  /**
   * An array relationship
   */
  form_school_ranks: GetPreviousFormByFormId_form_by_pk_previous_form_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: GetPreviousFormByFormId_form_by_pk_previous_form_grades_answers[];
}

export interface GetPreviousFormByFormId_form_by_pk_previous_offer_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_offer_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_offer_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetPreviousFormByFormId_form_by_pk_previous_offer_grade_grade_config;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetPreviousFormByFormId_form_by_pk_previous_offer_school;
  /**
   * An object relationship
   */
  grade: GetPreviousFormByFormId_form_by_pk_previous_offer_grade;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_waitlist_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_waitlist_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_waitlist_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: GetPreviousFormByFormId_form_by_pk_previous_waitlist_grade_grade_config;
}

export interface GetPreviousFormByFormId_form_by_pk_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: GetPreviousFormByFormId_form_by_pk_previous_waitlist_school;
  /**
   * An object relationship
   */
  grade: GetPreviousFormByFormId_form_by_pk_previous_waitlist_grade;
}

export interface GetPreviousFormByFormId_form_by_pk {
  __typename: "form";
  id: uuid;
  /**
   * An object relationship
   */
  form_template: GetPreviousFormByFormId_form_by_pk_form_template;
  /**
   * An object relationship
   */
  person: GetPreviousFormByFormId_form_by_pk_person;
  /**
   * An array relationship
   */
  form_tags: GetPreviousFormByFormId_form_by_pk_form_tags[];
  /**
   * An array relationship
   */
  next_forms: GetPreviousFormByFormId_form_by_pk_next_forms[];
  /**
   * An object relationship
   */
  previous_form: GetPreviousFormByFormId_form_by_pk_previous_form | null;
  /**
   * An object relationship
   */
  previous_offer: GetPreviousFormByFormId_form_by_pk_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetPreviousFormByFormId_form_by_pk_previous_waitlist | null;
}

export interface GetPreviousFormByFormId {
  /**
   * fetch data from the table: "form" using primary key columns
   */
  form_by_pk: GetPreviousFormByFormId_form_by_pk | null;
}

export interface GetPreviousFormByFormIdVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormViewById
// ====================================================

export interface GetFormViewById_form_by_pk_form_attending_school_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_form_attending_school {
  __typename: "form_attending_school";
  /**
   * An object relationship
   */
  school: GetFormViewById_form_by_pk_form_attending_school_school | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetFormViewById_form_by_pk_form_template_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetFormViewById_form_by_pk_form_template_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormViewById_form_by_pk_form_template_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormViewById_form_by_pk_form_template_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetFormViewById_form_by_pk_form_template_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetFormViewById_form_by_pk_form_template_sections_questions_custom_question | null;
}

export interface GetFormViewById_form_by_pk_form_template_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetFormViewById_form_by_pk_form_template_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetFormViewById_form_by_pk_form_template_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetFormViewById_form_by_pk_form_template_sections_questions[];
}

export interface GetFormViewById_form_by_pk_form_template_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_template_enrollment_period {
  __typename: "enrollment_period";
  active: boolean;
  name: string;
}

export interface GetFormViewById_form_by_pk_form_template {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetFormViewById_form_by_pk_form_template_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetFormViewById_form_by_pk_form_template_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: GetFormViewById_form_by_pk_form_template_enrollment_period;
}

export interface GetFormViewById_form_by_pk_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormViewById_form_by_pk_form_verification_results {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: GetFormViewById_form_by_pk_form_verification_results_form_verification;
}

export interface GetFormViewById_form_by_pk_custom_question_answer_bank_relationships_person_answer_bank_person_answer_bank_external_relationship {
  __typename: "person_answer_bank_external_relationship";
  external_id: string;
}

export interface GetFormViewById_form_by_pk_custom_question_answer_bank_relationships_person_answer_bank {
  __typename: "person_answer_bank";
  id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank_external_relationship: GetFormViewById_form_by_pk_custom_question_answer_bank_relationships_person_answer_bank_person_answer_bank_external_relationship | null;
}

export interface GetFormViewById_form_by_pk_custom_question_answer_bank_relationships {
  __typename: "custom_question_answer_bank_relationship";
  id: uuid;
  custom_question_id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank: GetFormViewById_form_by_pk_custom_question_answer_bank_relationships_person_answer_bank;
}

export interface GetFormViewById_form_by_pk_form_disclaimers {
  __typename: "form_disclaimer";
  id: uuid;
  signature: string;
  signed_at: timestamptz;
}

export interface GetFormViewById_form_by_pk_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_template_enrollment_period;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second_forms[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship_second;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_template_enrollment_period;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first_forms[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship_first;
}

export interface GetFormViewById_form_by_pk_person_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  first_relationship: GetFormViewById_form_by_pk_person_first_relationship_second_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetFormViewById_form_by_pk_person_first_relationship_second_second_relationship[];
}

export interface GetFormViewById_form_by_pk_person_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetFormViewById_form_by_pk_person_first_relationship_second;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_template_enrollment_period;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second_forms[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship_second;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_template_enrollment_period;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_forms_form_school_ranks[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first_forms[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship_first;
}

export interface GetFormViewById_form_by_pk_person_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  first_relationship: GetFormViewById_form_by_pk_person_second_relationship_first_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetFormViewById_form_by_pk_person_second_relationship_first_second_relationship[];
}

export interface GetFormViewById_form_by_pk_person_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetFormViewById_form_by_pk_person_second_relationship_first;
}

export interface GetFormViewById_form_by_pk_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetFormViewById_form_by_pk_person_user | null;
  /**
   * An array relationship
   */
  person_bins: GetFormViewById_form_by_pk_person_person_bins[];
  /**
   * An array relationship
   */
  first_relationship: GetFormViewById_form_by_pk_person_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetFormViewById_form_by_pk_person_second_relationship[];
}

export interface GetFormViewById_form_by_pk {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  submitted_at: timestamptz | null;
  is_hidden_from_parent: boolean | null;
  /**
   * An object relationship
   */
  form_attending_school: GetFormViewById_form_by_pk_form_attending_school | null;
  /**
   * An object relationship
   */
  form_template: GetFormViewById_form_by_pk_form_template;
  /**
   * An array relationship
   */
  form_verification_results: GetFormViewById_form_by_pk_form_verification_results[];
  /**
   * An array relationship
   */
  custom_question_answer_bank_relationships: GetFormViewById_form_by_pk_custom_question_answer_bank_relationships[];
  /**
   * An array relationship
   */
  form_disclaimers: GetFormViewById_form_by_pk_form_disclaimers[];
  /**
   * An object relationship
   */
  person: GetFormViewById_form_by_pk_person;
}

export interface GetFormViewById {
  /**
   * fetch data from the table: "form" using primary key columns
   */
  form_by_pk: GetFormViewById_form_by_pk | null;
}

export interface GetFormViewByIdVariables {
  form_id: uuid;
  organization_id: uuid;
  is_admin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestionTypesByOrg
// ====================================================

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question_form_question | null;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields_question;
}

export interface GetCustomQuestionTypesByOrg_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  custom_question_type_fields: GetCustomQuestionTypesByOrg_custom_question_type_custom_question_type_fields[];
}

export interface GetCustomQuestionTypesByOrg {
  /**
   * fetch data from the table: "custom_question_type"
   */
  custom_question_type: GetCustomQuestionTypesByOrg_custom_question_type[];
}

export interface GetCustomQuestionTypesByOrgVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsRankView
// ====================================================

export interface GetSchoolsRankView_form_school_rank_school_grades_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetSchoolsRankView_form_school_rank_school_grades_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetSchoolsRankView_form_school_rank_school_grades {
  __typename: "grade";
  id: uuid;
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period: GetSchoolsRankView_form_school_rank_school_grades_enrollment_period;
  /**
   * An object relationship
   */
  grade_config: GetSchoolsRankView_form_school_rank_school_grades_grade_config;
}

export interface GetSchoolsRankView_form_school_rank_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
  /**
   * An array relationship
   */
  grades: GetSchoolsRankView_form_school_rank_school_grades[];
}

export interface GetSchoolsRankView_form_school_rank_form_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolsRankView_form_school_rank_form_form_verification_results {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: GetSchoolsRankView_form_school_rank_form_form_verification_results_form_verification;
}

export interface GetSchoolsRankView_form_school_rank_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  /**
   * An array relationship
   */
  form_verification_results: GetSchoolsRankView_form_school_rank_form_form_verification_results[];
}

export interface GetSchoolsRankView_form_school_rank_form_verifications_flattened {
  __typename: "form_verifications_flattened";
  verifications: string | null;
}

export interface GetSchoolsRankView_form_school_rank_offers_grade_program {
  __typename: "program";
  label: citext;
}

export interface GetSchoolsRankView_form_school_rank_offers_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  program: GetSchoolsRankView_form_school_rank_offers_grade_program | null;
}

export interface GetSchoolsRankView_form_school_rank_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
  /**
   * An object relationship
   */
  grade: GetSchoolsRankView_form_school_rank_offers_grade;
}

export interface GetSchoolsRankView_form_school_rank_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetSchoolsRankView_form_school_rank_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  /**
   * An object relationship
   */
  waitlist_position: GetSchoolsRankView_form_school_rank_waitlists_waitlist_position | null;
}

export interface GetSchoolsRankView_form_school_rank_tags_enrollment_period_tag_tag_group {
  __typename: "tag_group";
  id: uuid;
  name: string;
}

export interface GetSchoolsRankView_form_school_rank_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  tag_group_id: uuid | null;
  /**
   * An object relationship
   */
  tag_group: GetSchoolsRankView_form_school_rank_tags_enrollment_period_tag_tag_group | null;
}

export interface GetSchoolsRankView_form_school_rank_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetSchoolsRankView_form_school_rank_tags_enrollment_period_tag;
}

export interface GetSchoolsRankView_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  rank: number;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: GetSchoolsRankView_form_school_rank_school;
  /**
   * An object relationship
   */
  form: GetSchoolsRankView_form_school_rank_form;
  /**
   * An object relationship
   */
  form_verifications_flattened: GetSchoolsRankView_form_school_rank_form_verifications_flattened | null;
  /**
   * An array relationship
   */
  offers: GetSchoolsRankView_form_school_rank_offers[];
  /**
   * An array relationship
   */
  waitlists: GetSchoolsRankView_form_school_rank_waitlists[];
  /**
   * An array relationship
   */
  tags: GetSchoolsRankView_form_school_rank_tags[];
}

export interface GetSchoolsRankView_tag_group {
  __typename: "tag_group";
  id: uuid;
  name: string;
  is_external: boolean;
  max_tags: number | null;
}

export interface GetSchoolsRankView {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: GetSchoolsRankView_form_school_rank[];
  /**
   * fetch data from the table: "tag_group"
   */
  tag_group: GetSchoolsRankView_tag_group[];
}

export interface GetSchoolsRankViewVariables {
  form_id: uuid;
  enrollment_period_id: uuid;
  skip_rank: boolean;
  filter: form_school_rank_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTagGroupsByEnrollmentPeriod
// ====================================================

export interface GetTagGroupsByEnrollmentPeriod_tag_group {
  __typename: "tag_group";
  id: uuid;
  name: string;
  is_external: boolean;
  max_tags: number | null;
  is_default: boolean;
}

export interface GetTagGroupsByEnrollmentPeriod {
  /**
   * fetch data from the table: "tag_group"
   */
  tag_group: GetTagGroupsByEnrollmentPeriod_tag_group[];
}

export interface GetTagGroupsByEnrollmentPeriodVariables {
  enrollment_period_id: uuid;
  is_external?: Boolean_comparison_exp | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForFilters
// ====================================================

export interface GetSchoolsForFilters_form_school_rank {
  __typename: "form_school_rank";
  rank: number;
}

export interface GetSchoolsForFilters_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetSchoolsForFilters_attending_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetSchoolsForFilters {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: GetSchoolsForFilters_form_school_rank[];
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsForFilters_school[];
  /**
   * fetch data from the table: "school"
   */
  attending_school: GetSchoolsForFilters_attending_school[];
}

export interface GetSchoolsForFiltersVariables {
  form_template_id: uuid;
  skip_rank: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRanksForFilters
// ====================================================

export interface GetRanksForFilters_form_school_rank {
  __typename: "form_school_rank";
  rank: number;
}

export interface GetRanksForFilters {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: GetRanksForFilters_form_school_rank[];
}

export interface GetRanksForFiltersVariables {
  form_template_id: uuid;
  skip_rank: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTagsForFilters
// ====================================================

export interface GetFormTagsForFilters_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetFormTagsForFilters {
  /**
   * fetch data from the table: "enrollment_period_tag"
   */
  enrollment_period_tag: GetFormTagsForFilters_enrollment_period_tag[];
}

export interface GetFormTagsForFiltersVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTagGroupsForFilters
// ====================================================

export interface GetTagGroupsForFilters_tag_group_tags {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetTagGroupsForFilters_tag_group {
  __typename: "tag_group";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  tags: GetTagGroupsForFilters_tag_group_tags[];
}

export interface GetTagGroupsForFilters {
  /**
   * fetch data from the table: "tag_group"
   */
  tag_group: GetTagGroupsForFilters_tag_group[];
}

export interface GetTagGroupsForFiltersVariables {
  enrollment_period_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProgramsForCapacitiesFilter
// ====================================================

export interface GetProgramsForCapacitiesFilter_program_group_programs {
  __typename: "program";
  id: uuid;
  label: citext;
}

export interface GetProgramsForCapacitiesFilter_program_group {
  __typename: "program_group";
  /**
   * An array relationship
   */
  programs: GetProgramsForCapacitiesFilter_program_group_programs[];
}

export interface GetProgramsForCapacitiesFilter {
  /**
   * fetch data from the table: "program_group"
   */
  program_group: GetProgramsForCapacitiesFilter_program_group[];
}

export interface GetProgramsForCapacitiesFilterVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVerificationsForFilters
// ====================================================

export interface GetVerificationsForFilters_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetVerificationsForFilters {
  /**
   * fetch data from the table: "form_verification"
   */
  form_verification: GetVerificationsForFilters_form_verification[];
}

export interface GetVerificationsForFiltersVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormHistory
// ====================================================

export interface GetFormHistory_audit_form_transaction_logged_actions_user_people {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
  person_type: person_type_enum | null;
}

export interface GetFormHistory_audit_form_transaction_logged_actions_user {
  __typename: "user";
  /**
   * An array relationship
   */
  people: GetFormHistory_audit_form_transaction_logged_actions_user_people[];
}

export interface GetFormHistory_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * An object relationship
   */
  user: GetFormHistory_audit_form_transaction_logged_actions_user | null;
  /**
   * Action type; I = insert, D = delete, U = update, T = truncate
   */
  action: string;
  /**
   * Wall clock time at which audited event's trigger call occurred
   */
  action_tstamp_clk: timestamptz;
  /**
   * Non-schema-qualified table name of table event occured in
   */
  table_name: string;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
  /**
   * New values of fields changed by UPDATE. Null except for row-level UPDATE events.
   */
  changed_fields: jsonb | null;
}

export interface GetFormHistory_audit_form_transaction {
  __typename: "audit_form_transaction";
  transaction_id: bigint | null;
  action_tstamp_tx: timestamptz | null;
  /**
   * An array relationship
   */
  logged_actions: GetFormHistory_audit_form_transaction_logged_actions[];
}

export interface GetFormHistory_audit_form_transaction_aggregate_aggregate {
  __typename: "audit_form_transaction_aggregate_fields";
  count: number;
}

export interface GetFormHistory_audit_form_transaction_aggregate {
  __typename: "audit_form_transaction_aggregate";
  aggregate: GetFormHistory_audit_form_transaction_aggregate_aggregate | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetFormHistory_form_by_pk_form_template_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetFormHistory_form_by_pk_form_template_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormHistory_form_by_pk_form_template_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormHistory_form_by_pk_form_template_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetFormHistory_form_by_pk_form_template_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetFormHistory_form_by_pk_form_template_sections_questions_custom_question | null;
}

export interface GetFormHistory_form_by_pk_form_template_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetFormHistory_form_by_pk_form_template_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetFormHistory_form_by_pk_form_template_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetFormHistory_form_by_pk_form_template_sections_questions[];
}

export interface GetFormHistory_form_by_pk_form_template_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormHistory_form_by_pk_form_template {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetFormHistory_form_by_pk_form_template_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetFormHistory_form_by_pk_form_template_form_verifications[];
}

export interface GetFormHistory_form_by_pk {
  __typename: "form";
  /**
   * An object relationship
   */
  form_template: GetFormHistory_form_by_pk_form_template;
}

export interface GetFormHistory_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetFormHistory_grade_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFormHistory_grade_program {
  __typename: "program";
  id: uuid;
  label: citext;
}

export interface GetFormHistory_grade {
  __typename: "grade";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetFormHistory_grade_grade_config;
  /**
   * An object relationship
   */
  program: GetFormHistory_grade_program | null;
}

export interface GetFormHistory_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetFormHistory {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetFormHistory_audit_form_transaction[];
  /**
   * fetch aggregated fields from the table: "audit.form_transaction"
   */
  audit_form_transaction_aggregate: GetFormHistory_audit_form_transaction_aggregate;
  /**
   * fetch data from the table: "form" using primary key columns
   */
  form_by_pk: GetFormHistory_form_by_pk | null;
  /**
   * fetch data from the table: "school"
   */
  school: GetFormHistory_school[];
  /**
   * fetch data from the table: "grade"
   */
  grade: GetFormHistory_grade[];
  /**
   * fetch data from the table: "enrollment_period_tag"
   */
  enrollment_period_tag: GetFormHistory_enrollment_period_tag[];
}

export interface GetFormHistoryVariables {
  form_id: uuid;
  organization_id: uuid;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchActionItems
// ====================================================

export interface FetchActionItems_action_item {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface FetchActionItems {
  /**
   * fetch data from the table: "action_item"
   */
  action_item: FetchActionItems_action_item[];
}

export interface FetchActionItemsVariables {
  form_id: uuid;
  status: action_item_status_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportQuestionsAndVerifications
// ====================================================

export interface ExportQuestionsAndVerifications_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  label: string;
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question_form_question {
  __typename: "form_question";
  /**
   * An array relationship
   */
  form_question_options: ExportQuestionsAndVerifications_question_by_form_template_question_form_question_form_question_options[];
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  cloned_question_id: uuid;
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question_custom_question {
  __typename: "custom_question";
  /**
   * An array relationship
   */
  custom_question_relationships: ExportQuestionsAndVerifications_question_by_form_template_question_custom_question_custom_question_relationships[];
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question_custom_question_relationship {
  __typename: "custom_question_relationship";
  custom_question_type_field_id: uuid;
}

export interface ExportQuestionsAndVerifications_question_by_form_template_question {
  __typename: "question";
  type: question_type_enum;
  question: string;
  /**
   * An object relationship
   */
  form_question: ExportQuestionsAndVerifications_question_by_form_template_question_form_question | null;
  constraints: jsonb | null;
  /**
   * An object relationship
   */
  custom_question: ExportQuestionsAndVerifications_question_by_form_template_question_custom_question | null;
  /**
   * An object relationship
   */
  custom_question_relationship: ExportQuestionsAndVerifications_question_by_form_template_question_custom_question_relationship | null;
}

export interface ExportQuestionsAndVerifications_question_by_form_template {
  __typename: "question_by_form_template";
  question_id: uuid | null;
  /**
   * An object relationship
   */
  question: ExportQuestionsAndVerifications_question_by_form_template_question | null;
}

export interface ExportQuestionsAndVerifications_form_template_section {
  __typename: "form_template_section";
  title: string;
}

export interface ExportQuestionsAndVerifications {
  /**
   * fetch data from the table: "form_verification"
   */
  form_verification: ExportQuestionsAndVerifications_form_verification[];
  /**
   * fetch data from the table: "question_by_form_template"
   */
  question_by_form_template: ExportQuestionsAndVerifications_question_by_form_template[];
  /**
   * fetch data from the table: "form_template_section"
   */
  form_template_section: ExportQuestionsAndVerifications_form_template_section[];
}

export interface ExportQuestionsAndVerificationsVariables {
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportForms
// ====================================================

export interface ExportForms_form_form_attending_school_school {
  __typename: "school";
  name: string;
}

export interface ExportForms_form_form_attending_school {
  __typename: "form_attending_school";
  school_id: uuid | null;
  /**
   * An object relationship
   */
  school: ExportForms_form_form_attending_school_school | null;
  attendance_status: string | null;
}

export interface ExportForms_form_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  phone_number: string | null;
  email_address: string | null;
  preferred_language: string | null;
}

export interface ExportForms_form_applicant_guardians_guardian {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  phone_number: string | null;
  email_address: string | null;
  preferred_language: string | null;
}

export interface ExportForms_form_applicant_guardians {
  __typename: "applicant_guardian";
  /**
   * An object relationship
   */
  guardian: ExportForms_form_applicant_guardians_guardian | null;
}

export interface ExportForms_form_form_answers_form_answer_options {
  __typename: "form_answer_option";
  form_question_option_id: uuid;
}

export interface ExportForms_form_form_answers {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  free_text_answer: string | null;
  /**
   * An array relationship
   */
  form_answer_options: ExportForms_form_form_answers_form_answer_options[];
  number_answer: numeric | null;
  date_answer: date | null;
}

export interface ExportForms_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface ExportForms_form_grades_answers {
  __typename: "grades_answer";
  question_id: uuid;
  /**
   * An object relationship
   */
  grade_config: ExportForms_form_grades_answers_grade_config | null;
}

export interface ExportForms_form_form_addresses {
  __typename: "form_address";
  id: uuid;
  question_id: uuid;
  city: string;
  state: string;
  street_address: string;
  street_address_line_2: string;
  zip_code: string;
}

export interface ExportForms_form_custom_question_answer_bank_relationships_person_answer_bank_person_answer_bank_external_relationship {
  __typename: "person_answer_bank_external_relationship";
  external_id: string;
}

export interface ExportForms_form_custom_question_answer_bank_relationships_person_answer_bank {
  __typename: "person_answer_bank";
  id: uuid;
  person_id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank_external_relationship: ExportForms_form_custom_question_answer_bank_relationships_person_answer_bank_person_answer_bank_external_relationship | null;
}

export interface ExportForms_form_custom_question_answer_bank_relationships {
  __typename: "custom_question_answer_bank_relationship";
  custom_question_id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank: ExportForms_form_custom_question_answer_bank_relationships_person_answer_bank;
}

export interface ExportForms_form_form_disclaimers {
  __typename: "form_disclaimer";
  signature: string;
}

export interface ExportForms_form_form_verification_results {
  __typename: "form_verification_result";
  form_verification_id: uuid;
  verification_status: verification_status_enum;
}

export interface ExportForms_form_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  name: citext;
}

export interface ExportForms_form_tags {
  __typename: "form_tag";
  /**
   * An object relationship
   */
  enrollment_period_tag: ExportForms_form_tags_enrollment_period_tag;
}

export interface ExportForms_form_previous_offer_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface ExportForms_form_previous_offer_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface ExportForms_form_previous_offer_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: ExportForms_form_previous_offer_grade_grade_config;
}

export interface ExportForms_form_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: ExportForms_form_previous_offer_school;
  /**
   * An object relationship
   */
  grade: ExportForms_form_previous_offer_grade;
}

export interface ExportForms_form_previous_waitlist_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface ExportForms_form_previous_waitlist_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface ExportForms_form_previous_waitlist_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: ExportForms_form_previous_waitlist_grade_grade_config;
}

export interface ExportForms_form_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: ExportForms_form_previous_waitlist_school;
  /**
   * An object relationship
   */
  grade: ExportForms_form_previous_waitlist_grade;
}

export interface ExportForms_form_previous_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface ExportForms_form_previous_form_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: ExportForms_form_previous_form_grades_answers_grade_config | null;
}

export interface ExportForms_form_previous_form {
  __typename: "form";
  /**
   * An array relationship
   */
  grades_answers: ExportForms_form_previous_form_grades_answers[];
}

export interface ExportForms_form_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface ExportForms_form_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  name: citext;
  tag_group_id: uuid | null;
}

export interface ExportForms_form_form_school_ranks_tags {
  __typename: "form_school_tag";
  /**
   * An object relationship
   */
  enrollment_period_tag: ExportForms_form_form_school_ranks_tags_enrollment_period_tag;
}

export interface ExportForms_form_form_school_ranks_form_school_offer_status_history {
  __typename: "form_school_offer_status_history";
  submitted_at: timestamptz | null;
}

export interface ExportForms_form_form_school_ranks_offers_grade_program {
  __typename: "program";
  id: uuid;
  label: citext;
}

export interface ExportForms_form_form_school_ranks_offers_grade {
  __typename: "grade";
  /**
   * An object relationship
   */
  program: ExportForms_form_form_school_ranks_offers_grade_program | null;
}

export interface ExportForms_form_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  grade: ExportForms_form_form_school_ranks_offers_grade;
}

export interface ExportForms_form_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface ExportForms_form_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  waitlist_position: ExportForms_form_form_school_ranks_waitlists_waitlist_position | null;
}

export interface ExportForms_form_form_school_ranks {
  __typename: "form_school_rank";
  id: uuid;
  form_id: uuid;
  rank: number;
  lottery_order: numeric | null;
  status: form_school_rank_status_enum | null;
  /**
   * An object relationship
   */
  school: ExportForms_form_form_school_ranks_school;
  /**
   * An array relationship
   */
  tags: ExportForms_form_form_school_ranks_tags[];
  /**
   * An object relationship
   */
  form_school_offer_status_history: ExportForms_form_form_school_ranks_form_school_offer_status_history | null;
  /**
   * An array relationship
   */
  offers: ExportForms_form_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: ExportForms_form_form_school_ranks_waitlists[];
}

export interface ExportForms_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  submitted_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_attending_school: ExportForms_form_form_attending_school | null;
  /**
   * An object relationship
   */
  person: ExportForms_form_person;
  /**
   * An array relationship
   */
  applicant_guardians: ExportForms_form_applicant_guardians[];
  /**
   * An array relationship
   */
  form_answers: ExportForms_form_form_answers[];
  /**
   * An array relationship
   */
  grades_answers: ExportForms_form_grades_answers[];
  /**
   * An array relationship
   */
  form_addresses: ExportForms_form_form_addresses[];
  /**
   * An array relationship
   */
  custom_question_answer_bank_relationships: ExportForms_form_custom_question_answer_bank_relationships[];
  /**
   * An array relationship
   */
  form_disclaimers: ExportForms_form_form_disclaimers[];
  /**
   * An array relationship
   */
  form_verification_results: ExportForms_form_form_verification_results[];
  /**
   * An array relationship
   */
  tags: ExportForms_form_tags[];
  /**
   * An object relationship
   */
  previous_offer: ExportForms_form_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: ExportForms_form_previous_waitlist | null;
  /**
   * An object relationship
   */
  previous_form: ExportForms_form_previous_form | null;
  /**
   * An array relationship
   */
  form_school_ranks: ExportForms_form_form_school_ranks[];
}

export interface ExportForms {
  /**
   * fetch data from the table: "form"
   */
  form: ExportForms_form[];
}

export interface ExportFormsVariables {
  form_ids?: uuid[] | null;
  form_school_rank_ids?: uuid[] | null;
  skip_rank: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchFormStatus
// ====================================================

export interface FetchFormStatus_form {
  __typename: "form";
  status: form_status_enum;
}

export interface FetchFormStatus {
  /**
   * fetch data from the table: "form"
   */
  form: FetchFormStatus_form[];
}

export interface FetchFormStatusVariables {
  form_ids?: uuid[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchFormRecipients
// ====================================================

export interface FetchFormRecipients_form_person_first_relationship_second {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface FetchFormRecipients_form_person_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: FetchFormRecipients_form_person_first_relationship_second;
}

export interface FetchFormRecipients_form_person_second_relationship_first {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface FetchFormRecipients_form_person_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: FetchFormRecipients_form_person_second_relationship_first;
}

export interface FetchFormRecipients_form_person {
  __typename: "person";
  id: uuid;
  /**
   * An array relationship
   */
  first_relationship: FetchFormRecipients_form_person_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: FetchFormRecipients_form_person_second_relationship[];
}

export interface FetchFormRecipients_form {
  __typename: "form";
  /**
   * An object relationship
   */
  person: FetchFormRecipients_form_person;
}

export interface FetchFormRecipients {
  /**
   * fetch data from the table: "form"
   */
  form: FetchFormRecipients_form[];
}

export interface FetchFormRecipientsVariables {
  form_ids: uuid[];
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchOfferWaitlistStatus
// ====================================================

export interface FetchOfferWaitlistStatus_form_school_rank_form {
  __typename: "form";
  status: form_status_enum;
}

export interface FetchOfferWaitlistStatus_form_school_rank_offers_assigned_forms {
  __typename: "assigned_form";
  id: uuid;
}

export interface FetchOfferWaitlistStatus_form_school_rank_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
  /**
   * An array relationship
   */
  assigned_forms: FetchOfferWaitlistStatus_form_school_rank_offers_assigned_forms[];
}

export interface FetchOfferWaitlistStatus_form_school_rank_waitlists_assigned_forms {
  __typename: "assigned_form";
  id: uuid;
}

export interface FetchOfferWaitlistStatus_form_school_rank_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  /**
   * An array relationship
   */
  assigned_forms: FetchOfferWaitlistStatus_form_school_rank_waitlists_assigned_forms[];
}

export interface FetchOfferWaitlistStatus_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  form_id: uuid;
  school_id: uuid;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  form: FetchOfferWaitlistStatus_form_school_rank_form;
  /**
   * An array relationship
   */
  offers: FetchOfferWaitlistStatus_form_school_rank_offers[];
  /**
   * An array relationship
   */
  waitlists: FetchOfferWaitlistStatus_form_school_rank_waitlists[];
}

export interface FetchOfferWaitlistStatus {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: FetchOfferWaitlistStatus_form_school_rank[];
}

export interface FetchOfferWaitlistStatusVariables {
  search_keys: form_school_rank_bool_exp[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGradeIdsWithFormSchool
// ====================================================

export interface FetchGradeIdsWithFormSchool_form_school_grade {
  __typename: "form_school_grade";
  form_id: uuid | null;
  school_id: uuid | null;
  grade_id: uuid | null;
  form_school_rank_id: uuid | null;
}

export interface FetchGradeIdsWithFormSchool {
  /**
   * fetch data from the table: "form_school_grade"
   */
  form_school_grade: FetchGradeIdsWithFormSchool_form_school_grade[];
}

export interface FetchGradeIdsWithFormSchoolVariables {
  query: form_school_grade_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchSeatsAvailable
// ====================================================

export interface FetchSeatsAvailable_grade_school {
  __typename: "school";
  name: string;
}

export interface FetchSeatsAvailable_grade_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface FetchSeatsAvailable_grade {
  __typename: "grade";
  id: uuid;
  /**
   * An object relationship
   */
  school: FetchSeatsAvailable_grade_school;
  /**
   * An object relationship
   */
  grade_config: FetchSeatsAvailable_grade_grade_config;
  seats_available: number | null;
}

export interface FetchSeatsAvailable {
  /**
   * fetch data from the table: "grade"
   */
  grade: FetchSeatsAvailable_grade[];
}

export interface FetchSeatsAvailableVariables {
  grade_ids?: uuid[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProgramGroupsByOrg
// ====================================================

export interface GetProgramGroupsByOrg_program_group_programs {
  __typename: "program";
  id: uuid;
  label: citext;
  order: number;
}

export interface GetProgramGroupsByOrg_program_group {
  __typename: "program_group";
  id: uuid;
  name: citext;
  organization_id: uuid;
  /**
   * An array relationship
   */
  programs: GetProgramGroupsByOrg_program_group_programs[];
}

export interface GetProgramGroupsByOrg {
  /**
   * fetch data from the table: "program_group"
   */
  program_group: GetProgramGroupsByOrg_program_group[];
}

export interface GetProgramGroupsByOrgVariables {
  organization_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVerificationsForAdmin
// ====================================================

export interface GetVerificationsForAdmin_form_verification_form_questions {
  __typename: "form_question";
  question_id: uuid;
}

export interface GetVerificationsForAdmin_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
  /**
   * An array relationship
   */
  form_questions: GetVerificationsForAdmin_form_verification_form_questions[];
}

export interface GetVerificationsForAdmin_form_verification_result {
  __typename: "form_verification_result";
  id: uuid;
  form_verification_id: uuid;
  verification_status: verification_status_enum;
}

export interface GetVerificationsForAdmin {
  /**
   * fetch data from the table: "form_verification"
   */
  form_verification: GetVerificationsForAdmin_form_verification[];
  /**
   * fetch data from the table: "form_verification_result"
   */
  form_verification_result: GetVerificationsForAdmin_form_verification_result[];
}

export interface GetVerificationsForAdminVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetApplicableVerificationsSummaries
// ====================================================

export interface GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option_form_answer_options_form_answer {
  __typename: "form_answer";
  form_id: uuid;
}

export interface GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option_form_answer_options {
  __typename: "form_answer_option";
  /**
   * An object relationship
   */
  form_answer: GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option_form_answer_options_form_answer;
}

export interface GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option {
  __typename: "form_question_option";
  /**
   * An array relationship
   */
  form_answer_options: GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option_form_answer_options[];
}

export interface GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  form_question_option: GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions_form_question_option;
}

export interface GetApplicableVerificationsSummaries_form_verification_form_questions_question {
  __typename: "question";
  form_template_section_id: uuid | null;
  /**
   * An array relationship
   */
  ancestor_questions: GetApplicableVerificationsSummaries_form_verification_form_questions_question_ancestor_questions[];
}

export interface GetApplicableVerificationsSummaries_form_verification_form_questions {
  __typename: "form_question";
  /**
   * An object relationship
   */
  question: GetApplicableVerificationsSummaries_form_verification_form_questions_question;
}

export interface GetApplicableVerificationsSummaries_form_verification_form_verification_results {
  __typename: "form_verification_result";
  form_id: uuid;
  verification_status: verification_status_enum;
}

export interface GetApplicableVerificationsSummaries_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
  /**
   * An array relationship
   */
  form_questions: GetApplicableVerificationsSummaries_form_verification_form_questions[];
  /**
   * An array relationship
   */
  form_verification_results: GetApplicableVerificationsSummaries_form_verification_form_verification_results[];
}

export interface GetApplicableVerificationsSummaries {
  /**
   * fetch data from the table: "form_verification"
   */
  form_verification: GetApplicableVerificationsSummaries_form_verification[];
}

export interface GetApplicableVerificationsSummariesVariables {
  form_template_id: uuid;
  form_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormMessages
// ====================================================

export interface GetFormMessages_message_adhoc_receiver_message_adhoc_sender_person_user {
  __typename: "user";
  name: string | null;
}

export interface GetFormMessages_message_adhoc_receiver_message_adhoc_sender_person {
  __typename: "person";
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
  /**
   * An object relationship
   */
  user: GetFormMessages_message_adhoc_receiver_message_adhoc_sender_person_user | null;
}

export interface GetFormMessages_message_adhoc_receiver_message_adhoc_message_adhoc_payload_email {
  __typename: "message_adhoc_payload_email";
  message_body: string;
  message_subject: string;
}

export interface GetFormMessages_message_adhoc_receiver_message_adhoc_message_adhoc_payload_sms {
  __typename: "message_adhoc_payload_sms";
  message_body: string;
}

export interface GetFormMessages_message_adhoc_receiver_message_adhoc {
  __typename: "message_adhoc";
  /**
   * An object relationship
   */
  sender_person: GetFormMessages_message_adhoc_receiver_message_adhoc_sender_person | null;
  /**
   * An object relationship
   */
  message_adhoc_payload_email: GetFormMessages_message_adhoc_receiver_message_adhoc_message_adhoc_payload_email | null;
  /**
   * An object relationship
   */
  message_adhoc_payload_sms: GetFormMessages_message_adhoc_receiver_message_adhoc_message_adhoc_payload_sms | null;
}

export interface GetFormMessages_message_adhoc_receiver_receiver_person {
  __typename: "person";
  phone_number: string | null;
  email_address: string | null;
}

export interface GetFormMessages_message_adhoc_receiver {
  __typename: "message_adhoc_receiver";
  message_id: uuid;
  message_type: message_type_enum;
  sent_at: timestamptz | null;
  /**
   * An object relationship
   */
  message_adhoc: GetFormMessages_message_adhoc_receiver_message_adhoc;
  /**
   * An object relationship
   */
  receiver_person: GetFormMessages_message_adhoc_receiver_receiver_person | null;
  receiver_contact_information: string | null;
  message_status: message_status_enum;
}

export interface GetFormMessages_message_form_status_form_form_template_enrollment_period_organization {
  __typename: "organization";
  id: uuid;
  name: string;
}

export interface GetFormMessages_message_form_status_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  /**
   * An object relationship
   */
  organization: GetFormMessages_message_form_status_form_form_template_enrollment_period_organization | null;
}

export interface GetFormMessages_message_form_status_form_form_template {
  __typename: "form_template";
  /**
   * An object relationship
   */
  enrollment_period: GetFormMessages_message_form_status_form_form_template_enrollment_period;
}

export interface GetFormMessages_message_form_status_form {
  __typename: "form";
  /**
   * An object relationship
   */
  form_template: GetFormMessages_message_form_status_form_form_template;
}

export interface GetFormMessages_message_form_status {
  __typename: "message_form_status";
  id: uuid;
  message_type: message_type_enum;
  form_transition: form_transition_enum;
  created_at: timestamptz;
  /**
   * An object relationship
   */
  form: GetFormMessages_message_form_status_form;
  receiver_contact_information: string | null;
  message_subject: string | null;
  message_body: string | null;
  message_status: message_status_enum;
}

export interface GetFormMessages_message_offer_status_offer_form_form_template_enrollment_period_organization {
  __typename: "organization";
  id: uuid;
  name: string;
}

export interface GetFormMessages_message_offer_status_offer_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  /**
   * An object relationship
   */
  organization: GetFormMessages_message_offer_status_offer_form_form_template_enrollment_period_organization | null;
}

export interface GetFormMessages_message_offer_status_offer_form_form_template {
  __typename: "form_template";
  /**
   * An object relationship
   */
  enrollment_period: GetFormMessages_message_offer_status_offer_form_form_template_enrollment_period;
}

export interface GetFormMessages_message_offer_status_offer_form {
  __typename: "form";
  /**
   * An object relationship
   */
  form_template: GetFormMessages_message_offer_status_offer_form_form_template;
}

export interface GetFormMessages_message_offer_status_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  form: GetFormMessages_message_offer_status_offer_form;
}

export interface GetFormMessages_message_offer_status {
  __typename: "message_offer_status";
  id: uuid;
  message_type: message_type_enum;
  offer_transition: string;
  created_at: timestamptz;
  /**
   * An object relationship
   */
  offer: GetFormMessages_message_offer_status_offer;
  receiver_contact_information: string | null;
  message_subject: string | null;
  message_body: string | null;
  message_status: message_status_enum;
}

export interface GetFormMessages_message_waitlist_status_waitlist_form_form_template_enrollment_period_organization {
  __typename: "organization";
  id: uuid;
  name: string;
}

export interface GetFormMessages_message_waitlist_status_waitlist_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  /**
   * An object relationship
   */
  organization: GetFormMessages_message_waitlist_status_waitlist_form_form_template_enrollment_period_organization | null;
}

export interface GetFormMessages_message_waitlist_status_waitlist_form_form_template {
  __typename: "form_template";
  /**
   * An object relationship
   */
  enrollment_period: GetFormMessages_message_waitlist_status_waitlist_form_form_template_enrollment_period;
}

export interface GetFormMessages_message_waitlist_status_waitlist_form {
  __typename: "form";
  /**
   * An object relationship
   */
  form_template: GetFormMessages_message_waitlist_status_waitlist_form_form_template;
}

export interface GetFormMessages_message_waitlist_status_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  form: GetFormMessages_message_waitlist_status_waitlist_form;
}

export interface GetFormMessages_message_waitlist_status {
  __typename: "message_waitlist_status";
  id: uuid;
  message_type: message_type_enum;
  waitlist_transition: string;
  created_at: timestamptz;
  /**
   * An object relationship
   */
  waitlist: GetFormMessages_message_waitlist_status_waitlist;
  receiver_contact_information: string | null;
  message_subject: string | null;
  message_body: string | null;
  message_status: message_status_enum;
}

export interface GetFormMessages {
  /**
   * fetch data from the table: "message_adhoc_receiver"
   */
  message_adhoc_receiver: GetFormMessages_message_adhoc_receiver[];
  /**
   * fetch data from the table: "message_form_status"
   */
  message_form_status: GetFormMessages_message_form_status[];
  /**
   * fetch data from the table: "message_offer_status"
   */
  message_offer_status: GetFormMessages_message_offer_status[];
  /**
   * fetch data from the table: "message_waitlist_status"
   */
  message_waitlist_status: GetFormMessages_message_waitlist_status[];
}

export interface GetFormMessagesVariables {
  form_id: uuid;
  recipient_person_ids: uuid[];
  message_receiver_filter: message_adhoc_receiver_bool_exp;
  skip_content: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignedFormsByFormId
// ====================================================

export interface GetAssignedFormsByFormId_assigned_form_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
}

export interface GetAssignedFormsByFormId_assigned_form_person {
  __typename: "person";
  first_name: string | null;
}

export interface GetAssignedFormsByFormId_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  /**
   * An object relationship
   */
  form_template: GetAssignedFormsByFormId_assigned_form_form_template;
  person_id: uuid;
  /**
   * An object relationship
   */
  person: GetAssignedFormsByFormId_assigned_form_person;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
}

export interface GetAssignedFormsByFormId {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetAssignedFormsByFormId_assigned_form[];
}

export interface GetAssignedFormsByFormIdVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveFormSchoolsRankAdmin
// ====================================================

export interface SaveFormSchoolsRankAdmin_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface SaveFormSchoolsRankAdmin_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface SaveFormSchoolsRankAdmin_delete_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface SaveFormSchoolsRankAdmin_insert_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface SaveFormSchoolsRankAdmin_insert_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: SaveFormSchoolsRankAdmin_insert_form_school_rank_returning[];
}

export interface SaveFormSchoolsRankAdmin {
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: SaveFormSchoolsRankAdmin_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: SaveFormSchoolsRankAdmin_update_offer | null;
  /**
   * delete data from the table: "form_school_rank"
   */
  delete_form_school_rank: SaveFormSchoolsRankAdmin_delete_form_school_rank | null;
  /**
   * insert data into the table: "form_school_rank"
   */
  insert_form_school_rank: SaveFormSchoolsRankAdmin_insert_form_school_rank | null;
}

export interface SaveFormSchoolsRankAdminVariables {
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
  deleted_school_ranks: form_school_rank_bool_exp;
  upserted_school_ranks: form_school_rank_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFreeTextAnswerHistory
// ====================================================

export interface GetFreeTextAnswerHistory_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * Transaction start timestamp for tx in which audited event occurred
   */
  action_tstamp_tx: timestamptz;
  /**
   * Record `id` field value, if applicable.
   */
  row_id: uuid | null;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
}

export interface GetFreeTextAnswerHistory_audit_form_transaction {
  __typename: "audit_form_transaction";
  /**
   * An array relationship
   */
  logged_actions: GetFreeTextAnswerHistory_audit_form_transaction_logged_actions[];
}

export interface GetFreeTextAnswerHistory {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetFreeTextAnswerHistory_audit_form_transaction[];
}

export interface GetFreeTextAnswerHistoryVariables {
  formId: uuid;
  questionId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGradesAnswerHistory
// ====================================================

export interface GetGradesAnswerHistory_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * Transaction start timestamp for tx in which audited event occurred
   */
  action_tstamp_tx: timestamptz;
  /**
   * Record `id` field value, if applicable.
   */
  row_id: uuid | null;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
  /**
   * Non-schema-qualified table name of table event occured in
   */
  table_name: string;
  /**
   * Action type; I = insert, D = delete, U = update, T = truncate
   */
  action: string;
}

export interface GetGradesAnswerHistory_audit_form_transaction {
  __typename: "audit_form_transaction";
  form_id: uuid | null;
  /**
   * An array relationship
   */
  logged_actions: GetGradesAnswerHistory_audit_form_transaction_logged_actions[];
}

export interface GetGradesAnswerHistory {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetGradesAnswerHistory_audit_form_transaction[];
}

export interface GetGradesAnswerHistoryVariables {
  formId: uuid;
  questionId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMultiSelectAnswerHistory
// ====================================================

export interface GetMultiSelectAnswerHistory_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * Transaction start timestamp for tx in which audited event occurred
   */
  action_tstamp_tx: timestamptz;
  /**
   * Record `id` field value, if applicable.
   */
  row_id: uuid | null;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
  /**
   * Non-schema-qualified table name of table event occured in
   */
  table_name: string;
  /**
   * Action type; I = insert, D = delete, U = update, T = truncate
   */
  action: string;
}

export interface GetMultiSelectAnswerHistory_audit_form_transaction {
  __typename: "audit_form_transaction";
  form_id: uuid | null;
  /**
   * An array relationship
   */
  logged_actions: GetMultiSelectAnswerHistory_audit_form_transaction_logged_actions[];
}

export interface GetMultiSelectAnswerHistory {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetMultiSelectAnswerHistory_audit_form_transaction[];
}

export interface GetMultiSelectAnswerHistoryVariables {
  formId: uuid;
  questionId: uuid;
  formQuestionOptionFilter?: audit_logged_actions_bool_exp[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSingleSelectAnswerHistory
// ====================================================

export interface GetSingleSelectAnswerHistory_audit_form_transaction_logged_actions {
  __typename: "audit_logged_actions";
  /**
   * Transaction start timestamp for tx in which audited event occurred
   */
  action_tstamp_tx: timestamptz;
  /**
   * Record `id` field value, if applicable.
   */
  row_id: uuid | null;
  /**
   * Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple.
   */
  row_data: jsonb | null;
  /**
   * Non-schema-qualified table name of table event occured in
   */
  table_name: string;
  /**
   * Action type; I = insert, D = delete, U = update, T = truncate
   */
  action: string;
}

export interface GetSingleSelectAnswerHistory_audit_form_transaction {
  __typename: "audit_form_transaction";
  form_id: uuid | null;
  /**
   * An array relationship
   */
  logged_actions: GetSingleSelectAnswerHistory_audit_form_transaction_logged_actions[];
}

export interface GetSingleSelectAnswerHistory {
  /**
   * fetch data from the table: "audit.form_transaction"
   */
  audit_form_transaction: GetSingleSelectAnswerHistory_audit_form_transaction[];
}

export interface GetSingleSelectAnswerHistoryVariables {
  formId: uuid;
  questionId: uuid;
  formQuestionOptionFilter?: audit_logged_actions_bool_exp[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertMatchConfiguration
// ====================================================

export interface UpsertMatchConfiguration_insert_match_config_one {
  __typename: "match_config";
  id: uuid;
}

export interface UpsertMatchConfiguration {
  /**
   * insert a single row into the table: "match_config"
   */
  insert_match_config_one: UpsertMatchConfiguration_insert_match_config_one | null;
}

export interface UpsertMatchConfigurationVariables {
  matchConfig: match_config_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBinConfiguration
// ====================================================

export interface CreateBinConfiguration_insert_bin_config_one {
  __typename: "bin_config";
  id: uuid;
}

export interface CreateBinConfiguration_update_bin_many {
  __typename: "bin_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CreateBinConfiguration {
  /**
   * insert a single row into the table: "bin_config"
   */
  insert_bin_config_one: CreateBinConfiguration_insert_bin_config_one | null;
  /**
   * update multiples rows of table: "bin"
   */
  update_bin_many: (CreateBinConfiguration_update_bin_many | null)[] | null;
}

export interface CreateBinConfigurationVariables {
  binConfigId: uuid;
  binConfig: bin_config_insert_input;
  enrollmentPeriodId: uuid;
  schoolId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBinConfigurationByPk
// ====================================================

export interface UpdateBinConfigurationByPk_update_bin_config_by_pk {
  __typename: "bin_config";
  id: uuid;
}

export interface UpdateBinConfigurationByPk {
  /**
   * update single row of the table: "bin_config"
   */
  update_bin_config_by_pk: UpdateBinConfigurationByPk_update_bin_config_by_pk | null;
}

export interface UpdateBinConfigurationByPkVariables {
  binConfigId: uuid;
  binConfig: bin_config_set_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMatchDetails
// ====================================================

export interface GetMatchDetails_match_run_by_pk {
  __typename: "match_run";
  name: string | null;
  created_at: timestamptz;
  results_document_id: uuid | null;
  match_config_snapshot_document_id: uuid | null;
  capacities_snapshot_document_id: uuid | null;
}

export interface GetMatchDetails {
  /**
   * fetch data from the table: "match_run" using primary key columns
   */
  match_run_by_pk: GetMatchDetails_match_run_by_pk | null;
}

export interface GetMatchDetailsVariables {
  matchId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForMatch
// ====================================================

export interface GetSchoolsForMatch_school_school_default_bin_configs {
  __typename: "school_default_bin_config";
  id: uuid;
  bin_config_id: uuid;
}

export interface GetSchoolsForMatch_school {
  __typename: "school";
  id: uuid;
  name: string;
  reference_id: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  /**
   * An array relationship
   */
  school_default_bin_configs: GetSchoolsForMatch_school_school_default_bin_configs[];
}

export interface GetSchoolsForMatch {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsForMatch_school[];
}

export interface GetSchoolsForMatchVariables {
  organizationId: uuid;
  search: school_bool_exp;
  school_filter: school_bool_exp;
  enrollmentPeriodId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMatchConfiguration
// ====================================================

export interface GetMatchConfiguration_match_config_bin_configs {
  __typename: "bin_config";
  id: uuid;
  name: string | null;
  parameters: jsonb | null;
  sort_fields: jsonb | null;
  priority_groups: jsonb | null;
}

export interface GetMatchConfiguration_match_config {
  __typename: "match_config";
  id: uuid;
  name: string;
  parameters: jsonb | null;
  default_bin_config_id: uuid | null;
  enrollment_period_id: uuid;
  /**
   * An array relationship
   */
  bin_configs: GetMatchConfiguration_match_config_bin_configs[];
}

export interface GetMatchConfiguration {
  /**
   * fetch data from the table: "match_config"
   */
  match_config: GetMatchConfiguration_match_config[];
}

export interface GetMatchConfigurationVariables {
  enrollmentPeriodId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBinConfigurationById
// ====================================================

export interface GetBinConfigurationById_bin_config_by_pk {
  __typename: "bin_config";
  id: uuid;
  name: string | null;
  parameters: jsonb | null;
  sort_fields: jsonb | null;
  priority_groups: jsonb | null;
}

export interface GetBinConfigurationById {
  /**
   * fetch data from the table: "bin_config" using primary key columns
   */
  bin_config_by_pk: GetBinConfigurationById_bin_config_by_pk | null;
}

export interface GetBinConfigurationByIdVariables {
  binConfigId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolDefaultBinConfig
// ====================================================

export interface GetSchoolDefaultBinConfig_school_default_bin_config {
  __typename: "school_default_bin_config";
  id: uuid;
  bin_config_id: uuid;
}

export interface GetSchoolDefaultBinConfig {
  /**
   * fetch data from the table: "school_default_bin_config"
   */
  school_default_bin_config: GetSchoolDefaultBinConfig_school_default_bin_config[];
}

export interface GetSchoolDefaultBinConfigVariables {
  schoolId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMatchRuns
// ====================================================

export interface GetMatchRuns_match_run_owner_people {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
}

export interface GetMatchRuns_match_run_owner {
  __typename: "user";
  id: string;
  /**
   * An array relationship
   */
  people: GetMatchRuns_match_run_owner_people[];
}

export interface GetMatchRuns_match_run {
  __typename: "match_run";
  id: uuid;
  name: string | null;
  created_at: timestamptz;
  /**
   * An object relationship
   */
  owner: GetMatchRuns_match_run_owner;
  status: match_run_status_enum | null;
  status_message: string | null;
}

export interface GetMatchRuns {
  /**
   * fetch data from the table: "match_run"
   */
  match_run: GetMatchRuns_match_run[];
}

export interface GetMatchRunsVariables {
  enrollmentPeriodId: uuid;
  limit?: number | null;
  offset?: number | null;
  order_by?: match_run_order_by[] | null;
  school_filter: match_run_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentsAvatars
// ====================================================

export interface GetStudentsAvatars_person {
  __typename: "person";
  id: uuid;
  avatar: string | null;
}

export interface GetStudentsAvatars {
  /**
   * fetch data from the table: "person"
   */
  person: GetStudentsAvatars_person[];
}

export interface GetStudentsAvatarsVariables {
  studentIds: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchTemplates
// ====================================================

export interface CreateMatchTemplates_insert_match_template_returning {
  __typename: "match_template";
  id: uuid;
}

export interface CreateMatchTemplates_insert_match_template {
  __typename: "match_template_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: CreateMatchTemplates_insert_match_template_returning[];
}

export interface CreateMatchTemplates {
  /**
   * insert data into the table: "match_template"
   */
  insert_match_template: CreateMatchTemplates_insert_match_template | null;
}

export interface CreateMatchTemplatesVariables {
  match_templates: match_template_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelectMatchTemplate
// ====================================================

export interface SelectMatchTemplate_insert_match_template_organization_one {
  __typename: "match_template_organization";
  id: uuid;
}

export interface SelectMatchTemplate {
  /**
   * insert a single row into the table: "match_template_organization"
   */
  insert_match_template_organization_one: SelectMatchTemplate_insert_match_template_organization_one | null;
}

export interface SelectMatchTemplateVariables {
  organization_id: uuid;
  match_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMatchTemplates
// ====================================================

export interface DeleteMatchTemplates_delete_match_template_organization {
  __typename: "match_template_organization_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteMatchTemplates_delete_match_template {
  __typename: "match_template_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteMatchTemplates {
  /**
   * delete data from the table: "match_template_organization"
   */
  delete_match_template_organization: DeleteMatchTemplates_delete_match_template_organization | null;
  /**
   * delete data from the table: "match_template"
   */
  delete_match_template: DeleteMatchTemplates_delete_match_template | null;
}

export interface DeleteMatchTemplatesVariables {
  match_template_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchMatchTemplates
// ====================================================

export interface FetchMatchTemplates_match_template_match_template_organizations {
  __typename: "match_template_organization";
  id: uuid;
}

export interface FetchMatchTemplates_match_template {
  __typename: "match_template";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  match_template_organizations: FetchMatchTemplates_match_template_match_template_organizations[];
}

export interface FetchMatchTemplates {
  /**
   * fetch data from the table: "match_template"
   */
  match_template: FetchMatchTemplates_match_template[];
}

export interface FetchMatchTemplatesVariables {
  organization_id: uuid;
  order_by: match_template_order_by;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMessage
// ====================================================

export interface SendMessage_insert_message_adhoc_one {
  __typename: "message_adhoc";
  id: uuid;
}

export interface SendMessage {
  /**
   * insert a single row into the table: "message_adhoc"
   */
  insert_message_adhoc_one: SendMessage_insert_message_adhoc_one | null;
}

export interface SendMessageVariables {
  message: message_adhoc_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMessages
// ====================================================

export interface GetMessages_message_adhoc_aggregate_aggregate {
  __typename: "message_adhoc_aggregate_fields";
  count: number;
}

export interface GetMessages_message_adhoc_aggregate {
  __typename: "message_adhoc_aggregate";
  aggregate: GetMessages_message_adhoc_aggregate_aggregate | null;
}

export interface GetMessages_message_adhoc_sender_person {
  __typename: "person";
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
}

export interface GetMessages_message_adhoc_message_adhoc_receivers {
  __typename: "message_adhoc_receiver";
  sent_at: timestamptz | null;
  message_status: message_status_enum;
}

export interface GetMessages_message_adhoc_message_adhoc_receivers_aggregate_aggregate {
  __typename: "message_adhoc_receiver_aggregate_fields";
  count: number;
}

export interface GetMessages_message_adhoc_message_adhoc_receivers_aggregate {
  __typename: "message_adhoc_receiver_aggregate";
  aggregate: GetMessages_message_adhoc_message_adhoc_receivers_aggregate_aggregate | null;
}

export interface GetMessages_message_adhoc_message_adhoc_receivers_aggregate_sent_aggregate {
  __typename: "message_adhoc_receiver_aggregate_fields";
  count: number;
}

export interface GetMessages_message_adhoc_message_adhoc_receivers_aggregate_sent {
  __typename: "message_adhoc_receiver_aggregate";
  aggregate: GetMessages_message_adhoc_message_adhoc_receivers_aggregate_sent_aggregate | null;
}

export interface GetMessages_message_adhoc_message_adhoc_payload_email {
  __typename: "message_adhoc_payload_email";
  message_body: string;
}

export interface GetMessages_message_adhoc_message_adhoc_payload_sms {
  __typename: "message_adhoc_payload_sms";
  message_body: string;
}

export interface GetMessages_message_adhoc {
  __typename: "message_adhoc";
  id: uuid;
  /**
   * An object relationship
   */
  sender_person: GetMessages_message_adhoc_sender_person | null;
  /**
   * An array relationship
   */
  message_adhoc_receivers: GetMessages_message_adhoc_message_adhoc_receivers[];
  /**
   * An aggregate relationship
   */
  message_adhoc_receivers_aggregate: GetMessages_message_adhoc_message_adhoc_receivers_aggregate;
  /**
   * An aggregate relationship
   */
  message_adhoc_receivers_aggregate_sent: GetMessages_message_adhoc_message_adhoc_receivers_aggregate_sent;
  /**
   * An object relationship
   */
  message_adhoc_payload_email: GetMessages_message_adhoc_message_adhoc_payload_email | null;
  /**
   * An object relationship
   */
  message_adhoc_payload_sms: GetMessages_message_adhoc_message_adhoc_payload_sms | null;
}

export interface GetMessages {
  /**
   * fetch aggregated fields from the table: "message_adhoc"
   */
  message_adhoc_aggregate: GetMessages_message_adhoc_aggregate;
  /**
   * fetch data from the table: "message_adhoc"
   */
  message_adhoc: GetMessages_message_adhoc[];
}

export interface GetMessagesVariables {
  organization_id?: uuid | null;
  offset?: number | null;
  limit?: number | null;
  order_by?: message_adhoc_order_by[] | null;
  message_filter: message_adhoc_bool_exp;
  message_receiver_filter: message_adhoc_receiver_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecipientsDetails
// ====================================================

export interface GetRecipientsDetails_person_second_relationship_first {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
}

export interface GetRecipientsDetails_person_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetRecipientsDetails_person_second_relationship_first;
}

export interface GetRecipientsDetails_person_first_relationship_second {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
}

export interface GetRecipientsDetails_person_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetRecipientsDetails_person_first_relationship_second;
}

export interface GetRecipientsDetails_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetRecipientsDetails_person {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
  /**
   * An array relationship
   */
  second_relationship: GetRecipientsDetails_person_second_relationship[];
  /**
   * An array relationship
   */
  first_relationship: GetRecipientsDetails_person_first_relationship[];
  email_address: string | null;
  phone_number: string | null;
  email_okay: boolean | null;
  sms_okay: boolean | null;
  /**
   * An object relationship
   */
  user: GetRecipientsDetails_person_user | null;
  preferred_language: string | null;
}

export interface GetRecipientsDetails {
  /**
   * fetch data from the table: "person"
   */
  person: GetRecipientsDetails_person[];
}

export interface GetRecipientsDetailsVariables {
  parents_id?: uuid[] | null;
  offset?: number | null;
  limit?: number | null;
  order_by?: person_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMessageById
// ====================================================

export interface GetMessageById_message_adhoc_by_pk_message_adhoc_receivers_receiver_person {
  __typename: "person";
  id: uuid;
  /**
   * A computed field, executes function "full_name"
   */
  full_name: string | null;
  email_address: string | null;
  phone_number: string | null;
  email_okay: boolean | null;
  sms_okay: boolean | null;
  preferred_language: string | null;
}

export interface GetMessageById_message_adhoc_by_pk_message_adhoc_receivers {
  __typename: "message_adhoc_receiver";
  /**
   * An object relationship
   */
  receiver_person: GetMessageById_message_adhoc_by_pk_message_adhoc_receivers_receiver_person | null;
  message_status: message_status_enum;
}

export interface GetMessageById_message_adhoc_by_pk_message_adhoc_payload_email {
  __typename: "message_adhoc_payload_email";
  message_body: string;
  message_subject: string;
}

export interface GetMessageById_message_adhoc_by_pk_message_adhoc_payload_sms {
  __typename: "message_adhoc_payload_sms";
  message_body: string;
}

export interface GetMessageById_message_adhoc_by_pk {
  __typename: "message_adhoc";
  id: uuid;
  /**
   * An array relationship
   */
  message_adhoc_receivers: GetMessageById_message_adhoc_by_pk_message_adhoc_receivers[];
  /**
   * An object relationship
   */
  message_adhoc_payload_email: GetMessageById_message_adhoc_by_pk_message_adhoc_payload_email | null;
  /**
   * An object relationship
   */
  message_adhoc_payload_sms: GetMessageById_message_adhoc_by_pk_message_adhoc_payload_sms | null;
}

export interface GetMessageById {
  /**
   * fetch data from the table: "message_adhoc" using primary key columns
   */
  message_adhoc_by_pk: GetMessageById_message_adhoc_by_pk | null;
}

export interface GetMessageByIdVariables {
  message_id: uuid;
  message_receiver_filter: message_adhoc_receiver_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSenderId
// ====================================================

export interface GetSenderId_person {
  __typename: "person";
  id: uuid;
}

export interface GetSenderId {
  /**
   * fetch data from the table: "person"
   */
  person: GetSenderId_person[];
}

export interface GetSenderIdVariables {
  userId: string;
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateParent
// ====================================================

export interface CreateParent_insert_person_one {
  __typename: "person";
  id: uuid;
}

export interface CreateParent {
  /**
   * insert a single row into the table: "person"
   */
  insert_person_one: CreateParent_insert_person_one | null;
}

export interface CreateParentVariables {
  parent: person_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCreateParents
// ====================================================

export interface BulkCreateParents_insert_person {
  __typename: "person_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkCreateParents {
  /**
   * insert data into the table: "person"
   */
  insert_person: BulkCreateParents_insert_person | null;
}

export interface BulkCreateParentsVariables {
  parents: person_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateParent
// ====================================================

export interface UpdateParent_update_person_by_pk {
  __typename: "person";
  id: uuid;
}

export interface UpdateParent {
  /**
   * update single row of the table: "person"
   */
  update_person_by_pk: UpdateParent_update_person_by_pk | null;
}

export interface UpdateParentVariables {
  id: uuid;
  parent?: person_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrganizationParent
// ====================================================

export interface DeleteOrganizationParent_delete_person_by_pk {
  __typename: "person";
  id: uuid;
}

export interface DeleteOrganizationParent {
  /**
   * delete single row from the table: "person"
   */
  delete_person_by_pk: DeleteOrganizationParent_delete_person_by_pk | null;
}

export interface DeleteOrganizationParentVariables {
  parentId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactInfo
// ====================================================

export interface UpdateContactInfo_update_contact_info {
  __typename: "UpdateContactInfoOutput";
  message: string | null;
  status_code: number | null;
  user_id: string | null;
}

export interface UpdateContactInfo {
  /**
   * update_contact_info
   */
  update_contact_info: UpdateContactInfo_update_contact_info | null;
}

export interface UpdateContactInfoVariables {
  user_id: string;
  client_id?: string | null;
  email_address?: string | null;
  phone_number?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetParent
// ====================================================

export interface GetParent_person_by_pk_user {
  __typename: "user";
  id: string;
  name: string | null;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface GetParent_person_by_pk_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetParent_person_by_pk {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetParent_person_by_pk_user | null;
  /**
   * An array relationship
   */
  person_bins: GetParent_person_by_pk_person_bins[];
}

export interface GetParent {
  /**
   * fetch data from the table: "person" using primary key columns
   */
  person_by_pk: GetParent_person_by_pk | null;
}

export interface GetParentVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetParents
// ====================================================

export interface GetParents_person_aggregate_totals {
  __typename: "person_aggregate_fields";
  total: number;
}

export interface GetParents_person_aggregate {
  __typename: "person_aggregate";
  totals: GetParents_person_aggregate_totals | null;
}

export interface GetParents_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetParents_person_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetParents_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetParents_person_user | null;
  /**
   * An array relationship
   */
  person_bins: GetParents_person_person_bins[];
}

export interface GetParents {
  /**
   * fetch aggregated fields from the table: "person"
   */
  person_aggregate: GetParents_person_aggregate;
  /**
   * fetch data from the table: "person"
   */
  person: GetParents_person[];
}

export interface GetParentsVariables {
  organizationId: uuid;
  limit?: number | null;
  offset?: number | null;
  order_by?: person_order_by[] | null;
  search: person_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSchool
// ====================================================

export interface CreateSchool_insert_school_one {
  __typename: "school";
  id: uuid;
}

export interface CreateSchool {
  /**
   * insert a single row into the table: "school"
   */
  insert_school_one: CreateSchool_insert_school_one | null;
}

export interface CreateSchoolVariables {
  school: school_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkCreateSchools
// ====================================================

export interface BulkCreateSchools_insert_school {
  __typename: "school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface BulkCreateSchools {
  /**
   * insert data into the table: "school"
   */
  insert_school: BulkCreateSchools_insert_school | null;
}

export interface BulkCreateSchoolsVariables {
  schools: school_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchool
// ====================================================

export interface UpdateSchool_update_school_by_pk {
  __typename: "school";
  id: uuid;
  name: string;
  reference_id: string | null;
  status: string;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

export interface UpdateSchool {
  /**
   * update single row of the table: "school"
   */
  update_school_by_pk: UpdateSchool_update_school_by_pk | null;
}

export interface UpdateSchoolVariables {
  id: uuid;
  school?: school_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSchool
// ====================================================

export interface DeleteSchool_delete_school_by_pk {
  __typename: "school";
  id: uuid;
}

export interface DeleteSchool {
  /**
   * delete single row from the table: "school"
   */
  delete_school_by_pk: DeleteSchool_delete_school_by_pk | null;
}

export interface DeleteSchoolVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchool
// ====================================================

export interface GetSchool_school_by_pk {
  __typename: "school";
  id: uuid;
  name: string;
  reference_id: string | null;
  status: string;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

export interface GetSchool {
  /**
   * fetch data from the table: "school" using primary key columns
   */
  school_by_pk: GetSchool_school_by_pk | null;
}

export interface GetSchoolVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchools
// ====================================================

export interface GetSchools_school_aggregate_totals {
  __typename: "school_aggregate_fields";
  total: number;
}

export interface GetSchools_school_aggregate {
  __typename: "school_aggregate";
  totals: GetSchools_school_aggregate_totals | null;
}

export interface GetSchools_school {
  __typename: "school";
  id: uuid;
  name: string;
  reference_id: string | null;
  status: string;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

export interface GetSchools {
  /**
   * fetch aggregated fields from the table: "school"
   */
  school_aggregate: GetSchools_school_aggregate;
  /**
   * fetch data from the table: "school"
   */
  school: GetSchools_school[];
}

export interface GetSchoolsVariables {
  organization_id: uuid;
  limit?: number | null;
  offset?: number | null;
  order_by?: school_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateStudent
// ====================================================

export interface CreateStudent_insert_person_one {
  __typename: "person";
  id: uuid;
}

export interface CreateStudent {
  /**
   * insert a single row into the table: "person"
   */
  insert_person_one: CreateStudent_insert_person_one | null;
}

export interface CreateStudentVariables {
  student: person_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStudent
// ====================================================

export interface UpdateStudent_update_person_by_pk {
  __typename: "person";
  id: uuid;
}

export interface UpdateStudent {
  /**
   * update single row of the table: "person"
   */
  update_person_by_pk: UpdateStudent_update_person_by_pk | null;
}

export interface UpdateStudentVariables {
  id: uuid;
  student?: person_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrganizationStudent
// ====================================================

export interface DeleteOrganizationStudent_delete_person_by_pk {
  __typename: "person";
  id: uuid;
}

export interface DeleteOrganizationStudent {
  /**
   * delete single row from the table: "person"
   */
  delete_person_by_pk: DeleteOrganizationStudent_delete_person_by_pk | null;
}

export interface DeleteOrganizationStudentVariables {
  studentId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replaceApplicantAttendingSchools
// ====================================================

export interface replaceApplicantAttendingSchools_applicant_attending_schools_deleted_returning {
  __typename: "applicant_attending_school";
  person_id: uuid;
  school_id: uuid | null;
  enrollment_period_id: uuid;
}

export interface replaceApplicantAttendingSchools_applicant_attending_schools_deleted {
  __typename: "applicant_attending_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: replaceApplicantAttendingSchools_applicant_attending_schools_deleted_returning[];
}

export interface replaceApplicantAttendingSchools_applicant_attending_schools_create_returning {
  __typename: "applicant_attending_school";
  person_id: uuid;
  school_id: uuid | null;
  enrollment_period_id: uuid;
}

export interface replaceApplicantAttendingSchools_applicant_attending_schools_create {
  __typename: "applicant_attending_school_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: replaceApplicantAttendingSchools_applicant_attending_schools_create_returning[];
}

export interface replaceApplicantAttendingSchools {
  /**
   * delete data from the table: "applicant_attending_school"
   */
  applicant_attending_schools_deleted: replaceApplicantAttendingSchools_applicant_attending_schools_deleted | null;
  /**
   * insert data into the table: "applicant_attending_school"
   */
  applicant_attending_schools_create: replaceApplicantAttendingSchools_applicant_attending_schools_create | null;
}

export interface replaceApplicantAttendingSchoolsVariables {
  delete_attending_schools: applicant_attending_school_bool_exp;
  insert_attending_schools: applicant_attending_school_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentByIdAdminPortal
// ====================================================

export interface GetStudentByIdAdminPortal_person_by_pk_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_template_enrollment_period;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second_forms[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship_second;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_template_enrollment_period;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first_forms[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship_first;
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  first_relationship: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second_second_relationship[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetStudentByIdAdminPortal_person_by_pk_first_relationship_second;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_template_enrollment_period;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second_forms[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship_second;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_template_enrollment_period;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_forms_form_school_ranks[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_latest_forms[];
  /**
   * An array relationship
   */
  forms: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first_forms[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship_first;
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship_first {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  first_relationship: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first_second_relationship[];
}

export interface GetStudentByIdAdminPortal_person_by_pk_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetStudentByIdAdminPortal_person_by_pk_second_relationship_first;
}

export interface GetStudentByIdAdminPortal_person_by_pk_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetStudentByIdAdminPortal_person_by_pk_forms_form_template_enrollment_period;
}

export interface GetStudentByIdAdminPortal_person_by_pk_forms {
  __typename: "form";
  id: uuid;
  /**
   * An object relationship
   */
  form_template: GetStudentByIdAdminPortal_person_by_pk_forms_form_template;
}

export interface GetStudentByIdAdminPortal_person_by_pk_organization_schools {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_organization_enrollment_periods {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetStudentByIdAdminPortal_person_by_pk_organization {
  __typename: "organization";
  /**
   * An array relationship
   */
  schools: GetStudentByIdAdminPortal_person_by_pk_organization_schools[];
  /**
   * An array relationship
   */
  enrollment_periods: GetStudentByIdAdminPortal_person_by_pk_organization_enrollment_periods[];
}

export interface GetStudentByIdAdminPortal_person_by_pk {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdAdminPortal_person_by_pk_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdAdminPortal_person_by_pk_person_bins[];
  /**
   * An array relationship
   */
  applicant_attending_schools: GetStudentByIdAdminPortal_person_by_pk_applicant_attending_schools[];
  /**
   * An array relationship
   */
  first_relationship: GetStudentByIdAdminPortal_person_by_pk_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetStudentByIdAdminPortal_person_by_pk_second_relationship[];
  /**
   * An array relationship
   */
  forms: GetStudentByIdAdminPortal_person_by_pk_forms[];
  /**
   * An object relationship
   */
  organization: GetStudentByIdAdminPortal_person_by_pk_organization | null;
}

export interface GetStudentByIdAdminPortal {
  /**
   * fetch data from the table: "person" using primary key columns
   */
  person_by_pk: GetStudentByIdAdminPortal_person_by_pk | null;
}

export interface GetStudentByIdAdminPortalVariables {
  student_id: uuid;
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormsByStudentId
// ====================================================

export interface GetFormsByStudentId_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetFormsByStudentId_form_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: GetFormsByStudentId_form_form_template_enrollment_period;
}

export interface GetFormsByStudentId_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  /**
   * An object relationship
   */
  form_template: GetFormsByStudentId_form_form_template;
}

export interface GetFormsByStudentId {
  /**
   * fetch data from the table: "form"
   */
  form: GetFormsByStudentId_form[];
}

export interface GetFormsByStudentIdVariables {
  id: uuid;
  order_by?: form_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudents
// ====================================================

export interface GetStudents_person_aggregate_totals {
  __typename: "person_aggregate_fields";
  total: number;
}

export interface GetStudents_person_aggregate {
  __typename: "person_aggregate";
  totals: GetStudents_person_aggregate_totals | null;
}

export interface GetStudents_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudents_person_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudents_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudents_person_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudents_person_person_bins[];
}

export interface GetStudents {
  /**
   * fetch aggregated fields from the table: "person"
   */
  person_aggregate: GetStudents_person_aggregate;
  /**
   * fetch data from the table: "person"
   */
  person: GetStudents_person[];
}

export interface GetStudentsVariables {
  organizationId: uuid;
  limit?: number | null;
  offset?: number | null;
  order_by?: person_order_by[] | null;
  search: person_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActiveFormsByStudentId
// ====================================================

export interface GetActiveFormsByStudentId_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  form_template_id: uuid;
}

export interface GetActiveFormsByStudentId {
  /**
   * fetch data from the table: "form"
   */
  form: GetActiveFormsByStudentId_form[];
}

export interface GetActiveFormsByStudentIdVariables {
  id: uuid;
  order_by?: form_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPossibleStudentDuplicates
// ====================================================

export interface GetPossibleStudentDuplicates_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
  birth_date: date | null;
}

export interface GetPossibleStudentDuplicates {
  /**
   * fetch data from the table: "person"
   */
  person: GetPossibleStudentDuplicates_person[];
}

export interface GetPossibleStudentDuplicatesVariables {
  student_id: uuid;
  organization_id: uuid;
  first_name: string;
  last_name: string;
  date_of_birth: date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddFormSchoolTagAndUpdatePriorityGroups
// ====================================================

export interface AddFormSchoolTagAndUpdatePriorityGroups_insert_form_school_tag {
  __typename: "form_school_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddFormSchoolTagAndUpdatePriorityGroups_update_waitlist_many {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddFormSchoolTagAndUpdatePriorityGroups {
  /**
   * insert data into the table: "form_school_tag"
   */
  insert_form_school_tag: AddFormSchoolTagAndUpdatePriorityGroups_insert_form_school_tag | null;
  /**
   * update multiples rows of table: "waitlist"
   */
  update_waitlist_many: (AddFormSchoolTagAndUpdatePriorityGroups_update_waitlist_many | null)[] | null;
}

export interface AddFormSchoolTagAndUpdatePriorityGroupsVariables {
  form_school_tags: form_school_tag_insert_input[];
  waitlist_updates: waitlist_updates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddFormTag
// ====================================================

export interface AddFormTag_insert_form_tag {
  __typename: "form_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddFormTag {
  /**
   * insert data into the table: "form_tag"
   */
  insert_form_tag: AddFormTag_insert_form_tag | null;
}

export interface AddFormTagVariables {
  form_tags: form_tag_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFormSchoolTagAndUpdatePriorityGroups
// ====================================================

export interface RemoveFormSchoolTagAndUpdatePriorityGroups_delete_form_school_tag {
  __typename: "form_school_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface RemoveFormSchoolTagAndUpdatePriorityGroups_update_waitlist_many {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface RemoveFormSchoolTagAndUpdatePriorityGroups {
  /**
   * delete data from the table: "form_school_tag"
   */
  delete_form_school_tag: RemoveFormSchoolTagAndUpdatePriorityGroups_delete_form_school_tag | null;
  /**
   * update multiples rows of table: "waitlist"
   */
  update_waitlist_many: (RemoveFormSchoolTagAndUpdatePriorityGroups_update_waitlist_many | null)[] | null;
}

export interface RemoveFormSchoolTagAndUpdatePriorityGroupsVariables {
  condition: form_school_tag_bool_exp;
  waitlist_updates: waitlist_updates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFormTag
// ====================================================

export interface RemoveFormTag_delete_form_tag {
  __typename: "form_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface RemoveFormTag {
  /**
   * delete data from the table: "form_tag"
   */
  delete_form_tag: RemoveFormTag_delete_form_tag | null;
}

export interface RemoveFormTagVariables {
  condition: form_tag_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewTag
// ====================================================

export interface NewTag_insert_enrollment_period_tag_one {
  __typename: "enrollment_period_tag";
  id: uuid;
  enrollment_period_id: uuid;
  name: citext;
}

export interface NewTag {
  /**
   * insert a single row into the table: "enrollment_period_tag"
   */
  insert_enrollment_period_tag_one: NewTag_insert_enrollment_period_tag_one | null;
}

export interface NewTagVariables {
  enrollment_period_id: uuid;
  name: citext;
  description?: string | null;
  tag_group_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewTagGroup
// ====================================================

export interface NewTagGroup_insert_tag_group_one {
  __typename: "tag_group";
  id: uuid;
  name: string;
}

export interface NewTagGroup {
  /**
   * insert a single row into the table: "tag_group"
   */
  insert_tag_group_one: NewTagGroup_insert_tag_group_one | null;
}

export interface NewTagGroupVariables {
  enrollment_period_id: uuid;
  name: string;
  is_external: boolean;
  max_tags?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTagGroup
// ====================================================

export interface EditTagGroup_update_tag_group_by_pk {
  __typename: "tag_group";
  id: uuid;
  name: string;
}

export interface EditTagGroup {
  /**
   * update single row of the table: "tag_group"
   */
  update_tag_group_by_pk: EditTagGroup_update_tag_group_by_pk | null;
}

export interface EditTagGroupVariables {
  tag_group_id: uuid;
  name: string;
  is_external: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTagGroup
// ====================================================

export interface DeleteTagGroup_update_enrollment_period_tag_many {
  __typename: "enrollment_period_tag_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteTagGroup_delete_tag_group_by_pk {
  __typename: "tag_group";
  id: uuid;
}

export interface DeleteTagGroup {
  /**
   * update multiples rows of table: "enrollment_period_tag"
   */
  update_enrollment_period_tag_many: (DeleteTagGroup_update_enrollment_period_tag_many | null)[] | null;
  /**
   * delete single row from the table: "tag_group"
   */
  delete_tag_group_by_pk: DeleteTagGroup_delete_tag_group_by_pk | null;
}

export interface DeleteTagGroupVariables {
  tag_group_id: uuid;
  tag_ids: uuid[];
  internal_tag_group_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTag
// ====================================================

export interface EditTag_update_enrollment_period_tag_by_pk {
  __typename: "enrollment_period_tag";
  id: uuid;
  enrollment_period_id: uuid;
  name: citext;
}

export interface EditTag {
  /**
   * update single row of the table: "enrollment_period_tag"
   */
  update_enrollment_period_tag_by_pk: EditTag_update_enrollment_period_tag_by_pk | null;
}

export interface EditTagVariables {
  tag_id: uuid;
  name: citext;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTag
// ====================================================

export interface DeleteTag_delete_enrollment_period_tag_by_pk {
  __typename: "enrollment_period_tag";
  id: uuid;
}

export interface DeleteTag {
  /**
   * delete single row from the table: "enrollment_period_tag"
   */
  delete_enrollment_period_tag_by_pk: DeleteTag_delete_enrollment_period_tag_by_pk | null;
}

export interface DeleteTagVariables {
  tag_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTagsByEnrollmentPeriod
// ====================================================

export interface GetTagsByEnrollmentPeriod_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
  last_used_at: timestamptz | null;
}

export interface GetTagsByEnrollmentPeriod {
  /**
   * fetch data from the table: "enrollment_period_tag"
   */
  enrollment_period_tag: GetTagsByEnrollmentPeriod_enrollment_period_tag[];
}

export interface GetTagsByEnrollmentPeriodVariables {
  enrollment_period_id: uuid;
  tag_group_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTagsIdsByTagGroup
// ====================================================

export interface GetTagsIdsByTagGroup_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
}

export interface GetTagsIdsByTagGroup {
  /**
   * fetch data from the table: "enrollment_period_tag"
   */
  enrollment_period_tag: GetTagsIdsByTagGroup_enrollment_period_tag[];
}

export interface GetTagsIdsByTagGroupVariables {
  tag_group_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTagsAndFormSchoolTags
// ====================================================

export interface GetFormTagsAndFormSchoolTags_tag_by_form_school {
  __typename: "tag_by_form_school";
  form_school_rank_id: uuid | null;
  form_id: uuid | null;
  tag_id: uuid | null;
}

export interface GetFormTagsAndFormSchoolTags_form_tag {
  __typename: "form_tag";
  form_id: uuid;
  tag_id: uuid;
}

export interface GetFormTagsAndFormSchoolTags {
  /**
   * fetch data from the table: "tag_by_form_school"
   */
  tag_by_form_school: GetFormTagsAndFormSchoolTags_tag_by_form_school[];
  /**
   * fetch data from the table: "form_tag"
   */
  form_tag: GetFormTagsAndFormSchoolTags_form_tag[];
}

export interface GetFormTagsAndFormSchoolTagsVariables {
  form_school_rank_ids: uuid[];
  form_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTags
// ====================================================

export interface GetFormTags_form_tag {
  __typename: "form_tag";
  form_id: uuid;
  tag_id: uuid;
}

export interface GetFormTags {
  /**
   * fetch data from the table: "form_tag"
   */
  form_tag: GetFormTags_form_tag[];
}

export interface GetFormTagsVariables {
  form_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInternalDefaultTagGroup
// ====================================================

export interface GetInternalDefaultTagGroup_tag_group {
  __typename: "tag_group";
  id: uuid;
  name: string;
}

export interface GetInternalDefaultTagGroup {
  /**
   * fetch data from the table: "tag_group"
   */
  tag_group: GetInternalDefaultTagGroup_tag_group[];
}

export interface GetInternalDefaultTagGroupVariables {
  enrollment_period_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTagNameFromId
// ====================================================

export interface GetTagNameFromId_enrollment_period_tag_by_pk {
  __typename: "enrollment_period_tag";
  name: citext;
}

export interface GetTagNameFromId {
  /**
   * fetch data from the table: "enrollment_period_tag" using primary key columns
   */
  enrollment_period_tag_by_pk: GetTagNameFromId_enrollment_period_tag_by_pk | null;
}

export interface GetTagNameFromIdVariables {
  tag_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAuth0User
// ====================================================

export interface EditAuth0User_edit_auth0_user {
  __typename: "EditAuth0UserOutput";
  message: string | null;
  status_code: number | null;
}

export interface EditAuth0User {
  edit_auth0_user: EditAuth0User_edit_auth0_user | null;
}

export interface EditAuth0UserVariables {
  user_id: string;
  first_name: string;
  last_name: string;
  hasura_role: string;
  user_group_id: string;
  school_ids?: (string | null)[] | null;
  school_access_permission?: string | null;
  team_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAuth0User
// ====================================================

export interface CreateAuth0User_create_auth0_user {
  __typename: "CreateAuth0UserOutput";
  message: string | null;
  status_code: number | null;
  user_id: string | null;
}

export interface CreateAuth0User {
  /**
   * Create a user via the account management API
   */
  create_auth0_user: CreateAuth0User_create_auth0_user | null;
}

export interface CreateAuth0UserVariables {
  email_address: string;
  first_name: string;
  last_name: string;
  organization_id: string;
  hasura_role: string;
  user_group_id: string;
  school_ids?: (string | null)[] | null;
  school_access_permission?: string | null;
  team_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSSOAdminProfile
// ====================================================

export interface CreateSSOAdminProfile_create_sso_admin_profile {
  __typename: "CreateSSOAdminProfileOutput";
  message: string | null;
  status_code: number | null;
  user_id: string | null;
}

export interface CreateSSOAdminProfile {
  create_sso_admin_profile: CreateSSOAdminProfile_create_sso_admin_profile | null;
}

export interface CreateSSOAdminProfileVariables {
  email_address: string;
  first_name: string;
  hasura_role: string;
  last_name: string;
  school_ids?: (string | null)[] | null;
  organization_id: string;
  user_group_id: string;
  school_access_permission?: string | null;
  team_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTeam
// ====================================================

export interface CreateTeam_insert_team_one {
  __typename: "team";
  id: uuid;
}

export interface CreateTeam {
  /**
   * insert a single row into the table: "team"
   */
  insert_team_one: CreateTeam_insert_team_one | null;
}

export interface CreateTeamVariables {
  name: string;
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTeam
// ====================================================

export interface EditTeam_update_team_by_pk {
  __typename: "team";
  id: uuid;
}

export interface EditTeam {
  /**
   * update single row of the table: "team"
   */
  update_team_by_pk: EditTeam_update_team_by_pk | null;
}

export interface EditTeamVariables {
  teamId: uuid;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTeam
// ====================================================

export interface DeleteTeam_update_team_by_pk {
  __typename: "team";
  id: uuid;
}

export interface DeleteTeam_delete_person_team {
  __typename: "person_team_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteTeam {
  /**
   * update single row of the table: "team"
   */
  update_team_by_pk: DeleteTeam_update_team_by_pk | null;
  /**
   * delete data from the table: "person_team"
   */
  delete_person_team: DeleteTeam_delete_person_team | null;
}

export interface DeleteTeamVariables {
  teamId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignMembersToTeam
// ====================================================

export interface AssignMembersToTeam_delete_person_team {
  __typename: "person_team_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AssignMembersToTeam_insert_person_team {
  __typename: "person_team_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AssignMembersToTeam {
  /**
   * delete data from the table: "person_team"
   */
  delete_person_team: AssignMembersToTeam_delete_person_team | null;
  /**
   * insert data into the table: "person_team"
   */
  insert_person_team: AssignMembersToTeam_insert_person_team | null;
}

export interface AssignMembersToTeamVariables {
  teamId: uuid;
  input: person_team_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeamMember
// ====================================================

export interface GetTeamMember_person_person_teams_team {
  __typename: "team";
  id: uuid;
  name: string;
}

export interface GetTeamMember_person_person_teams {
  __typename: "person_team";
  /**
   * An object relationship
   */
  team: GetTeamMember_person_person_teams_team;
}

export interface GetTeamMember_person_user_school_users_school {
  __typename: "school";
  name: string;
}

export interface GetTeamMember_person_user_school_users {
  __typename: "school_user";
  user_id: string;
  school_id: uuid;
  /**
   * An object relationship
   */
  school: GetTeamMember_person_user_school_users_school;
  school_access_permission: string | null;
}

export interface GetTeamMember_person_user {
  __typename: "user";
  id: string;
  name: string | null;
  user_group_id: uuid | null;
  /**
   * An array relationship
   */
  school_users: GetTeamMember_person_user_school_users[];
}

export interface GetTeamMember_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
  email_address: string | null;
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  person_teams: GetTeamMember_person_person_teams[];
  /**
   * An object relationship
   */
  user: GetTeamMember_person_user | null;
}

export interface GetTeamMember_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
}

export interface GetTeamMember {
  /**
   * fetch data from the table: "person"
   */
  person: GetTeamMember_person[];
  /**
   * fetch data from the table: "school"
   */
  school: GetTeamMember_school[];
}

export interface GetTeamMemberVariables {
  personID: uuid;
  organizationID: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMembers
// ====================================================

export interface GetMembers_person_person_teams_team {
  __typename: "team";
  name: string;
}

export interface GetMembers_person_person_teams {
  __typename: "person_team";
  /**
   * An object relationship
   */
  team: GetMembers_person_person_teams_team;
}

export interface GetMembers_person_user_user_group {
  __typename: "user_group";
  id: uuid;
  name: string;
  label: string;
}

export interface GetMembers_person_user_school_users_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetMembers_person_user_school_users {
  __typename: "school_user";
  /**
   * An object relationship
   */
  school: GetMembers_person_user_school_users_school;
}

export interface GetMembers_person_user {
  __typename: "user";
  id: string;
  name: string | null;
  /**
   * An object relationship
   */
  user_group: GetMembers_person_user_user_group | null;
  /**
   * An array relationship
   */
  school_users: GetMembers_person_user_school_users[];
}

export interface GetMembers_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
  email_address: string | null;
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  person_teams: GetMembers_person_person_teams[];
  /**
   * An object relationship
   */
  user: GetMembers_person_user | null;
}

export interface GetMembers_person_aggregate_totals {
  __typename: "person_aggregate_fields";
  total: number;
}

export interface GetMembers_person_aggregate {
  __typename: "person_aggregate";
  totals: GetMembers_person_aggregate_totals | null;
}

export interface GetMembers {
  /**
   * fetch data from the table: "person"
   */
  person: GetMembers_person[];
  /**
   * fetch aggregated fields from the table: "person"
   */
  person_aggregate: GetMembers_person_aggregate;
}

export interface GetMembersVariables {
  organizationId: uuid;
  limit: number;
  offset: number;
  orderBy?: person_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeam
// ====================================================

export interface GetTeam_team_team_members_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
}

export interface GetTeam_team_team_members {
  __typename: "person_team";
  /**
   * An object relationship
   */
  person: GetTeam_team_team_members_person;
}

export interface GetTeam_team {
  __typename: "team";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  team_members: GetTeam_team_team_members[];
}

export interface GetTeam {
  /**
   * fetch data from the table: "team"
   */
  team: GetTeam_team[];
}

export interface GetTeamVariables {
  teamID: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeams
// ====================================================

export interface GetTeams_team {
  __typename: "team";
  id: uuid;
  name: string;
}

export interface GetTeams_team_aggregate_totals {
  __typename: "team_aggregate_fields";
  total: number;
}

export interface GetTeams_team_aggregate {
  __typename: "team_aggregate";
  totals: GetTeams_team_aggregate_totals | null;
}

export interface GetTeams {
  /**
   * fetch data from the table: "team"
   */
  team: GetTeams_team[];
  /**
   * fetch aggregated fields from the table: "team"
   */
  team_aggregate: GetTeams_team_aggregate;
}

export interface GetTeamsVariables {
  organizationId: uuid;
  limit: number;
  offset: number;
  orderBy?: team_order_by[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableTeams
// ====================================================

export interface GetAvailableTeams_team {
  __typename: "team";
  id: uuid;
  name: string;
}

export interface GetAvailableTeams {
  /**
   * fetch data from the table: "team"
   */
  team: GetAvailableTeams_team[];
}

export interface GetAvailableTeamsVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableMembers
// ====================================================

export interface GetAvailableMembers_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
}

export interface GetAvailableMembers {
  /**
   * fetch data from the table: "person"
   */
  person: GetAvailableMembers_person[];
}

export interface GetAvailableMembersVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsByOrgID
// ====================================================

export interface GetSchoolsByOrgID_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
}

export interface GetSchoolsByOrgID {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsByOrgID_school[];
}

export interface GetSchoolsByOrgIDVariables {
  organizationID: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserGroups
// ====================================================

export interface GetUserGroups_user_group {
  __typename: "user_group";
  id: uuid;
  name: string;
  label: string;
  person_type: person_type_enum;
}

export interface GetUserGroups {
  /**
   * fetch data from the table: "user_group"
   */
  user_group: GetUserGroups_user_group[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddToWaitlistsBulk
// ====================================================

export interface AddToWaitlistsBulk_update_form {
  __typename: "form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddToWaitlistsBulk_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddToWaitlistsBulk_insert_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddToWaitlistsBulk_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddToWaitlistsBulk_update_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface AddToWaitlistsBulk {
  /**
   * update data of the table: "form"
   */
  update_form: AddToWaitlistsBulk_update_form | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: AddToWaitlistsBulk_update_waitlist | null;
  /**
   * insert data into the table: "waitlist"
   */
  insert_waitlist: AddToWaitlistsBulk_insert_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: AddToWaitlistsBulk_update_offer | null;
  /**
   * update data of the table: "form_school_rank"
   */
  update_form_school_rank: AddToWaitlistsBulk_update_form_school_rank | null;
}

export interface AddToWaitlistsBulkVariables {
  form_ids?: uuid[] | null;
  delete_waitlists_where: waitlist_bool_exp;
  insert_waitlists: waitlist_insert_input[];
  delete_offers_where: offer_bool_exp;
  update_form_school_rank: form_school_rank_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFromWaitlistsBulk
// ====================================================

export interface RemoveFromWaitlistsBulk_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface RemoveFromWaitlistsBulk {
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: RemoveFromWaitlistsBulk_update_waitlist | null;
}

export interface RemoveFromWaitlistsBulkVariables {
  where: waitlist_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWaitlistTags
// ====================================================

export interface GetWaitlistTags_form_school_rank_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
}

export interface GetWaitlistTags_form_school_rank_tags {
  __typename: "form_school_tag";
  /**
   * An object relationship
   */
  enrollment_period_tag: GetWaitlistTags_form_school_rank_tags_enrollment_period_tag;
}

export interface GetWaitlistTags_form_school_rank_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  position: number | null;
  grade_id: uuid;
}

export interface GetWaitlistTags_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  /**
   * An array relationship
   */
  tags: GetWaitlistTags_form_school_rank_tags[];
  /**
   * An array relationship
   */
  waitlists: GetWaitlistTags_form_school_rank_waitlists[];
}

export interface GetWaitlistTags {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: GetWaitlistTags_form_school_rank[];
}

export interface GetWaitlistTagsVariables {
  form_id: uuid;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchPriorityTemplatesByGrade
// ====================================================

export interface FetchPriorityTemplatesByGrade_grade_resolved_priority_template_priority_template {
  __typename: "priority_template";
  config: jsonb;
}

export interface FetchPriorityTemplatesByGrade_grade_resolved_priority_template {
  __typename: "grade_resolved_priority_template";
  grade_id: uuid | null;
  /**
   * An object relationship
   */
  priority_template: FetchPriorityTemplatesByGrade_grade_resolved_priority_template_priority_template | null;
}

export interface FetchPriorityTemplatesByGrade {
  /**
   * fetch data from the table: "grade_resolved_priority_template"
   */
  grade_resolved_priority_template: FetchPriorityTemplatesByGrade_grade_resolved_priority_template[];
}

export interface FetchPriorityTemplatesByGradeVariables {
  grade_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchFormSchoolGradeIdsAndTags
// ====================================================

export interface FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  name: citext;
}

export interface FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank_tags {
  __typename: "form_school_tag";
  /**
   * An object relationship
   */
  enrollment_period_tag: FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank_tags_enrollment_period_tag;
}

export interface FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  /**
   * An array relationship
   */
  tags: FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank_tags[];
}

export interface FetchFormSchoolGradeIdsAndTags_form_school_grade {
  __typename: "form_school_grade";
  form_id: uuid | null;
  school_id: uuid | null;
  grade_id: uuid | null;
  /**
   * An object relationship
   */
  form_school_rank: FetchFormSchoolGradeIdsAndTags_form_school_grade_form_school_rank | null;
}

export interface FetchFormSchoolGradeIdsAndTags {
  /**
   * fetch data from the table: "form_school_grade"
   */
  form_school_grade: FetchFormSchoolGradeIdsAndTags_form_school_grade[];
}

export interface FetchFormSchoolGradeIdsAndTagsVariables {
  matching: form_school_grade_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertForm
// ====================================================

export interface InsertForm_insert_form_one {
  __typename: "form";
  id: uuid;
}

export interface InsertForm {
  /**
   * insert a single row into the table: "form"
   */
  insert_form_one: InsertForm_insert_form_one | null;
}

export interface InsertFormVariables {
  form_template_id: uuid;
  person_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAnswerFreeText
// ====================================================

export interface UpsertFormAnswerFreeText_insert_form_answer_one {
  __typename: "form_answer";
  id: uuid;
  updated_at: timestamptz;
  free_text_answer: string | null;
}

export interface UpsertFormAnswerFreeText {
  /**
   * insert a single row into the table: "form_answer"
   */
  insert_form_answer_one: UpsertFormAnswerFreeText_insert_form_answer_one | null;
}

export interface UpsertFormAnswerFreeTextVariables {
  form_id: uuid;
  question_id: uuid;
  free_text_answer?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAnswerDate
// ====================================================

export interface UpsertFormAnswerDate_insert_form_answer_one {
  __typename: "form_answer";
  id: uuid;
  updated_at: timestamptz;
  date_answer: date | null;
}

export interface UpsertFormAnswerDate {
  /**
   * insert a single row into the table: "form_answer"
   */
  insert_form_answer_one: UpsertFormAnswerDate_insert_form_answer_one | null;
}

export interface UpsertFormAnswerDateVariables {
  form_id: uuid;
  question_id: uuid;
  date_answer?: date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAnswerNumber
// ====================================================

export interface UpsertFormAnswerNumber_insert_form_answer_one {
  __typename: "form_answer";
  id: uuid;
  updated_at: timestamptz;
  number_answer: numeric | null;
}

export interface UpsertFormAnswerNumber {
  /**
   * insert a single row into the table: "form_answer"
   */
  insert_form_answer_one: UpsertFormAnswerNumber_insert_form_answer_one | null;
}

export interface UpsertFormAnswerNumberVariables {
  form_id: uuid;
  question_id: uuid;
  number_answer?: numeric | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGradesAnswer
// ====================================================

export interface UpsertGradesAnswer_insert_grades_answer_one {
  __typename: "grades_answer";
  id: uuid;
  grade_config_id: uuid | null;
  updated_at: timestamptz;
}

export interface UpsertGradesAnswer {
  /**
   * insert a single row into the table: "grades_answer"
   */
  insert_grades_answer_one: UpsertGradesAnswer_insert_grades_answer_one | null;
}

export interface UpsertGradesAnswerVariables {
  form_id: uuid;
  question_id: uuid;
  grade_config_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertFormAnswerOptions
// ====================================================

export interface InsertFormAnswerOptions_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertFormAnswerOptions_insert_form_answer_one_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
}

export interface InsertFormAnswerOptions_insert_form_answer_one {
  __typename: "form_answer";
  id: uuid;
  /**
   * An array relationship
   */
  form_answer_options: InsertFormAnswerOptions_insert_form_answer_one_form_answer_options[];
  updated_at: timestamptz;
}

export interface InsertFormAnswerOptions {
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: InsertFormAnswerOptions_delete_form_answer | null;
  /**
   * insert a single row into the table: "form_answer"
   */
  insert_form_answer_one: InsertFormAnswerOptions_insert_form_answer_one | null;
}

export interface InsertFormAnswerOptionsVariables {
  form_id: uuid;
  question_id: uuid;
  options: form_answer_option_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormAddress
// ====================================================

export interface DeleteFormAddress_delete_form_address {
  __typename: "form_address_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteFormAddress {
  /**
   * delete data from the table: "form_address"
   */
  delete_form_address: DeleteFormAddress_delete_form_address | null;
}

export interface DeleteFormAddressVariables {
  form_id: uuid;
  question_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormAddress
// ====================================================

export interface UpsertFormAddress_insert_form_address_one {
  __typename: "form_address";
  id: uuid;
  updated_at: timestamptz;
}

export interface UpsertFormAddress {
  /**
   * insert a single row into the table: "form_address"
   */
  insert_form_address_one: UpsertFormAddress_insert_form_address_one | null;
}

export interface UpsertFormAddressVariables {
  address: form_address_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCustomQuestionAnswers
// ====================================================

export interface UpsertCustomQuestionAnswers_delete_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertCustomQuestionAnswers_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertCustomQuestionAnswers_insert_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface UpsertCustomQuestionAnswers_insert_custom_question_answer_bank_relationship_one {
  __typename: "custom_question_answer_bank_relationship";
  id: uuid;
}

export interface UpsertCustomQuestionAnswers {
  /**
   * delete data from the table: "custom_question_answer"
   */
  delete_custom_question_answer: UpsertCustomQuestionAnswers_delete_custom_question_answer | null;
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: UpsertCustomQuestionAnswers_delete_form_answer | null;
  /**
   * insert data into the table: "custom_question_answer"
   */
  insert_custom_question_answer: UpsertCustomQuestionAnswers_insert_custom_question_answer | null;
  /**
   * insert a single row into the table: "custom_question_answer_bank_relationship"
   */
  insert_custom_question_answer_bank_relationship_one: UpsertCustomQuestionAnswers_insert_custom_question_answer_bank_relationship_one | null;
}

export interface UpsertCustomQuestionAnswersVariables {
  form_id: uuid;
  question_id: uuid;
  single_select_question_ids: uuid[];
  custom_question_answers: custom_question_answer_insert_input[];
  no_answer_bank_relationship: boolean;
  answer_bank_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCustomQuestionAnswers
// ====================================================

export interface DeleteCustomQuestionAnswers_delete_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteCustomQuestionAnswers_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteCustomQuestionAnswers_delete_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteCustomQuestionAnswers {
  /**
   * delete data from the table: "custom_question_answer"
   */
  delete_custom_question_answer: DeleteCustomQuestionAnswers_delete_custom_question_answer | null;
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: DeleteCustomQuestionAnswers_delete_form_answer | null;
  /**
   * delete data from the table: "custom_question_answer_bank_relationship"
   */
  delete_custom_question_answer_bank_relationship: DeleteCustomQuestionAnswers_delete_custom_question_answer_bank_relationship | null;
}

export interface DeleteCustomQuestionAnswersVariables {
  form_id: uuid;
  question_id: uuid;
  nested_question_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFormDisclaimer
// ====================================================

export interface UpsertFormDisclaimer_insert_form_disclaimer_one {
  __typename: "form_disclaimer";
  signed_at: timestamptz;
}

export interface UpsertFormDisclaimer {
  /**
   * insert a single row into the table: "form_disclaimer"
   */
  insert_form_disclaimer_one: UpsertFormDisclaimer_insert_form_disclaimer_one | null;
}

export interface UpsertFormDisclaimerVariables {
  form_id: uuid;
  disclaimer_section_id: uuid;
  signature: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertFormSchoolsRank
// ====================================================

export interface InsertFormSchoolsRank_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertFormSchoolsRank_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertFormSchoolsRank_delete_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface InsertFormSchoolsRank_insert_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface InsertFormSchoolsRank_insert_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: InsertFormSchoolsRank_insert_form_school_rank_returning[];
}

export interface InsertFormSchoolsRank {
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: InsertFormSchoolsRank_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: InsertFormSchoolsRank_update_offer | null;
  /**
   * delete data from the table: "form_school_rank"
   */
  delete_form_school_rank: InsertFormSchoolsRank_delete_form_school_rank | null;
  /**
   * insert data into the table: "form_school_rank"
   */
  insert_form_school_rank: InsertFormSchoolsRank_insert_form_school_rank | null;
}

export interface InsertFormSchoolsRankVariables {
  deleted_school_ranks: form_school_rank_bool_exp;
  upserted_school_ranks: form_school_rank_insert_input[];
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LateEditSchoolRanks
// ====================================================

export interface LateEditSchoolRanks_update_assigned_form {
  __typename: "assigned_form_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface LateEditSchoolRanks_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface LateEditSchoolRanks_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface LateEditSchoolRanks_delete_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface LateEditSchoolRanks_insert_form_school_rank_returning {
  __typename: "form_school_rank";
  id: uuid;
}

export interface LateEditSchoolRanks_insert_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: LateEditSchoolRanks_insert_form_school_rank_returning[];
}

export interface LateEditSchoolRanks {
  /**
   * update data of the table: "assigned_form"
   */
  update_assigned_form: LateEditSchoolRanks_update_assigned_form | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: LateEditSchoolRanks_update_waitlist | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: LateEditSchoolRanks_update_offer | null;
  /**
   * delete data from the table: "form_school_rank"
   */
  delete_form_school_rank: LateEditSchoolRanks_delete_form_school_rank | null;
  /**
   * insert data into the table: "form_school_rank"
   */
  insert_form_school_rank: LateEditSchoolRanks_insert_form_school_rank | null;
}

export interface LateEditSchoolRanksVariables {
  deleted_school_ranks: form_school_rank_bool_exp;
  upserted_school_ranks: form_school_rank_insert_input[];
  delete_offers_where: offer_bool_exp;
  delete_waitlists_where: waitlist_bool_exp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearImportSource
// ====================================================

export interface ClearImportSource_update_form_by_pk {
  __typename: "form";
  id: uuid;
}

export interface ClearImportSource {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: ClearImportSource_update_form_by_pk | null;
}

export interface ClearImportSourceVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInProgressForm
// ====================================================

export interface UpdateInProgressForm_update_form_by_pk {
  __typename: "form";
  id: uuid;
}

export interface UpdateInProgressForm {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: UpdateInProgressForm_update_form_by_pk | null;
}

export interface UpdateInProgressFormVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitForm
// ====================================================

export interface SubmitForm_update_form_by_pk {
  __typename: "form";
  id: uuid;
}

export interface SubmitForm_update_action_item_returning {
  __typename: "action_item";
  id: uuid;
}

export interface SubmitForm_update_action_item {
  __typename: "action_item_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: SubmitForm_update_action_item_returning[];
}

export interface SubmitForm {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: SubmitForm_update_form_by_pk | null;
  /**
   * update data of the table: "action_item"
   */
  update_action_item: SubmitForm_update_action_item | null;
}

export interface SubmitFormVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAnswers
// ====================================================

export interface DeleteAnswers_delete_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface DeleteAnswers {
  /**
   * delete data from the table: "form_answer"
   */
  delete_form_answer: DeleteAnswers_delete_form_answer | null;
}

export interface DeleteAnswersVariables {
  form_id: uuid;
  question_ids: uuid[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertFormAndLink
// ====================================================

export interface InsertFormAndLink_insert_form_returning {
  __typename: "form";
  id: uuid;
}

export interface InsertFormAndLink_insert_form {
  __typename: "form_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: InsertFormAndLink_insert_form_returning[];
}

export interface InsertFormAndLink {
  /**
   * insert data into the table: "form"
   */
  insert_form: InsertFormAndLink_insert_form | null;
}

export interface InsertFormAndLinkVariables {
  form_template_id: uuid;
  person_id: uuid;
  previous_form_id?: uuid | null;
  previous_offer_id?: uuid | null;
  previous_waitlist_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnhideAndLinkForm
// ====================================================

export interface UnhideAndLinkForm_update_form_by_pk {
  __typename: "form";
  id: uuid;
}

export interface UnhideAndLinkForm {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: UnhideAndLinkForm_update_form_by_pk | null;
}

export interface UnhideAndLinkFormVariables {
  form_id: uuid;
  previous_form_id?: uuid | null;
  previous_offer_id?: uuid | null;
  previous_waitlist_id?: uuid | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloneForm
// ====================================================

export interface CloneForm_insert_form_answer {
  __typename: "form_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm_insert_form_address {
  __typename: "form_address_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm_insert_form_school_rank {
  __typename: "form_school_rank_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm_insert_grades_answer {
  __typename: "grades_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm_insert_custom_question_answer {
  __typename: "custom_question_answer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm_insert_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
}

export interface CloneForm {
  /**
   * insert data into the table: "form_answer"
   */
  insert_form_answer: CloneForm_insert_form_answer | null;
  /**
   * insert data into the table: "form_address"
   */
  insert_form_address: CloneForm_insert_form_address | null;
  /**
   * insert data into the table: "form_school_rank"
   */
  insert_form_school_rank: CloneForm_insert_form_school_rank | null;
  /**
   * insert data into the table: "grades_answer"
   */
  insert_grades_answer: CloneForm_insert_grades_answer | null;
  /**
   * insert data into the table: "custom_question_answer"
   */
  insert_custom_question_answer: CloneForm_insert_custom_question_answer | null;
  /**
   * insert data into the table: "custom_question_answer_bank_relationship"
   */
  insert_custom_question_answer_bank_relationship: CloneForm_insert_custom_question_answer_bank_relationship | null;
}

export interface CloneFormVariables {
  insert_form_answer: form_answer_insert_input[];
  insert_form_address: form_address_insert_input[];
  insert_form_school_rank: form_school_rank_insert_input[];
  insert_grades_answer: grades_answer_insert_input[];
  insert_custom_question_answer: custom_question_answer_insert_input[];
  insert_custom_question_answer_bank_relationship: custom_question_answer_bank_relationship_insert_input[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormAnswersById
// ====================================================

export interface GetFormAnswersById_form_answer_form_question_question {
  __typename: "question";
  type: question_type_enum;
}

export interface GetFormAnswersById_form_answer_form_question {
  __typename: "form_question";
  question_id: uuid;
  category: form_question_category_enum;
  /**
   * An object relationship
   */
  question: GetFormAnswersById_form_answer_form_question_question;
}

export interface GetFormAnswersById_form_answer_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
  form_question_option_id: uuid;
}

export interface GetFormAnswersById_form_answer_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface GetFormAnswersById_form_answer {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_question: GetFormAnswersById_form_answer_form_question;
  free_text_answer: string | null;
  number_answer: numeric | null;
  date_answer: date | null;
  /**
   * An array relationship
   */
  form_answer_options: GetFormAnswersById_form_answer_form_answer_options[];
  /**
   * An array relationship
   */
  document_metadata: GetFormAnswersById_form_answer_document_metadata[];
}

export interface GetFormAnswersById_grades_answer_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GetFormAnswersById_grades_answer {
  __typename: "grades_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetFormAnswersById_grades_answer_grade_config | null;
}

export interface GetFormAnswersById_form_address {
  __typename: "form_address";
  id: uuid;
  question_id: uuid;
  street_address: string;
  street_address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface GetFormAnswersById_custom_question_answer_form_answer_form_question_question {
  __typename: "question";
  type: question_type_enum;
}

export interface GetFormAnswersById_custom_question_answer_form_answer_form_question {
  __typename: "form_question";
  question_id: uuid;
  category: form_question_category_enum;
  /**
   * An object relationship
   */
  question: GetFormAnswersById_custom_question_answer_form_answer_form_question_question;
}

export interface GetFormAnswersById_custom_question_answer_form_answer_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
  form_question_option_id: uuid;
}

export interface GetFormAnswersById_custom_question_answer_form_answer_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface GetFormAnswersById_custom_question_answer_form_answer {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_question: GetFormAnswersById_custom_question_answer_form_answer_form_question;
  free_text_answer: string | null;
  number_answer: numeric | null;
  date_answer: date | null;
  /**
   * An array relationship
   */
  form_answer_options: GetFormAnswersById_custom_question_answer_form_answer_form_answer_options[];
  /**
   * An array relationship
   */
  document_metadata: GetFormAnswersById_custom_question_answer_form_answer_document_metadata[];
}

export interface GetFormAnswersById_custom_question_answer {
  __typename: "custom_question_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_answer: GetFormAnswersById_custom_question_answer_form_answer;
}

export interface GetFormAnswersById_form_disclaimer {
  __typename: "form_disclaimer";
  id: uuid;
  signature: string;
  signed_at: timestamptz;
}

export interface GetFormAnswersById {
  /**
   * fetch data from the table: "form_answer"
   */
  form_answer: GetFormAnswersById_form_answer[];
  /**
   * fetch data from the table: "grades_answer"
   */
  grades_answer: GetFormAnswersById_grades_answer[];
  /**
   * fetch data from the table: "form_address"
   */
  form_address: GetFormAnswersById_form_address[];
  /**
   * fetch data from the table: "custom_question_answer"
   */
  custom_question_answer: GetFormAnswersById_custom_question_answer[];
  /**
   * fetch data from the table: "form_disclaimer"
   */
  form_disclaimer: GetFormAnswersById_form_disclaimer[];
}

export interface GetFormAnswersByIdVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormDisclaimerById
// ====================================================

export interface GetFormDisclaimerById_form_disclaimer {
  __typename: "form_disclaimer";
  id: uuid;
  signature: string;
  signed_at: timestamptz;
}

export interface GetFormDisclaimerById {
  /**
   * fetch data from the table: "form_disclaimer"
   */
  form_disclaimer: GetFormDisclaimerById_form_disclaimer[];
}

export interface GetFormDisclaimerByIdVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormById
// ====================================================

export interface GetFormById_form_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetFormById_form_form_template_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetFormById_form_form_template_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormById_form_form_template_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetFormById_form_form_template_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface GetFormById_form_form_template_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_template_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormById_form_form_template_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormById_form_form_template_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetFormById_form_form_template_sections_questions_grades_question_grades_grade_config | null;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions_question;
}

export interface GetFormById_form_form_template_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetFormById_form_form_template_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetFormById_form_form_template_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetFormById_form_form_template_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetFormById_form_form_template_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetFormById_form_form_template_sections_questions_custom_question_custom_question_relationships[];
}

export interface GetFormById_form_form_template_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetFormById_form_form_template_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetFormById_form_form_template_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetFormById_form_form_template_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetFormById_form_form_template_sections_questions_custom_question | null;
}

export interface GetFormById_form_form_template_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetFormById_form_form_template_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetFormById_form_form_template_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetFormById_form_form_template_sections_questions[];
}

export interface GetFormById_form_form_template_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  active: boolean;
  name: string;
}

export interface GetFormById_form_form_template {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetFormById_form_form_template_sections[];
  /**
   * An array relationship
   */
  form_verifications: GetFormById_form_form_template_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: GetFormById_form_form_template_enrollment_period;
}

export interface GetFormById_form_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetFormById_form_form_verification_results {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: GetFormById_form_form_verification_results_form_verification;
}

export interface GetFormById_form_person {
  __typename: "person";
  first_name: string | null;
  last_name: string | null;
  avatar: string | null;
  birth_date: date | null;
}

export interface GetFormById_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  submitted_before: boolean | null;
  /**
   * An object relationship
   */
  form_template: GetFormById_form_form_template;
  /**
   * An array relationship
   */
  form_verification_results: GetFormById_form_form_verification_results[];
  /**
   * An object relationship
   */
  person: GetFormById_form_person;
}

export interface GetFormById {
  /**
   * fetch data from the table: "form"
   */
  form: GetFormById_form[];
}

export interface GetFormByIdVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEligibilityAnswers
// ====================================================

export interface GetEligibilityAnswers_form_answer_form_answer_options_form_question_option_eligibility_schools_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
}

export interface GetEligibilityAnswers_form_answer_form_answer_options_form_question_option_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  /**
   * An object relationship
   */
  school: GetEligibilityAnswers_form_answer_form_answer_options_form_question_option_eligibility_schools_school;
}

export interface GetEligibilityAnswers_form_answer_form_answer_options_form_question_option {
  __typename: "form_question_option";
  /**
   * An array relationship
   */
  eligibility_schools: GetEligibilityAnswers_form_answer_form_answer_options_form_question_option_eligibility_schools[];
  question_id: uuid;
}

export interface GetEligibilityAnswers_form_answer_form_answer_options {
  __typename: "form_answer_option";
  /**
   * An object relationship
   */
  form_question_option: GetEligibilityAnswers_form_answer_form_answer_options_form_question_option;
}

export interface GetEligibilityAnswers_form_answer {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An array relationship
   */
  form_answer_options: GetEligibilityAnswers_form_answer_form_answer_options[];
}

export interface GetEligibilityAnswers {
  /**
   * fetch data from the table: "form_answer"
   */
  form_answer: GetEligibilityAnswers_form_answer[];
}

export interface GetEligibilityAnswersVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreviousFormSchools
// ====================================================

export interface GetPreviousFormSchools_form_by_pk_previous_form_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface GetPreviousFormSchools_form_by_pk_previous_form {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: GetPreviousFormSchools_form_by_pk_previous_form_form_school_ranks[];
}

export interface GetPreviousFormSchools_form_by_pk_previous_offer {
  __typename: "offer";
  school_id: uuid;
}

export interface GetPreviousFormSchools_form_by_pk_previous_waitlist {
  __typename: "waitlist";
  school_id: uuid;
}

export interface GetPreviousFormSchools_form_by_pk {
  __typename: "form";
  /**
   * An object relationship
   */
  previous_form: GetPreviousFormSchools_form_by_pk_previous_form | null;
  /**
   * An object relationship
   */
  previous_offer: GetPreviousFormSchools_form_by_pk_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetPreviousFormSchools_form_by_pk_previous_waitlist | null;
}

export interface GetPreviousFormSchools {
  /**
   * fetch data from the table: "form" using primary key columns
   */
  form_by_pk: GetPreviousFormSchools_form_by_pk | null;
}

export interface GetPreviousFormSchoolsVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsRank
// ====================================================

export interface GetSchoolsRank_form_school_rank_school_grades_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetSchoolsRank_form_school_rank_school_grades_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetSchoolsRank_form_school_rank_school_grades {
  __typename: "grade";
  id: uuid;
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period: GetSchoolsRank_form_school_rank_school_grades_enrollment_period;
  /**
   * An object relationship
   */
  grade_config: GetSchoolsRank_form_school_rank_school_grades_grade_config;
}

export interface GetSchoolsRank_form_school_rank_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
  /**
   * An array relationship
   */
  grades: GetSchoolsRank_form_school_rank_school_grades[];
}

export interface GetSchoolsRank_form_school_rank_form_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolsRank_form_school_rank_form_form_verification_results {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: GetSchoolsRank_form_school_rank_form_form_verification_results_form_verification;
}

export interface GetSchoolsRank_form_school_rank_form {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  /**
   * An array relationship
   */
  form_verification_results: GetSchoolsRank_form_school_rank_form_form_verification_results[];
}

export interface GetSchoolsRank_form_school_rank_form_verifications_flattened {
  __typename: "form_verifications_flattened";
  verifications: string | null;
}

export interface GetSchoolsRank_form_school_rank_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetSchoolsRank_form_school_rank_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetSchoolsRank_form_school_rank_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  /**
   * An object relationship
   */
  waitlist_position: GetSchoolsRank_form_school_rank_waitlists_waitlist_position | null;
}

export interface GetSchoolsRank_form_school_rank {
  __typename: "form_school_rank";
  id: uuid;
  rank: number;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  schools_ranking_section_id: uuid;
  /**
   * An object relationship
   */
  school: GetSchoolsRank_form_school_rank_school;
  /**
   * An object relationship
   */
  form: GetSchoolsRank_form_school_rank_form;
  /**
   * An object relationship
   */
  form_verifications_flattened: GetSchoolsRank_form_school_rank_form_verifications_flattened | null;
  /**
   * An array relationship
   */
  offers: GetSchoolsRank_form_school_rank_offers[];
  /**
   * An array relationship
   */
  waitlists: GetSchoolsRank_form_school_rank_waitlists[];
}

export interface GetSchoolsRank {
  /**
   * fetch data from the table: "form_school_rank"
   */
  form_school_rank: GetSchoolsRank_form_school_rank[];
}

export interface GetSchoolsRankVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActiveEnrollmentPeriods
// ====================================================

export interface GetActiveEnrollmentPeriods_enrollment_period_form_templates {
  __typename: "form_template";
  id: uuid;
  name: string;
  description: string | null;
  open_at: timestamptz | null;
}

export interface GetActiveEnrollmentPeriods_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  /**
   * An array relationship
   */
  form_templates: GetActiveEnrollmentPeriods_enrollment_period_form_templates[];
}

export interface GetActiveEnrollmentPeriods {
  /**
   * fetch data from the table: "enrollment_period"
   */
  enrollment_period: GetActiveEnrollmentPeriods_enrollment_period[];
}

export interface GetActiveEnrollmentPeriodsVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignedForm
// ====================================================

export interface GetAssignedForm_assigned_form_form_template {
  __typename: "form_template";
  id: uuid;
}

export interface GetAssignedForm_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  person_id: uuid;
  /**
   * An object relationship
   */
  form_template: GetAssignedForm_assigned_form_form_template;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
}

export interface GetAssignedForm {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetAssignedForm_assigned_form[];
}

export interface GetAssignedFormVariables {
  student_id: uuid;
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHiddenForm
// ====================================================

export interface GetHiddenForm_form {
  __typename: "form";
  id: uuid;
}

export interface GetHiddenForm {
  /**
   * fetch data from the table: "form"
   */
  form: GetHiddenForm_form[];
}

export interface GetHiddenFormVariables {
  student_id: uuid;
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLastDeletedForm
// ====================================================

export interface GetLastDeletedForm_form {
  __typename: "form";
  id: uuid;
}

export interface GetLastDeletedForm {
  /**
   * fetch data from the table: "form"
   */
  form: GetLastDeletedForm_form[];
}

export interface GetLastDeletedFormVariables {
  student_id: uuid;
  form_template_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSourceAndTargetFormTemplateIds
// ====================================================

export interface GetSourceAndTargetFormTemplateIds_source_form {
  __typename: "form";
  form_template_id: uuid;
}

export interface GetSourceAndTargetFormTemplateIds_target_form {
  __typename: "form";
  form_template_id: uuid;
}

export interface GetSourceAndTargetFormTemplateIds {
  /**
   * fetch data from the table: "form" using primary key columns
   */
  source_form: GetSourceAndTargetFormTemplateIds_source_form | null;
  /**
   * fetch data from the table: "form" using primary key columns
   */
  target_form: GetSourceAndTargetFormTemplateIds_target_form | null;
}

export interface GetSourceAndTargetFormTemplateIdsVariables {
  source_form_id: uuid;
  target_form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomQuestionAnswers
// ====================================================

export interface GetCustomQuestionAnswers_custom_question_answer_form_answer {
  __typename: "form_answer";
  question_id: uuid;
}

export interface GetCustomQuestionAnswers_custom_question_answer {
  __typename: "custom_question_answer";
  /**
   * An object relationship
   */
  form_answer: GetCustomQuestionAnswers_custom_question_answer_form_answer;
}

export interface GetCustomQuestionAnswers_custom_question_answer_bank_relationship {
  __typename: "custom_question_answer_bank_relationship";
  custom_question_id: uuid;
  person_answer_bank_id: uuid;
}

export interface GetCustomQuestionAnswers {
  /**
   * fetch data from the table: "custom_question_answer"
   */
  custom_question_answer: GetCustomQuestionAnswers_custom_question_answer[];
  /**
   * fetch data from the table: "custom_question_answer_bank_relationship"
   */
  custom_question_answer_bank_relationship: GetCustomQuestionAnswers_custom_question_answer_bank_relationship[];
}

export interface GetCustomQuestionAnswersVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetApplicantDateOfBirthByFormId
// ====================================================

export interface GetApplicantDateOfBirthByFormId_form_by_pk_person {
  __typename: "person";
  birth_date: date | null;
}

export interface GetApplicantDateOfBirthByFormId_form_by_pk {
  __typename: "form";
  /**
   * An object relationship
   */
  person: GetApplicantDateOfBirthByFormId_form_by_pk_person;
}

export interface GetApplicantDateOfBirthByFormId {
  /**
   * fetch data from the table: "form" using primary key columns
   */
  form_by_pk: GetApplicantDateOfBirthByFormId_form_by_pk | null;
}

export interface GetApplicantDateOfBirthByFormIdVariables {
  formId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteForm
// ====================================================

export interface DeleteForm_update_form_by_pk {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
}

export interface DeleteForm_update_offer_returning {
  __typename: "offer";
  id: uuid;
}

export interface DeleteForm_update_offer {
  __typename: "offer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: DeleteForm_update_offer_returning[];
}

export interface DeleteForm_update_waitlist_returning {
  __typename: "waitlist";
  id: uuid;
}

export interface DeleteForm_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: number;
  /**
   * data from the rows affected by the mutation
   */
  returning: DeleteForm_update_waitlist_returning[];
}

export interface DeleteForm {
  /**
   * update single row of the table: "form"
   */
  update_form_by_pk: DeleteForm_update_form_by_pk | null;
  /**
   * update data of the table: "offer"
   */
  update_offer: DeleteForm_update_offer | null;
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: DeleteForm_update_waitlist | null;
}

export interface DeleteFormVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptOffer
// ====================================================

export interface AcceptOffer_update_offer_by_pk {
  __typename: "offer";
  id: uuid;
}

export interface AcceptOffer {
  /**
   * update single row of the table: "offer"
   */
  update_offer_by_pk: AcceptOffer_update_offer_by_pk | null;
}

export interface AcceptOfferVariables {
  offer_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineOffer
// ====================================================

export interface DeclineOffer_update_offer_by_pk {
  __typename: "offer";
  id: uuid;
}

export interface DeclineOffer {
  /**
   * update single row of the table: "offer"
   */
  update_offer_by_pk: DeclineOffer_update_offer_by_pk | null;
}

export interface DeclineOfferVariables {
  offer_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WithdrawForm
// ====================================================

export interface WithdrawForm_update_waitlist_returning {
  __typename: "waitlist";
  id: uuid;
}

export interface WithdrawForm_update_waitlist {
  __typename: "waitlist_mutation_response";
  /**
   * data from the rows affected by the mutation
   */
  returning: WithdrawForm_update_waitlist_returning[];
}

export interface WithdrawForm {
  /**
   * update data of the table: "waitlist"
   */
  update_waitlist: WithdrawForm_update_waitlist | null;
}

export interface WithdrawFormVariables {
  form_id: uuid;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetParentAssignedForms
// ====================================================

export interface GetParentAssignedForms_assigned_form_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
}

export interface GetParentAssignedForms_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  /**
   * An object relationship
   */
  form_template: GetParentAssignedForms_assigned_form_form_template;
  person_id: uuid;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
}

export interface GetParentAssignedForms {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetParentAssignedForms_assigned_form[];
}

export interface GetParentAssignedFormsVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetParentFormsIndex
// ====================================================

export interface GetParentFormsIndex_assigned_form_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
}

export interface GetParentFormsIndex_assigned_form {
  __typename: "assigned_form";
  id: uuid;
  /**
   * An object relationship
   */
  form_template: GetParentFormsIndex_assigned_form_form_template;
  person_id: uuid;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
}

export interface GetParentFormsIndex_person_forms_previous_offer_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetParentFormsIndex_person_forms_previous_offer {
  __typename: "offer";
  /**
   * An object relationship
   */
  school: GetParentFormsIndex_person_forms_previous_offer_school;
}

export interface GetParentFormsIndex_person_forms_previous_waitlist_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetParentFormsIndex_person_forms_previous_waitlist {
  __typename: "waitlist";
  /**
   * An object relationship
   */
  school: GetParentFormsIndex_person_forms_previous_waitlist_school;
}

export interface GetParentFormsIndex_person_forms_action_items {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface GetParentFormsIndex_person_forms_form_template_form_status_descriptions {
  __typename: "form_status_description";
  description: string | null;
  id: uuid;
  status: form_status_enum;
}

export interface GetParentFormsIndex_person_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface GetParentFormsIndex_person_forms_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  ranking_enabled: boolean;
}

export interface GetParentFormsIndex_person_forms_form_template_sections {
  __typename: "form_template_section";
  type: form_template_section_type_enum;
  /**
   * An object relationship
   */
  schools_ranking_section: GetParentFormsIndex_person_forms_form_template_sections_schools_ranking_section | null;
  family_editable_statuses: jsonb | null;
}

export interface GetParentFormsIndex_person_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  closed_at: timestamptz | null;
  open_at: timestamptz | null;
  /**
   * An array relationship
   */
  form_status_descriptions: GetParentFormsIndex_person_forms_form_template_form_status_descriptions[];
  /**
   * An object relationship
   */
  enrollment_period: GetParentFormsIndex_person_forms_form_template_enrollment_period;
  /**
   * An array relationship
   */
  sections: GetParentFormsIndex_person_forms_form_template_sections[];
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  status: waitlist_status_enum;
  /**
   * An object relationship
   */
  waitlist_position: GetParentFormsIndex_person_forms_form_school_ranks_waitlists_waitlist_position | null;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetParentFormsIndex_person_forms_form_school_ranks_tags_enrollment_period_tag;
}

export interface GetParentFormsIndex_person_forms_form_school_ranks {
  __typename: "form_school_rank";
  created_at: timestamptz;
  rank: number;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  updated_at: timestamptz;
  /**
   * An array relationship
   */
  offers: GetParentFormsIndex_person_forms_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: GetParentFormsIndex_person_forms_form_school_ranks_waitlists[];
  /**
   * An object relationship
   */
  school: GetParentFormsIndex_person_forms_form_school_ranks_school;
  /**
   * An array relationship
   */
  tags: GetParentFormsIndex_person_forms_form_school_ranks_tags[];
}

export interface GetParentFormsIndex_person_forms_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetParentFormsIndex_person_forms_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: GetParentFormsIndex_person_forms_grades_answers_grade_config | null;
}

export interface GetParentFormsIndex_person_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  submitted_at: timestamptz | null;
  /**
   * An object relationship
   */
  previous_offer: GetParentFormsIndex_person_forms_previous_offer | null;
  /**
   * An object relationship
   */
  previous_waitlist: GetParentFormsIndex_person_forms_previous_waitlist | null;
  /**
   * An array relationship
   */
  action_items: GetParentFormsIndex_person_forms_action_items[];
  /**
   * An object relationship
   */
  form_template: GetParentFormsIndex_person_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetParentFormsIndex_person_forms_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: GetParentFormsIndex_person_forms_grades_answers[];
}

export interface GetParentFormsIndex_person {
  __typename: "person";
  id: uuid;
  avatar: string | null;
  birth_date: date | null;
  first_name: string | null;
  last_name: string | null;
  /**
   * An array relationship
   */
  forms: GetParentFormsIndex_person_forms[];
}

export interface GetParentFormsIndex {
  /**
   * fetch data from the table: "assigned_form"
   */
  assigned_form: GetParentFormsIndex_assigned_form[];
  /**
   * fetch data from the table: "person"
   */
  person: GetParentFormsIndex_person[];
}

export interface GetParentFormsIndexVariables {
  organization_id: uuid;
  guardian_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActiveFormTemplates
// ====================================================

export interface GetActiveFormTemplates_form_template_form_status_descriptions {
  __typename: "form_status_description";
  id: uuid;
  form_template_id: uuid | null;
  status: form_status_enum;
  description: string | null;
}

export interface GetActiveFormTemplates_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
}

export interface GetActiveFormTemplates_form_template_forms_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  avatar: string | null;
  updated_at: timestamptz;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  /**
   * An object relationship
   */
  waitlist_position: GetActiveFormTemplates_form_template_forms_form_school_ranks_waitlists_waitlist_position | null;
  status: waitlist_status_enum;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetActiveFormTemplates_form_template_forms_form_school_ranks_tags_enrollment_period_tag;
}

export interface GetActiveFormTemplates_form_template_forms_form_school_ranks {
  __typename: "form_school_rank";
  rank: number;
  created_at: timestamptz;
  updated_at: timestamptz;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: GetActiveFormTemplates_form_template_forms_form_school_ranks_school;
  /**
   * An array relationship
   */
  offers: GetActiveFormTemplates_form_template_forms_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: GetActiveFormTemplates_form_template_forms_form_school_ranks_waitlists[];
  /**
   * An array relationship
   */
  tags: GetActiveFormTemplates_form_template_forms_form_school_ranks_tags[];
}

export interface GetActiveFormTemplates_form_template_forms_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
}

export interface GetActiveFormTemplates_form_template_forms_grades_answers {
  __typename: "grades_answer";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetActiveFormTemplates_form_template_forms_grades_answers_grade_config | null;
}

export interface GetActiveFormTemplates_form_template_forms_action_items {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface GetActiveFormTemplates_form_template_forms {
  __typename: "form";
  id: uuid;
  submitted_at: timestamptz | null;
  status: form_status_enum;
  /**
   * An object relationship
   */
  person: GetActiveFormTemplates_form_template_forms_person;
  /**
   * An array relationship
   */
  form_school_ranks: GetActiveFormTemplates_form_template_forms_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: GetActiveFormTemplates_form_template_forms_grades_answers[];
  /**
   * An array relationship
   */
  action_items: GetActiveFormTemplates_form_template_forms_action_items[];
}

export interface GetActiveFormTemplates_form_template {
  __typename: "form_template";
  id: uuid;
  /**
   * An array relationship
   */
  form_status_descriptions: GetActiveFormTemplates_form_template_form_status_descriptions[];
  /**
   * An object relationship
   */
  enrollment_period: GetActiveFormTemplates_form_template_enrollment_period;
  /**
   * An array relationship
   */
  forms: GetActiveFormTemplates_form_template_forms[];
}

export interface GetActiveFormTemplates {
  /**
   * fetch data from the table: "form_template"
   */
  form_template: GetActiveFormTemplates_form_template[];
}

export interface GetActiveFormTemplatesVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOffers
// ====================================================

export interface GetOffers_form_person {
  __typename: "person";
  first_name: string | null;
}

export interface GetOffers_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetOffers_form_grades_answers {
  __typename: "grades_answer";
  /**
   * An object relationship
   */
  grade_config: GetOffers_form_grades_answers_grade_config | null;
}

export interface GetOffers_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetOffers_form_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  ranking_enabled: boolean;
}

export interface GetOffers_form_form_template_sections {
  __typename: "form_template_section";
  /**
   * An object relationship
   */
  schools_ranking_section: GetOffers_form_form_template_sections_schools_ranking_section | null;
}

export interface GetOffers_form_form_template {
  __typename: "form_template";
  /**
   * An object relationship
   */
  enrollment_period: GetOffers_form_form_template_enrollment_period;
  /**
   * An array relationship
   */
  sections: GetOffers_form_form_template_sections[];
}

export interface GetOffers_form_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetOffers_form_form_school_ranks_school {
  __typename: "school";
  name: string;
}

export interface GetOffers_form_form_school_ranks {
  __typename: "form_school_rank";
  /**
   * An array relationship
   */
  offers: GetOffers_form_form_school_ranks_offers[];
  /**
   * An object relationship
   */
  school: GetOffers_form_form_school_ranks_school;
  rank: number;
}

export interface GetOffers_form {
  __typename: "form";
  id: uuid;
  form_template_id: uuid;
  /**
   * An object relationship
   */
  person: GetOffers_form_person;
  /**
   * An array relationship
   */
  grades_answers: GetOffers_form_grades_answers[];
  /**
   * An object relationship
   */
  form_template: GetOffers_form_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: GetOffers_form_form_school_ranks[];
}

export interface GetOffers {
  /**
   * fetch data from the table: "form"
   */
  form: GetOffers_form[];
}

export interface GetOffersVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAcceptedOffers
// ====================================================

export interface GetAcceptedOffers_form_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetAcceptedOffers_form_form_school_ranks_school {
  __typename: "school";
  name: string;
}

export interface GetAcceptedOffers_form_form_school_ranks {
  __typename: "form_school_rank";
  /**
   * An array relationship
   */
  offers: GetAcceptedOffers_form_form_school_ranks_offers[];
  /**
   * An object relationship
   */
  school: GetAcceptedOffers_form_form_school_ranks_school;
}

export interface GetAcceptedOffers_form {
  __typename: "form";
  id: uuid;
  /**
   * An array relationship
   */
  form_school_ranks: GetAcceptedOffers_form_form_school_ranks[];
}

export interface GetAcceptedOffers {
  /**
   * fetch data from the table: "form"
   */
  form: GetAcceptedOffers_form[];
}

export interface GetAcceptedOffersVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SchoolsBySchoolReferenceIDs
// ====================================================

export interface SchoolsBySchoolReferenceIDs_school_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
}

export interface SchoolsBySchoolReferenceIDs_school_grades {
  __typename: "grade";
  /**
   * An object relationship
   */
  grade_config: SchoolsBySchoolReferenceIDs_school_grades_grade_config;
}

export interface SchoolsBySchoolReferenceIDs_school {
  __typename: "school";
  id: uuid;
  reference_id: string | null;
  name: string;
  /**
   * An array relationship
   */
  grades: SchoolsBySchoolReferenceIDs_school_grades[];
}

export interface SchoolsBySchoolReferenceIDs {
  /**
   * fetch data from the table: "school"
   */
  school: SchoolsBySchoolReferenceIDs_school[];
}

export interface SchoolsBySchoolReferenceIDsVariables {
  school_reference_ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormTemplateHintForParent
// ====================================================

export interface GetFormTemplateHintForParent_form_template_hint {
  __typename: "form_template_hint";
  form_template_id: uuid;
  status_type: status_rule_type_enum;
  form_status: form_status_enum | null;
  offer_status: offer_status_enum | null;
  waitlist_status: waitlist_status_enum | null;
  title: string;
  body: string;
  image_type: message_image_type_enum | null;
}

export interface GetFormTemplateHintForParent {
  /**
   * fetch data from the table: "form_template_hint"
   */
  form_template_hint: GetFormTemplateHintForParent_form_template_hint[];
}

export interface GetFormTemplateHintForParentVariables {
  organizationId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInactiveForms
// ====================================================

export interface GetInactiveForms_form_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  avatar: string | null;
  updated_at: timestamptz;
}

export interface GetInactiveForms_form_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetInactiveForms_form_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetInactiveForms_form_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetInactiveForms_form_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  /**
   * An object relationship
   */
  waitlist_position: GetInactiveForms_form_form_school_ranks_waitlists_waitlist_position | null;
  status: waitlist_status_enum;
}

export interface GetInactiveForms_form_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
}

export interface GetInactiveForms_form_form_school_ranks_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetInactiveForms_form_form_school_ranks_tags_enrollment_period_tag;
}

export interface GetInactiveForms_form_form_school_ranks {
  __typename: "form_school_rank";
  rank: number;
  created_at: timestamptz;
  updated_at: timestamptz;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: GetInactiveForms_form_form_school_ranks_school;
  /**
   * An array relationship
   */
  offers: GetInactiveForms_form_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: GetInactiveForms_form_form_school_ranks_waitlists[];
  /**
   * An array relationship
   */
  tags: GetInactiveForms_form_form_school_ranks_tags[];
}

export interface GetInactiveForms_form_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
}

export interface GetInactiveForms_form_grades_answers {
  __typename: "grades_answer";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetInactiveForms_form_grades_answers_grade_config | null;
}

export interface GetInactiveForms_form_action_items {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface GetInactiveForms_form_form_template_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetInactiveForms_form_form_template {
  __typename: "form_template";
  /**
   * An object relationship
   */
  enrollment_period: GetInactiveForms_form_form_template_enrollment_period;
}

export interface GetInactiveForms_form {
  __typename: "form";
  id: uuid;
  submitted_at: timestamptz | null;
  status: form_status_enum;
  /**
   * An object relationship
   */
  person: GetInactiveForms_form_person;
  /**
   * An array relationship
   */
  form_school_ranks: GetInactiveForms_form_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: GetInactiveForms_form_grades_answers[];
  /**
   * An array relationship
   */
  action_items: GetInactiveForms_form_action_items[];
  /**
   * An object relationship
   */
  form_template: GetInactiveForms_form_form_template;
}

export interface GetInactiveForms {
  /**
   * fetch data from the table: "form"
   */
  form: GetInactiveForms_form[];
}

export interface GetInactiveFormsVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetImportantDatesByEnrollmentPeriod
// ====================================================

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_sections {
  __typename: "form_template_section";
  id: uuid;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  avatar: string | null;
  updated_at: timestamptz;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  /**
   * An object relationship
   */
  waitlist_position: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_waitlists_waitlist_position | null;
  status: waitlist_status_enum;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_tags_enrollment_period_tag;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks {
  __typename: "form_school_rank";
  rank: number;
  created_at: timestamptz;
  updated_at: timestamptz;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_school;
  /**
   * An array relationship
   */
  offers: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_waitlists[];
  /**
   * An array relationship
   */
  tags: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks_tags[];
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_grades_answers {
  __typename: "grades_answer";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_grades_answers_grade_config | null;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_action_items {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms {
  __typename: "form";
  id: uuid;
  submitted_at: timestamptz | null;
  status: form_status_enum;
  /**
   * An object relationship
   */
  person: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_person;
  /**
   * An array relationship
   */
  form_school_ranks: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_grades_answers[];
  /**
   * An array relationship
   */
  action_items: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms_action_items[];
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates {
  __typename: "form_template";
  id: uuid;
  name: string;
  active: boolean;
  open_at: timestamptz | null;
  closed_at: timestamptz | null;
  offer_start_at: timestamptz | null;
  reopen_at: timestamptz | null;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_sections[];
  /**
   * An array relationship
   */
  forms: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates_forms[];
}

export interface GetImportantDatesByEnrollmentPeriod_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  active: boolean;
  name: string;
  /**
   * An array relationship
   */
  form_templates: GetImportantDatesByEnrollmentPeriod_enrollment_period_form_templates[];
}

export interface GetImportantDatesByEnrollmentPeriod {
  /**
   * fetch data from the table: "enrollment_period"
   */
  enrollment_period: GetImportantDatesByEnrollmentPeriod_enrollment_period[];
}

export interface GetImportantDatesByEnrollmentPeriodVariables {
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProfile
// ====================================================

export interface CreateProfile_insert_person_one {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  user_id: string | null;
  phone_number: string | null;
  email_address: string | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
  avatar: string | null;
  preferred_language: string | null;
}

export interface CreateProfile {
  /**
   * insert a single row into the table: "person"
   */
  insert_person_one: CreateProfile_insert_person_one | null;
}

export interface CreateProfileVariables {
  person: person_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProfile
// ====================================================

export interface UpdateProfile_update_person_by_pk {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  user_id: string | null;
  phone_number: string | null;
  email_address: string | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
  avatar: string | null;
  preferred_language: string | null;
}

export interface UpdateProfile {
  /**
   * update single row of the table: "person"
   */
  update_person_by_pk: UpdateProfile_update_person_by_pk | null;
}

export interface UpdateProfileVariables {
  id: uuid;
  person?: person_set_input | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertProfileName
// ====================================================

export interface UpsertProfileName_insert_person_one {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
}

export interface UpsertProfileName {
  /**
   * insert a single row into the table: "person"
   */
  insert_person_one: UpsertProfileName_insert_person_one | null;
}

export interface UpsertProfileNameVariables {
  user_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertProfileAddress
// ====================================================

export interface UpsertProfileAddress_insert_person_one {
  __typename: "person";
  id: uuid;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

export interface UpsertProfileAddress {
  /**
   * insert a single row into the table: "person"
   */
  insert_person_one: UpsertProfileAddress_insert_person_one | null;
}

export interface UpsertProfileAddressVariables {
  user_id: string;
  street_address: string;
  street_address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  organization_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileByUserIdAndOrgId
// ====================================================

export interface GetProfileByUserIdAndOrgId_person_user {
  __typename: "user";
  name: string | null;
}

export interface GetProfileByUserIdAndOrgId_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  user_id: string | null;
  phone_number: string | null;
  email_address: string | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
  avatar: string | null;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetProfileByUserIdAndOrgId_person_user | null;
}

export interface GetProfileByUserIdAndOrgId {
  /**
   * fetch data from the table: "person"
   */
  person: GetProfileByUserIdAndOrgId_person[];
}

export interface GetProfileByUserIdAndOrgIdVariables {
  user_id: string;
  organizationId: uuid;
  include_user?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SoftDeleteStudent
// ====================================================

export interface SoftDeleteStudent_update_person_by_pk {
  __typename: "person";
  id: uuid;
}

export interface SoftDeleteStudent {
  /**
   * update single row of the table: "person"
   */
  update_person_by_pk: SoftDeleteStudent_update_person_by_pk | null;
}

export interface SoftDeleteStudentVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentsByGuardianId
// ====================================================

export interface GetStudentsByGuardianId_person_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentsByGuardianId_person_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentsByGuardianId_person_forms {
  __typename: "form";
  form_template_id: uuid;
}

export interface GetStudentsByGuardianId_person_assigned_forms_form_template {
  __typename: "form_template";
  name: string;
  description: string | null;
  id: uuid;
  open_at: timestamptz | null;
  enrollment_period_id: uuid;
}

export interface GetStudentsByGuardianId_person_assigned_forms {
  __typename: "assigned_form";
  /**
   * An object relationship
   */
  form_template: GetStudentsByGuardianId_person_assigned_forms_form_template;
}

export interface GetStudentsByGuardianId_person_forms_aggregate_aggregate {
  __typename: "form_aggregate_fields";
  count: number;
}

export interface GetStudentsByGuardianId_person_forms_aggregate {
  __typename: "form_aggregate";
  aggregate: GetStudentsByGuardianId_person_forms_aggregate_aggregate | null;
}

export interface GetStudentsByGuardianId_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentsByGuardianId_person_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentsByGuardianId_person_person_bins[];
  /**
   * An array relationship
   */
  forms: GetStudentsByGuardianId_person_forms[];
  /**
   * An array relationship
   */
  assigned_forms: GetStudentsByGuardianId_person_assigned_forms[];
  /**
   * An aggregate relationship
   */
  forms_aggregate: GetStudentsByGuardianId_person_forms_aggregate;
}

export interface GetStudentsByGuardianId {
  /**
   * fetch data from the table: "person"
   */
  person: GetStudentsByGuardianId_person[];
}

export interface GetStudentsByGuardianIdVariables {
  organization_id: uuid;
  guardian_id: uuid;
  include_form_count?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvatars
// ====================================================

export interface GetAvatars_person_first_relationship_second {
  __typename: "person";
  id: uuid;
  avatar: string | null;
}

export interface GetAvatars_person_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GetAvatars_person_first_relationship_second;
}

export interface GetAvatars_person_second_relationship_first {
  __typename: "person";
  id: uuid;
  avatar: string | null;
}

export interface GetAvatars_person_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GetAvatars_person_second_relationship_first;
}

export interface GetAvatars_person {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  last_name: string | null;
  /**
   * An array relationship
   */
  first_relationship: GetAvatars_person_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GetAvatars_person_second_relationship[];
}

export interface GetAvatars {
  /**
   * fetch data from the table: "person"
   */
  person: GetAvatars_person[];
}

export interface GetAvatarsVariables {
  organization_id: uuid;
  guardian_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentByIdParentPortal
// ====================================================

export interface GetStudentByIdParentPortal_person_by_pk_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface GetStudentByIdParentPortal_person_by_pk_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface GetStudentByIdParentPortal_person_by_pk_forms_aggregate_aggregate {
  __typename: "form_aggregate_fields";
  count: number;
}

export interface GetStudentByIdParentPortal_person_by_pk_forms_aggregate {
  __typename: "form_aggregate";
  aggregate: GetStudentByIdParentPortal_person_by_pk_forms_aggregate_aggregate | null;
}

export interface GetStudentByIdParentPortal_person_by_pk {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: GetStudentByIdParentPortal_person_by_pk_user | null;
  /**
   * An array relationship
   */
  person_bins: GetStudentByIdParentPortal_person_by_pk_person_bins[];
  /**
   * An aggregate relationship
   */
  forms_aggregate: GetStudentByIdParentPortal_person_by_pk_forms_aggregate;
}

export interface GetStudentByIdParentPortal {
  /**
   * fetch data from the table: "person" using primary key columns
   */
  person_by_pk: GetStudentByIdParentPortal_person_by_pk | null;
}

export interface GetStudentByIdParentPortalVariables {
  id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForFormTemplate
// ====================================================

export interface GetSchoolsForFormTemplate_school_by_form_school_grades_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface GetSchoolsForFormTemplate_school_by_form_school_grades_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface GetSchoolsForFormTemplate_school_by_form_school_grades {
  __typename: "grade";
  id: uuid;
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period: GetSchoolsForFormTemplate_school_by_form_school_grades_enrollment_period;
  /**
   * An object relationship
   */
  grade_config: GetSchoolsForFormTemplate_school_by_form_school_grades_grade_config;
}

export interface GetSchoolsForFormTemplate_school_by_form_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
  /**
   * An array relationship
   */
  grades: GetSchoolsForFormTemplate_school_by_form_school_grades[];
}

export interface GetSchoolsForFormTemplate_school_by_form {
  __typename: "school_by_form";
  /**
   * An object relationship
   */
  school: GetSchoolsForFormTemplate_school_by_form_school | null;
}

export interface GetSchoolsForFormTemplate {
  /**
   * fetch data from the table: "school_by_form"
   */
  school_by_form: GetSchoolsForFormTemplate_school_by_form[];
}

export interface GetSchoolsForFormTemplateVariables {
  form_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolsForOrganization
// ====================================================

export interface GetSchoolsForOrganization_school {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
}

export interface GetSchoolsForOrganization {
  /**
   * fetch data from the table: "school"
   */
  school: GetSchoolsForOrganization_school[];
}

export interface GetSchoolsForOrganizationVariables {
  organization_id: uuid;
  enrollment_period_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreRankingSectionByFormTemplateId
// ====================================================

export interface GetPreRankingSectionByFormTemplateId_form_template_section_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions[];
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_grade_config | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships[];
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetPreRankingSectionByFormTemplateId_form_template_section_questions_custom_question | null;
}

export interface GetPreRankingSectionByFormTemplateId_form_template_section {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetPreRankingSectionByFormTemplateId_form_template_section_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetPreRankingSectionByFormTemplateId_form_template_section_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetPreRankingSectionByFormTemplateId_form_template_section_questions[];
}

export interface GetPreRankingSectionByFormTemplateId {
  /**
   * fetch data from the table: "form_template_section"
   */
  form_template_section: GetPreRankingSectionByFormTemplateId_form_template_section[];
}

export interface GetPreRankingSectionByFormTemplateIdVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchoolRankingSectionByFormTemplateId
// ====================================================

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions_question;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options_additional_questions[];
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_grade_config | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions_question;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question_custom_question_relationships[];
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions_custom_question | null;
}

export interface GetSchoolRankingSectionByFormTemplateId_form_template_section {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: GetSchoolRankingSectionByFormTemplateId_form_template_section_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: GetSchoolRankingSectionByFormTemplateId_form_template_section_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: GetSchoolRankingSectionByFormTemplateId_form_template_section_questions[];
}

export interface GetSchoolRankingSectionByFormTemplateId {
  /**
   * fetch data from the table: "form_template_section"
   */
  form_template_section: GetSchoolRankingSectionByFormTemplateId_form_template_section[];
}

export interface GetSchoolRankingSectionByFormTemplateIdVariables {
  formTemplateId: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonAnswerBankFragment
// ====================================================

export interface PersonAnswerBankFragment_person_answers_custom_question_type_field_question {
  __typename: "question";
  id: uuid;
  type: question_type_enum;
  question: string;
}

export interface PersonAnswerBankFragment_person_answers_custom_question_type_field {
  __typename: "custom_question_type_field";
  question_id: uuid;
  /**
   * An object relationship
   */
  question: PersonAnswerBankFragment_person_answers_custom_question_type_field_question;
}

export interface PersonAnswerBankFragment_person_answers_person_answer_options_form_question_option {
  __typename: "form_question_option";
  id: uuid;
  label: string;
  value: string | null;
}

export interface PersonAnswerBankFragment_person_answers_person_answer_options {
  __typename: "person_answer_option";
  id: uuid;
  /**
   * An object relationship
   */
  form_question_option: PersonAnswerBankFragment_person_answers_person_answer_options_form_question_option;
}

export interface PersonAnswerBankFragment_person_answers {
  __typename: "person_answer";
  id: uuid;
  /**
   * An object relationship
   */
  custom_question_type_field: PersonAnswerBankFragment_person_answers_custom_question_type_field;
  answer: string | null;
  /**
   * An array relationship
   */
  person_answer_options: PersonAnswerBankFragment_person_answers_person_answer_options[];
}

export interface PersonAnswerBankFragment {
  __typename: "person_answer_bank";
  id: uuid;
  /**
   * An array relationship
   */
  person_answers: PersonAnswerBankFragment_person_answers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Organization
// ====================================================

export interface Organization {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ActionItemFragment
// ====================================================

export interface ActionItemFragment {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormSummaryFragment
// ====================================================

export interface FormSummaryFragment_person {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  avatar: string | null;
  updated_at: timestamptz;
}

export interface FormSummaryFragment_form_school_ranks_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface FormSummaryFragment_form_school_ranks_offers {
  __typename: "offer";
  id: uuid;
  status: offer_status_enum;
}

export interface FormSummaryFragment_form_school_ranks_waitlists_waitlist_position {
  __typename: "waitlist_position";
  position: number | null;
}

export interface FormSummaryFragment_form_school_ranks_waitlists {
  __typename: "waitlist";
  id: uuid;
  /**
   * An object relationship
   */
  waitlist_position: FormSummaryFragment_form_school_ranks_waitlists_waitlist_position | null;
  status: waitlist_status_enum;
}

export interface FormSummaryFragment_form_school_ranks_tags_enrollment_period_tag {
  __typename: "enrollment_period_tag";
  id: uuid;
  name: citext;
  description: string | null;
}

export interface FormSummaryFragment_form_school_ranks_tags {
  __typename: "form_school_tag";
  id: uuid;
  /**
   * An object relationship
   */
  enrollment_period_tag: FormSummaryFragment_form_school_ranks_tags_enrollment_period_tag;
}

export interface FormSummaryFragment_form_school_ranks {
  __typename: "form_school_rank";
  rank: number;
  created_at: timestamptz;
  updated_at: timestamptz;
  /**
   * A computed field, executes function "get_form_sub_status"
   */
  sub_status: string | null;
  /**
   * An object relationship
   */
  school: FormSummaryFragment_form_school_ranks_school;
  /**
   * An array relationship
   */
  offers: FormSummaryFragment_form_school_ranks_offers[];
  /**
   * An array relationship
   */
  waitlists: FormSummaryFragment_form_school_ranks_waitlists[];
  /**
   * An array relationship
   */
  tags: FormSummaryFragment_form_school_ranks_tags[];
}

export interface FormSummaryFragment_grades_answers_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
}

export interface FormSummaryFragment_grades_answers {
  __typename: "grades_answer";
  id: uuid;
  /**
   * An object relationship
   */
  grade_config: FormSummaryFragment_grades_answers_grade_config | null;
}

export interface FormSummaryFragment_action_items {
  __typename: "action_item";
  id: uuid;
  form_id: uuid;
  summary: string;
  status: action_item_status_enum;
  created_at: timestamptz;
  updated_at: timestamptz;
}

export interface FormSummaryFragment {
  __typename: "form";
  id: uuid;
  submitted_at: timestamptz | null;
  status: form_status_enum;
  /**
   * An object relationship
   */
  person: FormSummaryFragment_person;
  /**
   * An array relationship
   */
  form_school_ranks: FormSummaryFragment_form_school_ranks[];
  /**
   * An array relationship
   */
  grades_answers: FormSummaryFragment_grades_answers[];
  /**
   * An array relationship
   */
  action_items: FormSummaryFragment_action_items[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormVerificationResultFragment
// ====================================================

export interface FormVerificationResultFragment_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormVerificationResultFragment {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: FormVerificationResultFragment_form_verification;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormAnswerFragment
// ====================================================

export interface FormAnswerFragment_form_question_question {
  __typename: "question";
  type: question_type_enum;
}

export interface FormAnswerFragment_form_question {
  __typename: "form_question";
  question_id: uuid;
  category: form_question_category_enum;
  /**
   * An object relationship
   */
  question: FormAnswerFragment_form_question_question;
}

export interface FormAnswerFragment_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
  form_question_option_id: uuid;
}

export interface FormAnswerFragment_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface FormAnswerFragment {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_question: FormAnswerFragment_form_question;
  free_text_answer: string | null;
  number_answer: numeric | null;
  date_answer: date | null;
  /**
   * An array relationship
   */
  form_answer_options: FormAnswerFragment_form_answer_options[];
  /**
   * An array relationship
   */
  document_metadata: FormAnswerFragment_document_metadata[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GradesAnswersFragment
// ====================================================

export interface GradesAnswersFragment_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
}

export interface GradesAnswersFragment {
  __typename: "grades_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  grade_config: GradesAnswersFragment_grade_config | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressAnswersFragment
// ====================================================

export interface AddressAnswersFragment {
  __typename: "form_address";
  id: uuid;
  question_id: uuid;
  street_address: string;
  street_address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomQuestionAnswersFragment
// ====================================================

export interface CustomQuestionAnswersFragment_form_answer_form_question_question {
  __typename: "question";
  type: question_type_enum;
}

export interface CustomQuestionAnswersFragment_form_answer_form_question {
  __typename: "form_question";
  question_id: uuid;
  category: form_question_category_enum;
  /**
   * An object relationship
   */
  question: CustomQuestionAnswersFragment_form_answer_form_question_question;
}

export interface CustomQuestionAnswersFragment_form_answer_form_answer_options {
  __typename: "form_answer_option";
  id: uuid;
  form_question_option_id: uuid;
}

export interface CustomQuestionAnswersFragment_form_answer_document_metadata {
  __typename: "document_metadata";
  document_id: uuid;
}

export interface CustomQuestionAnswersFragment_form_answer {
  __typename: "form_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_question: CustomQuestionAnswersFragment_form_answer_form_question;
  free_text_answer: string | null;
  number_answer: numeric | null;
  date_answer: date | null;
  /**
   * An array relationship
   */
  form_answer_options: CustomQuestionAnswersFragment_form_answer_form_answer_options[];
  /**
   * An array relationship
   */
  document_metadata: CustomQuestionAnswersFragment_form_answer_document_metadata[];
}

export interface CustomQuestionAnswersFragment {
  __typename: "custom_question_answer";
  id: uuid;
  question_id: uuid;
  /**
   * An object relationship
   */
  form_answer: CustomQuestionAnswersFragment_form_answer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomQuestionAnswerBankRelationshipsFragment
// ====================================================

export interface CustomQuestionAnswerBankRelationshipsFragment_person_answer_bank_person_answer_bank_external_relationship {
  __typename: "person_answer_bank_external_relationship";
  external_id: string;
}

export interface CustomQuestionAnswerBankRelationshipsFragment_person_answer_bank {
  __typename: "person_answer_bank";
  id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank_external_relationship: CustomQuestionAnswerBankRelationshipsFragment_person_answer_bank_person_answer_bank_external_relationship | null;
}

export interface CustomQuestionAnswerBankRelationshipsFragment {
  __typename: "custom_question_answer_bank_relationship";
  id: uuid;
  custom_question_id: uuid;
  /**
   * An object relationship
   */
  person_answer_bank: CustomQuestionAnswerBankRelationshipsFragment_person_answer_bank;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormDisclaimerFragment
// ====================================================

export interface FormDisclaimerFragment {
  __typename: "form_disclaimer";
  id: uuid;
  signature: string;
  signed_at: timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormFragment
// ====================================================

export interface FormFragment_form_template_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface FormFragment_form_template_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface FormFragment_form_template_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface FormFragment_form_template_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormFragment_form_template_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: FormFragment_form_template_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface FormFragment_form_template_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_template_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormFragment_form_template_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormFragment_form_template_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: FormFragment_form_template_sections_questions_grades_question_grades_grade_config | null;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface FormFragment_form_template_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions_question;
}

export interface FormFragment_form_template_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: FormFragment_form_template_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: FormFragment_form_template_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface FormFragment_form_template_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface FormFragment_form_template_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: FormFragment_form_template_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: FormFragment_form_template_sections_questions_custom_question_custom_question_relationships[];
}

export interface FormFragment_form_template_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormFragment_form_template_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormFragment_form_template_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: FormFragment_form_template_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: FormFragment_form_template_sections_questions_custom_question | null;
}

export interface FormFragment_form_template_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: FormFragment_form_template_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: FormFragment_form_template_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: FormFragment_form_template_sections_questions[];
}

export interface FormFragment_form_template_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_template_enrollment_period {
  __typename: "enrollment_period";
  active: boolean;
  name: string;
}

export interface FormFragment_form_template {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: FormFragment_form_template_sections[];
  /**
   * An array relationship
   */
  form_verifications: FormFragment_form_template_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: FormFragment_form_template_enrollment_period;
}

export interface FormFragment_form_verification_results_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormFragment_form_verification_results {
  __typename: "form_verification_result";
  id: uuid;
  verification_status: verification_status_enum;
  /**
   * An object relationship
   */
  form_verification: FormFragment_form_verification_results_form_verification;
}

export interface FormFragment {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  submitted_before: boolean | null;
  /**
   * An object relationship
   */
  form_template: FormFragment_form_template;
  /**
   * An array relationship
   */
  form_verification_results: FormFragment_form_verification_results[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormVerificationFragment
// ====================================================

export interface FormVerificationFragment {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EligibilityFragment
// ====================================================

export interface EligibilityFragment {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: QuestionOptionFragment
// ====================================================

export interface QuestionOptionFragment_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface QuestionOptionFragment {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: QuestionOptionFragment_eligibility_schools[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionWithoutBranchingFragment
// ====================================================

export interface FormQuestionWithoutBranchingFragment_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormQuestionWithoutBranchingFragment_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormQuestionWithoutBranchingFragment_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormQuestionWithoutBranchingFragment_form_question_form_question_options_eligibility_schools[];
}

export interface FormQuestionWithoutBranchingFragment_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormQuestionWithoutBranchingFragment_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormQuestionWithoutBranchingFragment_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormQuestionWithoutBranchingFragment_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormQuestionWithoutBranchingFragment {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormQuestionWithoutBranchingFragment_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormQuestionWithoutBranchingFragment_form_question | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionOptionFragment
// ====================================================

export interface FormQuestionOptionFragment_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormQuestionOptionFragment_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormQuestionOptionFragment_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormQuestionOptionFragment_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormQuestionOptionFragment_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormQuestionOptionFragment_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormQuestionOptionFragment_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormQuestionOptionFragment_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormQuestionOptionFragment_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormQuestionOptionFragment_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormQuestionOptionFragment_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormQuestionOptionFragment_additional_questions_question_form_question | null;
}

export interface FormQuestionOptionFragment_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: FormQuestionOptionFragment_additional_questions_question;
}

export interface FormQuestionOptionFragment {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormQuestionOptionFragment_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: FormQuestionOptionFragment_additional_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionFragment
// ====================================================

export interface FormQuestionFragment_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormQuestionFragment_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormQuestionFragment_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface FormQuestionFragment_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: FormQuestionFragment_form_question_form_question_options_additional_questions_question;
}

export interface FormQuestionFragment_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormQuestionFragment_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: FormQuestionFragment_form_question_form_question_options_additional_questions[];
}

export interface FormQuestionFragment_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormQuestionFragment_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormQuestionFragment_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormQuestionFragment_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormQuestionFragment {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormQuestionFragment_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormQuestionFragment_form_question | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GradeFragment
// ====================================================

export interface GradeFragment_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface GradeFragment {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: GradeFragment_grade_config | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: QuestionFragment
// ====================================================

export interface QuestionFragment_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface QuestionFragment_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: QuestionFragment_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: QuestionFragment_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface QuestionFragment_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: QuestionFragment_form_question_form_question_options_additional_questions_question;
}

export interface QuestionFragment_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: QuestionFragment_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: QuestionFragment_form_question_form_question_options_additional_questions[];
}

export interface QuestionFragment_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface QuestionFragment_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: QuestionFragment_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: QuestionFragment_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface QuestionFragment_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface QuestionFragment_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: QuestionFragment_grades_question_grades_grade_config | null;
}

export interface QuestionFragment_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface QuestionFragment_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: QuestionFragment_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface QuestionFragment_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: QuestionFragment_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: QuestionFragment_grades_question_grades_additional_questions_question_form_question | null;
}

export interface QuestionFragment_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: QuestionFragment_grades_question_grades_additional_questions_question;
}

export interface QuestionFragment_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: QuestionFragment_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: QuestionFragment_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface QuestionFragment_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface QuestionFragment_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: QuestionFragment_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface QuestionFragment_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: QuestionFragment_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface QuestionFragment_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: QuestionFragment_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: QuestionFragment_custom_question_custom_question_relationships[];
}

export interface QuestionFragment {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: QuestionFragment_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: QuestionFragment_form_question | null;
  /**
   * An object relationship
   */
  grades_question: QuestionFragment_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: QuestionFragment_custom_question | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolsRankingSectionFragment
// ====================================================

export interface SchoolsRankingSectionFragment {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DisclaimerSectionFragment
// ====================================================

export interface DisclaimerSectionFragment {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormTemplateSectionFragment
// ====================================================

export interface FormTemplateSectionFragment_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface FormTemplateSectionFragment_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface FormTemplateSectionFragment_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions_question;
}

export interface FormTemplateSectionFragment_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateSectionFragment_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: FormTemplateSectionFragment_questions_form_question_form_question_options_additional_questions[];
}

export interface FormTemplateSectionFragment_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateSectionFragment_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateSectionFragment_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateSectionFragment_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: FormTemplateSectionFragment_questions_grades_question_grades_grade_config | null;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface FormTemplateSectionFragment_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions_question;
}

export interface FormTemplateSectionFragment_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: FormTemplateSectionFragment_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: FormTemplateSectionFragment_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface FormTemplateSectionFragment_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface FormTemplateSectionFragment_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: FormTemplateSectionFragment_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: FormTemplateSectionFragment_questions_custom_question_custom_question_relationships[];
}

export interface FormTemplateSectionFragment_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateSectionFragment_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateSectionFragment_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: FormTemplateSectionFragment_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: FormTemplateSectionFragment_questions_custom_question | null;
}

export interface FormTemplateSectionFragment {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: FormTemplateSectionFragment_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: FormTemplateSectionFragment_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: FormTemplateSectionFragment_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormTemplateFragment
// ====================================================

export interface FormTemplateFragment_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface FormTemplateFragment_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface FormTemplateFragment_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface FormTemplateFragment_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateFragment_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: FormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface FormTemplateFragment_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateFragment_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateFragment_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateFragment_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: FormTemplateFragment_sections_questions_grades_question_grades_grade_config | null;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface FormTemplateFragment_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question;
}

export interface FormTemplateFragment_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: FormTemplateFragment_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: FormTemplateFragment_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface FormTemplateFragment_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface FormTemplateFragment_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: FormTemplateFragment_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: FormTemplateFragment_sections_questions_custom_question_custom_question_relationships[];
}

export interface FormTemplateFragment_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: FormTemplateFragment_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: FormTemplateFragment_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: FormTemplateFragment_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: FormTemplateFragment_sections_questions_custom_question | null;
}

export interface FormTemplateFragment_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: FormTemplateFragment_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: FormTemplateFragment_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: FormTemplateFragment_sections_questions[];
}

export interface FormTemplateFragment_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface FormTemplateFragment {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: FormTemplateFragment_sections[];
  /**
   * An array relationship
   */
  form_verifications: FormTemplateFragment_form_verifications[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EnrollmentPeriodBasics
// ====================================================

export interface EnrollmentPeriodBasics {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BasicFormTemplateFragment
// ====================================================

export interface BasicFormTemplateFragment_sections_schools_ranking_section {
  __typename: "schools_ranking_section";
  id: uuid;
  explore_url: string | null;
  max_schools: number | null;
  min_schools: number | null;
  ranking_enabled: boolean;
}

export interface BasicFormTemplateFragment_sections_disclaimer_section {
  __typename: "disclaimer_section";
  id: uuid;
  disclaimer: string;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question_form_question | null;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions {
  __typename: "additional_question";
  /**
   * An object relationship
   */
  question: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions_question;
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_eligibility_schools[];
  /**
   * An array relationship
   */
  additional_questions: BasicFormTemplateFragment_sections_questions_form_question_form_question_options_additional_questions[];
}

export interface BasicFormTemplateFragment_sections_questions_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface BasicFormTemplateFragment_sections_questions_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: BasicFormTemplateFragment_sections_questions_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: BasicFormTemplateFragment_sections_questions_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_grade_config {
  __typename: "grade_config";
  id: uuid;
  label: string;
  order: number;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades {
  __typename: "grades_by_grade_question_id";
  /**
   * An object relationship
   */
  grade_config: BasicFormTemplateFragment_sections_questions_grades_question_grades_grade_config | null;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options_eligibility_schools[];
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question_form_question | null;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions {
  __typename: "grades_additional_question";
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  question: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions_question;
}

export interface BasicFormTemplateFragment_sections_questions_grades_question {
  __typename: "grades_question";
  /**
   * An array relationship
   */
  grades: BasicFormTemplateFragment_sections_questions_grades_question_grades[];
  /**
   * An array relationship
   */
  grades_additional_questions: BasicFormTemplateFragment_sections_questions_grades_question_grades_additional_questions[];
  filters: jsonb | null;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_type {
  __typename: "custom_question_type";
  id: uuid;
  name: string;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools {
  __typename: "form_question_school";
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools {
  __typename: "eligibility_question_school";
  is_eligible: boolean;
  school_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options {
  __typename: "form_question_option";
  id: uuid;
  order: number;
  label: string;
  value: string | null;
  translate_options: boolean | null;
  requires_verification: boolean;
  /**
   * An array relationship
   */
  eligibility_schools: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options_eligibility_schools[];
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question {
  __typename: "form_question";
  category: form_question_category_enum;
  /**
   * An array relationship
   */
  form_question_options: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_question_options[];
  /**
   * An object relationship
   */
  form_verification: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_form_verification | null;
  filters: jsonb | null;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question_form_question | null;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships {
  __typename: "custom_question_relationship";
  id: uuid;
  /**
   * An object relationship
   */
  cloned_question: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships_cloned_question;
  custom_question_type_field_id: uuid;
}

export interface BasicFormTemplateFragment_sections_questions_custom_question {
  __typename: "custom_question";
  /**
   * An object relationship
   */
  custom_question_type: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_type;
  /**
   * An array relationship
   */
  custom_question_relationships: BasicFormTemplateFragment_sections_questions_custom_question_custom_question_relationships[];
}

export interface BasicFormTemplateFragment_sections_questions {
  __typename: "question";
  id: uuid;
  order: number;
  question: string;
  type: question_type_enum;
  is_required: boolean;
  key: string | null;
  link_url: string | null;
  link_text: string | null;
  permission_level: string | null;
  constraints: jsonb | null;
  /**
   * An array relationship
   */
  form_question_schools: BasicFormTemplateFragment_sections_questions_form_question_schools[];
  /**
   * An object relationship
   */
  form_question: BasicFormTemplateFragment_sections_questions_form_question | null;
  /**
   * An object relationship
   */
  grades_question: BasicFormTemplateFragment_sections_questions_grades_question | null;
  /**
   * An object relationship
   */
  custom_question: BasicFormTemplateFragment_sections_questions_custom_question | null;
}

export interface BasicFormTemplateFragment_sections {
  __typename: "form_template_section";
  id: uuid;
  title: string;
  description: string | null;
  key: string | null;
  order: number;
  type: form_template_section_type_enum;
  permission_level: string | null;
  family_editable_statuses: jsonb | null;
  filters: jsonb | null;
  /**
   * An object relationship
   */
  schools_ranking_section: BasicFormTemplateFragment_sections_schools_ranking_section | null;
  /**
   * An object relationship
   */
  disclaimer_section: BasicFormTemplateFragment_sections_disclaimer_section | null;
  /**
   * An array relationship
   */
  questions: BasicFormTemplateFragment_sections_questions[];
}

export interface BasicFormTemplateFragment_form_verifications {
  __typename: "form_verification";
  id: uuid;
  label: string;
}

export interface BasicFormTemplateFragment_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
  active: boolean;
}

export interface BasicFormTemplateFragment {
  __typename: "form_template";
  id: uuid;
  enrollment_period_id: uuid;
  name: string;
  key: string;
  description: string | null;
  offer_start_at: timestamptz | null;
  lottery_offers_enabled: boolean;
  active: boolean;
  locked: boolean;
  /**
   * An array relationship
   */
  sections: BasicFormTemplateFragment_sections[];
  /**
   * An array relationship
   */
  form_verifications: BasicFormTemplateFragment_form_verifications[];
  /**
   * An object relationship
   */
  enrollment_period: BasicFormTemplateFragment_enrollment_period;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormStatusDescription
// ====================================================

export interface FormStatusDescription {
  __typename: "form_status_description";
  id: uuid;
  form_template_id: uuid | null;
  status: form_status_enum;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplicantAttendingSchoolsFragment
// ====================================================

export interface ApplicantAttendingSchoolsFragment_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface ApplicantAttendingSchoolsFragment {
  __typename: "person";
  /**
   * An array relationship
   */
  applicant_attending_schools: ApplicantAttendingSchoolsFragment_applicant_attending_schools[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplicantFormSchoolRanksFragment
// ====================================================

export interface ApplicantFormSchoolRanksFragment_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface ApplicantFormSchoolRanksFragment_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: ApplicantFormSchoolRanksFragment_forms_form_school_ranks[];
}

export interface ApplicantFormSchoolRanksFragment {
  __typename: "person";
  /**
   * An array relationship
   */
  forms: ApplicantFormSchoolRanksFragment_forms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonFragment
// ====================================================

export interface PersonFragment_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface PersonFragment_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface PersonFragment {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: PersonFragment_user | null;
  /**
   * An array relationship
   */
  person_bins: PersonFragment_person_bins[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonTotals
// ====================================================

export interface PersonTotals_totals {
  __typename: "person_aggregate_fields";
  total: number;
}

export interface PersonTotals {
  __typename: "person_aggregate";
  totals: PersonTotals_totals | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonBasicFragment
// ====================================================

export interface PersonBasicFragment {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  phone_number: string | null;
  reference_id: string | null;
  email_address: string | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RelationshipPersonFragment
// ====================================================

export interface RelationshipPersonFragment_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface RelationshipPersonFragment_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface RelationshipPersonFragment_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: RelationshipPersonFragment_latest_forms_form_template_enrollment_period;
}

export interface RelationshipPersonFragment_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface RelationshipPersonFragment_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: RelationshipPersonFragment_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: RelationshipPersonFragment_latest_forms_form_school_ranks[];
}

export interface RelationshipPersonFragment {
  __typename: "person";
  id: uuid;
  reference_id: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: RelationshipPersonFragment_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: RelationshipPersonFragment_latest_forms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SiblingFragment
// ====================================================

export interface SiblingFragment_user {
  __typename: "user";
  id: string;
  name: string | null;
}

export interface SiblingFragment_person_bins {
  __typename: "person_bin";
  lottery_number: number;
}

export interface SiblingFragment_applicant_attending_schools {
  __typename: "applicant_attending_school";
  person_id: uuid;
  enrollment_period_id: uuid;
  attendance_status: attendance_status_type_enum | null;
  school_id: uuid | null;
  description: string | null;
}

export interface SiblingFragment_latest_forms_form_template_enrollment_period {
  __typename: "enrollment_period";
  id: uuid;
  name: string;
}

export interface SiblingFragment_latest_forms_form_template {
  __typename: "form_template";
  id: uuid;
  name: string;
  /**
   * An object relationship
   */
  enrollment_period: SiblingFragment_latest_forms_form_template_enrollment_period;
}

export interface SiblingFragment_latest_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface SiblingFragment_latest_forms {
  __typename: "form";
  id: uuid;
  status: form_status_enum;
  status_updated_at: timestamptz | null;
  /**
   * An object relationship
   */
  form_template: SiblingFragment_latest_forms_form_template;
  /**
   * An array relationship
   */
  form_school_ranks: SiblingFragment_latest_forms_form_school_ranks[];
}

export interface SiblingFragment_forms_form_school_ranks {
  __typename: "form_school_rank";
  school_id: uuid;
}

export interface SiblingFragment_forms {
  __typename: "form";
  /**
   * An array relationship
   */
  form_school_ranks: SiblingFragment_forms_form_school_ranks[];
}

export interface SiblingFragment {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  birth_date: date | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  reference_id: string | null;
  phone_number: string | null;
  sms_okay: boolean | null;
  email_address: string | null;
  email_okay: boolean | null;
  avatar: string | null;
  updated_at: timestamptz;
  preferred_language: string | null;
  /**
   * An object relationship
   */
  user: SiblingFragment_user | null;
  /**
   * An array relationship
   */
  person_bins: SiblingFragment_person_bins[];
  person_type: person_type_enum | null;
  /**
   * An array relationship
   */
  applicant_attending_schools: SiblingFragment_applicant_attending_schools[];
  /**
   * An array relationship
   */
  latest_forms: SiblingFragment_latest_forms[];
  /**
   * An array relationship
   */
  forms: SiblingFragment_forms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SimpleSchoolFragment
// ====================================================

export interface SimpleSchoolFragment {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolGradesFragment
// ====================================================

export interface SchoolGradesFragment_grades_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface SchoolGradesFragment_grades_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface SchoolGradesFragment_grades {
  __typename: "grade";
  id: uuid;
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period: SchoolGradesFragment_grades_enrollment_period;
  /**
   * An object relationship
   */
  grade_config: SchoolGradesFragment_grades_grade_config;
}

export interface SchoolGradesFragment {
  __typename: "school";
  /**
   * An array relationship
   */
  grades: SchoolGradesFragment_grades[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolWithGradesFragment
// ====================================================

export interface SchoolWithGradesFragment_grades_enrollment_period {
  __typename: "enrollment_period";
  name: string;
}

export interface SchoolWithGradesFragment_grades_grade_config {
  __typename: "grade_config";
  label: string;
}

export interface SchoolWithGradesFragment_grades {
  __typename: "grade";
  id: uuid;
  grade_config_id: uuid;
  /**
   * An object relationship
   */
  enrollment_period: SchoolWithGradesFragment_grades_enrollment_period;
  /**
   * An object relationship
   */
  grade_config: SchoolWithGradesFragment_grades_grade_config;
}

export interface SchoolWithGradesFragment {
  __typename: "school";
  id: uuid;
  name: string;
  street_address: string | null;
  /**
   * An array relationship
   */
  grades: SchoolWithGradesFragment_grades[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment {
  __typename: "user";
  id: string;
  name: string | null;
  created_at: timestamptz;
  updated_at: timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationFragment
// ====================================================

export interface OrganizationFragment_announcements {
  __typename: "announcement";
  id: uuid;
  title: string;
  description: string;
  active: boolean;
  condition: announcement_condition_enum;
  display_type: announcement_display_type_enum;
  link_text: string | null;
  link_url: string | null;
}

export interface OrganizationFragment {
  __typename: "organization";
  id: uuid;
  name: string;
  path: string;
  timezone_name: string;
  /**
   * An array relationship
   */
  announcements: OrganizationFragment_announcements[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CapacityFragment
// ====================================================

export interface CapacityFragment_school {
  __typename: "school";
  id: uuid;
  name: string;
}

export interface CapacityFragment_grade_config {
  __typename: "grade_config";
  label: string;
  id: uuid;
  order: number;
}

export interface CapacityFragment_program {
  __typename: "program";
  label: citext;
  id: uuid;
  order: number;
}

export interface CapacityFragment_offered_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface CapacityFragment_offered_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: CapacityFragment_offered_offers_aggregate_aggregate | null;
}

export interface CapacityFragment_accepted_offers_aggregate_aggregate {
  __typename: "offer_aggregate_fields";
  count: number;
}

export interface CapacityFragment_accepted_offers_aggregate {
  __typename: "offer_aggregate";
  aggregate: CapacityFragment_accepted_offers_aggregate_aggregate | null;
}

export interface CapacityFragment_waitlists_aggregate_aggregate {
  __typename: "waitlist_aggregate_fields";
  count: number;
}

export interface CapacityFragment_waitlists_aggregate {
  __typename: "waitlist_aggregate";
  aggregate: CapacityFragment_waitlists_aggregate_aggregate | null;
}

export interface CapacityFragment {
  __typename: "grade";
  id: uuid;
  enrollment_period_id: uuid;
  capacity: number | null;
  seats_taken: number | null;
  seats_available: number | null;
  /**
   * An object relationship
   */
  school: CapacityFragment_school;
  /**
   * An object relationship
   */
  grade_config: CapacityFragment_grade_config;
  /**
   * An object relationship
   */
  program: CapacityFragment_program | null;
  /**
   * An aggregate relationship
   */
  offered_offers_aggregate: CapacityFragment_offered_offers_aggregate;
  /**
   * An aggregate relationship
   */
  accepted_offers_aggregate: CapacityFragment_accepted_offers_aggregate;
  /**
   * An aggregate relationship
   */
  waitlists_aggregate: CapacityFragment_waitlists_aggregate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormStatusDescriptionFragment
// ====================================================

export interface FormStatusDescriptionFragment {
  __typename: "form_status_description";
  id: uuid;
  form_template_id: uuid | null;
  status: form_status_enum;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuardianRelationshipFragment
// ====================================================

export interface GuardianRelationshipFragment_first_relationship_second {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface GuardianRelationshipFragment_first_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  second: GuardianRelationshipFragment_first_relationship_second;
}

export interface GuardianRelationshipFragment_second_relationship_first {
  __typename: "person";
  id: uuid;
  person_type: person_type_enum | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
}

export interface GuardianRelationshipFragment_second_relationship {
  __typename: "relationship_person";
  /**
   * An object relationship
   */
  first: GuardianRelationshipFragment_second_relationship_first;
}

export interface GuardianRelationshipFragment {
  __typename: "person";
  /**
   * An array relationship
   */
  first_relationship: GuardianRelationshipFragment_first_relationship[];
  /**
   * An array relationship
   */
  second_relationship: GuardianRelationshipFragment_second_relationship[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolTotals
// ====================================================

export interface SchoolTotals_totals {
  __typename: "school_aggregate_fields";
  total: number;
}

export interface SchoolTotals {
  __typename: "school_aggregate";
  totals: SchoolTotals_totals | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchoolFragment
// ====================================================

export interface SchoolFragment {
  __typename: "school";
  id: uuid;
  name: string;
  reference_id: string | null;
  status: string;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProfileFragment
// ====================================================

export interface ProfileFragment {
  __typename: "person";
  id: uuid;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  street_address: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  active: boolean;
  user_id: string | null;
  phone_number: string | null;
  email_address: string | null;
  sms_okay: boolean | null;
  email_okay: boolean | null;
  avatar: string | null;
  preferred_language: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "action_item"
 */
export enum action_item_constraint {
  action_item_pkey = "action_item_pkey",
}

/**
 * select columns of table "action_item"
 */
export enum action_item_select_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
}

export enum action_item_status_enum {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
}

/**
 * update columns of table "action_item"
 */
export enum action_item_update_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "additional_question"
 */
export enum additional_question_constraint {
  additional_question_pkey = "additional_question_pkey",
}

/**
 * select columns of table "additional_question"
 */
export enum additional_question_select_column {
  additional_question_id = "additional_question_id",
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * update columns of table "additional_question"
 */
export enum additional_question_update_column {
  additional_question_id = "additional_question_id",
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  updated_at = "updated_at",
}

export enum announcement_condition_enum {
  NO_STUDENT = "NO_STUDENT",
}

/**
 * unique or primary key constraints on table "announcement"
 */
export enum announcement_constraint {
  announcement_orgid_entry_point_cond_deleted_at_key = "announcement_orgid_entry_point_cond_deleted_at_key",
  announcement_orgid_entry_point_cond_key = "announcement_orgid_entry_point_cond_key",
  announcement_pkey = "announcement_pkey",
}

export enum announcement_display_type_enum {
  BANNER = "BANNER",
  DIALOG = "DIALOG",
}

export enum announcement_entry_point_enum {
  ADMIN_PORTAL = "ADMIN_PORTAL",
  PARENT_PORTAL = "PARENT_PORTAL",
}

/**
 * select columns of table "announcement"
 */
export enum announcement_select_column {
  active = "active",
  condition = "condition",
  created_at = "created_at",
  deleted_at = "deleted_at",
  description = "description",
  display_type = "display_type",
  entry_point = "entry_point",
  id = "id",
  link_text = "link_text",
  link_url = "link_url",
  organization_id = "organization_id",
  title = "title",
  type = "type",
  updated_at = "updated_at",
}

/**
 * select "announcement_aggregate_bool_exp_bool_and_arguments_columns" columns of table "announcement"
 */
export enum announcement_select_column_announcement_aggregate_bool_exp_bool_and_arguments_columns {
  active = "active",
}

/**
 * select "announcement_aggregate_bool_exp_bool_or_arguments_columns" columns of table "announcement"
 */
export enum announcement_select_column_announcement_aggregate_bool_exp_bool_or_arguments_columns {
  active = "active",
}

export enum announcement_type_enum {
  INFO = "INFO",
}

/**
 * update columns of table "announcement"
 */
export enum announcement_update_column {
  active = "active",
  condition = "condition",
  created_at = "created_at",
  deleted_at = "deleted_at",
  description = "description",
  display_type = "display_type",
  entry_point = "entry_point",
  id = "id",
  link_text = "link_text",
  link_url = "link_url",
  organization_id = "organization_id",
  title = "title",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "applicant_attending_school"
 */
export enum applicant_attending_school_constraint {
  applicant_attending_school_person_id_enrollment_period_id_key = "applicant_attending_school_person_id_enrollment_period_id_key",
  applicant_attending_school_pkey = "applicant_attending_school_pkey",
}

/**
 * select columns of table "applicant_attending_school"
 */
export enum applicant_attending_school_select_column {
  attendance_status = "attendance_status",
  created_at = "created_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  person_id = "person_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "applicant_attending_school"
 */
export enum applicant_attending_school_update_column {
  attendance_status = "attendance_status",
  created_at = "created_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  person_id = "person_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "applicant_guardian"
 */
export enum applicant_guardian_select_column {
  applicant_id = "applicant_id",
  guardian_id = "guardian_id",
}

/**
 * select columns of table "applicant_school_guardian_user"
 */
export enum applicant_school_guardian_user_select_column {
  person_id = "person_id",
  school_id = "school_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "assigned_form"
 */
export enum assigned_form_constraint {
  assigned_form_form_template_id_person_id_deleted_at_key = "assigned_form_form_template_id_person_id_deleted_at_key",
  assigned_form_form_template_id_person_id_key = "assigned_form_form_template_id_person_id_key",
  assigned_form_pkey = "assigned_form_pkey",
}

/**
 * select columns of table "assigned_form"
 */
export enum assigned_form_select_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_template_id = "form_template_id",
  id = "id",
  person_id = "person_id",
  previous_form_id = "previous_form_id",
  previous_offer_id = "previous_offer_id",
  previous_waitlist_id = "previous_waitlist_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "assigned_form"
 */
export enum assigned_form_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_template_id = "form_template_id",
  id = "id",
  person_id = "person_id",
  previous_form_id = "previous_form_id",
  previous_offer_id = "previous_offer_id",
  previous_waitlist_id = "previous_waitlist_id",
  updated_at = "updated_at",
}

export enum attendance_status_type_enum {
  CURRENT = "CURRENT",
  NEW = "NEW",
}

/**
 * unique or primary key constraints on table "bin_config"
 */
export enum bin_config_constraint {
  bin_config_pkey = "bin_config_pkey",
}

/**
 * select columns of table "bin_config"
 */
export enum bin_config_select_column {
  id = "id",
  match_config_id = "match_config_id",
  name = "name",
  parameters = "parameters",
  priority_groups = "priority_groups",
  sort_fields = "sort_fields",
}

/**
 * update columns of table "bin_config"
 */
export enum bin_config_update_column {
  id = "id",
  match_config_id = "match_config_id",
  name = "name",
  parameters = "parameters",
  priority_groups = "priority_groups",
  sort_fields = "sort_fields",
}

/**
 * unique or primary key constraints on table "bin"
 */
export enum bin_constraint {
  bin_pkey = "bin_pkey",
}

/**
 * select columns of table "bin"
 */
export enum bin_select_column {
  bin_config_id = "bin_config_id",
  capacity = "capacity",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  parent_bin_id = "parent_bin_id",
  seats_available = "seats_available",
  seats_taken = "seats_taken",
}

/**
 * update columns of table "bin"
 */
export enum bin_update_column {
  bin_config_id = "bin_config_id",
  capacity = "capacity",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  parent_bin_id = "parent_bin_id",
  seats_available = "seats_available",
  seats_taken = "seats_taken",
}

/**
 * unique or primary key constraints on table "boundary_tag"
 */
export enum boundary_tag_constraint {
  boundary_tag_pkey = "boundary_tag_pkey",
}

/**
 * select columns of table "boundary_tag"
 */
export enum boundary_tag_select_column {
  created_at = "created_at",
  geojson_id = "geojson_id",
  id = "id",
  is_inside = "is_inside",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * select "boundary_tag_aggregate_bool_exp_bool_and_arguments_columns" columns of table "boundary_tag"
 */
export enum boundary_tag_select_column_boundary_tag_aggregate_bool_exp_bool_and_arguments_columns {
  is_inside = "is_inside",
}

/**
 * select "boundary_tag_aggregate_bool_exp_bool_or_arguments_columns" columns of table "boundary_tag"
 */
export enum boundary_tag_select_column_boundary_tag_aggregate_bool_exp_bool_or_arguments_columns {
  is_inside = "is_inside",
}

/**
 * update columns of table "boundary_tag"
 */
export enum boundary_tag_update_column {
  created_at = "created_at",
  geojson_id = "geojson_id",
  id = "id",
  is_inside = "is_inside",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "custom_question_answer_bank_relationship"
 */
export enum custom_question_answer_bank_relationship_constraint {
  custom_question_answer_bank_rela_form_id_custom_question_id_key = "custom_question_answer_bank_rela_form_id_custom_question_id_key",
  custom_question_answer_bank_relationship_pkey = "custom_question_answer_bank_relationship_pkey",
}

/**
 * select columns of table "custom_question_answer_bank_relationship"
 */
export enum custom_question_answer_bank_relationship_select_column {
  custom_question_id = "custom_question_id",
  form_id = "form_id",
  id = "id",
  person_answer_bank_id = "person_answer_bank_id",
}

/**
 * update columns of table "custom_question_answer_bank_relationship"
 */
export enum custom_question_answer_bank_relationship_update_column {
  custom_question_id = "custom_question_id",
  form_id = "form_id",
  id = "id",
  person_answer_bank_id = "person_answer_bank_id",
}

/**
 * unique or primary key constraints on table "custom_question_answer"
 */
export enum custom_question_answer_constraint {
  custom_question_answer_form_id_question_id_form_answer_id_key = "custom_question_answer_form_id_question_id_form_answer_id_key",
  custom_question_answer_pkey = "custom_question_answer_pkey",
}

/**
 * select columns of table "custom_question_answer"
 */
export enum custom_question_answer_select_column {
  created_at = "created_at",
  form_answer_id = "form_answer_id",
  form_id = "form_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "custom_question_answer"
 */
export enum custom_question_answer_update_column {
  created_at = "created_at",
  form_answer_id = "form_answer_id",
  form_id = "form_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "custom_question"
 */
export enum custom_question_constraint {
  custom_question_pkey = "custom_question_pkey",
}

/**
 * unique or primary key constraints on table "custom_question_relationship"
 */
export enum custom_question_relationship_constraint {
  custom_question_relationship_custom_question_id_cloned_ques_key = "custom_question_relationship_custom_question_id_cloned_ques_key",
  custom_question_relationship_pkey = "custom_question_relationship_pkey",
}

/**
 * select columns of table "custom_question_relationship"
 */
export enum custom_question_relationship_select_column {
  cloned_question_id = "cloned_question_id",
  created_at = "created_at",
  custom_question_id = "custom_question_id",
  custom_question_type_field_id = "custom_question_type_field_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * update columns of table "custom_question_relationship"
 */
export enum custom_question_relationship_update_column {
  cloned_question_id = "cloned_question_id",
  created_at = "created_at",
  custom_question_id = "custom_question_id",
  custom_question_type_field_id = "custom_question_type_field_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * select columns of table "custom_question"
 */
export enum custom_question_select_column {
  created_at = "created_at",
  custom_question_type_id = "custom_question_type_id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "custom_question_type"
 */
export enum custom_question_type_constraint {
  custom_question_type_organization_id_key_key = "custom_question_type_organization_id_key_key",
  custom_question_type_pkey = "custom_question_type_pkey",
}

/**
 * unique or primary key constraints on table "custom_question_type_field"
 */
export enum custom_question_type_field_constraint {
  custom_question_type_field_pkey = "custom_question_type_field_pkey",
}

/**
 * select columns of table "custom_question_type_field"
 */
export enum custom_question_type_field_select_column {
  created_at = "created_at",
  custom_question_type_id = "custom_question_type_id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "custom_question_type_field"
 */
export enum custom_question_type_field_update_column {
  created_at = "created_at",
  custom_question_type_id = "custom_question_type_id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "custom_question_type"
 */
export enum custom_question_type_update_column {
  created_at = "created_at",
  id = "id",
  key = "key",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "custom_question"
 */
export enum custom_question_update_column {
  created_at = "created_at",
  custom_question_type_id = "custom_question_type_id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "disclaimer_section"
 */
export enum disclaimer_section_constraint {
  disclaimer_section_form_template_section_id_key = "disclaimer_section_form_template_section_id_key",
  disclaimer_section_pkey = "disclaimer_section_pkey",
}

/**
 * update columns of table "disclaimer_section"
 */
export enum disclaimer_section_update_column {
  created_at = "created_at",
  disclaimer = "disclaimer",
  form_template_section_id = "form_template_section_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "document_metadata"
 */
export enum document_metadata_constraint {
  document_metadata_pkey = "document_metadata_pkey",
}

/**
 * select columns of table "document_metadata"
 */
export enum document_metadata_select_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  document_id = "document_id",
  document_type = "document_type",
  filename = "filename",
  form_answer_id = "form_answer_id",
  owner_user_id = "owner_user_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "document_metadata"
 */
export enum document_metadata_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  document_id = "document_id",
  document_type = "document_type",
  filename = "filename",
  form_answer_id = "form_answer_id",
  owner_user_id = "owner_user_id",
  updated_at = "updated_at",
}

export enum document_type_enum {
  General = "General",
  MessagePayload = "MessagePayload",
}

/**
 * unique or primary key constraints on table "eligibility_question_school"
 */
export enum eligibility_question_school_constraint {
  eligibility_question_school_pkey = "eligibility_question_school_pkey",
}

/**
 * select columns of table "eligibility_question_school"
 */
export enum eligibility_question_school_select_column {
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  is_eligible = "is_eligible",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * select "eligibility_question_school_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eligibility_question_school"
 */
export enum eligibility_question_school_select_column_eligibility_question_school_aggregate_bool_exp_bool_and_arguments_columns {
  is_eligible = "is_eligible",
}

/**
 * select "eligibility_question_school_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eligibility_question_school"
 */
export enum eligibility_question_school_select_column_eligibility_question_school_aggregate_bool_exp_bool_or_arguments_columns {
  is_eligible = "is_eligible",
}

/**
 * update columns of table "eligibility_question_school"
 */
export enum eligibility_question_school_update_column {
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  is_eligible = "is_eligible",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "enrollment_period"
 */
export enum enrollment_period_constraint {
  enrollment_seasons_pkey = "enrollment_seasons_pkey",
  organization_id_key_idx = "organization_id_key_idx",
}

/**
 * select columns of table "enrollment_period"
 */
export enum enrollment_period_select_column {
  active = "active",
  created_at = "created_at",
  deleted_at = "deleted_at",
  id = "id",
  key = "key",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * select "enrollment_period_aggregate_bool_exp_bool_and_arguments_columns" columns of table "enrollment_period"
 */
export enum enrollment_period_select_column_enrollment_period_aggregate_bool_exp_bool_and_arguments_columns {
  active = "active",
}

/**
 * select "enrollment_period_aggregate_bool_exp_bool_or_arguments_columns" columns of table "enrollment_period"
 */
export enum enrollment_period_select_column_enrollment_period_aggregate_bool_exp_bool_or_arguments_columns {
  active = "active",
}

/**
 * unique or primary key constraints on table "enrollment_period_tag"
 */
export enum enrollment_period_tag_constraint {
  enrollment_period_tag_enrollment_period_id_name_tag_group_id_ke = "enrollment_period_tag_enrollment_period_id_name_tag_group_id_ke",
  enrollment_period_tag_pkey = "enrollment_period_tag_pkey",
}

/**
 * select columns of table "enrollment_period_tag"
 */
export enum enrollment_period_tag_select_column {
  created_at = "created_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  last_used_at = "last_used_at",
  name = "name",
  tag_group_id = "tag_group_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "enrollment_period_tag"
 */
export enum enrollment_period_tag_update_column {
  created_at = "created_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  last_used_at = "last_used_at",
  name = "name",
  tag_group_id = "tag_group_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "enrollment_period"
 */
export enum enrollment_period_update_column {
  active = "active",
  created_at = "created_at",
  deleted_at = "deleted_at",
  id = "id",
  key = "key",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_address"
 */
export enum form_address_constraint {
  form_address_form_id_question_id_key = "form_address_form_id_question_id_key",
  form_address_pkey = "form_address_pkey",
}

/**
 * select columns of table "form_address"
 */
export enum form_address_select_column {
  city = "city",
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  question_id = "question_id",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  zip_code = "zip_code",
}

/**
 * update columns of table "form_address"
 */
export enum form_address_update_column {
  city = "city",
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  question_id = "question_id",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  zip_code = "zip_code",
}

/**
 * unique or primary key constraints on table "form_answer"
 */
export enum form_answer_constraint {
  form_answer_form_id_form_question_id_key = "form_answer_form_id_form_question_id_key",
  form_answer_pkey = "form_answer_pkey",
}

/**
 * unique or primary key constraints on table "form_answer_option"
 */
export enum form_answer_option_constraint {
  form_answer_option_form_answer_id_form_key = "form_answer_option_form_answer_id_form_key",
  form_answer_option_pkey = "form_answer_option_pkey",
}

/**
 * select columns of table "form_answer_option"
 */
export enum form_answer_option_select_column {
  created_at = "created_at",
  form_answer_id = "form_answer_id",
  form_question_option_id = "form_question_option_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_answer_option"
 */
export enum form_answer_option_update_column {
  created_at = "created_at",
  form_answer_id = "form_answer_id",
  form_question_option_id = "form_question_option_id",
  id = "id",
  updated_at = "updated_at",
}

/**
 * select columns of table "form_answer"
 */
export enum form_answer_select_column {
  created_at = "created_at",
  date_answer = "date_answer",
  form_id = "form_id",
  free_text_answer = "free_text_answer",
  id = "id",
  number_answer = "number_answer",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_answer"
 */
export enum form_answer_update_column {
  created_at = "created_at",
  date_answer = "date_answer",
  form_id = "form_id",
  free_text_answer = "free_text_answer",
  id = "id",
  number_answer = "number_answer",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "form_answer_verification_result"
 */
export enum form_answer_verification_result_select_column {
  form_answer_id = "form_answer_id",
  form_verification_result_id = "form_verification_result_id",
  label = "label",
  verification_status = "verification_status",
}

/**
 * select columns of table "form_attending_school"
 */
export enum form_attending_school_select_column {
  attendance_status = "attendance_status",
  deleted_at = "deleted_at",
  enrollment_period_id = "enrollment_period_id",
  form_id = "form_id",
  form_template_id = "form_template_id",
  organization_id = "organization_id",
  person_id = "person_id",
  school_id = "school_id",
}

/**
 * unique or primary key constraints on table "form"
 */
export enum form_constraint {
  form_form_template_id_person_id_deleted_at_key = "form_form_template_id_person_id_deleted_at_key",
  form_form_template_id_person_id_key = "form_form_template_id_person_id_key",
  form_pkey = "form_pkey",
}

/**
 * unique or primary key constraints on table "form_disclaimer"
 */
export enum form_disclaimer_constraint {
  form_disclaimer_form_id_disclaimer_section_id_key = "form_disclaimer_form_id_disclaimer_section_id_key",
  form_disclaimer_pkey = "form_disclaimer_pkey",
}

/**
 * select columns of table "form_disclaimer"
 */
export enum form_disclaimer_select_column {
  disclaimer_section_id = "disclaimer_section_id",
  form_id = "form_id",
  id = "id",
  signature = "signature",
  signed_at = "signed_at",
}

/**
 * update columns of table "form_disclaimer"
 */
export enum form_disclaimer_update_column {
  disclaimer_section_id = "disclaimer_section_id",
  form_id = "form_id",
  id = "id",
  signature = "signature",
  signed_at = "signed_at",
}

/**
 * unique or primary key constraints on table "form_import"
 */
export enum form_import_constraint {
  form_import_pkey = "form_import_pkey",
}

/**
 * unique or primary key constraints on table "form_import_review_status"
 */
export enum form_import_review_status_constraint {
  form_import_review_status_pkey = "form_import_review_status_pkey",
}

export enum form_import_review_status_enum {
  Reviewed = "Reviewed",
  Reviewing = "Reviewing",
}

/**
 * update columns of table "form_import_review_status"
 */
export enum form_import_review_status_update_column {
  value = "value",
}

/**
 * unique or primary key constraints on table "form_import_row"
 */
export enum form_import_row_constraint {
  form_import_row_form_import_id_row_number_key = "form_import_row_form_import_id_row_number_key",
  form_import_row_pkey = "form_import_row_pkey",
}

/**
 * select columns of table "form_import_row"
 */
export enum form_import_row_select_column {
  created_at = "created_at",
  errors = "errors",
  form_id = "form_id",
  form_import_id = "form_import_id",
  id = "id",
  row_number = "row_number",
  status = "status",
  updated_at = "updated_at",
  warnings = "warnings",
}

/**
 * unique or primary key constraints on table "form_import_row_status"
 */
export enum form_import_row_status_constraint {
  form_import_row_status_pkey = "form_import_row_status_pkey",
}

export enum form_import_row_status_enum {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
}

/**
 * update columns of table "form_import_row_status"
 */
export enum form_import_row_status_update_column {
  value = "value",
}

/**
 * update columns of table "form_import_row"
 */
export enum form_import_row_update_column {
  created_at = "created_at",
  errors = "errors",
  form_id = "form_id",
  form_import_id = "form_import_id",
  id = "id",
  row_number = "row_number",
  status = "status",
  updated_at = "updated_at",
  warnings = "warnings",
}

/**
 * unique or primary key constraints on table "form_import_status"
 */
export enum form_import_status_constraint {
  form_import_status_pkey = "form_import_status_pkey",
}

export enum form_import_status_enum {
  Invalid = "Invalid",
  New = "New",
  Valid = "Valid",
}

/**
 * update columns of table "form_import_status"
 */
export enum form_import_status_update_column {
  value = "value",
}

/**
 * update columns of table "form_import"
 */
export enum form_import_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  errors = "errors",
  file_size_in_bytes = "file_size_in_bytes",
  filename = "filename",
  form_template_id = "form_template_id",
  id = "id",
  owner = "owner",
  review_status = "review_status",
  status = "status",
  updated_at = "updated_at",
  warnings = "warnings",
}

/**
 * unique or primary key constraints on table "form_question_category"
 */
export enum form_question_category_constraint {
  form_question_category_pkey = "form_question_category_pkey",
}

export enum form_question_category_enum {
  EligibilityQuestion = "EligibilityQuestion",
  GeneralQuestion = "GeneralQuestion",
}

/**
 * update columns of table "form_question_category"
 */
export enum form_question_category_update_column {
  value = "value",
}

/**
 * unique or primary key constraints on table "form_question"
 */
export enum form_question_constraint {
  form_question_pkey1 = "form_question_pkey1",
}

/**
 * unique or primary key constraints on table "form_question_option"
 */
export enum form_question_option_constraint {
  form_question_option_form_question_id_order_key = "form_question_option_form_question_id_order_key",
  form_question_option_pkey = "form_question_option_pkey",
}

/**
 * select columns of table "form_question_option"
 */
export enum form_question_option_select_column {
  created_at = "created_at",
  id = "id",
  label = "label",
  order = "order",
  question_id = "question_id",
  requires_verification = "requires_verification",
  skip_verification = "skip_verification",
  translate_options = "translate_options",
  updated_at = "updated_at",
  value = "value",
}

/**
 * select "form_question_option_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_question_option"
 */
export enum form_question_option_select_column_form_question_option_aggregate_bool_exp_bool_and_arguments_columns {
  requires_verification = "requires_verification",
  skip_verification = "skip_verification",
  translate_options = "translate_options",
}

/**
 * select "form_question_option_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_question_option"
 */
export enum form_question_option_select_column_form_question_option_aggregate_bool_exp_bool_or_arguments_columns {
  requires_verification = "requires_verification",
  skip_verification = "skip_verification",
  translate_options = "translate_options",
}

/**
 * update columns of table "form_question_option"
 */
export enum form_question_option_update_column {
  created_at = "created_at",
  id = "id",
  label = "label",
  order = "order",
  question_id = "question_id",
  requires_verification = "requires_verification",
  skip_verification = "skip_verification",
  translate_options = "translate_options",
  updated_at = "updated_at",
  value = "value",
}

/**
 * unique or primary key constraints on table "form_question_school"
 */
export enum form_question_school_constraint {
  form_question_school_pkey = "form_question_school_pkey",
}

/**
 * select columns of table "form_question_school"
 */
export enum form_question_school_select_column {
  created_at = "created_at",
  id = "id",
  question_id = "question_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_question_school"
 */
export enum form_question_school_update_column {
  created_at = "created_at",
  id = "id",
  question_id = "question_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "form_question"
 */
export enum form_question_select_column {
  category = "category",
  created_at = "created_at",
  filters = "filters",
  form_verification_id = "form_verification_id",
  question_id = "question_id",
  requires_verification = "requires_verification",
  updated_at = "updated_at",
}

/**
 * select "form_question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_question"
 */
export enum form_question_select_column_form_question_aggregate_bool_exp_bool_and_arguments_columns {
  requires_verification = "requires_verification",
}

/**
 * select "form_question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_question"
 */
export enum form_question_select_column_form_question_aggregate_bool_exp_bool_or_arguments_columns {
  requires_verification = "requires_verification",
}

/**
 * update columns of table "form_question"
 */
export enum form_question_update_column {
  category = "category",
  created_at = "created_at",
  filters = "filters",
  form_verification_id = "form_verification_id",
  question_id = "question_id",
  requires_verification = "requires_verification",
  updated_at = "updated_at",
}

/**
 * select columns of table "form_school_grade"
 */
export enum form_school_grade_select_column {
  bin_id = "bin_id",
  form_id = "form_id",
  form_school_rank_id = "form_school_rank_id",
  grade_id = "grade_id",
  person_id = "person_id",
  school_id = "school_id",
}

/**
 * unique or primary key constraints on table "form_school_offer_status_history"
 */
export enum form_school_offer_status_history_constraint {
  form_school_offer_status_history_form_id_school_id_key = "form_school_offer_status_history_form_id_school_id_key",
  form_school_offer_status_history_pkey = "form_school_offer_status_history_pkey",
}

/**
 * update columns of table "form_school_offer_status_history"
 */
export enum form_school_offer_status_history_update_column {
  form_id = "form_id",
  school_id = "school_id",
  submitted_at = "submitted_at",
}

/**
 * unique or primary key constraints on table "form_school_rank"
 */
export enum form_school_rank_constraint {
  app_school_rank_form_id_school_id_lottery_order_key = "app_school_rank_form_id_school_id_lottery_order_key",
  form_school_rank_form_id_schools_ranking_sect_key = "form_school_rank_form_id_schools_ranking_sect_key",
  form_school_rank_pkey = "form_school_rank_pkey",
}

/**
 * select columns of table "form_school_rank"
 */
export enum form_school_rank_select_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  lottery_order = "lottery_order",
  rank = "rank",
  school_id = "school_id",
  schools_ranking_section_id = "schools_ranking_section_id",
  status = "status",
  updated_at = "updated_at",
}

export enum form_school_rank_status_enum {
  NotConsidered = "NotConsidered",
}

/**
 * update columns of table "form_school_rank"
 */
export enum form_school_rank_update_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  lottery_order = "lottery_order",
  rank = "rank",
  school_id = "school_id",
  schools_ranking_section_id = "schools_ranking_section_id",
  status = "status",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_school_tag"
 */
export enum form_school_tag_constraint {
  form_school_tag_form_id_school_id_tag_id_key = "form_school_tag_form_id_school_id_tag_id_key",
  form_school_tag_pkey = "form_school_tag_pkey",
}

/**
 * select columns of table "form_school_tag"
 */
export enum form_school_tag_select_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  school_id = "school_id",
  tag_id = "tag_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_school_tag"
 */
export enum form_school_tag_update_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  school_id = "school_id",
  tag_id = "tag_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "form"
 */
export enum form_select_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_template_id = "form_template_id",
  id = "id",
  is_hidden_from_parent = "is_hidden_from_parent",
  person_id = "person_id",
  previous_form_id = "previous_form_id",
  previous_offer_id = "previous_offer_id",
  previous_waitlist_id = "previous_waitlist_id",
  source = "source",
  status = "status",
  status_updated_at = "status_updated_at",
  submitted_at = "submitted_at",
  submitted_before = "submitted_before",
  updated_at = "updated_at",
}

/**
 * select "form_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form"
 */
export enum form_select_column_form_aggregate_bool_exp_bool_and_arguments_columns {
  is_hidden_from_parent = "is_hidden_from_parent",
  submitted_before = "submitted_before",
}

/**
 * select "form_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form"
 */
export enum form_select_column_form_aggregate_bool_exp_bool_or_arguments_columns {
  is_hidden_from_parent = "is_hidden_from_parent",
  submitted_before = "submitted_before",
}

/**
 * unique or primary key constraints on table "form_source"
 */
export enum form_source_constraint {
  form_source_pkey = "form_source_pkey",
}

export enum form_source_enum {
  CustomerAPI = "CustomerAPI",
  Import = "Import",
}

/**
 * update columns of table "form_source"
 */
export enum form_source_update_column {
  value = "value",
}

/**
 * unique or primary key constraints on table "form_status"
 */
export enum form_status_constraint {
  form_status_pkey = "form_status_pkey",
}

/**
 * unique or primary key constraints on table "form_status_description"
 */
export enum form_status_description_constraint {
  form_status_description_pkey = "form_status_description_pkey",
  form_status_description_status_form_template_id_key = "form_status_description_status_form_template_id_key",
}

/**
 * select columns of table "form_status_description"
 */
export enum form_status_description_select_column {
  description = "description",
  form_template_id = "form_template_id",
  id = "id",
  status = "status",
}

/**
 * update columns of table "form_status_description"
 */
export enum form_status_description_update_column {
  description = "description",
  form_template_id = "form_template_id",
  id = "id",
  status = "status",
}

export enum form_status_enum {
  Admissions = "Admissions",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
  InProgress = "InProgress",
  LotteryReady = "LotteryReady",
  Submitted = "Submitted",
  Verified = "Verified",
  Withdrawn = "Withdrawn",
}

/**
 * update columns of table "form_status"
 */
export enum form_status_update_column {
  value = "value",
}

/**
 * unique or primary key constraints on table "form_tag"
 */
export enum form_tag_constraint {
  form_tag_pkey = "form_tag_pkey",
  form_tag_tag_id_form_id_key = "form_tag_tag_id_form_id_key",
}

/**
 * select columns of table "form_tag"
 */
export enum form_tag_select_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  tag_id = "tag_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_tag"
 */
export enum form_tag_update_column {
  created_at = "created_at",
  form_id = "form_id",
  id = "id",
  tag_id = "tag_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_template"
 */
export enum form_template_constraint {
  form_template_enrollment_period_deleted_key_index = "form_template_enrollment_period_deleted_key_index",
  form_template_enrollment_period_key_index = "form_template_enrollment_period_key_index",
  form_template_pkey = "form_template_pkey",
}

/**
 * unique or primary key constraints on table "form_template_hint"
 */
export enum form_template_hint_constraint {
  form_template_hint_pkey = "form_template_hint_pkey",
}

/**
 * select columns of table "form_template_hint"
 */
export enum form_template_hint_select_column {
  body = "body",
  created_at = "created_at",
  form_status = "form_status",
  form_template_id = "form_template_id",
  id = "id",
  image_type = "image_type",
  offer_status = "offer_status",
  status_type = "status_type",
  title = "title",
  updated_at = "updated_at",
  waitlist_status = "waitlist_status",
}

/**
 * update columns of table "form_template_hint"
 */
export enum form_template_hint_update_column {
  body = "body",
  created_at = "created_at",
  form_status = "form_status",
  form_template_id = "form_template_id",
  id = "id",
  image_type = "image_type",
  offer_status = "offer_status",
  status_type = "status_type",
  title = "title",
  updated_at = "updated_at",
  waitlist_status = "waitlist_status",
}

/**
 * unique or primary key constraints on table "form_template_rule"
 */
export enum form_template_rule_constraint {
  form_template_rule_pkey = "form_template_rule_pkey",
}

/**
 * update columns of table "form_template_rule"
 */
export enum form_template_rule_update_column {
  form_template_rule_id = "form_template_rule_id",
  id = "id",
  operator = "operator",
}

/**
 * unique or primary key constraints on table "form_template_section"
 */
export enum form_template_section_constraint {
  form_template_section_order_form_template_id_key = "form_template_section_order_form_template_id_key",
  form_template_section_pkey = "form_template_section_pkey",
  unique_key_form_template_id_idx = "unique_key_form_template_id_idx",
}

/**
 * select columns of table "form_template_section"
 */
export enum form_template_section_select_column {
  created_at = "created_at",
  description = "description",
  family_editable_statuses = "family_editable_statuses",
  filters = "filters",
  form_template_id = "form_template_id",
  id = "id",
  key = "key",
  order = "order",
  permission_level = "permission_level",
  title = "title",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_template_section_type"
 */
export enum form_template_section_type_constraint {
  form_template_section_type_pkey = "form_template_section_type_pkey",
}

export enum form_template_section_type_enum {
  DisclaimerSection = "DisclaimerSection",
  GeneralSection = "GeneralSection",
  PreRankingSection = "PreRankingSection",
  SchoolRankingSection = "SchoolRankingSection",
}

/**
 * update columns of table "form_template_section_type"
 */
export enum form_template_section_type_update_column {
  value = "value",
}

/**
 * update columns of table "form_template_section"
 */
export enum form_template_section_update_column {
  created_at = "created_at",
  description = "description",
  family_editable_statuses = "family_editable_statuses",
  filters = "filters",
  form_template_id = "form_template_id",
  id = "id",
  key = "key",
  order = "order",
  permission_level = "permission_level",
  title = "title",
  type = "type",
  updated_at = "updated_at",
}

/**
 * select columns of table "form_template"
 */
export enum form_template_select_column {
  active = "active",
  closed_at = "closed_at",
  created_at = "created_at",
  deleted_at = "deleted_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  form_template_rule_id = "form_template_rule_id",
  id = "id",
  key = "key",
  locked = "locked",
  lottery_offers_enabled = "lottery_offers_enabled",
  name = "name",
  offer_start_at = "offer_start_at",
  open_at = "open_at",
  reopen_at = "reopen_at",
  updated_at = "updated_at",
}

/**
 * select "form_template_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_template"
 */
export enum form_template_select_column_form_template_aggregate_bool_exp_bool_and_arguments_columns {
  active = "active",
  locked = "locked",
  lottery_offers_enabled = "lottery_offers_enabled",
}

/**
 * select "form_template_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_template"
 */
export enum form_template_select_column_form_template_aggregate_bool_exp_bool_or_arguments_columns {
  active = "active",
  locked = "locked",
  lottery_offers_enabled = "lottery_offers_enabled",
}

/**
 * update columns of table "form_template"
 */
export enum form_template_update_column {
  active = "active",
  closed_at = "closed_at",
  created_at = "created_at",
  deleted_at = "deleted_at",
  description = "description",
  enrollment_period_id = "enrollment_period_id",
  form_template_rule_id = "form_template_rule_id",
  id = "id",
  key = "key",
  locked = "locked",
  lottery_offers_enabled = "lottery_offers_enabled",
  name = "name",
  offer_start_at = "offer_start_at",
  open_at = "open_at",
  reopen_at = "reopen_at",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_tracking_dates"
 */
export enum form_tracking_dates_constraint {
  form_tracking_dates_pkey = "form_tracking_dates_pkey",
}

/**
 * update columns of table "form_tracking_dates"
 */
export enum form_tracking_dates_update_column {
  form_id = "form_id",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

export enum form_transition_enum {
  CANCELLED_TO_ADMIN_CANCELLED = "CANCELLED_TO_ADMIN_CANCELLED",
  CANCELLED_TO_DELETED = "CANCELLED_TO_DELETED",
  CANCELLED_TO_IN_PROGRESS = "CANCELLED_TO_IN_PROGRESS",
  CANCELLED_TO_SUBMITTED = "CANCELLED_TO_SUBMITTED",
  IN_PROGRESS_TO_ADMIN_CANCELLED = "IN_PROGRESS_TO_ADMIN_CANCELLED",
  IN_PROGRESS_TO_CANCELLED = "IN_PROGRESS_TO_CANCELLED",
  IN_PROGRESS_TO_DELETED = "IN_PROGRESS_TO_DELETED",
  IN_PROGRESS_TO_SUBMITTED = "IN_PROGRESS_TO_SUBMITTED",
  NEW_FORM = "NEW_FORM",
  SUBMITTED_TO_ADMIN_CANCELLED = "SUBMITTED_TO_ADMIN_CANCELLED",
  SUBMITTED_TO_CANCELLED = "SUBMITTED_TO_CANCELLED",
  SUBMITTED_TO_DELETED = "SUBMITTED_TO_DELETED",
  SUBMITTED_TO_IN_PROGRESS = "SUBMITTED_TO_IN_PROGRESS",
}

/**
 * update columns of table "form"
 */
export enum form_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_template_id = "form_template_id",
  id = "id",
  is_hidden_from_parent = "is_hidden_from_parent",
  person_id = "person_id",
  previous_form_id = "previous_form_id",
  previous_offer_id = "previous_offer_id",
  previous_waitlist_id = "previous_waitlist_id",
  source = "source",
  status = "status",
  status_updated_at = "status_updated_at",
  submitted_at = "submitted_at",
  submitted_before = "submitted_before",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "form_verification"
 */
export enum form_verification_constraint {
  form_verification_form_template_id_label_key = "form_verification_form_template_id_label_key",
  form_verification_pkey = "form_verification_pkey",
}

/**
 * unique or primary key constraints on table "form_verification_result"
 */
export enum form_verification_result_constraint {
  form_verification_result_pkey = "form_verification_result_pkey",
}

/**
 * select columns of table "form_verification_result"
 */
export enum form_verification_result_select_column {
  created_at = "created_at",
  form_id = "form_id",
  form_verification_id = "form_verification_id",
  id = "id",
  updated_at = "updated_at",
  verification_status = "verification_status",
}

/**
 * select columns of table "form_verification_result_status"
 */
export enum form_verification_result_status_select_column {
  form_id = "form_id",
  form_verification_id = "form_verification_id",
  form_verification_result_id = "form_verification_result_id",
  verification_status = "verification_status",
}

/**
 * update columns of table "form_verification_result"
 */
export enum form_verification_result_update_column {
  created_at = "created_at",
  form_id = "form_id",
  form_verification_id = "form_verification_id",
  id = "id",
  updated_at = "updated_at",
  verification_status = "verification_status",
}

/**
 * select columns of table "form_verification"
 */
export enum form_verification_select_column {
  created_at = "created_at",
  form_template_id = "form_template_id",
  id = "id",
  label = "label",
  updated_at = "updated_at",
}

/**
 * update columns of table "form_verification"
 */
export enum form_verification_update_column {
  created_at = "created_at",
  form_template_id = "form_template_id",
  id = "id",
  label = "label",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "geo_eligibility"
 */
export enum geo_eligibility_constraint {
  geo_eligibility_pkey = "geo_eligibility_pkey",
}

/**
 * select columns of table "geo_eligibility"
 */
export enum geo_eligibility_select_column {
  id = "id",
  is_eligible_inside = "is_eligible_inside",
  school_boundary_id = "school_boundary_id",
}

/**
 * select "geo_eligibility_aggregate_bool_exp_bool_and_arguments_columns" columns of table "geo_eligibility"
 */
export enum geo_eligibility_select_column_geo_eligibility_aggregate_bool_exp_bool_and_arguments_columns {
  is_eligible_inside = "is_eligible_inside",
}

/**
 * select "geo_eligibility_aggregate_bool_exp_bool_or_arguments_columns" columns of table "geo_eligibility"
 */
export enum geo_eligibility_select_column_geo_eligibility_aggregate_bool_exp_bool_or_arguments_columns {
  is_eligible_inside = "is_eligible_inside",
}

/**
 * update columns of table "geo_eligibility"
 */
export enum geo_eligibility_update_column {
  id = "id",
  is_eligible_inside = "is_eligible_inside",
  school_boundary_id = "school_boundary_id",
}

/**
 * unique or primary key constraints on table "geojson"
 */
export enum geojson_constraint {
  geojson_pkey = "geojson_pkey",
}

/**
 * update columns of table "geojson"
 */
export enum geojson_update_column {
  created_at = "created_at",
  geojson = "geojson",
  geojson_property_key = "geojson_property_key",
  id = "id",
  is_active = "is_active",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "glossary"
 */
export enum glossary_constraint {
  glossary_organization_id_enrollment_period_id_type_key = "glossary_organization_id_enrollment_period_id_type_key",
  glossary_pkey = "glossary_pkey",
}

/**
 * select columns of table "glossary"
 */
export enum glossary_select_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  organization_id = "organization_id",
  plural = "plural",
  singular = "singular",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "glossary_type"
 */
export enum glossary_type_constraint {
  glossary_type_pkey = "glossary_type_pkey",
}

export enum glossary_type_enum {
  accepted = "accepted",
  admissions = "admissions",
  canceled = "canceled",
  declined = "declined",
  inProgress = "inProgress",
  notConsidered = "notConsidered",
  offered = "offered",
  readyForLottery = "readyForLottery",
  removed = "removed",
  revoked = "revoked",
  school = "school",
  student = "student",
  submitted = "submitted",
  verified = "verified",
  waitlisted = "waitlisted",
}

/**
 * update columns of table "glossary_type"
 */
export enum glossary_type_update_column {
  value = "value",
}

/**
 * update columns of table "glossary"
 */
export enum glossary_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  organization_id = "organization_id",
  plural = "plural",
  singular = "singular",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "grade_config"
 */
export enum grade_config_constraint {
  grade_config_pkey = "grade_config_pkey",
}

/**
 * select columns of table "grade_config"
 */
export enum grade_config_select_column {
  id = "id",
  label = "label",
  order = "order",
  organization_id = "organization_id",
  value = "value",
}

/**
 * update columns of table "grade_config"
 */
export enum grade_config_update_column {
  id = "id",
  label = "label",
  order = "order",
  organization_id = "organization_id",
  value = "value",
}

/**
 * unique or primary key constraints on table "grade"
 */
export enum grade_constraint {
  grade_enrollment_period_id_grade_config_id_school_id_program_id = "grade_enrollment_period_id_grade_config_id_school_id_program_id",
  grade_pkey = "grade_pkey",
}

/**
 * select columns of table "grade"
 */
export enum grade_select_column {
  bin_id = "bin_id",
  capacity = "capacity",
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  grade_config_id = "grade_config_id",
  grades_group_id = "grades_group_id",
  id = "id",
  program_id = "program_id",
  school_id = "school_id",
  seats_available = "seats_available",
  seats_taken = "seats_taken",
  updated_at = "updated_at",
}

/**
 * update columns of table "grade"
 */
export enum grade_update_column {
  bin_id = "bin_id",
  capacity = "capacity",
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  grade_config_id = "grade_config_id",
  grades_group_id = "grades_group_id",
  id = "id",
  program_id = "program_id",
  school_id = "school_id",
  seats_available = "seats_available",
  seats_taken = "seats_taken",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "grades_additional_question"
 */
export enum grades_additional_question_constraint {
  grades_additional_question_pkey = "grades_additional_question_pkey",
  grades_additional_question_question_id_grade_config_id_addi_key = "grades_additional_question_question_id_grade_config_id_addi_key",
}

/**
 * select columns of table "grades_additional_question"
 */
export enum grades_additional_question_select_column {
  additional_question_id = "additional_question_id",
  created_at = "created_at",
  grade_config_id = "grade_config_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "grades_additional_question"
 */
export enum grades_additional_question_update_column {
  additional_question_id = "additional_question_id",
  created_at = "created_at",
  grade_config_id = "grade_config_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "grades_answer"
 */
export enum grades_answer_constraint {
  grades_answer_form_id_question_id_key = "grades_answer_form_id_question_id_key",
  grades_answer_pkey = "grades_answer_pkey",
}

/**
 * select columns of table "grades_answer"
 */
export enum grades_answer_select_column {
  created_at = "created_at",
  form_id = "form_id",
  grade_config_id = "grade_config_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "grades_answer"
 */
export enum grades_answer_update_column {
  created_at = "created_at",
  form_id = "form_id",
  grade_config_id = "grade_config_id",
  id = "id",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "grades_by_grade_question_id"
 */
export enum grades_by_grade_question_id_select_column {
  grade_config_id = "grade_config_id",
  order = "order",
  question_id = "question_id",
}

/**
 * unique or primary key constraints on table "grades_group"
 */
export enum grades_group_constraint {
  grades_group_pkey = "grades_group_pkey",
}

/**
 * unique or primary key constraints on table "grades_group_default_bin_config"
 */
export enum grades_group_default_bin_config_constraint {
  grades_group_default_bin_config_pkey = "grades_group_default_bin_config_pkey",
}

/**
 * select columns of table "grades_group_default_bin_config"
 */
export enum grades_group_default_bin_config_select_column {
  bin_config_id = "bin_config_id",
  grades_group_id = "grades_group_id",
  id = "id",
}

/**
 * update columns of table "grades_group_default_bin_config"
 */
export enum grades_group_default_bin_config_update_column {
  bin_config_id = "bin_config_id",
  grades_group_id = "grades_group_id",
  id = "id",
}

/**
 * update columns of table "grades_group"
 */
export enum grades_group_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "grades_question"
 */
export enum grades_question_constraint {
  grades_question_pkey = "grades_question_pkey",
}

/**
 * update columns of table "grades_question"
 */
export enum grades_question_update_column {
  created_at = "created_at",
  filters = "filters",
  question_id = "question_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "match_config"
 */
export enum match_config_constraint {
  match_config_pkey = "match_config_pkey",
}

/**
 * select columns of table "match_config"
 */
export enum match_config_select_column {
  default_bin_config_id = "default_bin_config_id",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  name = "name",
  parameters = "parameters",
}

/**
 * update columns of table "match_config"
 */
export enum match_config_update_column {
  default_bin_config_id = "default_bin_config_id",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  name = "name",
  parameters = "parameters",
}

/**
 * select columns of table "match_run_school"
 */
export enum match_run_school_select_column {
  match_run_id = "match_run_id",
  school_id = "school_id",
}

export enum match_run_status_enum {
  Canceled = "Canceled",
  Completed = "Completed",
  Error = "Error",
  Loading = "Loading",
  Matching = "Matching",
  Writing = "Writing",
}

/**
 * unique or primary key constraints on table "match_template"
 */
export enum match_template_constraint {
  match_template_organization_id_name_key = "match_template_organization_id_name_key",
  match_template_pkey = "match_template_pkey",
}

/**
 * unique or primary key constraints on table "match_template_organization"
 */
export enum match_template_organization_constraint {
  match_template_organization_organization_id_key = "match_template_organization_organization_id_key",
  match_template_organization_pkey = "match_template_organization_pkey",
}

/**
 * select columns of table "match_template_organization"
 */
export enum match_template_organization_select_column {
  created_at = "created_at",
  id = "id",
  match_template_id = "match_template_id",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "match_template_organization"
 */
export enum match_template_organization_update_column {
  created_at = "created_at",
  id = "id",
  match_template_id = "match_template_id",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "match_template"
 */
export enum match_template_select_column {
  config = "config",
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  sort_field_1 = "sort_field_1",
  sort_field_2 = "sort_field_2",
  sort_field_3 = "sort_field_3",
  sort_order_1 = "sort_order_1",
  sort_order_2 = "sort_order_2",
  sort_order_3 = "sort_order_3",
  updated_at = "updated_at",
}

/**
 * update columns of table "match_template"
 */
export enum match_template_update_column {
  config = "config",
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  sort_field_1 = "sort_field_1",
  sort_field_2 = "sort_field_2",
  sort_field_3 = "sort_field_3",
  sort_order_1 = "sort_order_1",
  sort_order_2 = "sort_order_2",
  sort_order_3 = "sort_order_3",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "message_adhoc"
 */
export enum message_adhoc_constraint {
  message_adhoc_pkey = "message_adhoc_pkey",
}

/**
 * unique or primary key constraints on table "message_adhoc_form"
 */
export enum message_adhoc_form_constraint {
  message_adhoc_form_pkey = "message_adhoc_form_pkey",
}

/**
 * select columns of table "message_adhoc_form"
 */
export enum message_adhoc_form_select_column {
  form_id = "form_id",
  id = "id",
  message_id = "message_id",
}

/**
 * update columns of table "message_adhoc_form"
 */
export enum message_adhoc_form_update_column {
  form_id = "form_id",
  id = "id",
  message_id = "message_id",
}

/**
 * unique or primary key constraints on table "message_adhoc_payload_email"
 */
export enum message_adhoc_payload_email_constraint {
  message_adhoc_payload_email_pkey = "message_adhoc_payload_email_pkey",
}

/**
 * update columns of table "message_adhoc_payload_email"
 */
export enum message_adhoc_payload_email_update_column {
  message_body = "message_body",
  message_id = "message_id",
  message_subject = "message_subject",
}

/**
 * unique or primary key constraints on table "message_adhoc_payload_rich_content"
 */
export enum message_adhoc_payload_rich_content_constraint {
  message_adhoc_payload_rich_content_pkey = "message_adhoc_payload_rich_content_pkey",
}

/**
 * select columns of table "message_adhoc_payload_rich_content"
 */
export enum message_adhoc_payload_rich_content_select_column {
  content_id = "content_id",
  message_id = "message_id",
}

/**
 * update columns of table "message_adhoc_payload_rich_content"
 */
export enum message_adhoc_payload_rich_content_update_column {
  content_id = "content_id",
  message_id = "message_id",
}

/**
 * unique or primary key constraints on table "message_adhoc_payload_sms"
 */
export enum message_adhoc_payload_sms_constraint {
  message_adhoc_payload_sms_pkey = "message_adhoc_payload_sms_pkey",
}

/**
 * update columns of table "message_adhoc_payload_sms"
 */
export enum message_adhoc_payload_sms_update_column {
  message_body = "message_body",
  message_id = "message_id",
}

/**
 * unique or primary key constraints on table "message_adhoc_receiver"
 */
export enum message_adhoc_receiver_constraint {
  message_adhoc_receiver_pkey = "message_adhoc_receiver_pkey",
}

/**
 * select columns of table "message_adhoc_receiver"
 */
export enum message_adhoc_receiver_select_column {
  created_at = "created_at",
  id = "id",
  message_id = "message_id",
  message_status = "message_status",
  message_type = "message_type",
  receiver_contact_information = "receiver_contact_information",
  receiver_person_id = "receiver_person_id",
  sent_at = "sent_at",
}

/**
 * update columns of table "message_adhoc_receiver"
 */
export enum message_adhoc_receiver_update_column {
  created_at = "created_at",
  id = "id",
  message_id = "message_id",
  message_status = "message_status",
  message_type = "message_type",
  receiver_contact_information = "receiver_contact_information",
  receiver_person_id = "receiver_person_id",
  sent_at = "sent_at",
}

/**
 * update columns of table "message_adhoc"
 */
export enum message_adhoc_update_column {
  created_at = "created_at",
  id = "id",
  organization_id = "organization_id",
  sender_person_id = "sender_person_id",
}

/**
 * unique or primary key constraints on table "message_branding"
 */
export enum message_branding_constraint {
  message_branding_organization_id_key = "message_branding_organization_id_key",
  message_branding_pkey = "message_branding_pkey",
}

/**
 * update columns of table "message_branding"
 */
export enum message_branding_update_column {
  created_at = "created_at",
  footer = "footer",
  id = "id",
  logo_url = "logo_url",
  organization_id = "organization_id",
  support_email = "support_email",
  updated_at = "updated_at",
}

export enum message_image_type_enum {
  AssignedFormInstruction = "AssignedFormInstruction",
}

export enum message_status_enum {
  Failed = "Failed",
  NotSent = "NotSent",
  Pending = "Pending",
  Sent = "Sent",
}

/**
 * unique or primary key constraints on table "message_template_type"
 */
export enum message_template_type_constraint {
  message_template_type_pkey = "message_template_type_pkey",
}

export enum message_template_type_enum {
  FormAdminCancelled = "FormAdminCancelled",
  FormCancelled = "FormCancelled",
  FormDeleted = "FormDeleted",
  FormStarted = "FormStarted",
  FormSubmitted = "FormSubmitted",
  OfferAccepted = "OfferAccepted",
  OfferDeclined = "OfferDeclined",
  OfferExtended = "OfferExtended",
  OfferRevoked = "OfferRevoked",
  WaitlistRemoved = "WaitlistRemoved",
  WaitlistWithdrawn = "WaitlistWithdrawn",
  Waitlisted = "Waitlisted",
}

/**
 * update columns of table "message_template_type"
 */
export enum message_template_type_update_column {
  value = "value",
}

export enum message_type_enum {
  SMS = "SMS",
  email = "email",
}

/**
 * unique or primary key constraints on table "offer"
 */
export enum offer_constraint {
  offer_form_id_school_id_idx = "offer_form_id_school_id_idx",
  offer_pkey = "offer_pkey",
}

/**
 * select columns of table "offer"
 */
export enum offer_select_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_id = "form_id",
  grade_id = "grade_id",
  id = "id",
  school_id = "school_id",
  status = "status",
  status_updated_at = "status_updated_at",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "offer_status"
 */
export enum offer_status_constraint {
  offer_status_pkey = "offer_status_pkey",
}

export enum offer_status_enum {
  Accepted = "Accepted",
  Declined = "Declined",
  Offered = "Offered",
  Revoked = "Revoked",
}

/**
 * update columns of table "offer_status"
 */
export enum offer_status_update_column {
  value = "value",
}

/**
 * update columns of table "offer"
 */
export enum offer_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_id = "form_id",
  grade_id = "grade_id",
  id = "id",
  school_id = "school_id",
  status = "status",
  status_updated_at = "status_updated_at",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "operator"
 */
export enum operator_constraint {
  operator_pkey = "operator_pkey",
}

export enum operator_enum {
  and = "and",
  or = "or",
}

/**
 * update columns of table "operator"
 */
export enum operator_update_column {
  value = "value",
}

/**
 * column ordering options
 */
export enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}

/**
 * unique or primary key constraints on table "organization_branding"
 */
export enum organization_branding_constraint {
  organization_branding_organization_id_key = "organization_branding_organization_id_key",
  organization_branding_pkey = "organization_branding_pkey",
}

/**
 * update columns of table "organization_branding"
 */
export enum organization_branding_update_column {
  color_100 = "color_100",
  color_200 = "color_200",
  color_300 = "color_300",
  color_400 = "color_400",
  color_50 = "color_50",
  color_500 = "color_500",
  color_600 = "color_600",
  color_700 = "color_700",
  color_800 = "color_800",
  color_900 = "color_900",
  created_at = "created_at",
  id = "id",
  logo_color_url = "logo_color_url",
  logo_white_url = "logo_white_url",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "organization_config"
 */
export enum organization_config_constraint {
  organization_config_org_and_type_index = "organization_config_org_and_type_index",
  organization_config_organization_id_type_key = "organization_config_organization_id_type_key",
  organization_config_pkey = "organization_config_pkey",
}

/**
 * select columns of table "organization_config"
 */
export enum organization_config_select_column {
  data = "data",
  enabled = "enabled",
  id = "id",
  organization_id = "organization_id",
  public = "public",
  type = "type",
}

/**
 * select "organization_config_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_config"
 */
export enum organization_config_select_column_organization_config_aggregate_bool_exp_bool_and_arguments_columns {
  enabled = "enabled",
  public = "public",
}

/**
 * select "organization_config_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_config"
 */
export enum organization_config_select_column_organization_config_aggregate_bool_exp_bool_or_arguments_columns {
  enabled = "enabled",
  public = "public",
}

/**
 * unique or primary key constraints on table "organization_config_type"
 */
export enum organization_config_type_constraint {
  organization_config_type_pkey = "organization_config_type_pkey",
}

export enum organization_config_type_enum {
  AccountLookup = "AccountLookup",
  Login = "Login",
  Match = "Match",
  ScheduleExport = "ScheduleExport",
  TranslationOptions = "TranslationOptions",
}

/**
 * update columns of table "organization_config_type"
 */
export enum organization_config_type_update_column {
  value = "value",
}

/**
 * update columns of table "organization_config"
 */
export enum organization_config_update_column {
  data = "data",
  enabled = "enabled",
  id = "id",
  organization_id = "organization_id",
  public = "public",
  type = "type",
}

/**
 * unique or primary key constraints on table "organization"
 */
export enum organization_constraint {
  organization_path_key = "organization_path_key",
  organizations_pkey = "organizations_pkey",
}

export enum organization_status_enum {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
}

/**
 * update columns of table "organization"
 */
export enum organization_update_column {
  created_at = "created_at",
  error = "error",
  id = "id",
  name = "name",
  path = "path",
  status = "status",
  timezone_name = "timezone_name",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "person_answer_bank"
 */
export enum person_answer_bank_constraint {
  person_answer_bank_person_id_custom_question_type_id_digest_key = "person_answer_bank_person_id_custom_question_type_id_digest_key",
  person_answer_bank_pkey = "person_answer_bank_pkey",
}

/**
 * unique or primary key constraints on table "person_answer_bank_external_relationship"
 */
export enum person_answer_bank_external_relationship_constraint {
  person_answer_bank_external_relations_person_answer_bank_id_key = "person_answer_bank_external_relations_person_answer_bank_id_key",
  person_answer_bank_external_relationship_pkey = "person_answer_bank_external_relationship_pkey",
}

/**
 * update columns of table "person_answer_bank_external_relationship"
 */
export enum person_answer_bank_external_relationship_update_column {
  external_id = "external_id",
  id = "id",
  person_answer_bank_id = "person_answer_bank_id",
}

/**
 * update columns of table "person_answer_bank"
 */
export enum person_answer_bank_update_column {
  created_at = "created_at",
  custom_question_type_id = "custom_question_type_id",
  digest = "digest",
  id = "id",
  last_used_at = "last_used_at",
  person_id = "person_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "person_answer"
 */
export enum person_answer_constraint {
  person_answer_person_answer_bank_id_custom_question_type_field_ = "person_answer_person_answer_bank_id_custom_question_type_field_",
  person_answer_pkey = "person_answer_pkey",
}

/**
 * unique or primary key constraints on table "person_answer_option"
 */
export enum person_answer_option_constraint {
  person_answer_option_person_answer_id_form_question_option__key = "person_answer_option_person_answer_id_form_question_option__key",
  person_answer_option_pkey = "person_answer_option_pkey",
}

/**
 * select columns of table "person_answer_option"
 */
export enum person_answer_option_select_column {
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  person_answer_id = "person_answer_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "person_answer_option"
 */
export enum person_answer_option_update_column {
  created_at = "created_at",
  form_question_option_id = "form_question_option_id",
  id = "id",
  person_answer_id = "person_answer_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "person_answer"
 */
export enum person_answer_select_column {
  answer = "answer",
  created_at = "created_at",
  custom_question_type_field_id = "custom_question_type_field_id",
  id = "id",
  person_answer_bank_id = "person_answer_bank_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "person_answer"
 */
export enum person_answer_update_column {
  answer = "answer",
  created_at = "created_at",
  custom_question_type_field_id = "custom_question_type_field_id",
  id = "id",
  person_answer_bank_id = "person_answer_bank_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "person_bin"
 */
export enum person_bin_constraint {
  person_bin_bin_id_lottery_number_key = "person_bin_bin_id_lottery_number_key",
  person_bin_person_id_bin_id_key = "person_bin_person_id_bin_id_key",
  person_bin_pkey = "person_bin_pkey",
}

/**
 * select columns of table "person_bin"
 */
export enum person_bin_select_column {
  bin_id = "bin_id",
  created_at = "created_at",
  id = "id",
  lottery_number = "lottery_number",
  person_id = "person_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "person_bin"
 */
export enum person_bin_update_column {
  bin_id = "bin_id",
  created_at = "created_at",
  id = "id",
  lottery_number = "lottery_number",
  person_id = "person_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "person"
 */
export enum person_constraint {
  person_pkey = "person_pkey",
  unique_user_organization = "unique_user_organization",
}

/**
 * unique or primary key constraints on table "person_match_config"
 */
export enum person_match_config_constraint {
  person_match_config_match_config_id_lottery_number_key = "person_match_config_match_config_id_lottery_number_key",
  person_match_config_person_id_match_config_id_key = "person_match_config_person_id_match_config_id_key",
  person_match_config_pkey = "person_match_config_pkey",
}

/**
 * select columns of table "person_match_config"
 */
export enum person_match_config_select_column {
  created_at = "created_at",
  id = "id",
  lottery_number = "lottery_number",
  match_config_id = "match_config_id",
  person_id = "person_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "person_match_config"
 */
export enum person_match_config_update_column {
  created_at = "created_at",
  id = "id",
  lottery_number = "lottery_number",
  match_config_id = "match_config_id",
  person_id = "person_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "person"
 */
export enum person_select_column {
  active = "active",
  avatar = "avatar",
  birth_date = "birth_date",
  city = "city",
  created_at = "created_at",
  deleted_at = "deleted_at",
  email_address = "email_address",
  email_okay = "email_okay",
  first_name = "first_name",
  id = "id",
  last_name = "last_name",
  middle_name = "middle_name",
  organization_id = "organization_id",
  person_type = "person_type",
  phone_number = "phone_number",
  preferred_language = "preferred_language",
  reference_id = "reference_id",
  sms_okay = "sms_okay",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  user_id = "user_id",
  zip_code = "zip_code",
}

/**
 * select "person_aggregate_bool_exp_bool_and_arguments_columns" columns of table "person"
 */
export enum person_select_column_person_aggregate_bool_exp_bool_and_arguments_columns {
  active = "active",
  email_okay = "email_okay",
  sms_okay = "sms_okay",
}

/**
 * select "person_aggregate_bool_exp_bool_or_arguments_columns" columns of table "person"
 */
export enum person_select_column_person_aggregate_bool_exp_bool_or_arguments_columns {
  active = "active",
  email_okay = "email_okay",
  sms_okay = "sms_okay",
}

/**
 * unique or primary key constraints on table "person_team"
 */
export enum person_team_constraint {
  person_team_person_id_key = "person_team_person_id_key",
  person_team_person_id_team_id_key = "person_team_person_id_team_id_key",
  person_team_pkey = "person_team_pkey",
}

/**
 * select columns of table "person_team"
 */
export enum person_team_select_column {
  id = "id",
  person_id = "person_id",
  team_id = "team_id",
}

/**
 * update columns of table "person_team"
 */
export enum person_team_update_column {
  id = "id",
  person_id = "person_id",
  team_id = "team_id",
}

export enum person_type_enum {
  admin = "admin",
  applicant = "applicant",
  districtAdmin = "districtAdmin",
  guardian = "guardian",
  orgAdmin = "orgAdmin",
  schoolAdmin = "schoolAdmin",
}

/**
 * update columns of table "person"
 */
export enum person_update_column {
  active = "active",
  avatar = "avatar",
  birth_date = "birth_date",
  city = "city",
  created_at = "created_at",
  deleted_at = "deleted_at",
  email_address = "email_address",
  email_okay = "email_okay",
  first_name = "first_name",
  id = "id",
  last_name = "last_name",
  middle_name = "middle_name",
  organization_id = "organization_id",
  person_type = "person_type",
  phone_number = "phone_number",
  preferred_language = "preferred_language",
  reference_id = "reference_id",
  sms_okay = "sms_okay",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  user_id = "user_id",
  zip_code = "zip_code",
}

/**
 * unique or primary key constraints on table "priority_template"
 */
export enum priority_template_constraint {
  priority_template_enrollment_period_id_name_key = "priority_template_enrollment_period_id_name_key",
  priority_template_pkey = "priority_template_pkey",
}

/**
 * unique or primary key constraints on table "priority_template_enrollment_period"
 */
export enum priority_template_enrollment_period_constraint {
  priority_template_enrollment__priority_template_id_enrollme_key = "priority_template_enrollment__priority_template_id_enrollme_key",
  priority_template_enrollment_period_pkey = "priority_template_enrollment_period_pkey",
}

/**
 * select columns of table "priority_template_enrollment_period"
 */
export enum priority_template_enrollment_period_select_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  priority_template_id = "priority_template_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "priority_template_enrollment_period"
 */
export enum priority_template_enrollment_period_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  priority_template_id = "priority_template_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "priority_template_grade"
 */
export enum priority_template_grade_constraint {
  priority_template_grade_pkey = "priority_template_grade_pkey",
  priority_template_grade_priority_template_id_grade_id_key = "priority_template_grade_priority_template_id_grade_id_key",
}

/**
 * select columns of table "priority_template_grade"
 */
export enum priority_template_grade_select_column {
  created_at = "created_at",
  grade_id = "grade_id",
  id = "id",
  priority_template_id = "priority_template_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "priority_template_grade"
 */
export enum priority_template_grade_update_column {
  created_at = "created_at",
  grade_id = "grade_id",
  id = "id",
  priority_template_id = "priority_template_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "priority_template_school"
 */
export enum priority_template_school_constraint {
  priority_template_school_pkey = "priority_template_school_pkey",
  priority_template_school_priority_template_id_enrollment_pe_key = "priority_template_school_priority_template_id_enrollment_pe_key",
}

/**
 * select columns of table "priority_template_school"
 */
export enum priority_template_school_select_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  priority_template_id = "priority_template_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "priority_template_school"
 */
export enum priority_template_school_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  priority_template_id = "priority_template_id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "priority_template"
 */
export enum priority_template_select_column {
  config = "config",
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  name = "name",
  sort_field_1 = "sort_field_1",
  sort_field_2 = "sort_field_2",
  sort_field_3 = "sort_field_3",
  sort_order_1 = "sort_order_1",
  sort_order_2 = "sort_order_2",
  sort_order_3 = "sort_order_3",
  updated_at = "updated_at",
}

export enum priority_template_sort_field_enum {
  AppSubmissionTime = "AppSubmissionTime",
  LotteryNumber = "LotteryNumber",
  LotteryOrder = "LotteryOrder",
  PriorityGroup = "PriorityGroup",
  SchoolSubmissionTime = "SchoolSubmissionTime",
  WaitlistedTime = "WaitlistedTime",
}

export enum priority_template_sort_order_enum {
  asc = "asc",
  desc = "desc",
}

/**
 * update columns of table "priority_template"
 */
export enum priority_template_update_column {
  config = "config",
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  name = "name",
  sort_field_1 = "sort_field_1",
  sort_field_2 = "sort_field_2",
  sort_field_3 = "sort_field_3",
  sort_order_1 = "sort_order_1",
  sort_order_2 = "sort_order_2",
  sort_order_3 = "sort_order_3",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "program"
 */
export enum program_constraint {
  program_pkey = "program_pkey",
  program_program_group_id_label_key = "program_program_group_id_label_key",
}

/**
 * unique or primary key constraints on table "program_group"
 */
export enum program_group_constraint {
  program_group_name_organization_id_key = "program_group_name_organization_id_key",
  program_group_pkey = "program_group_pkey",
}

/**
 * select columns of table "program_group"
 */
export enum program_group_select_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "program_group"
 */
export enum program_group_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * select columns of table "program"
 */
export enum program_select_column {
  created_at = "created_at",
  id = "id",
  label = "label",
  order = "order",
  program_group_id = "program_group_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "program"
 */
export enum program_update_column {
  created_at = "created_at",
  id = "id",
  label = "label",
  order = "order",
  program_group_id = "program_group_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question"
 */
export enum question_constraint {
  form_question_form_template_section_id_order_key = "form_question_form_template_section_id_order_key",
  form_question_pkey = "form_question_pkey",
}

/**
 * select columns of table "question"
 */
export enum question_select_column {
  constraints = "constraints",
  created_at = "created_at",
  form_template_section_id = "form_template_section_id",
  id = "id",
  is_required = "is_required",
  key = "key",
  link_text = "link_text",
  link_url = "link_url",
  order = "order",
  permission_level = "permission_level",
  question = "question",
  type = "type",
  updated_at = "updated_at",
}

/**
 * select "question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "question"
 */
export enum question_select_column_question_aggregate_bool_exp_bool_and_arguments_columns {
  is_required = "is_required",
}

/**
 * select "question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "question"
 */
export enum question_select_column_question_aggregate_bool_exp_bool_or_arguments_columns {
  is_required = "is_required",
}

/**
 * unique or primary key constraints on table "question_type"
 */
export enum question_type_constraint {
  question_type_pkey = "question_type_pkey",
}

export enum question_type_enum {
  Address = "Address",
  CustomQuestion = "CustomQuestion",
  Date = "Date",
  Email = "Email",
  FileUpload = "FileUpload",
  FreeText = "FreeText",
  Grades = "Grades",
  MultiSelect = "MultiSelect",
  Number = "Number",
  PhoneNumber = "PhoneNumber",
  SingleSelect = "SingleSelect",
}

/**
 * update columns of table "question_type"
 */
export enum question_type_update_column {
  value = "value",
}

/**
 * update columns of table "question"
 */
export enum question_update_column {
  constraints = "constraints",
  created_at = "created_at",
  form_template_section_id = "form_template_section_id",
  id = "id",
  is_required = "is_required",
  key = "key",
  link_text = "link_text",
  link_url = "link_url",
  order = "order",
  permission_level = "permission_level",
  question = "question",
  type = "type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "relationship_person"
 */
export enum relationship_person_constraint {
  relationship_person_first_person_second_person_key = "relationship_person_first_person_second_person_key",
  relationship_person_index = "relationship_person_index",
  relationship_person_pkey = "relationship_person_pkey",
  relationship_person_second_person_first_person_idx = "relationship_person_second_person_first_person_idx",
}

/**
 * select columns of table "relationship_person"
 */
export enum relationship_person_select_column {
  active = "active",
  created_at = "created_at",
  first_person = "first_person",
  id = "id",
  second_person = "second_person",
  updated_at = "updated_at",
}

/**
 * select "relationship_person_aggregate_bool_exp_bool_and_arguments_columns" columns of table "relationship_person"
 */
export enum relationship_person_select_column_relationship_person_aggregate_bool_exp_bool_and_arguments_columns {
  active = "active",
}

/**
 * select "relationship_person_aggregate_bool_exp_bool_or_arguments_columns" columns of table "relationship_person"
 */
export enum relationship_person_select_column_relationship_person_aggregate_bool_exp_bool_or_arguments_columns {
  active = "active",
}

/**
 * update columns of table "relationship_person"
 */
export enum relationship_person_update_column {
  active = "active",
  created_at = "created_at",
  first_person = "first_person",
  id = "id",
  second_person = "second_person",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "rule"
 */
export enum rule_constraint {
  rule_pkey = "rule_pkey",
}

/**
 * select columns of table "rule"
 */
export enum rule_select_column {
  form_template_rule_id = "form_template_rule_id",
  id = "id",
  type = "type",
}

/**
 * unique or primary key constraints on table "rule_type"
 */
export enum rule_type_constraint {
  rule_type_pkey = "rule_type_pkey",
}

export enum rule_type_enum {
  StatusRule = "StatusRule",
}

/**
 * update columns of table "rule_type"
 */
export enum rule_type_update_column {
  value = "value",
}

/**
 * update columns of table "rule"
 */
export enum rule_update_column {
  form_template_rule_id = "form_template_rule_id",
  id = "id",
  type = "type",
}

/**
 * unique or primary key constraints on table "school_boundary"
 */
export enum school_boundary_constraint {
  school_boundary_pkey = "school_boundary_pkey",
}

/**
 * select columns of table "school_boundary"
 */
export enum school_boundary_select_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  geojson_id = "geojson_id",
  geojson_property_value = "geojson_property_value",
  grade_id = "grade_id",
  id = "id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * update columns of table "school_boundary"
 */
export enum school_boundary_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  geojson_id = "geojson_id",
  geojson_property_value = "geojson_property_value",
  grade_id = "grade_id",
  id = "id",
  school_id = "school_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "school"
 */
export enum school_constraint {
  schools_pkey = "schools_pkey",
}

/**
 * unique or primary key constraints on table "school_default_bin_config"
 */
export enum school_default_bin_config_constraint {
  school_default_bin_config_pkey = "school_default_bin_config_pkey",
}

/**
 * select columns of table "school_default_bin_config"
 */
export enum school_default_bin_config_select_column {
  bin_config_id = "bin_config_id",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  school_id = "school_id",
}

/**
 * update columns of table "school_default_bin_config"
 */
export enum school_default_bin_config_update_column {
  bin_config_id = "bin_config_id",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  school_id = "school_id",
}

/**
 * select columns of table "school"
 */
export enum school_select_column {
  city = "city",
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  reference_id = "reference_id",
  state = "state",
  status = "status",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  zip_code = "zip_code",
}

/**
 * update columns of table "school"
 */
export enum school_update_column {
  city = "city",
  created_at = "created_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  reference_id = "reference_id",
  state = "state",
  status = "status",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  zip_code = "zip_code",
}

/**
 * unique or primary key constraints on table "school_user"
 */
export enum school_user_constraint {
  school_user_pkey = "school_user_pkey",
}

/**
 * select columns of table "school_user"
 */
export enum school_user_select_column {
  school_access_permission = "school_access_permission",
  school_id = "school_id",
  user_id = "user_id",
}

/**
 * update columns of table "school_user"
 */
export enum school_user_update_column {
  school_access_permission = "school_access_permission",
  school_id = "school_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "schools_ranking_section"
 */
export enum schools_ranking_section_constraint {
  schools_ranking_section_form_template_section_id_key = "schools_ranking_section_form_template_section_id_key",
  schools_ranking_section_pkey = "schools_ranking_section_pkey",
}

/**
 * update columns of table "schools_ranking_section"
 */
export enum schools_ranking_section_update_column {
  created_at = "created_at",
  explore_url = "explore_url",
  form_template_section_id = "form_template_section_id",
  id = "id",
  max_schools = "max_schools",
  min_schools = "min_schools",
  ranking_enabled = "ranking_enabled",
  updated_at = "updated_at",
}

/**
 * select columns of table "search.form_by_school"
 */
export enum search_form_by_school_select_column {
  attendance_status = "attendance_status",
  attending_school_id = "attending_school_id",
  deleted_at = "deleted_at",
  enrollment_period_id = "enrollment_period_id",
  form_id = "form_id",
  form_id_text = "form_id_text",
  form_school_rank_id = "form_school_rank_id",
  form_school_rank_status = "form_school_rank_status",
  form_school_rank_sub_status = "form_school_rank_sub_status",
  form_status = "form_status",
  form_status_updated_at = "form_status_updated_at",
  form_template_id = "form_template_id",
  grade_config_id = "grade_config_id",
  grade_label = "grade_label",
  grade_order = "grade_order",
  has_offer = "has_offer",
  has_waitlist = "has_waitlist",
  is_hidden_from_parent = "is_hidden_from_parent",
  lottery_order = "lottery_order",
  offer_id = "offer_id",
  offer_status = "offer_status",
  organization_id = "organization_id",
  person_first_name = "person_first_name",
  person_full_name = "person_full_name",
  person_id = "person_id",
  person_last_name = "person_last_name",
  person_middle_name = "person_middle_name",
  person_reference_id = "person_reference_id",
  person_searchable_text = "person_searchable_text",
  previous_form_id = "previous_form_id",
  previous_offer_grade_id = "previous_offer_grade_id",
  previous_offer_id = "previous_offer_id",
  previous_offer_school_id = "previous_offer_school_id",
  previous_offer_status = "previous_offer_status",
  previous_waitlist_grade_id = "previous_waitlist_grade_id",
  previous_waitlist_id = "previous_waitlist_id",
  previous_waitlist_school_id = "previous_waitlist_school_id",
  previous_waitlist_status = "previous_waitlist_status",
  rank = "rank",
  school_id = "school_id",
  school_name = "school_name",
  school_reference_id = "school_reference_id",
  sub_status_updated_at = "sub_status_updated_at",
  submitted_at = "submitted_at",
  waitlist_id = "waitlist_id",
  waitlist_position = "waitlist_position",
  waitlist_status = "waitlist_status",
}

/**
 * select "search_form_by_school_aggregate_bool_exp_bool_and_arguments_columns" columns of table "search.form_by_school"
 */
export enum search_form_by_school_select_column_search_form_by_school_aggregate_bool_exp_bool_and_arguments_columns {
  has_offer = "has_offer",
  has_waitlist = "has_waitlist",
  is_hidden_from_parent = "is_hidden_from_parent",
}

/**
 * select "search_form_by_school_aggregate_bool_exp_bool_or_arguments_columns" columns of table "search.form_by_school"
 */
export enum search_form_by_school_select_column_search_form_by_school_aggregate_bool_exp_bool_or_arguments_columns {
  has_offer = "has_offer",
  has_waitlist = "has_waitlist",
  is_hidden_from_parent = "is_hidden_from_parent",
}

/**
 * select columns of table "search.form_related_grade"
 */
export enum search_form_related_grade_select_column {
  form_id = "form_id",
  grade_config_id = "grade_config_id",
}

/**
 * select columns of table "search.form_searchable_text"
 */
export enum search_form_searchable_text_select_column {
  form_id = "form_id",
  searchable_text = "searchable_text",
}

/**
 * select columns of table "search.relevant_person"
 */
export enum search_relevant_person_select_column {
  organization_id = "organization_id",
  person_id = "person_id",
  preferred_language = "preferred_language",
  relevant_person_id = "relevant_person_id",
  relevant_person_type = "relevant_person_type",
  searchable_text = "searchable_text",
}

/**
 * unique or primary key constraints on table "status_rule"
 */
export enum status_rule_constraint {
  status_rule_pkey = "status_rule_pkey",
}

/**
 * select columns of table "status_rule"
 */
export enum status_rule_select_column {
  form_status = "form_status",
  next_form_template_id = "next_form_template_id",
  offer_status = "offer_status",
  rule_id = "rule_id",
  type = "type",
  waitlist_status = "waitlist_status",
}

/**
 * unique or primary key constraints on table "status_rule_type"
 */
export enum status_rule_type_constraint {
  status_rule_type_pkey = "status_rule_type_pkey",
}

export enum status_rule_type_enum {
  FormStatus = "FormStatus",
  OfferStatus = "OfferStatus",
  WaitlistStatus = "WaitlistStatus",
}

/**
 * update columns of table "status_rule_type"
 */
export enum status_rule_type_update_column {
  value = "value",
}

/**
 * update columns of table "status_rule"
 */
export enum status_rule_update_column {
  form_status = "form_status",
  next_form_template_id = "next_form_template_id",
  offer_status = "offer_status",
  rule_id = "rule_id",
  type = "type",
  waitlist_status = "waitlist_status",
}

/**
 * unique or primary key constraints on table "tag_group"
 */
export enum tag_group_constraint {
  tag_group_name_enrollment_period_id_key = "tag_group_name_enrollment_period_id_key",
  tag_group_pkey = "tag_group_pkey",
}

/**
 * update columns of table "tag_group"
 */
export enum tag_group_update_column {
  created_at = "created_at",
  enrollment_period_id = "enrollment_period_id",
  id = "id",
  is_default = "is_default",
  is_external = "is_external",
  max_tags = "max_tags",
  name = "name",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "team"
 */
export enum team_constraint {
  team_name_organization_id_key = "team_name_organization_id_key",
  team_pkey = "team_pkey",
}

/**
 * update columns of table "team"
 */
export enum team_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  id = "id",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "user_address"
 */
export enum user_address_constraint {
  user_address_pkey = "user_address_pkey",
  user_address_user_id_street_address_street_address_line_2_city_ = "user_address_user_id_street_address_street_address_line_2_city_",
}

/**
 * select columns of table "user_address"
 */
export enum user_address_select_column {
  city = "city",
  created_at = "created_at",
  id = "id",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  user_id = "user_id",
  zip_code = "zip_code",
}

/**
 * update columns of table "user_address"
 */
export enum user_address_update_column {
  city = "city",
  created_at = "created_at",
  id = "id",
  state = "state",
  street_address = "street_address",
  street_address_line_2 = "street_address_line_2",
  updated_at = "updated_at",
  user_id = "user_id",
  zip_code = "zip_code",
}

/**
 * unique or primary key constraints on table "user"
 */
export enum user_constraint {
  users_pkey = "users_pkey",
}

/**
 * unique or primary key constraints on table "user_group"
 */
export enum user_group_constraint {
  user_group_name_key = "user_group_name_key",
  user_group_pkey = "user_group_pkey",
}

/**
 * unique or primary key constraints on table "user_group_permission"
 */
export enum user_group_permission_constraint {
  user_group_permission_pkey = "user_group_permission_pkey",
  user_group_permission_role_id_permission_key = "user_group_permission_role_id_permission_key",
}

/**
 * select columns of table "user_group_permission"
 */
export enum user_group_permission_select_column {
  id = "id",
  permission = "permission",
  user_group_id = "user_group_id",
}

/**
 * update columns of table "user_group_permission"
 */
export enum user_group_permission_update_column {
  id = "id",
  permission = "permission",
  user_group_id = "user_group_id",
}

/**
 * update columns of table "user_group"
 */
export enum user_group_update_column {
  id = "id",
  label = "label",
  name = "name",
  person_type = "person_type",
}

/**
 * select columns of table "user"
 */
export enum user_select_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  updated_at = "updated_at",
  user_group_id = "user_group_id",
}

/**
 * update columns of table "user"
 */
export enum user_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  updated_at = "updated_at",
  user_group_id = "user_group_id",
}

export enum verification_status_enum {
  Pending = "Pending",
  Rejected = "Rejected",
  Verified = "Verified",
}

/**
 * unique or primary key constraints on table "waitlist"
 */
export enum waitlist_constraint {
  waitlist_form_id_school_id_grade_id_idx = "waitlist_form_id_school_id_grade_id_idx",
  waitlist_pkey = "waitlist_pkey",
}

/**
 * select columns of table "waitlist"
 */
export enum waitlist_select_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_id = "form_id",
  grade_id = "grade_id",
  id = "id",
  position = "position",
  priority_group = "priority_group",
  school_id = "school_id",
  status = "status",
  status_updated_at = "status_updated_at",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "waitlist_status"
 */
export enum waitlist_status_constraint {
  waitlist_status_pkey = "waitlist_status_pkey",
}

export enum waitlist_status_enum {
  Removed = "Removed",
  Waitlisted = "Waitlisted",
  Withdrawn = "Withdrawn",
}

/**
 * update columns of table "waitlist_status"
 */
export enum waitlist_status_update_column {
  value = "value",
}

/**
 * update columns of table "waitlist"
 */
export enum waitlist_update_column {
  created_at = "created_at",
  deleted_at = "deleted_at",
  form_id = "form_id",
  grade_id = "grade_id",
  id = "id",
  position = "position",
  priority_group = "priority_group",
  school_id = "school_id",
  status = "status",
  status_updated_at = "status_updated_at",
  updated_at = "updated_at",
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

export interface SecondaryIdentity {
  provider?: string | null;
  userId?: string | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

export interface action_item_aggregate_bool_exp {
  count?: action_item_aggregate_bool_exp_count | null;
}

export interface action_item_aggregate_bool_exp_count {
  arguments?: action_item_select_column[] | null;
  distinct?: boolean | null;
  filter?: action_item_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "action_item"
 */
export interface action_item_aggregate_order_by {
  count?: order_by | null;
  max?: action_item_max_order_by | null;
  min?: action_item_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "action_item"
 */
export interface action_item_arr_rel_insert_input {
  data: action_item_insert_input[];
  on_conflict?: action_item_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "action_item". All fields are combined with a logical 'AND'.
 */
export interface action_item_bool_exp {
  _and?: action_item_bool_exp[] | null;
  _not?: action_item_bool_exp | null;
  _or?: action_item_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  status?: action_item_status_enum_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "action_item"
 */
export interface action_item_insert_input {
  created_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  status?: action_item_status_enum | null;
  summary?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "action_item"
 */
export interface action_item_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  summary?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "action_item"
 */
export interface action_item_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  summary?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "action_item"
 */
export interface action_item_on_conflict {
  constraint: action_item_constraint;
  update_columns: action_item_update_column[];
  where?: action_item_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "action_item_status_enum". All fields are combined with logical 'AND'.
 */
export interface action_item_status_enum_comparison_exp {
  _eq?: action_item_status_enum | null;
  _in?: action_item_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: action_item_status_enum | null;
  _nin?: action_item_status_enum[] | null;
}

export interface additional_question_aggregate_bool_exp {
  count?: additional_question_aggregate_bool_exp_count | null;
}

export interface additional_question_aggregate_bool_exp_count {
  arguments?: additional_question_select_column[] | null;
  distinct?: boolean | null;
  filter?: additional_question_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "additional_question"
 */
export interface additional_question_arr_rel_insert_input {
  data: additional_question_insert_input[];
  on_conflict?: additional_question_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "additional_question". All fields are combined with a logical 'AND'.
 */
export interface additional_question_bool_exp {
  _and?: additional_question_bool_exp[] | null;
  _not?: additional_question_bool_exp | null;
  _or?: additional_question_bool_exp[] | null;
  additional_question_id?: uuid_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  form_question_option?: form_question_option_bool_exp | null;
  form_question_option_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "additional_question"
 */
export interface additional_question_insert_input {
  additional_question_id?: uuid | null;
  created_at?: timestamptz | null;
  form_question_option?: form_question_option_obj_rel_insert_input | null;
  form_question_option_id?: uuid | null;
  id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "additional_question"
 */
export interface additional_question_on_conflict {
  constraint: additional_question_constraint;
  update_columns: additional_question_update_column[];
  where?: additional_question_bool_exp | null;
}

export interface announcement_aggregate_bool_exp {
  bool_and?: announcement_aggregate_bool_exp_bool_and | null;
  bool_or?: announcement_aggregate_bool_exp_bool_or | null;
  count?: announcement_aggregate_bool_exp_count | null;
}

export interface announcement_aggregate_bool_exp_bool_and {
  arguments: announcement_select_column_announcement_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: announcement_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface announcement_aggregate_bool_exp_bool_or {
  arguments: announcement_select_column_announcement_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: announcement_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface announcement_aggregate_bool_exp_count {
  arguments?: announcement_select_column[] | null;
  distinct?: boolean | null;
  filter?: announcement_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "announcement"
 */
export interface announcement_aggregate_order_by {
  count?: order_by | null;
  max?: announcement_max_order_by | null;
  min?: announcement_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "announcement"
 */
export interface announcement_arr_rel_insert_input {
  data: announcement_insert_input[];
  on_conflict?: announcement_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "announcement". All fields are combined with a logical 'AND'.
 */
export interface announcement_bool_exp {
  _and?: announcement_bool_exp[] | null;
  _not?: announcement_bool_exp | null;
  _or?: announcement_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  condition?: announcement_condition_enum_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  display_type?: announcement_display_type_enum_comparison_exp | null;
  entry_point?: announcement_entry_point_enum_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  link_text?: String_comparison_exp | null;
  link_url?: String_comparison_exp | null;
  organization_id?: uuid_comparison_exp | null;
  title?: String_comparison_exp | null;
  type?: announcement_type_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "announcement_condition_enum". All fields are combined with logical 'AND'.
 */
export interface announcement_condition_enum_comparison_exp {
  _eq?: announcement_condition_enum | null;
  _in?: announcement_condition_enum[] | null;
  _is_null?: boolean | null;
  _neq?: announcement_condition_enum | null;
  _nin?: announcement_condition_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "announcement_display_type_enum". All fields are combined with logical 'AND'.
 */
export interface announcement_display_type_enum_comparison_exp {
  _eq?: announcement_display_type_enum | null;
  _in?: announcement_display_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: announcement_display_type_enum | null;
  _nin?: announcement_display_type_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "announcement_entry_point_enum". All fields are combined with logical 'AND'.
 */
export interface announcement_entry_point_enum_comparison_exp {
  _eq?: announcement_entry_point_enum | null;
  _in?: announcement_entry_point_enum[] | null;
  _is_null?: boolean | null;
  _neq?: announcement_entry_point_enum | null;
  _nin?: announcement_entry_point_enum[] | null;
}

/**
 * input type for inserting data into table "announcement"
 */
export interface announcement_insert_input {
  active?: boolean | null;
  condition?: announcement_condition_enum | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  description?: string | null;
  display_type?: announcement_display_type_enum | null;
  entry_point?: announcement_entry_point_enum | null;
  id?: uuid | null;
  link_text?: string | null;
  link_url?: string | null;
  organization_id?: uuid | null;
  title?: string | null;
  type?: announcement_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "announcement"
 */
export interface announcement_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  link_text?: order_by | null;
  link_url?: order_by | null;
  organization_id?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "announcement"
 */
export interface announcement_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  link_text?: order_by | null;
  link_url?: order_by | null;
  organization_id?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "announcement"
 */
export interface announcement_on_conflict {
  constraint: announcement_constraint;
  update_columns: announcement_update_column[];
  where?: announcement_bool_exp | null;
}

/**
 * input type for updating data in table "announcement"
 */
export interface announcement_set_input {
  active?: boolean | null;
  condition?: announcement_condition_enum | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  description?: string | null;
  display_type?: announcement_display_type_enum | null;
  entry_point?: announcement_entry_point_enum | null;
  id?: uuid | null;
  link_text?: string | null;
  link_url?: string | null;
  organization_id?: uuid | null;
  title?: string | null;
  type?: announcement_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to compare columns of type "announcement_type_enum". All fields are combined with logical 'AND'.
 */
export interface announcement_type_enum_comparison_exp {
  _eq?: announcement_type_enum | null;
  _in?: announcement_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: announcement_type_enum | null;
  _nin?: announcement_type_enum[] | null;
}

export interface applicant_attending_school_aggregate_bool_exp {
  count?: applicant_attending_school_aggregate_bool_exp_count | null;
}

export interface applicant_attending_school_aggregate_bool_exp_count {
  arguments?: applicant_attending_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: applicant_attending_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "applicant_attending_school"
 */
export interface applicant_attending_school_aggregate_order_by {
  count?: order_by | null;
  max?: applicant_attending_school_max_order_by | null;
  min?: applicant_attending_school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "applicant_attending_school"
 */
export interface applicant_attending_school_arr_rel_insert_input {
  data: applicant_attending_school_insert_input[];
  on_conflict?: applicant_attending_school_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "applicant_attending_school". All fields are combined with a logical 'AND'.
 */
export interface applicant_attending_school_bool_exp {
  _and?: applicant_attending_school_bool_exp[] | null;
  _not?: applicant_attending_school_bool_exp | null;
  _or?: applicant_attending_school_bool_exp[] | null;
  attendance_status?: attendance_status_type_enum_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "applicant_attending_school"
 */
export interface applicant_attending_school_insert_input {
  attendance_status?: attendance_status_type_enum | null;
  created_at?: timestamptz | null;
  description?: string | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "applicant_attending_school"
 */
export interface applicant_attending_school_max_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "applicant_attending_school"
 */
export interface applicant_attending_school_min_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "applicant_attending_school"
 */
export interface applicant_attending_school_obj_rel_insert_input {
  data: applicant_attending_school_insert_input;
  on_conflict?: applicant_attending_school_on_conflict | null;
}

/**
 * on_conflict condition type for table "applicant_attending_school"
 */
export interface applicant_attending_school_on_conflict {
  constraint: applicant_attending_school_constraint;
  update_columns: applicant_attending_school_update_column[];
  where?: applicant_attending_school_bool_exp | null;
}

/**
 * Ordering options when selecting data from "applicant_attending_school".
 */
export interface applicant_attending_school_order_by {
  attendance_status?: order_by | null;
  created_at?: order_by | null;
  description?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  person?: person_order_by | null;
  person_id?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

export interface applicant_guardian_aggregate_bool_exp {
  count?: applicant_guardian_aggregate_bool_exp_count | null;
}

export interface applicant_guardian_aggregate_bool_exp_count {
  arguments?: applicant_guardian_select_column[] | null;
  distinct?: boolean | null;
  filter?: applicant_guardian_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "applicant_guardian"
 */
export interface applicant_guardian_aggregate_order_by {
  count?: order_by | null;
  max?: applicant_guardian_max_order_by | null;
  min?: applicant_guardian_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "applicant_guardian"
 */
export interface applicant_guardian_arr_rel_insert_input {
  data: applicant_guardian_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "applicant_guardian". All fields are combined with a logical 'AND'.
 */
export interface applicant_guardian_bool_exp {
  _and?: applicant_guardian_bool_exp[] | null;
  _not?: applicant_guardian_bool_exp | null;
  _or?: applicant_guardian_bool_exp[] | null;
  applicant?: person_bool_exp | null;
  applicant_id?: uuid_comparison_exp | null;
  guardian?: person_bool_exp | null;
  guardian_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "applicant_guardian"
 */
export interface applicant_guardian_insert_input {
  applicant?: person_obj_rel_insert_input | null;
  applicant_id?: uuid | null;
  guardian?: person_obj_rel_insert_input | null;
  guardian_id?: uuid | null;
}

/**
 * order by max() on columns of table "applicant_guardian"
 */
export interface applicant_guardian_max_order_by {
  applicant_id?: order_by | null;
  guardian_id?: order_by | null;
}

/**
 * order by min() on columns of table "applicant_guardian"
 */
export interface applicant_guardian_min_order_by {
  applicant_id?: order_by | null;
  guardian_id?: order_by | null;
}

export interface applicant_school_guardian_user_aggregate_bool_exp {
  count?: applicant_school_guardian_user_aggregate_bool_exp_count | null;
}

export interface applicant_school_guardian_user_aggregate_bool_exp_count {
  arguments?: applicant_school_guardian_user_select_column[] | null;
  distinct?: boolean | null;
  filter?: applicant_school_guardian_user_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "applicant_school_guardian_user"
 */
export interface applicant_school_guardian_user_aggregate_order_by {
  count?: order_by | null;
  max?: applicant_school_guardian_user_max_order_by | null;
  min?: applicant_school_guardian_user_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "applicant_school_guardian_user"
 */
export interface applicant_school_guardian_user_arr_rel_insert_input {
  data: applicant_school_guardian_user_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "applicant_school_guardian_user". All fields are combined with a logical 'AND'.
 */
export interface applicant_school_guardian_user_bool_exp {
  _and?: applicant_school_guardian_user_bool_exp[] | null;
  _not?: applicant_school_guardian_user_bool_exp | null;
  _or?: applicant_school_guardian_user_bool_exp[] | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "applicant_school_guardian_user"
 */
export interface applicant_school_guardian_user_insert_input {
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "applicant_school_guardian_user"
 */
export interface applicant_school_guardian_user_max_order_by {
  person_id?: order_by | null;
  school_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "applicant_school_guardian_user"
 */
export interface applicant_school_guardian_user_min_order_by {
  person_id?: order_by | null;
  school_id?: order_by | null;
  user_id?: order_by | null;
}

export interface assigned_form_aggregate_bool_exp {
  count?: assigned_form_aggregate_bool_exp_count | null;
}

export interface assigned_form_aggregate_bool_exp_count {
  arguments?: assigned_form_select_column[] | null;
  distinct?: boolean | null;
  filter?: assigned_form_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "assigned_form"
 */
export interface assigned_form_aggregate_order_by {
  count?: order_by | null;
  max?: assigned_form_max_order_by | null;
  min?: assigned_form_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "assigned_form"
 */
export interface assigned_form_arr_rel_insert_input {
  data: assigned_form_insert_input[];
  on_conflict?: assigned_form_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "assigned_form". All fields are combined with a logical 'AND'.
 */
export interface assigned_form_bool_exp {
  _and?: assigned_form_bool_exp[] | null;
  _not?: assigned_form_bool_exp | null;
  _or?: assigned_form_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  has_started?: Boolean_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  previous_form?: form_bool_exp | null;
  previous_form_id?: uuid_comparison_exp | null;
  previous_offer?: offer_bool_exp | null;
  previous_offer_id?: uuid_comparison_exp | null;
  previous_waitlist?: waitlist_bool_exp | null;
  previous_waitlist_id?: uuid_comparison_exp | null;
  search_previous_form_by_school?: search_form_by_school_bool_exp | null;
  search_previous_form_by_school_aggregate?: search_form_by_school_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "assigned_form"
 */
export interface assigned_form_insert_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  previous_form?: form_obj_rel_insert_input | null;
  previous_form_id?: uuid | null;
  previous_offer?: offer_obj_rel_insert_input | null;
  previous_offer_id?: uuid | null;
  previous_waitlist?: waitlist_obj_rel_insert_input | null;
  previous_waitlist_id?: uuid | null;
  search_previous_form_by_school?: search_form_by_school_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "assigned_form"
 */
export interface assigned_form_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "assigned_form"
 */
export interface assigned_form_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "assigned_form"
 */
export interface assigned_form_obj_rel_insert_input {
  data: assigned_form_insert_input;
  on_conflict?: assigned_form_on_conflict | null;
}

/**
 * on_conflict condition type for table "assigned_form"
 */
export interface assigned_form_on_conflict {
  constraint: assigned_form_constraint;
  update_columns: assigned_form_update_column[];
  where?: assigned_form_bool_exp | null;
}

/**
 * Ordering options when selecting data from "assigned_form".
 */
export interface assigned_form_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_template?: form_template_order_by | null;
  form_template_id?: order_by | null;
  has_started?: order_by | null;
  id?: order_by | null;
  person?: person_order_by | null;
  person_id?: order_by | null;
  previous_form?: form_order_by | null;
  previous_form_id?: order_by | null;
  previous_offer?: offer_order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist?: waitlist_order_by | null;
  previous_waitlist_id?: order_by | null;
  search_previous_form_by_school_aggregate?: search_form_by_school_aggregate_order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "attendance_status_type_enum". All fields are combined with logical 'AND'.
 */
export interface attendance_status_type_enum_comparison_exp {
  _eq?: attendance_status_type_enum | null;
  _in?: attendance_status_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: attendance_status_type_enum | null;
  _nin?: attendance_status_type_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'.
 */
export interface audit_logged_actions_bool_exp {
  _and?: audit_logged_actions_bool_exp[] | null;
  _not?: audit_logged_actions_bool_exp | null;
  _or?: audit_logged_actions_bool_exp[] | null;
  action?: String_comparison_exp | null;
  action_tstamp_clk?: timestamptz_comparison_exp | null;
  action_tstamp_stm?: timestamptz_comparison_exp | null;
  action_tstamp_tx?: timestamptz_comparison_exp | null;
  changed_fields?: jsonb_comparison_exp | null;
  client_addr?: inet_comparison_exp | null;
  client_port?: Int_comparison_exp | null;
  form_id?: uuid_comparison_exp | null;
  hasura_user?: jsonb_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  relid?: oid_comparison_exp | null;
  row_data?: jsonb_comparison_exp | null;
  row_id?: uuid_comparison_exp | null;
  schema_name?: String_comparison_exp | null;
  school_id?: uuid_comparison_exp | null;
  statement_only?: Boolean_comparison_exp | null;
  table_name?: String_comparison_exp | null;
  transaction_id?: bigint_comparison_exp | null;
  user?: user_bool_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: bigint | null;
  _gt?: bigint | null;
  _gte?: bigint | null;
  _in?: bigint[] | null;
  _is_null?: boolean | null;
  _lt?: bigint | null;
  _lte?: bigint | null;
  _neq?: bigint | null;
  _nin?: bigint[] | null;
}

export interface bin_aggregate_bool_exp {
  count?: bin_aggregate_bool_exp_count | null;
}

export interface bin_aggregate_bool_exp_count {
  arguments?: bin_select_column[] | null;
  distinct?: boolean | null;
  filter?: bin_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "bin"
 */
export interface bin_aggregate_order_by {
  avg?: bin_avg_order_by | null;
  count?: order_by | null;
  max?: bin_max_order_by | null;
  min?: bin_min_order_by | null;
  stddev?: bin_stddev_order_by | null;
  stddev_pop?: bin_stddev_pop_order_by | null;
  stddev_samp?: bin_stddev_samp_order_by | null;
  sum?: bin_sum_order_by | null;
  var_pop?: bin_var_pop_order_by | null;
  var_samp?: bin_var_samp_order_by | null;
  variance?: bin_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "bin"
 */
export interface bin_arr_rel_insert_input {
  data: bin_insert_input[];
  on_conflict?: bin_on_conflict | null;
}

/**
 * order by avg() on columns of table "bin"
 */
export interface bin_avg_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "bin". All fields are combined with a logical 'AND'.
 */
export interface bin_bool_exp {
  _and?: bin_bool_exp[] | null;
  _not?: bin_bool_exp | null;
  _or?: bin_bool_exp[] | null;
  bin_config?: bin_config_bool_exp | null;
  bin_config_id?: uuid_comparison_exp | null;
  capacity?: Int_comparison_exp | null;
  child_bins?: bin_bool_exp | null;
  child_bins_aggregate?: bin_aggregate_bool_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  grades?: grade_bool_exp | null;
  grades_aggregate?: grade_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  parent_bin?: bin_bool_exp | null;
  parent_bin_id?: uuid_comparison_exp | null;
  person_bins?: person_bin_bool_exp | null;
  person_bins_aggregate?: person_bin_aggregate_bool_exp | null;
  seats_available?: Int_comparison_exp | null;
  seats_taken?: Int_comparison_exp | null;
}

export interface bin_config_aggregate_bool_exp {
  count?: bin_config_aggregate_bool_exp_count | null;
}

export interface bin_config_aggregate_bool_exp_count {
  arguments?: bin_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: bin_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "bin_config"
 */
export interface bin_config_aggregate_order_by {
  count?: order_by | null;
  max?: bin_config_max_order_by | null;
  min?: bin_config_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "bin_config"
 */
export interface bin_config_arr_rel_insert_input {
  data: bin_config_insert_input[];
  on_conflict?: bin_config_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "bin_config". All fields are combined with a logical 'AND'.
 */
export interface bin_config_bool_exp {
  _and?: bin_config_bool_exp[] | null;
  _not?: bin_config_bool_exp | null;
  _or?: bin_config_bool_exp[] | null;
  bins?: bin_bool_exp | null;
  bins_aggregate?: bin_aggregate_bool_exp | null;
  default_bin_match_configs?: match_config_bool_exp | null;
  default_bin_match_configs_aggregate?: match_config_aggregate_bool_exp | null;
  grades_group_default_bin_configs?: grades_group_default_bin_config_bool_exp | null;
  grades_group_default_bin_configs_aggregate?: grades_group_default_bin_config_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  match_config?: match_config_bool_exp | null;
  match_config_id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  parameters?: jsonb_comparison_exp | null;
  priority_groups?: jsonb_comparison_exp | null;
  school_default_bin_configs?: school_default_bin_config_bool_exp | null;
  school_default_bin_configs_aggregate?: school_default_bin_config_aggregate_bool_exp | null;
  sort_fields?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "bin_config"
 */
export interface bin_config_insert_input {
  bins?: bin_arr_rel_insert_input | null;
  default_bin_match_configs?: match_config_arr_rel_insert_input | null;
  grades_group_default_bin_configs?: grades_group_default_bin_config_arr_rel_insert_input | null;
  id?: uuid | null;
  match_config?: match_config_obj_rel_insert_input | null;
  match_config_id?: uuid | null;
  name?: string | null;
  parameters?: jsonb | null;
  priority_groups?: jsonb | null;
  school_default_bin_configs?: school_default_bin_config_arr_rel_insert_input | null;
  sort_fields?: jsonb | null;
}

/**
 * order by max() on columns of table "bin_config"
 */
export interface bin_config_max_order_by {
  id?: order_by | null;
  match_config_id?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "bin_config"
 */
export interface bin_config_min_order_by {
  id?: order_by | null;
  match_config_id?: order_by | null;
  name?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "bin_config"
 */
export interface bin_config_obj_rel_insert_input {
  data: bin_config_insert_input;
  on_conflict?: bin_config_on_conflict | null;
}

/**
 * on_conflict condition type for table "bin_config"
 */
export interface bin_config_on_conflict {
  constraint: bin_config_constraint;
  update_columns: bin_config_update_column[];
  where?: bin_config_bool_exp | null;
}

/**
 * Ordering options when selecting data from "bin_config".
 */
export interface bin_config_order_by {
  bins_aggregate?: bin_aggregate_order_by | null;
  default_bin_match_configs_aggregate?: match_config_aggregate_order_by | null;
  grades_group_default_bin_configs_aggregate?: grades_group_default_bin_config_aggregate_order_by | null;
  id?: order_by | null;
  match_config?: match_config_order_by | null;
  match_config_id?: order_by | null;
  name?: order_by | null;
  parameters?: order_by | null;
  priority_groups?: order_by | null;
  school_default_bin_configs_aggregate?: school_default_bin_config_aggregate_order_by | null;
  sort_fields?: order_by | null;
}

/**
 * input type for updating data in table "bin_config"
 */
export interface bin_config_set_input {
  id?: uuid | null;
  match_config_id?: uuid | null;
  name?: string | null;
  parameters?: jsonb | null;
  priority_groups?: jsonb | null;
  sort_fields?: jsonb | null;
}

/**
 * input type for incrementing numeric columns in table "bin"
 */
export interface bin_inc_input {
  capacity?: number | null;
  seats_available?: number | null;
  seats_taken?: number | null;
}

/**
 * input type for inserting data into table "bin"
 */
export interface bin_insert_input {
  bin_config?: bin_config_obj_rel_insert_input | null;
  bin_config_id?: uuid | null;
  capacity?: number | null;
  child_bins?: bin_arr_rel_insert_input | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  grades?: grade_arr_rel_insert_input | null;
  id?: uuid | null;
  parent_bin?: bin_obj_rel_insert_input | null;
  parent_bin_id?: uuid | null;
  person_bins?: person_bin_arr_rel_insert_input | null;
  seats_available?: number | null;
  seats_taken?: number | null;
}

/**
 * order by max() on columns of table "bin"
 */
export interface bin_max_order_by {
  bin_config_id?: order_by | null;
  capacity?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  parent_bin_id?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by min() on columns of table "bin"
 */
export interface bin_min_order_by {
  bin_config_id?: order_by | null;
  capacity?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  parent_bin_id?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "bin"
 */
export interface bin_obj_rel_insert_input {
  data: bin_insert_input;
  on_conflict?: bin_on_conflict | null;
}

/**
 * on_conflict condition type for table "bin"
 */
export interface bin_on_conflict {
  constraint: bin_constraint;
  update_columns: bin_update_column[];
  where?: bin_bool_exp | null;
}

/**
 * Ordering options when selecting data from "bin".
 */
export interface bin_order_by {
  bin_config?: bin_config_order_by | null;
  bin_config_id?: order_by | null;
  capacity?: order_by | null;
  child_bins_aggregate?: bin_aggregate_order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  grades_aggregate?: grade_aggregate_order_by | null;
  id?: order_by | null;
  parent_bin?: bin_order_by | null;
  parent_bin_id?: order_by | null;
  person_bins_aggregate?: person_bin_aggregate_order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * input type for updating data in table "bin"
 */
export interface bin_set_input {
  bin_config_id?: uuid | null;
  capacity?: number | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  parent_bin_id?: uuid | null;
  seats_available?: number | null;
  seats_taken?: number | null;
}

/**
 * order by stddev() on columns of table "bin"
 */
export interface bin_stddev_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "bin"
 */
export interface bin_stddev_pop_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "bin"
 */
export interface bin_stddev_samp_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by sum() on columns of table "bin"
 */
export interface bin_sum_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

export interface bin_updates {
  _inc?: bin_inc_input | null;
  _set?: bin_set_input | null;
  where: bin_bool_exp;
}

/**
 * order by var_pop() on columns of table "bin"
 */
export interface bin_var_pop_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by var_samp() on columns of table "bin"
 */
export interface bin_var_samp_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by variance() on columns of table "bin"
 */
export interface bin_variance_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

export interface boundary_tag_aggregate_bool_exp {
  bool_and?: boundary_tag_aggregate_bool_exp_bool_and | null;
  bool_or?: boundary_tag_aggregate_bool_exp_bool_or | null;
  count?: boundary_tag_aggregate_bool_exp_count | null;
}

export interface boundary_tag_aggregate_bool_exp_bool_and {
  arguments: boundary_tag_select_column_boundary_tag_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: boundary_tag_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface boundary_tag_aggregate_bool_exp_bool_or {
  arguments: boundary_tag_select_column_boundary_tag_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: boundary_tag_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface boundary_tag_aggregate_bool_exp_count {
  arguments?: boundary_tag_select_column[] | null;
  distinct?: boolean | null;
  filter?: boundary_tag_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "boundary_tag"
 */
export interface boundary_tag_arr_rel_insert_input {
  data: boundary_tag_insert_input[];
  on_conflict?: boundary_tag_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "boundary_tag". All fields are combined with a logical 'AND'.
 */
export interface boundary_tag_bool_exp {
  _and?: boundary_tag_bool_exp[] | null;
  _not?: boundary_tag_bool_exp | null;
  _or?: boundary_tag_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  geojson?: geojson_bool_exp | null;
  geojson_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  is_inside?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "boundary_tag"
 */
export interface boundary_tag_insert_input {
  created_at?: timestamptz | null;
  geojson?: geojson_obj_rel_insert_input | null;
  geojson_id?: uuid | null;
  id?: uuid | null;
  is_inside?: boolean | null;
  name?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "boundary_tag"
 */
export interface boundary_tag_on_conflict {
  constraint: boundary_tag_constraint;
  update_columns: boundary_tag_update_column[];
  where?: boundary_tag_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'.
 */
export interface citext_comparison_exp {
  _eq?: citext | null;
  _gt?: citext | null;
  _gte?: citext | null;
  _ilike?: citext | null;
  _in?: citext[] | null;
  _iregex?: citext | null;
  _is_null?: boolean | null;
  _like?: citext | null;
  _lt?: citext | null;
  _lte?: citext | null;
  _neq?: citext | null;
  _nilike?: citext | null;
  _nin?: citext[] | null;
  _niregex?: citext | null;
  _nlike?: citext | null;
  _nregex?: citext | null;
  _nsimilar?: citext | null;
  _regex?: citext | null;
  _similar?: citext | null;
}

export interface custom_question_aggregate_bool_exp {
  count?: custom_question_aggregate_bool_exp_count | null;
}

export interface custom_question_aggregate_bool_exp_count {
  arguments?: custom_question_select_column[] | null;
  distinct?: boolean | null;
  filter?: custom_question_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface custom_question_answer_aggregate_bool_exp {
  count?: custom_question_answer_aggregate_bool_exp_count | null;
}

export interface custom_question_answer_aggregate_bool_exp_count {
  arguments?: custom_question_answer_select_column[] | null;
  distinct?: boolean | null;
  filter?: custom_question_answer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "custom_question_answer"
 */
export interface custom_question_answer_aggregate_order_by {
  count?: order_by | null;
  max?: custom_question_answer_max_order_by | null;
  min?: custom_question_answer_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "custom_question_answer"
 */
export interface custom_question_answer_arr_rel_insert_input {
  data: custom_question_answer_insert_input[];
  on_conflict?: custom_question_answer_on_conflict | null;
}

export interface custom_question_answer_bank_relationship_aggregate_bool_exp {
  count?: custom_question_answer_bank_relationship_aggregate_bool_exp_count | null;
}

export interface custom_question_answer_bank_relationship_aggregate_bool_exp_count {
  arguments?: custom_question_answer_bank_relationship_select_column[] | null;
  distinct?: boolean | null;
  filter?: custom_question_answer_bank_relationship_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_aggregate_order_by {
  count?: order_by | null;
  max?: custom_question_answer_bank_relationship_max_order_by | null;
  min?: custom_question_answer_bank_relationship_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_arr_rel_insert_input {
  data: custom_question_answer_bank_relationship_insert_input[];
  on_conflict?: custom_question_answer_bank_relationship_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question_answer_bank_relationship". All fields are combined with a logical 'AND'.
 */
export interface custom_question_answer_bank_relationship_bool_exp {
  _and?: custom_question_answer_bank_relationship_bool_exp[] | null;
  _not?: custom_question_answer_bank_relationship_bool_exp | null;
  _or?: custom_question_answer_bank_relationship_bool_exp[] | null;
  custom_question?: custom_question_bool_exp | null;
  custom_question_id?: uuid_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person_answer_bank?: person_answer_bank_bool_exp | null;
  person_answer_bank_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_insert_input {
  custom_question?: custom_question_obj_rel_insert_input | null;
  custom_question_id?: uuid | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  person_answer_bank?: person_answer_bank_obj_rel_insert_input | null;
  person_answer_bank_id?: uuid | null;
}

/**
 * order by max() on columns of table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_max_order_by {
  custom_question_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  person_answer_bank_id?: order_by | null;
}

/**
 * order by min() on columns of table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_min_order_by {
  custom_question_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  person_answer_bank_id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_obj_rel_insert_input {
  data: custom_question_answer_bank_relationship_insert_input;
  on_conflict?: custom_question_answer_bank_relationship_on_conflict | null;
}

/**
 * on_conflict condition type for table "custom_question_answer_bank_relationship"
 */
export interface custom_question_answer_bank_relationship_on_conflict {
  constraint: custom_question_answer_bank_relationship_constraint;
  update_columns: custom_question_answer_bank_relationship_update_column[];
  where?: custom_question_answer_bank_relationship_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question_answer". All fields are combined with a logical 'AND'.
 */
export interface custom_question_answer_bool_exp {
  _and?: custom_question_answer_bool_exp[] | null;
  _not?: custom_question_answer_bool_exp | null;
  _or?: custom_question_answer_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question?: custom_question_bool_exp | null;
  form?: form_bool_exp | null;
  form_answer?: form_answer_bool_exp | null;
  form_answer_id?: uuid_comparison_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "custom_question_answer"
 */
export interface custom_question_answer_insert_input {
  created_at?: timestamptz | null;
  custom_question?: custom_question_obj_rel_insert_input | null;
  form?: form_obj_rel_insert_input | null;
  form_answer?: form_answer_obj_rel_insert_input | null;
  form_answer_id?: uuid | null;
  form_id?: uuid | null;
  id?: uuid | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "custom_question_answer"
 */
export interface custom_question_answer_max_order_by {
  created_at?: order_by | null;
  form_answer_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "custom_question_answer"
 */
export interface custom_question_answer_min_order_by {
  created_at?: order_by | null;
  form_answer_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "custom_question_answer"
 */
export interface custom_question_answer_on_conflict {
  constraint: custom_question_answer_constraint;
  update_columns: custom_question_answer_update_column[];
  where?: custom_question_answer_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "custom_question"
 */
export interface custom_question_arr_rel_insert_input {
  data: custom_question_insert_input[];
  on_conflict?: custom_question_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question". All fields are combined with a logical 'AND'.
 */
export interface custom_question_bool_exp {
  _and?: custom_question_bool_exp[] | null;
  _not?: custom_question_bool_exp | null;
  _or?: custom_question_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_bool_exp | null;
  custom_question_answer_bank_relationships_aggregate?: custom_question_answer_bank_relationship_aggregate_bool_exp | null;
  custom_question_answers?: custom_question_answer_bool_exp | null;
  custom_question_answers_aggregate?: custom_question_answer_aggregate_bool_exp | null;
  custom_question_relationships?: custom_question_relationship_bool_exp | null;
  custom_question_relationships_aggregate?: custom_question_relationship_aggregate_bool_exp | null;
  custom_question_type?: custom_question_type_bool_exp | null;
  custom_question_type_id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "custom_question"
 */
export interface custom_question_insert_input {
  created_at?: timestamptz | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_arr_rel_insert_input | null;
  custom_question_answers?: custom_question_answer_arr_rel_insert_input | null;
  custom_question_relationships?: custom_question_relationship_arr_rel_insert_input | null;
  custom_question_type?: custom_question_type_obj_rel_insert_input | null;
  custom_question_type_id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "custom_question"
 */
export interface custom_question_obj_rel_insert_input {
  data: custom_question_insert_input;
  on_conflict?: custom_question_on_conflict | null;
}

/**
 * on_conflict condition type for table "custom_question"
 */
export interface custom_question_on_conflict {
  constraint: custom_question_constraint;
  update_columns: custom_question_update_column[];
  where?: custom_question_bool_exp | null;
}

export interface custom_question_relationship_aggregate_bool_exp {
  count?: custom_question_relationship_aggregate_bool_exp_count | null;
}

export interface custom_question_relationship_aggregate_bool_exp_count {
  arguments?: custom_question_relationship_select_column[] | null;
  distinct?: boolean | null;
  filter?: custom_question_relationship_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "custom_question_relationship"
 */
export interface custom_question_relationship_arr_rel_insert_input {
  data: custom_question_relationship_insert_input[];
  on_conflict?: custom_question_relationship_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question_relationship". All fields are combined with a logical 'AND'.
 */
export interface custom_question_relationship_bool_exp {
  _and?: custom_question_relationship_bool_exp[] | null;
  _not?: custom_question_relationship_bool_exp | null;
  _or?: custom_question_relationship_bool_exp[] | null;
  cloned_question?: question_bool_exp | null;
  cloned_question_id?: uuid_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question?: custom_question_bool_exp | null;
  custom_question_id?: uuid_comparison_exp | null;
  custom_question_type_field?: custom_question_type_field_bool_exp | null;
  custom_question_type_field_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "custom_question_relationship"
 */
export interface custom_question_relationship_insert_input {
  cloned_question?: question_obj_rel_insert_input | null;
  cloned_question_id?: uuid | null;
  created_at?: timestamptz | null;
  custom_question?: custom_question_obj_rel_insert_input | null;
  custom_question_id?: uuid | null;
  custom_question_type_field?: custom_question_type_field_obj_rel_insert_input | null;
  custom_question_type_field_id?: uuid | null;
  id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "custom_question_relationship"
 */
export interface custom_question_relationship_obj_rel_insert_input {
  data: custom_question_relationship_insert_input;
  on_conflict?: custom_question_relationship_on_conflict | null;
}

/**
 * on_conflict condition type for table "custom_question_relationship"
 */
export interface custom_question_relationship_on_conflict {
  constraint: custom_question_relationship_constraint;
  update_columns: custom_question_relationship_update_column[];
  where?: custom_question_relationship_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question_type". All fields are combined with a logical 'AND'.
 */
export interface custom_question_type_bool_exp {
  _and?: custom_question_type_bool_exp[] | null;
  _not?: custom_question_type_bool_exp | null;
  _or?: custom_question_type_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_type_fields?: custom_question_type_field_bool_exp | null;
  custom_question_type_fields_aggregate?: custom_question_type_field_aggregate_bool_exp | null;
  custom_questions?: custom_question_bool_exp | null;
  custom_questions_aggregate?: custom_question_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface custom_question_type_field_aggregate_bool_exp {
  count?: custom_question_type_field_aggregate_bool_exp_count | null;
}

export interface custom_question_type_field_aggregate_bool_exp_count {
  arguments?: custom_question_type_field_select_column[] | null;
  distinct?: boolean | null;
  filter?: custom_question_type_field_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "custom_question_type_field"
 */
export interface custom_question_type_field_arr_rel_insert_input {
  data: custom_question_type_field_insert_input[];
  on_conflict?: custom_question_type_field_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "custom_question_type_field". All fields are combined with a logical 'AND'.
 */
export interface custom_question_type_field_bool_exp {
  _and?: custom_question_type_field_bool_exp[] | null;
  _not?: custom_question_type_field_bool_exp | null;
  _or?: custom_question_type_field_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_type?: custom_question_type_bool_exp | null;
  custom_question_type_id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "custom_question_type_field"
 */
export interface custom_question_type_field_insert_input {
  created_at?: timestamptz | null;
  custom_question_type?: custom_question_type_obj_rel_insert_input | null;
  custom_question_type_id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "custom_question_type_field"
 */
export interface custom_question_type_field_obj_rel_insert_input {
  data: custom_question_type_field_insert_input;
  on_conflict?: custom_question_type_field_on_conflict | null;
}

/**
 * on_conflict condition type for table "custom_question_type_field"
 */
export interface custom_question_type_field_on_conflict {
  constraint: custom_question_type_field_constraint;
  update_columns: custom_question_type_field_update_column[];
  where?: custom_question_type_field_bool_exp | null;
}

/**
 * input type for inserting data into table "custom_question_type"
 */
export interface custom_question_type_insert_input {
  created_at?: timestamptz | null;
  custom_question_type_fields?: custom_question_type_field_arr_rel_insert_input | null;
  custom_questions?: custom_question_arr_rel_insert_input | null;
  id?: uuid | null;
  key?: string | null;
  name?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "custom_question_type"
 */
export interface custom_question_type_obj_rel_insert_input {
  data: custom_question_type_insert_input;
  on_conflict?: custom_question_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "custom_question_type"
 */
export interface custom_question_type_on_conflict {
  constraint: custom_question_type_constraint;
  update_columns: custom_question_type_update_column[];
  where?: custom_question_type_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: date | null;
  _gt?: date | null;
  _gte?: date | null;
  _in?: date[] | null;
  _is_null?: boolean | null;
  _lt?: date | null;
  _lte?: date | null;
  _neq?: date | null;
  _nin?: date[] | null;
}

/**
 * Boolean expression to filter rows from the table "disclaimer_section". All fields are combined with a logical 'AND'.
 */
export interface disclaimer_section_bool_exp {
  _and?: disclaimer_section_bool_exp[] | null;
  _not?: disclaimer_section_bool_exp | null;
  _or?: disclaimer_section_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  disclaimer?: String_comparison_exp | null;
  form_disclaimers?: form_disclaimer_bool_exp | null;
  form_disclaimers_aggregate?: form_disclaimer_aggregate_bool_exp | null;
  form_template_section?: form_template_section_bool_exp | null;
  form_template_section_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "disclaimer_section"
 */
export interface disclaimer_section_insert_input {
  created_at?: timestamptz | null;
  disclaimer?: string | null;
  form_disclaimers?: form_disclaimer_arr_rel_insert_input | null;
  form_template_section?: form_template_section_obj_rel_insert_input | null;
  form_template_section_id?: uuid | null;
  id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "disclaimer_section"
 */
export interface disclaimer_section_obj_rel_insert_input {
  data: disclaimer_section_insert_input;
  on_conflict?: disclaimer_section_on_conflict | null;
}

/**
 * on_conflict condition type for table "disclaimer_section"
 */
export interface disclaimer_section_on_conflict {
  constraint: disclaimer_section_constraint;
  update_columns: disclaimer_section_update_column[];
  where?: disclaimer_section_bool_exp | null;
}

/**
 * Ordering options when selecting data from "disclaimer_section".
 */
export interface disclaimer_section_order_by {
  created_at?: order_by | null;
  disclaimer?: order_by | null;
  form_disclaimers_aggregate?: form_disclaimer_aggregate_order_by | null;
  form_template_section?: form_template_section_order_by | null;
  form_template_section_id?: order_by | null;
  id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "disclaimer_section"
 */
export interface disclaimer_section_set_input {
  created_at?: timestamptz | null;
  disclaimer?: string | null;
  form_template_section_id?: uuid | null;
  id?: uuid | null;
  updated_at?: timestamptz | null;
}

export interface disclaimer_section_updates {
  _set?: disclaimer_section_set_input | null;
  where: disclaimer_section_bool_exp;
}

export interface document_metadata_aggregate_bool_exp {
  count?: document_metadata_aggregate_bool_exp_count | null;
}

export interface document_metadata_aggregate_bool_exp_count {
  arguments?: document_metadata_select_column[] | null;
  distinct?: boolean | null;
  filter?: document_metadata_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "document_metadata"
 */
export interface document_metadata_arr_rel_insert_input {
  data: document_metadata_insert_input[];
  on_conflict?: document_metadata_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "document_metadata". All fields are combined with a logical 'AND'.
 */
export interface document_metadata_bool_exp {
  _and?: document_metadata_bool_exp[] | null;
  _not?: document_metadata_bool_exp | null;
  _or?: document_metadata_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  document_id?: uuid_comparison_exp | null;
  document_type?: document_type_enum_comparison_exp | null;
  filename?: String_comparison_exp | null;
  form_answer?: form_answer_bool_exp | null;
  form_answer_id?: uuid_comparison_exp | null;
  owner_user?: user_bool_exp | null;
  owner_user_id?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "document_metadata"
 */
export interface document_metadata_insert_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  document_id?: uuid | null;
  document_type?: document_type_enum | null;
  filename?: string | null;
  form_answer?: form_answer_obj_rel_insert_input | null;
  form_answer_id?: uuid | null;
  owner_user?: user_obj_rel_insert_input | null;
  owner_user_id?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "document_metadata"
 */
export interface document_metadata_on_conflict {
  constraint: document_metadata_constraint;
  update_columns: document_metadata_update_column[];
  where?: document_metadata_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "document_type_enum". All fields are combined with logical 'AND'.
 */
export interface document_type_enum_comparison_exp {
  _eq?: document_type_enum | null;
  _in?: document_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: document_type_enum | null;
  _nin?: document_type_enum[] | null;
}

export interface eligibility_question_school_aggregate_bool_exp {
  bool_and?: eligibility_question_school_aggregate_bool_exp_bool_and | null;
  bool_or?: eligibility_question_school_aggregate_bool_exp_bool_or | null;
  count?: eligibility_question_school_aggregate_bool_exp_count | null;
}

export interface eligibility_question_school_aggregate_bool_exp_bool_and {
  arguments: eligibility_question_school_select_column_eligibility_question_school_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: eligibility_question_school_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface eligibility_question_school_aggregate_bool_exp_bool_or {
  arguments: eligibility_question_school_select_column_eligibility_question_school_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: eligibility_question_school_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface eligibility_question_school_aggregate_bool_exp_count {
  arguments?: eligibility_question_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: eligibility_question_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "eligibility_question_school"
 */
export interface eligibility_question_school_aggregate_order_by {
  count?: order_by | null;
  max?: eligibility_question_school_max_order_by | null;
  min?: eligibility_question_school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "eligibility_question_school"
 */
export interface eligibility_question_school_arr_rel_insert_input {
  data: eligibility_question_school_insert_input[];
  on_conflict?: eligibility_question_school_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "eligibility_question_school". All fields are combined with a logical 'AND'.
 */
export interface eligibility_question_school_bool_exp {
  _and?: eligibility_question_school_bool_exp[] | null;
  _not?: eligibility_question_school_bool_exp | null;
  _or?: eligibility_question_school_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form_question_option?: form_question_option_bool_exp | null;
  form_question_option_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  is_eligible?: Boolean_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "eligibility_question_school"
 */
export interface eligibility_question_school_insert_input {
  created_at?: timestamptz | null;
  form_question_option?: form_question_option_obj_rel_insert_input | null;
  form_question_option_id?: uuid | null;
  id?: uuid | null;
  is_eligible?: boolean | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "eligibility_question_school"
 */
export interface eligibility_question_school_max_order_by {
  created_at?: order_by | null;
  form_question_option_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "eligibility_question_school"
 */
export interface eligibility_question_school_min_order_by {
  created_at?: order_by | null;
  form_question_option_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "eligibility_question_school"
 */
export interface eligibility_question_school_on_conflict {
  constraint: eligibility_question_school_constraint;
  update_columns: eligibility_question_school_update_column[];
  where?: eligibility_question_school_bool_exp | null;
}

export interface enrollment_period_aggregate_bool_exp {
  bool_and?: enrollment_period_aggregate_bool_exp_bool_and | null;
  bool_or?: enrollment_period_aggregate_bool_exp_bool_or | null;
  count?: enrollment_period_aggregate_bool_exp_count | null;
}

export interface enrollment_period_aggregate_bool_exp_bool_and {
  arguments: enrollment_period_select_column_enrollment_period_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: enrollment_period_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface enrollment_period_aggregate_bool_exp_bool_or {
  arguments: enrollment_period_select_column_enrollment_period_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: enrollment_period_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface enrollment_period_aggregate_bool_exp_count {
  arguments?: enrollment_period_select_column[] | null;
  distinct?: boolean | null;
  filter?: enrollment_period_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "enrollment_period"
 */
export interface enrollment_period_aggregate_order_by {
  count?: order_by | null;
  max?: enrollment_period_max_order_by | null;
  min?: enrollment_period_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "enrollment_period"
 */
export interface enrollment_period_arr_rel_insert_input {
  data: enrollment_period_insert_input[];
  on_conflict?: enrollment_period_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "enrollment_period". All fields are combined with a logical 'AND'.
 */
export interface enrollment_period_bool_exp {
  _and?: enrollment_period_bool_exp[] | null;
  _not?: enrollment_period_bool_exp | null;
  _or?: enrollment_period_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  enrollment_period_tags?: enrollment_period_tag_bool_exp | null;
  enrollment_period_tags_aggregate?: enrollment_period_tag_aggregate_bool_exp | null;
  formTemplateById?: form_template_bool_exp | null;
  form_templates?: form_template_bool_exp | null;
  form_templates_aggregate?: form_template_aggregate_bool_exp | null;
  grades?: grade_bool_exp | null;
  grades_aggregate?: grade_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  priority_templates?: priority_template_bool_exp | null;
  priority_templates_aggregate?: priority_template_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "enrollment_period"
 */
export interface enrollment_period_insert_input {
  active?: boolean | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  enrollment_period_tags?: enrollment_period_tag_arr_rel_insert_input | null;
  formTemplateById?: form_template_obj_rel_insert_input | null;
  form_templates?: form_template_arr_rel_insert_input | null;
  grades?: grade_arr_rel_insert_input | null;
  id?: uuid | null;
  key?: string | null;
  name?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  priority_templates?: priority_template_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "enrollment_period"
 */
export interface enrollment_period_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "enrollment_period"
 */
export interface enrollment_period_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "enrollment_period"
 */
export interface enrollment_period_obj_rel_insert_input {
  data: enrollment_period_insert_input;
  on_conflict?: enrollment_period_on_conflict | null;
}

/**
 * on_conflict condition type for table "enrollment_period"
 */
export interface enrollment_period_on_conflict {
  constraint: enrollment_period_constraint;
  update_columns: enrollment_period_update_column[];
  where?: enrollment_period_bool_exp | null;
}

/**
 * Ordering options when selecting data from "enrollment_period".
 */
export interface enrollment_period_order_by {
  active?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_tags_aggregate?: enrollment_period_tag_aggregate_order_by | null;
  formTemplateById?: form_template_order_by | null;
  form_templates_aggregate?: form_template_aggregate_order_by | null;
  grades_aggregate?: grade_aggregate_order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  priority_templates_aggregate?: priority_template_aggregate_order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "enrollment_period"
 */
export interface enrollment_period_set_input {
  active?: boolean | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  id?: uuid | null;
  key?: string | null;
  name?: string | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

export interface enrollment_period_tag_aggregate_bool_exp {
  count?: enrollment_period_tag_aggregate_bool_exp_count | null;
}

export interface enrollment_period_tag_aggregate_bool_exp_count {
  arguments?: enrollment_period_tag_select_column[] | null;
  distinct?: boolean | null;
  filter?: enrollment_period_tag_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "enrollment_period_tag"
 */
export interface enrollment_period_tag_aggregate_order_by {
  count?: order_by | null;
  max?: enrollment_period_tag_max_order_by | null;
  min?: enrollment_period_tag_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "enrollment_period_tag"
 */
export interface enrollment_period_tag_arr_rel_insert_input {
  data: enrollment_period_tag_insert_input[];
  on_conflict?: enrollment_period_tag_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "enrollment_period_tag". All fields are combined with a logical 'AND'.
 */
export interface enrollment_period_tag_bool_exp {
  _and?: enrollment_period_tag_bool_exp[] | null;
  _not?: enrollment_period_tag_bool_exp | null;
  _or?: enrollment_period_tag_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  form_school_tags?: form_school_tag_bool_exp | null;
  form_school_tags_aggregate?: form_school_tag_aggregate_bool_exp | null;
  form_tags?: form_tag_bool_exp | null;
  form_tags_aggregate?: form_tag_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  last_used_at?: timestamptz_comparison_exp | null;
  name?: citext_comparison_exp | null;
  tag_group?: tag_group_bool_exp | null;
  tag_group_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "enrollment_period_tag"
 */
export interface enrollment_period_tag_insert_input {
  created_at?: timestamptz | null;
  description?: string | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  form_school_tags?: form_school_tag_arr_rel_insert_input | null;
  form_tags?: form_tag_arr_rel_insert_input | null;
  id?: uuid | null;
  last_used_at?: timestamptz | null;
  name?: citext | null;
  tag_group?: tag_group_obj_rel_insert_input | null;
  tag_group_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "enrollment_period_tag"
 */
export interface enrollment_period_tag_max_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  last_used_at?: order_by | null;
  name?: order_by | null;
  tag_group_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "enrollment_period_tag"
 */
export interface enrollment_period_tag_min_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  last_used_at?: order_by | null;
  name?: order_by | null;
  tag_group_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "enrollment_period_tag"
 */
export interface enrollment_period_tag_obj_rel_insert_input {
  data: enrollment_period_tag_insert_input;
  on_conflict?: enrollment_period_tag_on_conflict | null;
}

/**
 * on_conflict condition type for table "enrollment_period_tag"
 */
export interface enrollment_period_tag_on_conflict {
  constraint: enrollment_period_tag_constraint;
  update_columns: enrollment_period_tag_update_column[];
  where?: enrollment_period_tag_bool_exp | null;
}

export interface form_address_aggregate_bool_exp {
  count?: form_address_aggregate_bool_exp_count | null;
}

export interface form_address_aggregate_bool_exp_count {
  arguments?: form_address_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_address_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_address"
 */
export interface form_address_aggregate_order_by {
  count?: order_by | null;
  max?: form_address_max_order_by | null;
  min?: form_address_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_address"
 */
export interface form_address_arr_rel_insert_input {
  data: form_address_insert_input[];
  on_conflict?: form_address_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_address". All fields are combined with a logical 'AND'.
 */
export interface form_address_bool_exp {
  _and?: form_address_bool_exp[] | null;
  _not?: form_address_bool_exp | null;
  _or?: form_address_bool_exp[] | null;
  city?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_question?: form_question_bool_exp | null;
  id?: uuid_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
  state?: String_comparison_exp | null;
  street_address?: String_comparison_exp | null;
  street_address_line_2?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  zip_code?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_address"
 */
export interface form_address_insert_input {
  city?: string | null;
  created_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_question?: form_question_obj_rel_insert_input | null;
  id?: uuid | null;
  question_id?: uuid | null;
  state?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  zip_code?: string | null;
}

/**
 * order by max() on columns of table "form_address"
 */
export interface form_address_max_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * order by min() on columns of table "form_address"
 */
export interface form_address_min_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * on_conflict condition type for table "form_address"
 */
export interface form_address_on_conflict {
  constraint: form_address_constraint;
  update_columns: form_address_update_column[];
  where?: form_address_bool_exp | null;
}

export interface form_aggregate_bool_exp {
  bool_and?: form_aggregate_bool_exp_bool_and | null;
  bool_or?: form_aggregate_bool_exp_bool_or | null;
  count?: form_aggregate_bool_exp_count | null;
}

export interface form_aggregate_bool_exp_bool_and {
  arguments: form_select_column_form_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: form_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_aggregate_bool_exp_bool_or {
  arguments: form_select_column_form_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: form_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_aggregate_bool_exp_count {
  arguments?: form_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form"
 */
export interface form_aggregate_order_by {
  count?: order_by | null;
  max?: form_max_order_by | null;
  min?: form_min_order_by | null;
}

export interface form_answer_aggregate_bool_exp {
  count?: form_answer_aggregate_bool_exp_count | null;
}

export interface form_answer_aggregate_bool_exp_count {
  arguments?: form_answer_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_answer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_answer"
 */
export interface form_answer_aggregate_order_by {
  avg?: form_answer_avg_order_by | null;
  count?: order_by | null;
  max?: form_answer_max_order_by | null;
  min?: form_answer_min_order_by | null;
  stddev?: form_answer_stddev_order_by | null;
  stddev_pop?: form_answer_stddev_pop_order_by | null;
  stddev_samp?: form_answer_stddev_samp_order_by | null;
  sum?: form_answer_sum_order_by | null;
  var_pop?: form_answer_var_pop_order_by | null;
  var_samp?: form_answer_var_samp_order_by | null;
  variance?: form_answer_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_answer"
 */
export interface form_answer_arr_rel_insert_input {
  data: form_answer_insert_input[];
  on_conflict?: form_answer_on_conflict | null;
}

/**
 * order by avg() on columns of table "form_answer"
 */
export interface form_answer_avg_order_by {
  number_answer?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_answer". All fields are combined with a logical 'AND'.
 */
export interface form_answer_bool_exp {
  _and?: form_answer_bool_exp[] | null;
  _not?: form_answer_bool_exp | null;
  _or?: form_answer_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_answer_bank_relationship?: custom_question_answer_bank_relationship_bool_exp | null;
  custom_question_type_relationship?: custom_question_relationship_bool_exp | null;
  date_answer?: date_comparison_exp | null;
  document_metadata?: document_metadata_bool_exp | null;
  document_metadata_aggregate?: document_metadata_aggregate_bool_exp | null;
  form?: form_bool_exp | null;
  form_answer_options?: form_answer_option_bool_exp | null;
  form_answer_options_aggregate?: form_answer_option_aggregate_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_question?: form_question_bool_exp | null;
  form_verification_result?: form_answer_verification_result_bool_exp | null;
  free_text_answer?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  number_answer?: numeric_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_answer"
 */
export interface form_answer_insert_input {
  created_at?: timestamptz | null;
  custom_question_answer_bank_relationship?: custom_question_answer_bank_relationship_obj_rel_insert_input | null;
  custom_question_type_relationship?: custom_question_relationship_obj_rel_insert_input | null;
  date_answer?: date | null;
  document_metadata?: document_metadata_arr_rel_insert_input | null;
  form?: form_obj_rel_insert_input | null;
  form_answer_options?: form_answer_option_arr_rel_insert_input | null;
  form_id?: uuid | null;
  form_question?: form_question_obj_rel_insert_input | null;
  form_verification_result?: form_answer_verification_result_obj_rel_insert_input | null;
  free_text_answer?: string | null;
  id?: uuid | null;
  number_answer?: numeric | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_answer"
 */
export interface form_answer_max_order_by {
  created_at?: order_by | null;
  date_answer?: order_by | null;
  form_id?: order_by | null;
  free_text_answer?: order_by | null;
  id?: order_by | null;
  number_answer?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_answer"
 */
export interface form_answer_min_order_by {
  created_at?: order_by | null;
  date_answer?: order_by | null;
  form_id?: order_by | null;
  free_text_answer?: order_by | null;
  id?: order_by | null;
  number_answer?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_answer"
 */
export interface form_answer_obj_rel_insert_input {
  data: form_answer_insert_input;
  on_conflict?: form_answer_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_answer"
 */
export interface form_answer_on_conflict {
  constraint: form_answer_constraint;
  update_columns: form_answer_update_column[];
  where?: form_answer_bool_exp | null;
}

export interface form_answer_option_aggregate_bool_exp {
  count?: form_answer_option_aggregate_bool_exp_count | null;
}

export interface form_answer_option_aggregate_bool_exp_count {
  arguments?: form_answer_option_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_answer_option_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "form_answer_option"
 */
export interface form_answer_option_arr_rel_insert_input {
  data: form_answer_option_insert_input[];
  on_conflict?: form_answer_option_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_answer_option". All fields are combined with a logical 'AND'.
 */
export interface form_answer_option_bool_exp {
  _and?: form_answer_option_bool_exp[] | null;
  _not?: form_answer_option_bool_exp | null;
  _or?: form_answer_option_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form_answer?: form_answer_bool_exp | null;
  form_answer_id?: uuid_comparison_exp | null;
  form_question_option?: form_question_option_bool_exp | null;
  form_question_option_id?: uuid_comparison_exp | null;
  form_verification_result?: form_answer_verification_result_bool_exp | null;
  id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_answer_option"
 */
export interface form_answer_option_insert_input {
  created_at?: timestamptz | null;
  form_answer?: form_answer_obj_rel_insert_input | null;
  form_answer_id?: uuid | null;
  form_question_option?: form_question_option_obj_rel_insert_input | null;
  form_question_option_id?: uuid | null;
  form_verification_result?: form_answer_verification_result_obj_rel_insert_input | null;
  id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "form_answer_option"
 */
export interface form_answer_option_on_conflict {
  constraint: form_answer_option_constraint;
  update_columns: form_answer_option_update_column[];
  where?: form_answer_option_bool_exp | null;
}

/**
 * order by stddev() on columns of table "form_answer"
 */
export interface form_answer_stddev_order_by {
  number_answer?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "form_answer"
 */
export interface form_answer_stddev_pop_order_by {
  number_answer?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "form_answer"
 */
export interface form_answer_stddev_samp_order_by {
  number_answer?: order_by | null;
}

/**
 * order by sum() on columns of table "form_answer"
 */
export interface form_answer_sum_order_by {
  number_answer?: order_by | null;
}

/**
 * order by var_pop() on columns of table "form_answer"
 */
export interface form_answer_var_pop_order_by {
  number_answer?: order_by | null;
}

/**
 * order by var_samp() on columns of table "form_answer"
 */
export interface form_answer_var_samp_order_by {
  number_answer?: order_by | null;
}

/**
 * order by variance() on columns of table "form_answer"
 */
export interface form_answer_variance_order_by {
  number_answer?: order_by | null;
}

export interface form_answer_verification_result_aggregate_bool_exp {
  count?: form_answer_verification_result_aggregate_bool_exp_count | null;
}

export interface form_answer_verification_result_aggregate_bool_exp_count {
  arguments?: form_answer_verification_result_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_answer_verification_result_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "form_answer_verification_result"
 */
export interface form_answer_verification_result_arr_rel_insert_input {
  data: form_answer_verification_result_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "form_answer_verification_result". All fields are combined with a logical 'AND'.
 */
export interface form_answer_verification_result_bool_exp {
  _and?: form_answer_verification_result_bool_exp[] | null;
  _not?: form_answer_verification_result_bool_exp | null;
  _or?: form_answer_verification_result_bool_exp[] | null;
  form_answer?: form_answer_bool_exp | null;
  form_answer_id?: uuid_comparison_exp | null;
  form_verification_result_id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  verification_status?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_answer_verification_result"
 */
export interface form_answer_verification_result_insert_input {
  form_answer?: form_answer_obj_rel_insert_input | null;
  form_answer_id?: uuid | null;
  form_verification_result_id?: uuid | null;
  label?: string | null;
  verification_status?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_answer_verification_result"
 */
export interface form_answer_verification_result_obj_rel_insert_input {
  data: form_answer_verification_result_insert_input;
}

/**
 * input type for inserting array relation for remote table "form"
 */
export interface form_arr_rel_insert_input {
  data: form_insert_input[];
  on_conflict?: form_on_conflict | null;
}

export interface form_attending_school_aggregate_bool_exp {
  count?: form_attending_school_aggregate_bool_exp_count | null;
}

export interface form_attending_school_aggregate_bool_exp_count {
  arguments?: form_attending_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_attending_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_attending_school"
 */
export interface form_attending_school_aggregate_order_by {
  count?: order_by | null;
  max?: form_attending_school_max_order_by | null;
  min?: form_attending_school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_attending_school"
 */
export interface form_attending_school_arr_rel_insert_input {
  data: form_attending_school_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "form_attending_school". All fields are combined with a logical 'AND'.
 */
export interface form_attending_school_bool_exp {
  _and?: form_attending_school_bool_exp[] | null;
  _not?: form_attending_school_bool_exp | null;
  _or?: form_attending_school_bool_exp[] | null;
  attendance_status?: String_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  organization_id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_attending_school"
 */
export interface form_attending_school_insert_input {
  attendance_status?: string | null;
  deleted_at?: timestamptz | null;
  enrollment_period_id?: uuid | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_template_id?: uuid | null;
  organization_id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
}

/**
 * order by max() on columns of table "form_attending_school"
 */
export interface form_attending_school_max_order_by {
  attendance_status?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_id?: order_by | null;
  form_template_id?: order_by | null;
  organization_id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * order by min() on columns of table "form_attending_school"
 */
export interface form_attending_school_min_order_by {
  attendance_status?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_id?: order_by | null;
  form_template_id?: order_by | null;
  organization_id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_attending_school"
 */
export interface form_attending_school_obj_rel_insert_input {
  data: form_attending_school_insert_input;
}

/**
 * Ordering options when selecting data from "form_attending_school".
 */
export interface form_attending_school_order_by {
  attendance_status?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form?: form_order_by | null;
  form_id?: order_by | null;
  form_template_id?: order_by | null;
  organization_id?: order_by | null;
  person?: person_order_by | null;
  person_id?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form". All fields are combined with a logical 'AND'.
 */
export interface form_bool_exp {
  _and?: form_bool_exp[] | null;
  _not?: form_bool_exp | null;
  _or?: form_bool_exp[] | null;
  action_items?: action_item_bool_exp | null;
  action_items_aggregate?: action_item_aggregate_bool_exp | null;
  applicant_guardians?: applicant_guardian_bool_exp | null;
  applicant_guardians_aggregate?: applicant_guardian_aggregate_bool_exp | null;
  assigned_forms?: assigned_form_bool_exp | null;
  assigned_forms_aggregate?: assigned_form_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_bool_exp | null;
  custom_question_answer_bank_relationships_aggregate?: custom_question_answer_bank_relationship_aggregate_bool_exp | null;
  custom_question_answers?: custom_question_answer_bool_exp | null;
  custom_question_answers_aggregate?: custom_question_answer_aggregate_bool_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  form_addresses?: form_address_bool_exp | null;
  form_addresses_aggregate?: form_address_aggregate_bool_exp | null;
  form_answers?: form_answer_bool_exp | null;
  form_answers_aggregate?: form_answer_aggregate_bool_exp | null;
  form_attending_school?: form_attending_school_bool_exp | null;
  form_disclaimers?: form_disclaimer_bool_exp | null;
  form_disclaimers_aggregate?: form_disclaimer_aggregate_bool_exp | null;
  form_import_rows?: form_import_row_bool_exp | null;
  form_import_rows_aggregate?: form_import_row_aggregate_bool_exp | null;
  form_school_ranks?: form_school_rank_bool_exp | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_bool_exp | null;
  form_school_tags?: form_school_tag_bool_exp | null;
  form_school_tags_aggregate?: form_school_tag_aggregate_bool_exp | null;
  form_source?: form_source_bool_exp | null;
  form_status?: form_status_bool_exp | null;
  form_tags?: form_tag_bool_exp | null;
  form_tags_aggregate?: form_tag_aggregate_bool_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  form_tracking_date?: form_tracking_dates_bool_exp | null;
  form_verification_result_statuses?: form_verification_result_status_bool_exp | null;
  form_verification_result_statuses_aggregate?: form_verification_result_status_aggregate_bool_exp | null;
  form_verification_results?: form_verification_result_bool_exp | null;
  form_verification_results_aggregate?: form_verification_result_aggregate_bool_exp | null;
  grades_answers?: grades_answer_bool_exp | null;
  grades_answers_aggregate?: grades_answer_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  id_text?: String_comparison_exp | null;
  is_hidden_from_parent?: Boolean_comparison_exp | null;
  message_adhoc_forms?: message_adhoc_form_bool_exp | null;
  message_adhoc_forms_aggregate?: message_adhoc_form_aggregate_bool_exp | null;
  next_forms?: form_bool_exp | null;
  next_forms_aggregate?: form_aggregate_bool_exp | null;
  offers?: offer_bool_exp | null;
  offers_aggregate?: offer_aggregate_bool_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  previous_assigned_form?: assigned_form_bool_exp | null;
  previous_form?: form_bool_exp | null;
  previous_form_id?: uuid_comparison_exp | null;
  previous_offer?: offer_bool_exp | null;
  previous_offer_id?: uuid_comparison_exp | null;
  previous_waitlist?: waitlist_bool_exp | null;
  previous_waitlist_id?: uuid_comparison_exp | null;
  relevant_persons?: search_relevant_person_bool_exp | null;
  relevant_persons_aggregate?: search_relevant_person_aggregate_bool_exp | null;
  source?: form_source_enum_comparison_exp | null;
  status?: form_status_enum_comparison_exp | null;
  status_updated_at?: timestamptz_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
  submitted_before?: Boolean_comparison_exp | null;
  tags?: form_tag_bool_exp | null;
  tags_aggregate?: form_tag_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlists?: waitlist_bool_exp | null;
  waitlists_aggregate?: waitlist_aggregate_bool_exp | null;
}

export interface form_disclaimer_aggregate_bool_exp {
  count?: form_disclaimer_aggregate_bool_exp_count | null;
}

export interface form_disclaimer_aggregate_bool_exp_count {
  arguments?: form_disclaimer_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_disclaimer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_disclaimer"
 */
export interface form_disclaimer_aggregate_order_by {
  count?: order_by | null;
  max?: form_disclaimer_max_order_by | null;
  min?: form_disclaimer_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_disclaimer"
 */
export interface form_disclaimer_arr_rel_insert_input {
  data: form_disclaimer_insert_input[];
  on_conflict?: form_disclaimer_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_disclaimer". All fields are combined with a logical 'AND'.
 */
export interface form_disclaimer_bool_exp {
  _and?: form_disclaimer_bool_exp[] | null;
  _not?: form_disclaimer_bool_exp | null;
  _or?: form_disclaimer_bool_exp[] | null;
  disclaimer_section?: disclaimer_section_bool_exp | null;
  disclaimer_section_id?: uuid_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  signature?: String_comparison_exp | null;
  signed_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_disclaimer"
 */
export interface form_disclaimer_insert_input {
  disclaimer_section?: disclaimer_section_obj_rel_insert_input | null;
  disclaimer_section_id?: uuid | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  signature?: string | null;
  signed_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_disclaimer"
 */
export interface form_disclaimer_max_order_by {
  disclaimer_section_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  signature?: order_by | null;
  signed_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_disclaimer"
 */
export interface form_disclaimer_min_order_by {
  disclaimer_section_id?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  signature?: order_by | null;
  signed_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_disclaimer"
 */
export interface form_disclaimer_on_conflict {
  constraint: form_disclaimer_constraint;
  update_columns: form_disclaimer_update_column[];
  where?: form_disclaimer_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "form_import". All fields are combined with a logical 'AND'.
 */
export interface form_import_bool_exp {
  _and?: form_import_bool_exp[] | null;
  _not?: form_import_bool_exp | null;
  _or?: form_import_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  errors?: jsonb_comparison_exp | null;
  file_size_in_bytes?: Int_comparison_exp | null;
  filename?: String_comparison_exp | null;
  form_import_review_status?: form_import_review_status_bool_exp | null;
  form_import_rows?: form_import_row_bool_exp | null;
  form_import_rows_aggregate?: form_import_row_aggregate_bool_exp | null;
  form_import_status?: form_import_status_bool_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  owner?: String_comparison_exp | null;
  review_status?: form_import_review_status_enum_comparison_exp | null;
  status?: form_import_status_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  warnings?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_import"
 */
export interface form_import_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  errors?: jsonb | null;
  file_size_in_bytes?: number | null;
  filename?: string | null;
  form_import_review_status?: form_import_review_status_obj_rel_insert_input | null;
  form_import_rows?: form_import_row_arr_rel_insert_input | null;
  form_import_status?: form_import_status_obj_rel_insert_input | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  owner?: string | null;
  review_status?: form_import_review_status_enum | null;
  status?: form_import_status_enum | null;
  updated_at?: timestamptz | null;
  warnings?: jsonb | null;
}

/**
 * input type for inserting object relation for remote table "form_import"
 */
export interface form_import_obj_rel_insert_input {
  data: form_import_insert_input;
  on_conflict?: form_import_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_import"
 */
export interface form_import_on_conflict {
  constraint: form_import_constraint;
  update_columns: form_import_update_column[];
  where?: form_import_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "form_import_review_status". All fields are combined with a logical 'AND'.
 */
export interface form_import_review_status_bool_exp {
  _and?: form_import_review_status_bool_exp[] | null;
  _not?: form_import_review_status_bool_exp | null;
  _or?: form_import_review_status_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_import_review_status_enum". All fields are combined with logical 'AND'.
 */
export interface form_import_review_status_enum_comparison_exp {
  _eq?: form_import_review_status_enum | null;
  _in?: form_import_review_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_import_review_status_enum | null;
  _nin?: form_import_review_status_enum[] | null;
}

/**
 * input type for inserting data into table "form_import_review_status"
 */
export interface form_import_review_status_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_import_review_status"
 */
export interface form_import_review_status_obj_rel_insert_input {
  data: form_import_review_status_insert_input;
  on_conflict?: form_import_review_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_import_review_status"
 */
export interface form_import_review_status_on_conflict {
  constraint: form_import_review_status_constraint;
  update_columns: form_import_review_status_update_column[];
  where?: form_import_review_status_bool_exp | null;
}

export interface form_import_row_aggregate_bool_exp {
  count?: form_import_row_aggregate_bool_exp_count | null;
}

export interface form_import_row_aggregate_bool_exp_count {
  arguments?: form_import_row_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_import_row_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_import_row"
 */
export interface form_import_row_aggregate_order_by {
  avg?: form_import_row_avg_order_by | null;
  count?: order_by | null;
  max?: form_import_row_max_order_by | null;
  min?: form_import_row_min_order_by | null;
  stddev?: form_import_row_stddev_order_by | null;
  stddev_pop?: form_import_row_stddev_pop_order_by | null;
  stddev_samp?: form_import_row_stddev_samp_order_by | null;
  sum?: form_import_row_sum_order_by | null;
  var_pop?: form_import_row_var_pop_order_by | null;
  var_samp?: form_import_row_var_samp_order_by | null;
  variance?: form_import_row_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_import_row"
 */
export interface form_import_row_arr_rel_insert_input {
  data: form_import_row_insert_input[];
  on_conflict?: form_import_row_on_conflict | null;
}

/**
 * order by avg() on columns of table "form_import_row"
 */
export interface form_import_row_avg_order_by {
  row_number?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_import_row". All fields are combined with a logical 'AND'.
 */
export interface form_import_row_bool_exp {
  _and?: form_import_row_bool_exp[] | null;
  _not?: form_import_row_bool_exp | null;
  _or?: form_import_row_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  errors?: jsonb_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_import?: form_import_bool_exp | null;
  form_import_id?: uuid_comparison_exp | null;
  form_import_row_status?: form_import_row_status_bool_exp | null;
  id?: uuid_comparison_exp | null;
  row_number?: Int_comparison_exp | null;
  status?: form_import_row_status_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  warnings?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_import_row"
 */
export interface form_import_row_insert_input {
  created_at?: timestamptz | null;
  errors?: jsonb | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_import?: form_import_obj_rel_insert_input | null;
  form_import_id?: uuid | null;
  form_import_row_status?: form_import_row_status_obj_rel_insert_input | null;
  id?: uuid | null;
  row_number?: number | null;
  status?: form_import_row_status_enum | null;
  updated_at?: timestamptz | null;
  warnings?: jsonb | null;
}

/**
 * order by max() on columns of table "form_import_row"
 */
export interface form_import_row_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  form_import_id?: order_by | null;
  id?: order_by | null;
  row_number?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_import_row"
 */
export interface form_import_row_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  form_import_id?: order_by | null;
  id?: order_by | null;
  row_number?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_import_row"
 */
export interface form_import_row_on_conflict {
  constraint: form_import_row_constraint;
  update_columns: form_import_row_update_column[];
  where?: form_import_row_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "form_import_row_status". All fields are combined with a logical 'AND'.
 */
export interface form_import_row_status_bool_exp {
  _and?: form_import_row_status_bool_exp[] | null;
  _not?: form_import_row_status_bool_exp | null;
  _or?: form_import_row_status_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_import_row_status_enum". All fields are combined with logical 'AND'.
 */
export interface form_import_row_status_enum_comparison_exp {
  _eq?: form_import_row_status_enum | null;
  _in?: form_import_row_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_import_row_status_enum | null;
  _nin?: form_import_row_status_enum[] | null;
}

/**
 * input type for inserting data into table "form_import_row_status"
 */
export interface form_import_row_status_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_import_row_status"
 */
export interface form_import_row_status_obj_rel_insert_input {
  data: form_import_row_status_insert_input;
  on_conflict?: form_import_row_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_import_row_status"
 */
export interface form_import_row_status_on_conflict {
  constraint: form_import_row_status_constraint;
  update_columns: form_import_row_status_update_column[];
  where?: form_import_row_status_bool_exp | null;
}

/**
 * order by stddev() on columns of table "form_import_row"
 */
export interface form_import_row_stddev_order_by {
  row_number?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "form_import_row"
 */
export interface form_import_row_stddev_pop_order_by {
  row_number?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "form_import_row"
 */
export interface form_import_row_stddev_samp_order_by {
  row_number?: order_by | null;
}

/**
 * order by sum() on columns of table "form_import_row"
 */
export interface form_import_row_sum_order_by {
  row_number?: order_by | null;
}

/**
 * order by var_pop() on columns of table "form_import_row"
 */
export interface form_import_row_var_pop_order_by {
  row_number?: order_by | null;
}

/**
 * order by var_samp() on columns of table "form_import_row"
 */
export interface form_import_row_var_samp_order_by {
  row_number?: order_by | null;
}

/**
 * order by variance() on columns of table "form_import_row"
 */
export interface form_import_row_variance_order_by {
  row_number?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_import_status". All fields are combined with a logical 'AND'.
 */
export interface form_import_status_bool_exp {
  _and?: form_import_status_bool_exp[] | null;
  _not?: form_import_status_bool_exp | null;
  _or?: form_import_status_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_import_status_enum". All fields are combined with logical 'AND'.
 */
export interface form_import_status_enum_comparison_exp {
  _eq?: form_import_status_enum | null;
  _in?: form_import_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_import_status_enum | null;
  _nin?: form_import_status_enum[] | null;
}

/**
 * input type for inserting data into table "form_import_status"
 */
export interface form_import_status_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_import_status"
 */
export interface form_import_status_obj_rel_insert_input {
  data: form_import_status_insert_input;
  on_conflict?: form_import_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_import_status"
 */
export interface form_import_status_on_conflict {
  constraint: form_import_status_constraint;
  update_columns: form_import_status_update_column[];
  where?: form_import_status_bool_exp | null;
}

/**
 * input type for inserting data into table "form"
 */
export interface form_insert_input {
  action_items?: action_item_arr_rel_insert_input | null;
  applicant_guardians?: applicant_guardian_arr_rel_insert_input | null;
  assigned_forms?: assigned_form_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_arr_rel_insert_input | null;
  custom_question_answers?: custom_question_answer_arr_rel_insert_input | null;
  deleted_at?: timestamptz | null;
  form_addresses?: form_address_arr_rel_insert_input | null;
  form_answers?: form_answer_arr_rel_insert_input | null;
  form_attending_school?: form_attending_school_obj_rel_insert_input | null;
  form_disclaimers?: form_disclaimer_arr_rel_insert_input | null;
  form_import_rows?: form_import_row_arr_rel_insert_input | null;
  form_school_ranks?: form_school_rank_arr_rel_insert_input | null;
  form_school_tags?: form_school_tag_arr_rel_insert_input | null;
  form_source?: form_source_obj_rel_insert_input | null;
  form_status?: form_status_obj_rel_insert_input | null;
  form_tags?: form_tag_arr_rel_insert_input | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  form_tracking_date?: form_tracking_dates_obj_rel_insert_input | null;
  form_verification_result_statuses?: form_verification_result_status_arr_rel_insert_input | null;
  form_verification_results?: form_verification_result_arr_rel_insert_input | null;
  grades_answers?: grades_answer_arr_rel_insert_input | null;
  id?: uuid | null;
  is_hidden_from_parent?: boolean | null;
  message_adhoc_forms?: message_adhoc_form_arr_rel_insert_input | null;
  next_forms?: form_arr_rel_insert_input | null;
  offers?: offer_arr_rel_insert_input | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  previous_assigned_form?: assigned_form_obj_rel_insert_input | null;
  previous_form?: form_obj_rel_insert_input | null;
  previous_form_id?: uuid | null;
  previous_offer?: offer_obj_rel_insert_input | null;
  previous_offer_id?: uuid | null;
  previous_waitlist?: waitlist_obj_rel_insert_input | null;
  previous_waitlist_id?: uuid | null;
  relevant_persons?: search_relevant_person_arr_rel_insert_input | null;
  source?: form_source_enum | null;
  status?: form_status_enum | null;
  status_updated_at?: timestamptz | null;
  submitted_at?: timestamptz | null;
  submitted_before?: boolean | null;
  tags?: form_tag_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
  waitlists?: waitlist_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "form"
 */
export interface form_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form"
 */
export interface form_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  person_id?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form"
 */
export interface form_obj_rel_insert_input {
  data: form_insert_input;
  on_conflict?: form_on_conflict | null;
}

/**
 * on_conflict condition type for table "form"
 */
export interface form_on_conflict {
  constraint: form_constraint;
  update_columns: form_update_column[];
  where?: form_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form".
 */
export interface form_order_by {
  action_items_aggregate?: action_item_aggregate_order_by | null;
  applicant_guardians_aggregate?: applicant_guardian_aggregate_order_by | null;
  assigned_forms_aggregate?: assigned_form_aggregate_order_by | null;
  created_at?: order_by | null;
  custom_question_answer_bank_relationships_aggregate?: custom_question_answer_bank_relationship_aggregate_order_by | null;
  custom_question_answers_aggregate?: custom_question_answer_aggregate_order_by | null;
  deleted_at?: order_by | null;
  form_addresses_aggregate?: form_address_aggregate_order_by | null;
  form_answers_aggregate?: form_answer_aggregate_order_by | null;
  form_attending_school?: form_attending_school_order_by | null;
  form_disclaimers_aggregate?: form_disclaimer_aggregate_order_by | null;
  form_import_rows_aggregate?: form_import_row_aggregate_order_by | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_order_by | null;
  form_school_tags_aggregate?: form_school_tag_aggregate_order_by | null;
  form_source?: form_source_order_by | null;
  form_status?: form_status_order_by | null;
  form_tags_aggregate?: form_tag_aggregate_order_by | null;
  form_template?: form_template_order_by | null;
  form_template_id?: order_by | null;
  form_tracking_date?: form_tracking_dates_order_by | null;
  form_verification_result_statuses_aggregate?: form_verification_result_status_aggregate_order_by | null;
  form_verification_results_aggregate?: form_verification_result_aggregate_order_by | null;
  grades_answers_aggregate?: grades_answer_aggregate_order_by | null;
  id?: order_by | null;
  id_text?: order_by | null;
  is_hidden_from_parent?: order_by | null;
  message_adhoc_forms_aggregate?: message_adhoc_form_aggregate_order_by | null;
  next_forms_aggregate?: form_aggregate_order_by | null;
  offers_aggregate?: offer_aggregate_order_by | null;
  person?: person_order_by | null;
  person_id?: order_by | null;
  previous_assigned_form?: assigned_form_order_by | null;
  previous_form?: form_order_by | null;
  previous_form_id?: order_by | null;
  previous_offer?: offer_order_by | null;
  previous_offer_id?: order_by | null;
  previous_waitlist?: waitlist_order_by | null;
  previous_waitlist_id?: order_by | null;
  relevant_persons_aggregate?: search_relevant_person_aggregate_order_by | null;
  source?: order_by | null;
  status?: order_by | null;
  status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  submitted_before?: order_by | null;
  tags_aggregate?: form_tag_aggregate_order_by | null;
  updated_at?: order_by | null;
  waitlists_aggregate?: waitlist_aggregate_order_by | null;
}

export interface form_question_aggregate_bool_exp {
  bool_and?: form_question_aggregate_bool_exp_bool_and | null;
  bool_or?: form_question_aggregate_bool_exp_bool_or | null;
  count?: form_question_aggregate_bool_exp_count | null;
}

export interface form_question_aggregate_bool_exp_bool_and {
  arguments: form_question_select_column_form_question_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: form_question_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_question_aggregate_bool_exp_bool_or {
  arguments: form_question_select_column_form_question_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: form_question_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_question_aggregate_bool_exp_count {
  arguments?: form_question_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_question_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * append existing jsonb value of filtered columns with new jsonb value
 */
export interface form_question_append_input {
  filters?: jsonb | null;
}

/**
 * input type for inserting array relation for remote table "form_question"
 */
export interface form_question_arr_rel_insert_input {
  data: form_question_insert_input[];
  on_conflict?: form_question_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_question". All fields are combined with a logical 'AND'.
 */
export interface form_question_bool_exp {
  _and?: form_question_bool_exp[] | null;
  _not?: form_question_bool_exp | null;
  _or?: form_question_bool_exp[] | null;
  category?: form_question_category_enum_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  filters?: jsonb_comparison_exp | null;
  form_addresses?: form_address_bool_exp | null;
  form_addresses_aggregate?: form_address_aggregate_bool_exp | null;
  form_answers?: form_answer_bool_exp | null;
  form_answers_aggregate?: form_answer_aggregate_bool_exp | null;
  form_question_category?: form_question_category_bool_exp | null;
  form_question_options?: form_question_option_bool_exp | null;
  form_question_options_aggregate?: form_question_option_aggregate_bool_exp | null;
  form_verification?: form_verification_bool_exp | null;
  form_verification_id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  requires_verification?: Boolean_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "form_question_category". All fields are combined with a logical 'AND'.
 */
export interface form_question_category_bool_exp {
  _and?: form_question_category_bool_exp[] | null;
  _not?: form_question_category_bool_exp | null;
  _or?: form_question_category_bool_exp[] | null;
  form_questions?: form_question_bool_exp | null;
  form_questions_aggregate?: form_question_aggregate_bool_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_question_category_enum". All fields are combined with logical 'AND'.
 */
export interface form_question_category_enum_comparison_exp {
  _eq?: form_question_category_enum | null;
  _in?: form_question_category_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_question_category_enum | null;
  _nin?: form_question_category_enum[] | null;
}

/**
 * input type for inserting data into table "form_question_category"
 */
export interface form_question_category_insert_input {
  form_questions?: form_question_arr_rel_insert_input | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_question_category"
 */
export interface form_question_category_obj_rel_insert_input {
  data: form_question_category_insert_input;
  on_conflict?: form_question_category_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_question_category"
 */
export interface form_question_category_on_conflict {
  constraint: form_question_category_constraint;
  update_columns: form_question_category_update_column[];
  where?: form_question_category_bool_exp | null;
}

/**
 * delete the field or element with specified path (for JSON arrays, negative integers count from the end)
 */
export interface form_question_delete_at_path_input {
  filters?: string[] | null;
}

/**
 * delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array
 */
export interface form_question_delete_elem_input {
  filters?: number | null;
}

/**
 * delete key/value pair or string element. key/value pairs are matched based on their key value
 */
export interface form_question_delete_key_input {
  filters?: string | null;
}

/**
 * input type for inserting data into table "form_question"
 */
export interface form_question_insert_input {
  category?: form_question_category_enum | null;
  created_at?: timestamptz | null;
  filters?: jsonb | null;
  form_addresses?: form_address_arr_rel_insert_input | null;
  form_answers?: form_answer_arr_rel_insert_input | null;
  form_question_category?: form_question_category_obj_rel_insert_input | null;
  form_question_options?: form_question_option_arr_rel_insert_input | null;
  form_verification?: form_verification_obj_rel_insert_input | null;
  form_verification_id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  requires_verification?: boolean | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "form_question"
 */
export interface form_question_obj_rel_insert_input {
  data: form_question_insert_input;
  on_conflict?: form_question_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_question"
 */
export interface form_question_on_conflict {
  constraint: form_question_constraint;
  update_columns: form_question_update_column[];
  where?: form_question_bool_exp | null;
}

export interface form_question_option_aggregate_bool_exp {
  bool_and?: form_question_option_aggregate_bool_exp_bool_and | null;
  bool_or?: form_question_option_aggregate_bool_exp_bool_or | null;
  count?: form_question_option_aggregate_bool_exp_count | null;
}

export interface form_question_option_aggregate_bool_exp_bool_and {
  arguments: form_question_option_select_column_form_question_option_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: form_question_option_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_question_option_aggregate_bool_exp_bool_or {
  arguments: form_question_option_select_column_form_question_option_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: form_question_option_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_question_option_aggregate_bool_exp_count {
  arguments?: form_question_option_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_question_option_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "form_question_option"
 */
export interface form_question_option_arr_rel_insert_input {
  data: form_question_option_insert_input[];
  on_conflict?: form_question_option_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_question_option". All fields are combined with a logical 'AND'.
 */
export interface form_question_option_bool_exp {
  _and?: form_question_option_bool_exp[] | null;
  _not?: form_question_option_bool_exp | null;
  _or?: form_question_option_bool_exp[] | null;
  additional_questions?: additional_question_bool_exp | null;
  additional_questions_aggregate?: additional_question_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  eligibility_schools?: eligibility_question_school_bool_exp | null;
  eligibility_schools_aggregate?: eligibility_question_school_aggregate_bool_exp | null;
  form_answer_options?: form_answer_option_bool_exp | null;
  form_answer_options_aggregate?: form_answer_option_aggregate_bool_exp | null;
  form_question?: form_question_bool_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
  requires_verification?: Boolean_comparison_exp | null;
  skip_verification?: Boolean_comparison_exp | null;
  translate_options?: Boolean_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for incrementing numeric columns in table "form_question_option"
 */
export interface form_question_option_inc_input {
  order?: number | null;
}

/**
 * input type for inserting data into table "form_question_option"
 */
export interface form_question_option_insert_input {
  additional_questions?: additional_question_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  eligibility_schools?: eligibility_question_school_arr_rel_insert_input | null;
  form_answer_options?: form_answer_option_arr_rel_insert_input | null;
  form_question?: form_question_obj_rel_insert_input | null;
  id?: uuid | null;
  label?: string | null;
  order?: number | null;
  question_id?: uuid | null;
  requires_verification?: boolean | null;
  skip_verification?: boolean | null;
  translate_options?: boolean | null;
  updated_at?: timestamptz | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_question_option"
 */
export interface form_question_option_obj_rel_insert_input {
  data: form_question_option_insert_input;
  on_conflict?: form_question_option_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_question_option"
 */
export interface form_question_option_on_conflict {
  constraint: form_question_option_constraint;
  update_columns: form_question_option_update_column[];
  where?: form_question_option_bool_exp | null;
}

/**
 * input type for updating data in table "form_question_option"
 */
export interface form_question_option_set_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  label?: string | null;
  order?: number | null;
  question_id?: uuid | null;
  requires_verification?: boolean | null;
  skip_verification?: boolean | null;
  translate_options?: boolean | null;
  updated_at?: timestamptz | null;
  value?: string | null;
}

export interface form_question_option_updates {
  _inc?: form_question_option_inc_input | null;
  _set?: form_question_option_set_input | null;
  where: form_question_option_bool_exp;
}

/**
 * prepend existing jsonb value of filtered columns with new jsonb value
 */
export interface form_question_prepend_input {
  filters?: jsonb | null;
}

export interface form_question_school_aggregate_bool_exp {
  count?: form_question_school_aggregate_bool_exp_count | null;
}

export interface form_question_school_aggregate_bool_exp_count {
  arguments?: form_question_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_question_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_question_school"
 */
export interface form_question_school_aggregate_order_by {
  count?: order_by | null;
  max?: form_question_school_max_order_by | null;
  min?: form_question_school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_question_school"
 */
export interface form_question_school_arr_rel_insert_input {
  data: form_question_school_insert_input[];
  on_conflict?: form_question_school_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_question_school". All fields are combined with a logical 'AND'.
 */
export interface form_question_school_bool_exp {
  _and?: form_question_school_bool_exp[] | null;
  _not?: form_question_school_bool_exp | null;
  _or?: form_question_school_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_question_school"
 */
export interface form_question_school_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_question_school"
 */
export interface form_question_school_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_question_school"
 */
export interface form_question_school_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_question_school"
 */
export interface form_question_school_on_conflict {
  constraint: form_question_school_constraint;
  update_columns: form_question_school_update_column[];
  where?: form_question_school_bool_exp | null;
}

/**
 * input type for updating data in table "form_question"
 */
export interface form_question_set_input {
  category?: form_question_category_enum | null;
  created_at?: timestamptz | null;
  filters?: jsonb | null;
  form_verification_id?: uuid | null;
  question_id?: uuid | null;
  requires_verification?: boolean | null;
  updated_at?: timestamptz | null;
}

export interface form_question_updates {
  _append?: form_question_append_input | null;
  _delete_at_path?: form_question_delete_at_path_input | null;
  _delete_elem?: form_question_delete_elem_input | null;
  _delete_key?: form_question_delete_key_input | null;
  _prepend?: form_question_prepend_input | null;
  _set?: form_question_set_input | null;
  where: form_question_bool_exp;
}

export interface form_school_grade_aggregate_bool_exp {
  count?: form_school_grade_aggregate_bool_exp_count | null;
}

export interface form_school_grade_aggregate_bool_exp_count {
  arguments?: form_school_grade_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_school_grade_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_school_grade"
 */
export interface form_school_grade_aggregate_order_by {
  count?: order_by | null;
  max?: form_school_grade_max_order_by | null;
  min?: form_school_grade_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_school_grade"
 */
export interface form_school_grade_arr_rel_insert_input {
  data: form_school_grade_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "form_school_grade". All fields are combined with a logical 'AND'.
 */
export interface form_school_grade_bool_exp {
  _and?: form_school_grade_bool_exp[] | null;
  _not?: form_school_grade_bool_exp | null;
  _or?: form_school_grade_bool_exp[] | null;
  bin_id?: uuid_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_school_rank?: form_school_rank_bool_exp | null;
  form_school_rank_id?: uuid_comparison_exp | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  person_bin?: person_bin_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_school_grade"
 */
export interface form_school_grade_insert_input {
  bin_id?: uuid | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_school_rank?: form_school_rank_obj_rel_insert_input | null;
  form_school_rank_id?: uuid | null;
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  person_bin?: person_bin_obj_rel_insert_input | null;
  person_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
}

/**
 * order by max() on columns of table "form_school_grade"
 */
export interface form_school_grade_max_order_by {
  bin_id?: order_by | null;
  form_id?: order_by | null;
  form_school_rank_id?: order_by | null;
  grade_id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * order by min() on columns of table "form_school_grade"
 */
export interface form_school_grade_min_order_by {
  bin_id?: order_by | null;
  form_id?: order_by | null;
  form_school_rank_id?: order_by | null;
  grade_id?: order_by | null;
  person_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_school_offer_status_history". All fields are combined with a logical 'AND'.
 */
export interface form_school_offer_status_history_bool_exp {
  _and?: form_school_offer_status_history_bool_exp[] | null;
  _not?: form_school_offer_status_history_bool_exp | null;
  _or?: form_school_offer_status_history_bool_exp[] | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_school_offer_status_history"
 */
export interface form_school_offer_status_history_insert_input {
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  submitted_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "form_school_offer_status_history"
 */
export interface form_school_offer_status_history_obj_rel_insert_input {
  data: form_school_offer_status_history_insert_input;
  on_conflict?: form_school_offer_status_history_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_school_offer_status_history"
 */
export interface form_school_offer_status_history_on_conflict {
  constraint: form_school_offer_status_history_constraint;
  update_columns: form_school_offer_status_history_update_column[];
  where?: form_school_offer_status_history_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_school_offer_status_history".
 */
export interface form_school_offer_status_history_order_by {
  form?: form_order_by | null;
  form_id?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  submitted_at?: order_by | null;
}

export interface form_school_rank_aggregate_bool_exp {
  count?: form_school_rank_aggregate_bool_exp_count | null;
}

export interface form_school_rank_aggregate_bool_exp_count {
  arguments?: form_school_rank_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_school_rank_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_school_rank"
 */
export interface form_school_rank_aggregate_order_by {
  avg?: form_school_rank_avg_order_by | null;
  count?: order_by | null;
  max?: form_school_rank_max_order_by | null;
  min?: form_school_rank_min_order_by | null;
  stddev?: form_school_rank_stddev_order_by | null;
  stddev_pop?: form_school_rank_stddev_pop_order_by | null;
  stddev_samp?: form_school_rank_stddev_samp_order_by | null;
  sum?: form_school_rank_sum_order_by | null;
  var_pop?: form_school_rank_var_pop_order_by | null;
  var_samp?: form_school_rank_var_samp_order_by | null;
  variance?: form_school_rank_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_school_rank"
 */
export interface form_school_rank_arr_rel_insert_input {
  data: form_school_rank_insert_input[];
  on_conflict?: form_school_rank_on_conflict | null;
}

/**
 * order by avg() on columns of table "form_school_rank"
 */
export interface form_school_rank_avg_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_school_rank". All fields are combined with a logical 'AND'.
 */
export interface form_school_rank_bool_exp {
  _and?: form_school_rank_bool_exp[] | null;
  _not?: form_school_rank_bool_exp | null;
  _or?: form_school_rank_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_school_grades?: form_school_grade_bool_exp | null;
  form_school_grades_aggregate?: form_school_grade_aggregate_bool_exp | null;
  form_school_offer_status_history?: form_school_offer_status_history_bool_exp | null;
  form_verifications_flattened?: form_verifications_flattened_bool_exp | null;
  id?: uuid_comparison_exp | null;
  lottery_order?: numeric_comparison_exp | null;
  offers?: offer_bool_exp | null;
  offers_aggregate?: offer_aggregate_bool_exp | null;
  rank?: Int_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  schools_ranking_section?: schools_ranking_section_bool_exp | null;
  schools_ranking_section_id?: uuid_comparison_exp | null;
  status?: form_school_rank_status_enum_comparison_exp | null;
  sub_status?: String_comparison_exp | null;
  tags?: form_school_tag_bool_exp | null;
  tags_aggregate?: form_school_tag_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlists?: waitlist_bool_exp | null;
  waitlists_aggregate?: waitlist_aggregate_bool_exp | null;
}

/**
 * input type for incrementing numeric columns in table "form_school_rank"
 */
export interface form_school_rank_inc_input {
  lottery_order?: numeric | null;
  rank?: number | null;
}

/**
 * input type for inserting data into table "form_school_rank"
 */
export interface form_school_rank_insert_input {
  created_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_school_grades?: form_school_grade_arr_rel_insert_input | null;
  form_school_offer_status_history?: form_school_offer_status_history_obj_rel_insert_input | null;
  form_verifications_flattened?: form_verifications_flattened_obj_rel_insert_input | null;
  id?: uuid | null;
  lottery_order?: numeric | null;
  offers?: offer_arr_rel_insert_input | null;
  rank?: number | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  schools_ranking_section?: schools_ranking_section_obj_rel_insert_input | null;
  schools_ranking_section_id?: uuid | null;
  status?: form_school_rank_status_enum | null;
  tags?: form_school_tag_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
  waitlists?: waitlist_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "form_school_rank"
 */
export interface form_school_rank_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  school_id?: order_by | null;
  schools_ranking_section_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_school_rank"
 */
export interface form_school_rank_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  school_id?: order_by | null;
  schools_ranking_section_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_school_rank"
 */
export interface form_school_rank_obj_rel_insert_input {
  data: form_school_rank_insert_input;
  on_conflict?: form_school_rank_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_school_rank"
 */
export interface form_school_rank_on_conflict {
  constraint: form_school_rank_constraint;
  update_columns: form_school_rank_update_column[];
  where?: form_school_rank_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_school_rank".
 */
export interface form_school_rank_order_by {
  created_at?: order_by | null;
  form?: form_order_by | null;
  form_id?: order_by | null;
  form_school_grades_aggregate?: form_school_grade_aggregate_order_by | null;
  form_school_offer_status_history?: form_school_offer_status_history_order_by | null;
  form_verifications_flattened?: form_verifications_flattened_order_by | null;
  id?: order_by | null;
  lottery_order?: order_by | null;
  offers_aggregate?: offer_aggregate_order_by | null;
  rank?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  schools_ranking_section?: schools_ranking_section_order_by | null;
  schools_ranking_section_id?: order_by | null;
  status?: order_by | null;
  sub_status?: order_by | null;
  tags_aggregate?: form_school_tag_aggregate_order_by | null;
  updated_at?: order_by | null;
  waitlists_aggregate?: waitlist_aggregate_order_by | null;
}

/**
 * input type for updating data in table "form_school_rank"
 */
export interface form_school_rank_set_input {
  created_at?: timestamptz | null;
  form_id?: uuid | null;
  id?: uuid | null;
  lottery_order?: numeric | null;
  rank?: number | null;
  school_id?: uuid | null;
  schools_ranking_section_id?: uuid | null;
  status?: form_school_rank_status_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to compare columns of type "form_school_rank_status_enum". All fields are combined with logical 'AND'.
 */
export interface form_school_rank_status_enum_comparison_exp {
  _eq?: form_school_rank_status_enum | null;
  _in?: form_school_rank_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_school_rank_status_enum | null;
  _nin?: form_school_rank_status_enum[] | null;
}

/**
 * order by stddev() on columns of table "form_school_rank"
 */
export interface form_school_rank_stddev_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "form_school_rank"
 */
export interface form_school_rank_stddev_pop_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "form_school_rank"
 */
export interface form_school_rank_stddev_samp_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * order by sum() on columns of table "form_school_rank"
 */
export interface form_school_rank_sum_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

export interface form_school_rank_updates {
  _inc?: form_school_rank_inc_input | null;
  _set?: form_school_rank_set_input | null;
  where: form_school_rank_bool_exp;
}

/**
 * order by var_pop() on columns of table "form_school_rank"
 */
export interface form_school_rank_var_pop_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * order by var_samp() on columns of table "form_school_rank"
 */
export interface form_school_rank_var_samp_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

/**
 * order by variance() on columns of table "form_school_rank"
 */
export interface form_school_rank_variance_order_by {
  lottery_order?: order_by | null;
  rank?: order_by | null;
}

export interface form_school_tag_aggregate_bool_exp {
  count?: form_school_tag_aggregate_bool_exp_count | null;
}

export interface form_school_tag_aggregate_bool_exp_count {
  arguments?: form_school_tag_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_school_tag_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_school_tag"
 */
export interface form_school_tag_aggregate_order_by {
  count?: order_by | null;
  max?: form_school_tag_max_order_by | null;
  min?: form_school_tag_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_school_tag"
 */
export interface form_school_tag_arr_rel_insert_input {
  data: form_school_tag_insert_input[];
  on_conflict?: form_school_tag_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_school_tag". All fields are combined with a logical 'AND'.
 */
export interface form_school_tag_bool_exp {
  _and?: form_school_tag_bool_exp[] | null;
  _not?: form_school_tag_bool_exp | null;
  _or?: form_school_tag_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period_tag?: enrollment_period_tag_bool_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  tag_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_school_tag"
 */
export interface form_school_tag_insert_input {
  created_at?: timestamptz | null;
  enrollment_period_tag?: enrollment_period_tag_obj_rel_insert_input | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  tag_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_school_tag"
 */
export interface form_school_tag_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  tag_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_school_tag"
 */
export interface form_school_tag_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  tag_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_school_tag"
 */
export interface form_school_tag_on_conflict {
  constraint: form_school_tag_constraint;
  update_columns: form_school_tag_update_column[];
  where?: form_school_tag_bool_exp | null;
}

/**
 * input type for updating data in table "form"
 */
export interface form_set_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  is_hidden_from_parent?: boolean | null;
  person_id?: uuid | null;
  previous_form_id?: uuid | null;
  previous_offer_id?: uuid | null;
  previous_waitlist_id?: uuid | null;
  source?: form_source_enum | null;
  status?: form_status_enum | null;
  status_updated_at?: timestamptz | null;
  submitted_at?: timestamptz | null;
  submitted_before?: boolean | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to filter rows from the table "form_source". All fields are combined with a logical 'AND'.
 */
export interface form_source_bool_exp {
  _and?: form_source_bool_exp[] | null;
  _not?: form_source_bool_exp | null;
  _or?: form_source_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_source_enum". All fields are combined with logical 'AND'.
 */
export interface form_source_enum_comparison_exp {
  _eq?: form_source_enum | null;
  _in?: form_source_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_source_enum | null;
  _nin?: form_source_enum[] | null;
}

/**
 * input type for inserting data into table "form_source"
 */
export interface form_source_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_source"
 */
export interface form_source_obj_rel_insert_input {
  data: form_source_insert_input;
  on_conflict?: form_source_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_source"
 */
export interface form_source_on_conflict {
  constraint: form_source_constraint;
  update_columns: form_source_update_column[];
  where?: form_source_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_source".
 */
export interface form_source_order_by {
  value?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_status". All fields are combined with a logical 'AND'.
 */
export interface form_status_bool_exp {
  _and?: form_status_bool_exp[] | null;
  _not?: form_status_bool_exp | null;
  _or?: form_status_bool_exp[] | null;
  forms?: form_bool_exp | null;
  forms_aggregate?: form_aggregate_bool_exp | null;
  value?: String_comparison_exp | null;
}

export interface form_status_description_aggregate_bool_exp {
  count?: form_status_description_aggregate_bool_exp_count | null;
}

export interface form_status_description_aggregate_bool_exp_count {
  arguments?: form_status_description_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_status_description_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_status_description"
 */
export interface form_status_description_aggregate_order_by {
  count?: order_by | null;
  max?: form_status_description_max_order_by | null;
  min?: form_status_description_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_status_description"
 */
export interface form_status_description_arr_rel_insert_input {
  data: form_status_description_insert_input[];
  on_conflict?: form_status_description_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_status_description". All fields are combined with a logical 'AND'.
 */
export interface form_status_description_bool_exp {
  _and?: form_status_description_bool_exp[] | null;
  _not?: form_status_description_bool_exp | null;
  _or?: form_status_description_bool_exp[] | null;
  description?: String_comparison_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  status?: form_status_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_status_description"
 */
export interface form_status_description_insert_input {
  description?: string | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  status?: form_status_enum | null;
}

/**
 * order by max() on columns of table "form_status_description"
 */
export interface form_status_description_max_order_by {
  description?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by min() on columns of table "form_status_description"
 */
export interface form_status_description_min_order_by {
  description?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
}

/**
 * on_conflict condition type for table "form_status_description"
 */
export interface form_status_description_on_conflict {
  constraint: form_status_description_constraint;
  update_columns: form_status_description_update_column[];
  where?: form_status_description_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_status_enum". All fields are combined with logical 'AND'.
 */
export interface form_status_enum_comparison_exp {
  _eq?: form_status_enum | null;
  _in?: form_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_status_enum | null;
  _nin?: form_status_enum[] | null;
}

/**
 * input type for inserting data into table "form_status"
 */
export interface form_status_insert_input {
  forms?: form_arr_rel_insert_input | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_status"
 */
export interface form_status_obj_rel_insert_input {
  data: form_status_insert_input;
  on_conflict?: form_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_status"
 */
export interface form_status_on_conflict {
  constraint: form_status_constraint;
  update_columns: form_status_update_column[];
  where?: form_status_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_status".
 */
export interface form_status_order_by {
  forms_aggregate?: form_aggregate_order_by | null;
  value?: order_by | null;
}

export interface form_tag_aggregate_bool_exp {
  count?: form_tag_aggregate_bool_exp_count | null;
}

export interface form_tag_aggregate_bool_exp_count {
  arguments?: form_tag_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_tag_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_tag"
 */
export interface form_tag_aggregate_order_by {
  count?: order_by | null;
  max?: form_tag_max_order_by | null;
  min?: form_tag_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_tag"
 */
export interface form_tag_arr_rel_insert_input {
  data: form_tag_insert_input[];
  on_conflict?: form_tag_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_tag". All fields are combined with a logical 'AND'.
 */
export interface form_tag_bool_exp {
  _and?: form_tag_bool_exp[] | null;
  _not?: form_tag_bool_exp | null;
  _or?: form_tag_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period_tag?: enrollment_period_tag_bool_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  tag_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_tag"
 */
export interface form_tag_insert_input {
  created_at?: timestamptz | null;
  enrollment_period_tag?: enrollment_period_tag_obj_rel_insert_input | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  tag_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_tag"
 */
export interface form_tag_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  tag_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_tag"
 */
export interface form_tag_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  id?: order_by | null;
  tag_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_tag"
 */
export interface form_tag_on_conflict {
  constraint: form_tag_constraint;
  update_columns: form_tag_update_column[];
  where?: form_tag_bool_exp | null;
}

export interface form_template_aggregate_bool_exp {
  bool_and?: form_template_aggregate_bool_exp_bool_and | null;
  bool_or?: form_template_aggregate_bool_exp_bool_or | null;
  count?: form_template_aggregate_bool_exp_count | null;
}

export interface form_template_aggregate_bool_exp_bool_and {
  arguments: form_template_select_column_form_template_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: form_template_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_template_aggregate_bool_exp_bool_or {
  arguments: form_template_select_column_form_template_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: form_template_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface form_template_aggregate_bool_exp_count {
  arguments?: form_template_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_template_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_template"
 */
export interface form_template_aggregate_order_by {
  count?: order_by | null;
  max?: form_template_max_order_by | null;
  min?: form_template_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_template"
 */
export interface form_template_arr_rel_insert_input {
  data: form_template_insert_input[];
  on_conflict?: form_template_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_template". All fields are combined with a logical 'AND'.
 */
export interface form_template_bool_exp {
  _and?: form_template_bool_exp[] | null;
  _not?: form_template_bool_exp | null;
  _or?: form_template_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  assigned_forms?: assigned_form_bool_exp | null;
  assigned_forms_aggregate?: assigned_form_aggregate_bool_exp | null;
  closed_at?: timestamptz_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  form_status_descriptions?: form_status_description_bool_exp | null;
  form_status_descriptions_aggregate?: form_status_description_aggregate_bool_exp | null;
  form_template_hints?: form_template_hint_bool_exp | null;
  form_template_hints_aggregate?: form_template_hint_aggregate_bool_exp | null;
  form_template_rule?: form_template_rule_bool_exp | null;
  form_template_rule_id?: uuid_comparison_exp | null;
  form_verifications?: form_verification_bool_exp | null;
  form_verifications_aggregate?: form_verification_aggregate_bool_exp | null;
  forms?: form_bool_exp | null;
  forms_aggregate?: form_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  locked?: Boolean_comparison_exp | null;
  lottery_offers_enabled?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  offer_start_at?: timestamptz_comparison_exp | null;
  open_at?: timestamptz_comparison_exp | null;
  reopen_at?: timestamptz_comparison_exp | null;
  sections?: form_template_section_bool_exp | null;
  sections_aggregate?: form_template_section_aggregate_bool_exp | null;
  status_rules?: status_rule_bool_exp | null;
  status_rules_aggregate?: status_rule_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface form_template_hint_aggregate_bool_exp {
  count?: form_template_hint_aggregate_bool_exp_count | null;
}

export interface form_template_hint_aggregate_bool_exp_count {
  arguments?: form_template_hint_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_template_hint_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_template_hint"
 */
export interface form_template_hint_aggregate_order_by {
  count?: order_by | null;
  max?: form_template_hint_max_order_by | null;
  min?: form_template_hint_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_template_hint"
 */
export interface form_template_hint_arr_rel_insert_input {
  data: form_template_hint_insert_input[];
  on_conflict?: form_template_hint_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_template_hint". All fields are combined with a logical 'AND'.
 */
export interface form_template_hint_bool_exp {
  _and?: form_template_hint_bool_exp[] | null;
  _not?: form_template_hint_bool_exp | null;
  _or?: form_template_hint_bool_exp[] | null;
  body?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  form_status?: form_status_enum_comparison_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  image_type?: message_image_type_enum_comparison_exp | null;
  offer_status?: offer_status_enum_comparison_exp | null;
  status_type?: status_rule_type_enum_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlist_status?: waitlist_status_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_template_hint"
 */
export interface form_template_hint_insert_input {
  body?: string | null;
  created_at?: timestamptz | null;
  form_status?: form_status_enum | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  image_type?: message_image_type_enum | null;
  offer_status?: offer_status_enum | null;
  status_type?: status_rule_type_enum | null;
  title?: string | null;
  updated_at?: timestamptz | null;
  waitlist_status?: waitlist_status_enum | null;
}

/**
 * order by max() on columns of table "form_template_hint"
 */
export interface form_template_hint_max_order_by {
  body?: order_by | null;
  created_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_template_hint"
 */
export interface form_template_hint_min_order_by {
  body?: order_by | null;
  created_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "form_template_hint"
 */
export interface form_template_hint_on_conflict {
  constraint: form_template_hint_constraint;
  update_columns: form_template_hint_update_column[];
  where?: form_template_hint_bool_exp | null;
}

/**
 * input type for inserting data into table "form_template"
 */
export interface form_template_insert_input {
  active?: boolean | null;
  assigned_forms?: assigned_form_arr_rel_insert_input | null;
  closed_at?: timestamptz | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  description?: string | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  form_status_descriptions?: form_status_description_arr_rel_insert_input | null;
  form_template_hints?: form_template_hint_arr_rel_insert_input | null;
  form_template_rule?: form_template_rule_obj_rel_insert_input | null;
  form_template_rule_id?: uuid | null;
  form_verifications?: form_verification_arr_rel_insert_input | null;
  forms?: form_arr_rel_insert_input | null;
  id?: uuid | null;
  key?: string | null;
  locked?: boolean | null;
  lottery_offers_enabled?: boolean | null;
  name?: string | null;
  offer_start_at?: timestamptz | null;
  open_at?: timestamptz | null;
  reopen_at?: timestamptz | null;
  sections?: form_template_section_arr_rel_insert_input | null;
  status_rules?: status_rule_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_template"
 */
export interface form_template_max_order_by {
  closed_at?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_template_rule_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  offer_start_at?: order_by | null;
  open_at?: order_by | null;
  reopen_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_template"
 */
export interface form_template_min_order_by {
  closed_at?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  description?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_template_rule_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  offer_start_at?: order_by | null;
  open_at?: order_by | null;
  reopen_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_template"
 */
export interface form_template_obj_rel_insert_input {
  data: form_template_insert_input;
  on_conflict?: form_template_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_template"
 */
export interface form_template_on_conflict {
  constraint: form_template_constraint;
  update_columns: form_template_update_column[];
  where?: form_template_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_template".
 */
export interface form_template_order_by {
  active?: order_by | null;
  assigned_forms_aggregate?: assigned_form_aggregate_order_by | null;
  closed_at?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  description?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  form_status_descriptions_aggregate?: form_status_description_aggregate_order_by | null;
  form_template_hints_aggregate?: form_template_hint_aggregate_order_by | null;
  form_template_rule?: form_template_rule_order_by | null;
  form_template_rule_id?: order_by | null;
  form_verifications_aggregate?: form_verification_aggregate_order_by | null;
  forms_aggregate?: form_aggregate_order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  locked?: order_by | null;
  lottery_offers_enabled?: order_by | null;
  name?: order_by | null;
  offer_start_at?: order_by | null;
  open_at?: order_by | null;
  reopen_at?: order_by | null;
  sections_aggregate?: form_template_section_aggregate_order_by | null;
  status_rules_aggregate?: status_rule_aggregate_order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_template_rule". All fields are combined with a logical 'AND'.
 */
export interface form_template_rule_bool_exp {
  _and?: form_template_rule_bool_exp[] | null;
  _not?: form_template_rule_bool_exp | null;
  _or?: form_template_rule_bool_exp[] | null;
  form_template_rule?: form_template_rule_bool_exp | null;
  form_template_rule_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  operator?: operator_enum_comparison_exp | null;
  operatorByOperator?: operator_bool_exp | null;
  rules?: rule_bool_exp | null;
  rules_aggregate?: rule_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "form_template_rule"
 */
export interface form_template_rule_insert_input {
  form_template_rule?: form_template_rule_obj_rel_insert_input | null;
  form_template_rule_id?: uuid | null;
  id?: uuid | null;
  operator?: operator_enum | null;
  operatorByOperator?: operator_obj_rel_insert_input | null;
  rules?: rule_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "form_template_rule"
 */
export interface form_template_rule_obj_rel_insert_input {
  data: form_template_rule_insert_input;
  on_conflict?: form_template_rule_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_template_rule"
 */
export interface form_template_rule_on_conflict {
  constraint: form_template_rule_constraint;
  update_columns: form_template_rule_update_column[];
  where?: form_template_rule_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_template_rule".
 */
export interface form_template_rule_order_by {
  form_template_rule?: form_template_rule_order_by | null;
  form_template_rule_id?: order_by | null;
  id?: order_by | null;
  operator?: order_by | null;
  operatorByOperator?: operator_order_by | null;
  rules_aggregate?: rule_aggregate_order_by | null;
}

export interface form_template_section_aggregate_bool_exp {
  count?: form_template_section_aggregate_bool_exp_count | null;
}

export interface form_template_section_aggregate_bool_exp_count {
  arguments?: form_template_section_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_template_section_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_template_section"
 */
export interface form_template_section_aggregate_order_by {
  avg?: form_template_section_avg_order_by | null;
  count?: order_by | null;
  max?: form_template_section_max_order_by | null;
  min?: form_template_section_min_order_by | null;
  stddev?: form_template_section_stddev_order_by | null;
  stddev_pop?: form_template_section_stddev_pop_order_by | null;
  stddev_samp?: form_template_section_stddev_samp_order_by | null;
  sum?: form_template_section_sum_order_by | null;
  var_pop?: form_template_section_var_pop_order_by | null;
  var_samp?: form_template_section_var_samp_order_by | null;
  variance?: form_template_section_variance_order_by | null;
}

/**
 * append existing jsonb value of filtered columns with new jsonb value
 */
export interface form_template_section_append_input {
  family_editable_statuses?: jsonb | null;
  filters?: jsonb | null;
}

/**
 * input type for inserting array relation for remote table "form_template_section"
 */
export interface form_template_section_arr_rel_insert_input {
  data: form_template_section_insert_input[];
  on_conflict?: form_template_section_on_conflict | null;
}

/**
 * order by avg() on columns of table "form_template_section"
 */
export interface form_template_section_avg_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_template_section". All fields are combined with a logical 'AND'.
 */
export interface form_template_section_bool_exp {
  _and?: form_template_section_bool_exp[] | null;
  _not?: form_template_section_bool_exp | null;
  _or?: form_template_section_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  disclaimer_section?: disclaimer_section_bool_exp | null;
  family_editable_statuses?: jsonb_comparison_exp | null;
  filters?: jsonb_comparison_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  form_template_section_type?: form_template_section_type_bool_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  order?: Int_comparison_exp | null;
  permission_level?: String_comparison_exp | null;
  questions?: question_bool_exp | null;
  questions_aggregate?: question_aggregate_bool_exp | null;
  schools_ranking_section?: schools_ranking_section_bool_exp | null;
  title?: String_comparison_exp | null;
  type?: form_template_section_type_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * delete the field or element with specified path (for JSON arrays, negative integers count from the end)
 */
export interface form_template_section_delete_at_path_input {
  family_editable_statuses?: string[] | null;
  filters?: string[] | null;
}

/**
 * delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array
 */
export interface form_template_section_delete_elem_input {
  family_editable_statuses?: number | null;
  filters?: number | null;
}

/**
 * delete key/value pair or string element. key/value pairs are matched based on their key value
 */
export interface form_template_section_delete_key_input {
  family_editable_statuses?: string | null;
  filters?: string | null;
}

/**
 * input type for incrementing numeric columns in table "form_template_section"
 */
export interface form_template_section_inc_input {
  order?: number | null;
}

/**
 * input type for inserting data into table "form_template_section"
 */
export interface form_template_section_insert_input {
  created_at?: timestamptz | null;
  description?: string | null;
  disclaimer_section?: disclaimer_section_obj_rel_insert_input | null;
  family_editable_statuses?: jsonb | null;
  filters?: jsonb | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  form_template_section_type?: form_template_section_type_obj_rel_insert_input | null;
  id?: uuid | null;
  key?: string | null;
  order?: number | null;
  permission_level?: string | null;
  questions?: question_arr_rel_insert_input | null;
  schools_ranking_section?: schools_ranking_section_obj_rel_insert_input | null;
  title?: string | null;
  type?: form_template_section_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_template_section"
 */
export interface form_template_section_max_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  order?: order_by | null;
  permission_level?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_template_section"
 */
export interface form_template_section_min_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  order?: order_by | null;
  permission_level?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_template_section"
 */
export interface form_template_section_obj_rel_insert_input {
  data: form_template_section_insert_input;
  on_conflict?: form_template_section_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_template_section"
 */
export interface form_template_section_on_conflict {
  constraint: form_template_section_constraint;
  update_columns: form_template_section_update_column[];
  where?: form_template_section_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_template_section".
 */
export interface form_template_section_order_by {
  created_at?: order_by | null;
  description?: order_by | null;
  disclaimer_section?: disclaimer_section_order_by | null;
  family_editable_statuses?: order_by | null;
  filters?: order_by | null;
  form_template?: form_template_order_by | null;
  form_template_id?: order_by | null;
  form_template_section_type?: form_template_section_type_order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  order?: order_by | null;
  permission_level?: order_by | null;
  questions_aggregate?: question_aggregate_order_by | null;
  schools_ranking_section?: schools_ranking_section_order_by | null;
  title?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * prepend existing jsonb value of filtered columns with new jsonb value
 */
export interface form_template_section_prepend_input {
  family_editable_statuses?: jsonb | null;
  filters?: jsonb | null;
}

/**
 * input type for updating data in table "form_template_section"
 */
export interface form_template_section_set_input {
  created_at?: timestamptz | null;
  description?: string | null;
  family_editable_statuses?: jsonb | null;
  filters?: jsonb | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  key?: string | null;
  order?: number | null;
  permission_level?: string | null;
  title?: string | null;
  type?: form_template_section_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by stddev() on columns of table "form_template_section"
 */
export interface form_template_section_stddev_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "form_template_section"
 */
export interface form_template_section_stddev_pop_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "form_template_section"
 */
export interface form_template_section_stddev_samp_order_by {
  order?: order_by | null;
}

/**
 * order by sum() on columns of table "form_template_section"
 */
export interface form_template_section_sum_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_template_section_type". All fields are combined with a logical 'AND'.
 */
export interface form_template_section_type_bool_exp {
  _and?: form_template_section_type_bool_exp[] | null;
  _not?: form_template_section_type_bool_exp | null;
  _or?: form_template_section_type_bool_exp[] | null;
  form_template_sections?: form_template_section_bool_exp | null;
  form_template_sections_aggregate?: form_template_section_aggregate_bool_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "form_template_section_type_enum". All fields are combined with logical 'AND'.
 */
export interface form_template_section_type_enum_comparison_exp {
  _eq?: form_template_section_type_enum | null;
  _in?: form_template_section_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: form_template_section_type_enum | null;
  _nin?: form_template_section_type_enum[] | null;
}

/**
 * input type for inserting data into table "form_template_section_type"
 */
export interface form_template_section_type_insert_input {
  form_template_sections?: form_template_section_arr_rel_insert_input | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_template_section_type"
 */
export interface form_template_section_type_obj_rel_insert_input {
  data: form_template_section_type_insert_input;
  on_conflict?: form_template_section_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_template_section_type"
 */
export interface form_template_section_type_on_conflict {
  constraint: form_template_section_type_constraint;
  update_columns: form_template_section_type_update_column[];
  where?: form_template_section_type_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_template_section_type".
 */
export interface form_template_section_type_order_by {
  form_template_sections_aggregate?: form_template_section_aggregate_order_by | null;
  value?: order_by | null;
}

export interface form_template_section_updates {
  _append?: form_template_section_append_input | null;
  _delete_at_path?: form_template_section_delete_at_path_input | null;
  _delete_elem?: form_template_section_delete_elem_input | null;
  _delete_key?: form_template_section_delete_key_input | null;
  _inc?: form_template_section_inc_input | null;
  _prepend?: form_template_section_prepend_input | null;
  _set?: form_template_section_set_input | null;
  where: form_template_section_bool_exp;
}

/**
 * order by var_pop() on columns of table "form_template_section"
 */
export interface form_template_section_var_pop_order_by {
  order?: order_by | null;
}

/**
 * order by var_samp() on columns of table "form_template_section"
 */
export interface form_template_section_var_samp_order_by {
  order?: order_by | null;
}

/**
 * order by variance() on columns of table "form_template_section"
 */
export interface form_template_section_variance_order_by {
  order?: order_by | null;
}

/**
 * input type for updating data in table "form_template"
 */
export interface form_template_set_input {
  active?: boolean | null;
  closed_at?: timestamptz | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  description?: string | null;
  enrollment_period_id?: uuid | null;
  form_template_rule_id?: uuid | null;
  id?: uuid | null;
  key?: string | null;
  locked?: boolean | null;
  lottery_offers_enabled?: boolean | null;
  name?: string | null;
  offer_start_at?: timestamptz | null;
  open_at?: timestamptz | null;
  reopen_at?: timestamptz | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to filter rows from the table "form_tracking_dates". All fields are combined with a logical 'AND'.
 */
export interface form_tracking_dates_bool_exp {
  _and?: form_tracking_dates_bool_exp[] | null;
  _not?: form_tracking_dates_bool_exp | null;
  _or?: form_tracking_dates_bool_exp[] | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
  user?: user_bool_exp | null;
}

/**
 * input type for inserting data into table "form_tracking_dates"
 */
export interface form_tracking_dates_insert_input {
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  updated_at?: timestamptz | null;
  updated_by?: string | null;
  user?: user_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "form_tracking_dates"
 */
export interface form_tracking_dates_obj_rel_insert_input {
  data: form_tracking_dates_insert_input;
  on_conflict?: form_tracking_dates_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_tracking_dates"
 */
export interface form_tracking_dates_on_conflict {
  constraint: form_tracking_dates_constraint;
  update_columns: form_tracking_dates_update_column[];
  where?: form_tracking_dates_bool_exp | null;
}

/**
 * Ordering options when selecting data from "form_tracking_dates".
 */
export interface form_tracking_dates_order_by {
  form?: form_order_by | null;
  form_id?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  user?: user_order_by | null;
}

export interface form_verification_aggregate_bool_exp {
  count?: form_verification_aggregate_bool_exp_count | null;
}

export interface form_verification_aggregate_bool_exp_count {
  arguments?: form_verification_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_verification_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_verification"
 */
export interface form_verification_aggregate_order_by {
  count?: order_by | null;
  max?: form_verification_max_order_by | null;
  min?: form_verification_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_verification"
 */
export interface form_verification_arr_rel_insert_input {
  data: form_verification_insert_input[];
  on_conflict?: form_verification_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_verification". All fields are combined with a logical 'AND'.
 */
export interface form_verification_bool_exp {
  _and?: form_verification_bool_exp[] | null;
  _not?: form_verification_bool_exp | null;
  _or?: form_verification_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form_questions?: form_question_bool_exp | null;
  form_questions_aggregate?: form_question_aggregate_bool_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  form_verification_results?: form_verification_result_bool_exp | null;
  form_verification_results_aggregate?: form_verification_result_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_verification"
 */
export interface form_verification_insert_input {
  created_at?: timestamptz | null;
  form_questions?: form_question_arr_rel_insert_input | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  form_verification_results?: form_verification_result_arr_rel_insert_input | null;
  id?: uuid | null;
  label?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "form_verification"
 */
export interface form_verification_max_order_by {
  created_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_verification"
 */
export interface form_verification_min_order_by {
  created_at?: order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_verification"
 */
export interface form_verification_obj_rel_insert_input {
  data: form_verification_insert_input;
  on_conflict?: form_verification_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_verification"
 */
export interface form_verification_on_conflict {
  constraint: form_verification_constraint;
  update_columns: form_verification_update_column[];
  where?: form_verification_bool_exp | null;
}

export interface form_verification_result_aggregate_bool_exp {
  count?: form_verification_result_aggregate_bool_exp_count | null;
}

export interface form_verification_result_aggregate_bool_exp_count {
  arguments?: form_verification_result_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_verification_result_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_verification_result"
 */
export interface form_verification_result_aggregate_order_by {
  count?: order_by | null;
  max?: form_verification_result_max_order_by | null;
  min?: form_verification_result_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_verification_result"
 */
export interface form_verification_result_arr_rel_insert_input {
  data: form_verification_result_insert_input[];
  on_conflict?: form_verification_result_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "form_verification_result". All fields are combined with a logical 'AND'.
 */
export interface form_verification_result_bool_exp {
  _and?: form_verification_result_bool_exp[] | null;
  _not?: form_verification_result_bool_exp | null;
  _or?: form_verification_result_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_answer_verification_results?: form_answer_verification_result_bool_exp | null;
  form_answer_verification_results_aggregate?: form_answer_verification_result_aggregate_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_verification?: form_verification_bool_exp | null;
  form_verification_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  verification_status?: verification_status_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_verification_result"
 */
export interface form_verification_result_insert_input {
  created_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_answer_verification_results?: form_answer_verification_result_arr_rel_insert_input | null;
  form_id?: uuid | null;
  form_verification?: form_verification_obj_rel_insert_input | null;
  form_verification_id?: uuid | null;
  id?: uuid | null;
  updated_at?: timestamptz | null;
  verification_status?: verification_status_enum | null;
}

/**
 * order by max() on columns of table "form_verification_result"
 */
export interface form_verification_result_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  form_verification_id?: order_by | null;
  id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "form_verification_result"
 */
export interface form_verification_result_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  form_verification_id?: order_by | null;
  id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "form_verification_result"
 */
export interface form_verification_result_obj_rel_insert_input {
  data: form_verification_result_insert_input;
  on_conflict?: form_verification_result_on_conflict | null;
}

/**
 * on_conflict condition type for table "form_verification_result"
 */
export interface form_verification_result_on_conflict {
  constraint: form_verification_result_constraint;
  update_columns: form_verification_result_update_column[];
  where?: form_verification_result_bool_exp | null;
}

export interface form_verification_result_status_aggregate_bool_exp {
  count?: form_verification_result_status_aggregate_bool_exp_count | null;
}

export interface form_verification_result_status_aggregate_bool_exp_count {
  arguments?: form_verification_result_status_select_column[] | null;
  distinct?: boolean | null;
  filter?: form_verification_result_status_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "form_verification_result_status"
 */
export interface form_verification_result_status_aggregate_order_by {
  count?: order_by | null;
  max?: form_verification_result_status_max_order_by | null;
  min?: form_verification_result_status_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "form_verification_result_status"
 */
export interface form_verification_result_status_arr_rel_insert_input {
  data: form_verification_result_status_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "form_verification_result_status". All fields are combined with a logical 'AND'.
 */
export interface form_verification_result_status_bool_exp {
  _and?: form_verification_result_status_bool_exp[] | null;
  _not?: form_verification_result_status_bool_exp | null;
  _or?: form_verification_result_status_bool_exp[] | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_verification?: form_verification_bool_exp | null;
  form_verification_id?: uuid_comparison_exp | null;
  form_verification_result?: form_verification_result_bool_exp | null;
  form_verification_result_id?: uuid_comparison_exp | null;
  verification_status?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_verification_result_status"
 */
export interface form_verification_result_status_insert_input {
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_verification?: form_verification_obj_rel_insert_input | null;
  form_verification_id?: uuid | null;
  form_verification_result?: form_verification_result_obj_rel_insert_input | null;
  form_verification_result_id?: uuid | null;
  verification_status?: string | null;
}

/**
 * order by max() on columns of table "form_verification_result_status"
 */
export interface form_verification_result_status_max_order_by {
  form_id?: order_by | null;
  form_verification_id?: order_by | null;
  form_verification_result_id?: order_by | null;
  verification_status?: order_by | null;
}

/**
 * order by min() on columns of table "form_verification_result_status"
 */
export interface form_verification_result_status_min_order_by {
  form_id?: order_by | null;
  form_verification_id?: order_by | null;
  form_verification_result_id?: order_by | null;
  verification_status?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "form_verifications_flattened". All fields are combined with a logical 'AND'.
 */
export interface form_verifications_flattened_bool_exp {
  _and?: form_verifications_flattened_bool_exp[] | null;
  _not?: form_verifications_flattened_bool_exp | null;
  _or?: form_verifications_flattened_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  form_school_ranks?: form_school_rank_bool_exp | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_bool_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  status?: String_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  verifications?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "form_verifications_flattened"
 */
export interface form_verifications_flattened_insert_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form_school_ranks?: form_school_rank_arr_rel_insert_input | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  status?: string | null;
  submitted_at?: timestamptz | null;
  updated_at?: timestamptz | null;
  verifications?: string | null;
}

/**
 * input type for inserting object relation for remote table "form_verifications_flattened"
 */
export interface form_verifications_flattened_obj_rel_insert_input {
  data: form_verifications_flattened_insert_input;
}

/**
 * Ordering options when selecting data from "form_verifications_flattened".
 */
export interface form_verifications_flattened_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_order_by | null;
  form_template?: form_template_order_by | null;
  form_template_id?: order_by | null;
  id?: order_by | null;
  person?: person_order_by | null;
  person_id?: order_by | null;
  status?: order_by | null;
  submitted_at?: order_by | null;
  updated_at?: order_by | null;
  verifications?: order_by | null;
}

export interface geo_eligibility_aggregate_bool_exp {
  bool_and?: geo_eligibility_aggregate_bool_exp_bool_and | null;
  bool_or?: geo_eligibility_aggregate_bool_exp_bool_or | null;
  count?: geo_eligibility_aggregate_bool_exp_count | null;
}

export interface geo_eligibility_aggregate_bool_exp_bool_and {
  arguments: geo_eligibility_select_column_geo_eligibility_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: geo_eligibility_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface geo_eligibility_aggregate_bool_exp_bool_or {
  arguments: geo_eligibility_select_column_geo_eligibility_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: geo_eligibility_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface geo_eligibility_aggregate_bool_exp_count {
  arguments?: geo_eligibility_select_column[] | null;
  distinct?: boolean | null;
  filter?: geo_eligibility_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "geo_eligibility"
 */
export interface geo_eligibility_arr_rel_insert_input {
  data: geo_eligibility_insert_input[];
  on_conflict?: geo_eligibility_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "geo_eligibility". All fields are combined with a logical 'AND'.
 */
export interface geo_eligibility_bool_exp {
  _and?: geo_eligibility_bool_exp[] | null;
  _not?: geo_eligibility_bool_exp | null;
  _or?: geo_eligibility_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  is_eligible_inside?: Boolean_comparison_exp | null;
  school_boundary?: school_boundary_bool_exp | null;
  school_boundary_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "geo_eligibility"
 */
export interface geo_eligibility_insert_input {
  id?: uuid | null;
  is_eligible_inside?: boolean | null;
  school_boundary?: school_boundary_obj_rel_insert_input | null;
  school_boundary_id?: uuid | null;
}

/**
 * on_conflict condition type for table "geo_eligibility"
 */
export interface geo_eligibility_on_conflict {
  constraint: geo_eligibility_constraint;
  update_columns: geo_eligibility_update_column[];
  where?: geo_eligibility_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "geojson". All fields are combined with a logical 'AND'.
 */
export interface geojson_bool_exp {
  _and?: geojson_bool_exp[] | null;
  _not?: geojson_bool_exp | null;
  _or?: geojson_bool_exp[] | null;
  boundary_tags?: boundary_tag_bool_exp | null;
  boundary_tags_aggregate?: boundary_tag_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  geojson?: jsonb_comparison_exp | null;
  geojson_property_key?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  school_boundaries?: school_boundary_bool_exp | null;
  school_boundaries_aggregate?: school_boundary_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "geojson"
 */
export interface geojson_insert_input {
  boundary_tags?: boundary_tag_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  geojson?: jsonb | null;
  geojson_property_key?: string | null;
  id?: uuid | null;
  is_active?: boolean | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  school_boundaries?: school_boundary_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "geojson"
 */
export interface geojson_obj_rel_insert_input {
  data: geojson_insert_input;
  on_conflict?: geojson_on_conflict | null;
}

/**
 * on_conflict condition type for table "geojson"
 */
export interface geojson_on_conflict {
  constraint: geojson_constraint;
  update_columns: geojson_update_column[];
  where?: geojson_bool_exp | null;
}

export interface glossary_aggregate_bool_exp {
  count?: glossary_aggregate_bool_exp_count | null;
}

export interface glossary_aggregate_bool_exp_count {
  arguments?: glossary_select_column[] | null;
  distinct?: boolean | null;
  filter?: glossary_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "glossary"
 */
export interface glossary_arr_rel_insert_input {
  data: glossary_insert_input[];
  on_conflict?: glossary_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "glossary". All fields are combined with a logical 'AND'.
 */
export interface glossary_bool_exp {
  _and?: glossary_bool_exp[] | null;
  _not?: glossary_bool_exp | null;
  _or?: glossary_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  glossary_type?: glossary_type_bool_exp | null;
  id?: uuid_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  plural?: String_comparison_exp | null;
  singular?: String_comparison_exp | null;
  type?: glossary_type_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "glossary"
 */
export interface glossary_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  glossary_type?: glossary_type_obj_rel_insert_input | null;
  id?: uuid | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  plural?: string | null;
  singular?: string | null;
  type?: glossary_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "glossary"
 */
export interface glossary_on_conflict {
  constraint: glossary_constraint;
  update_columns: glossary_update_column[];
  where?: glossary_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "glossary_type". All fields are combined with a logical 'AND'.
 */
export interface glossary_type_bool_exp {
  _and?: glossary_type_bool_exp[] | null;
  _not?: glossary_type_bool_exp | null;
  _or?: glossary_type_bool_exp[] | null;
  glossaries?: glossary_bool_exp | null;
  glossaries_aggregate?: glossary_aggregate_bool_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "glossary_type_enum". All fields are combined with logical 'AND'.
 */
export interface glossary_type_enum_comparison_exp {
  _eq?: glossary_type_enum | null;
  _in?: glossary_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: glossary_type_enum | null;
  _nin?: glossary_type_enum[] | null;
}

/**
 * input type for inserting data into table "glossary_type"
 */
export interface glossary_type_insert_input {
  glossaries?: glossary_arr_rel_insert_input | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "glossary_type"
 */
export interface glossary_type_obj_rel_insert_input {
  data: glossary_type_insert_input;
  on_conflict?: glossary_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "glossary_type"
 */
export interface glossary_type_on_conflict {
  constraint: glossary_type_constraint;
  update_columns: glossary_type_update_column[];
  where?: glossary_type_bool_exp | null;
}

export interface grade_aggregate_bool_exp {
  count?: grade_aggregate_bool_exp_count | null;
}

export interface grade_aggregate_bool_exp_count {
  arguments?: grade_select_column[] | null;
  distinct?: boolean | null;
  filter?: grade_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "grade"
 */
export interface grade_aggregate_order_by {
  avg?: grade_avg_order_by | null;
  count?: order_by | null;
  max?: grade_max_order_by | null;
  min?: grade_min_order_by | null;
  stddev?: grade_stddev_order_by | null;
  stddev_pop?: grade_stddev_pop_order_by | null;
  stddev_samp?: grade_stddev_samp_order_by | null;
  sum?: grade_sum_order_by | null;
  var_pop?: grade_var_pop_order_by | null;
  var_samp?: grade_var_samp_order_by | null;
  variance?: grade_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grade"
 */
export interface grade_arr_rel_insert_input {
  data: grade_insert_input[];
  on_conflict?: grade_on_conflict | null;
}

/**
 * order by avg() on columns of table "grade"
 */
export interface grade_avg_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grade". All fields are combined with a logical 'AND'.
 */
export interface grade_bool_exp {
  _and?: grade_bool_exp[] | null;
  _not?: grade_bool_exp | null;
  _or?: grade_bool_exp[] | null;
  bin?: bin_bool_exp | null;
  bin_id?: uuid_comparison_exp | null;
  capacity?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  grade_config?: grade_config_bool_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
  grade_resolved_priority_template?: grade_resolved_priority_template_bool_exp | null;
  grades_group?: grades_group_bool_exp | null;
  grades_group_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  offers?: offer_bool_exp | null;
  offers_aggregate?: offer_aggregate_bool_exp | null;
  program?: program_bool_exp | null;
  program_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  seats_available?: Int_comparison_exp | null;
  seats_taken?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlists?: waitlist_bool_exp | null;
  waitlists_aggregate?: waitlist_aggregate_bool_exp | null;
}

export interface grade_config_aggregate_bool_exp {
  count?: grade_config_aggregate_bool_exp_count | null;
}

export interface grade_config_aggregate_bool_exp_count {
  arguments?: grade_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: grade_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "grade_config"
 */
export interface grade_config_aggregate_order_by {
  avg?: grade_config_avg_order_by | null;
  count?: order_by | null;
  max?: grade_config_max_order_by | null;
  min?: grade_config_min_order_by | null;
  stddev?: grade_config_stddev_order_by | null;
  stddev_pop?: grade_config_stddev_pop_order_by | null;
  stddev_samp?: grade_config_stddev_samp_order_by | null;
  sum?: grade_config_sum_order_by | null;
  var_pop?: grade_config_var_pop_order_by | null;
  var_samp?: grade_config_var_samp_order_by | null;
  variance?: grade_config_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grade_config"
 */
export interface grade_config_arr_rel_insert_input {
  data: grade_config_insert_input[];
  on_conflict?: grade_config_on_conflict | null;
}

/**
 * order by avg() on columns of table "grade_config"
 */
export interface grade_config_avg_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grade_config". All fields are combined with a logical 'AND'.
 */
export interface grade_config_bool_exp {
  _and?: grade_config_bool_exp[] | null;
  _not?: grade_config_bool_exp | null;
  _or?: grade_config_bool_exp[] | null;
  grades?: grade_bool_exp | null;
  grades_additional_questions?: grades_additional_question_bool_exp | null;
  grades_additional_questions_aggregate?: grades_additional_question_aggregate_bool_exp | null;
  grades_aggregate?: grade_aggregate_bool_exp | null;
  grades_answers?: grades_answer_bool_exp | null;
  grades_answers_aggregate?: grades_answer_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  order?: Int_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for incrementing numeric columns in table "grade_config"
 */
export interface grade_config_inc_input {
  order?: number | null;
}

/**
 * input type for inserting data into table "grade_config"
 */
export interface grade_config_insert_input {
  grades?: grade_arr_rel_insert_input | null;
  grades_additional_questions?: grades_additional_question_arr_rel_insert_input | null;
  grades_answers?: grades_answer_arr_rel_insert_input | null;
  id?: uuid | null;
  label?: string | null;
  order?: number | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  value?: string | null;
}

/**
 * order by max() on columns of table "grade_config"
 */
export interface grade_config_max_order_by {
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  organization_id?: order_by | null;
  value?: order_by | null;
}

/**
 * order by min() on columns of table "grade_config"
 */
export interface grade_config_min_order_by {
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  organization_id?: order_by | null;
  value?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "grade_config"
 */
export interface grade_config_obj_rel_insert_input {
  data: grade_config_insert_input;
  on_conflict?: grade_config_on_conflict | null;
}

/**
 * on_conflict condition type for table "grade_config"
 */
export interface grade_config_on_conflict {
  constraint: grade_config_constraint;
  update_columns: grade_config_update_column[];
  where?: grade_config_bool_exp | null;
}

/**
 * Ordering options when selecting data from "grade_config".
 */
export interface grade_config_order_by {
  grades_additional_questions_aggregate?: grades_additional_question_aggregate_order_by | null;
  grades_aggregate?: grade_aggregate_order_by | null;
  grades_answers_aggregate?: grades_answer_aggregate_order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  value?: order_by | null;
}

/**
 * input type for updating data in table "grade_config"
 */
export interface grade_config_set_input {
  id?: uuid | null;
  label?: string | null;
  order?: number | null;
  organization_id?: uuid | null;
  value?: string | null;
}

/**
 * order by stddev() on columns of table "grade_config"
 */
export interface grade_config_stddev_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grade_config"
 */
export interface grade_config_stddev_pop_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grade_config"
 */
export interface grade_config_stddev_samp_order_by {
  order?: order_by | null;
}

/**
 * order by sum() on columns of table "grade_config"
 */
export interface grade_config_sum_order_by {
  order?: order_by | null;
}

export interface grade_config_updates {
  _inc?: grade_config_inc_input | null;
  _set?: grade_config_set_input | null;
  where: grade_config_bool_exp;
}

/**
 * order by var_pop() on columns of table "grade_config"
 */
export interface grade_config_var_pop_order_by {
  order?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grade_config"
 */
export interface grade_config_var_samp_order_by {
  order?: order_by | null;
}

/**
 * order by variance() on columns of table "grade_config"
 */
export interface grade_config_variance_order_by {
  order?: order_by | null;
}

/**
 * input type for incrementing numeric columns in table "grade"
 */
export interface grade_inc_input {
  capacity?: number | null;
  seats_available?: number | null;
  seats_taken?: number | null;
}

/**
 * input type for inserting data into table "grade"
 */
export interface grade_insert_input {
  bin?: bin_obj_rel_insert_input | null;
  bin_id?: uuid | null;
  capacity?: number | null;
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  grade_config?: grade_config_obj_rel_insert_input | null;
  grade_config_id?: uuid | null;
  grade_resolved_priority_template?: grade_resolved_priority_template_obj_rel_insert_input | null;
  grades_group?: grades_group_obj_rel_insert_input | null;
  grades_group_id?: uuid | null;
  id?: uuid | null;
  offers?: offer_arr_rel_insert_input | null;
  program?: program_obj_rel_insert_input | null;
  program_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  seats_available?: number | null;
  seats_taken?: number | null;
  updated_at?: timestamptz | null;
  waitlists?: waitlist_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "grade"
 */
export interface grade_max_order_by {
  bin_id?: order_by | null;
  capacity?: order_by | null;
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  grade_config_id?: order_by | null;
  grades_group_id?: order_by | null;
  id?: order_by | null;
  program_id?: order_by | null;
  school_id?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "grade"
 */
export interface grade_min_order_by {
  bin_id?: order_by | null;
  capacity?: order_by | null;
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  grade_config_id?: order_by | null;
  grades_group_id?: order_by | null;
  id?: order_by | null;
  program_id?: order_by | null;
  school_id?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "grade"
 */
export interface grade_obj_rel_insert_input {
  data: grade_insert_input;
  on_conflict?: grade_on_conflict | null;
}

/**
 * on_conflict condition type for table "grade"
 */
export interface grade_on_conflict {
  constraint: grade_constraint;
  update_columns: grade_update_column[];
  where?: grade_bool_exp | null;
}

/**
 * Ordering options when selecting data from "grade".
 */
export interface grade_order_by {
  bin?: bin_order_by | null;
  bin_id?: order_by | null;
  capacity?: order_by | null;
  created_at?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  grade_config?: grade_config_order_by | null;
  grade_config_id?: order_by | null;
  grade_resolved_priority_template?: grade_resolved_priority_template_order_by | null;
  grades_group?: grades_group_order_by | null;
  grades_group_id?: order_by | null;
  id?: order_by | null;
  offers_aggregate?: offer_aggregate_order_by | null;
  program?: program_order_by | null;
  program_id?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
  updated_at?: order_by | null;
  waitlists_aggregate?: waitlist_aggregate_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grade_resolved_priority_template". All fields are combined with a logical 'AND'.
 */
export interface grade_resolved_priority_template_bool_exp {
  _and?: grade_resolved_priority_template_bool_exp[] | null;
  _not?: grade_resolved_priority_template_bool_exp | null;
  _or?: grade_resolved_priority_template_bool_exp[] | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  priority_template?: priority_template_bool_exp | null;
  priority_template_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "grade_resolved_priority_template"
 */
export interface grade_resolved_priority_template_insert_input {
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  priority_template?: priority_template_obj_rel_insert_input | null;
  priority_template_id?: uuid | null;
}

/**
 * input type for inserting object relation for remote table "grade_resolved_priority_template"
 */
export interface grade_resolved_priority_template_obj_rel_insert_input {
  data: grade_resolved_priority_template_insert_input;
}

/**
 * Ordering options when selecting data from "grade_resolved_priority_template".
 */
export interface grade_resolved_priority_template_order_by {
  grade?: grade_order_by | null;
  grade_id?: order_by | null;
  priority_template?: priority_template_order_by | null;
  priority_template_id?: order_by | null;
}

/**
 * input type for updating data in table "grade"
 */
export interface grade_set_input {
  bin_id?: uuid | null;
  capacity?: number | null;
  created_at?: timestamptz | null;
  enrollment_period_id?: uuid | null;
  grade_config_id?: uuid | null;
  grades_group_id?: uuid | null;
  id?: uuid | null;
  program_id?: uuid | null;
  school_id?: uuid | null;
  seats_available?: number | null;
  seats_taken?: number | null;
  updated_at?: timestamptz | null;
}

/**
 * order by stddev() on columns of table "grade"
 */
export interface grade_stddev_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grade"
 */
export interface grade_stddev_pop_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grade"
 */
export interface grade_stddev_samp_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by sum() on columns of table "grade"
 */
export interface grade_sum_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

export interface grade_updates {
  _inc?: grade_inc_input | null;
  _set?: grade_set_input | null;
  where: grade_bool_exp;
}

/**
 * order by var_pop() on columns of table "grade"
 */
export interface grade_var_pop_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grade"
 */
export interface grade_var_samp_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

/**
 * order by variance() on columns of table "grade"
 */
export interface grade_variance_order_by {
  capacity?: order_by | null;
  seats_available?: order_by | null;
  seats_taken?: order_by | null;
}

export interface grades_additional_question_aggregate_bool_exp {
  count?: grades_additional_question_aggregate_bool_exp_count | null;
}

export interface grades_additional_question_aggregate_bool_exp_count {
  arguments?: grades_additional_question_select_column[] | null;
  distinct?: boolean | null;
  filter?: grades_additional_question_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "grades_additional_question"
 */
export interface grades_additional_question_aggregate_order_by {
  count?: order_by | null;
  max?: grades_additional_question_max_order_by | null;
  min?: grades_additional_question_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grades_additional_question"
 */
export interface grades_additional_question_arr_rel_insert_input {
  data: grades_additional_question_insert_input[];
  on_conflict?: grades_additional_question_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grades_additional_question". All fields are combined with a logical 'AND'.
 */
export interface grades_additional_question_bool_exp {
  _and?: grades_additional_question_bool_exp[] | null;
  _not?: grades_additional_question_bool_exp | null;
  _or?: grades_additional_question_bool_exp[] | null;
  additional_question_id?: uuid_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  grade_config?: grade_config_bool_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
  grades_question?: grades_question_bool_exp | null;
  id?: uuid_comparison_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "grades_additional_question"
 */
export interface grades_additional_question_insert_input {
  additional_question_id?: uuid | null;
  created_at?: timestamptz | null;
  grade_config?: grade_config_obj_rel_insert_input | null;
  grade_config_id?: uuid | null;
  grades_question?: grades_question_obj_rel_insert_input | null;
  id?: uuid | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "grades_additional_question"
 */
export interface grades_additional_question_max_order_by {
  additional_question_id?: order_by | null;
  created_at?: order_by | null;
  grade_config_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "grades_additional_question"
 */
export interface grades_additional_question_min_order_by {
  additional_question_id?: order_by | null;
  created_at?: order_by | null;
  grade_config_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "grades_additional_question"
 */
export interface grades_additional_question_on_conflict {
  constraint: grades_additional_question_constraint;
  update_columns: grades_additional_question_update_column[];
  where?: grades_additional_question_bool_exp | null;
}

export interface grades_answer_aggregate_bool_exp {
  count?: grades_answer_aggregate_bool_exp_count | null;
}

export interface grades_answer_aggregate_bool_exp_count {
  arguments?: grades_answer_select_column[] | null;
  distinct?: boolean | null;
  filter?: grades_answer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "grades_answer"
 */
export interface grades_answer_aggregate_order_by {
  count?: order_by | null;
  max?: grades_answer_max_order_by | null;
  min?: grades_answer_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grades_answer"
 */
export interface grades_answer_arr_rel_insert_input {
  data: grades_answer_insert_input[];
  on_conflict?: grades_answer_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grades_answer". All fields are combined with a logical 'AND'.
 */
export interface grades_answer_bool_exp {
  _and?: grades_answer_bool_exp[] | null;
  _not?: grades_answer_bool_exp | null;
  _or?: grades_answer_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  grade_config?: grade_config_bool_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
  grades_question?: grades_question_bool_exp | null;
  id?: uuid_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "grades_answer"
 */
export interface grades_answer_insert_input {
  created_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  grade_config?: grade_config_obj_rel_insert_input | null;
  grade_config_id?: uuid | null;
  grades_question?: grades_question_obj_rel_insert_input | null;
  id?: uuid | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "grades_answer"
 */
export interface grades_answer_max_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  grade_config_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "grades_answer"
 */
export interface grades_answer_min_order_by {
  created_at?: order_by | null;
  form_id?: order_by | null;
  grade_config_id?: order_by | null;
  id?: order_by | null;
  question_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "grades_answer"
 */
export interface grades_answer_obj_rel_insert_input {
  data: grades_answer_insert_input;
  on_conflict?: grades_answer_on_conflict | null;
}

/**
 * on_conflict condition type for table "grades_answer"
 */
export interface grades_answer_on_conflict {
  constraint: grades_answer_constraint;
  update_columns: grades_answer_update_column[];
  where?: grades_answer_bool_exp | null;
}

export interface grades_by_grade_question_id_aggregate_bool_exp {
  count?: grades_by_grade_question_id_aggregate_bool_exp_count | null;
}

export interface grades_by_grade_question_id_aggregate_bool_exp_count {
  arguments?: grades_by_grade_question_id_select_column[] | null;
  distinct?: boolean | null;
  filter?: grades_by_grade_question_id_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "grades_by_grade_question_id"
 */
export interface grades_by_grade_question_id_arr_rel_insert_input {
  data: grades_by_grade_question_id_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "grades_by_grade_question_id". All fields are combined with a logical 'AND'.
 */
export interface grades_by_grade_question_id_bool_exp {
  _and?: grades_by_grade_question_id_bool_exp[] | null;
  _not?: grades_by_grade_question_id_bool_exp | null;
  _or?: grades_by_grade_question_id_bool_exp[] | null;
  grade_config?: grade_config_bool_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "grades_by_grade_question_id"
 */
export interface grades_by_grade_question_id_insert_input {
  grade_config?: grade_config_obj_rel_insert_input | null;
  grade_config_id?: uuid | null;
  order?: number | null;
  question_id?: uuid | null;
}

/**
 * Boolean expression to filter rows from the table "grades_group". All fields are combined with a logical 'AND'.
 */
export interface grades_group_bool_exp {
  _and?: grades_group_bool_exp[] | null;
  _not?: grades_group_bool_exp | null;
  _or?: grades_group_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  grade_programs?: grade_bool_exp | null;
  grade_programs_aggregate?: grade_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface grades_group_default_bin_config_aggregate_bool_exp {
  count?: grades_group_default_bin_config_aggregate_bool_exp_count | null;
}

export interface grades_group_default_bin_config_aggregate_bool_exp_count {
  arguments?: grades_group_default_bin_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: grades_group_default_bin_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_aggregate_order_by {
  count?: order_by | null;
  max?: grades_group_default_bin_config_max_order_by | null;
  min?: grades_group_default_bin_config_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_arr_rel_insert_input {
  data: grades_group_default_bin_config_insert_input[];
  on_conflict?: grades_group_default_bin_config_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grades_group_default_bin_config". All fields are combined with a logical 'AND'.
 */
export interface grades_group_default_bin_config_bool_exp {
  _and?: grades_group_default_bin_config_bool_exp[] | null;
  _not?: grades_group_default_bin_config_bool_exp | null;
  _or?: grades_group_default_bin_config_bool_exp[] | null;
  bin_config_id?: uuid_comparison_exp | null;
  default_bin_config?: bin_config_bool_exp | null;
  default_grades_group?: grades_group_bool_exp | null;
  grades_group_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_insert_input {
  bin_config_id?: uuid | null;
  default_bin_config?: bin_config_obj_rel_insert_input | null;
  default_grades_group?: grades_group_obj_rel_insert_input | null;
  grades_group_id?: uuid | null;
  id?: uuid | null;
}

/**
 * order by max() on columns of table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_max_order_by {
  bin_config_id?: order_by | null;
  grades_group_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by min() on columns of table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_min_order_by {
  bin_config_id?: order_by | null;
  grades_group_id?: order_by | null;
  id?: order_by | null;
}

/**
 * on_conflict condition type for table "grades_group_default_bin_config"
 */
export interface grades_group_default_bin_config_on_conflict {
  constraint: grades_group_default_bin_config_constraint;
  update_columns: grades_group_default_bin_config_update_column[];
  where?: grades_group_default_bin_config_bool_exp | null;
}

/**
 * input type for inserting data into table "grades_group"
 */
export interface grades_group_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  grade_programs?: grade_arr_rel_insert_input | null;
  id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "grades_group"
 */
export interface grades_group_obj_rel_insert_input {
  data: grades_group_insert_input;
  on_conflict?: grades_group_on_conflict | null;
}

/**
 * on_conflict condition type for table "grades_group"
 */
export interface grades_group_on_conflict {
  constraint: grades_group_constraint;
  update_columns: grades_group_update_column[];
  where?: grades_group_bool_exp | null;
}

/**
 * Ordering options when selecting data from "grades_group".
 */
export interface grades_group_order_by {
  created_at?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  grade_programs_aggregate?: grade_aggregate_order_by | null;
  id?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * append existing jsonb value of filtered columns with new jsonb value
 */
export interface grades_question_append_input {
  filters?: jsonb | null;
}

/**
 * Boolean expression to filter rows from the table "grades_question". All fields are combined with a logical 'AND'.
 */
export interface grades_question_bool_exp {
  _and?: grades_question_bool_exp[] | null;
  _not?: grades_question_bool_exp | null;
  _or?: grades_question_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  filters?: jsonb_comparison_exp | null;
  grades?: grades_by_grade_question_id_bool_exp | null;
  grades_additional_questions?: grades_additional_question_bool_exp | null;
  grades_additional_questions_aggregate?: grades_additional_question_aggregate_bool_exp | null;
  grades_aggregate?: grades_by_grade_question_id_aggregate_bool_exp | null;
  grades_answer?: grades_answer_bool_exp | null;
  grades_answers?: grades_answer_bool_exp | null;
  grades_answers_aggregate?: grades_answer_aggregate_bool_exp | null;
  question?: question_bool_exp | null;
  question_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * delete the field or element with specified path (for JSON arrays, negative integers count from the end)
 */
export interface grades_question_delete_at_path_input {
  filters?: string[] | null;
}

/**
 * delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array
 */
export interface grades_question_delete_elem_input {
  filters?: number | null;
}

/**
 * delete key/value pair or string element. key/value pairs are matched based on their key value
 */
export interface grades_question_delete_key_input {
  filters?: string | null;
}

/**
 * input type for inserting data into table "grades_question"
 */
export interface grades_question_insert_input {
  created_at?: timestamptz | null;
  filters?: jsonb | null;
  grades?: grades_by_grade_question_id_arr_rel_insert_input | null;
  grades_additional_questions?: grades_additional_question_arr_rel_insert_input | null;
  grades_answer?: grades_answer_obj_rel_insert_input | null;
  grades_answers?: grades_answer_arr_rel_insert_input | null;
  question?: question_obj_rel_insert_input | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "grades_question"
 */
export interface grades_question_obj_rel_insert_input {
  data: grades_question_insert_input;
  on_conflict?: grades_question_on_conflict | null;
}

/**
 * on_conflict condition type for table "grades_question"
 */
export interface grades_question_on_conflict {
  constraint: grades_question_constraint;
  update_columns: grades_question_update_column[];
  where?: grades_question_bool_exp | null;
}

/**
 * prepend existing jsonb value of filtered columns with new jsonb value
 */
export interface grades_question_prepend_input {
  filters?: jsonb | null;
}

/**
 * input type for updating data in table "grades_question"
 */
export interface grades_question_set_input {
  created_at?: timestamptz | null;
  filters?: jsonb | null;
  question_id?: uuid | null;
  updated_at?: timestamptz | null;
}

export interface grades_question_updates {
  _append?: grades_question_append_input | null;
  _delete_at_path?: grades_question_delete_at_path_input | null;
  _delete_elem?: grades_question_delete_elem_input | null;
  _delete_key?: grades_question_delete_key_input | null;
  _prepend?: grades_question_prepend_input | null;
  _set?: grades_question_set_input | null;
  where: grades_question_bool_exp;
}

/**
 * Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'.
 */
export interface inet_comparison_exp {
  _eq?: inet | null;
  _gt?: inet | null;
  _gte?: inet | null;
  _in?: inet[] | null;
  _is_null?: boolean | null;
  _lt?: inet | null;
  _lte?: inet | null;
  _neq?: inet | null;
  _nin?: inet[] | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: jsonb | null;
  _contains?: jsonb | null;
  _eq?: jsonb | null;
  _gt?: jsonb | null;
  _gte?: jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: jsonb | null;
  _lte?: jsonb | null;
  _neq?: jsonb | null;
  _nin?: jsonb[] | null;
}

export interface match_config_aggregate_bool_exp {
  count?: match_config_aggregate_bool_exp_count | null;
}

export interface match_config_aggregate_bool_exp_count {
  arguments?: match_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: match_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "match_config"
 */
export interface match_config_aggregate_order_by {
  count?: order_by | null;
  max?: match_config_max_order_by | null;
  min?: match_config_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "match_config"
 */
export interface match_config_arr_rel_insert_input {
  data: match_config_insert_input[];
  on_conflict?: match_config_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "match_config". All fields are combined with a logical 'AND'.
 */
export interface match_config_bool_exp {
  _and?: match_config_bool_exp[] | null;
  _not?: match_config_bool_exp | null;
  _or?: match_config_bool_exp[] | null;
  bin_config?: bin_config_bool_exp | null;
  bin_configs?: bin_config_bool_exp | null;
  bin_configs_aggregate?: bin_config_aggregate_bool_exp | null;
  default_bin_config_id?: uuid_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  parameters?: jsonb_comparison_exp | null;
  person_match_configs?: person_match_config_bool_exp | null;
  person_match_configs_aggregate?: person_match_config_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "match_config"
 */
export interface match_config_insert_input {
  bin_config?: bin_config_obj_rel_insert_input | null;
  bin_configs?: bin_config_arr_rel_insert_input | null;
  default_bin_config_id?: uuid | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  name?: string | null;
  parameters?: jsonb | null;
  person_match_configs?: person_match_config_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "match_config"
 */
export interface match_config_max_order_by {
  default_bin_config_id?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "match_config"
 */
export interface match_config_min_order_by {
  default_bin_config_id?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "match_config"
 */
export interface match_config_obj_rel_insert_input {
  data: match_config_insert_input;
  on_conflict?: match_config_on_conflict | null;
}

/**
 * on_conflict condition type for table "match_config"
 */
export interface match_config_on_conflict {
  constraint: match_config_constraint;
  update_columns: match_config_update_column[];
  where?: match_config_bool_exp | null;
}

/**
 * Ordering options when selecting data from "match_config".
 */
export interface match_config_order_by {
  bin_config?: bin_config_order_by | null;
  bin_configs_aggregate?: bin_config_aggregate_order_by | null;
  default_bin_config_id?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  parameters?: order_by | null;
  person_match_configs_aggregate?: person_match_config_aggregate_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "match_run". All fields are combined with a logical 'AND'.
 */
export interface match_run_bool_exp {
  _and?: match_run_bool_exp[] | null;
  _not?: match_run_bool_exp | null;
  _or?: match_run_bool_exp[] | null;
  capacities_snapshot_document_id?: uuid_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  ended_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  loaded_form_count?: Int_comparison_exp | null;
  match_config_snapshot_document_id?: uuid_comparison_exp | null;
  matched_form_count?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  owner?: user_bool_exp | null;
  owner_id?: String_comparison_exp | null;
  results_document_id?: uuid_comparison_exp | null;
  schools?: match_run_school_bool_exp | null;
  schools_aggregate?: match_run_school_aggregate_bool_exp | null;
  status?: match_run_status_enum_comparison_exp | null;
  status_message?: String_comparison_exp | null;
  total_form_count?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "match_run".
 */
export interface match_run_order_by {
  capacities_snapshot_document_id?: order_by | null;
  created_at?: order_by | null;
  ended_at?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  loaded_form_count?: order_by | null;
  match_config_snapshot_document_id?: order_by | null;
  matched_form_count?: order_by | null;
  name?: order_by | null;
  owner?: user_order_by | null;
  owner_id?: order_by | null;
  results_document_id?: order_by | null;
  schools_aggregate?: match_run_school_aggregate_order_by | null;
  status?: order_by | null;
  status_message?: order_by | null;
  total_form_count?: order_by | null;
  updated_at?: order_by | null;
}

export interface match_run_school_aggregate_bool_exp {
  count?: match_run_school_aggregate_bool_exp_count | null;
}

export interface match_run_school_aggregate_bool_exp_count {
  arguments?: match_run_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: match_run_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "match_run_school"
 */
export interface match_run_school_aggregate_order_by {
  count?: order_by | null;
  max?: match_run_school_max_order_by | null;
  min?: match_run_school_min_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "match_run_school". All fields are combined with a logical 'AND'.
 */
export interface match_run_school_bool_exp {
  _and?: match_run_school_bool_exp[] | null;
  _not?: match_run_school_bool_exp | null;
  _or?: match_run_school_bool_exp[] | null;
  match_run?: match_run_bool_exp | null;
  match_run_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
}

/**
 * order by max() on columns of table "match_run_school"
 */
export interface match_run_school_max_order_by {
  match_run_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * order by min() on columns of table "match_run_school"
 */
export interface match_run_school_min_order_by {
  match_run_id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "match_run_status_enum". All fields are combined with logical 'AND'.
 */
export interface match_run_status_enum_comparison_exp {
  _eq?: match_run_status_enum | null;
  _in?: match_run_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: match_run_status_enum | null;
  _nin?: match_run_status_enum[] | null;
}

export interface match_template_aggregate_bool_exp {
  count?: match_template_aggregate_bool_exp_count | null;
}

export interface match_template_aggregate_bool_exp_count {
  arguments?: match_template_select_column[] | null;
  distinct?: boolean | null;
  filter?: match_template_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "match_template"
 */
export interface match_template_aggregate_order_by {
  count?: order_by | null;
  max?: match_template_max_order_by | null;
  min?: match_template_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "match_template"
 */
export interface match_template_arr_rel_insert_input {
  data: match_template_insert_input[];
  on_conflict?: match_template_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "match_template". All fields are combined with a logical 'AND'.
 */
export interface match_template_bool_exp {
  _and?: match_template_bool_exp[] | null;
  _not?: match_template_bool_exp | null;
  _or?: match_template_bool_exp[] | null;
  config?: jsonb_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  match_template_organizations?: match_template_organization_bool_exp | null;
  match_template_organizations_aggregate?: match_template_organization_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  sort_field_1?: priority_template_sort_field_enum_comparison_exp | null;
  sort_field_2?: priority_template_sort_field_enum_comparison_exp | null;
  sort_field_3?: priority_template_sort_field_enum_comparison_exp | null;
  sort_order_1?: priority_template_sort_order_enum_comparison_exp | null;
  sort_order_2?: priority_template_sort_order_enum_comparison_exp | null;
  sort_order_3?: priority_template_sort_order_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "match_template"
 */
export interface match_template_insert_input {
  config?: jsonb | null;
  created_at?: timestamptz | null;
  id?: uuid | null;
  match_template_organizations?: match_template_organization_arr_rel_insert_input | null;
  name?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  sort_field_1?: priority_template_sort_field_enum | null;
  sort_field_2?: priority_template_sort_field_enum | null;
  sort_field_3?: priority_template_sort_field_enum | null;
  sort_order_1?: priority_template_sort_order_enum | null;
  sort_order_2?: priority_template_sort_order_enum | null;
  sort_order_3?: priority_template_sort_order_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "match_template"
 */
export interface match_template_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "match_template"
 */
export interface match_template_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "match_template"
 */
export interface match_template_obj_rel_insert_input {
  data: match_template_insert_input;
  on_conflict?: match_template_on_conflict | null;
}

/**
 * on_conflict condition type for table "match_template"
 */
export interface match_template_on_conflict {
  constraint: match_template_constraint;
  update_columns: match_template_update_column[];
  where?: match_template_bool_exp | null;
}

/**
 * Ordering options when selecting data from "match_template".
 */
export interface match_template_order_by {
  config?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  match_template_organizations_aggregate?: match_template_organization_aggregate_order_by | null;
  name?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  sort_field_1?: order_by | null;
  sort_field_2?: order_by | null;
  sort_field_3?: order_by | null;
  sort_order_1?: order_by | null;
  sort_order_2?: order_by | null;
  sort_order_3?: order_by | null;
  updated_at?: order_by | null;
}

export interface match_template_organization_aggregate_bool_exp {
  count?: match_template_organization_aggregate_bool_exp_count | null;
}

export interface match_template_organization_aggregate_bool_exp_count {
  arguments?: match_template_organization_select_column[] | null;
  distinct?: boolean | null;
  filter?: match_template_organization_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "match_template_organization"
 */
export interface match_template_organization_aggregate_order_by {
  count?: order_by | null;
  max?: match_template_organization_max_order_by | null;
  min?: match_template_organization_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "match_template_organization"
 */
export interface match_template_organization_arr_rel_insert_input {
  data: match_template_organization_insert_input[];
  on_conflict?: match_template_organization_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "match_template_organization". All fields are combined with a logical 'AND'.
 */
export interface match_template_organization_bool_exp {
  _and?: match_template_organization_bool_exp[] | null;
  _not?: match_template_organization_bool_exp | null;
  _or?: match_template_organization_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  match_template?: match_template_bool_exp | null;
  match_template_id?: uuid_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "match_template_organization"
 */
export interface match_template_organization_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  match_template?: match_template_obj_rel_insert_input | null;
  match_template_id?: uuid | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "match_template_organization"
 */
export interface match_template_organization_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  match_template_id?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "match_template_organization"
 */
export interface match_template_organization_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  match_template_id?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "match_template_organization"
 */
export interface match_template_organization_obj_rel_insert_input {
  data: match_template_organization_insert_input;
  on_conflict?: match_template_organization_on_conflict | null;
}

/**
 * on_conflict condition type for table "match_template_organization"
 */
export interface match_template_organization_on_conflict {
  constraint: match_template_organization_constraint;
  update_columns: match_template_organization_update_column[];
  where?: match_template_organization_bool_exp | null;
}

/**
 * Ordering options when selecting data from "match_template_organization".
 */
export interface match_template_organization_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  match_template?: match_template_order_by | null;
  match_template_id?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_bool_exp {
  _and?: message_adhoc_bool_exp[] | null;
  _not?: message_adhoc_bool_exp | null;
  _or?: message_adhoc_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  message_adhoc_forms?: message_adhoc_form_bool_exp | null;
  message_adhoc_forms_aggregate?: message_adhoc_form_aggregate_bool_exp | null;
  message_adhoc_payload_email?: message_adhoc_payload_email_bool_exp | null;
  message_adhoc_payload_rich_contents?: message_adhoc_payload_rich_content_bool_exp | null;
  message_adhoc_payload_rich_contents_aggregate?: message_adhoc_payload_rich_content_aggregate_bool_exp | null;
  message_adhoc_payload_sms?: message_adhoc_payload_sms_bool_exp | null;
  message_adhoc_receivers?: message_adhoc_receiver_bool_exp | null;
  message_adhoc_receivers_aggregate?: message_adhoc_receiver_aggregate_bool_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  sender_person?: person_bool_exp | null;
  sender_person_id?: uuid_comparison_exp | null;
}

export interface message_adhoc_form_aggregate_bool_exp {
  count?: message_adhoc_form_aggregate_bool_exp_count | null;
}

export interface message_adhoc_form_aggregate_bool_exp_count {
  arguments?: message_adhoc_form_select_column[] | null;
  distinct?: boolean | null;
  filter?: message_adhoc_form_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "message_adhoc_form"
 */
export interface message_adhoc_form_aggregate_order_by {
  count?: order_by | null;
  max?: message_adhoc_form_max_order_by | null;
  min?: message_adhoc_form_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "message_adhoc_form"
 */
export interface message_adhoc_form_arr_rel_insert_input {
  data: message_adhoc_form_insert_input[];
  on_conflict?: message_adhoc_form_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc_form". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_form_bool_exp {
  _and?: message_adhoc_form_bool_exp[] | null;
  _not?: message_adhoc_form_bool_exp | null;
  _or?: message_adhoc_form_bool_exp[] | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  message_adhoc?: message_adhoc_bool_exp | null;
  message_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc_form"
 */
export interface message_adhoc_form_insert_input {
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  id?: uuid | null;
  message_adhoc?: message_adhoc_obj_rel_insert_input | null;
  message_id?: uuid | null;
}

/**
 * order by max() on columns of table "message_adhoc_form"
 */
export interface message_adhoc_form_max_order_by {
  form_id?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
}

/**
 * order by min() on columns of table "message_adhoc_form"
 */
export interface message_adhoc_form_min_order_by {
  form_id?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
}

/**
 * on_conflict condition type for table "message_adhoc_form"
 */
export interface message_adhoc_form_on_conflict {
  constraint: message_adhoc_form_constraint;
  update_columns: message_adhoc_form_update_column[];
  where?: message_adhoc_form_bool_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc"
 */
export interface message_adhoc_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  message_adhoc_forms?: message_adhoc_form_arr_rel_insert_input | null;
  message_adhoc_payload_email?: message_adhoc_payload_email_obj_rel_insert_input | null;
  message_adhoc_payload_rich_contents?: message_adhoc_payload_rich_content_arr_rel_insert_input | null;
  message_adhoc_payload_sms?: message_adhoc_payload_sms_obj_rel_insert_input | null;
  message_adhoc_receivers?: message_adhoc_receiver_arr_rel_insert_input | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  sender_person?: person_obj_rel_insert_input | null;
  sender_person_id?: uuid | null;
}

/**
 * input type for inserting object relation for remote table "message_adhoc"
 */
export interface message_adhoc_obj_rel_insert_input {
  data: message_adhoc_insert_input;
  on_conflict?: message_adhoc_on_conflict | null;
}

/**
 * on_conflict condition type for table "message_adhoc"
 */
export interface message_adhoc_on_conflict {
  constraint: message_adhoc_constraint;
  update_columns: message_adhoc_update_column[];
  where?: message_adhoc_bool_exp | null;
}

/**
 * Ordering options when selecting data from "message_adhoc".
 */
export interface message_adhoc_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  message_adhoc_forms_aggregate?: message_adhoc_form_aggregate_order_by | null;
  message_adhoc_payload_email?: message_adhoc_payload_email_order_by | null;
  message_adhoc_payload_rich_contents_aggregate?: message_adhoc_payload_rich_content_aggregate_order_by | null;
  message_adhoc_payload_sms?: message_adhoc_payload_sms_order_by | null;
  message_adhoc_receivers_aggregate?: message_adhoc_receiver_aggregate_order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  sender_person?: person_order_by | null;
  sender_person_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc_payload_email". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_payload_email_bool_exp {
  _and?: message_adhoc_payload_email_bool_exp[] | null;
  _not?: message_adhoc_payload_email_bool_exp | null;
  _or?: message_adhoc_payload_email_bool_exp[] | null;
  message_adhoc?: message_adhoc_bool_exp | null;
  message_body?: String_comparison_exp | null;
  message_id?: uuid_comparison_exp | null;
  message_subject?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc_payload_email"
 */
export interface message_adhoc_payload_email_insert_input {
  message_adhoc?: message_adhoc_obj_rel_insert_input | null;
  message_body?: string | null;
  message_id?: uuid | null;
  message_subject?: string | null;
}

/**
 * input type for inserting object relation for remote table "message_adhoc_payload_email"
 */
export interface message_adhoc_payload_email_obj_rel_insert_input {
  data: message_adhoc_payload_email_insert_input;
  on_conflict?: message_adhoc_payload_email_on_conflict | null;
}

/**
 * on_conflict condition type for table "message_adhoc_payload_email"
 */
export interface message_adhoc_payload_email_on_conflict {
  constraint: message_adhoc_payload_email_constraint;
  update_columns: message_adhoc_payload_email_update_column[];
  where?: message_adhoc_payload_email_bool_exp | null;
}

/**
 * Ordering options when selecting data from "message_adhoc_payload_email".
 */
export interface message_adhoc_payload_email_order_by {
  message_adhoc?: message_adhoc_order_by | null;
  message_body?: order_by | null;
  message_id?: order_by | null;
  message_subject?: order_by | null;
}

export interface message_adhoc_payload_rich_content_aggregate_bool_exp {
  count?: message_adhoc_payload_rich_content_aggregate_bool_exp_count | null;
}

export interface message_adhoc_payload_rich_content_aggregate_bool_exp_count {
  arguments?: message_adhoc_payload_rich_content_select_column[] | null;
  distinct?: boolean | null;
  filter?: message_adhoc_payload_rich_content_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_aggregate_order_by {
  count?: order_by | null;
  max?: message_adhoc_payload_rich_content_max_order_by | null;
  min?: message_adhoc_payload_rich_content_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_arr_rel_insert_input {
  data: message_adhoc_payload_rich_content_insert_input[];
  on_conflict?: message_adhoc_payload_rich_content_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc_payload_rich_content". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_payload_rich_content_bool_exp {
  _and?: message_adhoc_payload_rich_content_bool_exp[] | null;
  _not?: message_adhoc_payload_rich_content_bool_exp | null;
  _or?: message_adhoc_payload_rich_content_bool_exp[] | null;
  content_id?: uuid_comparison_exp | null;
  message_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_insert_input {
  content_id?: uuid | null;
  message_id?: uuid | null;
}

/**
 * order by max() on columns of table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_max_order_by {
  content_id?: order_by | null;
  message_id?: order_by | null;
}

/**
 * order by min() on columns of table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_min_order_by {
  content_id?: order_by | null;
  message_id?: order_by | null;
}

/**
 * on_conflict condition type for table "message_adhoc_payload_rich_content"
 */
export interface message_adhoc_payload_rich_content_on_conflict {
  constraint: message_adhoc_payload_rich_content_constraint;
  update_columns: message_adhoc_payload_rich_content_update_column[];
  where?: message_adhoc_payload_rich_content_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc_payload_sms". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_payload_sms_bool_exp {
  _and?: message_adhoc_payload_sms_bool_exp[] | null;
  _not?: message_adhoc_payload_sms_bool_exp | null;
  _or?: message_adhoc_payload_sms_bool_exp[] | null;
  message_adhoc?: message_adhoc_bool_exp | null;
  message_body?: String_comparison_exp | null;
  message_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc_payload_sms"
 */
export interface message_adhoc_payload_sms_insert_input {
  message_adhoc?: message_adhoc_obj_rel_insert_input | null;
  message_body?: string | null;
  message_id?: uuid | null;
}

/**
 * input type for inserting object relation for remote table "message_adhoc_payload_sms"
 */
export interface message_adhoc_payload_sms_obj_rel_insert_input {
  data: message_adhoc_payload_sms_insert_input;
  on_conflict?: message_adhoc_payload_sms_on_conflict | null;
}

/**
 * on_conflict condition type for table "message_adhoc_payload_sms"
 */
export interface message_adhoc_payload_sms_on_conflict {
  constraint: message_adhoc_payload_sms_constraint;
  update_columns: message_adhoc_payload_sms_update_column[];
  where?: message_adhoc_payload_sms_bool_exp | null;
}

/**
 * Ordering options when selecting data from "message_adhoc_payload_sms".
 */
export interface message_adhoc_payload_sms_order_by {
  message_adhoc?: message_adhoc_order_by | null;
  message_body?: order_by | null;
  message_id?: order_by | null;
}

export interface message_adhoc_receiver_aggregate_bool_exp {
  count?: message_adhoc_receiver_aggregate_bool_exp_count | null;
}

export interface message_adhoc_receiver_aggregate_bool_exp_count {
  arguments?: message_adhoc_receiver_select_column[] | null;
  distinct?: boolean | null;
  filter?: message_adhoc_receiver_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_aggregate_order_by {
  count?: order_by | null;
  max?: message_adhoc_receiver_max_order_by | null;
  min?: message_adhoc_receiver_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_arr_rel_insert_input {
  data: message_adhoc_receiver_insert_input[];
  on_conflict?: message_adhoc_receiver_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "message_adhoc_receiver". All fields are combined with a logical 'AND'.
 */
export interface message_adhoc_receiver_bool_exp {
  _and?: message_adhoc_receiver_bool_exp[] | null;
  _not?: message_adhoc_receiver_bool_exp | null;
  _or?: message_adhoc_receiver_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  message_adhoc?: message_adhoc_bool_exp | null;
  message_id?: uuid_comparison_exp | null;
  message_status?: message_status_enum_comparison_exp | null;
  message_type?: message_type_enum_comparison_exp | null;
  receiver_contact_information?: String_comparison_exp | null;
  receiver_person?: person_bool_exp | null;
  receiver_person_id?: uuid_comparison_exp | null;
  receiver_user_schools?: applicant_school_guardian_user_bool_exp | null;
  receiver_user_schools_aggregate?: applicant_school_guardian_user_aggregate_bool_exp | null;
  sent_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  message_adhoc?: message_adhoc_obj_rel_insert_input | null;
  message_id?: uuid | null;
  message_status?: message_status_enum | null;
  message_type?: message_type_enum | null;
  receiver_contact_information?: string | null;
  receiver_person?: person_obj_rel_insert_input | null;
  receiver_person_id?: uuid | null;
  receiver_user_schools?: applicant_school_guardian_user_arr_rel_insert_input | null;
  sent_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
  receiver_contact_information?: order_by | null;
  receiver_person_id?: order_by | null;
  sent_at?: order_by | null;
}

/**
 * order by min() on columns of table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
  receiver_contact_information?: order_by | null;
  receiver_person_id?: order_by | null;
  sent_at?: order_by | null;
}

/**
 * on_conflict condition type for table "message_adhoc_receiver"
 */
export interface message_adhoc_receiver_on_conflict {
  constraint: message_adhoc_receiver_constraint;
  update_columns: message_adhoc_receiver_update_column[];
  where?: message_adhoc_receiver_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "message_branding". All fields are combined with a logical 'AND'.
 */
export interface message_branding_bool_exp {
  _and?: message_branding_bool_exp[] | null;
  _not?: message_branding_bool_exp | null;
  _or?: message_branding_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  footer?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  logo_url?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  support_email?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_branding"
 */
export interface message_branding_insert_input {
  created_at?: timestamptz | null;
  footer?: string | null;
  id?: uuid | null;
  logo_url?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  support_email?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "message_branding"
 */
export interface message_branding_obj_rel_insert_input {
  data: message_branding_insert_input;
  on_conflict?: message_branding_on_conflict | null;
}

/**
 * on_conflict condition type for table "message_branding"
 */
export interface message_branding_on_conflict {
  constraint: message_branding_constraint;
  update_columns: message_branding_update_column[];
  where?: message_branding_bool_exp | null;
}

/**
 * Ordering options when selecting data from "message_branding".
 */
export interface message_branding_order_by {
  created_at?: order_by | null;
  footer?: order_by | null;
  id?: order_by | null;
  logo_url?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  support_email?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "message_image_type_enum". All fields are combined with logical 'AND'.
 */
export interface message_image_type_enum_comparison_exp {
  _eq?: message_image_type_enum | null;
  _in?: message_image_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: message_image_type_enum | null;
  _nin?: message_image_type_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "message_status_enum". All fields are combined with logical 'AND'.
 */
export interface message_status_enum_comparison_exp {
  _eq?: message_status_enum | null;
  _in?: message_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: message_status_enum | null;
  _nin?: message_status_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "message_template". All fields are combined with a logical 'AND'.
 */
export interface message_template_bool_exp {
  _and?: message_template_bool_exp[] | null;
  _not?: message_template_bool_exp | null;
  _or?: message_template_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  email_markup?: String_comparison_exp | null;
  email_plain_text?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  enabled?: Boolean_comparison_exp | null;
  form_template?: form_template_bool_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  message_template_type?: message_template_type_bool_exp | null;
  sms_body?: String_comparison_exp | null;
  type?: message_template_type_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "message_template"
 */
export interface message_template_insert_input {
  created_at?: timestamptz | null;
  email_markup?: string | null;
  email_plain_text?: string | null;
  email_subject?: string | null;
  enabled?: boolean | null;
  form_template?: form_template_obj_rel_insert_input | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  message_template_type?: message_template_type_obj_rel_insert_input | null;
  sms_body?: string | null;
  type?: message_template_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for updating data in table "message_template"
 */
export interface message_template_set_input {
  created_at?: timestamptz | null;
  email_markup?: string | null;
  email_plain_text?: string | null;
  email_subject?: string | null;
  enabled?: boolean | null;
  form_template_id?: uuid | null;
  id?: uuid | null;
  sms_body?: string | null;
  type?: message_template_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to filter rows from the table "message_template_type". All fields are combined with a logical 'AND'.
 */
export interface message_template_type_bool_exp {
  _and?: message_template_type_bool_exp[] | null;
  _not?: message_template_type_bool_exp | null;
  _or?: message_template_type_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "message_template_type_enum". All fields are combined with logical 'AND'.
 */
export interface message_template_type_enum_comparison_exp {
  _eq?: message_template_type_enum | null;
  _in?: message_template_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: message_template_type_enum | null;
  _nin?: message_template_type_enum[] | null;
}

/**
 * input type for inserting data into table "message_template_type"
 */
export interface message_template_type_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "message_template_type"
 */
export interface message_template_type_obj_rel_insert_input {
  data: message_template_type_insert_input;
  on_conflict?: message_template_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "message_template_type"
 */
export interface message_template_type_on_conflict {
  constraint: message_template_type_constraint;
  update_columns: message_template_type_update_column[];
  where?: message_template_type_bool_exp | null;
}

export interface message_template_updates {
  _set?: message_template_set_input | null;
  where: message_template_bool_exp;
}

/**
 * Boolean expression to compare columns of type "message_type_enum". All fields are combined with logical 'AND'.
 */
export interface message_type_enum_comparison_exp {
  _eq?: message_type_enum | null;
  _in?: message_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: message_type_enum | null;
  _nin?: message_type_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: numeric | null;
  _gt?: numeric | null;
  _gte?: numeric | null;
  _in?: numeric[] | null;
  _is_null?: boolean | null;
  _lt?: numeric | null;
  _lte?: numeric | null;
  _neq?: numeric | null;
  _nin?: numeric[] | null;
}

export interface offer_aggregate_bool_exp {
  count?: offer_aggregate_bool_exp_count | null;
}

export interface offer_aggregate_bool_exp_count {
  arguments?: offer_select_column[] | null;
  distinct?: boolean | null;
  filter?: offer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "offer"
 */
export interface offer_aggregate_order_by {
  count?: order_by | null;
  max?: offer_max_order_by | null;
  min?: offer_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "offer"
 */
export interface offer_arr_rel_insert_input {
  data: offer_insert_input[];
  on_conflict?: offer_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "offer". All fields are combined with a logical 'AND'.
 */
export interface offer_bool_exp {
  _and?: offer_bool_exp[] | null;
  _not?: offer_bool_exp | null;
  _or?: offer_bool_exp[] | null;
  assigned_forms?: assigned_form_bool_exp | null;
  assigned_forms_aggregate?: assigned_form_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  offer_status?: offer_status_bool_exp | null;
  previous_forms?: form_bool_exp | null;
  previous_forms_aggregate?: form_aggregate_bool_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  status?: offer_status_enum_comparison_exp | null;
  status_updated_at?: timestamptz_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "offer"
 */
export interface offer_insert_input {
  assigned_forms?: assigned_form_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  id?: uuid | null;
  offer_status?: offer_status_obj_rel_insert_input | null;
  previous_forms?: form_arr_rel_insert_input | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  status?: offer_status_enum | null;
  status_updated_at?: timestamptz | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "offer"
 */
export interface offer_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_id?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "offer"
 */
export interface offer_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_id?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "offer"
 */
export interface offer_obj_rel_insert_input {
  data: offer_insert_input;
  on_conflict?: offer_on_conflict | null;
}

/**
 * on_conflict condition type for table "offer"
 */
export interface offer_on_conflict {
  constraint: offer_constraint;
  update_columns: offer_update_column[];
  where?: offer_bool_exp | null;
}

/**
 * Ordering options when selecting data from "offer".
 */
export interface offer_order_by {
  assigned_forms_aggregate?: assigned_form_aggregate_order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form?: form_order_by | null;
  form_id?: order_by | null;
  grade?: grade_order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  offer_status?: offer_status_order_by | null;
  previous_forms_aggregate?: form_aggregate_order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  status?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "offer_status". All fields are combined with a logical 'AND'.
 */
export interface offer_status_bool_exp {
  _and?: offer_status_bool_exp[] | null;
  _not?: offer_status_bool_exp | null;
  _or?: offer_status_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "offer_status_enum". All fields are combined with logical 'AND'.
 */
export interface offer_status_enum_comparison_exp {
  _eq?: offer_status_enum | null;
  _in?: offer_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: offer_status_enum | null;
  _nin?: offer_status_enum[] | null;
}

/**
 * input type for inserting data into table "offer_status"
 */
export interface offer_status_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "offer_status"
 */
export interface offer_status_obj_rel_insert_input {
  data: offer_status_insert_input;
  on_conflict?: offer_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "offer_status"
 */
export interface offer_status_on_conflict {
  constraint: offer_status_constraint;
  update_columns: offer_status_update_column[];
  where?: offer_status_bool_exp | null;
}

/**
 * Ordering options when selecting data from "offer_status".
 */
export interface offer_status_order_by {
  value?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'.
 */
export interface oid_comparison_exp {
  _eq?: oid | null;
  _gt?: oid | null;
  _gte?: oid | null;
  _in?: oid[] | null;
  _is_null?: boolean | null;
  _lt?: oid | null;
  _lte?: oid | null;
  _neq?: oid | null;
  _nin?: oid[] | null;
}

/**
 * Boolean expression to filter rows from the table "operator". All fields are combined with a logical 'AND'.
 */
export interface operator_bool_exp {
  _and?: operator_bool_exp[] | null;
  _not?: operator_bool_exp | null;
  _or?: operator_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "operator_enum". All fields are combined with logical 'AND'.
 */
export interface operator_enum_comparison_exp {
  _eq?: operator_enum | null;
  _in?: operator_enum[] | null;
  _is_null?: boolean | null;
  _neq?: operator_enum | null;
  _nin?: operator_enum[] | null;
}

/**
 * input type for inserting data into table "operator"
 */
export interface operator_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "operator"
 */
export interface operator_obj_rel_insert_input {
  data: operator_insert_input;
  on_conflict?: operator_on_conflict | null;
}

/**
 * on_conflict condition type for table "operator"
 */
export interface operator_on_conflict {
  constraint: operator_constraint;
  update_columns: operator_update_column[];
  where?: operator_bool_exp | null;
}

/**
 * Ordering options when selecting data from "operator".
 */
export interface operator_order_by {
  value?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'.
 */
export interface organization_bool_exp {
  _and?: organization_bool_exp[] | null;
  _not?: organization_bool_exp | null;
  _or?: organization_bool_exp[] | null;
  active_match_template?: match_template_organization_bool_exp | null;
  announcements?: announcement_bool_exp | null;
  announcements_aggregate?: announcement_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_periods?: enrollment_period_bool_exp | null;
  enrollment_periods_aggregate?: enrollment_period_aggregate_bool_exp | null;
  error?: jsonb_comparison_exp | null;
  grade_configs?: grade_config_bool_exp | null;
  grade_configs_aggregate?: grade_config_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  match_templates?: match_template_bool_exp | null;
  match_templates_aggregate?: match_template_aggregate_bool_exp | null;
  message_branding?: message_branding_bool_exp | null;
  name?: String_comparison_exp | null;
  organization_branding?: organization_branding_bool_exp | null;
  organization_configs?: organization_config_bool_exp | null;
  organization_configs_aggregate?: organization_config_aggregate_bool_exp | null;
  path?: String_comparison_exp | null;
  people?: person_bool_exp | null;
  people_aggregate?: person_aggregate_bool_exp | null;
  program_groups?: program_group_bool_exp | null;
  program_groups_aggregate?: program_group_aggregate_bool_exp | null;
  schools?: school_bool_exp | null;
  schools_aggregate?: school_aggregate_bool_exp | null;
  status?: organization_status_enum_comparison_exp | null;
  timezone_name?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "organization_branding". All fields are combined with a logical 'AND'.
 */
export interface organization_branding_bool_exp {
  _and?: organization_branding_bool_exp[] | null;
  _not?: organization_branding_bool_exp | null;
  _or?: organization_branding_bool_exp[] | null;
  color_100?: String_comparison_exp | null;
  color_200?: String_comparison_exp | null;
  color_300?: String_comparison_exp | null;
  color_400?: String_comparison_exp | null;
  color_50?: String_comparison_exp | null;
  color_500?: String_comparison_exp | null;
  color_600?: String_comparison_exp | null;
  color_700?: String_comparison_exp | null;
  color_800?: String_comparison_exp | null;
  color_900?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  logo_color_url?: String_comparison_exp | null;
  logo_white_url?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "organization_branding"
 */
export interface organization_branding_insert_input {
  color_100?: string | null;
  color_200?: string | null;
  color_300?: string | null;
  color_400?: string | null;
  color_50?: string | null;
  color_500?: string | null;
  color_600?: string | null;
  color_700?: string | null;
  color_800?: string | null;
  color_900?: string | null;
  created_at?: timestamptz | null;
  id?: uuid | null;
  logo_color_url?: string | null;
  logo_white_url?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "organization_branding"
 */
export interface organization_branding_obj_rel_insert_input {
  data: organization_branding_insert_input;
  on_conflict?: organization_branding_on_conflict | null;
}

/**
 * on_conflict condition type for table "organization_branding"
 */
export interface organization_branding_on_conflict {
  constraint: organization_branding_constraint;
  update_columns: organization_branding_update_column[];
  where?: organization_branding_bool_exp | null;
}

/**
 * Ordering options when selecting data from "organization_branding".
 */
export interface organization_branding_order_by {
  color_100?: order_by | null;
  color_200?: order_by | null;
  color_300?: order_by | null;
  color_400?: order_by | null;
  color_50?: order_by | null;
  color_500?: order_by | null;
  color_600?: order_by | null;
  color_700?: order_by | null;
  color_800?: order_by | null;
  color_900?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  logo_color_url?: order_by | null;
  logo_white_url?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

export interface organization_config_aggregate_bool_exp {
  bool_and?: organization_config_aggregate_bool_exp_bool_and | null;
  bool_or?: organization_config_aggregate_bool_exp_bool_or | null;
  count?: organization_config_aggregate_bool_exp_count | null;
}

export interface organization_config_aggregate_bool_exp_bool_and {
  arguments: organization_config_select_column_organization_config_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: organization_config_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface organization_config_aggregate_bool_exp_bool_or {
  arguments: organization_config_select_column_organization_config_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: organization_config_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface organization_config_aggregate_bool_exp_count {
  arguments?: organization_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: organization_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "organization_config"
 */
export interface organization_config_aggregate_order_by {
  count?: order_by | null;
  max?: organization_config_max_order_by | null;
  min?: organization_config_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "organization_config"
 */
export interface organization_config_arr_rel_insert_input {
  data: organization_config_insert_input[];
  on_conflict?: organization_config_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "organization_config". All fields are combined with a logical 'AND'.
 */
export interface organization_config_bool_exp {
  _and?: organization_config_bool_exp[] | null;
  _not?: organization_config_bool_exp | null;
  _or?: organization_config_bool_exp[] | null;
  data?: jsonb_comparison_exp | null;
  enabled?: Boolean_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_config_type?: organization_config_type_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  public?: Boolean_comparison_exp | null;
  type?: organization_config_type_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "organization_config"
 */
export interface organization_config_insert_input {
  data?: jsonb | null;
  enabled?: boolean | null;
  id?: uuid | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_config_type?: organization_config_type_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  public?: boolean | null;
  type?: organization_config_type_enum | null;
}

/**
 * order by max() on columns of table "organization_config"
 */
export interface organization_config_max_order_by {
  id?: order_by | null;
  organization_id?: order_by | null;
}

/**
 * order by min() on columns of table "organization_config"
 */
export interface organization_config_min_order_by {
  id?: order_by | null;
  organization_id?: order_by | null;
}

/**
 * on_conflict condition type for table "organization_config"
 */
export interface organization_config_on_conflict {
  constraint: organization_config_constraint;
  update_columns: organization_config_update_column[];
  where?: organization_config_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "organization_config_type". All fields are combined with a logical 'AND'.
 */
export interface organization_config_type_bool_exp {
  _and?: organization_config_type_bool_exp[] | null;
  _not?: organization_config_type_bool_exp | null;
  _or?: organization_config_type_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "organization_config_type_enum". All fields are combined with logical 'AND'.
 */
export interface organization_config_type_enum_comparison_exp {
  _eq?: organization_config_type_enum | null;
  _in?: organization_config_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: organization_config_type_enum | null;
  _nin?: organization_config_type_enum[] | null;
}

/**
 * input type for inserting data into table "organization_config_type"
 */
export interface organization_config_type_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "organization_config_type"
 */
export interface organization_config_type_obj_rel_insert_input {
  data: organization_config_type_insert_input;
  on_conflict?: organization_config_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "organization_config_type"
 */
export interface organization_config_type_on_conflict {
  constraint: organization_config_type_constraint;
  update_columns: organization_config_type_update_column[];
  where?: organization_config_type_bool_exp | null;
}

/**
 * input type for inserting data into table "organization"
 */
export interface organization_insert_input {
  active_match_template?: match_template_organization_obj_rel_insert_input | null;
  announcements?: announcement_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  enrollment_periods?: enrollment_period_arr_rel_insert_input | null;
  error?: jsonb | null;
  grade_configs?: grade_config_arr_rel_insert_input | null;
  id?: uuid | null;
  match_templates?: match_template_arr_rel_insert_input | null;
  message_branding?: message_branding_obj_rel_insert_input | null;
  name?: string | null;
  organization_branding?: organization_branding_obj_rel_insert_input | null;
  organization_configs?: organization_config_arr_rel_insert_input | null;
  path?: string | null;
  people?: person_arr_rel_insert_input | null;
  program_groups?: program_group_arr_rel_insert_input | null;
  schools?: school_arr_rel_insert_input | null;
  status?: organization_status_enum | null;
  timezone_name?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "organization"
 */
export interface organization_obj_rel_insert_input {
  data: organization_insert_input;
  on_conflict?: organization_on_conflict | null;
}

/**
 * on_conflict condition type for table "organization"
 */
export interface organization_on_conflict {
  constraint: organization_constraint;
  update_columns: organization_update_column[];
  where?: organization_bool_exp | null;
}

/**
 * Ordering options when selecting data from "organization".
 */
export interface organization_order_by {
  active_match_template?: match_template_organization_order_by | null;
  announcements_aggregate?: announcement_aggregate_order_by | null;
  created_at?: order_by | null;
  enrollment_periods_aggregate?: enrollment_period_aggregate_order_by | null;
  error?: order_by | null;
  grade_configs_aggregate?: grade_config_aggregate_order_by | null;
  id?: order_by | null;
  match_templates_aggregate?: match_template_aggregate_order_by | null;
  message_branding?: message_branding_order_by | null;
  name?: order_by | null;
  organization_branding?: organization_branding_order_by | null;
  organization_configs_aggregate?: organization_config_aggregate_order_by | null;
  path?: order_by | null;
  people_aggregate?: person_aggregate_order_by | null;
  program_groups_aggregate?: program_group_aggregate_order_by | null;
  schools_aggregate?: school_aggregate_order_by | null;
  status?: order_by | null;
  timezone_name?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "organization"
 */
export interface organization_set_input {
  created_at?: timestamptz | null;
  error?: jsonb | null;
  id?: uuid | null;
  name?: string | null;
  path?: string | null;
  status?: organization_status_enum | null;
  timezone_name?: string | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to compare columns of type "organization_status_enum". All fields are combined with logical 'AND'.
 */
export interface organization_status_enum_comparison_exp {
  _eq?: organization_status_enum | null;
  _in?: organization_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: organization_status_enum | null;
  _nin?: organization_status_enum[] | null;
}

export interface person_aggregate_bool_exp {
  bool_and?: person_aggregate_bool_exp_bool_and | null;
  bool_or?: person_aggregate_bool_exp_bool_or | null;
  count?: person_aggregate_bool_exp_count | null;
}

export interface person_aggregate_bool_exp_bool_and {
  arguments: person_select_column_person_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: person_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface person_aggregate_bool_exp_bool_or {
  arguments: person_select_column_person_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: person_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface person_aggregate_bool_exp_count {
  arguments?: person_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "person"
 */
export interface person_aggregate_order_by {
  count?: order_by | null;
  max?: person_max_order_by | null;
  min?: person_min_order_by | null;
}

export interface person_answer_aggregate_bool_exp {
  count?: person_answer_aggregate_bool_exp_count | null;
}

export interface person_answer_aggregate_bool_exp_count {
  arguments?: person_answer_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_answer_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "person_answer"
 */
export interface person_answer_arr_rel_insert_input {
  data: person_answer_insert_input[];
  on_conflict?: person_answer_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "person_answer_bank". All fields are combined with a logical 'AND'.
 */
export interface person_answer_bank_bool_exp {
  _and?: person_answer_bank_bool_exp[] | null;
  _not?: person_answer_bank_bool_exp | null;
  _or?: person_answer_bank_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_bool_exp | null;
  custom_question_answer_bank_relationships_aggregate?: custom_question_answer_bank_relationship_aggregate_bool_exp | null;
  custom_question_type?: custom_question_type_bool_exp | null;
  custom_question_type_id?: uuid_comparison_exp | null;
  digest?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  last_used_at?: timestamptz_comparison_exp | null;
  person?: person_bool_exp | null;
  person_answer_bank_external_relationship?: person_answer_bank_external_relationship_bool_exp | null;
  person_answers?: person_answer_bool_exp | null;
  person_answers_aggregate?: person_answer_aggregate_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "person_answer_bank_external_relationship". All fields are combined with a logical 'AND'.
 */
export interface person_answer_bank_external_relationship_bool_exp {
  _and?: person_answer_bank_external_relationship_bool_exp[] | null;
  _not?: person_answer_bank_external_relationship_bool_exp | null;
  _or?: person_answer_bank_external_relationship_bool_exp[] | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person_answer_bank?: person_answer_bank_bool_exp | null;
  person_answer_bank_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_answer_bank_external_relationship"
 */
export interface person_answer_bank_external_relationship_insert_input {
  external_id?: string | null;
  id?: uuid | null;
  person_answer_bank?: person_answer_bank_obj_rel_insert_input | null;
  person_answer_bank_id?: uuid | null;
}

/**
 * input type for inserting object relation for remote table "person_answer_bank_external_relationship"
 */
export interface person_answer_bank_external_relationship_obj_rel_insert_input {
  data: person_answer_bank_external_relationship_insert_input;
  on_conflict?: person_answer_bank_external_relationship_on_conflict | null;
}

/**
 * on_conflict condition type for table "person_answer_bank_external_relationship"
 */
export interface person_answer_bank_external_relationship_on_conflict {
  constraint: person_answer_bank_external_relationship_constraint;
  update_columns: person_answer_bank_external_relationship_update_column[];
  where?: person_answer_bank_external_relationship_bool_exp | null;
}

/**
 * input type for inserting data into table "person_answer_bank"
 */
export interface person_answer_bank_insert_input {
  created_at?: timestamptz | null;
  custom_question_answer_bank_relationships?: custom_question_answer_bank_relationship_arr_rel_insert_input | null;
  custom_question_type?: custom_question_type_obj_rel_insert_input | null;
  custom_question_type_id?: uuid | null;
  digest?: string | null;
  id?: uuid | null;
  last_used_at?: timestamptz | null;
  person?: person_obj_rel_insert_input | null;
  person_answer_bank_external_relationship?: person_answer_bank_external_relationship_obj_rel_insert_input | null;
  person_answers?: person_answer_arr_rel_insert_input | null;
  person_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "person_answer_bank"
 */
export interface person_answer_bank_obj_rel_insert_input {
  data: person_answer_bank_insert_input;
  on_conflict?: person_answer_bank_on_conflict | null;
}

/**
 * on_conflict condition type for table "person_answer_bank"
 */
export interface person_answer_bank_on_conflict {
  constraint: person_answer_bank_constraint;
  update_columns: person_answer_bank_update_column[];
  where?: person_answer_bank_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "person_answer". All fields are combined with a logical 'AND'.
 */
export interface person_answer_bool_exp {
  _and?: person_answer_bool_exp[] | null;
  _not?: person_answer_bool_exp | null;
  _or?: person_answer_bool_exp[] | null;
  answer?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question_type_field?: custom_question_type_field_bool_exp | null;
  custom_question_type_field_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person_answer_bank?: person_answer_bank_bool_exp | null;
  person_answer_bank_id?: uuid_comparison_exp | null;
  person_answer_options?: person_answer_option_bool_exp | null;
  person_answer_options_aggregate?: person_answer_option_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_answer"
 */
export interface person_answer_insert_input {
  answer?: string | null;
  created_at?: timestamptz | null;
  custom_question_type_field?: custom_question_type_field_obj_rel_insert_input | null;
  custom_question_type_field_id?: uuid | null;
  id?: uuid | null;
  person_answer_bank?: person_answer_bank_obj_rel_insert_input | null;
  person_answer_bank_id?: uuid | null;
  person_answer_options?: person_answer_option_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "person_answer"
 */
export interface person_answer_obj_rel_insert_input {
  data: person_answer_insert_input;
  on_conflict?: person_answer_on_conflict | null;
}

/**
 * on_conflict condition type for table "person_answer"
 */
export interface person_answer_on_conflict {
  constraint: person_answer_constraint;
  update_columns: person_answer_update_column[];
  where?: person_answer_bool_exp | null;
}

export interface person_answer_option_aggregate_bool_exp {
  count?: person_answer_option_aggregate_bool_exp_count | null;
}

export interface person_answer_option_aggregate_bool_exp_count {
  arguments?: person_answer_option_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_answer_option_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "person_answer_option"
 */
export interface person_answer_option_arr_rel_insert_input {
  data: person_answer_option_insert_input[];
  on_conflict?: person_answer_option_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "person_answer_option". All fields are combined with a logical 'AND'.
 */
export interface person_answer_option_bool_exp {
  _and?: person_answer_option_bool_exp[] | null;
  _not?: person_answer_option_bool_exp | null;
  _or?: person_answer_option_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  form_question_option?: form_question_option_bool_exp | null;
  form_question_option_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  person_answer?: person_answer_bool_exp | null;
  person_answer_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_answer_option"
 */
export interface person_answer_option_insert_input {
  created_at?: timestamptz | null;
  form_question_option?: form_question_option_obj_rel_insert_input | null;
  form_question_option_id?: uuid | null;
  id?: uuid | null;
  person_answer?: person_answer_obj_rel_insert_input | null;
  person_answer_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * on_conflict condition type for table "person_answer_option"
 */
export interface person_answer_option_on_conflict {
  constraint: person_answer_option_constraint;
  update_columns: person_answer_option_update_column[];
  where?: person_answer_option_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "person"
 */
export interface person_arr_rel_insert_input {
  data: person_insert_input[];
  on_conflict?: person_on_conflict | null;
}

export interface person_bin_aggregate_bool_exp {
  count?: person_bin_aggregate_bool_exp_count | null;
}

export interface person_bin_aggregate_bool_exp_count {
  arguments?: person_bin_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_bin_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "person_bin"
 */
export interface person_bin_aggregate_order_by {
  avg?: person_bin_avg_order_by | null;
  count?: order_by | null;
  max?: person_bin_max_order_by | null;
  min?: person_bin_min_order_by | null;
  stddev?: person_bin_stddev_order_by | null;
  stddev_pop?: person_bin_stddev_pop_order_by | null;
  stddev_samp?: person_bin_stddev_samp_order_by | null;
  sum?: person_bin_sum_order_by | null;
  var_pop?: person_bin_var_pop_order_by | null;
  var_samp?: person_bin_var_samp_order_by | null;
  variance?: person_bin_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "person_bin"
 */
export interface person_bin_arr_rel_insert_input {
  data: person_bin_insert_input[];
  on_conflict?: person_bin_on_conflict | null;
}

/**
 * order by avg() on columns of table "person_bin"
 */
export interface person_bin_avg_order_by {
  lottery_number?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "person_bin". All fields are combined with a logical 'AND'.
 */
export interface person_bin_bool_exp {
  _and?: person_bin_bool_exp[] | null;
  _not?: person_bin_bool_exp | null;
  _or?: person_bin_bool_exp[] | null;
  bin?: bin_bool_exp | null;
  bin_id?: uuid_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  lottery_number?: Int_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_bin"
 */
export interface person_bin_insert_input {
  bin?: bin_obj_rel_insert_input | null;
  bin_id?: uuid | null;
  created_at?: timestamptz | null;
  id?: uuid | null;
  lottery_number?: number | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "person_bin"
 */
export interface person_bin_max_order_by {
  bin_id?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  lottery_number?: order_by | null;
  person_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "person_bin"
 */
export interface person_bin_min_order_by {
  bin_id?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  lottery_number?: order_by | null;
  person_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "person_bin"
 */
export interface person_bin_obj_rel_insert_input {
  data: person_bin_insert_input;
  on_conflict?: person_bin_on_conflict | null;
}

/**
 * on_conflict condition type for table "person_bin"
 */
export interface person_bin_on_conflict {
  constraint: person_bin_constraint;
  update_columns: person_bin_update_column[];
  where?: person_bin_bool_exp | null;
}

/**
 * order by stddev() on columns of table "person_bin"
 */
export interface person_bin_stddev_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "person_bin"
 */
export interface person_bin_stddev_pop_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "person_bin"
 */
export interface person_bin_stddev_samp_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by sum() on columns of table "person_bin"
 */
export interface person_bin_sum_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by var_pop() on columns of table "person_bin"
 */
export interface person_bin_var_pop_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by var_samp() on columns of table "person_bin"
 */
export interface person_bin_var_samp_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by variance() on columns of table "person_bin"
 */
export interface person_bin_variance_order_by {
  lottery_number?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "person". All fields are combined with a logical 'AND'.
 */
export interface person_bool_exp {
  _and?: person_bool_exp[] | null;
  _not?: person_bool_exp | null;
  _or?: person_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  applicant_attending_schools?: applicant_attending_school_bool_exp | null;
  applicant_attending_schools_aggregate?: applicant_attending_school_aggregate_bool_exp | null;
  assigned_forms?: assigned_form_bool_exp | null;
  assigned_forms_aggregate?: assigned_form_aggregate_bool_exp | null;
  avatar?: String_comparison_exp | null;
  birth_date?: date_comparison_exp | null;
  city?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  email_address?: String_comparison_exp | null;
  email_okay?: Boolean_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  first_relationship?: relationship_person_bool_exp | null;
  first_relationship_aggregate?: relationship_person_aggregate_bool_exp | null;
  forms?: form_bool_exp | null;
  forms_aggregate?: form_aggregate_bool_exp | null;
  full_name?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  id_text?: String_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  middle_name?: String_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  person_bins?: person_bin_bool_exp | null;
  person_bins_aggregate?: person_bin_aggregate_bool_exp | null;
  person_teams?: person_team_bool_exp | null;
  person_teams_aggregate?: person_team_aggregate_bool_exp | null;
  person_type?: person_type_enum_comparison_exp | null;
  phone_number?: String_comparison_exp | null;
  preferred_language?: String_comparison_exp | null;
  reference_id?: String_comparison_exp | null;
  searchable_text?: String_comparison_exp | null;
  second_relationship?: relationship_person_bool_exp | null;
  second_relationship_aggregate?: relationship_person_aggregate_bool_exp | null;
  sms_okay?: Boolean_comparison_exp | null;
  state?: String_comparison_exp | null;
  street_address?: String_comparison_exp | null;
  street_address_line_2?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: user_bool_exp | null;
  user_id?: String_comparison_exp | null;
  zip_code?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "person"
 */
export interface person_insert_input {
  active?: boolean | null;
  applicant_attending_schools?: applicant_attending_school_arr_rel_insert_input | null;
  assigned_forms?: assigned_form_arr_rel_insert_input | null;
  avatar?: string | null;
  birth_date?: date | null;
  city?: string | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  email_address?: string | null;
  email_okay?: boolean | null;
  first_name?: string | null;
  first_relationship?: relationship_person_arr_rel_insert_input | null;
  forms?: form_arr_rel_insert_input | null;
  id?: uuid | null;
  last_name?: string | null;
  middle_name?: string | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  person_bins?: person_bin_arr_rel_insert_input | null;
  person_teams?: person_team_arr_rel_insert_input | null;
  person_type?: person_type_enum | null;
  phone_number?: string | null;
  preferred_language?: string | null;
  reference_id?: string | null;
  second_relationship?: relationship_person_arr_rel_insert_input | null;
  sms_okay?: boolean | null;
  state?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  user?: user_obj_rel_insert_input | null;
  user_id?: string | null;
  zip_code?: string | null;
}

export interface person_match_config_aggregate_bool_exp {
  count?: person_match_config_aggregate_bool_exp_count | null;
}

export interface person_match_config_aggregate_bool_exp_count {
  arguments?: person_match_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_match_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "person_match_config"
 */
export interface person_match_config_aggregate_order_by {
  avg?: person_match_config_avg_order_by | null;
  count?: order_by | null;
  max?: person_match_config_max_order_by | null;
  min?: person_match_config_min_order_by | null;
  stddev?: person_match_config_stddev_order_by | null;
  stddev_pop?: person_match_config_stddev_pop_order_by | null;
  stddev_samp?: person_match_config_stddev_samp_order_by | null;
  sum?: person_match_config_sum_order_by | null;
  var_pop?: person_match_config_var_pop_order_by | null;
  var_samp?: person_match_config_var_samp_order_by | null;
  variance?: person_match_config_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "person_match_config"
 */
export interface person_match_config_arr_rel_insert_input {
  data: person_match_config_insert_input[];
  on_conflict?: person_match_config_on_conflict | null;
}

/**
 * order by avg() on columns of table "person_match_config"
 */
export interface person_match_config_avg_order_by {
  lottery_number?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "person_match_config". All fields are combined with a logical 'AND'.
 */
export interface person_match_config_bool_exp {
  _and?: person_match_config_bool_exp[] | null;
  _not?: person_match_config_bool_exp | null;
  _or?: person_match_config_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  lottery_number?: Int_comparison_exp | null;
  match_config?: match_config_bool_exp | null;
  match_config_id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_match_config"
 */
export interface person_match_config_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  lottery_number?: number | null;
  match_config?: match_config_obj_rel_insert_input | null;
  match_config_id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "person_match_config"
 */
export interface person_match_config_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  lottery_number?: order_by | null;
  match_config_id?: order_by | null;
  person_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "person_match_config"
 */
export interface person_match_config_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  lottery_number?: order_by | null;
  match_config_id?: order_by | null;
  person_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "person_match_config"
 */
export interface person_match_config_on_conflict {
  constraint: person_match_config_constraint;
  update_columns: person_match_config_update_column[];
  where?: person_match_config_bool_exp | null;
}

/**
 * order by stddev() on columns of table "person_match_config"
 */
export interface person_match_config_stddev_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "person_match_config"
 */
export interface person_match_config_stddev_pop_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "person_match_config"
 */
export interface person_match_config_stddev_samp_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by sum() on columns of table "person_match_config"
 */
export interface person_match_config_sum_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by var_pop() on columns of table "person_match_config"
 */
export interface person_match_config_var_pop_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by var_samp() on columns of table "person_match_config"
 */
export interface person_match_config_var_samp_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by variance() on columns of table "person_match_config"
 */
export interface person_match_config_variance_order_by {
  lottery_number?: order_by | null;
}

/**
 * order by max() on columns of table "person"
 */
export interface person_max_order_by {
  avatar?: order_by | null;
  birth_date?: order_by | null;
  city?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  email_address?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_name?: order_by | null;
  middle_name?: order_by | null;
  organization_id?: order_by | null;
  phone_number?: order_by | null;
  preferred_language?: order_by | null;
  reference_id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  user_id?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * order by min() on columns of table "person"
 */
export interface person_min_order_by {
  avatar?: order_by | null;
  birth_date?: order_by | null;
  city?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  email_address?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_name?: order_by | null;
  middle_name?: order_by | null;
  organization_id?: order_by | null;
  phone_number?: order_by | null;
  preferred_language?: order_by | null;
  reference_id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  user_id?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "person"
 */
export interface person_obj_rel_insert_input {
  data: person_insert_input;
  on_conflict?: person_on_conflict | null;
}

/**
 * on_conflict condition type for table "person"
 */
export interface person_on_conflict {
  constraint: person_constraint;
  update_columns: person_update_column[];
  where?: person_bool_exp | null;
}

/**
 * Ordering options when selecting data from "person".
 */
export interface person_order_by {
  active?: order_by | null;
  applicant_attending_schools_aggregate?: applicant_attending_school_aggregate_order_by | null;
  assigned_forms_aggregate?: assigned_form_aggregate_order_by | null;
  avatar?: order_by | null;
  birth_date?: order_by | null;
  city?: order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  email_address?: order_by | null;
  email_okay?: order_by | null;
  first_name?: order_by | null;
  first_relationship_aggregate?: relationship_person_aggregate_order_by | null;
  forms_aggregate?: form_aggregate_order_by | null;
  full_name?: order_by | null;
  id?: order_by | null;
  id_text?: order_by | null;
  last_name?: order_by | null;
  middle_name?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  person_bins_aggregate?: person_bin_aggregate_order_by | null;
  person_teams_aggregate?: person_team_aggregate_order_by | null;
  person_type?: order_by | null;
  phone_number?: order_by | null;
  preferred_language?: order_by | null;
  reference_id?: order_by | null;
  searchable_text?: order_by | null;
  second_relationship_aggregate?: relationship_person_aggregate_order_by | null;
  sms_okay?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  user?: user_order_by | null;
  user_id?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * input type for updating data in table "person"
 */
export interface person_set_input {
  active?: boolean | null;
  avatar?: string | null;
  birth_date?: date | null;
  city?: string | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  email_address?: string | null;
  email_okay?: boolean | null;
  first_name?: string | null;
  id?: uuid | null;
  last_name?: string | null;
  middle_name?: string | null;
  organization_id?: uuid | null;
  person_type?: person_type_enum | null;
  phone_number?: string | null;
  preferred_language?: string | null;
  reference_id?: string | null;
  sms_okay?: boolean | null;
  state?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  user_id?: string | null;
  zip_code?: string | null;
}

export interface person_team_aggregate_bool_exp {
  count?: person_team_aggregate_bool_exp_count | null;
}

export interface person_team_aggregate_bool_exp_count {
  arguments?: person_team_select_column[] | null;
  distinct?: boolean | null;
  filter?: person_team_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "person_team"
 */
export interface person_team_aggregate_order_by {
  count?: order_by | null;
  max?: person_team_max_order_by | null;
  min?: person_team_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "person_team"
 */
export interface person_team_arr_rel_insert_input {
  data: person_team_insert_input[];
  on_conflict?: person_team_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "person_team". All fields are combined with a logical 'AND'.
 */
export interface person_team_bool_exp {
  _and?: person_team_bool_exp[] | null;
  _not?: person_team_bool_exp | null;
  _or?: person_team_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  person?: person_bool_exp | null;
  person_id?: uuid_comparison_exp | null;
  team?: team_bool_exp | null;
  team_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "person_team"
 */
export interface person_team_insert_input {
  id?: uuid | null;
  person?: person_obj_rel_insert_input | null;
  person_id?: uuid | null;
  team?: team_obj_rel_insert_input | null;
  team_id?: uuid | null;
}

/**
 * order by max() on columns of table "person_team"
 */
export interface person_team_max_order_by {
  id?: order_by | null;
  person_id?: order_by | null;
  team_id?: order_by | null;
}

/**
 * order by min() on columns of table "person_team"
 */
export interface person_team_min_order_by {
  id?: order_by | null;
  person_id?: order_by | null;
  team_id?: order_by | null;
}

/**
 * on_conflict condition type for table "person_team"
 */
export interface person_team_on_conflict {
  constraint: person_team_constraint;
  update_columns: person_team_update_column[];
  where?: person_team_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "person_type_enum". All fields are combined with logical 'AND'.
 */
export interface person_type_enum_comparison_exp {
  _eq?: person_type_enum | null;
  _in?: person_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: person_type_enum | null;
  _nin?: person_type_enum[] | null;
}

export interface priority_template_aggregate_bool_exp {
  count?: priority_template_aggregate_bool_exp_count | null;
}

export interface priority_template_aggregate_bool_exp_count {
  arguments?: priority_template_select_column[] | null;
  distinct?: boolean | null;
  filter?: priority_template_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "priority_template"
 */
export interface priority_template_aggregate_order_by {
  count?: order_by | null;
  max?: priority_template_max_order_by | null;
  min?: priority_template_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "priority_template"
 */
export interface priority_template_arr_rel_insert_input {
  data: priority_template_insert_input[];
  on_conflict?: priority_template_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "priority_template". All fields are combined with a logical 'AND'.
 */
export interface priority_template_bool_exp {
  _and?: priority_template_bool_exp[] | null;
  _not?: priority_template_bool_exp | null;
  _or?: priority_template_bool_exp[] | null;
  config?: jsonb_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  priority_template_enrollment_periods?: priority_template_enrollment_period_bool_exp | null;
  priority_template_enrollment_periods_aggregate?: priority_template_enrollment_period_aggregate_bool_exp | null;
  priority_template_grades?: priority_template_grade_bool_exp | null;
  priority_template_grades_aggregate?: priority_template_grade_aggregate_bool_exp | null;
  priority_template_schools?: priority_template_school_bool_exp | null;
  priority_template_schools_aggregate?: priority_template_school_aggregate_bool_exp | null;
  sort_field_1?: priority_template_sort_field_enum_comparison_exp | null;
  sort_field_2?: priority_template_sort_field_enum_comparison_exp | null;
  sort_field_3?: priority_template_sort_field_enum_comparison_exp | null;
  sort_order_1?: priority_template_sort_order_enum_comparison_exp | null;
  sort_order_2?: priority_template_sort_order_enum_comparison_exp | null;
  sort_order_3?: priority_template_sort_order_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface priority_template_enrollment_period_aggregate_bool_exp {
  count?: priority_template_enrollment_period_aggregate_bool_exp_count | null;
}

export interface priority_template_enrollment_period_aggregate_bool_exp_count {
  arguments?: priority_template_enrollment_period_select_column[] | null;
  distinct?: boolean | null;
  filter?: priority_template_enrollment_period_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_aggregate_order_by {
  count?: order_by | null;
  max?: priority_template_enrollment_period_max_order_by | null;
  min?: priority_template_enrollment_period_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_arr_rel_insert_input {
  data: priority_template_enrollment_period_insert_input[];
  on_conflict?: priority_template_enrollment_period_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "priority_template_enrollment_period". All fields are combined with a logical 'AND'.
 */
export interface priority_template_enrollment_period_bool_exp {
  _and?: priority_template_enrollment_period_bool_exp[] | null;
  _not?: priority_template_enrollment_period_bool_exp | null;
  _or?: priority_template_enrollment_period_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  priority_template?: priority_template_bool_exp | null;
  priority_template_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  priority_template?: priority_template_obj_rel_insert_input | null;
  priority_template_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_max_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_min_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "priority_template_enrollment_period"
 */
export interface priority_template_enrollment_period_on_conflict {
  constraint: priority_template_enrollment_period_constraint;
  update_columns: priority_template_enrollment_period_update_column[];
  where?: priority_template_enrollment_period_bool_exp | null;
}

export interface priority_template_grade_aggregate_bool_exp {
  count?: priority_template_grade_aggregate_bool_exp_count | null;
}

export interface priority_template_grade_aggregate_bool_exp_count {
  arguments?: priority_template_grade_select_column[] | null;
  distinct?: boolean | null;
  filter?: priority_template_grade_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "priority_template_grade"
 */
export interface priority_template_grade_aggregate_order_by {
  count?: order_by | null;
  max?: priority_template_grade_max_order_by | null;
  min?: priority_template_grade_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "priority_template_grade"
 */
export interface priority_template_grade_arr_rel_insert_input {
  data: priority_template_grade_insert_input[];
  on_conflict?: priority_template_grade_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "priority_template_grade". All fields are combined with a logical 'AND'.
 */
export interface priority_template_grade_bool_exp {
  _and?: priority_template_grade_bool_exp[] | null;
  _not?: priority_template_grade_bool_exp | null;
  _or?: priority_template_grade_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  priority_template?: priority_template_bool_exp | null;
  priority_template_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "priority_template_grade"
 */
export interface priority_template_grade_insert_input {
  created_at?: timestamptz | null;
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  id?: uuid | null;
  priority_template?: priority_template_obj_rel_insert_input | null;
  priority_template_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "priority_template_grade"
 */
export interface priority_template_grade_max_order_by {
  created_at?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "priority_template_grade"
 */
export interface priority_template_grade_min_order_by {
  created_at?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "priority_template_grade"
 */
export interface priority_template_grade_on_conflict {
  constraint: priority_template_grade_constraint;
  update_columns: priority_template_grade_update_column[];
  where?: priority_template_grade_bool_exp | null;
}

/**
 * input type for inserting data into table "priority_template"
 */
export interface priority_template_insert_input {
  config?: jsonb | null;
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  name?: string | null;
  priority_template_enrollment_periods?: priority_template_enrollment_period_arr_rel_insert_input | null;
  priority_template_grades?: priority_template_grade_arr_rel_insert_input | null;
  priority_template_schools?: priority_template_school_arr_rel_insert_input | null;
  sort_field_1?: priority_template_sort_field_enum | null;
  sort_field_2?: priority_template_sort_field_enum | null;
  sort_field_3?: priority_template_sort_field_enum | null;
  sort_order_1?: priority_template_sort_order_enum | null;
  sort_order_2?: priority_template_sort_order_enum | null;
  sort_order_3?: priority_template_sort_order_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "priority_template"
 */
export interface priority_template_max_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "priority_template"
 */
export interface priority_template_min_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "priority_template"
 */
export interface priority_template_obj_rel_insert_input {
  data: priority_template_insert_input;
  on_conflict?: priority_template_on_conflict | null;
}

/**
 * on_conflict condition type for table "priority_template"
 */
export interface priority_template_on_conflict {
  constraint: priority_template_constraint;
  update_columns: priority_template_update_column[];
  where?: priority_template_bool_exp | null;
}

/**
 * Ordering options when selecting data from "priority_template".
 */
export interface priority_template_order_by {
  config?: order_by | null;
  created_at?: order_by | null;
  enrollment_period?: enrollment_period_order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  priority_template_enrollment_periods_aggregate?: priority_template_enrollment_period_aggregate_order_by | null;
  priority_template_grades_aggregate?: priority_template_grade_aggregate_order_by | null;
  priority_template_schools_aggregate?: priority_template_school_aggregate_order_by | null;
  sort_field_1?: order_by | null;
  sort_field_2?: order_by | null;
  sort_field_3?: order_by | null;
  sort_order_1?: order_by | null;
  sort_order_2?: order_by | null;
  sort_order_3?: order_by | null;
  updated_at?: order_by | null;
}

export interface priority_template_school_aggregate_bool_exp {
  count?: priority_template_school_aggregate_bool_exp_count | null;
}

export interface priority_template_school_aggregate_bool_exp_count {
  arguments?: priority_template_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: priority_template_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "priority_template_school"
 */
export interface priority_template_school_aggregate_order_by {
  count?: order_by | null;
  max?: priority_template_school_max_order_by | null;
  min?: priority_template_school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "priority_template_school"
 */
export interface priority_template_school_arr_rel_insert_input {
  data: priority_template_school_insert_input[];
  on_conflict?: priority_template_school_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "priority_template_school". All fields are combined with a logical 'AND'.
 */
export interface priority_template_school_bool_exp {
  _and?: priority_template_school_bool_exp[] | null;
  _not?: priority_template_school_bool_exp | null;
  _or?: priority_template_school_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  priority_template?: priority_template_bool_exp | null;
  priority_template_id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "priority_template_school"
 */
export interface priority_template_school_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  priority_template?: priority_template_obj_rel_insert_input | null;
  priority_template_id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "priority_template_school"
 */
export interface priority_template_school_max_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "priority_template_school"
 */
export interface priority_template_school_min_order_by {
  created_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  priority_template_id?: order_by | null;
  school_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "priority_template_school"
 */
export interface priority_template_school_on_conflict {
  constraint: priority_template_school_constraint;
  update_columns: priority_template_school_update_column[];
  where?: priority_template_school_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "priority_template_sort_field_enum". All fields are combined with logical 'AND'.
 */
export interface priority_template_sort_field_enum_comparison_exp {
  _eq?: priority_template_sort_field_enum | null;
  _in?: priority_template_sort_field_enum[] | null;
  _is_null?: boolean | null;
  _neq?: priority_template_sort_field_enum | null;
  _nin?: priority_template_sort_field_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "priority_template_sort_order_enum". All fields are combined with logical 'AND'.
 */
export interface priority_template_sort_order_enum_comparison_exp {
  _eq?: priority_template_sort_order_enum | null;
  _in?: priority_template_sort_order_enum[] | null;
  _is_null?: boolean | null;
  _neq?: priority_template_sort_order_enum | null;
  _nin?: priority_template_sort_order_enum[] | null;
}

export interface program_aggregate_bool_exp {
  count?: program_aggregate_bool_exp_count | null;
}

export interface program_aggregate_bool_exp_count {
  arguments?: program_select_column[] | null;
  distinct?: boolean | null;
  filter?: program_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "program"
 */
export interface program_aggregate_order_by {
  avg?: program_avg_order_by | null;
  count?: order_by | null;
  max?: program_max_order_by | null;
  min?: program_min_order_by | null;
  stddev?: program_stddev_order_by | null;
  stddev_pop?: program_stddev_pop_order_by | null;
  stddev_samp?: program_stddev_samp_order_by | null;
  sum?: program_sum_order_by | null;
  var_pop?: program_var_pop_order_by | null;
  var_samp?: program_var_samp_order_by | null;
  variance?: program_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "program"
 */
export interface program_arr_rel_insert_input {
  data: program_insert_input[];
  on_conflict?: program_on_conflict | null;
}

/**
 * order by avg() on columns of table "program"
 */
export interface program_avg_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "program". All fields are combined with a logical 'AND'.
 */
export interface program_bool_exp {
  _and?: program_bool_exp[] | null;
  _not?: program_bool_exp | null;
  _or?: program_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  grade_programs?: grade_bool_exp | null;
  grade_programs_aggregate?: grade_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  label?: citext_comparison_exp | null;
  order?: Int_comparison_exp | null;
  program_group?: program_group_bool_exp | null;
  program_group_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface program_group_aggregate_bool_exp {
  count?: program_group_aggregate_bool_exp_count | null;
}

export interface program_group_aggregate_bool_exp_count {
  arguments?: program_group_select_column[] | null;
  distinct?: boolean | null;
  filter?: program_group_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "program_group"
 */
export interface program_group_aggregate_order_by {
  count?: order_by | null;
  max?: program_group_max_order_by | null;
  min?: program_group_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "program_group"
 */
export interface program_group_arr_rel_insert_input {
  data: program_group_insert_input[];
  on_conflict?: program_group_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "program_group". All fields are combined with a logical 'AND'.
 */
export interface program_group_bool_exp {
  _and?: program_group_bool_exp[] | null;
  _not?: program_group_bool_exp | null;
  _or?: program_group_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: citext_comparison_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  programs?: program_bool_exp | null;
  programs_aggregate?: program_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "program_group"
 */
export interface program_group_insert_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  name?: citext | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  programs?: program_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "program_group"
 */
export interface program_group_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "program_group"
 */
export interface program_group_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "program_group"
 */
export interface program_group_obj_rel_insert_input {
  data: program_group_insert_input;
  on_conflict?: program_group_on_conflict | null;
}

/**
 * on_conflict condition type for table "program_group"
 */
export interface program_group_on_conflict {
  constraint: program_group_constraint;
  update_columns: program_group_update_column[];
  where?: program_group_bool_exp | null;
}

/**
 * Ordering options when selecting data from "program_group".
 */
export interface program_group_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  programs_aggregate?: program_aggregate_order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "program_group"
 */
export interface program_group_set_input {
  created_at?: timestamptz | null;
  id?: uuid | null;
  name?: citext | null;
  organization_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting data into table "program"
 */
export interface program_insert_input {
  created_at?: timestamptz | null;
  grade_programs?: grade_arr_rel_insert_input | null;
  id?: uuid | null;
  label?: citext | null;
  order?: number | null;
  program_group?: program_group_obj_rel_insert_input | null;
  program_group_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "program"
 */
export interface program_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  program_group_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "program"
 */
export interface program_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  program_group_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "program"
 */
export interface program_obj_rel_insert_input {
  data: program_insert_input;
  on_conflict?: program_on_conflict | null;
}

/**
 * on_conflict condition type for table "program"
 */
export interface program_on_conflict {
  constraint: program_constraint;
  update_columns: program_update_column[];
  where?: program_bool_exp | null;
}

/**
 * Ordering options when selecting data from "program".
 */
export interface program_order_by {
  created_at?: order_by | null;
  grade_programs_aggregate?: grade_aggregate_order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  order?: order_by | null;
  program_group?: program_group_order_by | null;
  program_group_id?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by stddev() on columns of table "program"
 */
export interface program_stddev_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "program"
 */
export interface program_stddev_pop_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "program"
 */
export interface program_stddev_samp_order_by {
  order?: order_by | null;
}

/**
 * order by sum() on columns of table "program"
 */
export interface program_sum_order_by {
  order?: order_by | null;
}

/**
 * order by var_pop() on columns of table "program"
 */
export interface program_var_pop_order_by {
  order?: order_by | null;
}

/**
 * order by var_samp() on columns of table "program"
 */
export interface program_var_samp_order_by {
  order?: order_by | null;
}

/**
 * order by variance() on columns of table "program"
 */
export interface program_variance_order_by {
  order?: order_by | null;
}

export interface question_aggregate_bool_exp {
  bool_and?: question_aggregate_bool_exp_bool_and | null;
  bool_or?: question_aggregate_bool_exp_bool_or | null;
  count?: question_aggregate_bool_exp_count | null;
}

export interface question_aggregate_bool_exp_bool_and {
  arguments: question_select_column_question_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: question_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface question_aggregate_bool_exp_bool_or {
  arguments: question_select_column_question_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: question_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface question_aggregate_bool_exp_count {
  arguments?: question_select_column[] | null;
  distinct?: boolean | null;
  filter?: question_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "question"
 */
export interface question_aggregate_order_by {
  avg?: question_avg_order_by | null;
  count?: order_by | null;
  max?: question_max_order_by | null;
  min?: question_min_order_by | null;
  stddev?: question_stddev_order_by | null;
  stddev_pop?: question_stddev_pop_order_by | null;
  stddev_samp?: question_stddev_samp_order_by | null;
  sum?: question_sum_order_by | null;
  var_pop?: question_var_pop_order_by | null;
  var_samp?: question_var_samp_order_by | null;
  variance?: question_variance_order_by | null;
}

/**
 * append existing jsonb value of filtered columns with new jsonb value
 */
export interface question_append_input {
  constraints?: jsonb | null;
}

/**
 * input type for inserting array relation for remote table "question"
 */
export interface question_arr_rel_insert_input {
  data: question_insert_input[];
  on_conflict?: question_on_conflict | null;
}

/**
 * order by avg() on columns of table "question"
 */
export interface question_avg_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'.
 */
export interface question_bool_exp {
  _and?: question_bool_exp[] | null;
  _not?: question_bool_exp | null;
  _or?: question_bool_exp[] | null;
  ancestor_questions?: additional_question_bool_exp | null;
  ancestor_questions_aggregate?: additional_question_aggregate_bool_exp | null;
  cloned_questions?: custom_question_relationship_bool_exp | null;
  cloned_questions_aggregate?: custom_question_relationship_aggregate_bool_exp | null;
  constraints?: jsonb_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  custom_question?: custom_question_bool_exp | null;
  custom_question_relationship?: custom_question_relationship_bool_exp | null;
  custom_question_type_field?: custom_question_type_field_bool_exp | null;
  form_question?: form_question_bool_exp | null;
  form_question_details?: form_question_bool_exp | null;
  form_question_details_aggregate?: form_question_aggregate_bool_exp | null;
  form_question_schools?: form_question_school_bool_exp | null;
  form_question_schools_aggregate?: form_question_school_aggregate_bool_exp | null;
  form_template_section?: form_template_section_bool_exp | null;
  form_template_section_id?: uuid_comparison_exp | null;
  grades_additional_questions?: grades_additional_question_bool_exp | null;
  grades_additional_questions_aggregate?: grades_additional_question_aggregate_bool_exp | null;
  grades_question?: grades_question_bool_exp | null;
  id?: uuid_comparison_exp | null;
  is_required?: Boolean_comparison_exp | null;
  key?: String_comparison_exp | null;
  link_text?: String_comparison_exp | null;
  link_url?: String_comparison_exp | null;
  order?: Int_comparison_exp | null;
  permission_level?: String_comparison_exp | null;
  question?: String_comparison_exp | null;
  question_type?: question_type_bool_exp | null;
  type?: question_type_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * delete the field or element with specified path (for JSON arrays, negative integers count from the end)
 */
export interface question_delete_at_path_input {
  constraints?: string[] | null;
}

/**
 * delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array
 */
export interface question_delete_elem_input {
  constraints?: number | null;
}

/**
 * delete key/value pair or string element. key/value pairs are matched based on their key value
 */
export interface question_delete_key_input {
  constraints?: string | null;
}

/**
 * input type for incrementing numeric columns in table "question"
 */
export interface question_inc_input {
  order?: number | null;
}

/**
 * input type for inserting data into table "question"
 */
export interface question_insert_input {
  ancestor_questions?: additional_question_arr_rel_insert_input | null;
  cloned_questions?: custom_question_relationship_arr_rel_insert_input | null;
  constraints?: jsonb | null;
  created_at?: timestamptz | null;
  custom_question?: custom_question_obj_rel_insert_input | null;
  custom_question_relationship?: custom_question_relationship_obj_rel_insert_input | null;
  custom_question_type_field?: custom_question_type_field_obj_rel_insert_input | null;
  form_question?: form_question_obj_rel_insert_input | null;
  form_question_details?: form_question_arr_rel_insert_input | null;
  form_question_schools?: form_question_school_arr_rel_insert_input | null;
  form_template_section?: form_template_section_obj_rel_insert_input | null;
  form_template_section_id?: uuid | null;
  grades_additional_questions?: grades_additional_question_arr_rel_insert_input | null;
  grades_question?: grades_question_obj_rel_insert_input | null;
  id?: uuid | null;
  is_required?: boolean | null;
  key?: string | null;
  link_text?: string | null;
  link_url?: string | null;
  order?: number | null;
  permission_level?: string | null;
  question?: string | null;
  question_type?: question_type_obj_rel_insert_input | null;
  type?: question_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "question"
 */
export interface question_max_order_by {
  created_at?: order_by | null;
  form_template_section_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  link_text?: order_by | null;
  link_url?: order_by | null;
  order?: order_by | null;
  permission_level?: order_by | null;
  question?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "question"
 */
export interface question_min_order_by {
  created_at?: order_by | null;
  form_template_section_id?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  link_text?: order_by | null;
  link_url?: order_by | null;
  order?: order_by | null;
  permission_level?: order_by | null;
  question?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "question"
 */
export interface question_obj_rel_insert_input {
  data: question_insert_input;
  on_conflict?: question_on_conflict | null;
}

/**
 * on_conflict condition type for table "question"
 */
export interface question_on_conflict {
  constraint: question_constraint;
  update_columns: question_update_column[];
  where?: question_bool_exp | null;
}

/**
 * prepend existing jsonb value of filtered columns with new jsonb value
 */
export interface question_prepend_input {
  constraints?: jsonb | null;
}

/**
 * input type for updating data in table "question"
 */
export interface question_set_input {
  constraints?: jsonb | null;
  created_at?: timestamptz | null;
  form_template_section_id?: uuid | null;
  id?: uuid | null;
  is_required?: boolean | null;
  key?: string | null;
  link_text?: string | null;
  link_url?: string | null;
  order?: number | null;
  permission_level?: string | null;
  question?: string | null;
  type?: question_type_enum | null;
  updated_at?: timestamptz | null;
}

/**
 * order by stddev() on columns of table "question"
 */
export interface question_stddev_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "question"
 */
export interface question_stddev_pop_order_by {
  order?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "question"
 */
export interface question_stddev_samp_order_by {
  order?: order_by | null;
}

/**
 * order by sum() on columns of table "question"
 */
export interface question_sum_order_by {
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "question_type". All fields are combined with a logical 'AND'.
 */
export interface question_type_bool_exp {
  _and?: question_type_bool_exp[] | null;
  _not?: question_type_bool_exp | null;
  _or?: question_type_bool_exp[] | null;
  form_questions?: question_bool_exp | null;
  form_questions_aggregate?: question_aggregate_bool_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "question_type_enum". All fields are combined with logical 'AND'.
 */
export interface question_type_enum_comparison_exp {
  _eq?: question_type_enum | null;
  _in?: question_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: question_type_enum | null;
  _nin?: question_type_enum[] | null;
}

/**
 * input type for inserting data into table "question_type"
 */
export interface question_type_insert_input {
  form_questions?: question_arr_rel_insert_input | null;
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "question_type"
 */
export interface question_type_obj_rel_insert_input {
  data: question_type_insert_input;
  on_conflict?: question_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "question_type"
 */
export interface question_type_on_conflict {
  constraint: question_type_constraint;
  update_columns: question_type_update_column[];
  where?: question_type_bool_exp | null;
}

export interface question_updates {
  _append?: question_append_input | null;
  _delete_at_path?: question_delete_at_path_input | null;
  _delete_elem?: question_delete_elem_input | null;
  _delete_key?: question_delete_key_input | null;
  _inc?: question_inc_input | null;
  _prepend?: question_prepend_input | null;
  _set?: question_set_input | null;
  where: question_bool_exp;
}

/**
 * order by var_pop() on columns of table "question"
 */
export interface question_var_pop_order_by {
  order?: order_by | null;
}

/**
 * order by var_samp() on columns of table "question"
 */
export interface question_var_samp_order_by {
  order?: order_by | null;
}

/**
 * order by variance() on columns of table "question"
 */
export interface question_variance_order_by {
  order?: order_by | null;
}

export interface relationship_person_aggregate_bool_exp {
  bool_and?: relationship_person_aggregate_bool_exp_bool_and | null;
  bool_or?: relationship_person_aggregate_bool_exp_bool_or | null;
  count?: relationship_person_aggregate_bool_exp_count | null;
}

export interface relationship_person_aggregate_bool_exp_bool_and {
  arguments: relationship_person_select_column_relationship_person_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: relationship_person_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface relationship_person_aggregate_bool_exp_bool_or {
  arguments: relationship_person_select_column_relationship_person_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: relationship_person_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface relationship_person_aggregate_bool_exp_count {
  arguments?: relationship_person_select_column[] | null;
  distinct?: boolean | null;
  filter?: relationship_person_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "relationship_person"
 */
export interface relationship_person_aggregate_order_by {
  count?: order_by | null;
  max?: relationship_person_max_order_by | null;
  min?: relationship_person_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "relationship_person"
 */
export interface relationship_person_arr_rel_insert_input {
  data: relationship_person_insert_input[];
  on_conflict?: relationship_person_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "relationship_person". All fields are combined with a logical 'AND'.
 */
export interface relationship_person_bool_exp {
  _and?: relationship_person_bool_exp[] | null;
  _not?: relationship_person_bool_exp | null;
  _or?: relationship_person_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  first?: person_bool_exp | null;
  first_person?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  second?: person_bool_exp | null;
  second_person?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "relationship_person"
 */
export interface relationship_person_insert_input {
  active?: boolean | null;
  created_at?: timestamptz | null;
  first?: person_obj_rel_insert_input | null;
  first_person?: uuid | null;
  id?: uuid | null;
  second?: person_obj_rel_insert_input | null;
  second_person?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * order by max() on columns of table "relationship_person"
 */
export interface relationship_person_max_order_by {
  created_at?: order_by | null;
  first_person?: order_by | null;
  id?: order_by | null;
  second_person?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "relationship_person"
 */
export interface relationship_person_min_order_by {
  created_at?: order_by | null;
  first_person?: order_by | null;
  id?: order_by | null;
  second_person?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "relationship_person"
 */
export interface relationship_person_on_conflict {
  constraint: relationship_person_constraint;
  update_columns: relationship_person_update_column[];
  where?: relationship_person_bool_exp | null;
}

export interface rule_aggregate_bool_exp {
  count?: rule_aggregate_bool_exp_count | null;
}

export interface rule_aggregate_bool_exp_count {
  arguments?: rule_select_column[] | null;
  distinct?: boolean | null;
  filter?: rule_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "rule"
 */
export interface rule_aggregate_order_by {
  count?: order_by | null;
  max?: rule_max_order_by | null;
  min?: rule_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "rule"
 */
export interface rule_arr_rel_insert_input {
  data: rule_insert_input[];
  on_conflict?: rule_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rule". All fields are combined with a logical 'AND'.
 */
export interface rule_bool_exp {
  _and?: rule_bool_exp[] | null;
  _not?: rule_bool_exp | null;
  _or?: rule_bool_exp[] | null;
  form_template_rule?: form_template_rule_bool_exp | null;
  form_template_rule_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  rule_type?: rule_type_bool_exp | null;
  status_rule?: status_rule_bool_exp | null;
  type?: rule_type_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "rule"
 */
export interface rule_insert_input {
  form_template_rule?: form_template_rule_obj_rel_insert_input | null;
  form_template_rule_id?: uuid | null;
  id?: uuid | null;
  rule_type?: rule_type_obj_rel_insert_input | null;
  status_rule?: status_rule_obj_rel_insert_input | null;
  type?: rule_type_enum | null;
}

/**
 * order by max() on columns of table "rule"
 */
export interface rule_max_order_by {
  form_template_rule_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by min() on columns of table "rule"
 */
export interface rule_min_order_by {
  form_template_rule_id?: order_by | null;
  id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "rule"
 */
export interface rule_obj_rel_insert_input {
  data: rule_insert_input;
  on_conflict?: rule_on_conflict | null;
}

/**
 * on_conflict condition type for table "rule"
 */
export interface rule_on_conflict {
  constraint: rule_constraint;
  update_columns: rule_update_column[];
  where?: rule_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "rule_type". All fields are combined with a logical 'AND'.
 */
export interface rule_type_bool_exp {
  _and?: rule_type_bool_exp[] | null;
  _not?: rule_type_bool_exp | null;
  _or?: rule_type_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "rule_type_enum". All fields are combined with logical 'AND'.
 */
export interface rule_type_enum_comparison_exp {
  _eq?: rule_type_enum | null;
  _in?: rule_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: rule_type_enum | null;
  _nin?: rule_type_enum[] | null;
}

/**
 * input type for inserting data into table "rule_type"
 */
export interface rule_type_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "rule_type"
 */
export interface rule_type_obj_rel_insert_input {
  data: rule_type_insert_input;
  on_conflict?: rule_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "rule_type"
 */
export interface rule_type_on_conflict {
  constraint: rule_type_constraint;
  update_columns: rule_type_update_column[];
  where?: rule_type_bool_exp | null;
}

export interface school_aggregate_bool_exp {
  count?: school_aggregate_bool_exp_count | null;
}

export interface school_aggregate_bool_exp_count {
  arguments?: school_select_column[] | null;
  distinct?: boolean | null;
  filter?: school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "school"
 */
export interface school_aggregate_order_by {
  count?: order_by | null;
  max?: school_max_order_by | null;
  min?: school_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "school"
 */
export interface school_arr_rel_insert_input {
  data: school_insert_input[];
  on_conflict?: school_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "school". All fields are combined with a logical 'AND'.
 */
export interface school_bool_exp {
  _and?: school_bool_exp[] | null;
  _not?: school_bool_exp | null;
  _or?: school_bool_exp[] | null;
  applicant_attending_schools?: applicant_attending_school_bool_exp | null;
  applicant_attending_schools_aggregate?: applicant_attending_school_aggregate_bool_exp | null;
  city?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  eligibility_question_schools?: eligibility_question_school_bool_exp | null;
  eligibility_question_schools_aggregate?: eligibility_question_school_aggregate_bool_exp | null;
  form_attending_schools?: form_attending_school_bool_exp | null;
  form_attending_schools_aggregate?: form_attending_school_aggregate_bool_exp | null;
  form_question_schools?: form_question_school_bool_exp | null;
  form_question_schools_aggregate?: form_question_school_aggregate_bool_exp | null;
  form_school_ranks?: form_school_rank_bool_exp | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_bool_exp | null;
  grades?: grade_bool_exp | null;
  grades_aggregate?: grade_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  offers?: offer_bool_exp | null;
  offers_aggregate?: offer_aggregate_bool_exp | null;
  organization?: organization_bool_exp | null;
  organization_id?: uuid_comparison_exp | null;
  reference_id?: String_comparison_exp | null;
  school_default_bin_configs?: school_default_bin_config_bool_exp | null;
  school_default_bin_configs_aggregate?: school_default_bin_config_aggregate_bool_exp | null;
  school_users?: school_user_bool_exp | null;
  school_users_aggregate?: school_user_aggregate_bool_exp | null;
  state?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  street_address?: String_comparison_exp | null;
  street_address_line_2?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlists?: waitlist_bool_exp | null;
  waitlists_aggregate?: waitlist_aggregate_bool_exp | null;
  zip_code?: String_comparison_exp | null;
}

export interface school_boundary_aggregate_bool_exp {
  count?: school_boundary_aggregate_bool_exp_count | null;
}

export interface school_boundary_aggregate_bool_exp_count {
  arguments?: school_boundary_select_column[] | null;
  distinct?: boolean | null;
  filter?: school_boundary_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "school_boundary"
 */
export interface school_boundary_arr_rel_insert_input {
  data: school_boundary_insert_input[];
  on_conflict?: school_boundary_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "school_boundary". All fields are combined with a logical 'AND'.
 */
export interface school_boundary_bool_exp {
  _and?: school_boundary_bool_exp[] | null;
  _not?: school_boundary_bool_exp | null;
  _or?: school_boundary_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  geo_eligibilities?: geo_eligibility_bool_exp | null;
  geo_eligibilities_aggregate?: geo_eligibility_aggregate_bool_exp | null;
  geojson?: geojson_bool_exp | null;
  geojson_id?: uuid_comparison_exp | null;
  geojson_property_value?: String_comparison_exp | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "school_boundary"
 */
export interface school_boundary_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  geo_eligibilities?: geo_eligibility_arr_rel_insert_input | null;
  geojson?: geojson_obj_rel_insert_input | null;
  geojson_id?: uuid | null;
  geojson_property_value?: string | null;
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "school_boundary"
 */
export interface school_boundary_obj_rel_insert_input {
  data: school_boundary_insert_input;
  on_conflict?: school_boundary_on_conflict | null;
}

/**
 * on_conflict condition type for table "school_boundary"
 */
export interface school_boundary_on_conflict {
  constraint: school_boundary_constraint;
  update_columns: school_boundary_update_column[];
  where?: school_boundary_bool_exp | null;
}

export interface school_default_bin_config_aggregate_bool_exp {
  count?: school_default_bin_config_aggregate_bool_exp_count | null;
}

export interface school_default_bin_config_aggregate_bool_exp_count {
  arguments?: school_default_bin_config_select_column[] | null;
  distinct?: boolean | null;
  filter?: school_default_bin_config_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "school_default_bin_config"
 */
export interface school_default_bin_config_aggregate_order_by {
  count?: order_by | null;
  max?: school_default_bin_config_max_order_by | null;
  min?: school_default_bin_config_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "school_default_bin_config"
 */
export interface school_default_bin_config_arr_rel_insert_input {
  data: school_default_bin_config_insert_input[];
  on_conflict?: school_default_bin_config_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "school_default_bin_config". All fields are combined with a logical 'AND'.
 */
export interface school_default_bin_config_bool_exp {
  _and?: school_default_bin_config_bool_exp[] | null;
  _not?: school_default_bin_config_bool_exp | null;
  _or?: school_default_bin_config_bool_exp[] | null;
  bin_config_id?: uuid_comparison_exp | null;
  default_bin_config?: bin_config_bool_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "school_default_bin_config"
 */
export interface school_default_bin_config_insert_input {
  bin_config_id?: uuid | null;
  default_bin_config?: bin_config_obj_rel_insert_input | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
}

/**
 * order by max() on columns of table "school_default_bin_config"
 */
export interface school_default_bin_config_max_order_by {
  bin_config_id?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * order by min() on columns of table "school_default_bin_config"
 */
export interface school_default_bin_config_min_order_by {
  bin_config_id?: order_by | null;
  enrollment_period_id?: order_by | null;
  id?: order_by | null;
  school_id?: order_by | null;
}

/**
 * on_conflict condition type for table "school_default_bin_config"
 */
export interface school_default_bin_config_on_conflict {
  constraint: school_default_bin_config_constraint;
  update_columns: school_default_bin_config_update_column[];
  where?: school_default_bin_config_bool_exp | null;
}

/**
 * input type for inserting data into table "school"
 */
export interface school_insert_input {
  applicant_attending_schools?: applicant_attending_school_arr_rel_insert_input | null;
  city?: string | null;
  created_at?: timestamptz | null;
  eligibility_question_schools?: eligibility_question_school_arr_rel_insert_input | null;
  form_attending_schools?: form_attending_school_arr_rel_insert_input | null;
  form_question_schools?: form_question_school_arr_rel_insert_input | null;
  form_school_ranks?: form_school_rank_arr_rel_insert_input | null;
  grades?: grade_arr_rel_insert_input | null;
  id?: uuid | null;
  name?: string | null;
  offers?: offer_arr_rel_insert_input | null;
  organization?: organization_obj_rel_insert_input | null;
  organization_id?: uuid | null;
  reference_id?: string | null;
  school_default_bin_configs?: school_default_bin_config_arr_rel_insert_input | null;
  school_users?: school_user_arr_rel_insert_input | null;
  state?: string | null;
  status?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  waitlists?: waitlist_arr_rel_insert_input | null;
  zip_code?: string | null;
}

/**
 * order by max() on columns of table "school"
 */
export interface school_max_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  reference_id?: order_by | null;
  state?: order_by | null;
  status?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * order by min() on columns of table "school"
 */
export interface school_min_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  reference_id?: order_by | null;
  state?: order_by | null;
  status?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "school"
 */
export interface school_obj_rel_insert_input {
  data: school_insert_input;
  on_conflict?: school_on_conflict | null;
}

/**
 * on_conflict condition type for table "school"
 */
export interface school_on_conflict {
  constraint: school_constraint;
  update_columns: school_update_column[];
  where?: school_bool_exp | null;
}

/**
 * Ordering options when selecting data from "school".
 */
export interface school_order_by {
  applicant_attending_schools_aggregate?: applicant_attending_school_aggregate_order_by | null;
  city?: order_by | null;
  created_at?: order_by | null;
  eligibility_question_schools_aggregate?: eligibility_question_school_aggregate_order_by | null;
  form_attending_schools_aggregate?: form_attending_school_aggregate_order_by | null;
  form_question_schools_aggregate?: form_question_school_aggregate_order_by | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_order_by | null;
  grades_aggregate?: grade_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  offers_aggregate?: offer_aggregate_order_by | null;
  organization?: organization_order_by | null;
  organization_id?: order_by | null;
  reference_id?: order_by | null;
  school_default_bin_configs_aggregate?: school_default_bin_config_aggregate_order_by | null;
  school_users_aggregate?: school_user_aggregate_order_by | null;
  state?: order_by | null;
  status?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  waitlists_aggregate?: waitlist_aggregate_order_by | null;
  zip_code?: order_by | null;
}

/**
 * input type for updating data in table "school"
 */
export interface school_set_input {
  city?: string | null;
  created_at?: timestamptz | null;
  id?: uuid | null;
  name?: string | null;
  organization_id?: uuid | null;
  reference_id?: string | null;
  state?: string | null;
  status?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  zip_code?: string | null;
}

export interface school_user_aggregate_bool_exp {
  count?: school_user_aggregate_bool_exp_count | null;
}

export interface school_user_aggregate_bool_exp_count {
  arguments?: school_user_select_column[] | null;
  distinct?: boolean | null;
  filter?: school_user_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "school_user"
 */
export interface school_user_aggregate_order_by {
  count?: order_by | null;
  max?: school_user_max_order_by | null;
  min?: school_user_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "school_user"
 */
export interface school_user_arr_rel_insert_input {
  data: school_user_insert_input[];
  on_conflict?: school_user_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "school_user". All fields are combined with a logical 'AND'.
 */
export interface school_user_bool_exp {
  _and?: school_user_bool_exp[] | null;
  _not?: school_user_bool_exp | null;
  _or?: school_user_bool_exp[] | null;
  school?: school_bool_exp | null;
  school_access_permission?: String_comparison_exp | null;
  school_id?: uuid_comparison_exp | null;
  user?: user_bool_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "school_user"
 */
export interface school_user_insert_input {
  school?: school_obj_rel_insert_input | null;
  school_access_permission?: string | null;
  school_id?: uuid | null;
  user?: user_obj_rel_insert_input | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "school_user"
 */
export interface school_user_max_order_by {
  school_access_permission?: order_by | null;
  school_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "school_user"
 */
export interface school_user_min_order_by {
  school_access_permission?: order_by | null;
  school_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on_conflict condition type for table "school_user"
 */
export interface school_user_on_conflict {
  constraint: school_user_constraint;
  update_columns: school_user_update_column[];
  where?: school_user_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "schools_ranking_section". All fields are combined with a logical 'AND'.
 */
export interface schools_ranking_section_bool_exp {
  _and?: schools_ranking_section_bool_exp[] | null;
  _not?: schools_ranking_section_bool_exp | null;
  _or?: schools_ranking_section_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  explore_url?: String_comparison_exp | null;
  form_school_ranks?: form_school_rank_bool_exp | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_bool_exp | null;
  form_template_section?: form_template_section_bool_exp | null;
  form_template_section_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  max_schools?: Int_comparison_exp | null;
  min_schools?: Int_comparison_exp | null;
  ranking_enabled?: Boolean_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for incrementing numeric columns in table "schools_ranking_section"
 */
export interface schools_ranking_section_inc_input {
  max_schools?: number | null;
  min_schools?: number | null;
}

/**
 * input type for inserting data into table "schools_ranking_section"
 */
export interface schools_ranking_section_insert_input {
  created_at?: timestamptz | null;
  explore_url?: string | null;
  form_school_ranks?: form_school_rank_arr_rel_insert_input | null;
  form_template_section?: form_template_section_obj_rel_insert_input | null;
  form_template_section_id?: uuid | null;
  id?: uuid | null;
  max_schools?: number | null;
  min_schools?: number | null;
  ranking_enabled?: boolean | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "schools_ranking_section"
 */
export interface schools_ranking_section_obj_rel_insert_input {
  data: schools_ranking_section_insert_input;
  on_conflict?: schools_ranking_section_on_conflict | null;
}

/**
 * on_conflict condition type for table "schools_ranking_section"
 */
export interface schools_ranking_section_on_conflict {
  constraint: schools_ranking_section_constraint;
  update_columns: schools_ranking_section_update_column[];
  where?: schools_ranking_section_bool_exp | null;
}

/**
 * Ordering options when selecting data from "schools_ranking_section".
 */
export interface schools_ranking_section_order_by {
  created_at?: order_by | null;
  explore_url?: order_by | null;
  form_school_ranks_aggregate?: form_school_rank_aggregate_order_by | null;
  form_template_section?: form_template_section_order_by | null;
  form_template_section_id?: order_by | null;
  id?: order_by | null;
  max_schools?: order_by | null;
  min_schools?: order_by | null;
  ranking_enabled?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "schools_ranking_section"
 */
export interface schools_ranking_section_set_input {
  created_at?: timestamptz | null;
  explore_url?: string | null;
  form_template_section_id?: uuid | null;
  id?: uuid | null;
  max_schools?: number | null;
  min_schools?: number | null;
  ranking_enabled?: boolean | null;
  updated_at?: timestamptz | null;
}

export interface schools_ranking_section_updates {
  _inc?: schools_ranking_section_inc_input | null;
  _set?: schools_ranking_section_set_input | null;
  where: schools_ranking_section_bool_exp;
}

export interface search_form_by_school_aggregate_bool_exp {
  bool_and?: search_form_by_school_aggregate_bool_exp_bool_and | null;
  bool_or?: search_form_by_school_aggregate_bool_exp_bool_or | null;
  count?: search_form_by_school_aggregate_bool_exp_count | null;
}

export interface search_form_by_school_aggregate_bool_exp_bool_and {
  arguments: search_form_by_school_select_column_search_form_by_school_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: search_form_by_school_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface search_form_by_school_aggregate_bool_exp_bool_or {
  arguments: search_form_by_school_select_column_search_form_by_school_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: search_form_by_school_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface search_form_by_school_aggregate_bool_exp_count {
  arguments?: search_form_by_school_select_column[] | null;
  distinct?: boolean | null;
  filter?: search_form_by_school_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "search.form_by_school"
 */
export interface search_form_by_school_aggregate_order_by {
  avg?: search_form_by_school_avg_order_by | null;
  count?: order_by | null;
  max?: search_form_by_school_max_order_by | null;
  min?: search_form_by_school_min_order_by | null;
  stddev?: search_form_by_school_stddev_order_by | null;
  stddev_pop?: search_form_by_school_stddev_pop_order_by | null;
  stddev_samp?: search_form_by_school_stddev_samp_order_by | null;
  sum?: search_form_by_school_sum_order_by | null;
  var_pop?: search_form_by_school_var_pop_order_by | null;
  var_samp?: search_form_by_school_var_samp_order_by | null;
  variance?: search_form_by_school_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "search.form_by_school"
 */
export interface search_form_by_school_arr_rel_insert_input {
  data: search_form_by_school_insert_input[];
}

/**
 * order by avg() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_avg_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "search.form_by_school". All fields are combined with a logical 'AND'.
 */
export interface search_form_by_school_bool_exp {
  _and?: search_form_by_school_bool_exp[] | null;
  _not?: search_form_by_school_bool_exp | null;
  _or?: search_form_by_school_bool_exp[] | null;
  applicant_attending_school?: applicant_attending_school_bool_exp | null;
  attendance_status?: String_comparison_exp | null;
  attending_school_id?: uuid_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  form_id_text?: String_comparison_exp | null;
  form_related_grades?: search_form_related_grade_bool_exp | null;
  form_related_grades_aggregate?: search_form_related_grade_aggregate_bool_exp | null;
  form_school_rank?: form_school_rank_bool_exp | null;
  form_school_rank_id?: uuid_comparison_exp | null;
  form_school_rank_status?: String_comparison_exp | null;
  form_school_rank_sub_status?: String_comparison_exp | null;
  form_searchable_text?: search_form_searchable_text_bool_exp | null;
  form_searchable_text_aggregate?: search_form_searchable_text_aggregate_bool_exp | null;
  form_status?: String_comparison_exp | null;
  form_status_updated_at?: timestamptz_comparison_exp | null;
  form_template_id?: uuid_comparison_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
  grade_label?: String_comparison_exp | null;
  grade_order?: Int_comparison_exp | null;
  has_offer?: Boolean_comparison_exp | null;
  has_waitlist?: Boolean_comparison_exp | null;
  is_hidden_from_parent?: Boolean_comparison_exp | null;
  lottery_order?: numeric_comparison_exp | null;
  offer_id?: uuid_comparison_exp | null;
  offer_status?: String_comparison_exp | null;
  organization_id?: uuid_comparison_exp | null;
  person_first_name?: String_comparison_exp | null;
  person_full_name?: String_comparison_exp | null;
  person_id?: uuid_comparison_exp | null;
  person_last_name?: String_comparison_exp | null;
  person_middle_name?: String_comparison_exp | null;
  person_reference_id?: String_comparison_exp | null;
  person_searchable_text?: String_comparison_exp | null;
  previous_form?: form_bool_exp | null;
  previous_form_id?: uuid_comparison_exp | null;
  previous_offer?: offer_bool_exp | null;
  previous_offer_grade_id?: uuid_comparison_exp | null;
  previous_offer_id?: uuid_comparison_exp | null;
  previous_offer_school_id?: uuid_comparison_exp | null;
  previous_offer_status?: String_comparison_exp | null;
  previous_waitlist?: waitlist_bool_exp | null;
  previous_waitlist_grade_id?: uuid_comparison_exp | null;
  previous_waitlist_id?: uuid_comparison_exp | null;
  previous_waitlist_school_id?: uuid_comparison_exp | null;
  previous_waitlist_status?: String_comparison_exp | null;
  rank?: Int_comparison_exp | null;
  relevant_persons?: search_relevant_person_bool_exp | null;
  relevant_persons_aggregate?: search_relevant_person_aggregate_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  school_name?: String_comparison_exp | null;
  school_reference_id?: String_comparison_exp | null;
  sub_status_updated_at?: timestamptz_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
  waitlist_id?: uuid_comparison_exp | null;
  waitlist_position?: Int_comparison_exp | null;
  waitlist_status?: String_comparison_exp | null;
  waitlists?: waitlist_bool_exp | null;
  waitlists_aggregate?: waitlist_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "search.form_by_school"
 */
export interface search_form_by_school_insert_input {
  applicant_attending_school?: applicant_attending_school_obj_rel_insert_input | null;
  attendance_status?: string | null;
  attending_school_id?: uuid | null;
  deleted_at?: timestamptz | null;
  enrollment_period_id?: uuid | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  form_id_text?: string | null;
  form_related_grades?: search_form_related_grade_arr_rel_insert_input | null;
  form_school_rank?: form_school_rank_obj_rel_insert_input | null;
  form_school_rank_id?: uuid | null;
  form_school_rank_status?: string | null;
  form_school_rank_sub_status?: string | null;
  form_searchable_text?: search_form_searchable_text_arr_rel_insert_input | null;
  form_status?: string | null;
  form_status_updated_at?: timestamptz | null;
  form_template_id?: uuid | null;
  grade_config_id?: uuid | null;
  grade_label?: string | null;
  grade_order?: number | null;
  has_offer?: boolean | null;
  has_waitlist?: boolean | null;
  is_hidden_from_parent?: boolean | null;
  lottery_order?: numeric | null;
  offer_id?: uuid | null;
  offer_status?: string | null;
  organization_id?: uuid | null;
  person_first_name?: string | null;
  person_full_name?: string | null;
  person_id?: uuid | null;
  person_last_name?: string | null;
  person_middle_name?: string | null;
  person_reference_id?: string | null;
  person_searchable_text?: string | null;
  previous_form?: form_obj_rel_insert_input | null;
  previous_form_id?: uuid | null;
  previous_offer?: offer_obj_rel_insert_input | null;
  previous_offer_grade_id?: uuid | null;
  previous_offer_id?: uuid | null;
  previous_offer_school_id?: uuid | null;
  previous_offer_status?: string | null;
  previous_waitlist?: waitlist_obj_rel_insert_input | null;
  previous_waitlist_grade_id?: uuid | null;
  previous_waitlist_id?: uuid | null;
  previous_waitlist_school_id?: uuid | null;
  previous_waitlist_status?: string | null;
  rank?: number | null;
  relevant_persons?: search_relevant_person_arr_rel_insert_input | null;
  school_id?: uuid | null;
  school_name?: string | null;
  school_reference_id?: string | null;
  sub_status_updated_at?: timestamptz | null;
  submitted_at?: timestamptz | null;
  waitlist_id?: uuid | null;
  waitlist_position?: number | null;
  waitlist_status?: string | null;
  waitlists?: waitlist_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_max_order_by {
  attendance_status?: order_by | null;
  attending_school_id?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_id?: order_by | null;
  form_id_text?: order_by | null;
  form_school_rank_id?: order_by | null;
  form_school_rank_status?: order_by | null;
  form_school_rank_sub_status?: order_by | null;
  form_status?: order_by | null;
  form_status_updated_at?: order_by | null;
  form_template_id?: order_by | null;
  grade_config_id?: order_by | null;
  grade_label?: order_by | null;
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  offer_id?: order_by | null;
  offer_status?: order_by | null;
  organization_id?: order_by | null;
  person_first_name?: order_by | null;
  person_full_name?: order_by | null;
  person_id?: order_by | null;
  person_last_name?: order_by | null;
  person_middle_name?: order_by | null;
  person_reference_id?: order_by | null;
  person_searchable_text?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_grade_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_offer_school_id?: order_by | null;
  previous_offer_status?: order_by | null;
  previous_waitlist_grade_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  previous_waitlist_school_id?: order_by | null;
  previous_waitlist_status?: order_by | null;
  rank?: order_by | null;
  school_id?: order_by | null;
  school_name?: order_by | null;
  school_reference_id?: order_by | null;
  sub_status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  waitlist_id?: order_by | null;
  waitlist_position?: order_by | null;
  waitlist_status?: order_by | null;
}

/**
 * order by min() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_min_order_by {
  attendance_status?: order_by | null;
  attending_school_id?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form_id?: order_by | null;
  form_id_text?: order_by | null;
  form_school_rank_id?: order_by | null;
  form_school_rank_status?: order_by | null;
  form_school_rank_sub_status?: order_by | null;
  form_status?: order_by | null;
  form_status_updated_at?: order_by | null;
  form_template_id?: order_by | null;
  grade_config_id?: order_by | null;
  grade_label?: order_by | null;
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  offer_id?: order_by | null;
  offer_status?: order_by | null;
  organization_id?: order_by | null;
  person_first_name?: order_by | null;
  person_full_name?: order_by | null;
  person_id?: order_by | null;
  person_last_name?: order_by | null;
  person_middle_name?: order_by | null;
  person_reference_id?: order_by | null;
  person_searchable_text?: order_by | null;
  previous_form_id?: order_by | null;
  previous_offer_grade_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_offer_school_id?: order_by | null;
  previous_offer_status?: order_by | null;
  previous_waitlist_grade_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  previous_waitlist_school_id?: order_by | null;
  previous_waitlist_status?: order_by | null;
  rank?: order_by | null;
  school_id?: order_by | null;
  school_name?: order_by | null;
  school_reference_id?: order_by | null;
  sub_status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  waitlist_id?: order_by | null;
  waitlist_position?: order_by | null;
  waitlist_status?: order_by | null;
}

/**
 * Ordering options when selecting data from "search.form_by_school".
 */
export interface search_form_by_school_order_by {
  applicant_attending_school?: applicant_attending_school_order_by | null;
  attendance_status?: order_by | null;
  attending_school_id?: order_by | null;
  deleted_at?: order_by | null;
  enrollment_period_id?: order_by | null;
  form?: form_order_by | null;
  form_id?: order_by | null;
  form_id_text?: order_by | null;
  form_related_grades_aggregate?: search_form_related_grade_aggregate_order_by | null;
  form_school_rank?: form_school_rank_order_by | null;
  form_school_rank_id?: order_by | null;
  form_school_rank_status?: order_by | null;
  form_school_rank_sub_status?: order_by | null;
  form_searchable_text_aggregate?: search_form_searchable_text_aggregate_order_by | null;
  form_status?: order_by | null;
  form_status_updated_at?: order_by | null;
  form_template_id?: order_by | null;
  grade_config_id?: order_by | null;
  grade_label?: order_by | null;
  grade_order?: order_by | null;
  has_offer?: order_by | null;
  has_waitlist?: order_by | null;
  is_hidden_from_parent?: order_by | null;
  lottery_order?: order_by | null;
  offer_id?: order_by | null;
  offer_status?: order_by | null;
  organization_id?: order_by | null;
  person_first_name?: order_by | null;
  person_full_name?: order_by | null;
  person_id?: order_by | null;
  person_last_name?: order_by | null;
  person_middle_name?: order_by | null;
  person_reference_id?: order_by | null;
  person_searchable_text?: order_by | null;
  previous_form?: form_order_by | null;
  previous_form_id?: order_by | null;
  previous_offer?: offer_order_by | null;
  previous_offer_grade_id?: order_by | null;
  previous_offer_id?: order_by | null;
  previous_offer_school_id?: order_by | null;
  previous_offer_status?: order_by | null;
  previous_waitlist?: waitlist_order_by | null;
  previous_waitlist_grade_id?: order_by | null;
  previous_waitlist_id?: order_by | null;
  previous_waitlist_school_id?: order_by | null;
  previous_waitlist_status?: order_by | null;
  rank?: order_by | null;
  relevant_persons_aggregate?: search_relevant_person_aggregate_order_by | null;
  school_id?: order_by | null;
  school_name?: order_by | null;
  school_reference_id?: order_by | null;
  sub_status_updated_at?: order_by | null;
  submitted_at?: order_by | null;
  waitlist_id?: order_by | null;
  waitlist_position?: order_by | null;
  waitlist_status?: order_by | null;
  waitlists_aggregate?: waitlist_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_stddev_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_stddev_pop_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_stddev_samp_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by sum() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_sum_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by var_pop() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_var_pop_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by var_samp() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_var_samp_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

/**
 * order by variance() on columns of table "search.form_by_school"
 */
export interface search_form_by_school_variance_order_by {
  grade_order?: order_by | null;
  lottery_order?: order_by | null;
  rank?: order_by | null;
  waitlist_position?: order_by | null;
}

export interface search_form_related_grade_aggregate_bool_exp {
  count?: search_form_related_grade_aggregate_bool_exp_count | null;
}

export interface search_form_related_grade_aggregate_bool_exp_count {
  arguments?: search_form_related_grade_select_column[] | null;
  distinct?: boolean | null;
  filter?: search_form_related_grade_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "search.form_related_grade"
 */
export interface search_form_related_grade_aggregate_order_by {
  count?: order_by | null;
  max?: search_form_related_grade_max_order_by | null;
  min?: search_form_related_grade_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "search.form_related_grade"
 */
export interface search_form_related_grade_arr_rel_insert_input {
  data: search_form_related_grade_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "search.form_related_grade". All fields are combined with a logical 'AND'.
 */
export interface search_form_related_grade_bool_exp {
  _and?: search_form_related_grade_bool_exp[] | null;
  _not?: search_form_related_grade_bool_exp | null;
  _or?: search_form_related_grade_bool_exp[] | null;
  form_id?: uuid_comparison_exp | null;
  grade_config_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "search.form_related_grade"
 */
export interface search_form_related_grade_insert_input {
  form_id?: uuid | null;
  grade_config_id?: uuid | null;
}

/**
 * order by max() on columns of table "search.form_related_grade"
 */
export interface search_form_related_grade_max_order_by {
  form_id?: order_by | null;
  grade_config_id?: order_by | null;
}

/**
 * order by min() on columns of table "search.form_related_grade"
 */
export interface search_form_related_grade_min_order_by {
  form_id?: order_by | null;
  grade_config_id?: order_by | null;
}

export interface search_form_searchable_text_aggregate_bool_exp {
  count?: search_form_searchable_text_aggregate_bool_exp_count | null;
}

export interface search_form_searchable_text_aggregate_bool_exp_count {
  arguments?: search_form_searchable_text_select_column[] | null;
  distinct?: boolean | null;
  filter?: search_form_searchable_text_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "search.form_searchable_text"
 */
export interface search_form_searchable_text_aggregate_order_by {
  count?: order_by | null;
  max?: search_form_searchable_text_max_order_by | null;
  min?: search_form_searchable_text_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "search.form_searchable_text"
 */
export interface search_form_searchable_text_arr_rel_insert_input {
  data: search_form_searchable_text_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "search.form_searchable_text". All fields are combined with a logical 'AND'.
 */
export interface search_form_searchable_text_bool_exp {
  _and?: search_form_searchable_text_bool_exp[] | null;
  _not?: search_form_searchable_text_bool_exp | null;
  _or?: search_form_searchable_text_bool_exp[] | null;
  form_id?: uuid_comparison_exp | null;
  searchable_text?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "search.form_searchable_text"
 */
export interface search_form_searchable_text_insert_input {
  form_id?: uuid | null;
  searchable_text?: string | null;
}

/**
 * order by max() on columns of table "search.form_searchable_text"
 */
export interface search_form_searchable_text_max_order_by {
  form_id?: order_by | null;
  searchable_text?: order_by | null;
}

/**
 * order by min() on columns of table "search.form_searchable_text"
 */
export interface search_form_searchable_text_min_order_by {
  form_id?: order_by | null;
  searchable_text?: order_by | null;
}

export interface search_relevant_person_aggregate_bool_exp {
  count?: search_relevant_person_aggregate_bool_exp_count | null;
}

export interface search_relevant_person_aggregate_bool_exp_count {
  arguments?: search_relevant_person_select_column[] | null;
  distinct?: boolean | null;
  filter?: search_relevant_person_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "search.relevant_person"
 */
export interface search_relevant_person_aggregate_order_by {
  count?: order_by | null;
  max?: search_relevant_person_max_order_by | null;
  min?: search_relevant_person_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "search.relevant_person"
 */
export interface search_relevant_person_arr_rel_insert_input {
  data: search_relevant_person_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "search.relevant_person". All fields are combined with a logical 'AND'.
 */
export interface search_relevant_person_bool_exp {
  _and?: search_relevant_person_bool_exp[] | null;
  _not?: search_relevant_person_bool_exp | null;
  _or?: search_relevant_person_bool_exp[] | null;
  organization_id?: uuid_comparison_exp | null;
  person_id?: uuid_comparison_exp | null;
  preferred_language?: String_comparison_exp | null;
  relevant_person_id?: uuid_comparison_exp | null;
  relevant_person_type?: String_comparison_exp | null;
  searchable_text?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "search.relevant_person"
 */
export interface search_relevant_person_insert_input {
  organization_id?: uuid | null;
  person_id?: uuid | null;
  preferred_language?: string | null;
  relevant_person_id?: uuid | null;
  relevant_person_type?: string | null;
  searchable_text?: string | null;
}

/**
 * order by max() on columns of table "search.relevant_person"
 */
export interface search_relevant_person_max_order_by {
  organization_id?: order_by | null;
  person_id?: order_by | null;
  preferred_language?: order_by | null;
  relevant_person_id?: order_by | null;
  relevant_person_type?: order_by | null;
  searchable_text?: order_by | null;
}

/**
 * order by min() on columns of table "search.relevant_person"
 */
export interface search_relevant_person_min_order_by {
  organization_id?: order_by | null;
  person_id?: order_by | null;
  preferred_language?: order_by | null;
  relevant_person_id?: order_by | null;
  relevant_person_type?: order_by | null;
  searchable_text?: order_by | null;
}

export interface status_rule_aggregate_bool_exp {
  count?: status_rule_aggregate_bool_exp_count | null;
}

export interface status_rule_aggregate_bool_exp_count {
  arguments?: status_rule_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_rule_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "status_rule"
 */
export interface status_rule_aggregate_order_by {
  count?: order_by | null;
  max?: status_rule_max_order_by | null;
  min?: status_rule_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "status_rule"
 */
export interface status_rule_arr_rel_insert_input {
  data: status_rule_insert_input[];
  on_conflict?: status_rule_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "status_rule". All fields are combined with a logical 'AND'.
 */
export interface status_rule_bool_exp {
  _and?: status_rule_bool_exp[] | null;
  _not?: status_rule_bool_exp | null;
  _or?: status_rule_bool_exp[] | null;
  formStatusByFormStatus?: form_status_bool_exp | null;
  form_status?: form_status_enum_comparison_exp | null;
  next_form_template?: form_template_bool_exp | null;
  next_form_template_id?: uuid_comparison_exp | null;
  offerStatusByOfferStatus?: offer_status_bool_exp | null;
  offer_status?: offer_status_enum_comparison_exp | null;
  rule?: rule_bool_exp | null;
  rule_id?: uuid_comparison_exp | null;
  status_rule_type?: status_rule_type_bool_exp | null;
  type?: status_rule_type_enum_comparison_exp | null;
  waitlistStatusByWaitlistStatus?: waitlist_status_bool_exp | null;
  waitlist_status?: waitlist_status_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "status_rule"
 */
export interface status_rule_insert_input {
  formStatusByFormStatus?: form_status_obj_rel_insert_input | null;
  form_status?: form_status_enum | null;
  next_form_template?: form_template_obj_rel_insert_input | null;
  next_form_template_id?: uuid | null;
  offerStatusByOfferStatus?: offer_status_obj_rel_insert_input | null;
  offer_status?: offer_status_enum | null;
  rule?: rule_obj_rel_insert_input | null;
  rule_id?: uuid | null;
  status_rule_type?: status_rule_type_obj_rel_insert_input | null;
  type?: status_rule_type_enum | null;
  waitlistStatusByWaitlistStatus?: waitlist_status_obj_rel_insert_input | null;
  waitlist_status?: waitlist_status_enum | null;
}

/**
 * order by max() on columns of table "status_rule"
 */
export interface status_rule_max_order_by {
  next_form_template_id?: order_by | null;
  rule_id?: order_by | null;
}

/**
 * order by min() on columns of table "status_rule"
 */
export interface status_rule_min_order_by {
  next_form_template_id?: order_by | null;
  rule_id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "status_rule"
 */
export interface status_rule_obj_rel_insert_input {
  data: status_rule_insert_input;
  on_conflict?: status_rule_on_conflict | null;
}

/**
 * on_conflict condition type for table "status_rule"
 */
export interface status_rule_on_conflict {
  constraint: status_rule_constraint;
  update_columns: status_rule_update_column[];
  where?: status_rule_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "status_rule_type". All fields are combined with a logical 'AND'.
 */
export interface status_rule_type_bool_exp {
  _and?: status_rule_type_bool_exp[] | null;
  _not?: status_rule_type_bool_exp | null;
  _or?: status_rule_type_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "status_rule_type_enum". All fields are combined with logical 'AND'.
 */
export interface status_rule_type_enum_comparison_exp {
  _eq?: status_rule_type_enum | null;
  _in?: status_rule_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: status_rule_type_enum | null;
  _nin?: status_rule_type_enum[] | null;
}

/**
 * input type for inserting data into table "status_rule_type"
 */
export interface status_rule_type_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "status_rule_type"
 */
export interface status_rule_type_obj_rel_insert_input {
  data: status_rule_type_insert_input;
  on_conflict?: status_rule_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "status_rule_type"
 */
export interface status_rule_type_on_conflict {
  constraint: status_rule_type_constraint;
  update_columns: status_rule_type_update_column[];
  where?: status_rule_type_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "tag_group". All fields are combined with a logical 'AND'.
 */
export interface tag_group_bool_exp {
  _and?: tag_group_bool_exp[] | null;
  _not?: tag_group_bool_exp | null;
  _or?: tag_group_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  enrollment_period?: enrollment_period_bool_exp | null;
  enrollment_period_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  is_default?: Boolean_comparison_exp | null;
  is_external?: Boolean_comparison_exp | null;
  max_tags?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  tags?: enrollment_period_tag_bool_exp | null;
  tags_aggregate?: enrollment_period_tag_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "tag_group"
 */
export interface tag_group_insert_input {
  created_at?: timestamptz | null;
  enrollment_period?: enrollment_period_obj_rel_insert_input | null;
  enrollment_period_id?: uuid | null;
  id?: uuid | null;
  is_default?: boolean | null;
  is_external?: boolean | null;
  max_tags?: number | null;
  name?: string | null;
  tags?: enrollment_period_tag_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "tag_group"
 */
export interface tag_group_obj_rel_insert_input {
  data: tag_group_insert_input;
  on_conflict?: tag_group_on_conflict | null;
}

/**
 * on_conflict condition type for table "tag_group"
 */
export interface tag_group_on_conflict {
  constraint: tag_group_constraint;
  update_columns: tag_group_update_column[];
  where?: tag_group_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'.
 */
export interface team_bool_exp {
  _and?: team_bool_exp[] | null;
  _not?: team_bool_exp | null;
  _or?: team_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization_id?: uuid_comparison_exp | null;
  team_members?: person_team_bool_exp | null;
  team_members_aggregate?: person_team_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "team"
 */
export interface team_insert_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  id?: uuid | null;
  name?: string | null;
  organization_id?: uuid | null;
  team_members?: person_team_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "team"
 */
export interface team_obj_rel_insert_input {
  data: team_insert_input;
  on_conflict?: team_on_conflict | null;
}

/**
 * on_conflict condition type for table "team"
 */
export interface team_on_conflict {
  constraint: team_constraint;
  update_columns: team_update_column[];
  where?: team_bool_exp | null;
}

/**
 * Ordering options when selecting data from "team".
 */
export interface team_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
  team_members_aggregate?: person_team_aggregate_order_by | null;
  updated_at?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: timestamptz | null;
  _gt?: timestamptz | null;
  _gte?: timestamptz | null;
  _in?: timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: timestamptz | null;
  _lte?: timestamptz | null;
  _neq?: timestamptz | null;
  _nin?: timestamptz[] | null;
}

export interface user_address_aggregate_bool_exp {
  count?: user_address_aggregate_bool_exp_count | null;
}

export interface user_address_aggregate_bool_exp_count {
  arguments?: user_address_select_column[] | null;
  distinct?: boolean | null;
  filter?: user_address_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "user_address"
 */
export interface user_address_aggregate_order_by {
  count?: order_by | null;
  max?: user_address_max_order_by | null;
  min?: user_address_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_address"
 */
export interface user_address_arr_rel_insert_input {
  data: user_address_insert_input[];
  on_conflict?: user_address_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_address". All fields are combined with a logical 'AND'.
 */
export interface user_address_bool_exp {
  _and?: user_address_bool_exp[] | null;
  _not?: user_address_bool_exp | null;
  _or?: user_address_bool_exp[] | null;
  city?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  state?: String_comparison_exp | null;
  street_address?: String_comparison_exp | null;
  street_address_line_2?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: user_bool_exp | null;
  user_id?: String_comparison_exp | null;
  zip_code?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_address"
 */
export interface user_address_insert_input {
  city?: string | null;
  created_at?: timestamptz | null;
  id?: uuid | null;
  state?: string | null;
  street_address?: string | null;
  street_address_line_2?: string | null;
  updated_at?: timestamptz | null;
  user?: user_obj_rel_insert_input | null;
  user_id?: string | null;
  zip_code?: string | null;
}

/**
 * order by max() on columns of table "user_address"
 */
export interface user_address_max_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  user_id?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * order by min() on columns of table "user_address"
 */
export interface user_address_min_order_by {
  city?: order_by | null;
  created_at?: order_by | null;
  id?: order_by | null;
  state?: order_by | null;
  street_address?: order_by | null;
  street_address_line_2?: order_by | null;
  updated_at?: order_by | null;
  user_id?: order_by | null;
  zip_code?: order_by | null;
}

/**
 * on_conflict condition type for table "user_address"
 */
export interface user_address_on_conflict {
  constraint: user_address_constraint;
  update_columns: user_address_update_column[];
  where?: user_address_bool_exp | null;
}

export interface user_aggregate_bool_exp {
  count?: user_aggregate_bool_exp_count | null;
}

export interface user_aggregate_bool_exp_count {
  arguments?: user_select_column[] | null;
  distinct?: boolean | null;
  filter?: user_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "user"
 */
export interface user_aggregate_order_by {
  count?: order_by | null;
  max?: user_max_order_by | null;
  min?: user_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user"
 */
export interface user_arr_rel_insert_input {
  data: user_insert_input[];
  on_conflict?: user_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'.
 */
export interface user_bool_exp {
  _and?: user_bool_exp[] | null;
  _not?: user_bool_exp | null;
  _or?: user_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  guardian_applicant_schools?: applicant_school_guardian_user_bool_exp | null;
  guardian_applicant_schools_aggregate?: applicant_school_guardian_user_aggregate_bool_exp | null;
  id?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  people?: person_bool_exp | null;
  people_aggregate?: person_aggregate_bool_exp | null;
  school_users?: school_user_bool_exp | null;
  school_users_aggregate?: school_user_aggregate_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user_addresses?: user_address_bool_exp | null;
  user_addresses_aggregate?: user_address_aggregate_bool_exp | null;
  user_group?: user_group_bool_exp | null;
  user_group_id?: uuid_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_group". All fields are combined with a logical 'AND'.
 */
export interface user_group_bool_exp {
  _and?: user_group_bool_exp[] | null;
  _not?: user_group_bool_exp | null;
  _or?: user_group_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  person_type?: person_type_enum_comparison_exp | null;
  user_group_permissions?: user_group_permission_bool_exp | null;
  user_group_permissions_aggregate?: user_group_permission_aggregate_bool_exp | null;
  users?: user_bool_exp | null;
  users_aggregate?: user_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "user_group"
 */
export interface user_group_insert_input {
  id?: uuid | null;
  label?: string | null;
  name?: string | null;
  person_type?: person_type_enum | null;
  user_group_permissions?: user_group_permission_arr_rel_insert_input | null;
  users?: user_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_group"
 */
export interface user_group_obj_rel_insert_input {
  data: user_group_insert_input;
  on_conflict?: user_group_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_group"
 */
export interface user_group_on_conflict {
  constraint: user_group_constraint;
  update_columns: user_group_update_column[];
  where?: user_group_bool_exp | null;
}

/**
 * Ordering options when selecting data from "user_group".
 */
export interface user_group_order_by {
  id?: order_by | null;
  label?: order_by | null;
  name?: order_by | null;
  person_type?: order_by | null;
  user_group_permissions_aggregate?: user_group_permission_aggregate_order_by | null;
  users_aggregate?: user_aggregate_order_by | null;
}

export interface user_group_permission_aggregate_bool_exp {
  count?: user_group_permission_aggregate_bool_exp_count | null;
}

export interface user_group_permission_aggregate_bool_exp_count {
  arguments?: user_group_permission_select_column[] | null;
  distinct?: boolean | null;
  filter?: user_group_permission_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "user_group_permission"
 */
export interface user_group_permission_aggregate_order_by {
  count?: order_by | null;
  max?: user_group_permission_max_order_by | null;
  min?: user_group_permission_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_group_permission"
 */
export interface user_group_permission_arr_rel_insert_input {
  data: user_group_permission_insert_input[];
  on_conflict?: user_group_permission_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_group_permission". All fields are combined with a logical 'AND'.
 */
export interface user_group_permission_bool_exp {
  _and?: user_group_permission_bool_exp[] | null;
  _not?: user_group_permission_bool_exp | null;
  _or?: user_group_permission_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  permission?: String_comparison_exp | null;
  user_group?: user_group_bool_exp | null;
  user_group_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_group_permission"
 */
export interface user_group_permission_insert_input {
  id?: uuid | null;
  permission?: string | null;
  user_group?: user_group_obj_rel_insert_input | null;
  user_group_id?: uuid | null;
}

/**
 * order by max() on columns of table "user_group_permission"
 */
export interface user_group_permission_max_order_by {
  id?: order_by | null;
  permission?: order_by | null;
  user_group_id?: order_by | null;
}

/**
 * order by min() on columns of table "user_group_permission"
 */
export interface user_group_permission_min_order_by {
  id?: order_by | null;
  permission?: order_by | null;
  user_group_id?: order_by | null;
}

/**
 * on_conflict condition type for table "user_group_permission"
 */
export interface user_group_permission_on_conflict {
  constraint: user_group_permission_constraint;
  update_columns: user_group_permission_update_column[];
  where?: user_group_permission_bool_exp | null;
}

/**
 * input type for inserting data into table "user"
 */
export interface user_insert_input {
  created_at?: timestamptz | null;
  guardian_applicant_schools?: applicant_school_guardian_user_arr_rel_insert_input | null;
  id?: string | null;
  name?: string | null;
  people?: person_arr_rel_insert_input | null;
  school_users?: school_user_arr_rel_insert_input | null;
  updated_at?: timestamptz | null;
  user_addresses?: user_address_arr_rel_insert_input | null;
  user_group?: user_group_obj_rel_insert_input | null;
  user_group_id?: uuid | null;
}

/**
 * order by max() on columns of table "user"
 */
export interface user_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
  user_group_id?: order_by | null;
}

/**
 * order by min() on columns of table "user"
 */
export interface user_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
  user_group_id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "user"
 */
export interface user_obj_rel_insert_input {
  data: user_insert_input;
  on_conflict?: user_on_conflict | null;
}

/**
 * on_conflict condition type for table "user"
 */
export interface user_on_conflict {
  constraint: user_constraint;
  update_columns: user_update_column[];
  where?: user_bool_exp | null;
}

/**
 * Ordering options when selecting data from "user".
 */
export interface user_order_by {
  created_at?: order_by | null;
  guardian_applicant_schools_aggregate?: applicant_school_guardian_user_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  people_aggregate?: person_aggregate_order_by | null;
  school_users_aggregate?: school_user_aggregate_order_by | null;
  updated_at?: order_by | null;
  user_addresses_aggregate?: user_address_aggregate_order_by | null;
  user_group?: user_group_order_by | null;
  user_group_id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: uuid | null;
  _gt?: uuid | null;
  _gte?: uuid | null;
  _in?: uuid[] | null;
  _is_null?: boolean | null;
  _lt?: uuid | null;
  _lte?: uuid | null;
  _neq?: uuid | null;
  _nin?: uuid[] | null;
}

/**
 * Boolean expression to compare columns of type "verification_status_enum". All fields are combined with logical 'AND'.
 */
export interface verification_status_enum_comparison_exp {
  _eq?: verification_status_enum | null;
  _in?: verification_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: verification_status_enum | null;
  _nin?: verification_status_enum[] | null;
}

export interface waitlist_aggregate_bool_exp {
  count?: waitlist_aggregate_bool_exp_count | null;
}

export interface waitlist_aggregate_bool_exp_count {
  arguments?: waitlist_select_column[] | null;
  distinct?: boolean | null;
  filter?: waitlist_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "waitlist"
 */
export interface waitlist_aggregate_order_by {
  avg?: waitlist_avg_order_by | null;
  count?: order_by | null;
  max?: waitlist_max_order_by | null;
  min?: waitlist_min_order_by | null;
  stddev?: waitlist_stddev_order_by | null;
  stddev_pop?: waitlist_stddev_pop_order_by | null;
  stddev_samp?: waitlist_stddev_samp_order_by | null;
  sum?: waitlist_sum_order_by | null;
  var_pop?: waitlist_var_pop_order_by | null;
  var_samp?: waitlist_var_samp_order_by | null;
  variance?: waitlist_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "waitlist"
 */
export interface waitlist_arr_rel_insert_input {
  data: waitlist_insert_input[];
  on_conflict?: waitlist_on_conflict | null;
}

/**
 * order by avg() on columns of table "waitlist"
 */
export interface waitlist_avg_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "waitlist". All fields are combined with a logical 'AND'.
 */
export interface waitlist_bool_exp {
  _and?: waitlist_bool_exp[] | null;
  _not?: waitlist_bool_exp | null;
  _or?: waitlist_bool_exp[] | null;
  assigned_forms?: assigned_form_bool_exp | null;
  assigned_forms_aggregate?: assigned_form_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  form?: form_bool_exp | null;
  form_id?: uuid_comparison_exp | null;
  grade?: grade_bool_exp | null;
  grade_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  position?: Int_comparison_exp | null;
  previous_forms?: form_bool_exp | null;
  previous_forms_aggregate?: form_aggregate_bool_exp | null;
  priority_group?: Int_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  status?: waitlist_status_enum_comparison_exp | null;
  status_updated_at?: timestamptz_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  waitlist_position?: waitlist_position_bool_exp | null;
  waitlist_status?: waitlist_status_bool_exp | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_bool_exp | null;
}

/**
 * input type for incrementing numeric columns in table "waitlist"
 */
export interface waitlist_inc_input {
  position?: number | null;
  priority_group?: number | null;
}

/**
 * input type for inserting data into table "waitlist"
 */
export interface waitlist_insert_input {
  assigned_forms?: assigned_form_arr_rel_insert_input | null;
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form?: form_obj_rel_insert_input | null;
  form_id?: uuid | null;
  grade?: grade_obj_rel_insert_input | null;
  grade_id?: uuid | null;
  id?: uuid | null;
  position?: number | null;
  previous_forms?: form_arr_rel_insert_input | null;
  priority_group?: number | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  status?: waitlist_status_enum | null;
  status_updated_at?: timestamptz | null;
  updated_at?: timestamptz | null;
  waitlist_position?: waitlist_position_obj_rel_insert_input | null;
  waitlist_status?: waitlist_status_obj_rel_insert_input | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "waitlist"
 */
export interface waitlist_max_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_id?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  position?: order_by | null;
  priority_group?: order_by | null;
  school_id?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "waitlist"
 */
export interface waitlist_min_order_by {
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form_id?: order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  position?: order_by | null;
  priority_group?: order_by | null;
  school_id?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "waitlist"
 */
export interface waitlist_obj_rel_insert_input {
  data: waitlist_insert_input;
  on_conflict?: waitlist_on_conflict | null;
}

/**
 * on_conflict condition type for table "waitlist"
 */
export interface waitlist_on_conflict {
  constraint: waitlist_constraint;
  update_columns: waitlist_update_column[];
  where?: waitlist_bool_exp | null;
}

/**
 * Ordering options when selecting data from "waitlist".
 */
export interface waitlist_order_by {
  assigned_forms_aggregate?: assigned_form_aggregate_order_by | null;
  created_at?: order_by | null;
  deleted_at?: order_by | null;
  form?: form_order_by | null;
  form_id?: order_by | null;
  grade?: grade_order_by | null;
  grade_id?: order_by | null;
  id?: order_by | null;
  position?: order_by | null;
  previous_forms_aggregate?: form_aggregate_order_by | null;
  priority_group?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  status?: order_by | null;
  status_updated_at?: order_by | null;
  updated_at?: order_by | null;
  waitlist_position?: waitlist_position_order_by | null;
  waitlist_status?: waitlist_status_order_by | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "waitlist_position". All fields are combined with a logical 'AND'.
 */
export interface waitlist_position_bool_exp {
  _and?: waitlist_position_bool_exp[] | null;
  _not?: waitlist_position_bool_exp | null;
  _or?: waitlist_position_bool_exp[] | null;
  form_id?: uuid_comparison_exp | null;
  grade_id?: uuid_comparison_exp | null;
  position?: Int_comparison_exp | null;
  school_id?: uuid_comparison_exp | null;
  waitlist?: waitlist_bool_exp | null;
  waitlist_id?: uuid_comparison_exp | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_bool_exp | null;
}

/**
 * input type for inserting data into table "waitlist_position"
 */
export interface waitlist_position_insert_input {
  form_id?: uuid | null;
  grade_id?: uuid | null;
  position?: number | null;
  school_id?: uuid | null;
  waitlist?: waitlist_obj_rel_insert_input | null;
  waitlist_id?: uuid | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "waitlist_position"
 */
export interface waitlist_position_obj_rel_insert_input {
  data: waitlist_position_insert_input;
}

/**
 * Ordering options when selecting data from "waitlist_position".
 */
export interface waitlist_position_order_by {
  form_id?: order_by | null;
  grade_id?: order_by | null;
  position?: order_by | null;
  school_id?: order_by | null;
  waitlist?: waitlist_order_by | null;
  waitlist_id?: order_by | null;
  waitlist_vs_capacity?: waitlist_vs_capacity_order_by | null;
}

/**
 * input type for updating data in table "waitlist"
 */
export interface waitlist_set_input {
  created_at?: timestamptz | null;
  deleted_at?: timestamptz | null;
  form_id?: uuid | null;
  grade_id?: uuid | null;
  id?: uuid | null;
  position?: number | null;
  priority_group?: number | null;
  school_id?: uuid | null;
  status?: waitlist_status_enum | null;
  status_updated_at?: timestamptz | null;
  updated_at?: timestamptz | null;
}

/**
 * Boolean expression to filter rows from the table "waitlist_status". All fields are combined with a logical 'AND'.
 */
export interface waitlist_status_bool_exp {
  _and?: waitlist_status_bool_exp[] | null;
  _not?: waitlist_status_bool_exp | null;
  _or?: waitlist_status_bool_exp[] | null;
  value?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "waitlist_status_enum". All fields are combined with logical 'AND'.
 */
export interface waitlist_status_enum_comparison_exp {
  _eq?: waitlist_status_enum | null;
  _in?: waitlist_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: waitlist_status_enum | null;
  _nin?: waitlist_status_enum[] | null;
}

/**
 * input type for inserting data into table "waitlist_status"
 */
export interface waitlist_status_insert_input {
  value?: string | null;
}

/**
 * input type for inserting object relation for remote table "waitlist_status"
 */
export interface waitlist_status_obj_rel_insert_input {
  data: waitlist_status_insert_input;
  on_conflict?: waitlist_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "waitlist_status"
 */
export interface waitlist_status_on_conflict {
  constraint: waitlist_status_constraint;
  update_columns: waitlist_status_update_column[];
  where?: waitlist_status_bool_exp | null;
}

/**
 * Ordering options when selecting data from "waitlist_status".
 */
export interface waitlist_status_order_by {
  value?: order_by | null;
}

/**
 * order by stddev() on columns of table "waitlist"
 */
export interface waitlist_stddev_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "waitlist"
 */
export interface waitlist_stddev_pop_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "waitlist"
 */
export interface waitlist_stddev_samp_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * order by sum() on columns of table "waitlist"
 */
export interface waitlist_sum_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

export interface waitlist_updates {
  _inc?: waitlist_inc_input | null;
  _set?: waitlist_set_input | null;
  where: waitlist_bool_exp;
}

/**
 * order by var_pop() on columns of table "waitlist"
 */
export interface waitlist_var_pop_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * order by var_samp() on columns of table "waitlist"
 */
export interface waitlist_var_samp_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * order by variance() on columns of table "waitlist"
 */
export interface waitlist_variance_order_by {
  position?: order_by | null;
  priority_group?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "waitlist_vs_capacity". All fields are combined with a logical 'AND'.
 */
export interface waitlist_vs_capacity_bool_exp {
  _and?: waitlist_vs_capacity_bool_exp[] | null;
  _not?: waitlist_vs_capacity_bool_exp | null;
  _or?: waitlist_vs_capacity_bool_exp[] | null;
  form_id?: uuid_comparison_exp | null;
  grade_id?: uuid_comparison_exp | null;
  position?: Int_comparison_exp | null;
  position_beyond_capacity?: Int_comparison_exp | null;
  school?: school_bool_exp | null;
  school_id?: uuid_comparison_exp | null;
  waitlist?: waitlist_bool_exp | null;
  waitlist_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "waitlist_vs_capacity"
 */
export interface waitlist_vs_capacity_insert_input {
  form_id?: uuid | null;
  grade_id?: uuid | null;
  position?: number | null;
  position_beyond_capacity?: number | null;
  school?: school_obj_rel_insert_input | null;
  school_id?: uuid | null;
  waitlist?: waitlist_obj_rel_insert_input | null;
  waitlist_id?: uuid | null;
}

/**
 * input type for inserting object relation for remote table "waitlist_vs_capacity"
 */
export interface waitlist_vs_capacity_obj_rel_insert_input {
  data: waitlist_vs_capacity_insert_input;
}

/**
 * Ordering options when selecting data from "waitlist_vs_capacity".
 */
export interface waitlist_vs_capacity_order_by {
  form_id?: order_by | null;
  grade_id?: order_by | null;
  position?: order_by | null;
  position_beyond_capacity?: order_by | null;
  school?: school_order_by | null;
  school_id?: order_by | null;
  waitlist?: waitlist_order_by | null;
  waitlist_id?: order_by | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
