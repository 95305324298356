import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AccountLookupStep } from "../Step";
import { CreateAnAccountProps } from "./CreateAnAccount";
import {
  AuthenticationMethodType,
  useSpecificAuthenticationLink,
} from "./helpers";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

type SignUpConfirmationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedSignUpMethod: string;
  selectedSignUpMethodType: AuthenticationMethodType;
  unusedSignUpMethodType: string;
};

/**
 * This is fundamentally a ConfirmationDialog sans header and footer.
 * Our confirmationDialogHook expects a header, so create this new one instead.
 */
const SignUpConfirmationDialog: FunctionComponent<
  SignUpConfirmationDialogProps
> = (props) => {
  const {
    isOpen,
    onClose,
    selectedSignUpMethod,
    selectedSignUpMethodType,
    unusedSignUpMethodType,
  } = props;
  const signUpLink = useSpecificAuthenticationLink(
    "signup",
    selectedSignUpMethodType!
  );

  const { state: formState } = useAccountLookupState();

  const signUpState = {
    account_lookup_method: selectedSignUpMethodType,
    account_lookup_email:
      selectedSignUpMethodType === AuthenticationMethodType.EMAIL
        ? selectedSignUpMethod
        : "",
    account_lookup_phone_number:
      selectedSignUpMethodType === AuthenticationMethodType.PHONE
        ? selectedSignUpMethod
        : "",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent margin={5}>
        <ModalBody
          as={Flex}
          direction="column"
          padding={6}
          gap={4}
          textAlign="center"
        >
          <Text fontSize="2xl" fontWeight={600}>
            {selectedSignUpMethod}
          </Text>
          <Text fontSize="md" fontWeight={700}>
            When you create an account, you will only be able to login using
            this {selectedSignUpMethodType}.
          </Text>
          <Text size="sm" fontWeight={400} color="gray.600">
            You will <em>not</em> be able to login via {unusedSignUpMethodType}.
          </Text>

          <Button
            as={NavLink}
            to={signUpLink}
            onClick={() => {
              amplitude.track(
                "Account Lookup Two Option Sign Up Confirmation Dialog Sign Up Button Clicked",
                {
                  ...formState,
                  ...signUpState,
                }
              );
            }}
          >
            Ok, sign up with {selectedSignUpMethodType}
          </Button>

          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => {
              amplitude.track(
                "Account Lookup Two Option Sign Up Confirmation Dialog Help Button Clicked",
                {
                  ...formState,
                  ...signUpState,
                }
              );
            }}
          >
            Get help
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type AuthenticationMethod = {
  type?: AuthenticationMethodType;
  value?: string;
};

export const TwoOptionSignUp: FunctionComponent<CreateAnAccountProps> = (
  props
) => {
  const { emailAddress, phoneNumber, config } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [authenticationMethod, setAuthenticationMethod] =
    useState<AuthenticationMethod>({ type: undefined, value: undefined });

  const { state: formState } = useAccountLookupState();

  const signUpState = {
    account_lookup_method: "",
    account_lookup_email: emailAddress,
    account_lookup_phone_number: phoneNumber,
  };
  useEffect(() => {
    amplitude.track("Account Lookup Two Option Sign Up Started", {
      ...formState,
      ...signUpState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire the amplitude.track when the component first renders

  const authenticationMethods: AuthenticationMethod[] = [
    { type: AuthenticationMethodType.PHONE, value: phoneNumber! },
    { type: AuthenticationMethodType.EMAIL, value: emailAddress! },
  ];

  const { type, value } = authenticationMethod!;
  const unusedSignUpMethodType =
    authenticationMethod?.type === AuthenticationMethodType.PHONE
      ? AuthenticationMethodType.EMAIL
      : AuthenticationMethodType.PHONE;

  const handleOpenModal = useCallback(
    (method: AuthenticationMethod) => {
      setAuthenticationMethod(method);
      onOpen();
      amplitude.track("Account Lookup Two Option Sign Up Method Selected", {
        ...formState,
        ...signUpState,
        account_lookup_method: method,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOpen]
  );

  return (
    <AccountLookupStep
      heading="🎉 Create your account 🎉"
      description={`The information below can be used to create a new account.`}
    >
      <SignUpConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        selectedSignUpMethod={value!}
        selectedSignUpMethodType={type!}
        unusedSignUpMethodType={unusedSignUpMethodType}
      />
      <Stack gap={4} textAlign="center">
        {authenticationMethods.map((method) => {
          return (
            <Text fontSize="2xl" fontWeight={600}>
              {method.value}
            </Text>
          );
        })}

        {authenticationMethods.map((method) => {
          return (
            <Button
              onClick={() => {
                handleOpenModal(method);
              }}
            >
              I recognize the {method.type}
            </Button>
          );
        })}

        <Button
          as={NavLink}
          variant="outline"
          colorScheme="gray"
          to={config.supportUrl}
          onClick={() => {
            amplitude.track("Account Lookup Two Option Help Button Clicked", {
              ...formState,
              ...signUpState,
            });
          }}
        >
          I don't recognize either
        </Button>

        <Text size="sm" fontWeight={400} color="gray.600">
          The data is redacted for privacy reasons. If you do not recognize this
          data, reach out to an administrator.
        </Text>
      </Stack>
    </AccountLookupStep>
  );
};
