import { Flex } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { calculatePages, calculatePagination } from "src/hooks/usePagination";
import { Pagination } from "./Pagination";
import { Table, TableProps } from "./Table";

type PaginatedTableProps<T> = TableProps<T> & {
  limit: number;
  offset: number;
  count: number;
  onFetchMore: (limit: number, offset: number) => void;
  tableHeader?: React.FC<any>;
};

export function PaginatedTable<T>({
  limit,
  offset,
  count,
  onFetchMore,
  ...table
}: PaginatedTableProps<T>) {
  const onPageChange = useCallback(
    (currentPage: number, pageSize: number) => {
      const { limit: newLimit, offset: newOffset } = calculatePagination(
        currentPage,
        pageSize
      );
      onFetchMore && onFetchMore(newLimit, newOffset);
    },
    [onFetchMore]
  );

  const pages = useMemo(
    () => calculatePages(limit ?? 0, offset ?? 0, count ?? 0),
    [limit, offset, count]
  );

  return (
    <Flex direction="column" w="100%" alignItems="center" gap={2}>
      <Table count={count} {...table} />
      <Pagination {...pages} onChange={onPageChange} pageSize={limit} />
    </Flex>
  );
}
