import { Flex, Progress, ProgressProps, Text } from "@chakra-ui/react";

type ProgressMeterProps = ProgressProps & {
  value: number | undefined;
  stripeAtFull?: boolean;
};

export const ProgressMeter = ({
  value,
  stripeAtFull,
  ...props
}: ProgressMeterProps) => {
  const { min = 0, max = 100 } = props;
  const progress = Math.round((((value ?? min) - min) / (max - min)) * 100);
  return (
    <Flex width="100%" alignItems="center" justifyContent="center" gap={1}>
      <Progress
        value={value}
        width="100%"
        borderRadius="300px"
        hasStripe={stripeAtFull && progress >= 100}
        isAnimated={stripeAtFull}
        sx={{ "& > div:first-of-type": { transitionProperty: "width" } }}
        {...props}
      />
      <Text minWidth="3em" textAlign="right">
        {progress}%
      </Text>
    </Flex>
  );
};
