import { ChakraProvider } from "@chakra-ui/react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { ScheduledDowntime } from "src/components/Feedback/ScheduledDowntime";
import { BrandingProvider } from "src/components/Providers/BrandingProvider";
import { DefaultBranding } from "src/schemas/Branding";
import { buildTheme } from "src/services/theme/themeBuilder";
import { BrandedChakraProvider } from "../components/Providers/BrandedChakraProvider";
import { WeglotInject } from "../plugins/weglot";
import { AuthorizedAvelaRoutes } from "./AuthorizedAvelaRoutes";
import { UnauthorizedAvelaRoutes } from "./UnauthorizedRoutes";

export default function App() {
  const scheduledDowntimeIsEnabled =
    process.env.REACT_APP_MAINTENANCE_MODE === "true";

  if (scheduledDowntimeIsEnabled) {
    return (
      <ChakraProvider theme={buildTheme(DefaultBranding)}>
        <ScheduledDowntime />
      </ChakraProvider>
    );
  }

  // react-router v6 recommends the following approach for route composition:
  // https://github.com/remix-run/react-router/discussions/8228
  // - Used for AvelaAdminRoutes, OrgAdminRoutes, ParentPortalRoutes below
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <BrandingProvider>
            <BrandedChakraProvider>
              <Outlet />
            </BrandedChakraProvider>
          </BrandingProvider>
        }
      >
        {AuthorizedAvelaRoutes}
        {UnauthorizedAvelaRoutes}
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return (
    <WeglotInject>
      <RouterProvider router={router} />
    </WeglotInject>
  );
}
