import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as Url from "src/services/url";
import { HasuraRole } from "src/types/hasuraRole";

interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
}

const RadioCard = (props: RadioCardProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        minH="7em"
        maxW="12em"
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "#ECEEF8",
          color: "#4551BA",
          borderColor: "#4551BA",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        onChange={props.onChange}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const DEFAULT_DATA_SERVICE_TAB = "API Credentials";
export const DEFAULT_DATA_SERVICE_TYPE = "API Credential";

export type DataServiceTab =
  | "API Credentials"
  | "Webhooks"
  | "Scheduled exports";
export type DataServiceType = "API Credential" | "Webhook" | "ScheduledExport";
export type DataService = {
  tab: DataServiceTab;
  type: DataServiceType;
  description: string;
  link: string;
  excludeFromCreate?: boolean;
};

export const useCreateDataService = (
  type: DataServiceType = DEFAULT_DATA_SERVICE_TYPE
) => {
  const organization = useOrganization();
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const services: DataService[] = React.useMemo(
    () => [
      ...(isAvelaAdmin
        ? ([
            {
              tab: "API Credentials",
              type: "API Credential",
              description:
                "Generate client secrets to identify your connection",
              link: organization
                .map((org) => Url.OrgAdmin.ApiCredentials.new(org))
                .withDefault("#"),
            },
            {
              tab: "Webhooks",
              type: "Webhook",
              description: "Enter the endpoint URL and type of connection",
              link: organization
                .map((org) => Url.OrgAdmin.Webhooks.new(org))
                .withDefault("#"),
            },
          ] as DataService[])
        : []),
      {
        tab: "Scheduled exports",
        type: "ScheduledExport",
        description: "",
        link: organization
          .map((org) => Url.OrgAdmin.ScheduledExports.view(org))
          .withDefault("#"),
        excludeFromCreate: true,
      },
    ],
    [organization, isAvelaAdmin]
  );

  const [selectedService, setSelectedService] = React.useState<DataService>();

  React.useEffect(() => {
    onChange(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const onChange = (nextValue: DataServiceType) => {
    const service = services.find((service) => service.type === nextValue);
    setSelectedService(service);
  };

  const onCancel = () => {
    onChange(type);
    onClose();
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "data_services",
    value: selectedService?.type,
    onChange: onChange,
  });

  const group = getRootProps();

  const dialog = (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent width="28em">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Create data service
          </AlertDialogHeader>
          <AlertDialogBody>
            <Flex direction="column" gap={6}>
              What kind of service do you want to create?
              <HStack {...group} justify="center">
                {services
                  .filter((service) => {
                    return !service.excludeFromCreate;
                  })
                  .map((service) => {
                    const radio = getRadioProps({ value: service.type });
                    return (
                      <RadioCard key={service.tab} {...radio}>
                        <VStack>
                          <Text as="h1" fontWeight="600" fontSize="medium">
                            {service.type}
                          </Text>
                          <Text
                            fontWeight="400"
                            fontSize="small"
                            textAlign="center"
                          >
                            {service.description}
                          </Text>
                        </VStack>
                      </RadioCard>
                    );
                  })}
              </HStack>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              onClick={onClose}
              ml={3}
              as={NavLink}
              to={selectedService?.link || ""}
            >
              Next
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  return { services, onClick: onOpen, dialog };
};
