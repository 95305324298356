import React, { useCallback } from "react";
import { Button, Flex, Heading } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { JsonEditor } from "src/scenes/admin/components/JsonEditor";
import * as GQL from "src/types/graphql";
import matchRuleSchema from "./matchRuleSchema.json";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_SCHOOL_BY_ID } from "../schools/graphql/queries";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";
import { BinConfig } from "src/types/priorityTemplate";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { useMatchConfiguration } from "src/hooks/useMatchConfiguration";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  CREATE_BIN_CONFIGURATION,
  UPDATE_BIN_CONFIGURATION_BY_PK,
} from "./graphql/mutations";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import {
  GET_BIN_CONFIGURATION_BY_ID,
  GET_MATCH_CONFIGURATION,
} from "./graphql/queries";

const ExampleOutline: BinConfig = {
  name: "[example default]",
  priorityGroups: [
    [
      ["tagA", "tagB"],
      ["tagA", { not: "tagC" }],
    ],
    [["tagD", "tagE"]],
  ],
  sortFields: [
    {
      field: GQL.priority_template_sort_field_enum.PriorityGroup,
      order: GQL.priority_template_sort_order_enum.asc,
    },
    {
      field: GQL.priority_template_sort_field_enum.LotteryNumber,
      order: GQL.priority_template_sort_order_enum.asc,
    },
  ],
};

export function ManageMatchRule() {
  const toast = useAvelaToast();
  const navigate = useNavigate();
  const { glossary } = useGlossary();
  const { schoolId = "", binConfigId = "" } = useParams();
  const organization = useOrganization();
  const { matchConfig } = useMatchConfiguration();
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const [JSONRuleValue, setJSONRuleValue] = React.useState<string>(
    JSON.stringify(ExampleOutline, undefined, 2)
  );

  const { remoteData } = useRemoteDataQuery<
    GQL.GetSchool,
    GQL.GetSchoolVariables
  >(GET_SCHOOL_BY_ID, {
    variables: { id: schoolId },
    skip: !schoolId,
  });

  useRemoteDataQuery<
    GQL.GetBinConfigurationById,
    GQL.GetBinConfigurationByIdVariables
  >(GET_BIN_CONFIGURATION_BY_ID, {
    variables: { binConfigId },
    skip: !binConfigId,
    onCompleted: (data) => {
      if (!data.bin_config_by_pk) return;
      const binConfigJSON: BinConfig = {
        ...data.bin_config_by_pk.parameters,
        name: data.bin_config_by_pk.name ?? "",
      };
      setJSONRuleValue(JSON.stringify(binConfigJSON, undefined, 2));
    },
  });

  const [createBinConfiguration] = useRemoteDataMutation<
    GQL.CreateBinConfiguration,
    GQL.CreateBinConfigurationVariables
  >(CREATE_BIN_CONFIGURATION, {
    refetchQueries: [GET_MATCH_CONFIGURATION],
    awaitRefetchQueries: true,
  });

  const [updateBinConfigurationByPk] = useRemoteDataMutation<
    GQL.UpdateBinConfigurationByPk,
    GQL.UpdateBinConfigurationByPkVariables
  >(UPDATE_BIN_CONFIGURATION_BY_PK, {
    refetchQueries: [GET_MATCH_CONFIGURATION],
    awaitRefetchQueries: true,
  });

  const handleCreateSchoolRules = useCallback(async () => {
    try {
      if (!matchConfig || !selectedEnrollmentPeriod?.id || !schoolId) return;
      const binConfigJSON: BinConfig = JSON.parse(JSONRuleValue);

      const binConfigId = crypto.randomUUID();

      const binConfig: GQL.bin_config_insert_input = {
        id: binConfigId,
        name: binConfigJSON.name,
        match_config_id: matchConfig.id,
        parameters: binConfigJSON,
        school_default_bin_configs: {
          data: [
            {
              school_id: schoolId,
              enrollment_period_id: selectedEnrollmentPeriod.id,
            },
          ],
        },
      };

      await createBinConfiguration({
        variables: {
          binConfig,
          binConfigId,
          enrollmentPeriodId: selectedEnrollmentPeriod?.id,
          schoolId,
        },
      });

      if (!toast.isActive("school-rules-saved")) {
        toast({
          id: "school-rules-saved",
          title: glossary`School rules saved`,
          isClosable: true,
          status: "success",
        });
      }

      navigate(
        organization
          .map((org) => OrgAdmin.Match.configure(org))
          .withDefault("#")
      );
    } catch (err) {
      console.error(err);
      toast({
        title: glossary`Failed to save school rules`,
        isClosable: true,
        status: "error",
      });
    }
  }, [
    JSONRuleValue,
    matchConfig,
    selectedEnrollmentPeriod,
    schoolId,
    createBinConfiguration,
    navigate,
    organization,
    glossary,
    toast,
  ]);

  const handleUpdateSchoolRules = useCallback(async () => {
    const binConfigJSON: BinConfig = JSON.parse(JSONRuleValue);

    const binConfig: GQL.bin_config_set_input = {
      name: binConfigJSON.name,
      parameters: binConfigJSON,
    };

    try {
      await updateBinConfigurationByPk({
        variables: {
          binConfigId,
          binConfig,
        },
      });

      if (!toast.isActive("school-rules-saved")) {
        toast({
          id: "school-rules-saved",
          title: glossary`School rules saved`,
          isClosable: true,
          status: "success",
        });
      }

      navigate(
        organization
          .map((org) => OrgAdmin.Match.configure(org))
          .withDefault("#")
      );
    } catch (err) {
      console.error(err);
      toast({
        title: glossary`Failed to save school rules`,
        isClosable: true,
        status: "error",
      });
    }
  }, [
    binConfigId,
    updateBinConfigurationByPk,
    navigate,
    organization,
    glossary,
    toast,
    JSONRuleValue,
  ]);

  if (!schoolId) return <GenericError />;

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.school_by_pk === null) {
          return <NotFound />;
        }

        return (
          <Flex direction="column" gap={8} height="100%">
            <Heading
              as="h1"
              color="gray.700"
              fontWeight="semibold"
              fontSize="3xl"
            >
              Edit {data.school_by_pk.name} rules
            </Heading>

            <JsonEditor
              text={JSONRuleValue}
              onChange={setJSONRuleValue}
              schema={matchRuleSchema}
              filename={`${data.school_by_pk.name}-match-rules.json`}
              hideToolbar
              width="100%"
              height="100%"
            />
            <AdminFormButtons justifyContent="space-between">
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() =>
                  navigate(
                    organization
                      .map((org) => OrgAdmin.Match.configure(org))
                      .withDefault("#")
                  )
                }
              >
                Cancel
              </Button>
              <Button
                onClick={
                  binConfigId
                    ? handleUpdateSchoolRules
                    : handleCreateSchoolRules
                }
              >
                Save rules
              </Button>
            </AdminFormButtons>
          </Flex>
        );
      }}
    </GQLRemoteDataView>
  );
}
