import { Heading } from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import React from "react";
import { ApolloError } from "src/components/Feedback/ApolloError";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { OrganizationError, useOrganization } from "src/hooks/useOrganization";
import { Home } from "./dashboard";

export function Index() {
  const remoteData = useOrganization();
  const flags = useFlags(["admin-dashboard"]);
  const adminDashboardEnabled = flags["admin-dashboard"]?.enabled;

  const renderError = React.useCallback((error: OrganizationError) => {
    switch (error.kind) {
      case "NotFoundError":
        return <NotFound />;
      case "ServerError":
        return <ApolloError error={error.error} />;
    }
  }, []);

  if (adminDashboardEnabled) {
    return <Home />;
  }

  return (
    <RemoteDataView remoteData={remoteData} error={renderError}>
      {(org) => <Heading>{org.name} Admin Portal</Heading>}
    </RemoteDataView>
  );
}
