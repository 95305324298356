import { Button } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FunctionComponent, useEffect } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { AccountLookupStep, StepProps } from "./Step";
import { FormikAccountLookup } from "./helpers";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

export const EnterNameStep: FunctionComponent<StepProps> = (props) => {
  const { validateForm, setTouched } = useFormikContext<FormikAccountLookup>();
  const { goToNext } = props;

  const { setStepName } = useAccountLookupState();
  useEffect(() => {
    setStepName(EnterNameStep.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire setStepName on component load

  return (
    <AccountLookupStep
      heading="Find your account"
      description="Get started by entering in your full name."
    >
      <InputWithValidationControl
        name="fullName"
        isRequired={true}
        label="Parent or guardian full name"
        inputProps={{ placeholder: "E.g.: John Doe Sr.", autoFocus: true }}
      />
      <Button
        type="submit"
        onClick={async () => {
          setTouched({ fullName: true });
          const errors = await validateForm();

          if (!errors.fullName) {
            goToNext();
          }
        }}
      >
        Next step
      </Button>
    </AccountLookupStep>
  );
};
