import { Flex } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { ReactComponent as LaunchApp } from "src/images/launch-app.svg";
import { ReactComponent as NoFilters } from "src/images/no-filters.svg";
import { ConfigureMatchButton } from "./ConfigureMatchButton";
import { useMatchConfiguration } from "src/hooks/useMatchConfiguration";
import { Loading } from "src/components/Feedback/Loading";

export function EmptyMatchConfig() {
  const { matchConfig, isLoading } = useMatchConfiguration();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Flex minHeight="70vh" justify="center" align="center">
      {!matchConfig ? (
        <EmptyState
          Svg={LaunchApp}
          heading="Configure Match to get started"
          description="To run your first match, we need to know how you’d like it to run"
        >
          <Flex>
            <WithUserPermissions permissions={["match.configuration:create"]}>
              <ConfigureMatchButton variant="outline" colorScheme="gray" />
            </WithUserPermissions>
          </Flex>
        </EmptyState>
      ) : (
        <EmptyState
          Svg={NoFilters}
          heading="No matches run"
          description="To run your first match, navigate to a form to select form responses, then click “Run match”"
        />
      )}
    </Flex>
  );
}
