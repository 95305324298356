import { Flex, HStack, Skeleton, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { GenericError } from "src/components/Feedback/GenericError";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_PARENT_PROCESSED_FORM_STAT } from "./graphql/queries";
import { DateRange, getDateRange } from "./types/dateRange";

type Props = {
  organizationId: string;
  enrollmentPeriodId: string;
  dateRange: DateRange;
};

export function ParentProcessedForm({
  organizationId,
  enrollmentPeriodId,
  dateRange,
}: Props) {
  const { current, current_minus_range, current_minus_2_range } = useMemo(
    () => getDateRange(dateRange),
    [dateRange]
  );

  const { remoteData } = useRemoteDataQuery<
    GQL.GetParentProcessedFormStat,
    GQL.GetParentProcessedFormStatVariables
  >(GET_PARENT_PROCESSED_FORM_STAT, {
    variables: {
      organization_id: organizationId,
      enrollment_period_id: enrollmentPeriodId,
      previous_begin_at: current_minus_2_range,
      previous_end_at: current_minus_range,
      current_begin_at: current_minus_range,
      current_end_at: current,
    },
    skip: !enrollmentPeriodId || !organizationId,
    fetchPolicy: "cache-first",
  });

  return (
    <Flex
      direction="column"
      gap={4}
      border="solid 1px"
      bgColor="gray.50"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      w="full"
      minW="17rem"
    >
      <Text fontSize="md" color="gray.800" fontWeight="500">
        New responses
      </Text>
      {remoteData.isLoading() ? (
        <Skeleton h="3rem" w="full" />
      ) : (
        <GQLRemoteDataView
          remoteData={remoteData}
          error={(error) => <GenericError error={error} />}
        >
          {({ current_period, previous_period }) => {
            const currentPeriodCount = current_period.aggregate?.count || 0;
            const previousPeriodCount = previous_period.aggregate?.count || 0;

            const changePercentage =
              previousPeriodCount > 0
                ? ((currentPeriodCount - previousPeriodCount) /
                    previousPeriodCount) *
                  100
                : undefined;

            const isPositiveChange = changePercentage && changePercentage > 0;
            const changePercentageText = isPositiveChange ? "+" : "";

            return (
              <Flex
                direction="row"
                gap={4}
                justify="space-between"
                align="center"
              >
                <HStack gap={2}>
                  <Text fontSize="2xl" color="gray.800" fontWeight="600">
                    {currentPeriodCount !== undefined
                      ? currentPeriodCount
                      : "No data"}
                  </Text>

                  <Text
                    fontSize="sm"
                    bgColor={isPositiveChange ? "green.200" : "orange.100"}
                    borderRadius="md"
                    pl={2}
                    pr={2}
                  >
                    {changePercentage && !isNaN(changePercentage)
                      ? `${changePercentageText} ${changePercentage}%`
                      : "No data"}
                  </Text>
                </HStack>
                <RiArrowRightLine style={{ width: 14, height: 14 }} />
              </Flex>
            );
          }}
        </GQLRemoteDataView>
      )}
    </Flex>
  );
}
