import { useCallback } from "react";
import { GET_DOCUMENT_LIBRARY_FOR_USER } from "./graphql/queries";
import { useDocumentStorage } from "./useDocumentStorage";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { useRemoteDataMutation } from "./useRemoteDataMutation";
import { INSERT_FORM_ANSWER } from "src/components/graphql/mutations";

export type ExistingDocument = {
  documentId: uuid;
  filename: string;
  createdAt: string;
  lastUsed: {
    at: string;
    question: {
      id: string;
      text: string;
    } | null;
  } | null;
};

export function useDocumentLibrary(props: {
  userId: string;
  formAnswerId?: string;
  formId: string;
  questionId: string;
}) {
  const { userId, formAnswerId, formId, questionId } = props;
  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.GetDocumentLibraryForUser,
    GQL.GetDocumentLibraryForUserVariables
  >(GET_DOCUMENT_LIBRARY_FOR_USER, {
    variables: { userId },
  });

  const { getDownloadUrl, getUploadUrl, uploadFile } = useDocumentStorage();

  const [insertFormAnswer] = useRemoteDataMutation<
    GQL.InsertFormAnswerOne,
    GQL.InsertFormAnswerOneVariables
  >(INSERT_FORM_ANSWER);

  const reuploadDocument = useCallback(
    async (document: ExistingDocument) => {
      const downloadUrl = await getDownloadUrl(document.documentId);
      if (!downloadUrl) {
        throw new Error("Unable to generate download URL");
      }

      const response = await fetch(downloadUrl);
      const contentType = response.headers.get("Content-Type");
      if (!contentType) {
        throw new Error("Unknown content type");
      }

      const blob = await response.blob();
      const file = new File([blob], document.filename, {
        type: contentType ?? undefined,
      });

      let form_answer_id = formAnswerId;
      if (!form_answer_id) {
        // This question doesn't have answer yet.
        // Before uploading the file, we first need to create a form answer
        const resp = await insertFormAnswer({
          variables: {
            form_answer: {
              form_id: formId,
              question_id: questionId,
            },
          },
        });

        form_answer_id = resp.data?.insert_form_answer_one?.id;
        if (!form_answer_id) {
          throw new Error("Unable to create new form answer");
        }
      }

      const uploadUrl = await getUploadUrl({
        filename: document.filename,
        document_content_type: contentType,
        document_type: "General",
        form_answer_id,
      });
      if (!uploadUrl) {
        throw new Error("Unable to generate upload URL");
      }

      await uploadFile(file, uploadUrl);
      return {
        file,
        fileId: uploadUrl.document_id,
        formAnswerId: form_answer_id,
      };
    },
    [
      formAnswerId,
      formId,
      getDownloadUrl,
      getUploadUrl,
      insertFormAnswer,
      questionId,
      uploadFile,
    ]
  );

  return {
    reuploadDocument,
    refetchExistingDocuments: refetch,
    existingDocuments: remoteData.map((data) => {
      return data.document_metadata.map<ExistingDocument>((document) => {
        return {
          documentId: document.document_id,
          filename: document.filename,
          createdAt: document.created_at,
          lastUsed: document.form_answer && {
            at: document.form_answer.created_at,
            // Note: `form_question` can be null if the question is admin-only.
            question: document.form_answer.form_question && {
              id: document.form_answer.form_question.question_id,
              text: document.form_answer.form_question.question.question,
            },
          },
        };
      });
    }),
  };
}
