import { HStack, Link, Text, VStack } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { FORM_STATUS, RelationshipPerson } from "src/constants";
import { OrganizationError } from "src/hooks/useOrganization";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { formatIsoDateToOrgDate } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { RelationshipTableColumnType as ColumnType } from "../RelationshipsTable";

// For elevated school admins, we need to filter the forms they are allowed to see
export const getFilterFormsFunction = (
  isElevatedSchoolAdmin: boolean,
  applyingSchoolIds: string[],
  attendingSchoolIds: string[]
) => {
  return (student: RelationshipPerson) => {
    // If not elevated school admin, show all forms
    if (!isElevatedSchoolAdmin) return student.latest_forms;

    // If elevated school admin with matching attending school, show all forms
    const hasMatchingAttendingSchool =
      student.applicant_attending_schools?.some(
        (attendance) =>
          attendance.school_id &&
          attendingSchoolIds.includes(attendance.school_id)
      );
    if (hasMatchingAttendingSchool) {
      return student.latest_forms;
    }

    // Otherwise, show forms only from applying school
    return student.latest_forms?.filter((form) =>
      form.form_school_ranks?.some((rank) =>
        applyingSchoolIds.includes(rank.school_id)
      )
    );
  };
};

const LatestFormCell = ({
  person,
  organization,
}: {
  person: ColumnType["person"];
  organization: RemoteData<OrganizationError, GQL.Organization>;
}) => {
  const [searchParams] = useSearchParams();
  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();
  const filterForms = getFilterFormsFunction(
    isElevatedSchoolAdmin,
    applyingSchoolIds,
    attendingSchoolIds
  );

  const forms = filterForms(person);
  if (!forms || forms.length === 0) {
    return <Text variant="placeholder">No forms</Text>;
  }

  const latestForm = forms[0]!;
  const url = Url.OrgAdmin.Forms.view({
    organization,
    formTemplateId: latestForm.form_template.id,
    id: latestForm.id,
    params: searchParams.toString(),
  });

  return (
    <VStack align="left">
      <HStack>
        <Link
          as={RouterLink}
          color="primary.500"
          to={url}
          textDecoration="underline"
        >
          {latestForm.form_template.enrollment_period.name} -{" "}
          {latestForm.form_template.name}
        </Link>
      </HStack>
      <HStack>
        <StatusTag status={FORM_STATUS[latestForm.status]} />
        <Text fontSize="xs">
          {formatIsoDateToOrgDate(
            latestForm.status_updated_at || "",
            organization
          )}
        </Text>
      </HStack>
    </VStack>
  );
};

type FormInfoColumnDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
};

export function buildFormInfoColumnDef<T extends ColumnType>({
  organization,
}: FormInfoColumnDefConfig): ColumnDef<T> {
  return {
    id: "latest_form",
    header: "Latest Form",
    enableSorting: false,
    cell: ({ row }) => (
      <LatestFormCell
        person={row.original.person}
        organization={organization}
      />
    ),
  };
}
