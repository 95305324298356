import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import { FormSchool } from "../types";
import * as RD from "src/types/remoteData";
import { Organization } from "src/types/graphql";
import { FormSchoolId } from "src/hooks/useMatchService";
import { useMatchService } from "src/hooks/useMatchService";
import { partition } from "lodash";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { formatIsoDateToOrg } from "src/services/format";
import { useAvelaToast } from "src/hooks/useAvelaToast";

const MAX_NAME_LENGTH = 120;

type RunMatchDialogProps = {
  formTemplateId: string;
  schoolForms: FormSchool[];
  isOpen: boolean;
  onCancel: () => void;
  matchName: string;
  onMatchNameChange: (value: string) => void;
};

export const RunMatchDialog = ({
  formTemplateId,
  schoolForms,
  isOpen,
  onCancel,
  matchName,
  onMatchNameChange,
}: RunMatchDialogProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const organizationRD = useOrganization();
  const hasReachedLimit = matchName.length > MAX_NAME_LENGTH;
  const isError = hasReachedLimit;
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const [isStarting, setIsStarting] = useState(false);
  const toast = useAvelaToast();

  const [organization, setOrganization] = React.useState<Organization>();

  const { startMatch } = useMatchService();
  const [formsWithSchools, formsWithoutSchools] = useMemo(
    () =>
      partition(
        schoolForms.map((sf) => ({
          formId: sf.form?.id,
          schoolId: sf.school?.id,
        })),
        (sf): sf is FormSchoolId => !!(sf.formId && sf.schoolId)
      ),
    [schoolForms]
  );

  React.useEffect(() => {
    if (organizationRD.kind === RD.RemoteDataKind.Success)
      setOrganization(organizationRD.data);
  }, [organizationRD]);

  const handleSubmit = React.useCallback(async () => {
    if (!organization || !selectedEnrollmentPeriod?.id) return;
    try {
      setIsStarting(true);

      const timestamp = formatIsoDateToOrg(
        new Date().toISOString(),
        organizationRD,
        "MM/dd/yyyy h:mm:ssaaa"
      );
      const tagLabel = `Match ${matchName} ${timestamp}`;
      const matchRun = await startMatch({
        matchName,
        formSchools: formsWithSchools,
        formsWithoutSchools: formsWithoutSchools
          .map((form) => form.formId)
          .filter((id): id is string => typeof id === "string"),
        tagLabel,
        enrollmentPeriodId: selectedEnrollmentPeriod.id,
        formTemplateId,
      });

      navigate(Url.OrgAdmin.Match.progress(organization, matchRun.matchId));
    } catch (error) {
      console.error("Failed to start match:", error);
      toast({
        title: "Failed to start match",
        description: "There was an error starting the match. Please try again.",
        status: "error",
      });
      setIsStarting(false);
    }
  }, [
    organization,
    selectedEnrollmentPeriod?.id,
    organizationRD,
    startMatch,
    matchName,
    formsWithSchools,
    formsWithoutSchools,
    navigate,
    toast,
    formTemplateId,
  ]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">Run a match</AlertDialogHeader>
          <AlertDialogBody>
            <VStack spacing={4} align="stretch">
              <Text>
                A match will be run on {schoolForms.length} entries using the
                match configuration for this enrollment period.
              </Text>
              <FormControl isInvalid={isError}>
                <FormLabel>Match name</FormLabel>
                <Input
                  placeholder="Match name"
                  value={matchName}
                  onChange={(e) => onMatchNameChange(e.target.value)}
                  isDisabled={isStarting}
                />
                {!hasReachedLimit && (
                  <FormHelperText>
                    {MAX_NAME_LENGTH - matchName.length}
                  </FormHelperText>
                )}
                {hasReachedLimit && (
                  <FormErrorMessage>
                    Limit reached -{matchName.length - MAX_NAME_LENGTH}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="ghost"
              colorScheme="gray"
              ref={cancelRef}
              onClick={onCancel}
              isDisabled={isStarting}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              ml={3}
              isDisabled={!matchName || hasReachedLimit || isStarting}
              isLoading={isStarting}
            >
              Run match
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
