import { Flex, Image } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import { usePreferredLanguageBrowserOnly } from "src/hooks/usePreferredLanguage";
import { WeglotSelect } from "src/plugins/weglot";
import { Footer } from "./Footer";
import { AccountLookupForm } from "./Form";
import { CARD_PROPS } from "./steps/Step";
import { useBranding } from "src/components/Providers/BrandingProvider";
import { ContextProvider as AccountLookupContextProvider } from "./Context";

/**
 * Based on /src/auth/AuthLayout.tsx.
 */
export const AccountLookup: FunctionComponent<{}> = () => {
  const branding = useBranding();
  const configRD = useOrgConfig("AccountLookup");
  const preferredLanguageSetting = usePreferredLanguageBrowserOnly();

  return (
    <RemoteDataView
      remoteData={configRD}
      loading={<Loading />}
      error={() => <GenericError />}
    >
      {(config) => {
        if (config.disabled) {
          return <NotFound />;
        }

        return (
          <Flex alignItems="center" direction="column" padding={4} gap={8}>
            <Flex alignSelf="flex-end">
              <WeglotSelect
                colorScheme="gray"
                variant="link"
                preferredLanguageSetting={preferredLanguageSetting}
              />
            </Flex>
            <Image
              paddingX={6}
              width={CARD_PROPS.width}
              maxWidth={CARD_PROPS.maxWidth}
              src={branding.logos.colorSvg}
              alt={`${branding.name} logo`}
            />
            <AccountLookupContextProvider>
              <AccountLookupForm config={config} />
            </AccountLookupContextProvider>
            <Footer config={config} />
          </Flex>
        );
      }}
    </RemoteDataView>
  );
};
