import { gql } from "@apollo/client";

export const UPSERT_MATCH_CONFIGURATION = gql`
  mutation UpsertMatchConfiguration($matchConfig: match_config_insert_input!) {
    insert_match_config_one(
      object: $matchConfig
      on_conflict: {
        update_columns: [parameters]
        constraint: match_config_pkey
      }
    ) {
      id
    }
  }
`;

export const CREATE_BIN_CONFIGURATION = gql`
  mutation CreateBinConfiguration(
    $binConfigId: uuid!
    $binConfig: bin_config_insert_input!
    $enrollmentPeriodId: uuid!
    $schoolId: uuid!
  ) {
    insert_bin_config_one(object: $binConfig) {
      id
    }
    update_bin_many(
      updates: {
        where: {
          enrollment_period_id: { _eq: $enrollmentPeriodId }
          grades: { school_id: { _eq: $schoolId } }
        }
        _set: { bin_config_id: $binConfigId }
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_BIN_CONFIGURATION_BY_PK = gql`
  mutation UpdateBinConfigurationByPk(
    $binConfigId: uuid!
    $binConfig: bin_config_set_input!
  ) {
    update_bin_config_by_pk(
      pk_columns: { id: $binConfigId }
      _set: $binConfig
    ) {
      id
    }
  }
`;
