import { Flex, Skeleton } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { Table } from "src/components/Table/Table";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { FeedItem } from "./components/FeedItem";
import { GET_FEED_DATA } from "./graphql/queries";
import { FeedEvent, resolveFeedTransaction } from "./helpers";
import { DateRange, getDateRange } from "./types/dateRange";

type Props = {
  organizationId: string;
  enrollmentPeriodId: string;
  dateRange: DateRange;
};

export function RecentActivityFeed({
  organizationId,
  enrollmentPeriodId,
  dateRange,
}: Props) {
  const { current, current_minus_range } = useMemo(
    () => getDateRange(dateRange),
    [dateRange]
  );

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFeedData,
    GQL.GetFeedDataVariables
  >(GET_FEED_DATA, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      organization_id: organizationId,
      begin_at: current_minus_range,
      end_at: current,
    },
    skip: !enrollmentPeriodId || !organizationId,
  });

  const columns: ColumnDef<FeedEvent<string>>[] = useMemo(
    () => [
      {
        id: "entry",
        cell: (props) => <FeedItem event={props.row.original} />,
      },
    ],
    []
  );

  return (
    <>
      {remoteData.isLoading() ? (
        <Flex direction="column" gap="4" alignItems="center" mt={2}>
          <Skeleton height="2rem" width="100%" borderRadius="md" />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={0.9} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.1} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.2} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.3} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.4} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.5} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.6} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.7} />
        </Flex>
      ) : (
        <RemoteDataView remoteData={remoteData} error={() => <GenericError />}>
          {({
            audit_form_transaction,
            school,
            grade,
            enrollment_period_tag,
          }) => (
            <Flex h={475}>
              <Table<FeedEvent<string>>
                data={audit_form_transaction
                  .flatMap((transaction) =>
                    resolveFeedTransaction(
                      transaction,
                      school,
                      grade,
                      enrollment_period_tag
                    )
                  )
                  .filter(isNotNull)}
                columns={columns}
                hideHeader={true}
                tableHeader={() => <></>}
                isLoading={remoteData.isLoading()}
              />
            </Flex>
          )}
        </RemoteDataView>
      )}
    </>
  );
}
