import { Button, Icon } from "@chakra-ui/react";
import { RiUploadLine } from "react-icons/ri";
import { BulkUploadInput } from "src/components/Inputs/BulkUploadInput";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { UPDATE_CAPACITIES } from "./graphql/mutations";
import { GET_SCHOOL_GRADE_CAPACITY } from "./graphql/queries";

const Capacity = z.object({
  ID: z.string(),
  "Total Seats": z.string(),
});

type CapacityRow = z.infer<typeof Capacity>;

function isValid(data: unknown): data is CapacityRow[] {
  return (
    Array.isArray(data) && data.filter((row) => !isValidRow(row)).length === 0
  );
}

function isValidRow(data: unknown): data is CapacityRow {
  const { success } = Capacity.safeParse(data);
  return success;
}

export const UploadCapacitiesButton = () => {
  const organization = useOrganization();
  const toast = useAvelaToast();
  const [updateCapacities] = useRemoteDataMutation<
    GQL.UpdateCapacities,
    GQL.UpdateCapacitiesVariables
  >(UPDATE_CAPACITIES);

  const parseData = (data: unknown[]): CapacityRow[] => {
    const parsedCapacities: (CapacityRow | null)[] = data.map((row) => {
      const capacity = Capacity.parse(row);
      if (capacity) {
        return {
          ...capacity,
          organization_id: organization.map((d) => d.id).toNullable() as string,
        };
      } else {
        return null;
      }
    });

    const capacities: CapacityRow[] = parsedCapacities.filter(isNotNull);

    return capacities;
  };

  const handleUpload = async (data: unknown[]) => {
    let capacities;
    try {
      capacities = parseData(data).map((row) => {
        const capacity = parseInt(row["Total Seats"]);

        return {
          gradeUpdate: {
            where: {
              id: { _eq: row["ID"] },
            },
            _set: { capacity },
          },
          binUpdate: {
            where: {
              grades: {
                id: { _eq: row["ID"] },
              },
            },
            _set: { capacity },
          },
        };
      });
    } catch (e) {
      const description = "We were unable to parse the csv.";
      toast.error({
        title: "Something went wrong!",
        description: description,
      });
      console.error(description, e);
      return;
    }

    if (!(capacities && capacities.length > 0)) {
      return;
    }

    const loadingToastId = toast({
      title: `Working hard`,
      description: `Importing capacities...`,
      status: "loading",
    });

    try {
      await updateCapacities({
        variables: {
          gradeUpdates: capacities.map((c) => c.gradeUpdate),
          binUpdates: capacities.map((c) => c.binUpdate),
        },
        refetchQueries: [GET_SCHOOL_GRADE_CAPACITY],
      });
      toast.close(loadingToastId);
      toast({
        title: `Capacities imported`,
        status: "success",
      });
    } catch (e: any) {
      toast.close(loadingToastId);
      toast.error({
        title: "Something went wrong!",
        description: "Check your network and try again",
      });
      console.error(e);
    }
  };

  return (
    <BulkUploadInput onUpload={handleUpload} isValid={isValid}>
      <Button leftIcon={<Icon as={RiUploadLine} />} size="md" variant="outline">
        Import
      </Button>
    </BulkUploadInput>
  );
};
