export const DATE_RANGE_MAP = {
  "24h": 24 * 60 * 60 * 1000,
  "7d": 7 * 24 * 60 * 60 * 1000,
  "30d": 30 * 24 * 60 * 60 * 1000,
};

export type DateRange = keyof typeof DATE_RANGE_MAP;

export const DEFAULT_DATE_RANGE = "24h";

export function getDateRange(dateRange: DateRange) {
  const range = DATE_RANGE_MAP[dateRange];
  const current = new Date();
  const current_minus_range = new Date(current.getTime() - range);
  const current_minus_2_range = new Date(current.getTime() - 2 * range);

  return {
    current: current.toISOString(),
    current_minus_range: current_minus_range.toISOString(),
    current_minus_2_range: current_minus_2_range.toISOString(),
  };
}
