import { $getRoot, $insertNodes, LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $convertToMarkdownString } from "@lexical/markdown";
import { LEXICAL_TRANSFORMERS } from "../plugins/MarkdownTransformers";

// Function to convert Lexical EditorState to plain text
export const editorStateToPlainText = (editor: LexicalEditor) => {
  let plainText = "";

  editor.getEditorState().read(() => {
    // Get the root node and extract its text content
    const root = $getRoot();
    plainText = root ? root.getTextContent() : "";
  });

  return plainText;
};

// Function to convert Lexical EditorState to HTML
export const editorStateToHtml = (editor: LexicalEditor) => {
  let html = "";
  editor.getEditorState().read(() => {
    html = $generateHtmlFromNodes(editor, null);
  });
  return html;
};

export const importHtml = (editor: LexicalEditor, html: string) => {
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, "text/html");

    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);

    // Select the root
    $getRoot().clear();

    // Insert them at a selection.
    $insertNodes(nodes);
  });
};

/**
 * Converts plain text to HTML by:
 * 1. Escaping special HTML characters (&, <, >, ", ') to prevent XSS
 * 2. Converting newlines to <br> tags
 * 3. Wrapping the entire text in a paragraph tag
 *
 * @param plainText - The plain text string to convert to HTML
 * @returns An HTML string with escaped characters and proper formatting
 *
 * @example
 * const html = convertPlainTextToHtml("Hello & goodbye\nNew line");
 * // Returns: "<p>Hello &amp; goodbye<br>New line</p>"
 */
export const convertPlainTextToHtml = (plainText: string) => {
  const sanitized = plainText
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/\n/g, "<br>");
  return `<p>${sanitized}</p>`;
};

interface Attachment {
  data: string;
  cid: string;
  mimeType: string;
  filename: string;
}

interface HtmlWithAttachments {
  html: string;
  attachments: Attachment[];
}

const replaceInlineImagesWithCID = (html: string): HtmlWithAttachments => {
  // Regex to match <img> tags with inline data URIs in the src attribute
  const imgRegex =
    /<img([^>]*?)src=["'](data:([^;]+);base64,([^"']+))["']([^>]*?)>/gi;

  // Array to store the metadata for attachments
  const attachments: Attachment[] = [];

  // Replace all matches in the HTML
  const updatedHtml = html.replaceAll(
    imgRegex,
    (match, beforeSrc, dataUri, mimeType, base64Data, afterSrc) => {
      // Generate a unique CID
      const cid = `image_${attachments.length + 1}`;

      // Add the attachment metadata
      attachments.push({
        data: base64Data,
        cid,
        mimeType,
        filename: `image-${attachments.length + 1}.${mimeType.split("/")[1]}`,
      });

      // Return the new <img> tag with the CID in the src attribute
      return `<img${beforeSrc}src="cid:${cid}"${afterSrc}>`;
    }
  );

  return { html: updatedHtml, attachments };
};

export const editorStateToHtmlWithCIDs = (
  editor: LexicalEditor
): HtmlWithAttachments | null => {
  const html = editorStateToHtml(editor);
  return replaceInlineImagesWithCID(html);
};

// Function to convert Lexical EditorState to Markdown
export const editorStateToMarkdown = (editor: LexicalEditor) => {
  let markdown = "";
  editor.getEditorState().read(() => {
    markdown = $convertToMarkdownString(LEXICAL_TRANSFORMERS, undefined, true);
  });
  return markdown;
};
