import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import React from "react";
import { GET_ENROLLMENT_PERIODS } from "src/scenes/orgAdmin/enrollmentPeriods/graphql/queries";
import { SelectControl } from "formik-chakra-ui";
import { useField } from "formik";

type EnrollmentPeriod = GQL.GetEnrollments_enrollment_period;

interface EnrollmentPeriodSelectInputProps {
  name: string;
  organizationId: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
  onSelectedEnrollmentPeriod?: (period: EnrollmentPeriod | null) => void;
}

export function EnrollmentPeriodSelectInput({
  name,
  organizationId,
  label,
  placeholder,
  isRequired = false,
  isDisabled = false,
  isVisible = true,
  onSelectedEnrollmentPeriod,
}: EnrollmentPeriodSelectInputProps) {
  const [hasUserSelected, setHasUserSelected] = React.useState(false);
  const [, { initialValue }, helper] = useField(name);
  const { remoteData } = useRemoteDataQuery<
    GQL.GetEnrollments,
    GQL.GetEnrollmentsVariables
  >(GET_ENROLLMENT_PERIODS, {
    variables: {
      organization_id: organizationId,
      order_by: { created_at: GQL.order_by.asc },
    },
    skip: !organizationId,
    fetchPolicy: "network-only",
  });

  const periods = React.useMemo(
    () =>
      !remoteData.hasError() && remoteData.hasData()
        ? remoteData.data.enrollment_period
        : [],
    [remoteData]
  );

  React.useEffect(() => {
    if (remoteData.hasError()) {
      helper.setError("Error loading enrollment periods");
      onSelectedEnrollmentPeriod?.(null);
    }
  }, [remoteData, helper, onSelectedEnrollmentPeriod]);

  // Notify parent about pre-selected period when data loads
  React.useEffect(() => {
    if (!hasUserSelected && periods.length > 0) {
      const preSelectedPeriod = periods.find(
        (period) => period.id === initialValue
      );
      if (preSelectedPeriod) {
        onSelectedEnrollmentPeriod?.(preSelectedPeriod);
      }
    }
  }, [periods, hasUserSelected, initialValue, onSelectedEnrollmentPeriod]);

  const handleChange = (value: string) => {
    setHasUserSelected(true);
    const selectedPeriod =
      periods.find((period) => period.id === value) || null;
    helper.setTouched(true, false); // no validate until setValue()
    helper.setValue(selectedPeriod?.id ?? "", true);
    onSelectedEnrollmentPeriod?.(selectedPeriod);
  };

  if (!isVisible) return null;

  return (
    <SelectControl
      name={name}
      label={label}
      isRequired={isRequired}
      isDisabled={isDisabled}
      selectProps={{
        placeholder,
        onChange: (event) => {
          handleChange(event.target.value);
        },
      }}
    >
      {periods.map((period) => (
        <option key={period.id} value={period.id}>
          {period.name}
        </option>
      ))}
    </SelectControl>
  );
}
