import {
  Button,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { ExitPublicViewButton } from "./ExitPublicViewButton";
import { MatchResult } from "../PublicView";
import { useCallback } from "react";

interface JumpToResultsButtonProps {
  id: string;
  results: (MatchResult & { index: number })[];
  onClick: (index: number) => void;
}

export const JumpToResultsButton = ({
  id,
  results,
  onClick,
}: JumpToResultsButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleClick = useCallback(
    (index: number) => {
      onClose();
      onClick(index);
    },
    [onClick, onClose]
  );

  return (
    <>
      <Button aria-label="Exit public view" onClick={onOpen} colorScheme="gray">
        Jump to other results
      </Button>
      <JumpToResultsDialog
        isOpen={isOpen}
        onClose={onClose}
        id={id}
        results={results}
        onClick={handleClick}
      />
    </>
  );
};

interface JumpToResultsDialogProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  results: (MatchResult & { index: number })[];
  onClick: (index: number) => void;
}

export const JumpToResultsDialog: React.FC<JumpToResultsDialogProps> = ({
  id,
  isOpen,
  onClose,
  results,
  onClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered={true}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxH="60vh" minH="20rem">
        <ModalHeader>Jump to results</ModalHeader>
        <ModalBody as={Flex} direction="column" gap={2} overflowY="auto">
          {results.map((result) => (
            <Button
              key={result.schoolId}
              colorScheme="gray"
              variant="outline"
              p={2}
              onClick={() => onClick(result.index)}
            >
              <Flex align="center" gap={1}>
                <Text>{result.schoolName} - </Text>
                <Text fontSize="sm" fontWeight={400}>
                  Grade {result.grade}
                </Text>
                <UnorderedList>
                  <ListItem fontSize="sm" fontWeight={400}>
                    {result.placement}
                  </ListItem>
                </UnorderedList>
              </Flex>
            </Button>
          ))}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <ExitPublicViewButton id={id} colorScheme="primary" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
