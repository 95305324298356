import { gql } from "@apollo/client";
import axios, { HttpStatusCode } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useOrganization } from "src/hooks/useOrganization";
import * as Env from "src/services/env";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import useAccessToken from "./useAccessToken";
import { exponentialBackoff, useDynamicPolling } from "./useDynamicPolling";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import useUser from "./useUser";

const POLL_INTERVAL = exponentialBackoff(1_000, 1.5, 5_000);

const CHECK_MATCH_RUN_STATUS = gql`
  query CheckMatchRunStatus($match_id: uuid!) {
    match_run_by_pk(id: $match_id) {
      status
      results_document_id
      name
      status_message
      total_form_count
      loaded_form_count
      matched_form_count
    }
  }
`;

export interface FormSchoolId {
  formId: string;
  schoolId: string;
}

export interface StartMatchInput {
  matchName?: string;
  formSchools: FormSchoolId[];
  formsWithoutSchools: uuid[];
  tagLabel: string;
  enrollmentPeriodId: uuid;
  formTemplateId?: uuid;
}

const StartMatchResponseSchema = z.object({
  matchId: z.string(),
});

export function useMatchService() {
  const matchUrl = Env.read().REACT_APP_MATCH_API_URL;
  const accessToken = useAccessToken();
  const user = useUser();
  const organization = useOrganization();
  const [matchRunError, setMatchRunError] = useState<string | null>(null);

  const organizationId = organization.map((o) => o.id).withDefault("");

  const startMatch = useCallback(
    ({
      matchName,
      formSchools,
      formsWithoutSchools,
      tagLabel,
      enrollmentPeriodId,
      formTemplateId,
    }: StartMatchInput) => {
      if (
        !matchUrl ||
        accessToken.status !== Status.OK ||
        user.status !== Status.OK
      ) {
        console.error("Match service unavailable:", {
          matchUrl,
          accessTokenStatus: accessToken.status,
          userStatus: user.status,
        });
        setMatchRunError(
          `Match service unavailable: access token status ${accessToken.status} user status ${user.status}`
        );
        throw new Error("Unable to access Match service.");
      }

      return axios
        .post(
          `${matchUrl}/start-match`,
          {
            matchName,
            formSchools,
            formsWithoutSchools,
            tagLabel,
            enrollmentPeriodId,
            organizationId,
            formTemplateId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
              "x-hasura-role": user.data.role,
            },
          }
        )
        .then((response) => {
          if (response.status !== HttpStatusCode.Accepted) {
            setMatchRunError(
              `Internal Match error: ${response.data.error} ${response.data.message}`
            );
            throw new Error(`Internal Match error: ${response.data.error}`);
          }
          return StartMatchResponseSchema.parse(response.data);
        })
        .catch((error) => {
          setMatchRunError(
            `Match service error: ${error.message} ${error.response?.data?.error} ${error.response?.data?.message}`
          );
          throw error;
        });
    },
    [matchUrl, accessToken, user, organizationId]
  );

  return {
    startMatch,
    matchRunError,
  };
}

export function useMatchResults(matchId?: string) {
  const [matchRunError, setMatchRunError] = useState<string | null>(null);

  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.CheckMatchRunStatus,
    GQL.CheckMatchRunStatusVariables
  >(CHECK_MATCH_RUN_STATUS, {
    skip: !matchId,
    variables: {
      match_id: matchId ?? "",
    },
  });

  const { startPolling, stopPolling, resetPollInterval } = useDynamicPolling(
    refetch,
    POLL_INTERVAL
  );

  // When there's new data available, reset the poll interval, or stop polling
  // if it's in a terminal state.
  useEffect(() => {
    if (remoteData.isLoading() || !remoteData.hasData()) return;
    const status = remoteData.data.match_run_by_pk?.status;
    if (status && isTerminalState(status)) {
      stopPolling();

      if (status === GQL.match_run_status_enum.Error) {
        setMatchRunError(
          `Match service error: ${remoteData.data.match_run_by_pk?.status_message}  `
        );
      }
    } else {
      resetPollInterval();
    }
  }, [remoteData, stopPolling, resetPollInterval]);

  const matchProgress = useMemo(() => {
    if (!remoteData.hasData() || !remoteData.data.match_run_by_pk) return null;
    const { total_form_count, loaded_form_count, matched_form_count } =
      remoteData.data.match_run_by_pk;
    return (loaded_form_count + matched_form_count) / (2 * total_form_count);
  }, [remoteData]);

  return {
    matchRunResults: remoteData,
    startPolling,
    cancelMatch: stopPolling,
    /** A value between 0 and 1. */
    matchProgress,
    matchRunError,
  };
}

export function isTerminalState(status: GQL.match_run_status_enum) {
  switch (status) {
    case GQL.match_run_status_enum.Completed:
    case GQL.match_run_status_enum.Canceled:
    case GQL.match_run_status_enum.Error:
      return true;
  }
  return false;
}
