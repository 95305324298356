import { Outlet, Route } from "react-router-dom";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import * as Url from "src/services/url";
// import { RequireConfig } from "src/components/Permissions/RequireConfig";
import { Configure } from "./Configure";
import { Index } from ".";
import { MatchProgress } from "./MatchProgress";
import { Details } from "./Details";
import { ManageMatchRule } from "./ManageMatchRule";
import { RequireConfig } from "src/components/Permissions/RequireConfig";
import { WithFeatureFlags } from "src/components/Permissions/WithFeatureFlags";
import { PublicView } from "./PublicView";

export const MatchRoutes = (
  <Route
    element={
      <RequireConfig configType="Match">
        <WithFeatureFlags featureFlags={["match-v2"]}>
          <Outlet />
        </WithFeatureFlags>
      </RequireConfig>
    }
  >
    <Route
      path={Url.OrgAdmin.Match.indexPath}
      element={
        <RequireUserPermissions permission="match:read">
          <Index />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Url.OrgAdmin.Match.configurePath}
      element={
        <RequireUserPermissions permission="match.configuration:read">
          <Configure />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Url.OrgAdmin.Match.progressPath}
      element={
        <RequireUserPermissions permission="match:read">
          <MatchProgress />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Url.OrgAdmin.Match.detailsPath}
      element={
        <RequireUserPermissions permission="match:read">
          <Details />
        </RequireUserPermissions>
      }
    />

    <Route
      path={Url.OrgAdmin.Match.rulesEditPath}
      element={
        <RequireUserPermissions permission="match.rules:update">
          <ManageMatchRule />
        </RequireUserPermissions>
      }
    />

    <Route
      path={Url.OrgAdmin.Match.rulesNewPath}
      element={
        <RequireUserPermissions permission="match.rules:create">
          <ManageMatchRule />
        </RequireUserPermissions>
      }
    />

    <Route
      path={Url.OrgAdmin.Match.publicViewPath}
      element={
        <RequireUserPermissions permission="match:read">
          <PublicView />
        </RequireUserPermissions>
      }
    />
  </Route>
);
