import { Box } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

type Props = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  minLeftWidth?: number; // in percentage
  minRightWidth?: number; // in percentage
  autosaveId?: string;
};

const LOCAL_STORAGE_KEY = "resizableFilterPanelLeftWidth";

export const ResizableFilterPanel = ({
  leftContent,
  rightContent,
  autosaveId = LOCAL_STORAGE_KEY,
  minLeftWidth = 20,
  minRightWidth = 20,
}: Props) => {
  const [leftWidth, setLeftWidth] = React.useState(() => {
    const savedWidth = localStorage.getItem(autosaveId);
    return savedWidth ? parseFloat(savedWidth) : 70;
  }); // in percentage

  useEffect(() => {
    localStorage.setItem(autosaveId, leftWidth.toString());
  }, [autosaveId, leftWidth]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsResizing(true);
    e.preventDefault();

    const startX = e.clientX;
    const startWidthLeft = (e.currentTarget.previousSibling as HTMLElement)
      ?.offsetWidth;
    const startWidthRight = (e.currentTarget.nextSibling as HTMLElement)
      ?.offsetWidth;
    const containerWidth = e.currentTarget.parentElement?.offsetWidth;

    if (
      containerWidth === undefined ||
      startWidthLeft === undefined ||
      startWidthRight === undefined
    ) {
      return;
    }

    const handleMouseMove = (event: MouseEvent) => {
      const deltaX = event.clientX - startX;
      const newLeftWidth = Math.max(
        ((startWidthLeft + deltaX) / containerWidth) * 100,
        minLeftWidth
      );
      const newRightWidth = Math.max(
        ((startWidthRight - deltaX) / containerWidth) * 100,
        minRightWidth
      );

      if (newLeftWidth + newRightWidth <= 100) {
        setLeftWidth(newLeftWidth);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const [isResizing, setIsResizing] = useState(false);

  return (
    <Flex
      width="100%"
      height="100%"
      cursor={isResizing ? "col-resize" : undefined}
    >
      {/* Left Column */}
      <Box
        flexBasis={rightContent ? `${leftWidth}%` : "100%"}
        minWidth={`${minLeftWidth}%`}
      >
        {leftContent}
      </Box>

      {/* Right Column */}
      {rightContent && (
        <>
          {/* Divider */}
          <Box
            width="4px"
            cursor="col-resize"
            bg={isResizing ? "gray.400" : undefined}
            _hover={{ bg: "gray.400" }}
            onMouseDown={handleMouseDown}
          />
          <Box
            flex="1"
            minWidth={`${minRightWidth}%`}
            bg="white"
            flexBasis={`calc(100% - ${leftWidth}%)`}
          >
            {rightContent}
          </Box>
        </>
      )}
    </Flex>
  );
};
