import { Button, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AccountLookupStep } from "../Step";
import { SuccessAccountLookupResponse } from "../helpers";
import {
  AuthenticationMethodType,
  useSpecificAuthenticationLink,
} from "./helpers";
import * as OrgConfig from "@avela/organization-config-sdk";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

function getLoginMethod(
  auth0UserType: SuccessAccountLookupResponse["auth0UserType"]
): AuthenticationMethodType | null {
  if (auth0UserType === "auth0") {
    return AuthenticationMethodType.EMAIL;
  }

  if (auth0UserType === "sms") {
    return AuthenticationMethodType.PHONE;
  }

  return null;
}

export type Props = Omit<
  SuccessAccountLookupResponse,
  "parentGuardianExists"
> & {
  config: OrgConfig.AccountLookup.Config;
};

export const FoundAnAccount: FunctionComponent<Props> = (props) => {
  const { auth0UserType, emailAddress, phoneNumber, config } = props;
  const loginMethod = getLoginMethod(auth0UserType);
  const loginLink = useSpecificAuthenticationLink("login", loginMethod!);

  const { state: formState } = useAccountLookupState();
  const signUpState = {
    account_lookup_method: loginMethod,
    account_lookup_email: emailAddress,
    account_lookup_phone_number: phoneNumber,
  };

  useEffect(() => {
    amplitude.track("Account Lookup Found an Account Started", {
      ...formState,
      ...signUpState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire the amplitude.track when the component first renders

  const onClickLogin = () => {
    amplitude.track("Account Lookup Found an Account Login Button Clicked", {
      ...formState,
      ...signUpState,
    });
  };
  const onClickHelp = () => {
    amplitude.track("Account Lookup Found an Account Help Button Clicked", {
      ...formState,
      ...signUpState,
    });
  };

  return (
    <AccountLookupStep
      heading="🎉 We found an account 🎉"
      description={`The ${loginMethod} below can be used to login.`}
    >
      <Stack gap={4} textAlign="center">
        <Text fontSize="2xl" fontWeight={600}>
          {emailAddress ?? phoneNumber}
        </Text>

        <Button as={NavLink} to={loginLink} onClick={onClickLogin}>
          Login with {loginMethod}
        </Button>

        <Button
          as={NavLink}
          variant="outline"
          colorScheme="gray"
          to={config.supportUrl}
          onClick={onClickHelp}
        >
          I don't have access to this
        </Button>

        <Text size="sm" fontWeight={400} color="gray.600">
          The {loginMethod} is redacted for privacy reasons. If you do not
          recognize this {loginMethod}, reach out to an administrator.
        </Text>
      </Stack>
    </AccountLookupStep>
  );
};
