import { Outlet, Route } from "react-router-dom";
import { PublicLayout } from "./public";
import { AccountLookup } from "./public/accountLookup";
import * as Url from "src/services/url";
import { FeatureFlagProvider } from "src/components/Providers/FeatureFlagProvider";
import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { PageRedirect } from "./public/PageRedirect";

function AmplitudeInstrumentation({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const identify = new amplitude.Identify();

    // "Don't set the user ID if there isn't one": https://amplitude.com/docs/get-started/identify-users
    amplitude.identify(identify, {});
  }, []);

  return <>{children}</>;
}

export const UnauthorizedAvelaRoutes = (
  <Route
    element={
      <PublicLayout>
        <FeatureFlagProvider>
          <AmplitudeInstrumentation>
            <Outlet />
          </AmplitudeInstrumentation>
        </FeatureFlagProvider>
      </PublicLayout>
    }
  >
    <Route path={Url.Parent.AccountLookup.path} element={<AccountLookup />} />
    <Route path={Url.pageRedirectPath} element={<PageRedirect />} />
  </Route>
);
