import { Button, Flex } from "@chakra-ui/react";
import { useField } from "formik";
import { FunctionComponent, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormSection } from "src/components/Layout/FormSection";
import { Glossary } from "src/components/Text/Glossary";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import {
  GetActiveEnrollmentPeriods_enrollment_period,
  GetStudentsByGuardianId_person,
} from "src/types/graphql";
import { SelectEnrollmentPeriod } from "./SelectEnrollmentPeriod";
import { SelectFormTemplate } from "./SelectFormTemplate";
import { SelectStudent } from "./SelectStudent";

type Props = {
  studentsByGuardian: GetStudentsByGuardianId_person[];
  enrollmentPeriods: GetActiveEnrollmentPeriods_enrollment_period[];
};

export const StartNewFormSection: FunctionComponent<Props> = (props) => {
  const { studentsByGuardian, enrollmentPeriods } = props;
  const { glossary } = useGlossary();
  const organization = useOrganization();

  const [{ value: selectedStudentId }] = useField<string>("studentId");
  const selectedStudent = useMemo(() => {
    return studentsByGuardian.find(
      (student) => student.id === selectedStudentId
    );
  }, [studentsByGuardian, selectedStudentId]);

  const [{ value: selectedEnrollmentPeriodId }] =
    useField("enrollmentPeriodId");
  const formTemplates = useMemo(() => {
    const formTemplatesForEnrollmentPeriod =
      enrollmentPeriods.find((periods) => {
        return periods.id === selectedEnrollmentPeriodId;
      })?.form_templates ?? [];
    return formTemplatesForEnrollmentPeriod;
  }, [enrollmentPeriods, selectedEnrollmentPeriodId]);

  const returnUrl = organization.map(Url.Parent.Form.new).withDefault("#");

  return (
    <FormSection title={glossary`Select student and enrollment period`}>
      <Flex direction="column" gap={5}>
        <SelectStudent studentsByGuardian={studentsByGuardian} />
        <Button
          as={RouterLink}
          colorScheme="gray"
          to={organization
            .map((org) => Url.Parent.Student.new(org, { returnUrl }))
            .withDefault("#")}
          variant="outline"
          width="100%"
        >
          <Glossary>Add new student</Glossary>
        </Button>
        <SelectEnrollmentPeriod enrollmentPeriods={enrollmentPeriods} />
        <SelectFormTemplate
          formTemplates={formTemplates}
          selectedStudent={selectedStudent}
          selectedEnrollmentPeriodId={selectedEnrollmentPeriodId}
        />
      </Flex>
    </FormSection>
  );
};
