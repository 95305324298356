import { Flex, FormControl, useSteps } from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FunctionComponent, useCallback, useState } from "react";
import { validateWithZod } from "src/services/formValidations";
import { EnterAddressStep } from "./steps/EnterAddress";
import { EnterNameStep } from "./steps/EnterName";
import { ShowResultStep } from "./steps/ShowResult";
import { SolveCaptchaStep } from "./steps/SolveCaptcha";
import {
  AccountLookupFormSchema,
  AccountLookupResponse,
  AccountLookupResponseSchema,
  FormikAccountLookup,
  INITIAL_VALUES,
} from "./steps/helpers";
import * as RD from "src/types/remoteData";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { Error as ErrorComponent } from "./steps/results/Error";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import * as OrgConfig from "@avela/organization-config-sdk";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "./Context";

const FORM_STEPS = [EnterNameStep, EnterAddressStep, SolveCaptchaStep];

type Props = {
  config: OrgConfig.AccountLookup.Config;
};

async function submitForm(
  values: FormikAccountLookup,
  organizationPath: string
) {
  const { fullName, addresses, captchaToken } = values;
  const accountLookupUrl = process.env.REACT_APP_ACCOUNT_LOOKUP_URL!;

  return axios.post(
    accountLookupUrl,
    {
      path: organizationPath,
      parent_guardian_full_name: fullName,
      addresses,
      captchaToken,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

/**
 * This form is separated into 4 steps:
 * 1. Enter name
 * 2. Enter address
 * 3. Solve captcha
 * 4. Show results // 3 further sub-states
 *    a. No account found
 *    b. Found an account
 *    c. Register an account for an imported parent/guardian
 */
export const AccountLookupForm: FunctionComponent<Props> = ({ config }) => {
  const [lookupResponse, setLookupResponse] = useState<
    RD.RemoteData<unknown, AccountLookupResponse>
  >(RD.notAsked());
  const organizationPath = useOrganizationPath();
  const { activeStep, goToNext, setActiveStep } = useSteps({
    count: FORM_STEPS.length,
  });
  const { state: formState } = useAccountLookupState();

  const onNextStep = () => {
    goToNext();
    amplitude.track(`Account Lookup Next Step Clicked`, formState);
  };

  const resetSteps = useCallback(
    (formik: FormikProps<FormikAccountLookup>) => {
      setLookupResponse(RD.notAsked());
      setActiveStep(0);
      formik.resetForm();

      amplitude.track("Account Lookup Reset Steps Clicked", formState);
    },
    [setActiveStep, formState]
  );

  const onSubmit = useCallback(
    async (
      values: FormikAccountLookup,
      helpers: FormikHelpers<FormikAccountLookup>
    ) => {
      if (!organizationPath) {
        const error = new Error("Organization path is not defined");
        console.error(error);
        setLookupResponse(RD.failure(error));
        return;
      }

      try {
        const { data } = await submitForm(values, organizationPath);
        const parsedData = RD.fromZod(AccountLookupResponseSchema, data);
        setLookupResponse(parsedData);

        helpers.resetForm();
        setActiveStep(0);
        amplitude.track(
          "Account Lookup Name and Address Form Submit Success",
          formState
        );
      } catch (error) {
        setLookupResponse(RD.failure(error));
        console.warn(error);
        amplitude.track(
          "Account Lookup Name and Address Form Submit Failure",
          formState
        );
      }
    },
    [organizationPath, setActiveStep, formState]
  );

  const ActiveStepComponent = FORM_STEPS[activeStep]!;

  return (
    <Formik<FormikAccountLookup>
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validate={validateWithZod(AccountLookupFormSchema)}
    >
      {(formik) => (
        <Form>
          <Flex
            as={FormControl}
            direction="column"
            paddingX={6}
            paddingBottom={0}
          >
            <RemoteDataView
              remoteData={lookupResponse}
              error={() => (
                <ErrorComponent resetSteps={() => resetSteps(formik)} />
              )}
              notAsked={<ActiveStepComponent goToNext={() => onNextStep()} />}
            >
              {(data) => (
                <ShowResultStep
                  lookupResponse={data}
                  resetSteps={() => resetSteps(formik)}
                  config={config}
                />
              )}
            </RemoteDataView>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
