import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { CARD_PROPS } from "../Step";
import { NavLink } from "react-router-dom";
import * as OrgConfig from "@avela/organization-config-sdk";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

type Props = {
  config: OrgConfig.AccountLookup.Config;
  resetSteps: () => void;
};

export const NoAccountFound: FunctionComponent<Props> = (props) => {
  const { resetSteps, config } = props;

  const { state: formState } = useAccountLookupState();

  useEffect(() => {
    amplitude.track("Account Lookup No Account Found", formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire the amplitude.track when the component first renders

  return (
    <Flex {...CARD_PROPS} padding={8} gap={4} textAlign="center">
      <Heading as="h2" size="lg" fontSize="1.25rem" fontWeight={700}>
        No account login found
      </Heading>
      <Text fontSize="1rem">
        We were unable to find an account login. You can try again with
        different information or reach out to your school district.
      </Text>
      <Button
        onClick={() => {
          resetSteps();
        }}
      >
        Try again
      </Button>
      <Button
        as={NavLink}
        to={config.supportUrl}
        variant="outline"
        colorScheme="gray"
        target="_blank"
        onClick={() => {
          amplitude.track(
            "Account Lookup No Account Found Get Help Clicked",
            formState
          );
        }}
      >
        Stuck? Get help
      </Button>
    </Flex>
  );
};
