import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { DeleteIcon } from "@chakra-ui/icons";
import { Form, Formik, FieldArray, Field, FieldProps } from "formik";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as ConfigForm from "./components/ConfigForm";
import * as SecurityForm from "./components/SecurityForm";
import { AllOrgConfigs } from "./types";
import { validateWithZod } from "src/services/formValidations";
import { FormControl, InputControl } from "formik-chakra-ui";
import { AnnouncementForm } from "./components/AnnouncementForm";
import { BasicInformationForm } from "./BasicInformationForm";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { Divider } from "@chakra-ui/react";
import * as BrandingForm from "./components/BrandingForm";
import { FormikSchema, OrganizationFormSchema } from "./types/formikSchema";
import * as EmailFooterForm from "./components/EmailFooterForm";
import { RiDeleteBin6Line } from "react-icons/ri";
import { formatPhoneNumberWithFallback } from "src/services/format";

type Organization = {
  id: string;
  name: string;
  path: string;
  timezone_name: string;
  announcements: GQL.GetOrganization_organization_by_pk_announcements[];
};
interface OrganizationFormProps {
  organization: Organization;
  configs?: AllOrgConfigs;
  onSubmit: (values: OrganizationFormSchema) => void;
  submitting: boolean;
}

/**
 * OrganizationForm is a complex form component that manages multiple sections of organization settings.
 * It uses Formik for form state management and is composed of several smaller form components:
 *
 * Form Components:
 * - BasicInformationForm: Handles name, path, timezone settings
 * - Contact Information: Manages phone, email and link contacts using FieldArray
 * - ConfigForm: Handles general organization config settings
 * - AnnouncementForm: Manages organization announcements
 * - SecurityForm: Handles security-related settings
 * - BrandingForm: Controls organization branding options
 * - MessageBrandingForm: Manage branding used by email template
 *
 * The Formik state is composed of:
 * - organizationConfigs: Complex nested object containing various config settings
 * - name: Organization name
 * - path: Organization URL path
 * - timezoneName: Organization timezone
 * - noStudentAnnouncement: Settings for student-related announcements
 */
export const OrganizationForm = ({
  organization,
  configs,
  onSubmit,
  submitting,
}: OrganizationFormProps) => {
  /* October 2024,
    we only have NO_STUDENT condition
    that belongs on the PARENT_PORTAL entry_point
    with the INFO type announcement
    but we can have more in the future
  */
  const noStudentAnnouncement = organization.announcements.find(
    (announcement) =>
      announcement.condition === GQL.announcement_condition_enum.NO_STUDENT
  );

  const initialValues: OrganizationFormSchema = useMemo(() => {
    const organizationConfigs = {
      ...ConfigForm.getInitialValues(configs),
      ...BrandingForm.getInitialValues(configs),
      ...SecurityForm.getInitialValues(configs),
    };

    if (organizationConfigs.Contacts.disabled) {
      organizationConfigs.Contacts = {
        path: organization.path || "",
        contacts: [],
        disabled: false,
      };
    }

    return {
      organizationConfigs,
      name: organization.name,
      path: organization.path,
      timezoneName: organization.timezone_name,
      noStudentAnnouncement: {
        id: noStudentAnnouncement?.id || "",
        title: noStudentAnnouncement?.title || "",
        description: noStudentAnnouncement?.description || "",
        active: noStudentAnnouncement?.active ?? false,
        showType:
          noStudentAnnouncement?.display_type ??
          GQL.announcement_display_type_enum.BANNER,
        linkActive:
          !!noStudentAnnouncement?.link_text &&
          !!noStudentAnnouncement?.link_url,
        linkText: noStudentAnnouncement?.link_text || "",
        linkUrl: noStudentAnnouncement?.link_url || "",
      },
      ...EmailFooterForm.getInitialValues(configs),
    };
  }, [
    configs,
    organization.name,
    organization.path,
    organization.timezone_name,
    noStudentAnnouncement,
  ]);

  function editPolicyUrl(organization: { id: uuid }): string {
    return Url.Admin.Organizations.editPolicy(organization.id);
  }

  // Helper functions to get filtered contacts
  const getPhones = (contacts: any[]) =>
    contacts.filter((c) => c.type === "phone");
  const getEmails = (contacts: any[]) =>
    contacts.filter((c) => c.type === "email");
  const getLinks = (contacts: any[]) =>
    contacts.filter((c) => c.type === "link");

  return (
    <Formik<OrganizationFormSchema>
      initialValues={initialValues}
      validate={validateWithZod(FormikSchema)}
      onSubmit={onSubmit}
    >
      {(form) => {
        const contactsConfig = form.values.organizationConfigs.Contacts;
        const contacts =
          "contacts" in contactsConfig ? contactsConfig.contacts : [];

        return (
          <Form noValidate>
            <VStack align="left" spacing={6}>
              <Heading as="h1" variant="admin">
                Edit {organization?.name || "Organization"}
              </Heading>
              <BasicInformationForm isNew={false} />

              <Divider marginY={4} />

              <Heading as="h2" variant="admin">
                Contact information
              </Heading>

              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getPhones(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      const valueName = `organizationConfigs.Contacts.contacts.${contactIndex}.value`;
                      return (
                        <ContactGrid key={contact.id}>
                          <InputControl
                            as={ContactSubgrid}
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Phone label"
                            isRequired
                            inputProps={{ placeholder: "E.g.: Call support" }}
                          />
                          <Field name={valueName}>
                            {({ field, meta }: FieldProps) => {
                              const { error, touched } = meta;
                              return (
                                <FormControl
                                  as={ContactSubgrid}
                                  isRequired
                                  isInvalid={!!error && touched}
                                  name={valueName}
                                >
                                  <FormLabel htmlFor={valueName}>
                                    Phone
                                  </FormLabel>
                                  <Input {...field} id={valueName} />
                                  {error && (
                                    <FormErrorMessage>{error}</FormErrorMessage>
                                  )}
                                  {!error && (
                                    <FormHelperText>
                                      Preview:{" "}
                                      {formatPhoneNumberWithFallback(
                                        field.value
                                      )}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              );
                            }}
                          </Field>

                          <ContactSubgrid>
                            <IconButton
                              aria-label="Delete phone"
                              alignSelf="center"
                              icon={<Icon as={RiDeleteBin6Line} />}
                              variant="outline"
                              colorScheme="primary"
                              onClick={() => arrayHelpers.remove(contactIndex)}
                              gridRowStart="input"
                            />
                          </ContactSubgrid>
                        </ContactGrid>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "phone",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add phone
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getEmails(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      return (
                        <ContactGrid key={contact.id}>
                          <InputControl
                            as={ContactSubgrid}
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Email label"
                            isRequired
                            inputProps={{
                              placeholder: "E.g.: Email support",
                            }}
                          />
                          <InputControl
                            as={ContactSubgrid}
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.value`}
                            label="Email address"
                            isRequired
                            inputProps={{
                              placeholder: "E.g.: email@address.com",
                            }}
                          ></InputControl>
                          <ContactSubgrid>
                            <IconButton
                              aria-label="Delete email"
                              alignSelf="center"
                              icon={<Icon as={RiDeleteBin6Line} />}
                              gridRowStart="input"
                              colorScheme="primary"
                              variant="outline"
                              onClick={() => arrayHelpers.remove(contactIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ContactSubgrid>
                        </ContactGrid>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "email",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add email
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <Flex direction="column">
                <Heading as="h3" variant="admin">
                  Additional resources
                </Heading>
                <Text color="blackAlpha.700" fontSize="sm">
                  Optionally add links to external resources like websites,
                  helpdesks, and more
                </Text>
              </Flex>
              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getLinks(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      return (
                        <ContactGrid key={contact.id}>
                          <InputControl
                            as={ContactSubgrid}
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Link label"
                            isRequired
                            inputProps={{
                              placeholder: "E.g.: Official website",
                            }}
                          />
                          <InputControl
                            as={ContactSubgrid}
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.value`}
                            label="Link URL"
                            isRequired
                            inputProps={{
                              placeholder: "E.g.: http://www.mywebsite.com",
                            }}
                          />
                          <ContactSubgrid>
                            <IconButton
                              aria-label="Delete link"
                              alignSelf="center"
                              colorScheme="primary"
                              icon={<Icon as={RiDeleteBin6Line} />}
                              variant="outline"
                              onClick={() => arrayHelpers.remove(contactIndex)}
                              gridRowStart="input"
                            ></IconButton>
                          </ContactSubgrid>
                        </ContactGrid>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "link",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add additional resource
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <EmailFooterForm.EmailFooterForm />
              <Divider marginY={4} />

              {!!organization && (
                <>
                  <Flex direction="column">
                    <Heading as="h2" variant="admin">
                      Access & policies
                    </Heading>
                    <Text color="blackAlpha.700" fontSize="sm">
                      Configure access policies for the organization.
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Button
                      as={NavLink}
                      colorScheme="gray"
                      to={editPolicyUrl(organization)}
                      aria-label={`Edit access policies`}
                      variant="outline"
                    >
                      Edit access policies
                    </Button>
                    <Spacer />
                  </Flex>
                  <Divider marginY={4} />
                </>
              )}

              <Heading as="h2" variant="admin">
                General settings
              </Heading>

              <ConfigForm.ConfigForm />

              <AnnouncementForm />

              <Divider marginY={4} />
              <Heading as="h2" variant="admin">
                Security
              </Heading>

              <SecurityForm.SecurityForm />

              <Divider marginY={4} />

              <Heading as="h2" variant="admin">
                Branding
              </Heading>
              <BrandingForm.BrandingForm />

              <AdminFormButtons sticky alignItems="center">
                <Button
                  as={NavLink}
                  to={Url.Admin.Organizations.index()}
                  variant="outline"
                  colorScheme="gray"
                >
                  Cancel
                </Button>
                <Spacer />
                <Button type="submit" isLoading={submitting}>
                  Update
                </Button>
              </AdminFormButtons>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

function ContactGrid(props: { children: React.ReactNode }) {
  return (
    <Grid
      gap={4}
      gridTemplateColumns="[left] 1fr [right] 1fr [delete] 2rem"
      gridTemplateRows="[label] auto [input] auto [helper] auto"
    >
      {props.children}
    </Grid>
  );
}

function ContactSubgrid(props: { children: React.ReactNode }) {
  return (
    <Grid gridRow="span 3" gridTemplateRows="subgrid" gap={0}>
      {props.children}
    </Grid>
  );
}
