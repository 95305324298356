import {
  Box,
  Button,
  Flex,
  FormErrorMessage,
  Grid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { BaseInputProps } from "src/components/Inputs/QuestionDynamicInputs/Question";
import { GET_FORM_ANSWER_ID } from "src/components/graphql/queries";
import { MAX_NUMBER_OF_FILES } from "src/constants";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import * as GQL from "src/types/graphql";
import { UploadFileInput } from "../../Inputs/UploadFileInput";
import { QuestionFileItem } from "./QuestionFileItem";
import { UploadFileItem } from "./UploadFileItem";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { DocumentLibraryDialog } from "src/components/Inputs/DocumentLibraryDialog";
import { RiCloudLine } from "react-icons/ri";
import {
  useDocumentLibrary,
  ExistingDocument,
} from "src/hooks/useDocumentLibrary";
import useUser from "src/hooks/useUser";
import { sleep } from "src/services/sleep";

export interface UploadFileQuestionProps extends BaseInputProps {
  formId: string;
}

export const UploadFileQuestion: React.FC<UploadFileQuestionProps> = ({
  id,
  formId,
  isDisabled,
}) => {
  const flags = useFlags(["document-library-parent"]);
  const [field, meta, helpers] = useField<FormikFieldValue>(id);
  const [uploadingFiles, setUploadingFiles] = useState<File[] | undefined>();
  const [questionFiles, setQuestionFiles] = useState<string[] | undefined>();
  const [formAnswerId, setFormAnswerId] = useState<string | undefined>();
  const [disableFileUpload, setDisableFileUpload] = useState<boolean>(
    isDisabled ?? false
  );

  const user = useUser();
  const { existingDocuments, refetchExistingDocuments, reuploadDocument } =
    useDocumentLibrary({
      userId: user.status === "ok" ? user.data.id : "",
      formAnswerId,
      formId,
      questionId: id,
    });

  useRemoteDataQuery<GQL.GetFormAnswerId, GQL.GetFormAnswerIdVariables>(
    GET_FORM_ANSWER_ID,
    {
      variables: {
        question_id: id,
        form_id: formId,
      },
      onCompleted: (data) => {
        setFormAnswerId(data.form_answer[0]?.id);
      },
    }
  );

  useEffect(() => {
    if (Array.isArray(field.value)) {
      setQuestionFiles(field.value);
    }
  }, [field]);

  const handleFileUpload = (data: File) => {
    helpers.setTouched(true);
    if (uploadingFiles?.find((uploadlingFile) => uploadlingFile === data))
      return;

    const newFiles = uploadingFiles?.length
      ? [...uploadingFiles, data]
      : [data];

    setDisableFileUpload(true);
    setUploadingFiles(newFiles);
  };

  const handleRemoveFile = useCallback(
    (file: File) => {
      const newFiles = uploadingFiles?.filter(
        (uploadingFile) => uploadingFile !== file
      );
      setUploadingFiles(newFiles);
    },
    [uploadingFiles]
  );

  const handleFileUploadComplete = useCallback(
    (file: File, document_id: string) => {
      handleRemoveFile(file);

      const newDocumentIds = questionFiles?.length
        ? [...questionFiles, document_id]
        : [document_id];

      helpers.setValue(newDocumentIds);
      refetchExistingDocuments();
    },
    [handleRemoveFile, helpers, questionFiles, refetchExistingDocuments]
  );

  const handleDeleteFile = useCallback(
    (documentId: string) => {
      const newDocumentIds = questionFiles?.filter(
        (questionFile) => questionFile !== documentId
      );
      helpers.setValue(newDocumentIds);
      refetchExistingDocuments();
    },
    [helpers, questionFiles, refetchExistingDocuments]
  );

  const handleUpdateFormAnswerId = useCallback(
    (formAnswerIdResponse: string) => {
      if (formAnswerId) return;

      setFormAnswerId(formAnswerIdResponse);
    },
    [formAnswerId]
  );

  const handleEnableFileUpload = useCallback(() => {
    setDisableFileUpload(isDisabled ?? false);
  }, [isDisabled]);

  const {
    isOpen: isDocumentLibraryOpen,
    onOpen: openDocumentLibrary,
    onClose: closeDocumentLibrary,
  } = useDisclosure({ defaultIsOpen: false });

  return (
    <Box
      display="flex"
      flexDirection="column"
      onBlur={() => {
        helpers.setTouched(true);
      }}
    >
      <Flex direction="column" gap="2">
        {questionFiles && questionFiles.length > 0 && (
          <Flex direction="column">
            {questionFiles?.map((documentId) => (
              <QuestionFileItem
                key={documentId}
                documentId={documentId}
                onDeleteDocument={handleDeleteFile}
                onGenerateDocumentComplete={handleEnableFileUpload}
                viewMode={isDisabled}
              />
            ))}
          </Flex>
        )}
        {uploadingFiles?.map((uploadingFile) => (
          <UploadFileItem
            key={uploadingFile.name}
            file={uploadingFile}
            formAnswerId={formAnswerId}
            formId={formId}
            questionId={id}
            onRemoveFile={handleRemoveFile}
            onUploadFile={handleFileUploadComplete}
            onFormAnswerCreation={handleUpdateFormAnswerId}
            onFileUploadError={handleEnableFileUpload}
          />
        ))}
        <Grid
          gap={2}
          gridTemplateColumns="repeat(auto-fit, minmax(12rem, 1fr))"
        >
          <UploadFileInput
            onUpload={handleFileUpload}
            disabled={
              disableFileUpload || questionFiles?.length === MAX_NUMBER_OF_FILES
            }
          />
          {flags["document-library-parent"].enabled &&
            existingDocuments.hasData() &&
            (existingDocuments.data.length > 0 ? (
              <>
                <DocumentLibraryDialog
                  isOpen={isDocumentLibraryOpen}
                  onClose={closeDocumentLibrary}
                  documents={existingDocuments.data}
                  onCancel={closeDocumentLibrary}
                  onUseFile={async (document: ExistingDocument) => {
                    const resp = await reuploadDocument(document);
                    if (!formAnswerId) {
                      // this is a first file upload for this question.
                      // update the formAnswerId state so we can use the same id
                      // for the next file upload
                      setFormAnswerId(resp.formAnswerId);
                    }

                    // need to put 2s delay in order to get the file preview showing properly
                    await sleep(2000);
                    handleFileUploadComplete(resp.file, resp.fileId);

                    closeDocumentLibrary();
                  }}
                />
                <Button
                  alignSelf="center"
                  justifySelf="center"
                  leftIcon={<RiCloudLine />}
                  variant="outline"
                  colorScheme="gray"
                  width="100%"
                  whiteSpace="pre-wrap"
                  paddingY={2}
                  height="fit-content"
                  minHeight={10}
                  onClick={openDocumentLibrary}
                >
                  Choose existing file
                </Button>
              </>
            ) : null)}
        </Grid>
      </Flex>
      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      <Text fontSize="xs" color="gray" mt={2}>
        You can upload <strong>{MAX_NUMBER_OF_FILES}</strong> files, supported
        formats: JPG/JPEG, PNG, HEIC, PDF.
        {window.screen.width < 1024 && " You can tap to preview the file."}
      </Text>
    </Box>
  );
};
