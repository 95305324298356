import React from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Card } from "src/components/Layout/Card";

export type MatchType = "single-best-offer" | "multi-offer";
type MatchTypeObject = {
  type: MatchType;
  title: string;
  description: string;
  enabled: boolean;
};

// Store somewhere else?
export const matchTypes = [
  {
    type: "single-best-offer" as MatchType,
    title: "Single best offer",
    description:
      "Creates at most one offer per student across all schools they’ve applied to",
    enabled: false,
  },
  {
    type: "multi-offer" as MatchType,
    title: "Multi offer",
    description:
      "Creates many offers for a single student across all schools they’ve applied to",
    enabled: true,
  },
];

type ConfigureMatchDialogProps = {
  isOpen: boolean;
  onNextButtonClick: (matchType: MatchType) => void;
  onCancelButtonClick: () => void;
};

export const ConfigureMatchDialog: React.FC<ConfigureMatchDialogProps> = ({
  isOpen,
  onNextButtonClick,
  onCancelButtonClick,
}) => {
  const [selectedType, setSelectedType] =
    React.useState<MatchType>("single-best-offer");
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancelButtonClick}
      scrollBehavior="inside"
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configure Match</ModalHeader>
        <ModalBody as={Flex} direction="column" gap={8}>
          <Text>What type of Match would you like?</Text>
          <Flex gap={2}>
            {matchTypes.map((matchType) => (
              <MatchTypeCard
                matchType={matchType}
                onSelect={setSelectedType}
                selected={matchType.type === selectedType}
                enabled={matchType.enabled}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            variant="ghost"
            colorScheme="gray"
            onClick={onCancelButtonClick}
          >
            Cancel
          </Button>
          <Button onClick={() => onNextButtonClick(selectedType)}>Next</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type MatchTypeCardProps = {
  matchType: MatchTypeObject;
  onSelect: React.Dispatch<React.SetStateAction<MatchType>>;
  selected: boolean;
  enabled: boolean;
};

const MatchTypeCard: React.FC<MatchTypeCardProps> = ({
  matchType,
  onSelect,
  selected,
  enabled,
}) => {
  const cardSelectedStyle = selected
    ? {
        color: "primary.500",
        background: "primary.50",
        borderColor: "primary.500",
        _hover: {
          cursor: "pointer",
          background: "primary.20",
        },
      }
    : {};
  return enabled ? (
    <Card
      showBorder
      padding={4}
      flex={1}
      _hover={{
        cursor: "pointer",
        background: "blackAlpha.200",
      }}
      onClick={() => onSelect(matchType.type)}
      {...cardSelectedStyle}
    >
      <Flex direction="column" gap={3} textAlign="center">
        <Heading size="md">{matchType.title}</Heading>
        <Text fontSize="xs">{matchType.description}</Text>
      </Flex>
    </Card>
  ) : (
    <Card showBorder padding={4} flex={1} background="gray.50">
      <Flex direction="column" gap={3} textAlign="center">
        <Heading size="md">{matchType.title}</Heading>
        <Text fontSize="xs">{matchType.description}</Text>
      </Flex>
    </Card>
  );
};
