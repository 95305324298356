import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS_BY_USER_ID = gql`
  query GetOrganizationsByUserId($userId: String!) {
    organization(
      where: { people: { user_id: { _eq: $userId } } }
      order_by: { name: asc }
    ) {
      name
      path
      id
    }
  }
`;

export const GET_DOCUMENT_LIBRARY_FOR_USER = gql`
  query GetDocumentLibraryForUser($userId: String!) {
    document_metadata(
      where: {
        owner_user_id: { _eq: $userId }
        document_type: { _eq: General }
        deleted_at: { _is_null: true }
      }
      order_by: [{ updated_at: desc }]
    ) {
      document_id
      filename
      created_at
      form_answer {
        created_at
        form_question {
          question_id
          question {
            question
          }
        }
      }
    }
  }
`;
