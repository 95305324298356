import { Route } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireConfig } from "src/components/Permissions/RequireConfig";
import { RequireHasuraRoles } from "src/components/Permissions/RequireHasuraRoles";
import { RequireOrganization } from "src/components/Permissions/RequireOrganization";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { NewTeam } from "src/scenes/orgAdmin/team/NewTeam";
import * as Url from "src/services/url";
import { HasuraRole } from "src/types/hasuraRole";
import { NewApiCredential } from "./apiCredentials/New";
import { DataServices } from "./dataServices";
import { EditWebhook } from "./dataServices/webhooks/Edit";
import { NewWebhook } from "./dataServices/webhooks/New";
import {
  EditFormTemplateRoutes,
  EnrollmentPeriodRoutes,
} from "./enrollmentPeriods/routes";
import { Forms } from "./forms";
import { ViewForm } from "./forms/View";
import { BulkExport } from "./forms/export";
import { FormImport } from "./forms/import";
import { BulkVerifications } from "./forms/verifications";
import { Messages } from "./messages";
import { NewMessage } from "./messages/New";
import { ViewMessage } from "./messages/View";
import { Parents } from "./parents";
import { EditParent } from "./parents/Edit";
import { NewParent } from "./parents/New";
import { ViewParent } from "./parents/View";
import { Schools } from "./schools";
import { EditSchool } from "./schools/Edit";
import { NewSchool } from "./schools/New";
import { Students } from "./students";
import { EditStudent } from "./students/Edit";
import { NewStudent } from "./students/New";
import { ViewStudent } from "./students/View";
import { Team } from "./team";
import { EditTeamMember } from "./team/EditMember";
import { EditTeam } from "./team/EditTeam";
import { NewTeamMember } from "./team/NewMember";
import { MatchRoutes } from "./match/routes";
import { Capacities } from "./capacities";
import { MatchTemplates } from "./matchTemplates";
import { NewMatchTemplate } from "./matchTemplates/New";
import { Layout } from "./Layout";
import { DuplicateStudentReport } from "./avelaLabs/DuplicateStudentReport";
import { Index } from ".";

export const OrgAdminRoutes = (
  <Route
    element={
      <RequireHasuraRoles
        roles={[
          HasuraRole.ORG_ADMIN,
          HasuraRole.ADMIN,
          HasuraRole.SCHOOL_ADMIN,
          HasuraRole.DISTRICT_ADMIN,
        ]}
      />
    }
  >
    <Route element={<Layout />}>
      <Route element={<RequireOrganization />}>
        <Route path={Url.OrgAdmin.indexPath} element={<Index />} />
        {EnrollmentPeriodRoutes}
        {EditFormTemplateRoutes}
        {MatchRoutes}

        <Route
          path={Url.OrgAdmin.Schools.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="navigation.admin.schools">
                <Schools />
              </RequireUserPermissions>
            </PreserveState>
          }
        >
          <Route
            path={Url.OrgAdmin.Schools.newPath}
            element={
              <RequireUserPermissions permission="school:create">
                <NewSchool />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Schools.editPath}
            element={
              <RequireUserPermissions permission="school:update">
                <EditSchool />
              </RequireUserPermissions>
            }
          />
        </Route>
        <Route
          path={Url.OrgAdmin.Students.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="navigation.admin.users">
                <Students />
              </RequireUserPermissions>
            </PreserveState>
          }
        >
          <Route
            path={Url.OrgAdmin.Students.newPath}
            element={
              <RequireUserPermissions permission="user:create">
                <NewStudent />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Students.editPath}
            element={
              <RequireUserPermissions permission="user:update">
                <EditStudent />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Students.viewPath}
            element={
              <RequireUserPermissions permission="user:read">
                <ViewStudent />
              </RequireUserPermissions>
            }
          />
        </Route>
        <Route
          path={Url.OrgAdmin.Parents.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="navigation.admin.users">
                <Parents />
              </RequireUserPermissions>
            </PreserveState>
          }
        >
          <Route
            path={Url.OrgAdmin.Parents.newPath}
            element={
              <RequireUserPermissions permission="user:create">
                <NewParent />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Parents.editPath}
            element={
              <RequireUserPermissions permission="user:update">
                <EditParent />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Parents.viewPath}
            element={
              <RequireUserPermissions permission="user:read">
                <ViewParent />
              </RequireUserPermissions>
            }
          />
        </Route>
        <Route
          path={Url.OrgAdmin.FormImport.viewPath}
          element={
            <RequireUserPermissions permission="navigation.admin.forms">
              <FormImport />
            </RequireUserPermissions>
          }
        />
        <Route
          path={Url.OrgAdmin.Forms.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="form:read">
                <Forms />
              </RequireUserPermissions>
            </PreserveState>
          }
        >
          <Route
            path={Url.OrgAdmin.Forms.viewPath}
            element={
              <RequireUserPermissions permission="form:read">
                <ViewForm />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Forms.verificationsPath}
            element={
              <RequireUserPermissions permission="form:update">
                <BulkVerifications />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Forms.exportPath}
            element={
              <RequireUserPermissions permission="form:read">
                <BulkExport />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Forms.newMessagePath}
            element={
              <RequireUserPermissions permission="message:create">
                <NewMessage />
              </RequireUserPermissions>
            }
          />
        </Route>
        <Route
          path={Url.OrgAdmin.Team.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="navigation.admin.team">
                <Team />
              </RequireUserPermissions>
            </PreserveState>
          }
        >
          <Route
            path={Url.OrgAdmin.Team.editMemberPath}
            element={
              <RequireUserPermissions permission="team:update">
                <EditTeamMember />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Team.newMemberPath}
            element={
              <RequireUserPermissions permission="team:create">
                <NewTeamMember />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Team.editTeamPath}
            element={
              <RequireUserPermissions permission="team:update">
                <EditTeam />
              </RequireUserPermissions>
            }
          />
          <Route
            path={Url.OrgAdmin.Team.newTeamPath}
            element={
              <RequireUserPermissions permission="team:create">
                <NewTeam />
              </RequireUserPermissions>
            }
          />
        </Route>
        <Route
          path={Url.OrgAdmin.Messages.newPath}
          element={
            <RequireUserPermissions permission="message:create">
              <NewMessage />
            </RequireUserPermissions>
          }
        />
        <Route
          path={Url.OrgAdmin.Messages.indexPath}
          element={
            <RequireUserPermissions permission="message:read">
              <Messages />
            </RequireUserPermissions>
          }
        />
        <Route
          path={Url.OrgAdmin.Messages.viewPath}
          element={
            <RequireUserPermissions permission="message:read">
              <ViewMessage />
            </RequireUserPermissions>
          }
        />
        <Route
          path={Url.OrgAdmin.DataServices.indexPath}
          element={
            <PreserveState>
              <RequireUserPermissions permission="navigation.admin.data_services">
                <DataServices />
              </RequireUserPermissions>
            </PreserveState>
          }
        />
        <Route element={<RequireHasuraRoles roles={[HasuraRole.ADMIN]} />}>
          <Route
            path={Url.OrgAdmin.ApiCredentials.newPath}
            element={
              <RequireUserPermissions permission="team:create">
                <NewApiCredential />
              </RequireUserPermissions>
            }
          ></Route>
          <Route
            path={Url.OrgAdmin.Webhooks.newPath}
            element={
              <RequireUserPermissions permission="data_services:create">
                <NewWebhook />
              </RequireUserPermissions>
            }
          ></Route>
          <Route
            path={Url.OrgAdmin.Webhooks.editPath}
            element={
              <RequireUserPermissions permission="data_services:update">
                <EditWebhook />
              </RequireUserPermissions>
            }
          ></Route>
        </Route>
        <Route
          path={Url.OrgAdmin.CapacitiesConfig.indexPath}
          element={
            <RequireUserPermissions permission="navigation.admin.capacities">
              <Capacities />
            </RequireUserPermissions>
          }
        />
      </Route>
      <Route
        path={Url.OrgAdmin.MatchTemplates.indexPath}
        element={
          <RequireConfig configType="Match">
            <RequireUserPermissions permission="navigation.admin.priority_templates">
              <MatchTemplates />
            </RequireUserPermissions>
          </RequireConfig>
        }
      />
      <Route element={<RequireHasuraRoles roles={[HasuraRole.ADMIN]} />}>
        <Route
          path={Url.OrgAdmin.MatchTemplates.newPath}
          element={
            <RequireConfig configType="Match">
              <RequireUserPermissions permission="priority_template:create">
                <NewMatchTemplate />
              </RequireUserPermissions>
            </RequireConfig>
          }
        />
      </Route>
      <Route
        element={
          <RequireHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          />
        }
      >
        <Route
          path={Url.OrgAdmin.AvelaLabs.duplicateStudentReportPath}
          element={<DuplicateStudentReport />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Route>
);
