import { gql } from "@apollo/client";

export const UPDATE_CAPACITIES = gql`
  mutation UpdateCapacities(
    $gradeUpdates: [grade_updates!]!
    $binUpdates: [bin_updates!]!
  ) {
    update_grade_many(updates: $gradeUpdates) {
      affected_rows
    }
    update_bin_many(updates: $binUpdates) {
      affected_rows
    }
  }
`;
