import { Flex, Heading } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import * as GQL from "src/types/graphql";
import { Table } from "./Table";
import {
  ColumnType,
  buildDuplicatePersonIdColumnDef,
} from "./columns/DuplicatePersonIdColumn";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_POSSIBLE_STUDENT_DUPLICATES } from "src/scenes/orgAdmin/students/graphql/queries";
import { GQLRemoteDataView } from "../Layout/RemoteDataView";

interface PossibleDuplicatesTableProps {
  student: GQL.GetStudentByIdAdminPortal_person_by_pk;
}

export const PossibleDuplicatesTable = ({
  student,
}: PossibleDuplicatesTableProps) => {
  const organization = useOrganization();

  const { remoteData: possibleStudentDuplicates } = useRemoteDataQuery<
    GQL.GetPossibleStudentDuplicates,
    GQL.GetPossibleStudentDuplicatesVariables
  >(GET_POSSIBLE_STUDENT_DUPLICATES, {
    variables: {
      student_id: student.id,
      organization_id: organization.map((org) => org.id).withDefault(""),
      first_name: student.first_name ? student.first_name : "INVALID",
      last_name: student.last_name ? student.last_name : "INVALID",
      date_of_birth: student.birth_date ? student.birth_date : "0000-01-01",
    },
  });

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      buildDuplicatePersonIdColumnDef<ColumnType>({
        organization,
      }),
      {
        accessorKey: "first_name",
        header: "first name",
        accessorFn: (row) => row?.first_name || "",
      },
      {
        accessorKey: "last_name",
        header: "last name",
        accessorFn: (row) => row?.last_name || "",
      },
      {
        accessorKey: "birth_date",
        header: "birth date",
        accessorFn: (row) => row?.birth_date || "",
      },
    ],
    [organization]
  );

  return (
    <GQLRemoteDataView remoteData={possibleStudentDuplicates}>
      {(possibleStudentDuplicatesData) => (
        <Flex direction="column" gap={2}>
          <Flex justifyContent="space-between">
            <Heading as="h2" fontSize="2xl" fontWeight="400">
              Possible Duplicates
            </Heading>
          </Flex>
          <Table
            data={possibleStudentDuplicatesData.person}
            columns={columns}
            noDataMessage="No possible duplicates found for this user"
          />
        </Flex>
      )}
    </GQLRemoteDataView>
  );
};
