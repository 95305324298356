import { ReactNode } from "react";
import { FeatureFlag } from "src/types/featureFlag";
import { useFlags } from "../Providers/FeatureFlagProvider";

type Props = {
  children: ReactNode | ReactNode[];
  featureFlags: FeatureFlag[];
};

export const WithFeatureFlags: React.FC<Props> = ({
  children,
  featureFlags,
}: Props) => {
  const flags = useFlags(featureFlags);

  const isEnabled = featureFlags.every((flag) => flags[flag].enabled);

  return isEnabled ? <>{children}</> : null;
};
