import { gql } from "@apollo/client";

export const GET_FEED_DATA = gql`
  query GetFeedData(
    $enrollment_period_id: uuid!
    $organization_id: uuid!
    $begin_at: timestamptz!
    $end_at: timestamptz!
  ) {
    audit_form_transaction(
      where: {
        form: {
          form_template: {
            enrollment_period_id: { _eq: $enrollment_period_id }
          }
        }
        action_tstamp_tx: { _gte: $begin_at, _lte: $end_at }
      }
      order_by: { action_tstamp_tx: desc }
      limit: 10
    ) {
      transaction_id
      action_tstamp_tx
      logged_actions {
        user {
          people(
            where: {
              _or: [
                { organization_id: { _eq: $organization_id } }
                { person_type: { _eq: admin } }
              ]
            }
          ) {
            id
            full_name
            person_type
          }
        }
        action
        action_tstamp_clk
        table_name
        row_data
        changed_fields
      }
    }
    school(where: { organization_id: { _eq: $organization_id } }) {
      id
      name
    }
    grade(where: { enrollment_period_id: { _eq: $enrollment_period_id } }) {
      id
      grade_config {
        id
        label
      }
      program {
        id
        label
      }
    }
    enrollment_period_tag(
      where: { enrollment_period_id: { _eq: $enrollment_period_id } }
    ) {
      id
      name
    }
  }
`;

export const GET_PARENT_PROCESSED_FORM_STAT = gql`
  query GetParentProcessedFormStat(
    $current_begin_at: timestamptz!
    $current_end_at: timestamptz!
    $previous_begin_at: timestamptz!
    $previous_end_at: timestamptz!
    $enrollment_period_id: uuid!
    $organization_id: uuid!
  ) {
    current_period: form_tracking_dates_aggregate(
      where: {
        updated_at: { _gte: $current_begin_at, _lte: $current_end_at }
        form: {
          form_template: {
            enrollment_period: {
              id: { _eq: $enrollment_period_id }
              organization_id: { _eq: $organization_id }
            }
          }
        }
        user: { people: { person_type: { _eq: guardian } } }
      }
    ) {
      aggregate {
        count(columns: form_id)
      }
    }

    previous_period: form_tracking_dates_aggregate(
      where: {
        updated_at: { _gte: $previous_begin_at, _lte: $previous_end_at }
        form: {
          form_template: {
            enrollment_period: {
              id: { _eq: $enrollment_period_id }
              organization_id: { _eq: $organization_id }
            }
          }
        }
        user: { people: { person_type: { _eq: guardian } } }
      }
    ) {
      aggregate {
        count(columns: form_id)
      }
    }
  }
`;

export const GET_ADMIN_PROCESSED_FORM_STAT = gql`
  query GetAdminProcessedFormStat(
    $current_begin_at: timestamptz!
    $current_end_at: timestamptz!
    $previous_begin_at: timestamptz!
    $previous_end_at: timestamptz!
    $enrollment_period_id: uuid!
    $organization_id: uuid!
  ) {
    current_period: form_tracking_dates_aggregate(
      where: {
        updated_at: { _gte: $current_begin_at, _lt: $current_end_at }
        form: {
          form_template: {
            enrollment_period: {
              id: { _eq: $enrollment_period_id }
              organization_id: { _eq: $organization_id }
            }
          }
        }
        user: {
          people: {
            person_type: { _in: [admin, orgAdmin, schoolAdmin, districtAdmin] }
          }
        }
      }
    ) {
      aggregate {
        count(columns: form_id)
      }
    }

    previous_period: form_tracking_dates_aggregate(
      where: {
        updated_at: { _gte: $previous_begin_at, _lt: $previous_end_at }
        form: {
          form_template: {
            enrollment_period: {
              id: { _eq: $enrollment_period_id }
              organization_id: { _eq: $organization_id }
            }
          }
        }
        user: {
          people: {
            person_type: { _in: [admin, orgAdmin, schoolAdmin, districtAdmin] }
          }
        }
      }
    ) {
      aggregate {
        count(columns: form_id)
      }
    }
  }
`;
