import { Button, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";

interface ExitPublicViewButtonProps {
  id: string;
  colorScheme?: "primary" | "gray";
}

export const ExitPublicViewButton = ({
  id,
  colorScheme = "gray",
}: ExitPublicViewButtonProps) => {
  const organization = useOrganization();
  const navigate = useNavigate();

  const {
    confirm: confirmExitPublicView,
    confirmationDialog: exitPublicViewConfirmationDialog,
  } = useConfirmationDialog({
    header: "Exit public view",
    body: (
      <Text>
        Exiting the public match view will take you back to the details of the
        match.
        <Text fontWeight="bold">
          This will show protected student information.
        </Text>
      </Text>
    ),
    cancelButton: {
      label: "Cancel",
    },
    confirmButton: {
      label: "Exit",
      colorScheme: "primary",
    },
    translate: true,
  });

  const handleExitPublicViewClick = useCallback(async () => {
    if (!(await confirmExitPublicView())) {
      return;
    }

    navigate(
      organization
        .map((org) => OrgAdmin.Match.details(org, id))
        .withDefault("#")
    );
  }, [confirmExitPublicView, id, navigate, organization]);

  return (
    <>
      <Button
        aria-label="Exit public view"
        onClick={handleExitPublicViewClick}
        colorScheme={colorScheme}
      >
        Exit
      </Button>
      {exitPublicViewConfirmationDialog}
    </>
  );
};
