import React, { useCallback } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Card } from "src/components/Layout/Card";
import * as GQL from "src/types/graphql";
import { GET_SCHOOLS_FOR_MATCH } from "../graphql/queries";
import { useOrganization } from "src/hooks/useOrganization";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { SearchInput } from "src/components/Inputs/SearchInput";
import { normalizeSearchQuery } from "src/components/graphql/utils";
import { RiEdit2Line, RiMenuAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { OrgAdmin } from "src/services/url";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";

function createElevatedSchoolAdminFilter(
  applyingSchoolIds: string[],
  attendingSchoolIds: string[]
) {
  return {
    _not: {
      id: {
        _nin: [...applyingSchoolIds, ...attendingSchoolIds],
      },
    },
  };
}

export const ConfigureMatchSchoolList = () => {
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const organization = useOrganization();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetSchoolsForMatch,
    GQL.GetSchoolsForMatchVariables
  >(GET_SCHOOLS_FOR_MATCH, {
    variables: {
      organizationId: organization.map((org) => org.id).withDefault(""),
      search: searchTerm ? { name: { _ilike: `%${searchTerm}%` } } : {},
      school_filter: isElevatedSchoolAdmin
        ? createElevatedSchoolAdminFilter(applyingSchoolIds, attendingSchoolIds)
        : {},
      enrollmentPeriodId: selectedEnrollmentPeriod?.id ?? "",
    },
    skip: !organization.hasData() || !selectedEnrollmentPeriod,
    fetchPolicy: "cache-and-network",
  });

  const handleActionSearch = useCallback(
    (searchTerm: string) => {
      const normalized = normalizeSearchQuery(searchTerm);

      setSearchTerm(normalized);
    },
    [setSearchTerm]
  );

  const handleManageRulesClick = useCallback(
    (schoolId: string, binConfigId?: string) => {
      if (binConfigId) {
        navigate(
          organization
            .map((org) => OrgAdmin.Match.rulesEdit(org, schoolId, binConfigId))
            .withDefault("#")
        );
      } else {
        navigate(
          organization
            .map((org) => OrgAdmin.Match.rulesNew(org, schoolId))
            .withDefault("#")
        );
      }
    },
    [navigate, organization]
  );

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {({ school: schools }) => {
        return (
          <>
            <SearchInput
              onSearch={handleActionSearch}
              value={searchTerm}
              onClear={() => setSearchTerm("")}
              placeholder="Search for a school"
            />
            {schools.map((school) => (
              <Card
                display="flex"
                showBorder
                padding={6}
                width="100%"
                justifyContent="space-between"
              >
                <Flex direction="column" gap={4} width="100%">
                  <Flex justifyContent="space-between">
                    <Flex direction="column" gap={2}>
                      <Text fontSize="xl" color="gray.800">
                        {school.name}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {school.reference_id && (
                          <>Ref. Id: {school.reference_id} | </>
                        )}
                        Avela Id: {school.id}
                      </Text>
                    </Flex>

                    {school.school_default_bin_configs[0]?.bin_config_id ? (
                      <WithUserPermissions permissions={["match.rules:update"]}>
                        <Button
                          variant="outline"
                          colorScheme="gray"
                          leftIcon={<RiEdit2Line />}
                          size="sm"
                          onClick={() =>
                            handleManageRulesClick(
                              school.id,
                              school.school_default_bin_configs[0]
                                ?.bin_config_id ?? undefined
                            )
                          }
                        >
                          Edit rules
                        </Button>
                      </WithUserPermissions>
                    ) : (
                      <WithUserPermissions permissions={["match.rules:create"]}>
                        <Button
                          variant="outline"
                          colorScheme="gray"
                          leftIcon={<RiMenuAddFill />}
                          size="sm"
                          onClick={() => handleManageRulesClick(school.id)}
                        >
                          Add rules
                        </Button>
                      </WithUserPermissions>
                    )}
                  </Flex>
                  <Text>
                    {school.street_address} {school.street_address_line_2}{" "}
                    {school.city} {school.state} {school.zip_code}
                  </Text>
                </Flex>
              </Card>
            ))}
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
