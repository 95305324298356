import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { ExistingDocument } from "src/hooks/useDocumentLibrary";
import { formatIsoDate } from "src/services/format";
import { Card } from "../Layout/Card";

type Props = {
  documents: ExistingDocument[];
  onCancel: () => void;
  onUseFile: (document: ExistingDocument) => Promise<void>;
} & Omit<ModalProps, "children">;
export const DocumentLibraryDialog: React.FC<Props> = ({
  documents,
  onCancel,
  onUseFile,
  ...modalProps
}) => {
  const [error, setError] = React.useState<Error>();
  const [selectedDocument, setSelectedDocument] = React.useState<
    ExistingDocument | undefined
  >();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isSaving, setSaving] = React.useState(false);

  const reset = useCallback(() => {
    setSaving(false);
    setSelectedDocument(undefined);
    setError(undefined);
  }, []);
  return (
    <Modal isCentered scrollBehavior="inside" {...modalProps}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Choose existing file</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onCancel();
            reset();
          }}
        />
        <ModalBody>
          <RadioGroup
            as={Flex}
            flexDirection="column"
            gap={2}
            value={selectedDocument?.documentId}
            onChange={(id) => {
              const document = documents.find((doc) => doc.documentId === id);
              setSelectedDocument(document);
            }}
          >
            {documents.map((document) => {
              return (
                <Card showBorder padding={4} key={document.documentId}>
                  <Radio value={document.documentId} isDisabled={isSaving}>
                    <Flex direction="column">
                      <Text color="gray.800">{document.filename}</Text>
                      {document.lastUsed && (
                        <Text fontSize="sm" color="gray.600">
                          Last uploaded on{" "}
                          {formatIsoDate(
                            document.lastUsed.at,
                            "MM/dd/yyyy z",
                            timezone
                          )}
                          {document.lastUsed.question && (
                            <>
                              {" "}
                              for{" "}
                              <QuestionOverflow
                                content={document.lastUsed.question.text}
                              />{" "}
                              question.
                            </>
                          )}
                        </Text>
                      )}
                    </Flex>
                  </Radio>
                </Card>
              );
            })}
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Flex direction="column" width="100%" gap={2}>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Oops! Something went wrong!</AlertTitle>
              </Alert>
            )}
            <Flex gap={2} justifyContent="right">
              <Button
                variant="ghost"
                onClick={() => {
                  onCancel();
                  reset();
                }}
                isDisabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                isLoading={isSaving}
                isDisabled={!selectedDocument}
                onClick={async () => {
                  if (selectedDocument) {
                    try {
                      setError(undefined);
                      setSaving(true);
                      await onUseFile(selectedDocument);
                      reset();
                    } catch (error) {
                      console.error(error);
                      setSaving(false);
                      if (error instanceof Error) {
                        setError(error);
                      }
                    }
                  }
                }}
              >
                Use file
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const MAX_LENGTH = 50;
const QuestionOverflow = (props: { content: string }) => {
  const { content } = props;
  if (content.length >= MAX_LENGTH) {
    return (
      <Tooltip label={content}>
        <span>"{content.substring(0, MAX_LENGTH - 1)}..."</span>
      </Tooltip>
    );
  }

  return <>"{content}"</>;
};
