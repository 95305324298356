import { ApolloError } from "@apollo/client";
import {
  Attributes,
  EvaluateRequestBuilder,
  FormEvaluateRequestBuilder,
} from "@avela/avela-authorization-sdk";
import {
  Alert,
  AlertIcon,
  CloseButton,
  Flex,
  List,
  ListItem,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { identity } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import { NotFound } from "src/components/Feedback/NotFound";
import { Section } from "src/components/Form/Section";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { FormStatusMenu } from "src/components/Menus/FormStatusMenu";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { FORM_AUTHORIZATION_SCOPE } from "src/components/Providers/AuthorizationProvider";
import { AdminHeading } from "src/components/Text/AdminHeading";
import { Glossary } from "src/components/Text/Glossary";
import { AttendanceTypes, FORM_STATUS } from "src/constants";
import { useAuthorizationApi } from "src/hooks/useAuthorizationApi";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useFormAnswers } from "src/hooks/useFormAnswers";
import { useFormTemplatePermissions } from "src/hooks/useFormTemplatePermissions";
import { useIsOfferEnabled } from "src/hooks/useIsOfferEnabled";
import { hasOrganizations, useOrganization } from "src/hooks/useOrganization";
import { usePreviousFormSchools } from "src/hooks/usePreviousFormSchools";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";
import useUser from "src/hooks/useUser";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as breadcrumb from "src/services/breadcrumb";
import { formatIsoDateToOrgDate, fullName } from "src/services/format";
import * as FormTemplate from "src/services/formTemplate";
import { Status } from "src/types/authData";
import { MultiSelectType, SingleSelectType } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { NextStepMenuSingle } from "../components/NextStepMenuSingle";
import { SetVisibilityMenuSingle } from "../components/SetVisibilityMenuSingle";
import { UPDATE_FORM_STATUS } from "../graphql/mutations";
import {
  FETCH_OFFER_WAITLIST_STATUS,
  GET_FORM_VIEW_BY_ID,
  GET_PREVIOUS_FORM_BY_FORM_ID,
  GET_SCHOOLS_RANK_VIEW,
  GET_VERIFICATIONS_FOR_ADMIN,
  GET_ASSIGNED_FORMS_BY_FORM_ID,
} from "../graphql/queries";
import { VerificationSummary } from "../verifications/VerificationSummary";
import { ExportMenu } from "./ExportMenu";
import { FormDetails } from "./FormDetails";
import { FormMessages } from "./FormMessages/FormMessages";
import { HistoryLog } from "./HistoryLog/HistoryLog";
import { RelatedForms } from "./RelatedForms";
import { StudentProfile } from "./StudentProfile";
import { CombinedData } from "./types";
import { GET_BASIC_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";
import { Hint } from "../components/Hint";

type FormPermissionsState = {
  attributes: Attributes;
  formPermissionsBuilder: EvaluateRequestBuilder | null;
};

function handleError(error: Error | ApolloError): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  return <GenericError />;
}

export const ViewForm: React.FC = () => {
  const { id } = useParams();
  const user = useUser();
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const { attributes } = useUserPermissions();
  const { evaluateWithPrincipalOverride } = useAuthorizationApi(
    FORM_AUTHORIZATION_SCOPE,
    id
  );

  // StudentForm creates a react-router Link with state as {showBirthdateWarning: true}
  // check this state and show the birthdate warning message
  const location = useLocation();
  const {
    isOpen: showBirthdateWarning,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });
  useEffect(() => {
    const state: any = location.state;
    if (state?.showBirthdateWarning) {
      onOpen();
    }
  }, [onOpen, location.state]);

  const toast = useAvelaToast();
  const { isSchoolAdmin, attendingSchoolIds } = useSchoolAdmin();
  const { formSchoolRankFilter } = useSchoolAdminPermissions();
  const { pruneFormTemplate } = useFormTemplatePermissions();
  const [editedSectionId, setEditedSectionId] = React.useState<
    string | undefined
  >(undefined);

  const { confirm, confirmationDialog, setBody, setHeader } =
    useConfirmationDialog({
      header: null,
      body: null,
      cancelButton: {
        label: "No, cancel",
      },
      confirmButton: {
        label: "Yes, change status",
        colorScheme: "red",
      },
      translate: true,
    });

  const skipQueries =
    !id || !organization.hasData() || user.status !== Status.OK;

  const { remoteData: baseFormRemoteData } = useRemoteDataQuery<
    GQL.GetFormViewById,
    GQL.GetFormViewByIdVariables
  >(GET_FORM_VIEW_BY_ID, {
    variables: {
      form_id: id ?? "",
      organization_id: organization.toNullable()?.id ?? "",
      is_admin: true,
    },
    skip: skipQueries,
  });
  const formRemoteData = useMemo(
    () =>
      baseFormRemoteData.map((data) => {
        if (!data.form_by_pk) return data;
        const attendingSchoolId =
          data.form_by_pk.form_attending_school?.school?.id;
        const attendanceContext =
          attendingSchoolId && attendingSchoolIds.includes(attendingSchoolId)
            ? AttendanceTypes.Attending
            : AttendanceTypes.Applying;
        return {
          ...data,
          form_by_pk: {
            ...data.form_by_pk,
            form_template: pruneFormTemplate(
              data.form_by_pk.form_template,
              attendanceContext
            ),
          },
        };
      }),
    [baseFormRemoteData, attendingSchoolIds, pruneFormTemplate]
  );

  const { remoteData: previousFormData } = useRemoteDataQuery<
    GQL.GetPreviousFormByFormId,
    GQL.GetPreviousFormByFormIdVariables
  >(GET_PREVIOUS_FORM_BY_FORM_ID, {
    variables: {
      form_id: id ?? "",
    },
    skip: !id,
  });

  const formTemplate = formRemoteData.hasData()
    ? formRemoteData.data.form_by_pk?.form_template
    : undefined;

  const formById =
    formRemoteData.hasData() && formRemoteData.data.form_by_pk
      ? formRemoteData.data.form_by_pk
      : undefined;

  const isOfferEnabled = useIsOfferEnabled(organization, formTemplate);

  const answersRemoteData = useFormAnswers(formById, id, skipQueries);

  const { remoteData: getSchoolsRankRemoteData, refetch: refetchSchoolsRank } =
    useRemoteDataQuery<GQL.GetSchoolsRankView, GQL.GetSchoolsRankViewVariables>(
      GET_SCHOOLS_RANK_VIEW,
      {
        variables: {
          form_id: id ?? "",
          skip_rank: isSchoolAdmin,
          enrollment_period_id: formTemplate?.enrollment_period_id ?? "",
          filter: formSchoolRankFilter(),
        },
        skip: skipQueries || !formTemplate?.enrollment_period_id,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-only",
      }
    );

  const { remoteData: verificationsRemoteData } = useRemoteDataQuery<
    GQL.GetVerificationsForAdmin,
    GQL.GetVerificationsForAdminVariables
  >(GET_VERIFICATIONS_FOR_ADMIN, {
    variables: { form_id: id ?? "" },
    skip: skipQueries,
  });

  const {
    remoteData: assignedFormsRemoteData,
    startPolling,
    stopPolling,
  } = useRemoteDataQuery<
    GQL.GetAssignedFormsByFormId,
    GQL.GetAssignedFormsByFormIdVariables
  >(GET_ASSIGNED_FORMS_BY_FORM_ID, {
    variables: { form_id: id ?? "" },
    skip: skipQueries,
    pollInterval: 10000,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      stopPolling();
    }, 600000); // @@@ WARNING - "STOP POLLING" MAY BE A PROBLEM FOR ADMINS
    return () => clearInterval(interval);
  }, [startPolling, stopPolling]);

  const previousFormSchoolIdsRemoteData = usePreviousFormSchools(id);

  const [UpdateFormStatus, { remoteData: updateFormStatusData }] =
    useRemoteDataMutation<GQL.UpdateFormStatus, GQL.UpdateFormStatusVariables>(
      UPDATE_FORM_STATUS
    );

  const [formPermissionsState, setFormPermissionsState] =
    React.useState<FormPermissionsState>({
      attributes: {},
      formPermissionsBuilder: null,
    });

  const { remoteData: formTemplateSummariesRemoteData } = useRemoteDataQuery<
    GQL.GetBasicFormTemplatesByEnrollmentPeriod,
    GQL.GetBasicFormTemplatesByEnrollmentPeriodVariables
  >(GET_BASIC_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period: formTemplate?.enrollment_period_id ?? "",
      search: {
        status_rules: {},
      },
    },
    skip: !formTemplate?.enrollment_period_id,
  });
  const [showAssignedFormHint, setShowAssignedFormHint] =
    React.useState<boolean>(false);

  const formData: RD.RemoteData<ApolloError | Error, CombinedData> =
    React.useMemo(
      () =>
        RD.toTuple5(
          formRemoteData,
          getSchoolsRankRemoteData,
          answersRemoteData,
          verificationsRemoteData,
          RD.toTuple4(
            previousFormData,
            previousFormSchoolIdsRemoteData,
            assignedFormsRemoteData,
            formTemplateSummariesRemoteData
          )
        )
          .mapError<ApolloError | Error>(identity)
          .andThen(
            ([
              data,
              schoolsRank,
              answers,
              verificationSummary,
              [
                previousForm,
                previousFormSchoolIds,
                assignedForm,
                formTemplateSummaries,
              ],
            ]) => {
              if (!data.form_by_pk?.form_template) {
                return RD.failure<ApolloError | Error, CombinedData>(
                  new Error()
                );
              }
              try {
                const formTemplate = FormTemplate.fromGQL(
                  data.form_by_pk?.form_template
                );
                const remoteData = RD.success<
                  ApolloError | Error,
                  CombinedData
                >({
                  form: data.form_by_pk,
                  previousForm: previousForm.form_by_pk,
                  sections: formTemplate.sections,
                  form_answers: answers.form_answer,
                  grades_answers: answers.grades_answer,
                  address_answers: answers.form_address,
                  custom_question_answers: answers.custom_question_answer,
                  custom_question_answer_bank_relationships:
                    data.form_by_pk.custom_question_answer_bank_relationships,
                  schoolsRank: schoolsRank.form_school_rank,
                  tagGroups: schoolsRank.tag_group,
                  verificationSummary,
                  formDisclaimer: answers.form_disclaimer[0],
                  previousFormSchoolIds,
                  assignedForms: assignedForm.assigned_form,
                  formTemplateSummaries: formTemplateSummaries.form_template,
                });

                const formPermissionsBuilder = new FormEvaluateRequestBuilder({
                  formTemplateKey: data.form_by_pk.form_template.key,
                  metadata: {
                    applyingSchoolIds: schoolsRank.form_school_rank.map(
                      (rank) => rank.school.id
                    ),
                    currentSchoolId:
                      data.form_by_pk.form_attending_school?.school?.id ??
                      "None",
                  },
                });

                for (const section of formTemplate.sections) {
                  if (!section) continue;
                  formPermissionsBuilder.buildFormSectionEdit({
                    sectionKey: section.key || section.id,
                    attributes: {
                      sectionType: section.type,
                    },
                  });
                  if (!("questions" in section)) continue;
                  for (const question of section.questions) {
                    formPermissionsBuilder.buildFormQuestionEdit(
                      {
                        sectionKey: section.key || section.id,
                        questionKey: question.key || question.id,
                        attributes: {
                          questionType: question.type,
                        },
                      },
                      {
                        sectionType: section.type,
                      }
                    );
                    // nested questions
                    if (
                      question.type === MultiSelectType ||
                      question.type === SingleSelectType
                    ) {
                      for (const option of question.options) {
                        if (!option.additionalQuestions) continue;
                        for (const additionalQuestion of option.additionalQuestions) {
                          formPermissionsBuilder.buildFormQuestionEdit(
                            {
                              sectionKey: section.key || section.id,
                              questionKey:
                                additionalQuestion.key || additionalQuestion.id,
                              attributes: {
                                questionType: additionalQuestion.type,
                              },
                            },
                            {
                              sectionType: section.type,
                            }
                          );
                        }
                      }
                    }
                  }
                }

                setFormPermissionsState({
                  attributes,
                  formPermissionsBuilder,
                });

                return remoteData;
              } catch (error: unknown) {
                console.error(error);
                return RD.failure<ApolloError | Error, CombinedData>(
                  error as Error
                );
              }
            }
          ),
      [
        formRemoteData,
        getSchoolsRankRemoteData,
        answersRemoteData,
        verificationsRemoteData,
        previousFormData,
        previousFormSchoolIdsRemoteData,
        assignedFormsRemoteData,
        formTemplateSummariesRemoteData,
        attributes,
      ]
    );

  // evaluate the form permissions with principal override
  // outside of the initial render
  useEffect(() => {
    const { attributes, formPermissionsBuilder } = formPermissionsState;
    if (formPermissionsBuilder) {
      evaluateWithPrincipalOverride(attributes, formPermissionsBuilder);
    }
  }, [formPermissionsState, evaluateWithPrincipalOverride]);

  const handleOnSelectStatusChange = React.useCallback(
    async (value: GQL.form_status_enum) => {
      setHeader(
        <Text>
          <Glossary>{`${FORM_STATUS[value].statusDialogHeader}`}</Glossary>
        </Text>
      );
      setBody(
        <Text>
          <Glossary>{`${FORM_STATUS[value].statusDialogDescription}`}</Glossary>
        </Text>
      );

      const createExtraOptions = () => {
        if (value === GQL.form_status_enum.Submitted) {
          return {
            submitted_at: "now()",
            deleted_at: null,
            submitted_before: true,
          };
        }

        if (value === GQL.form_status_enum.Deleted) {
          return { deleted_at: "now()", submitted_at: null };
        }

        if (
          value === GQL.form_status_enum.Verified ||
          value === GQL.form_status_enum.LotteryReady
        )
          return { deleted_at: null };

        return { submitted_at: null, deleted_at: null };
      };

      if (await confirm()) {
        try {
          if (!id) return;

          await UpdateFormStatus({
            variables: {
              form_id: id,
              skipOfferWaitlist: value !== GQL.form_status_enum.Cancelled,
              set: {
                status: value,
                source: null,
                ...createExtraOptions(),
              },
            },
            refetchQueries: [
              GET_FORM_VIEW_BY_ID,
              FETCH_OFFER_WAITLIST_STATUS,
              GET_SCHOOLS_RANK_VIEW,
            ],
          });

          toast({ title: "Form status updated" });
        } catch (err) {
          toast.error({ title: "Error updating form status" });
        }
      }
    },
    [UpdateFormStatus, id, toast, confirm, setHeader, setBody]
  );

  const hasEditStatusPermissionAdmins = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);

  // hard-coding to allow school admin edit permissions for some organization(s)
  // TODO: remove when custom user roles allow for school admin to configure more granular form edit permissions
  const hasEditStatusPermissionSchoolAdmin =
    useRequiredHasuraRoles([HasuraRole.SCHOOL_ADMIN]) &&
    hasOrganizations(
      organization,
      "saisd",
      "metco",
      "tulsa",
      "garlandisd",
      "nolaps",
      "newarkcommonapp",
      "phillyprek",
      "philasd",
      "bezosacademy",
      "enrollwcc",
      "oaklandenrolls",
      "aimsk12",
      "efcps",
      "wcsdschoolofchoice",
      "enrollbuffalocharters",
      "leadps",
      "schoolappstl",
      "schoolappkc",
      "pisota",
      "rvla",
      "evcs",
      "tapestry",
      "buffsci",
      "kccs",
      "enterprise",
      "primaryhall",
      "platoacademy"
    );

  const hasEditStatusPermission =
    hasEditStatusPermissionAdmins || hasEditStatusPermissionSchoolAdmin;

  if (!id) return <NotFound />;

  return (
    <RemoteDataView
      remoteData={formData}
      error={handleError}
      loading={<Loading />}
    >
      {({
        form,
        previousForm,
        grades_answers,
        address_answers,
        custom_question_answers,
        form_answers,
        custom_question_answer_bank_relationships,
        sections,
        schoolsRank,
        tagGroups,
        verificationSummary,
        formDisclaimer,
        previousFormSchoolIds,
        assignedForms,
        formTemplateSummaries,
      }) => {
        const statusUpdatedDate = form.status_updated_at
          ? formatIsoDateToOrgDate(form.status_updated_at, organization)
          : "";

        return !form ? (
          <NotFound />
        ) : (
          <Flex direction="column" gap={8}>
            <Breadcrumb
              items={breadcrumb.form.getBreadcrumbsForView(
                organization,
                form,
                searchParams.toString()
              )}
            />
            <Hint
              header="Assigning form"
              isOpen={showAssignedFormHint}
              body="Form assignments may appear in the form detail section after an offer is accepted."
              onClose={() => {
                setShowAssignedFormHint(false);
              }}
            ></Hint>
            <AdminHeading
              upperTitle={form.form_template.enrollment_period.name}
              title={`${fullName(form.person)}'s response`}
              id={id}
              actionButton={
                <Flex>
                  <WithUserPermissions permissions={["form:read"]}>
                    <ExportMenu
                      formData={formData}
                      enrollmentPeriodId={
                        form.form_template.enrollment_period_id
                      }
                    />
                  </WithUserPermissions>
                </Flex>
              }
              actionBar={
                <Flex gap={2} alignItems="center">
                  <StatusTag status={FORM_STATUS[form.status]} />
                  <Text color="gray.500">
                    {statusUpdatedDate &&
                      `Status last changed: ${statusUpdatedDate}`}
                  </Text>

                  <WithUserPermissions permissions={["form:update"]}>
                    {hasEditStatusPermission && (
                      <>
                        <FormStatusMenu
                          isLoading={updateFormStatusData.isLoading()}
                          formStatus={form.status}
                          label="Set to"
                          onSelect={handleOnSelectStatusChange}
                        />
                        <NextStepMenuSingle form={form} />

                        <SetVisibilityMenuSingle
                          formVisibility={form.is_hidden_from_parent}
                          formId={form.id}
                        />
                      </>
                    )}
                  </WithUserPermissions>
                </Flex>
              }
            />
            <Flex alignItems="flex-start" gap={4}>
              <Flex direction="column" flexGrow="1" minWidth="32rem">
                <Tabs defaultIndex={0} isLazy>
                  <TabList>
                    <Tab>
                      <Text textTransform="capitalize">
                        {form.form_template.name}
                      </Text>
                    </Tab>
                    <WithUserPermissions permissions={["message:read"]}>
                      <Tab>Messages</Tab>
                    </WithUserPermissions>
                    <WithRequiredHasuraRoles
                      roles={[
                        HasuraRole.ADMIN,
                        HasuraRole.ORG_ADMIN,
                        HasuraRole.DISTRICT_ADMIN,
                      ]}
                    >
                      <WithUserPermissions permissions={["form:read"]}>
                        <Tab>History</Tab>
                      </WithUserPermissions>
                    </WithRequiredHasuraRoles>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {showBirthdateWarning && (
                        <Alert status="warning" marginBottom="1.5rem">
                          <AlertIcon />
                          <VStack alignItems="flex-start">
                            <Text>
                              Date of birth has changed. Please ensure the
                              following are correct:
                            </Text>
                            <List>
                              <ListItem>- Form grade</ListItem>
                              <ListItem>
                                <Glossary>- School choices</Glossary>
                              </ListItem>
                              <ListItem>
                                <Glossary>- School-specific questions</Glossary>
                              </ListItem>
                            </List>
                          </VStack>
                          <Spacer />
                          <CloseButton onClick={onClose} />
                        </Alert>
                      )}
                      {previousForm && (
                        <FormDetails
                          form={previousForm}
                          assignedForms={assignedForms}
                        />
                      )}
                      {sections.map((section) =>
                        section ? (
                          <Section
                            previousFormSchoolIds={previousFormSchoolIds}
                            key={section.id}
                            form={form}
                            allSections={sections}
                            section={section}
                            form_answers={form_answers}
                            grades_answers={grades_answers}
                            address_answers={address_answers}
                            custom_question_answers={custom_question_answers}
                            custom_question_answer_bank_relationships={
                              custom_question_answer_bank_relationships
                            }
                            formDisclaimer={formDisclaimer}
                            schoolsRank={schoolsRank}
                            tagGroups={tagGroups}
                            onEdit={setEditedSectionId}
                            onCloseEdit={() => setEditedSectionId(undefined)}
                            onRefetchSchoolsRank={refetchSchoolsRank}
                            editMode={
                              editedSectionId === undefined
                                ? "view"
                                : editedSectionId === section.id
                                ? "edit"
                                : "disabled"
                            }
                            isOfferEnabled={isOfferEnabled}
                            onOffersAccepted={() => {
                              if (formTemplateSummaries.length > 0)
                                setShowAssignedFormHint(true);
                            }}
                          />
                        ) : null
                      )}
                    </TabPanel>

                    <WithUserPermissions permissions={["message:read"]}>
                      <TabPanel>
                        <FormMessages form={form} />
                      </TabPanel>
                    </WithUserPermissions>
                    <WithRequiredHasuraRoles
                      roles={[
                        HasuraRole.ADMIN,
                        HasuraRole.ORG_ADMIN,
                        HasuraRole.DISTRICT_ADMIN,
                      ]}
                    >
                      <WithUserPermissions permissions={["form:read"]}>
                        <TabPanel>
                          <HistoryLog formId={form.id} />
                        </TabPanel>
                      </WithUserPermissions>
                    </WithRequiredHasuraRoles>
                  </TabPanels>
                </Tabs>
              </Flex>
              <Flex direction="column" gap={4} width="35%">
                <StudentProfile form={form} />
                {previousForm && <RelatedForms form={previousForm} />}
                <VerificationSummary
                  verificationSummary={verificationSummary}
                />
              </Flex>
            </Flex>

            {confirmationDialog}
          </Flex>
        );
      }}
    </RemoteDataView>
  );
};
