import React, { useCallback } from "react";
import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import { ConfigureMatchDialog, MatchType } from "./ConfigureMatchDialog";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";

type ConfigureMatchButtonProps = ButtonProps & {
  editMode?: boolean;
  onMatchTypeChange?: (matchType: MatchType) => void;
};
export const ConfigureMatchButton: React.FC<ConfigureMatchButtonProps> = ({
  editMode,
  onMatchTypeChange,
  ...buttonProps
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const organization = useOrganization();

  const handleNextClick = useCallback(
    (matchType: MatchType) => {
      if (onMatchTypeChange) {
        onMatchTypeChange(matchType);
      } else {
        navigate(
          organization
            .map((org) => OrgAdmin.Match.configure(org))
            .withDefault("#"),
          { state: { matchType } }
        );
      }

      onClose();
    },
    [navigate, organization, onClose, onMatchTypeChange]
  );

  return (
    <>
      <Button {...buttonProps} onClick={onOpen}>
        {editMode ? "Edit" : "Configure match"}
      </Button>

      <ConfigureMatchDialog
        isOpen={isOpen}
        onNextButtonClick={handleNextClick}
        onCancelButtonClick={onClose}
      />
    </>
  );
};
