import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import * as breadcrumb from "src/services/breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { useFlags } from "flagsmith/react";
import { IconType } from "react-icons";

const WHAT_IS_IT = `The potential duplicates report shows potential duplicate Students when viewing a Student in your organization. It is meant to be a quick view into where the Student may have duplicated accounts.`;
const HOW_DO_I_USE_IT = `When activated, potential duplicates are shown on a subsection of each individual Student account page. These potential duplicates are shown based on similarity in name, address, birthdate and other datapoints. It is not guaranteed they are duplicates - but it is a starting point when searching for duplicated user accounts in the system. Navigate to a Student Profile to view the Potential Duplicate section.`;
const WHY_IS_THIS_IN_LABS = `Duplicates are hard to reconcile and we are still working through building workflows to help. This listing is meant to easily identify potential duplicates but we are still looking for feedback on what additional features would be most useful for duplicate reconciliation. Please help by letting us know what would be really useful to add to this feature to make it even more useful for you!`;
type StatusIndicatorProps = {
  icon: IconType;
  iconColor: string;
  label: string;
};
const StatusIndicator = ({ icon, iconColor, label }: StatusIndicatorProps) => {
  return (
    <Flex direction="row" align="center" gap={2}>
      <Text fontSize="lg" fontWeight="bold">
        {label}
      </Text>
      <Icon as={icon} color={iconColor} aria-hidden="true" w="8" h="8"></Icon>
    </Flex>
  );
};

export const DuplicateStudentReport = () => {
  const organization = useOrganization();
  const enabled: boolean = useFlags(["avela-labs-possible-student-duplicates"])[
    "avela-labs-possible-student-duplicates"
  ].enabled;

  return (
    <Flex direction="column" gap={6} height="100%">
      <Breadcrumb
        items={breadcrumb.avelaLabs.getBreadcrumbsForAvelaLabs(organization)}
      />

      <Flex direction="row" gap={3} width="100%" justify="space-between">
        <Text fontSize="3xl" fontWeight="bold">
          Duplicate Student Report
        </Text>

        {enabled ? (
          <StatusIndicator
            icon={RiCheckboxCircleFill}
            iconColor="green.500"
            label={"Lab active"}
          ></StatusIndicator>
        ) : (
          <StatusIndicator
            icon={RiCloseCircleFill}
            iconColor="red.500"
            label={
              "Reach out to your client services representative to activate"
            }
          ></StatusIndicator>
        )}
      </Flex>

      <Text fontSize="2xl" fontWeight="medium">
        What is it?
      </Text>
      <Text>{WHAT_IS_IT}</Text>

      <Flex direction="row" gap={3} width="100%" justify="space-between">
        <Text fontSize="2xl" fontWeight="medium">
          How do I use it?
        </Text>
      </Flex>
      <Text>{HOW_DO_I_USE_IT}</Text>

      <Flex direction="row" gap={3} width="100%" justify="space-between">
        <Text fontSize="2xl" fontWeight="medium">
          Why is this in Labs?
        </Text>
        <Button
          flexShrink={0}
          onClick={() =>
            window.open("https://avelahelp.zendesk.com/hc/en-us", "_blank")
          }
        >
          Submit Feedback
        </Button>
      </Flex>
      <Text>{WHY_IS_THIS_IN_LABS}</Text>
    </Flex>
  );
};
