import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import * as Env from "../services/env";
import { AuthData, loading, ok, unauthenticated } from "../types/authData";
import { useOrganizationPath } from "./useOrganizationPath";

type Token = AuthData<string>;
export default function useAccessToken(): Token {
  const [accessToken, setAccessToken] = React.useState<Token>(loading);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const avelaOrganization = useOrganizationPath();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const env = Env.read();
        const audience = env.REACT_APP_HASURA_URL;
        const accessToken = await getAccessTokenSilently({
          audience,
          avelaOrganization,
        });
        setAccessToken(ok(accessToken));
      } catch (e) {
        console.warn(e);
      }
    };

    getAccessToken();

    // Only execute this effect once on mount to avoid calling getAccessTokenSilently excessively, causing race condition with logout flow.
    // Both `avelaOrganization` and `getAccessTokenSilently` are available at mount and the value shouldn't change without re-mounting the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return loading;
  if (!isAuthenticated) return unauthenticated;

  return accessToken;
}
