import { Button, Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FunctionComponent, useCallback, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AccountLookupStep, StepProps } from "./Step";
import { FormikAccountLookup } from "./helpers";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

export const SolveCaptchaStep: FunctionComponent<StepProps> = () => {
  const [, { error, touched }, helpers] = useField("captchaToken");
  const { isSubmitting } = useFormikContext<FormikAccountLookup>();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const { state: formState, setStepName } = useAccountLookupState();

  useEffect(() => {
    setStepName(SolveCaptchaStep.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire setStepName on component load

  const handleOnCaptchaChange = useCallback(
    async (token: string | null) => {
      if (token) {
        amplitude.track("Account Lookup Captcha Solved", formState);
        helpers.setValue(token);
        helpers.setTouched(true);
      } else {
        amplitude.track("Account Lookup Captcha Not Solved", formState);
      }
    },
    [helpers, formState]
  );

  return (
    <AccountLookupStep
      heading="Complete the CAPTCHA"
      description="The last step before we try to find your account email or phone number!"
    >
      <Flex as={FormControl} direction="column" gap={4} alignItems="center">
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
          onChange={handleOnCaptchaChange}
        />
        {touched && error && <FormErrorMessage>{error}</FormErrorMessage>}
        <Button type="submit" width="100%" isLoading={isSubmitting}>
          Submit
        </Button>
      </Flex>
    </AccountLookupStep>
  );
};
