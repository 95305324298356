import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Flex, Heading, Icon, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { EmptyState } from "src/components/EmptyState";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { useOrganizationContacts } from "src/hooks/useOrganization";
import { ReactComponent as PaperPlanesSvg } from "src/images/paperplanes.svg";
import { formatPhoneNumberWithFallback } from "src/services/format";

export const Help: React.FC = () => {
  const organizationContacts = useOrganizationContacts();

  return (
    <ParentFormsLayout heading={"Help"}>
      <Flex
        direction="column"
        gap={5}
        bg="white"
        width="100%"
        maxW="600px"
        padding={6}
        alignItems="center"
        borderRadius="lg"
      >
        <VStack width="100%" spacing={0}>
          <EmptyState
            description="Get in touch with the enrollment team"
            heading="We're here to help"
            Svg={PaperPlanesSvg}
          />

          {organizationContacts
            .filter((contact) => contact.type === "phone")
            .map((contact) => (
              <VStack
                key={contact.value}
                align="stretch"
                width="100%"
                gap={0}
                spacing={1}
                paddingTop={2}
                paddingBottom={2}
              >
                <Text color="black" fontWeight="500" fontSize="sm">
                  {contact.label}
                </Text>
                <Link
                  fontSize="md"
                  href={`tel:${contact.value}`}
                  color="primary.500"
                  margin={0}
                >
                  {formatPhoneNumberWithFallback(contact.value)}
                </Link>
              </VStack>
            ))}
          {organizationContacts
            .filter((contact) => contact.type === "email")
            .map((contact) => (
              <VStack
                key={contact.value}
                align="stretch"
                width="100%"
                gap={0}
                spacing={1}
                paddingTop={2}
                paddingBottom={2}
              >
                <Text fontSize="sm">{contact.label}</Text>
                <Link
                  fontSize="md"
                  href={`mailto:${contact.value}`}
                  color="primary.500"
                >
                  {contact.value}
                </Link>
              </VStack>
            ))}
          <VStack
            gap={0}
            spacing={0}
            paddingTop={4}
            width="100%"
            align="stretch"
          >
            <Heading
              as="h5"
              fontSize="1.125rem"
              fontWeight="500"
              paddingBottom={3}
            >
              Looking for more information?
            </Heading>
            {organizationContacts
              .filter((contact) => contact.type === "link")
              .map((contact) => (
                <VStack
                  key={contact.value}
                  align="stretch"
                  width="100%"
                  gap={0}
                  spacing={1}
                  paddingTop={1}
                  paddingBottom={1}
                >
                  <Link
                    fontSize="md"
                    href={contact.value}
                    color="primary.500"
                    isExternal
                  >
                    {contact.label}
                    <Icon marginLeft={1} as={ExternalLinkIcon} />
                  </Link>
                </VStack>
              ))}
          </VStack>
        </VStack>
      </Flex>
    </ParentFormsLayout>
  );
};
