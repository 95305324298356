import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import { LexicalEditor } from "lexical";
import Editor, { EditorRef } from "./Editor";
import { Settings } from "./context/SettingsContext/settings";

type Props = {
  name: string;
  width?: string;
  height?: string;
  label?: string;
  labelProps?: FormLabelProps;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  editorRef?: EditorRef;
  settings?: Partial<Settings>;
  htmlContent?: string;
};

const FormikLexicalEditor = ({
  name,
  width,
  height,
  label,
  labelProps,
  placeholder,
  isDisabled = false,
  isRequired = false,
  isReadOnly = false,
  editorRef,
  settings,
  htmlContent,
}: Props) => {
  const validate = (value: string) => {
    const lexicalState = JSON.parse(value);

    if (
      isRequired &&
      (!lexicalState.root?.children ||
        lexicalState.root.children.length === 0 ||
        (lexicalState.root.children.length === 1 &&
          lexicalState.root.children[0]?.children?.length === 0))
    ) {
      return "This field is required";
    }
    return undefined;
  };

  const handleOnBlur = (editor: LexicalEditor, formik: FieldProps) => {
    formik.form.setFieldTouched(name, true, false);
    formik.form.setFieldValue(
      name,
      JSON.stringify(editor.getEditorState().toJSON())
    );
  };

  const handleOnChange = (editor: LexicalEditor, formik: FieldProps) => {
    formik.form.setFieldValue(
      name,
      JSON.stringify(editor.getEditorState().toJSON()),
      false
    );
  };

  return (
    <Field name={name} validate={validate}>
      {(formik: FieldProps) => {
        return (
          <FormControl
            isRequired={isRequired}
            isInvalid={!!formik.meta.error && formik.meta.touched}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
          >
            {label && <FormLabel {...labelProps}>{label}</FormLabel>}
            <Editor
              id={name}
              name={name}
              editorState={formik.field.value}
              htmlContent={htmlContent}
              placeholder={placeholder}
              height={height}
              width={width}
              isReadOnly={isReadOnly || isDisabled}
              onBlur={(editor) => handleOnBlur(editor, formik)}
              onChange={(editor) => handleOnChange(editor, formik)}
              editorRef={editorRef}
              settings={settings}
            />
            {!formik.form.isValid && (
              <FormErrorMessage>{formik.meta.error}</FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormikLexicalEditor;
