import { Button, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";

interface ShowPublicViewButtonProps {
  id: string;
}

export const ShowPublicViewButton = ({ id }: ShowPublicViewButtonProps) => {
  const organization = useOrganization();
  const navigate = useNavigate();

  const {
    confirm: confirmShowPublicView,
    confirmationDialog: showPublicViewConfirmationDialog,
  } = useConfirmationDialog({
    header: "Show public view",
    body: (
      <Text>
        Showing the public match view will display the results of this match
        without any identifying information. You can exit this view at any time.
      </Text>
    ),
    cancelButton: {
      label: "Cancel",
    },
    confirmButton: {
      label: "Show public view",
      colorScheme: "primary",
    },
    translate: true,
  });

  const handleShowPublicViewClick = useCallback(async () => {
    if (!(await confirmShowPublicView())) {
      return;
    }

    navigate(
      organization
        .map((org) => OrgAdmin.Match.publicView(org, id))
        .withDefault("#")
    );
  }, [confirmShowPublicView, id, navigate, organization]);

  return (
    <>
      <Button
        aria-label="Match public view"
        onClick={handleShowPublicViewClick}
      >
        Public view
      </Button>
      {showPublicViewConfirmationDialog}
    </>
  );
};
