import React, { useEffect } from "react";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";

// why is camelCase mixed with snake_case?
// this state gets attached to a JSON payload on amplitude.track. JSON fields seem to prefer snake_case.
// camelCase seems to be preferred in TS cod.
export type State = {
  account_lookup_step_name: string;
  organization_path: string;
};

export type SetStepNameFn = (stepName: string) => void;
export type SetOrgPathFn = (orgPath: string) => void;

export const INITIAL_STATE: State = {
  account_lookup_step_name: "",
  organization_path: "",
};

export type Payload = {
  state: State;
  setStepName: SetStepNameFn;
  setOrgPath: SetOrgPathFn;
};

export const Context = React.createContext<Payload | undefined>(undefined);

export type Props = { children: React.ReactNode };

export const ContextProvider = ({ children }: Props) => {
  const [state, setState] = React.useState<State>(INITIAL_STATE);
  const path = useOrganizationPath();

  const setOrgPath = (orgPath: string) => {
    setState((prevState) => ({
      ...prevState,
      organization_path: orgPath,
    }));
  };

  const setStepName = (stepName: string) => {
    setState((prevState) => ({
      ...prevState,
      account_lookup_step_name: stepName,
    }));
  };

  useEffect(() => {
    setOrgPath(path || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]); // just fire useEffect when orgPath changes

  return (
    <Context.Provider
      value={{ state: state, setStepName: setStepName, setOrgPath: setOrgPath }}
    >
      {children}
    </Context.Provider>
  );
};

export const useState = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      "[src/scenes/public/accountLookup/Context.ts]: useState must be used within a ContextProvider"
    );
  }
  return context;
};
