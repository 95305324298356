import { FunctionComponent, useEffect } from "react";
import { SuccessAccountLookupResponse } from "../helpers";
import { OneOptionSignUp } from "./OneOptionSignUp";
import { TwoOptionSignUp } from "./TwoOptionSignUp";
import * as OrgConfig from "@avela/organization-config-sdk";
import * as amplitude from "@amplitude/analytics-browser";
import { useState as useAccountLookupState } from "src/scenes/public/accountLookup/Context";

export type CreateAnAccountProps = Omit<
  SuccessAccountLookupResponse,
  "parentGuardianExists | auth0UserType"
> & { config: OrgConfig.AccountLookup.Config };

export const CreateAnAccount: FunctionComponent<CreateAnAccountProps> = (
  props
) => {
  const { emailAddress, phoneNumber } = props;

  const { state: formState } = useAccountLookupState();

  useEffect(() => {
    amplitude.track("Account Lookup Create an Account Started", formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire the amplitude.track when the component first renders

  if (emailAddress && phoneNumber) {
    return <TwoOptionSignUp {...props} />;
  }

  return <OneOptionSignUp {...props} />;
};
