import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { BreadcrumbType, getBreadcrumbsForHome } from ".";
import * as Url from "../url";

export const getBreadcrumbsForList = (
  organization: RD.RemoteData<unknown, Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForHome(organization),
    {
      label: "Match",
      href: organization
        .map((org) => Url.OrgAdmin.Match.index(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForConfigure = (
  organization: RD.RemoteData<unknown, Organization>
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Configure",
      href: organization
        .map((org) => Url.OrgAdmin.Match.configure(org))
        .withDefault("#"),
    },
  ];
};

export const getBreadcrumbsForDetails = (
  organization: RD.RemoteData<unknown, Organization>,
  matchId: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(organization),
    {
      label: "Details",
      href: organization
        .map((org) => Url.OrgAdmin.Match.details(org, matchId))
        .withDefault("#"),
    },
  ];
};
