import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressMeter } from "src/components/Feedback/ProgressMeter";
import { useMatchResults } from "src/hooks/useMatchService";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as ClipboardHappy } from "src/images/clipboard-happy.svg";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { MatchRunError } from "./components/MatchRunError";

export const MatchProgress = () => {
  const { matchId } = useParams<{ matchId: string }>();
  const navigate = useNavigate();
  const organization = useOrganization().withDefault(null);
  const { matchRunResults, startPolling, matchProgress, matchRunError } =
    useMatchResults(matchId);

  useEffect(() => {
    if (matchId) startPolling();
  }, [matchId, startPolling]);

  const matchName = matchRunResults.toNullable()?.match_run_by_pk?.name ?? "";

  useEffect(() => {
    if (
      !matchRunResults.isLoading() &&
      matchRunResults.hasData() &&
      organization
    ) {
      const status = matchRunResults.data.match_run_by_pk?.status;
      if (
        status === GQL.match_run_status_enum.Completed &&
        organization &&
        matchId
      ) {
        navigate(Url.OrgAdmin.Match.details(organization, matchId));
      }
    }
  }, [matchRunResults, navigate, organization, matchId]);

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={3}>
      <Heading as="h1" size="lg" textAlign="left">
        {matchName}&nbsp;
      </Heading>
      {matchRunError ? (
        <MatchRunError errorMessage={matchRunError} />
      ) : (
        <Flex
          alignItems="center"
          flexDirection="column"
          gap={8}
          justifyContent="center"
          width="100%"
          minHeight="70vh"
        >
          <ClipboardHappy />
          <Flex alignItems="center" gap={2}>
            <Heading as="h6">Running match</Heading>
          </Flex>
          <Text
            variant="body2"
            color="text.secondary"
            textAlign="center"
            maxWidth="300px"
          >
            Hang tight! We are running the match with the selected applicants
          </Text>
          <ProgressMeter
            value={(matchProgress ?? 0) * 100}
            size="lg"
            maxWidth="400px"
            stripeAtFull
          />
        </Flex>
      )}
    </Box>
  );
};
