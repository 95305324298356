import * as GQL from "../types/graphql";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import { GET_MATCH_CONFIGURATION } from "src/scenes/orgAdmin/match/graphql/queries";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";

export function useMatchConfiguration() {
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();

  const { remoteData: matchConfigRemoteData } = useRemoteDataQuery<
    GQL.GetMatchConfiguration,
    GQL.GetMatchConfigurationVariables
  >(GET_MATCH_CONFIGURATION, {
    variables: {
      enrollmentPeriodId: selectedEnrollmentPeriod?.id ?? "",
    },
    skip: !selectedEnrollmentPeriod?.id,
  });
  const isLoading = matchConfigRemoteData.isLoading();
  const hasError = matchConfigRemoteData.hasError();
  const matchConfig = matchConfigRemoteData.hasData()
    ? matchConfigRemoteData.data.match_config[0]
    : null;
  return { isLoading, hasError, matchConfig };
}
