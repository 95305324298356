import { useEffect, useState } from "react";

type GetInterval = (previousInterval?: number) => number;

/**
 * Returns an interval that starts at `baseInterval`, and exponentially
 * increases by `multiplier` each iteration, up to a maximum of `maxInterval`.
 */
export const exponentialBackoff =
  (baseInterval: number, multiplier = 2, maxInterval = Infinity): GetInterval =>
  (previous) =>
    previous ? Math.min(previous * multiplier, maxInterval) : baseInterval;

export function useDynamicPolling(
  callback: () => void,
  getInterval: GetInterval
) {
  const [poller] = useState(() => createDynamicPoller(callback, getInterval));

  useEffect(() => poller.setCallback(callback), [poller, callback]);
  useEffect(() => poller.setGetInterval(getInterval), [poller, getInterval]);

  useEffect(() => poller.stopPolling, [poller]);

  return poller;
}

function createDynamicPoller(callback: () => void, getInterval: GetInterval) {
  let pollInterval = getInterval();
  let lastPollTime = -Infinity;
  let timeoutRef: number | NodeJS.Timeout | undefined;

  function isPolling() {
    return timeoutRef !== undefined;
  }

  function poll() {
    callback();
    lastPollTime = Date.now();
    scheduleNextPoll();
  }

  function scheduleNextPoll() {
    // Clear the previous timeout to ensure that we never have more than one
    // running at a time.
    clearTimeout(timeoutRef);
    const remainingTime = Math.max(lastPollTime + pollInterval - Date.now(), 0);
    timeoutRef = setTimeout(poll, remainingTime);
    pollInterval = getInterval(pollInterval);
  }

  function startPolling() {
    if (isPolling()) return;
    poll();
  }

  function stopPolling() {
    clearTimeout(timeoutRef);
    timeoutRef = undefined;
  }

  function resetPollInterval(baseInterval = getInterval()) {
    pollInterval = baseInterval;
    if (isPolling()) scheduleNextPoll();
  }

  return {
    setCallback(value: () => void) {
      callback = value;
    },
    setGetInterval(value: GetInterval) {
      getInterval = value;
    },
    isPolling,
    startPolling,
    stopPolling,
    resetPollInterval,
  } as const;
}
