import { Flex, Heading, Link, Text } from "@chakra-ui/layout";
import React from "react";
import { EmptyMatchConfig } from "./components/EmptyMatchConfig";
import { Button, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { OrgAdmin } from "src/services/url";
import {
  useOrderByParams,
  usePaginationParams,
} from "src/hooks/useCommonSearchParams";
import { usePaginatedRemoteDataQuery } from "src/hooks/usePaginatedRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_MATCH_RUNS } from "./graphql/queries";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { ColumnDef } from "@tanstack/react-table";
import { formatIsoDateToOrg } from "src/services/format";
import * as Url from "src/services/url";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import * as breadcrumb from "src/services/breadcrumb";

function createElevatedSchoolAdminFilter(
  applyingSchoolIds: string[],
  attendingSchoolIds: string[]
) {
  return {
    _not: {
      schools: {
        school_id: {
          _nin: [...applyingSchoolIds, ...attendingSchoolIds],
        },
      },
    },
  };
}
export const Index = (): React.ReactElement => {
  const organization = useOrganization();
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const navigate = useNavigate();
  const { orderBy, setOrderBy } = useOrderByParams({
    defaultOrderBy: { sortKey: "created_at", sortType: GQL.order_by.desc },
  });

  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();

  const { pagination, setPagination } = usePaginationParams();
  const { usePaginatedQuery } = usePaginatedRemoteDataQuery<
    GQL.GetMatchRuns,
    GQL.GetMatchRunsVariables
  >(GET_MATCH_RUNS, {
    enrollmentPeriodId: selectedEnrollmentPeriod?.id ?? "",
    limit: pagination.limit,
    offset: pagination.offset,
    order_by: [orderBy ? { [orderBy.sortKey]: orderBy.sortType } : {}],
    school_filter: isElevatedSchoolAdmin
      ? createElevatedSchoolAdminFilter(applyingSchoolIds, attendingSchoolIds)
      : {},
  });
  const { remoteData } = usePaginatedQuery({
    skip: !selectedEnrollmentPeriod,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {({ match_run }) => {
        const columns: ColumnDef<GQL.GetMatchRuns_match_run>[] = [
          {
            id: "name",
            header: "Match name",
            cell: (props) => (
              <Link
                color="primary.500"
                href={organization
                  .map((org) =>
                    Url.OrgAdmin.Match.details(org, props.row.original.id)
                  )
                  .withDefault("#")}
                textDecoration="underline"
              >
                {props.row.original.name ?? "Match"}
              </Link>
            ),
          },
          {
            id: "created_at",
            header: "Date run",
            cell: (props) =>
              formatIsoDateToOrg(
                props.row.original.created_at,
                organization,
                "MM/dd/yyyy"
              ),
          },
          {
            id: "status",
            header: "Status",
            cell: (props) => (
              <Tooltip label={props.row.original.status_message ?? ""}>
                <Text cursor="default">{props.row.original.status}</Text>
              </Tooltip>
            ),
          },
          {
            id: "owner",
            header: "Run by",
            cell: (props) =>
              props.row.original.owner?.people.length > 0
                ? props.row.original.owner?.people[0]?.full_name
                : "Avela admin",
          },
        ];

        return (
          <Flex direction="column">
            <Breadcrumb
              items={breadcrumb.match.getBreadcrumbsForList(organization)}
              mb={4}
            />
            <Flex justifyContent="space-between" alignItems="center">
              <Heading as="h1" size="xl" fontWeight="semibold" color="gray.700">
                Match
              </Heading>
              <WithUserPermissions
                permissions={[
                  "match.configuration:create",
                  "match.configuration:read",
                ]}
              >
                <Button
                  onClick={() =>
                    navigate(
                      organization
                        .map((org) => OrgAdmin.Match.configure(org))
                        .withDefault("#")
                    )
                  }
                >
                  Configure match
                </Button>
              </WithUserPermissions>
            </Flex>

            {match_run.length === 0 ? (
              <EmptyMatchConfig />
            ) : (
              <Flex mt={10}>
                <PaginatedTable<GQL.GetMatchRuns_match_run>
                  columns={columns}
                  data={match_run}
                  limit={pagination.limit}
                  offset={pagination.offset}
                  count={match_run.length}
                  sortableColumnIds={["created_at"]}
                  onFetchMore={setPagination}
                  onChangeSort={setOrderBy}
                />
              </Flex>
            )}
          </Flex>
        );
      }}
    </GQLRemoteDataView>
  );
};
