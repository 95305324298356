import { useCallback, useState } from "react";

export function useDownloadProgress() {
  const [downloadProgress, setDownloadProgress] = useState(0);

  const onDownloadProgress = useCallback(
    ({ progress }: { progress?: number }) => {
      if (progress === undefined) return;
      setDownloadProgress((oldProgress) => Math.max(oldProgress, progress));
    },
    []
  );

  const resetDownloadProgress = useCallback(() => {
    setDownloadProgress(0);
  }, []);

  return { downloadProgress, onDownloadProgress, resetDownloadProgress };
}
