import { FormControl } from "@chakra-ui/form-control";
import { Flex, FlexProps, Grid } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { STATUS_ACTIVE, STATUS_CLOSED } from "src/constants";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import type { GlossaryFn } from "src/types/glossary";
import { HasuraRole } from "src/types/hasuraRole";
import { AddressForm } from "./AddressForm";

type Props = FlexProps & { glossary: GlossaryFn };
export const SchoolForm = ({ glossary, ...props }: Props) => {
  const isEditEnabled = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);
  return (
    <Flex direction="column" gap={4} {...props}>
      <Grid
        gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
        gap={3}
        alignItems="flex-end"
      >
        <InputControl
          inputProps={{ placeholder: glossary`E.g.: Lincoln School` }}
          name="name"
          label="Name"
          isRequired
          labelProps={{ requiredIndicator: <></> }}
        />
        <InputControl
          isRequired={false}
          inputProps={{ placeholder: "E.g.: 12345678" }}
          name="reference_id"
          id="reference_id"
          label="Reference ID (Optional)"
          isDisabled={!isEditEnabled}
        />
      </Grid>
      <AddressForm />

      <FormControl display="flex">
        <SwitchInput<string>
          label="Status"
          name="status"
          checked={STATUS_ACTIVE}
          unchecked={STATUS_CLOSED}
        />
      </FormControl>
    </Flex>
  );
};
