import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Link,
  Spinner,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { partition } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Glossary } from "src/components/Text/Glossary";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useDocumentStorage } from "src/hooks/useDocumentStorage";
import {
  FormSchoolId,
  useMatchResults,
  useMatchService,
} from "src/hooks/useMatchService";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as ClipboardSvg } from "src/images/clipboard-happy.svg";
import { ReactComponent as MagnifyingGlassSvg } from "src/images/magnifying-glass-avatars.svg";
import { ReactComponent as PaperAnalyticsSvg } from "src/images/paper-analytics.svg";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";
import { triggerDownloadFromUrl } from "src/services/dataTransfer";
import { formatIsoDateToOrg } from "src/services/format";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { FormSchool } from "../types";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";

enum Stage {
  Prompt,
  Running,
  Cancel,
  Download,
}

type RunMatchDialogProps = {
  schoolForms: FormSchool[];
  isOpen: boolean;
  onCancel: () => void;
};

export const RunMatchOldDialog = ({
  schoolForms,
  isOpen,
  onCancel,
}: RunMatchDialogProps) => {
  const [stage, setStage] = useState(Stage.Prompt);
  const [downloadUrl, setDownloadUrl] = useState("#");
  const [tagLabel, setTagLabel] = useState("");
  const organization = useOrganization();
  const toast = useAvelaToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();

  const [matchId, setMatchId] = useState("");
  const { startMatch } = useMatchService();
  const { matchRunResults, startPolling, cancelMatch } =
    useMatchResults(matchId);

  const { getDownloadUrl } = useDocumentStorage();

  useEffect(() => {
    setStage(Stage.Prompt);
  }, [isOpen]);

  const [formsWithSchools, formsWithoutSchools] = useMemo(
    () =>
      partition(
        schoolForms.map((sf) => ({
          formId: sf.form?.id,
          schoolId: sf.school?.id,
        })),
        (sf): sf is FormSchoolId => !!(sf.formId && sf.schoolId)
      ),
    [schoolForms]
  );

  const handleRunMatch = useCallback(async () => {
    if (!selectedEnrollmentPeriod?.id) {
      toast({
        title: "Error running match.",
        description: "No enrollment period selected.",
        status: "error",
        duration: null,
      });
      return;
    }
    const timestamp = formatIsoDateToOrg(
      new Date().toISOString(),
      organization,
      "MM/dd/yyyy h:mm:ssaaa"
    );
    const tagLabel = `Match ${timestamp}`;
    setTagLabel(tagLabel);
    try {
      const startMatchPromise = startMatch({
        formSchools: formsWithSchools,
        formsWithoutSchools: formsWithoutSchools
          .map((form) => form.formId)
          .filter(isNotNull),
        tagLabel,
        enrollmentPeriodId: selectedEnrollmentPeriod.id,
      });
      setStage(Stage.Running);
      const matchRun = await startMatchPromise;
      setMatchId(matchRun.matchId);
      startPolling();
    } catch (err) {
      console.error(err);
      toast({
        title: "Error running match.",
        description: "Match server error.",
        status: "error",
        duration: null,
      });
      setStage(Stage.Prompt);
    }
  }, [
    selectedEnrollmentPeriod?.id,
    organization,
    toast,
    startMatch,
    startPolling,
    formsWithSchools,
    formsWithoutSchools,
  ]);

  useEffect(() => {
    if (!isOpen) cancelMatch();
    return cancelMatch;
  }, [isOpen, cancelMatch]);

  const updateDownloadUrl = useCallback(
    async (documentId: string, triggerDownload: boolean) => {
      try {
        const documentUrl = await getDownloadUrl(documentId);
        if (!documentUrl) throw new Error("Bad document URL.");
        setDownloadUrl(documentUrl);
        setStage(Stage.Download);
        if (triggerDownload) triggerDownloadFromUrl(documentUrl);
      } catch (err: unknown) {
        console.error(err);
        toast({
          title: "Error running match.",
          description: "Error retrieving match results.",
          status: "error",
          duration: null,
        });
        setStage(Stage.Prompt);
      }
    },
    [toast, getDownloadUrl]
  );

  useEffect(() => {
    if (!isOpen || stage !== Stage.Running) return;
    if (matchRunResults.isLoading() || !matchRunResults.hasData()) return;
    else if (
      matchRunResults.data.match_run_by_pk?.status ===
      GQL.match_run_status_enum.Error
    ) {
      toast({
        title: "Error running match.",
        description: "Match did not complete successfully.",
        status: "error",
        duration: null,
      });
      setStage(Stage.Prompt);
      return;
    } else if (
      matchRunResults.data.match_run_by_pk?.status ===
      GQL.match_run_status_enum.Canceled
    ) {
      toast({
        title: "Match canceled.",
        status: "info",
      });
      setStage(Stage.Prompt);
      return;
    }
    const documentId =
      matchRunResults.data.match_run_by_pk?.results_document_id;
    if (!documentId) return; // Results not ready yet.
    toast({
      title: "Match completed.",
      description: "Download will start shortly.",
      status: "success",
    });
    updateDownloadUrl(documentId, true);
  }, [isOpen, stage, matchRunResults, toast, updateDownloadUrl]);

  function constructContent(): JSX.Element {
    switch (stage) {
      case Stage.Prompt:
        return (
          <>
            <AlertDialogHeader fontSize="lg">
              Run a lottery match
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              <VStack gap={2} alignItems="flex-start">
                <MagnifyingGlassSvg
                  aria-hidden
                  style={{ alignSelf: "center" }}
                />
                <Text>
                  <Glossary>Each of the </Glossary>
                  <b>
                    <Glossary>
                      {`${schoolForms.length} selected applications`}
                    </Glossary>
                  </b>
                  <Glossary> will be entered into a lottery.</Glossary>
                </Text>
                <Text>
                  <Glossary>
                    No offers will be made as a result of running this lottery
                    match!
                  </Glossary>
                </Text>
                <Text color="gray.500">
                  <Glossary>
                    You'll be able to review the results in a CSV document and
                    decide how to proceed.
                  </Glossary>
                </Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                type="button"
                ref={cancelRef}
                variant="ghost"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button onClick={handleRunMatch} ml={3}>
                Run lottery match
              </Button>
            </AlertDialogFooter>
          </>
        );
      case Stage.Running:
        return (
          <>
            <AlertDialogHeader fontSize="lg">Match loading</AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              <VStack gap={4} alignItems="flex-start">
                <ClipboardSvg aria-hidden style={{ alignSelf: "center" }} />
                <Text>
                  <Glossary>
                    We're running the lottery match with the selected
                    applicants.
                  </Glossary>
                </Text>
                <Text>
                  Estimated time remaining:
                  <Spinner
                    as="span"
                    size="sm"
                    verticalAlign="-10%"
                    margin="0 0.5em"
                  />
                  <b>Less than 1 minute</b>
                </Text>
                <Alert status="warning" variant="subtle" borderRadius={6}>
                  <AlertIcon alignSelf="flex-start" />
                  <AlertDescription>
                    Closing this browser tab will result in this match being
                    cancelled.
                  </AlertDescription>
                </Alert>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                type="button"
                ref={cancelRef}
                colorScheme="gray"
                onClick={() => setStage(Stage.Cancel)}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </>
        );
      case Stage.Cancel:
        return (
          <>
            <AlertDialogHeader fontSize="lg">Cancel match</AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              <VStack gap={4} alignItems="flex-start">
                <Text>
                  <Glossary>
                    Are you sure you want to cancel the match? The results will
                    be inaccessible.
                  </Glossary>
                </Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                type="button"
                ref={cancelRef}
                colorScheme="gray"
                onClick={() => setStage(Stage.Running)}
              >
                No, do not cancel
              </Button>
              <Button onClick={onCancel} ml={3}>
                Yes, cancel match
              </Button>
            </AlertDialogFooter>
          </>
        );
      case Stage.Download:
        // Note: The actual tag label names are generated in the backend; the
        // ones listed here are made to match, but they can get out of sync.
        // TODO: Synchronize generation of the tag labels, probably as part of
        // the lambda request or response.
        return (
          <>
            <AlertDialogHeader fontSize="lg">
              Your match results are ready
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              <VStack gap={2} alignItems="flex-start">
                <PaperAnalyticsSvg
                  aria-hidden
                  style={{ alignSelf: "center" }}
                />
                <Text>
                  Your match results are ready! If the results haven't
                  downloaded automatically, click{" "}
                  <Link
                    href={downloadUrl}
                    color="primary.500"
                    textDecoration="underline"
                  >
                    here
                  </Link>{" "}
                  to download.
                </Text>
                <Text>Results will be tagged:</Text>
                <Text>
                  <Tag variant="tag">{`Offer: ${tagLabel}`}</Tag>
                </Text>
                <Text>
                  <Tag variant="tag">{`Waitlist: ${tagLabel}`}</Tag>
                </Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button type="button" ref={cancelRef} onClick={onCancel}>
                Done
              </Button>
            </AlertDialogFooter>
          </>
        );
      default:
        const _exhaustiveCheck: never = stage;
        return _exhaustiveCheck;
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      closeOnOverlayClick={stage === Stage.Prompt}
      isCentered
    >
      <AlertDialogOverlay className={WEGLOT_APPLY_CLASS}>
        <AlertDialogContent marginX={4}>
          {constructContent()}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
