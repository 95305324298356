import { Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { ApolloError } from "src/components/Feedback/ApolloError";
import { NotFound } from "src/components/Feedback/NotFound";
import { EnrollmentPeriodSelectInput } from "src/components/Inputs/EnrollmentPeriodSelectInput";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { OrganizationError, useOrganization } from "src/hooks/useOrganization";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as breadcrumb from "src/services/breadcrumb";
import { capitalizeFirstLetter } from "src/services/format";
import { AdminProcessedForm } from "./AdminProcessedForm";
import { DateRangeSelector } from "./DateRangeSelector";
import { DateRange, DEFAULT_DATE_RANGE } from "./types/dateRange";
import { ParentProcessedForm } from "./ParentProcessedForm";
import { RecentActivityFeed } from "./RecentActivityFeed";

export function Home() {
  const organization = useOrganization();
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const profile = useUserProfile();

  const [enrollmentPeriodId, setEnrollmentPeriodId] = React.useState<string>();
  const [dateRange, setDateRange] =
    React.useState<DateRange>(DEFAULT_DATE_RANGE);

  React.useEffect(() => {
    if (selectedEnrollmentPeriod) {
      setEnrollmentPeriodId(selectedEnrollmentPeriod.id);
    }
  }, [selectedEnrollmentPeriod]);

  const renderError = React.useCallback((error: OrganizationError) => {
    switch (error.kind) {
      case "NotFoundError":
        return <NotFound />;
      case "ServerError":
        return <ApolloError error={error.error} />;
    }
  }, []);

  const userFirstName = profile.hasData()
    ? capitalizeFirstLetter(profile.data.first_name ?? "admin")
    : "Avela Admin";

  const onSubmit = React.useCallback(() => {
    // do nothing
  }, []);

  return (
    <RemoteDataView remoteData={organization} error={renderError}>
      {() => {
        const formData = {
          organizationId: organization.hasData() ? organization.data.id : "",
          enrollmentPeriodId: enrollmentPeriodId ?? "",
          dateRange,
        };

        return (
          <Formik
            onSubmit={onSubmit}
            initialValues={formData}
            enableReinitialize
          >
            {({ values }) => {
              return (
                <Flex as="form" direction="column" gap={6}>
                  <Breadcrumb
                    items={breadcrumb.getBreadcrumbsForHome(organization)}
                  />
                  <Flex direction="row" justify="space-between">
                    <Flex direction="column" gap={2}>
                      <Heading color="gray.700" fontWeight={600}>
                        Hi, {userFirstName}
                      </Heading>

                      <Text as="h2" color="gray.600" fontSize="lg">
                        Here's what you missed
                      </Text>
                    </Flex>
                    <HStack>
                      <DateRangeSelector
                        name="dateRange"
                        onChange={setDateRange}
                      />
                      <EnrollmentPeriodSelectInput
                        name="enrollmentPeriodId"
                        organizationId={values.organizationId}
                        onSelectedEnrollmentPeriod={(period) =>
                          setEnrollmentPeriodId(period?.id)
                        }
                      />
                    </HStack>
                  </Flex>

                  <Flex w="full" gap={4}>
                    <Flex direction="column" flex={0.5} gap={4}>
                      <Text
                        as="h3"
                        fontSize="lg"
                        color="gray.800"
                        fontWeight={600}
                      >
                        To do
                      </Text>
                      <Flex w="full" direction="row" gap={4}>
                        <ParentProcessedForm
                          organizationId={values.organizationId}
                          enrollmentPeriodId={values.enrollmentPeriodId}
                          dateRange={values.dateRange}
                        />
                        <AdminProcessedForm
                          organizationId={values.organizationId}
                          enrollmentPeriodId={values.enrollmentPeriodId}
                          dateRange={values.dateRange}
                        />
                      </Flex>
                    </Flex>

                    <Flex direction="column" flex={0.5} gap={4}>
                      <Text
                        as="h3"
                        fontSize="lg"
                        color="gray.800"
                        fontWeight={600}
                      >
                        Recent activity
                      </Text>
                      <RecentActivityFeed
                        organizationId={values.organizationId}
                        enrollmentPeriodId={values.enrollmentPeriodId}
                        dateRange={values.dateRange}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              );
            }}
          </Formik>
        );
      }}
    </RemoteDataView>
  );
}
