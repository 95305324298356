import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Icon, Link } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/table-core";
import { useCallback } from "react";
import { RiUserLine } from "react-icons/ri";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { BannerSelection } from "src/components/Table/BannerSelection";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useTableSelection } from "src/hooks/useTableSelection";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { csvExport } from "src/services/dataTransfer";
import { getReferenceId } from "src/services/id";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";

interface StudentsListProps {
  students: GQL.GetStudents_person[];
  limit: number;
  offset: number;
  count: number;
  onFetchAll: () => Promise<GQL.GetStudents_person[]>;
  onFetchMore: (limit: number, offset: number) => void;
  onSort: (columnName: string, sortType: SortType) => void;
  onClearSearch: () => void;
}

export const StudentsList: React.FC<StudentsListProps> = ({
  students,
  limit,
  offset,
  count,
  onFetchAll,
  onFetchMore,
  onSort,
  onClearSearch,
}) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const { selection, checkboxColumnDef } = useTableSelection({
    getId: (person: GQL.GetStudents_person) => person.id,
    count,
  });

  const userPermissions = useUserPermissions();
  const toast = useAvelaToast();

  function createRowLink(
    row: Row<GQL.GetStudents_person>,
    text: string | null
  ) {
    return text && userPermissions.hasOne("user:update") ? (
      <Link
        color="primary.500"
        href={organization
          .map((org) => Url.OrgAdmin.Students.edit(org, row.original.id))
          .withDefault("#")}
        textDecoration="underline"
      >
        {text}
      </Link>
    ) : (
      text
    );
  }

  const columns: ColumnDef<GQL.GetStudents_person>[] = [
    checkboxColumnDef({ id: "is_selected" }),
    {
      id: "id",
      cell: (props) => (
        <TextWithOverflowTooltip
          content={getReferenceId(props.row.original)}
          maxWidth="9em"
        />
      ),
    },
    {
      id: "first_name",
      header: "First name",
      cell: (props) => createRowLink(props.row, props.row.original.first_name),
    },
    {
      id: "last_name",
      header: "Last name",
      cell: (props) => createRowLink(props.row, props.row.original.last_name),
    },
    {
      id: "birth_date",
      header: "date of birth",
      accessorFn: (row) =>
        row?.birth_date ||
        ("" && new Date(row?.birth_date || "").toLocaleDateString()),
    },
    {
      id: "active",
      header: "status",
      accessorFn: (row) => (row?.active ? "Active" : "Not Active"),
    },
  ];

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  return students.length ? (
    <PaginatedTable<GQL.GetStudents_person>
      data={students}
      columns={columns}
      limit={limit}
      offset={offset}
      count={count}
      onFetchMore={onFetchMore}
      sortableColumnIds={["first_name", "last_name", "birth_date"]}
      onChangeSort={handleChangeSort}
      banner={() => (
        <BannerSelection selection={selection}>
          <WithUserPermissions permissions={["user:read"]}>
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={DownloadIcon} />}
              onClick={async () => {
                toast({
                  title: "Export in progress",
                  description: "Download will start shortly",
                });
                const records = await selection.materialize(onFetchAll);

                csvExport(
                  records.map(({ __typename, ...person }) => person),
                  "students.csv"
                );
              }}
            >
              Export
            </Button>
          </WithUserPermissions>
        </BannerSelection>
      )}
    />
  ) : (
    <NoDataTable
      icon={RiUserLine}
      text={glossary`No students found`}
      buttonText="Clear search"
      onButtonAction={onClearSearch}
    />
  );
};
