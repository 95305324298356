import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Link,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import * as Form from "src/services/form";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { GET_PREVIOUS_FORM_BY_FORM_ID } from "../graphql/queries";
import { FormTagsPopover } from "../../tags/FormTagsPopover";
import { getAssignedForms } from "src/scenes/parent/formTemplates/components/FormTemplateCard/helpers";
import React from "react";
import { useStartForm } from "src/scenes/parent/forms/hooks/useStartForm";
import { useAvelaToast } from "src/hooks/useAvelaToast";

type FormDetailAlertProps = {
  onClick: () => void;
  text: string;
  isLoading: boolean;
};

const FormDetailAlert: React.FC<FormDetailAlertProps> = ({
  text,
  isLoading,
  onClick,
}) => {
  return (
    <Alert
      variant="solid"
      status="info"
      borderRadius={6}
      justifyContent="space-between"
      paddingInline={4}
    >
      <AlertDescription
        color="white"
        fontSize="sm"
        fontWeight="400"
        display="flex"
      >
        <AlertIcon />
        {text}
      </AlertDescription>
      <Button
        variant="outline"
        size="xs"
        lineHeight="1"
        border="0"
        backgroundColor="white"
        colorScheme="blue"
        onClick={onClick}
        isLoading={isLoading}
      >
        Continue here
      </Button>
    </Alert>
  );
};

type Props = {
  form: GQL.GetPreviousFormByFormId_form_by_pk;
  assignedForms: GQL.GetAssignedFormsByFormId_assigned_form[];
};
export const FormDetails: React.FC<Props> = ({ form, assignedForms }) => {
  const organization = useOrganization();

  const relatedForms = Form.Related.getRelatedForms(form);
  const relatedGrade = Form.Related.getRelatedGrade(form);
  const relatedSchool = Form.Related.getRelatedSchool(form);
  const tags = form.form_tags.map((tag) => tag.enrollment_period_tag.name);
  const tagsContent = tags ? (
    <Flex gap="2" flexWrap="wrap">
      {tags.map((tag) => (
        <Tag key={tag} variant="tag" fontSize="sm">
          {tag}
        </Tag>
      ))}
      <WithUserPermissions permissions={["form:update"]}>
        <FormTagsPopover
          enrollmentPeriodId={form.form_template.enrollment_period_id}
          formId={{
            formId: form.id,
          }}
          refetchQueries={[GET_PREVIOUS_FORM_BY_FORM_ID]}
        />
      </WithUserPermissions>
    </Flex>
  ) : null;

  const hasRelatedForms = relatedForms.length > 0;
  const hasRelatedGrade = relatedGrade !== undefined;
  const hasRelatedSchool = relatedSchool !== undefined;
  const hasFormDetails = hasRelatedForms || hasRelatedGrade || hasRelatedSchool;
  const relatedAssignedForms = getAssignedForms(form, assignedForms);
  const hasAssignedForms = relatedAssignedForms.length > 0;

  const { startAssignedForm } = useStartForm();
  const navigate = useNavigate();
  const toast = useAvelaToast();

  const [isCreatingAssignedForm, setIsCreatingAssignedForm] =
    React.useState(false);
  const startForm = React.useCallback(
    async (assignedForm: GQL.GetAssignedFormsByFormId_assigned_form) => {
      setIsCreatingAssignedForm(true);
      if (!organization.hasData()) {
        return;
      }

      try {
        const formId = await startAssignedForm(assignedForm);
        if (formId) {
          // dont setIsCreatingAssignedForm(false); here - let the loading indicator spin
          // until the navigate moves away from the page
          navigate(
            Url.OrgAdmin.Forms.view({
              organization,
              formTemplateId: assignedForm.form_template.id,
              id: formId,
            })
          );
        }
      } catch (e) {
        setIsCreatingAssignedForm(false);
        console.error(e);
        toast.error({
          title: "Oops! Something went wrong",
        });
      }
    },
    [navigate, organization, startAssignedForm, toast]
  );

  return (
    <Flex
      marginX="-4"
      direction="column"
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="blackAlpha.80"
      padding="4"
      gap="4"
    >
      <Heading as="h2" fontSize="md" fontWeight="600">
        Form details
      </Heading>
      <Flex direction="column" gap="3">
        <Flex direction="column" gap="1">
          <Text fontSize="sm" color="gray.600">
            ID
          </Text>
          <Text>{form.id}</Text>
        </Flex>
        {!hasFormDetails && (
          <Text>
            Any additional information collected during the process will be
            displayed here
          </Text>
        )}
        <WithRequiredHasuraRoles
          roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
        >
          <WithUserPermissions permissions={["form:create"]}>
            {hasAssignedForms && (
              <Flex direction="column" gap="3">
                {assignedForms.map((assignedForm) => {
                  return (
                    <FormDetailAlert
                      text={` ${assignedForm.person.first_name} can now start ${assignedForm.form_template.name} 🎉`}
                      isLoading={isCreatingAssignedForm}
                      onClick={async () => {
                        await startForm(assignedForm);
                      }}
                    ></FormDetailAlert>
                  );
                })}
              </Flex>
            )}
          </WithUserPermissions>
        </WithRequiredHasuraRoles>
        {hasRelatedForms && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              Related form
            </Text>
            {relatedForms.map((relatedForm) => {
              return (
                <Link
                  key={relatedForm.id}
                  color="primary.500"
                  as={RouterLink}
                  to={Url.OrgAdmin.Forms.view({
                    organization,
                    id: relatedForm.id,
                    formTemplateId: relatedForm.form_template_id,
                  })}
                >
                  {relatedForm.form_template.name} ID {relatedForm.id}
                </Link>
              );
            })}
          </Flex>
        )}
        {hasRelatedGrade && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              Grade
            </Text>
            <Text>{relatedGrade}</Text>
          </Flex>
        )}
        {hasRelatedSchool && (
          <Flex direction="column" alignItems="flex-start">
            <Text fontSize="sm" color="gray.600">
              School
            </Text>
            <Text>{relatedSchool.name}</Text>
          </Flex>
        )}
      </Flex>

      <WithRequiredHasuraRoles
        roles={[
          HasuraRole.ADMIN,
          HasuraRole.DISTRICT_ADMIN,
          HasuraRole.ORG_ADMIN,
          HasuraRole.SCHOOL_ADMIN,
        ]}
      >
        {tagsContent}
      </WithRequiredHasuraRoles>
    </Flex>
  );
};
