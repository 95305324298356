import { Flex } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { ReactComponent as ErrorDog } from "src/images/error-dog.svg";

export function MatchRunError({ errorMessage }: { errorMessage: string }) {
  return (
    <Flex minHeight="70vh" justify="center" align="center">
      <EmptyState
        Svg={ErrorDog}
        heading="There was an error running this match"
        description={`Reach out to the Avela team for assistance with the following error message: ${errorMessage}`}
      />
    </Flex>
  );
}
