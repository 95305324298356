import { Link } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { Link as RouterLink } from "react-router-dom";
import type { OrganizationError } from "src/hooks/useOrganization";
import { formatUuid } from "src/services/id";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";

export type ColumnType = GQL.GetPossibleStudentDuplicates_person;

type CellFn<T extends ColumnType> = Exclude<
  ColumnDef<T>["cell"],
  undefined | string
>;

type DuplicatePersonIdColumnCellProps<T extends ColumnType> = Parameters<
  CellFn<T>
>[0] & {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  personId: uuid;
};

function DuplicatePersonIdColumnCell<T extends ColumnType>(
  props: DuplicatePersonIdColumnCellProps<T>
) {
  const { organization, personId } = props;

  const url = Url.OrgAdmin.Students.edit(organization, personId);

  return (
    <Link
      as={RouterLink}
      color="primary.500"
      to={url}
      textDecoration="underline"
    >
      {formatUuid(personId)}
    </Link>
  );
}

type DuplicatePersonIdDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
};

export function buildDuplicatePersonIdColumnDef<T extends ColumnType>({
  organization,
}: DuplicatePersonIdDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.id,
    id: "id",
    header: "id",

    cell: (props) => (
      <DuplicatePersonIdColumnCell
        {...props}
        organization={organization}
        personId={props.row.original.id}
      />
    ),
  };
}
